import React from "react";
import { Box, Stack, CssBaseline, TextField, InputAdornment, IconButton, Typography, Button } from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import plus from "../../assets/svg/plus.svg";
import less from "../../assets/svg/less.svg";
import empleft1 from '../../assets/staticPageAssets/EmployerWhyus-left.png'
import empright1 from '../../assets/staticPageAssets/employer-whyus-right.jpg'
import employer2A from '../../assets/staticPageAssets/employer2-a.png'
// import empright2 from '../../assets/staticPageAssets/whyus-2.gif'
import employerPartnerHexa1 from '../../assets/staticPageAssets/Path 15993.png'
import employerPartnerHexa2 from '../../assets/staticPageAssets/Path 14204.png'
import employerPartnerHexa4 from '../../assets/staticPageAssets/Path 15994.png'
import employerwhyus3 from '../../assets/staticPageAssets/employerwhyus-3.png'

// import whyusleftOne from '../../assets/staticPageAssets/whyus-left_inspire_change.png'
import bullet from '../../assets/staticPageAssets/Polygon.svg'
import platinumSmall from '../../assets/staticPageAssets/Platinum-small.png'
import goldSmall from '../../assets/staticPageAssets/gold-small.png'
import baseSmall from '../../assets/staticPageAssets/base-small.png'


import check from '../../assets/staticPageAssets/chk.png'
import bulletArrow from '../../assets/staticPageAssets/polygon.png'



import leftbottom from '../../assets/images/bg-10@1x.png'


import useStyles from './styles';
import { border, borderRadius } from "@mui/system";
import { BottomBox, LeftbottomA } from "./RightBlock";

import { _debug } from "../../constants";

var allowed = "no"

function EmployerPartnershipWhyUs() {
  const navigate = useNavigate();

  const [seek, setSeek] = React.useState(allowed)
  const classes = useStyles();
  // if(_debug === 'development') allowed = 'yes'
  allowed = 'yes'
  const [viewOne, setViewOne] = React.useState(false);
  const [viewTwo, setViewTwo] = React.useState(false);
  const [viewThree, setViewThree] = React.useState(false);
  const [slideState, setSlideState] = React.useState({
    heading: ['Employees ', 'at the work place'],
    leftContent: ['29% ', '84%', '90%'],
    rightContent: ['of millennials are engaged ', 'of those happy with high benefit report higher job satisfaction', 'of Millennials value benefits over pay'],
    image: employer2A,
    active: [true, false, false],
    footer: ['Most employees lack engagement at their place of work. Increasing satisfaction motivates your people and improves your company output. An effective and tangible', 'benefit is desired more than simple pay and will boost your productivity and value']

  })
  const handleClick = (index) => {
    if (index === 1) {
      setViewOne(true);
    }
    if (index === 2) {
      setViewTwo(true);
    }
    if (index === 3) {
      setViewThree(true);
    }
  };

  const handleCollapse = (index) => {
    if (index === 1) {
      setViewOne(false);
    }
    if (index === 2) {
      setViewTwo(false);
    }
    if (index === 3) {
       setViewThree(false);
    }
   
  };

  
  return (
    <React.Fragment><CssBaseline />
      <Box sx={{ overflow: 'hidden', width: '100%', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <Navbar />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <LeftBlockA />
          <Box sx={{ width: '13.6vw', justifyContent: 'center' }}>
            <Stack sx={{ mr: '2.0vw', ml: '3.0vw', mt: '19vw', height: '10vw', width: '10vw' }} direction="row">
              <Button sx={{overflow:'hidden', mt:0,zIndex:1,border:0,width:'8.0vw',position:'fixed',height:'10vw', minHeight: 0, minWidth: 0,padding: '0.2vw'}}  id="Discover" className='hexBtnCenter'
                onClick={() => allowed === 'yes' ? navigate("/LetsTalk", { replace: false }) : setSeek("ddd")} > 
                <HexButton titleText="LET's TALK" type='large' fontSize='24' fontWeight="bold" />
              </Button>
            </Stack>
            {seek == 'ddd' ? <InputKey /> : <></>}
          </Box>
          <RightBlockA />
        </Box>
        <Box id={'WhyUs'} sx={{ mt: '.1vw', width: '100%', height: '50vw', display: 'flex', flexDirection: 'row' }}>

          <Box sx={{ width: '36vw' }}>
            <Typography sx={{
              ml: '7.2vw', mt: '8vw', color: '#31312C', fontWeight: 300,
              font: "Roboto", textAlign: 'left'
            }} fontSize='5vw' lineHeight={1.2} letterSpacing='-1.6px'>
              Why us
            </Typography>
            <Typography sx={{
              mt: '2vw', ml: '7.2vw', color: '#8f8f8f',
              font: "Roboto", fontWeight: 'light', textAlign: 'left'
            }} fontSize='2.4vw' lineHeight={1}>
              Bringing Technology &
            </Typography>
            <Typography sx={{
              mt: '2vw', ml: '7.2vw', color: '#8f8f8f',
              font: "Roboto", fontWeight: 'light', textAlign: 'left'
            }} fontSize='2.4vw' lineHeight={1}>
              Innovation to enhance
            </Typography>
            <Typography sx={{
              mt: '2vw', ml: '7.2vw', color: '#8f8f8f',
              font: "Roboto", fontWeight: 'light', textAlign: 'left'
            }} fontSize='2.4vw' lineHeight={1}>
              your peoples financial
            </Typography>
            <Typography sx={{
              mt: '2vw', ml: '7.2vw', color: '#8f8f8f',
              font: "Roboto", fontWeight: 'light', textAlign: 'left'
            }} fontSize='2.4vw' lineHeight={1}>
              wellbeing
            </Typography>
          </Box>

          {/* <Box sx={{ml:'14vw',width:'50vw',height:'50vw'}}>
              <img src={empright2} alt = "Loading..." width="100%" height="100%" background='inherit'/>
              </Box> */}
          <Box sx={{ ml: '14vw', width: '50vw', height: '50vw', display: 'flex', flexDirection: 'column' }} >

            {/* Our Mission Right */}
            {/* <img src={empright2} alt = "Loading..." width="100%" heigh="100%"  /> */}
            <Stack spacing={0} direction="row">
          
            <Box sx={{ ml: "0vw", width: "50vw", height: "60vw" }}>
              {/* Right Content should be here */}
              {/* <img src={whyusrightTwo} alt = "Loading..." width="100%" heigh="100%" /> */}
              {viewOne ? (
                <Box
                  sx={{
                    marginLeft: "4.45vw",
                    marginTop: "11.45vw",
                    width: "42.24vw",
                    height: "28.85vw",
                    background: "#515151",
                    borderRadius: "1.04vw",
                    position: "relative",
                  }}
                  onMouseLeave={() => handleCollapse(1)}
                >
                  <Box
                    sx={{
                      marginLeft: "5.73vw",
                      marginTop: "3.13vw",
                      width: "45.51vw",
                      height: "2.71vw",
                      display: "flex",
                      position: "absolute",
                    }}
                  >
                    <Box sx={{ width: "32.76vw", height: "2.71vw"}}>
                      <Typography
                        sx={{
                          mt: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontSize:"2.03vw",
                          fontWeight:"medium"
                        }}
                      >
                        INNOVATIVE  FINANCING
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "18.9vw",
                        marginTop: "0vw",
                        width: "2.51vw",
                        height: "1vw",
                      }}
                     
                    >
                      <img src={less} alt = "Loading..." width="100%" heigh="100%" />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      marginLeft: "5.86vw",
                      marginTop: "8.44vw",
                      width: "32.79vw",
                      height: "17.55vw",
                      position: "absolute",
                      dispaly: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        width: "32.79vw",
                        height: "17.55vw",
                      }}
                    >
                      <Box
                        sx={{
                          width: "1.09vw",
                          height: "1.30vw",
                          dispaly: "flex",
                        }}
                      >
                          <img src={bulletArrow} alt = "Loading..." width="100%" heigh="100%" />
                      </Box>
                      <Box
                        sx={{
                          marginTop: "3.70vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bulletArrow} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                      <Box
                        sx={{
                          marginTop: "3.70vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bulletArrow} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                      <Box
                        sx={{
                          marginTop: "3.70vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bulletArrow} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                    </Box>
                    {/* <Box sx={{width:'1.09vw',height:'1.30vw'}}>
  </Box> */}
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "-17.55vw",
                        width: "29.19",
                        height: "3vw",
                      
                      }}
                    >
                      <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                      Tailor financing for your employees not just a general mortgage for anyone
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "2vw",
                        width: "29.19",
                        height: "5vw",
                        
                      }}
                    >
                           <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                     Utilize broader solutions considering your employees needs
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "0vw",
                        width: "29.19",
                        height: "5vw",
                      }}
                    >
                       <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                     Design and create wider choices with your employees front and foremost 
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "0vw",
                        width: "29.19",
                        height: "5vw",
                      }}
                    >
                       <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                  Optimizing to deliver rate, amount, and experience meeting your employee's goals
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )
              
               :
               viewTwo ? (
                <Box
                  sx={{
                    marginLeft: "4.45vw",
                    marginTop: "11.45vw",
                    width: "42.24vw",
                    height: "28.85vw",
                    background: "#515151",
                    borderRadius: "1.04vw",
                    position: "relative",
                  }}
                  onMouseLeave={() => handleCollapse(2)}
                >
                  <Box
                    sx={{
                      marginLeft: "5.73vw",
                      marginTop: "3.13vw",
                      width: "36.51vw",
                      height: "2.71vw",
                      display: "flex",
                      position: "absolute",
                    }}
                  >
                    <Box sx={{ width: "11.77vw", height: "2.71vw" }}>
                      <Typography
                        sx={{
                          mt: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                        }}
                        fontSize="2.03vw"
                        fontWeight="medium"
                      >
                       TECHNOLOGY
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "18.9vw",
                        marginTop: "0vw",
                        width: "2.51vw",
                        height: "1vw",
                      }}
                      
                    >
                      <img src={less} alt = "Loading..." width="100%" heigh="100%" />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      marginLeft: "5.86vw",
                      marginTop: "8.44vw",
                      width: "32.79vw",
                      height: "17.55vw",
                      position: "absolute",
                      dispaly: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        width: "32.79vw",
                        height: "17.55vw",
                      }}
                    >
                      <Box
                        sx={{
                          width: "1.09vw",
                          height: "1.30vw",
                          dispaly: "flex",
                        }}
                      >
                          <img src={bulletArrow} alt = "Loading..." width="100%" heigh="100%" />
                      </Box>
                      <Box
                        sx={{
                          marginTop: "1.88vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bulletArrow} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                      <Box
                        sx={{
                          marginTop: "4vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bulletArrow} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                      <Box
                        sx={{
                          marginTop: "3.5vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bulletArrow} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                    </Box>
                    {/* <Box sx={{width:'1.09vw',height:'1.30vw'}}>
  </Box> */}
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "-17.55vw",
                        width: "30.5",
                        height: "3vw",
                      
                      }}
                    >
                      <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                      Automation of smart home financing
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "0.5vw",
                        width: "30.5",
                        height: "5vw",
                        
                      }}
                    >
                           <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                        Use leading available technology and trusted partners
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "0vw",
                        width: "35.5",
                        height: "5vw",
                      }}
                    >
                       <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                  Integrated single platform solution 
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "0vw",
                        width: "35.5",
                        height: "5vw",
                      }}
                    >
                       <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                   Embed and use data and price feeds to create customized outcomes
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ):
              viewThree ? (
                <Box
                  sx={{
                    marginLeft: "4.45vw",
                    marginTop: "11.45vw",
                    width: "42.24vw",
                    height: "28.85vw",
                    background: "#515151",
                    borderRadius: "1.04vw",
                    position: "relative",
                  }}
                  onMouseLeave={() => handleCollapse(3)}
                >
                  <Box
                    sx={{
                      marginLeft: "5.73vw",
                      marginTop: "3.13vw",
                      width: "36.51vw",
                      height: "2.71vw",
                      display: "flex",
                      position: "absolute",
                    }}
                  >
                    <Box sx={{ width: "11.77vw", height: "2.71vw" }}>
                      <Typography
                        sx={{
                          mt: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                        }}
                        fontSize="2.03vw"
                        fontWeight="medium"
                      >
                        EXPERIENCE
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "18.9vw",
                        marginTop: "0vw",
                        width: "2.51vw",
                        height: "1vw",
                      }}
                      
                    >
                      <img src={less} alt = "Loading..." width="100%" heigh="100%" />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      marginLeft: "5.86vw",
                      marginTop: "8.44vw",
                      width: "32.79vw",
                      height: "17.55vw",
                      position: "absolute",
                      dispaly: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        width: "32.79vw",
                        height: "17.55vw",
                      }}
                    >
                      <Box
                        sx={{
                          width: "1.09vw",
                          height: "1.30vw",
                          dispaly: "flex",
                        }}
                      >
                          <img src={bulletArrow} alt = "Loading..." width="100%" heigh="100%" />
                      </Box>
                      <Box
                        sx={{
                          marginTop: "3.70vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bulletArrow} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                      <Box
                        sx={{
                          marginTop: "5.36vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bulletArrow} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                    </Box>
                    {/* <Box sx={{width:'1.09vw',height:'1.30vw'}}>
  </Box> */}
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "-17.55vw",
                        width: "29.19",
                        height: "3vw",
                      
                      }}
                    >
                      <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                       Leaders in mortgage finance since the 90’s 
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "2vw",
                        width: "29.19",
                        height: "5vw",
                        
                      }}
                    >
                           <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                      Success in delivering positive customer outcomes through digital transformation and providing an alternate choice 
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "1.5vw",
                        width: "29.19",
                        height: "5vw",
                      }}
                    >
                       <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                    Supported by strong financial backers, leading technology partners and a great team of people
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ):
               (
                <Box
                  sx={{
                    marginLeft: "4.45vw",
                    marginTop: "11.45vw",
                    width: "42.24vw",
                    height: "24.22vw",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        width: "42.24vw",
                        height: "8.07vw",
                        boxShadow: "0vw 0.16vw 2.03vw #00000029",
                        borderRadius: "1.04vw",
                        display: "flex",
                        position: "relative",
                      }}
                      onMouseOver={() =>handleClick(1)}
                    >
                      <Box
                        sx={{
                          marginLeft: "5.73vw",
                          marginTop: "2.66vw",
                          width: "22.76vw",
                          height: "2.71vw",
                          position: "absolute",
                        }}
                        
                      >
                        <Typography
                          sx={{
                            mt: "0vw",
                            color: "#C2C2C2",
                            font: "Roboto",
                            textAlign: "left",
                          }}
                          fontSize="2.03vw"
                          lineHeight={1.2}
                          letterSpacing="0px"
                          fontWeight="medium"
                        >
                          INNOVATIVE FINANCING
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          marginLeft: "36.48vw",
                          marginTop: "2.66vw",
                          width: "2.6vw",
                          height: "2.6vw",
                          position: "absolute",
                        }}
                       
                      >
                        <img
                          src={plus}
                          alt = "Loading..."
                          width="100%"
                          heigh="100%"
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "1.98vw",
                        width: "42.24vw",
                        height: "8.07vw",
                        boxShadow: "0vw 0.16vw 2.03vw #00000029",
                        borderRadius: "1.04vw",
                        display: "flex",
                        position: "relative",
                      }}
                      onMouseOver={() =>handleClick(2)}
                    >
                      <Box
                        sx={{
                          marginLeft: "5.73vw",
                          marginTop: "2.66vw",
                          width: "22.76vw",
                          height: "2.71vw",
                          position: "absolute",
                        }}
                      >
                        <Typography
                          sx={{
                            mt: "0vw",
                            color: "#C2C2C2",
                            font: "Roboto",
                            textAlign: "left",
                          }}
                          fontSize="2.03vw"
                          lineHeight={1.2}
                          letterSpacing="0px"
                          fontWeight="medium"
                        >
                          TECHNOLOGY
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          marginLeft: "36.48vw",
                          marginTop: "2.66vw",
                          width: "2.6vw",
                          height: "2.6vw",
                          position: "absolute",
                        }}
                        onClick={() => handleClick(2)}
                      >
                                 <img
                          src={plus}
                          alt = "Loading..."
                          width="100%"
                          heigh="100%"
                        /> 
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "1.98vw",
                        width: "42.24vw",
                        height: "8.07vw",
                        boxShadow: "0vw 0.16vw 2.03vw #00000029",
                        borderRadius: "1.04vw",
                        display: "flex",
                        position: "relative",
                      }}
                      onMouseOver={() =>handleClick(3)}
                    >
                      <Box
                        sx={{
                          marginLeft: "5.73vw",
                          marginTop: "2.66vw",
                          width: "22.76vw",
                          height: "2.71vw",
                          position: "absolute",
                        }}
                      >
                        <Typography
                          sx={{
                            mt: "0vw",
                            color: "#C2C2C2",
                            font: "Roboto",
                            textAlign: "left",
                          }}
                          fontSize="2.03vw"
                          lineHeight={1.2}
                          letterSpacing="0px"
                          fontWeight="medium"
                        >
                          EXPERIENCE
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          marginLeft: "36.48vw",
                          marginTop: "2.66vw",
                          width: "2.6vw",
                          height: "2.6vw",
                          position: "absolute",
                        }}
                        onClick={() => handleClick(3)}
                      >
                                  <img
                          src={plus}
                          alt = "Loading..."
                          width="100%"
                          heigh="100%"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              
            </Box>
          </Stack>

            <Box sx={{ marginLeft: '3.6vw', height: '43.5vw', width: '37.5vw' }}>
              {/* <Box sx={{display:'flex'}}>
                  <Box sx={{marginLeft:'5vw',marginTop:'6vw'}}>

                  </Box>
                </Box> */}
              {/* <Box sx={{marginLeft:'5.6vw',marginTop:'5.5vw',width:'29.5vw',height:'7.4vw',background:'black'}}></Box> */}
              {/* <Box sx={{marginLeft:'0vw',marginTop:'1.5vw',width:'37.6vw',height:'6.04vw',background:'black'}}></Box>
              <Box sx={{marginLeft:'0vw',marginTop:'1.18vw',width:'37.6vw',height:'18.6vw',background:'black'}}></Box>
              <Box sx={{marginLeft:'3vw',marginTop:'2.5vw',width:'31.67vw',height:'3vw',background:'black'}}></Box> */}

              {/* <Box sx={{marginLeft:'16.6vw',marginTop:'1.67vw',width:'4.2vw',height:'1.18vw',background:'black'}}>
                <Box sx={{width:'1.18vw',height:'1.18vw',borderRadius:'50%',display:'inline-block',backgroundColor:'#bbb'}}></Box>
                <Box sx={{marginLeft:'.3vw',width:'1.18vw',height:'1.18vw',borderRadius:'50%',display:'inline-block',backgroundColor:'#bbb'}}></Box>
                <Box sx={{marginLeft:'.3vw',width:'1.18vw',height:'1.18vw',borderRadius:'50%',display:'inline-block',backgroundColor:'#bbb'}}></Box>
              </Box> */}

            </Box>

          </Box>
          {/* </Box> */}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <LeftBlockB />
          <Box sx={{ width: '14vw', background: 'inherit', height: '52vw' }}></Box>
          <RightBlockB />
        </Box>
        <Box id={'OurProposition'} sx={{ width: '100%', height: '50vw' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: '36vw' }}>
              <Typography sx={{
                mt: '4.6vw', ml: '7.2vw', color: '#31312C', fontWeight: 300,
                font: "Roboto", textAlign: 'left'
              }} fontSize='4.5vw' lineHeight={1.2} letterSpacing='-1.6px'>
                Our Proposition </Typography>

              <Typography sx={{
                mt: '3vw', ml: '7.2vw', color: '#8f8f8f', fontWeight: '300',
                fontSize: '2.5vw', font: "Roboto"
              }} align='left' lineHeight={1.2} letterSpacing='-1.6px'>
                A plan for your  </Typography>
              <Typography sx={{
                mt: '1vw', ml: '7.2vw', color: '#8f8f8f', fontWeight: '300',
                fontSize: '2.5vw', font: "Roboto"
              }} align='left' lineHeight={1.2} letterSpacing='-1.6px'>
                Employees' needs</Typography>
 {/* Here */}
 <Box sx={{ display: 'flex', marginLeft:'3vw', marginTop: '4.07vw', width: '16.5vw', zIndex: '1',position: 'absolute' }}>
<Box sx={{marginTop:'0.4vw', width: '1.98vw', height: '2.19vw', marginLeft: '4.5vw' }}>
  <img src={platinumSmall} height='100%'></img>
</Box>


<Box sx={{ width: '20vw', height: '0.16vw', marginTop: '0vh' }}>
  <Typography sx={{
    mt: '0vh', ml: '1.54vw', color: '#31312C', fontWeight: 'bold',
    font: "Roboto", textAlign: 'left'
  }} fontSize='2.5vw' lineHeight={1.2}>
    Platinum
  </Typography>
</Box>


</Box>

<Box sx={{ display: 'flex', marginLeft:'3vw', marginTop: '9.07vw', width: '16.5vw', zIndex: '1',position: 'absolute' }}>
<Box sx={{marginTop:'0.4vw', width: '1.98vw', height: '2.19vw', marginLeft: '4.5vw' }}>
  <img src={goldSmall} height='100%'></img>
</Box>


<Box sx={{ width: '20vw', height: '0.16vw', marginTop: '0vh' }}>
  <Typography sx={{
    mt: '0vh', ml: '1.54vw', color: '#31312C', fontWeight: 'bold',
    font: "Roboto", textAlign: 'left'
  }} fontSize='2.5vw' lineHeight={1.2}>
    Gold
  </Typography>
</Box>


</Box>
<Box sx={{ display: 'flex', marginLeft:'3vw', marginTop: '14.07vw', width: '16.5vw', zIndex: '1',position: 'absolute' }}>
<Box sx={{marginTop:'0.4vw', width: '1.98vw', height: '2.19vw', marginLeft: '4.5vw' }}>
  <img src={baseSmall} height='100%'></img>
</Box>


<Box sx={{ width: '20vw', height: '0.16vw', marginTop: '0vh' }}>
  <Typography sx={{
    mt: '0vh', ml: '1.54vw', color: '#31312C', fontWeight: 'bold',
    font: "Roboto", textAlign: 'left'
  }} fontSize='2.5vw' lineHeight={1.2}>
    Base
  </Typography>
</Box>


</Box>
            </Box>
            <Box sx={{ width: '14vw', background: 'inherit', height: '52vw' }}>
              {/* add here----> */}
            </Box>
            {/* <Box sx={{ml:'0vw',width:'50vw'}}> <img src={empright4} alt = "Loading..." width="100%" height="100%" /></Box> */}
            <Box sx={{ ml: '0vw', marginTop: '0vw' }}>
              {/* <img src={empright4} alt = "Loading..." width="100%" heigh="100%" background='inherit'/> */}
              <Box sx={{ marginLeft: '16.3vw', marginTop: '1.07vw', width: '22.88vw', height: '25vw', postion: 'relative', zIndex: '0' }}>
                <img src={employerPartnerHexa1} alt = "Loading..." width="100%" height="100%" />

                <Box sx={{ marginLeft: '4.5vw', marginTop: '-18.66vw', width: '14.2vw', height: '9.2vw', zIndex: '1', position: 'absolute' }}>
                  <Typography sx={{
                    mt: '-0.5vw', ml: '0vw', color: '#31312c',
                    font: "Roboto", textAlign: 'center'
                  }} fontSize='3.3vw' lineHeight={1.2} fontWeight='bold' position='absolute' zIndex='1'>
                    Platinum
                  </Typography>

                </Box>
                <Box sx={{ display: 'flex', marginTop: '-14.07vw', width: '16.5vw', zIndex: '1', marginLeft: '2.2vw', position: 'absolute' }}>




                  <Box sx={{ width: '1.15vw', height: '1.31vw', marginLeft: '4.5vw' }}>
                    <img src={check} height='50%'></img>
                  </Box>


                  <Box sx={{ width: '20vw', height: '0.16vw', marginTop: '0vh' }}>
                    <Typography sx={{
                      mt: '0vh', ml: '.5vw', color: '#31312C', fontWeight: 'bold',
                      font: "Roboto", textAlign: 'left'
                    }} fontSize='1.0vw' lineHeight={1.2}>
                      Online Admin
                    </Typography>
                  </Box>


                </Box>

                <Box sx={{ display: 'flex', marginTop: '-12.07vw', width: '16.5vw', zIndex: '1', marginLeft: '2.2vw', position: 'absolute' }}>





                  <Box sx={{ width: '1.15vw', height: '1.31vw', marginLeft: '4.5vw' }}>
                    <img src={check} height='50%'></img>
                  </Box>


                  <Box sx={{ width: '20vw', height: '0.16vw', marginTop: '0vh' }}>
                    <Typography sx={{
                      mt: '0vh', ml: '.5vw', color: '#31312C', fontWeight: 'bold',
                      font: "Roboto", textAlign: 'left'
                    }} fontSize='1.0vw' lineHeight={1.2}>
                      Personalize Content
                    </Typography>
                  </Box>


                </Box>

                <Box sx={{ display: 'flex', marginTop: '-10.0vw', width: '16.5vw', zIndex: '1', marginLeft: '2.2vw', position: 'absolute' }}>





                  <Box sx={{ width: '1.15vw', height: '1.31vw', marginLeft: '4.5vw' }}>
                    <img src={check} height='50%'></img>
                  </Box>


                  <Box sx={{ width: '20vw', height: '0.16vw', marginTop: '0vh' }}>
                    <Typography sx={{
                      mt: '0vh', ml: '.5vw', color: '#31312C', fontWeight: 'bold',
                      font: "Roboto", textAlign: 'left'
                    }} fontSize='1.0vw' lineHeight={1.2}>
                      Education
                    </Typography>
                  </Box>


                </Box>

                <Box sx={{ display: 'flex', marginTop: '-8.0vw', width: '16.5vw', zIndex: '1', marginLeft: '2.2vw', position: 'absolute' }}>





                  <Box sx={{ width: '1.15vw', height: '1.31vw', marginLeft: '4.5vw' }}>
                    <img src={check} height='50%'></img>
                  </Box>


                  <Box sx={{ width: '20vw', height: '0.16vw', marginTop: '0vh' }}>
                    <Typography sx={{
                      mt: '0vh', ml: '.5vw', color: '#31312C', fontWeight: 'bold',
                      font: "Roboto", textAlign: 'left'
                    }} fontSize='1.0vw' lineHeight={1.2}>
                      Help desk
                    </Typography>
                  </Box>


                </Box>




                {/* <img src={ourPartnerHexa1} alt = "Loading..." width="100%" heigh="100%"/> */}


              </Box>
              <Box sx={{ display: 'flex', marginTop: '-3.5vw' }}>

                <Box sx={{ marginLeft: '5.8vw', marginTop: '-3.07vw', width: '22.88vw', height: '25vw', postion: 'relative', zIndex: '0' }}>

                  <img src={employerPartnerHexa2} alt = "Loading..." width="100%" heigh="100%" style={{ 'position': 'relative' }} />


                  <Box sx={{ marginLeft: '4.5vw', marginTop: '-16.66vw', width: '14.2vw', height: '9.2vw', zIndex: '1', position: 'absolute' }}>
                    <Typography sx={{
                      mt: '-0.5vw', ml: '0vw', color: '#31312c',
                      font: "Roboto", textAlign: 'center'
                    }} fontSize='3.3vw' lineHeight={1.2} fontWeight='bold' position='absolute' zIndex='1'>
                      Gold </Typography>

                    <Box sx={{ display: 'flex', marginTop: '4.07vw', width: '16.5vw', zIndex: '1', marginLeft: '-4.5vw', position: 'absolute' }}>




                      <Box sx={{ width: '1.15vw', height: '1.31vw', marginLeft: '4.5vw' }}>
                        <img src={check} height='50%'></img>
                      </Box>


                      <Box sx={{ width: '20vw', height: '0.16vw', marginTop: '0vh' }}>
                        <Typography sx={{
                          mt: '0vh', ml: '.5vw', color: '#31312C', fontWeight: 'bold',
                          font: "Roboto", textAlign: 'left'
                        }} fontSize='1.0vw' lineHeight={1.2}>
                          Personalized Content
                        </Typography>
                      </Box>


                    </Box>

                    <Box sx={{ display: 'flex', marginTop: '6.07vw', width: '16.5vw', zIndex: '1', marginLeft: '-4.5vw', position: 'absolute' }}>





                      <Box sx={{ width: '1.15vw', height: '1.31vw', marginLeft: '4.5vw' }}>
                        <img src={check} height='50%'></img>
                      </Box>


                      <Box sx={{ width: '20vw', height: '0.16vw', marginTop: '0vh' }}>
                        <Typography sx={{
                          mt: '0vh', ml: '.5vw', color: '#31312C', fontWeight: 'bold',
                          font: "Roboto", textAlign: 'left'
                        }} fontSize='1.0vw' lineHeight={1.2}>
                          Education
                        </Typography>
                      </Box>


                    </Box>

                    <Box sx={{ display: 'flex', marginTop: '8.0vw', width: '16.5vw', zIndex: '1', marginLeft: '-4.5vw', position: 'absolute' }}>





                      <Box sx={{ width: '1.15vw', height: '1.31vw', marginLeft: '4.5vw' }}>
                        <img src={check} height='50%'></img>
                      </Box>


                      <Box sx={{ width: '20vw', height: '0.16vw', marginTop: '0vh' }}>
                        <Typography sx={{
                          mt: '0vh', ml: '.5vw', color: '#31312C', fontWeight: 'bold',
                          font: "Roboto", textAlign: 'left'
                        }} fontSize='1.0vw' lineHeight={1.2}>
                          Help desk
                        </Typography>
                      </Box>


                    </Box>


                  </Box>

                  {/* <img src={ourPartnerHexa1} alt = "Loading..." width="100%" heigh="100%"/> */}

                </Box>

                <Box sx={{ marginLeft: '-1.43vw', marginTop: '-3.07vw', width: '22.88vw', height: '25vw', postion: 'relative', zIndex: '0' }}>

                  <img src={employerPartnerHexa4} alt = "Loading..." width="100%" heigh="100%" style={{ 'position': 'relative' }} />

                  <Box sx={{ marginLeft: '4.5vw', marginTop: '-16.66vw', width: '14.2vw', height: '9.2vw', zIndex: '1', position: 'absolute' }}>
                    <Typography sx={{
                      mt: '-0.5vw', ml: '0vw', color: '#31312c',
                      font: "Roboto", textAlign: 'center'
                    }} fontSize='3.3vw' lineHeight={1.2} fontWeight='bold' position='absolute' zIndex='1'>
                      Silver </Typography>

                    <Box sx={{ display: 'flex', marginTop: '4.07vw', width: '16.5vw', zIndex: '1', marginLeft: '-4.5vw', position: 'absolute' }}>




                      <Box sx={{ width: '1.15vw', height: '1.31vw', marginLeft: '4.5vw' }}>
                        <img src={check} height='50%'></img>
                      </Box>


                      <Box sx={{ width: '20vw', height: '0.16vw', marginTop: '0vh' }}>
                        <Typography sx={{
                          mt: '0vh', ml: '.5vw', color: '#31312C', fontWeight: 'bold',
                          font: "Roboto", textAlign: 'left'
                        }} fontSize='1.0vw' lineHeight={1.2}>
                         Education
                        </Typography>
                      </Box>


                    </Box>

                    <Box sx={{ display: 'flex', marginTop: '6.07vw', width: '16.5vw', zIndex: '1', marginLeft: '-4.5vw', position: 'absolute' }}>





                      <Box sx={{ width: '1.15vw', height: '1.31vw', marginLeft: '4.5vw' }}>
                        <img src={check} height='50%'></img>
                      </Box>


                      <Box sx={{ width: '20vw', height: '0.16vw', marginTop: '0vh' }}>
                        <Typography sx={{
                          mt: '0vh', ml: '.5vw', color: '#31312C', fontWeight: 'bold',
                          font: "Roboto", textAlign: 'left'
                        }} fontSize='1.0vw' lineHeight={1.2}>
                          Help desk
                        </Typography>
                      </Box>


                    </Box>


                  </Box>
                  {/* <img src={ourPartnerHexa1} alt = "Loading..." width="100%" heigh="100%"/> */}

                </Box>
              </Box>

            </Box>


          </Box></Box>
        {/* <Box sx={{ display: 'flex', flexDirection: 'row'}}>
        <LeftBlockC/> 
        <Box sx={{width: '14vw', background:'inherit',height:'52vw' }}></Box>
        <RightBlockC/>
      </Box> */}
        <Box>
          <BottomBox />
        </Box></Box>
    </React.Fragment>
  );
}

function RightBlockA() {
  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',marginLeft:'0.5vw',
      boxShadow: '-1px -6px 46px #0000003D', p: 0, opacity: 1, width: '50vw', height: '52vw', borderRadius: '0.5vw'
    }}>
      <img src={empright1} alt = "Loading..." height='100%' width='100%' borderRadius='0.5vw' />
      <Box sx={{ marginLeft:'7.60vw',marginTop:'-33.1vw', width:'37vw', height:'12.19vw',zIndex:'1',position:'absolute'}}>
          <Typography sx={{mt:'0vw',ml:'0vw',color:'#8f8f8f',
                        font: "Roboto", textAlign:'left'}} fontSize='4.2vw'lineHeight={1.2} fontWeight='bold'position='absolute' zIndex='1'> 
                    The right partner for your workforce </Typography>
          </Box>
    </Box>
  );
}
function RightBlockB() {
  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '-1px -6px 46px #0000003D', opacity: 1, width: '50vw', height: '50vw'
    }}>

<Box sx={{display:'flex',marginTop:'9.04vw'}}>

    <Box sx ={{width:'1.15vw',height:'2.31vh',marginLeft:'8.28vw'}}>
    <img src={bullet} height='100%'></img>
    </Box>
    

    <Box sx={{width:'32vw',marginTop:'0vh'}}>
    <Typography sx={{mt:'0vh', ml:'.5vw',color:'#31312C',
                font: "Roboto", textAlign:'left'}} fontSize='1.3vw'lineHeight={1.2}> 
                Nestfuel offers a fresh approach to benefits, making a transformational difference to your employees
                </Typography>
    </Box>

    
  

</Box>


<Box sx={{display:'flex',marginTop:'9.07vh'}}>
    <Box sx ={{width:'1.15vw',height:'2.31vh',marginLeft:'8.28vw'}}>
    <img src={bullet} height='100%'></img>
    </Box>
    

    <Box sx={{width:'32vw',marginTop:'0vh'}}>
    <Typography sx={{mt:'0vh', ml:'.5vw',color:'#31312C',
                font: "Roboto", textAlign:'left'}} fontSize='1.3vw'lineHeight={1.2}> 
                We focus on homeownership using you, your community, to increase home affordability
                </Typography>
    </Box>

    
  

</Box>

<Box sx={{display:'flex',marginTop:'9.07vh'}}>

    <Box sx ={{width:'1.15vw',height:'2.31vh',marginLeft:'8.28vw'}}>
    <img src={bullet} height='100%'></img>
    </Box>
    

    <Box sx={{width:'32vw',marginTop:'0vh'}}>
    <Typography sx={{mt:'0vh', ml:'.5vw',color:'#31312C',
                font: "Roboto", textAlign:'left'}} fontSize='1.3vw'lineHeight={1.2}> 
               Together we can help your people buy bigger and better homes
                </Typography>
    </Box>

    
  

</Box>
<Box sx={{marginTop:'4.7vw',width:'50vw',height:'18.09vw'}}>
<img src={employerwhyus3} alt = "Loading..." width="100%" height="100%" />

</Box>
{/* employerwhyus3 */}
    </Box>
  );
}

function LeftBlockA() {
  return (
    //   <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box' ,position:'relative',
    //   boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
    //     <Box sx={{ mt:'8.1vw' , ml: '7.2vw', width: '28vw',height:'25vw' }}> 
    //           <Typography sx={{color:'#31312C',
    //            fontSize:'3.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.55,letterSpacing: '-0.13vw'}}> 
    //                Unmatched Benefits
    //           </Typography>
    //           <Typography sx={{color:'#31312C',
    //            fontSize:'3.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.55,letterSpacing: '-0.13vw'}}> 
    //                that
    //           </Typography>
    //           <Typography sx={{color:'#31312C',
    //            fontSize:'3.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.55,letterSpacing: '-0.13vw'}}> 
    //                Empower your Team
    //           </Typography>
    //       </Box>  


    //           <Box sx={{
    //   background: '#F3F3F3 0% 0% no-repeat padding-box',
    //   boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '50vw'
    // }}>
    //     {/* <img src={whyusleftOne} alt = "Loading..." width="100%" height="100%" /> */}
    //     <Box sx={{marginLeft:'6.72vw',marginTop:'7.76vw',width:'15.89vw',height:'12.60vw',fontsize:'4.64vw',font:'Roboto'}}>
    //     <Typography sx={{color:'#8f8f8f', fontWeight:'bold',
    //             font: "Roboto", textAlign:'left'}} fontSize='5vw' lineHeight={1.2} letterSpacing='-1.6px'> 
    //             Inspire Change
    //             </Typography>
    //     </Box>

    //     <Box sx={{marginLeft:'7vw',marginTop:'5.52vw',marginRight:'2.34vw',width:'26.61vw',height:'8.7vw',fontsize:'3vw',font:'Roboto'}}>
    //     <Typography sx={{color:'#8f8f8f',fontWeight:'bold',
    //             font: "Roboto", textAlign:'left'}} fontSize='2.8vw'  lineHeight={1.2} letterSpacing='-1.6px'> 
    //             Bringing social media to bear
    //             </Typography>
    //     </Box>

    //     <Box sx={{width:'36vw',height:'15.68vw'}}>

    //     <img src={whyusleftOne} alt = "Loading..." width="100%" height="100%" />

    //     </Box>

    // </Box>
    //         <LeftbottomA leftbottom={leftbottom}/>
    //   </Box>

    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '35.5vw', height: '50vw'
    }}>
      {/* <img src={whyusleftOne} alt = "Loading..." width="100%" height="100%" /> */}
      <Box sx={{ marginLeft: '7.19vw', marginTop: '7.76vw', width: '20vw', height: '19vw', fontsize: '4.64vw', font: 'Roboto' }}>
        <Typography sx={{
          color: '#8f8f8f', fontWeight: 'bold',
          font: "Roboto", textAlign: 'left'
        }} fontSize='4.2vw' lineHeight={1.2} letterSpacing='-1.6px'>
          Support You & Your Team
        </Typography>
      </Box>

      <Box sx={{ marginLeft: '7vw', marginTop: '12.5vw', marginRight: '2.34vw', width: '26.61vw', fontsize: '3vw', font: 'Roboto' }}>
        <Typography sx={{
          color: '#8f8f8f', fontWeight: 'bold',
          font: "Roboto", textAlign: 'left'
        }} fontSize='2.8vw' lineHeight={1.2} letterSpacing='-1.6px'>

        </Typography>
      </Box>

      <Box sx={{ width: '35.7vw', height: '12.6vw' }}>

        <img src={empleft1} alt = "Loading..." width="100%" height="100%" />

      </Box>

    </Box>
  );
}

function LeftBlockB() {
  return (
    <Box id='OurDifference' sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '50vw'
    }}>
      {/* <img src={empleft3} alt = "Loading..." width='100%' heigh="100%" background='inherit'/>
        */}
      <Box sx={{ width: '36vw' }}>
        <Typography sx={{
          ml: '7.2vw', mt: '8vw', color: '#31312C', fontWeight: 300,
          font: "Roboto", textAlign: 'left'
        }} fontSize='5vw' lineHeight={1.2} letterSpacing='-1.6px'>
          Our Difference
        </Typography>
        <Typography sx={{
          mt: '2vw', ml: '7.2vw', color: '#8f8f8f',
          font: "Roboto", fontWeight: 'light', textAlign: 'left'
        }} fontSize='2.4vw' lineHeight={1.2}>
          Our partners truly care
        </Typography>
        <Typography sx={{
          mt: '2vw', ml: '7.2vw', color: '#8f8f8f',
          font: "Roboto", fontWeight: 'light', textAlign: 'left'
        }} fontSize='2.4vw' lineHeight={1.2}>
          about their employees
        </Typography>


      </Box>
    </Box>
  );
}

function LeftBlockC() {
  return (
    <Box id='Services' sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '52vw'
    }}>
      <Box sx={{ width: '36vw' }}>
        <Typography sx={{
          mt: '4.6vw', ml: '7.2vw', color: '#31312C', fontWeight: 300,
          font: "Roboto", textAlign: 'left'
        }} fontSize='5vw' lineHeight={1.2} letterSpacing='-1.6px'>
          Our Services</Typography>

        <Typography sx={{
          mt: '3vw', ml: '7.2vw', color: '#8f8f8f', fontWeight: '300',
          fontSize: '2.5vw', font: "Roboto"
        }} align='left' lineHeight={1.2} letterSpacing='-1.6px'>
          Comprehensive </Typography>
        <Typography sx={{
          mt: '1vw', ml: '7.2vw', color: '#8f8f8f', fontWeight: '300',
          fontSize: '2.5vw', font: "Roboto"
        }} align='left' lineHeight={1.2} letterSpacing='-1.6px'>
          solutions for you </Typography>
        <Typography sx={{
          mt: '1vw', ml: '7.2vw', color: '#8f8f8f', fontWeight: '300',
          fontSize: '2.5vw', font: "Roboto"
        }} align='left' lineHeight={1.2} letterSpacing='-1.6px'>
          Employer & Employees </Typography>
      </Box>
    </Box>
  );
}

function InputKey() {

  const name = 'password'
  const type = 'password'
  const [cur, setCur] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);


  function onClickFun(e) {
    const val1 = e.currentTarget.value
    val1 === "N3$tfu3L" ? allowed = 'yes' : allowed = 'no'

  };

  return (
    <div position='fixed' align='center'>
      <Typography sx={{ fontSize: '1vw', width: '12vw' }}>(Please answer the following to activae DISCOVER NESTFUEL)</Typography>
      <Typography sx={{ mt: 2, fontSize: '1vw', width: '12vw' }}></Typography>
      <TextField sx={{ fontsize: '0.5vw', mt: 1, width: '12vw', border: 1, borderColor: 'black', borderRadius: 2 }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label='No flying ends Indian penny'
        autoFocus={true}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </div>
  )
}

export default EmployerPartnershipWhyUs;