import * as React from 'react';
import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import nestfuelImage from '../../assets/svg/nestfuel_nav.svg';
import useStyles from './styles';
import { initializeApp, getApp } from 'firebase/app';
import { getAuth,signOut } from "firebase/auth";
import { updateFlag,updateEmployer, borrowerProfile, borrowerGetUserData,breadcrumbData} from "../../actions/borrower";
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT,LOGGEDIN, ISSIGNIN, _debug, LOAD_STATE } from '../../constants';
import InputBase from '@mui/material/InputBase';
const pages = ['Home', 'Properties', 'Why US', 'SignUP', 'Download App'];
import { getAnalytics,logEvent } from "firebase/analytics";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography,Badge
} from '@mui/material';

import { styled, alpha } from '@mui/material/styles';
//import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Link from '@mui/material/Link';
import { rspvw } from "./styles";

const firebaseConfig = _debug === 'development' 
    ?{
      apiKey            : process.env.REACT_APP_GOOGLE_AUTH_API_KEY_UAT,
      authDomain        : process.env.REACT_APP_GOOGLE_AUTH_DOMAIN_UAT,
      projectId         : process.env.REACT_APP_GOOGLE_AUTH_PROJECTID_UAT,
      storageBucket     : process.env.REACT_APP_GOOGLE_AUTH_BUCKET_UAT,
      messagingSenderId : process.env.REACT_APP_GOOGLE_AUTH_MSG_SNDR_ID_UAT,
      appId             : process.env.REACT_APP_GOOGLE_AUTH_APP_ID_UAT,
      measurementId     : process.env.REACT_APP_GOOGLE_AUTH_MSRMT_ID_UAT
    }
    :{
        apiKey            : process.env.REACT_APP_GOOGLE_AUTH_API_KEY,
        authDomain        : process.env.REACT_APP_GOOGLE_AUTH_DOMAIN,
        projectId         : process.env.REACT_APP_GOOGLE_AUTH_PROJECTID,
        storageBucket     : process.env.REACT_APP_GOOGLE_AUTH_BUCKET,
        messagingSenderId : process.env.REACT_APP_GOOGLE_AUTH_MSG_SNDR_ID,
        appId             : process.env.REACT_APP_GOOGLE_AUTH_APP_ID,
        measurementId     : process.env.REACT_APP_GOOGLE_AUTH_MSRMT_ID
    }  
    
let app
try{ app = getApp()}catch(error){ app = initializeApp(firebaseConfig)}
const auth = getAuth(app)
const analytics = getAnalytics(app);



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));

const iconBackColorOpen = 'grey.300';



const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


export const NavBarDashBoardUpdate = () => {
  
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const[breadCrumbVal, setBreadCrumbVal]= React.useState("");
  
  
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const handleClose = (event) => {
    setAnchorEl(null);
    if(event.target.value == 1){
      dispatch(breadcrumbData("Your Material"));
      navigate("/EmployerCompanyMaterial", { replace: false }) 
    }else if(event.target.value == 2){
      dispatch(breadcrumbData("Your Calculator"));
      navigate("/EmployerCompanyCal", { replace: false }) 
    }
    

  };
  
  
  
  const dispatch = useDispatch();
  const { bProfile,isLoggedin, navFlag,navEmployer} = useSelector((state)=>state.borrower);  
  const [user, setUser] = React.useState(getAuth().currentUser)
  //const [user,setUser] = React.useState(auth.currentUser);
  //if(_debug === 'development') console.log("Firebase analytics",analytics)
  //if(_debug === 'development') console.log("Firebase Auth",auth)
  logEvent(analytics, 'notification_received');
  !isLoggedin && user && dispatch({ type: LOGGEDIN })
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
 // const [open, setOpen] = useState(false);
const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
};


const handleClickTeam=(event)=> {
  event.preventDefault();
  dispatch(breadcrumbData("Your Team"));
  navigate("/EmployerTeam", { replace: false }) 
  console.info('You clicked a breadcrumb.');
}

const handleClickContract=(event)=> {
  event.preventDefault();
  dispatch(breadcrumbData("Your Admin"));
  navigate("/EmployerContract", { replace: false }) 
  console.info('You clicked a breadcrumb.');
}


const handleClickDashboard=(event)=> {
  event.preventDefault();
  dispatch(breadcrumbData("Your Dashboard"));
  navigate("/EmployerDashboard", { replace: false }) 
  console.info('You clicked a breadcrumb.');
}
const handleClickRes = (event) => {
  //setAnchorEl(event.currentTarget);
 // event.preventDefault();
// navigate("/EmployerResources", { replace: false }) 
 navigate("/EmployerResources", { replace: false }) 
 console.info('You clicked a resource menu.');
};



  //if(_debug === 'development') console.log("User",user)
  const logout = () => {
    signOut(auth).then(()=>{
      dispatch({ type: LOGOUT })
      window.sessionStorage.clear();
      setUser(null);
      navigate("/", { replace: true });
    }).catch((error)=>{console.log("Signout Error",error)})
    
   
  };

  
  React.useEffect(() => {
    if(auth.currentUser !==null){
      setUser(auth.currentUser)
      console.log("user",user)
      var displayName = auth.currentUser?.displayName?.indexOf(' ') >=0 ? auth.currentUser?.displayName?.split(" ") : [auth.currentUser?.displayName,'']
      if(displayName.length >=2) dispatch(borrowerProfile({...bProfile,firstName:displayName[0],lastName:displayName[1],emailId:auth.currentUser.email,userId:auth.currentUser.uid,photoURL:auth.currentUser.photoURL})); 
      if(displayName.length ===1) dispatch(borrowerProfile({...bProfile,firstName:displayName[0],lastName:"",emailId:auth.currentUser.email,userId:auth.currentUser.uid,photoURL:auth.currentUser.photoURL})); 
    }else{
      setUser(null)
    }
  }, [auth.currentUser]);

  const demo =()=>{
    dispatch(updateFlag(true))
    // setStateUpdate(1)
    //setCheck(navFlag) 
    navigate("/DashboardProfile", { replace: false }) 
  }
  










  return (
    <>
    {
 <Stack spacing={0} direction="row" justifyContent="space-between" backgroundColor="#F0F0F0">
<Box sx={{width:`${rspvw(691.2)}`, height:`${rspvw(85.5)}`}}>
<Stack sx={{mt:'0vw'}} spacing='1vw' direction="row"> 
<Box sx={{ml:`${rspvw(141)}` , width:`${rspvw(247.6)}`, height:`${rspvw(84.4)}`}}> 
  <img style={{ verticalAlign:'revert', paddingTop:`${rspvw(19.8)}`,paddingBottom:`${rspvw(19.8)}`}} src={nestfuelImage} alt="no image found" height='100%'/>
</Box>
<Typography  sx={{pt:'1.5vw'}} fontSize='0.9375vw' color='#000000' width='16vw'textAlign='left'>Democratizing Home Ownership</Typography>  
</Stack>
</Box>

<Box sx={{width:`${rspvw(768)}`,height:`${rspvw(77.7)}`,mr:`${rspvw(141)}`}} className={classes.appBar} >
<Box sx={{mt:'0vh'}}  className={classes.breadCrumbMenuA}><Link to="/EmployerDashboard" className='yourdashboard'  onClick={handleClickDashboard}> Your Dashboard </Link></Box>
<Box sx={{mt:'0vh'}} className={classes.breadCrumbMenuB}><Link to="/EmployerTeam"  className='yourteam'  onClick={handleClickTeam}> Your Team </Link></Box>
<Box sx={{mb:'0vw'}} className={classes.breadCrumbMenuC}>
<Link to="/"   sx={{textDecoration:'none'}}>
<Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        //onClick={handleClickRes}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon/>}
        sx={{textDecoration:'none',mt:`${rspvw(2)}`, fontWeight:'Bold', fontSize:`${rspvw(16)}`, textTransform: 'none', font:'Roboto', color:'#31312C', "&.MuiButtonBase-root:hover": {
          color: '#0d6efd'
          }}}
      
         
      >
    Your Resources
      </Button> 
      </Link>
      </Box>  
     
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple value={1}>
          Company Materials
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple value={2}>
          Company Calculator
        </MenuItem>
        {/* <Divider sx={{ my: 0.5 }} /> */}
      </StyledMenu>
     
<Box sx={{mt:'0vh'}} className={classes.breadCrumbMenuD}>
<Link to="/EmployerContract" className='youradmin' onClick={handleClickContract}> Your Admin </Link>
</Box>
 
</Box>

</Stack>
    }
    
    </>
  );
  
};

// export function Backtohistory() {
//   //const navigate = useNavigate();
//   console.log('remove_hash_from_url - clicked')
// 	var uri = window.location.toString();
// 	if (uri.indexOf("#messenger") > 0) {
// 		var clean_uri = uri.substring(0, uri.indexOf("#messenger"));
//     console.log(clean_uri)
// 		//navigate(clean_uri, { replace: false });
// 	}
// }

export default NavBarDashBoardUpdate;
