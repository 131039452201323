import { parsePhoneNumber } from "react-phone-number-input";
import { get } from "lodash";

// ******************************
export const validator = (values, fieldName) => {
  let errors = {};
  switch (fieldName) {
    case "firstName":
      validateFirstName(values.firstName, errors);
      break;
    case "lastName":
      validateLastName(values.lastName, errors);
      break;
    case "birthDate":
      validateBirthDate(values.birthDate, errors);
      break;
      case "gender":
      validateGender(values.gender, errors);
      break;

      case "address":
      validateAddress(values.address, errors);
      break;

      case "position":
      validatePosition(values.position, errors);
      break;
      case "startDate":
        validatestartDate(values.startDate, errors);
        break;
        case "department":
          validateDepartment(values.department, errors);
          break;
    default:
  }
  return errors;
};

// ******************************
export function getCountryCode(phoneNumber) {
  return get(parsePhoneNumber(phoneNumber), "country");
}

// ******************************
function validatePhoneNumber(phone, errors) {
  let result = true;
  const phoneObject = parsePhoneNumber(phone);
  if (!phoneObject) {
    errors.phone = "Invalid Phonenumber";
    result = false;
  }
  return result;
}
// ******************************
function validateEmail(email, errors) {
  let result = true;

  if (!email) {
    errors.email = "Email is Required";
    result = false;
  } else {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    result = re.test(String(email).toLowerCase());
    if (!result) errors.email = "Invalid Email address";
  }
  return result;
}


// ******************************
function validateFirstName(firstName, errors) {
  let result = true;

  if (!firstName) {
    errors.firstName = "First Name is Required";
    result = false;
   } //else {
  //   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   result = re.test(String(email).toLowerCase());
  //   if (!result) errors.email = "Invalid Email address";
  // }
  return result;
}


// ******************************
function validateLastName(lastName, errors) {
  let result = true;

  if (!lastName) {
    errors.lastName = "Last Name is Required";
    result = false;
   } //else {
  //   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   result = re.test(String(email).toLowerCase());
  //   if (!result) errors.email = "Invalid Email address";
  // }
  return result;
}


// ******************************
function validateBirthDate(birthDate, errors) {
  let result = true;

  if (!birthDate) {
    errors.birthDate = "Birth Date is Required";
    result = false;
   } //else {
  //   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   result = re.test(String(email).toLowerCase());
  //   if (!result) errors.email = "Invalid Email address";
  // }
  return result;
}


// ******************************
function validateGender(gender, errors) {
  let result = true;

  if (!gender) {
    errors.lastName = "Gender is Required";
    result = false;
   } //else {
  //   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   result = re.test(String(email).toLowerCase());
  //   if (!result) errors.email = "Invalid Email address";
  // }
  return result;
}


// ******************************
function validateAddress(address, errors) {
  let result = true;

  if (!address) {
    errors.address = "Address is Required";
    result = false;
   } //else {
  //   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   result = re.test(String(email).toLowerCase());
  //   if (!result) errors.email = "Invalid Email address";
  // }
  return result;
}


// ******************************
function validatePosition(position, errors) {
  let result = true;

  if (!position) {
    errors.position = "Position is Required";
    result = false;
   } //else {
  //   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   result = re.test(String(email).toLowerCase());
  //   if (!result) errors.email = "Invalid Email address";
  // }
  return result;
}


// ******************************
function validatestartDate(startDate, errors) {
  let result = true;

  if (!startDate) {
    errors.startDate = "startDate is Required";
    result = false;
   } //else {
  //   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   result = re.test(String(email).toLowerCase());
  //   if (!result) errors.email = "Invalid Email address";
  // }
  return result;
}


// ******************************
function validateDepartment(department, errors) {
  let result = true;

  if (!department) {
    errors.department = "Department is Required";
    result = false;
   } //else {
  //   const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   result = re.test(String(email).toLowerCase());
  //   if (!result) errors.email = "Invalid Email address";
  // }
  return result;
}



// ******************************
function validatePassword(pass, errors) {
  let result = true;

  if (!pass) {
    errors.password = "Password is Required";
    result = false;
  } else {
    var lower = /(?=.*[a-z])/;
    result = lower.test(pass);

    if (!result) {
      errors.password = "Password must contain at least one lower case letter.";
      result = false;
    } else if (pass.length < 8) {
      errors.password = "Your password has less than 8 characters.";
      result = false;
    }
  }

  return result;
}
