import React from "react";
import { Slider, Box, CircularProgress,CssBaseline, Container, Typography, Button,Stack} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ItemsCarousel from "react-items-carousel";
import { useDispatch, useSelector } from 'react-redux';
import leftbottom from '../../assets/images/image-66@1x.png'
import { borrowerfinOpt, borrowerLT, borrowerRIM, borrowerPP, borrowerIO, borrowerPBPR } from "../../actions/borrower";
import { Centerbottom } from "./RightBlock";
import { LeftBlockMsg } from "./Messenger";

const DebugOutput = false;
function withCommas(x) {return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};


function AdjustOptions() {
  const { finOptP,
          finOptS,
          isLoading,
          adjust} = useSelector((state)=>state.borrower);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [review,setReview] = React.useState(''); 

  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "ReviewA": 
        dispatch(borrowerfinOpt({...finOptS, property:'selected'}));
        setReview('reviewA');
        break;
      case "ReviewB": 
        dispatch(borrowerfinOpt({...finOptS, property:'proposed'}))
        setReview('reviewB');
        break;
      case "AdjustA": 
        setReview('adjustA');
        break;
      case "AdjustB": 
        setReview('adjustB');
        break;
      case "StartA" : 
        navigate("/SummaryApplication",{ replace: false }); 
        break;
      default: break;
    }
  }

  return(
    isLoading ? <CircularProgress /> : (
        <React.Fragment>
          <CssBaseline/>
          <Box sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
            <Navbar pathname={location.pathname}/>
            <Box sx={{ display: 'flex', flexDirection: 'row', borderRadius: 1,}}>
            {
              
                adjust === 'selected' && finOptS.fin.label === 'Interest Only'? <LeftBlockIOS/>
              : adjust === 'selected' && finOptS.fin.label === 'Pay Points'? <LeftBlockPPS/>
              : adjust === 'selected' && finOptS.fin.label === 'Reduced Initial Monthly'? <LeftBlockRIMS/>
              : adjust === 'selected' && finOptS.fin.label === 'Part Buy Part Rent'? <LeftBlockPBPRS/>
              : adjust === 'selected' && finOptS.fin.label === 'Longer Term'? <LeftBlockLTS/>
              : adjust === 'proposed' && finOptP.fin.label === 'Interest Only'? <LeftBlockIOP/>
              : adjust === 'proposed' && finOptP.fin.label === 'Pay Points'? <LeftBlockPPP/>
              : adjust === 'proposed' && finOptP.fin.label === 'Reduced Initial Monthly'? <LeftBlockRIMP/>
              : adjust === 'proposed' && finOptP.fin.label === 'Part Buy Part Rent'? <LeftBlockPBPRP/>
              : adjust === 'proposed' && finOptP.fin.label === 'Longer Term'? <LeftBlockLTP/>
              :<></>
            }
         <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center', position:'relative'}}>
              <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> 
                .... </Typography>
              <div align='center'>
              <Stack sx={{mt:'3.43vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="Confirm" className='hexBtnCenter' onClick={()=>navigate(-1)}> 
                  <HexButton titleText="Confirm" type='confirm' fontSize='24'/></Button>
              </Stack>
              <Centerbottom navigateForward='-1'/>
              </div>
          </Box>
          <RightBlock/>
      </Box>
    </Box>
    </React.Fragment>
  ));
  
}

  function RightBlock() {
    const { bChoiceIone,
            bChoiceItwo,
            selectedProp,
            proposedProp,
            adjust
          } = useSelector((state)=>state.borrower);
  
    var ObjArrayA = Object.entries(bChoiceIone);
    var ObjArrayB = Object.entries(bChoiceItwo);
    if(DebugOutput) console.log("Objects to be sent to Table",ObjArrayA,ObjArrayB);

    return (
    <React.Fragment>
    <CssBaseline/>
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '50vw',height:'46.75vw' }}>
          <Box sx={{mr:0,ml:0, p:.5,background:'#31312C 0% 0% no-repeat padding-box', display: 'flex', justifyContent:'center',flexDirection: 'row',width: "100%",height:'18vw' ,border:1, borderRadius:0}}>
              <Box sx={{mr:1.5,background:'#FFFFFF 0% 0% no-repeat padding-box', width: "30vw",height:"30vh", borderRadius:2,border:1,borderColor:'#FFF'}}>
                  <Typography align='center'fontSize='1.1vw' color='#000000'>Option-A</Typography>
                  <img src={selectedProp?.prop.thumbnail} width="100%" height="70%"/>
                  <Stack sx={{ml:2, mr:2,mb:0}} spacing={0} direction="row" justifyContent="space-between"> 
                <Typography mt='0.8vh' fontSize='1.1vw' fontWeight='bold' color='black' align='center'>${withCommas(selectedProp?.prop.offerPrice)}</Typography>
                <Stack direction="column">
                  <Typography fontSize='0.9vw' color='black' align='center'> {selectedProp?.prop.address}</Typography>
                  <Typography fontSize='0.9vw' color='black' align='center'> {selectedProp?.prop.city}, {selectedProp?.prop.state} - {selectedProp?.prop.zipcode}</Typography>
                </Stack>
              </Stack>
              </Box>
              <Box sx={{background:'#FFFFFF 0% 0% no-repeat padding-box', width: "18vw",height:"30vh", borderRadius:2,border:1,borderColor:'#FFF'}}>
                  <Typography align='center'fontSize='1.1vw' color='#000000'>Option-B </Typography>
                  <img src={proposedProp?.prop.thumbnail} width="100%" height="80%"/>
                  <Stack sx={{ml:2, mr:2,mb:0}} spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography mt='0.8vh' fontSize='1.1vw' fontWeight='bold' color='black' align='center'>${withCommas(proposedProp?.prop.offerPrice)}</Typography>
                    <Stack direction="column">
                      <Typography fontSize='0.9vw' color='black' align='center'> {proposedProp?.prop.address}</Typography>
                      <Typography fontSize='0.9vw' color='black' align='center'> {proposedProp?.prop.city}, {proposedProp?.prop.state} - {proposedProp?.prop.zipcode}</Typography>
                    </Stack>
                  </Stack>
              </Box>
          </Box>
          <Box sx={{background:'#31312C 0% 0% no-repeat padding-box' , border:1, borderRadius:2,justifyContent:"space-around", display: 'flex', flexDirection: 'row'}}>
          <Box sx={{ ml:'2wh',mt:'2vh',background:'inherit', width: '12vw',height:'58vh',borderRadius:2}}>
            <ImageTextCardA/>
          </Box>
             <Box sx={{ ml:'3wh',mt:'2vh',background:'inherit', width: '12vw',height:'58vh',borderRadius:2}}>
                    <OuterCarousel  ObjArray = {ObjArrayA} propertyPrice={selectedProp?.prop.price}
                    CardElement={selectedProp?.prop} _uScore='Y'/>
              </Box>
              <Box sx={{ mr:'2vw',mt:'2vh',bgcolor: 'inherit', width: '12vw',height:'58vh'}}>
                    <OuterCarousel  ObjArray = {ObjArrayB} propertyPrice={proposedProp?.prop.price }
                    adjust={adjust} CardElement={proposedProp?.prop} _uScore='N'/>
              </Box>
            </Box>
        </Box>
      </React.Fragment>
    );
  }

function OuterCarousel(props) {
    const {ObjArray,propertyPrice,adjust,CardElement,_uScore} = props;
    const { finOptP,
            finOptS}= (useSelector((state)=>state.borrower));
    const dispatch = useDispatch();
    const [active, setActive] = React.useState(_uScore==='Y' 
                                ? finOptS.option
                                : finOptP.option);
    if(DebugOutput) ObjArray.map((item) =>console.log(" Item ",item[1]))

    return (
      <div className="OuterCarousel">
        <ItemsCarousel
          autoPlay={true}
          infiniteLoop={true}
          gutter={0}
          timeout={1}
          activePosition={"center"}
          chevronWidth={5}
          disableSwipe={false}
          alwaysShowChevrons={false}
          numberOfCards={1}
          slidesToScroll={1}
          outsideChevron={false}
          showSlither={true}
          firstAndLastGutter={false}
          activeItemIndex={active}
          requestToChangeActive={(value) => {
            if(adjust === ""){
              if( value >=0 && value < ObjArray.length ) setActive(value)
              if(value <0 ) setActive(ObjArray.length -1)
              if(value >= ObjArray.length) setActive(0)
            } else {
              _uScore==='Y' ? setActive(finOptS.option)
              : setActive(finOptP.option)
            } 
       }
    }
          rightChevron={<ArrowForwardIosIcon sx={{mt:'-25vw',color:"#FFFFFF"}}/>}
           leftChevron={<ArrowBackIosIcon    sx={{mt:'-25vw',color:"#FFFFFF"}}/>}
        >
        { ObjArray.map((item) =>
        <Box sx={{mt:0, p:'0,1', width:"100%" ,border:0, borderColor:'#000000', borderRadius:2} } >
            <ImageTextCard CardElement={CardElement} financeOption={item[1]} propertyPrice={propertyPrice}/>
          </Box>)}
        </ItemsCarousel>
      </div>
    );
}

function ImageTextCardA(){

  return (
    <>
    <Stack spacing={1} direction="column" width='12vw'ml='2vw'>
        <Typography align='left'  fontSize='1.2vw'  color='white' > Finance Option </Typography>
        <Typography align='left'  fontSize='1vw'    color='white' > Property Price</Typography>
        <Typography align='left'  fontSize='1vw'    color='white' > Loan Amount</Typography>
        <Typography align='left'  fontSize='1vw'    color='white' > Upfront</Typography>
        <Typography align='left'  fontSize='1vw'    color='white' > Monthly</Typography>
        <Typography align='left'  fontSize='1vw'    color='white' > Rate</Typography>
        <Typography align='left'  fontSize='1vw'    color='white' > Monthly EMI from 2022</Typography>
        <Typography align='left'  fontSize='1vw'    color='white' > Monthly EMI from 2025</Typography>
        <Typography align='left'  fontSize='1vw'    color='white' > Monthly EMI from 2028</Typography>
        <Typography align='left'  fontSize='1vw'    color='white' > Loan Term</Typography>
        <Typography align='left'  fontSize='1vw'    color='white' > Points</Typography>
        <Typography align='left'  fontSize='1vw'    color='white' > Point Cost</Typography>
        <Typography align='left'  fontSize='1vw'    color='white' > Percent Ownership</Typography>
    </Stack>
  </>
  );
}

function ImageTextCard(props){

  const { CardElement,
         financeOption,
        propertyPrice} = props; 
return (
    <>
      <Stack spacing={1} direction="column" width='12vw'>
          <Typography align='center'  fontSize='1.2vw'  color='white' > {financeOption?.label} </Typography>
          <Typography align='center'  fontSize='1vw'    color='white' > $ {withCommas(propertyPrice)}</Typography>
          <Typography align='center'  fontSize='1vw'    color='white' > $ {withCommas(financeOption?.loanAmount)}</Typography>
          <Typography align='center'  fontSize='1vw'    color='white' > $ {withCommas(financeOption?.upfront)}</Typography>
          <Typography align='center'  fontSize='1vw'    color='white' > $ {withCommas(financeOption?.monthlyEMI)}</Typography>
          <Typography align='center'  fontSize='1vw'    color='white' > {financeOption?.interestRate.toFixed(2)}%</Typography>
          <Typography align='center'  fontSize='1vw'    color='white' > $ {withCommas(financeOption?.monthlyUnsecureTermEMI)}</Typography>
          <Typography align='center'  fontSize='1vw'    color='white' > $ {withCommas(financeOption?.monthlyEMIPostUnsecureTerm)}</Typography>
          <Typography align='center'  fontSize='1vw'    color='white' > $ {withCommas(financeOption?.monthlyUnsecureTermEMI)}</Typography>
          <Typography align='center'  fontSize='1vw'    color='white' > {financeOption?.loanTerm} years</Typography>
          <Typography align='center'  fontSize='1vw'    color='white' > {financeOption?.points}</Typography>
          <Typography align='center'  fontSize='1vw'    color='white' > $ {withCommas(Math.ceil(financeOption?.points*0.01*financeOption?.loanAmount))}</Typography>
          <Typography align='center'  fontSize='1vw'    color='white' > {financeOption?.percentOwnership}%</Typography>
      </Stack>
    </>
    );
}


function LeftBlockPBPRS() {
  
  const { selectedProp,
          bChoiceB,
          bfinancialProfile,
          bChoiceIone,
          adjust,
          finOptS
        } = useSelector((state)=>state.borrower);
  const propOpt = adjust ;
  const dispatch = useDispatch();
  
  const [value,setValue]    = React.useState(finOptS?.fin.percentOwnership);
  const [valueA, setValueA] = React.useState(finOptS?.fin.upfront)
  const navigate = useNavigate();

  var propFinRequest = {
      propertyPrice     : selectedProp?.prop.offerPrice,
      financeType       : bChoiceB === "firsthome"? 'primary' : 'secondary',
      creditScore       : bfinancialProfile?.income.ficoScore,
      annualIncome      : bfinancialProfile?.income.annualIncome,
      monthlyOutgoing   : bfinancialProfile?.income.outgoing,
      monthlyBudget     : bfinancialProfile?.income.monthlyBudget, 
      financeDocs       : bfinancialProfile?.financeDocs.docsAll,
      upfront           : bfinancialProfile?.income.upfront,
      loanTerm          : bfinancialProfile?.finance.loanTerm,
      creditEvents      : bfinancialProfile?.finance.creditEvents,
      houseEvents       : bfinancialProfile?.finance.houseEvents,
      percentOwnership  : 50,
      emailId           : 'vivek@gmail.com',
  };
    if(DebugOutput) console.log("Part buy part rent Finance Request",propFinRequest);

  React.useEffect(()=>{
    propFinRequest.percentOwnership = value;
    dispatch(borrowerPBPR(propFinRequest,propOpt));
    setValueA(bChoiceIone[4].upfront)
  },[value])

  React.useEffect(()=>{
    propFinRequest.upfront = valueA;
    dispatch(borrowerPBPR(propFinRequest,propOpt));
    setValue(bChoiceIone[4].percentOwnership)
  },[valueA])
  
  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'90vh' }}>
            <Box sx={{ ml:'8.3vw', mt:'11.25vw' , width:'20vw',background:'inherit', height: '50vh' }}> 
              <div>
              
                <Typography fontSize='1.4vw'color='#000000' textAlign='left'> Upfront ?</Typography>
                <Box sx={{p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                    <Stack spacing={0} direction="row" justifyContent="space-between">
                        <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                        ${withCommas(finOptS?.fin.filters[1].start)}
                        </Typography>
                        <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                        ${withCommas(valueA)}
                        </Typography>
                        <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                        ${withCommas(finOptS?.fin.filters[1].end)}
                        </Typography>
                      </Stack>
                </Box>
                  <Slider sx={{ width:'25vw',mt:2,color:'#000000'}} value={valueA} onChange={(event, newValue) => setValueA(newValue)}
                  aria-labelledby="input-slider"
                   min={finOptS?.fin.filters[1].start} max={finOptS?.fin.filters[1].end} step={1000}/>
             
              </div>
              <div>
             
                <Typography fontSize='1.4vw'color='#000000' textAlign='left'> Percent Ownership?</Typography>
                <Box sx={{ p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                <Stack spacing={0} direction="row" justifyContent="space-between">
                  <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                  {finOptS?.fin.filters[0].start}%
                  </Typography>
                  <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                  {value}%
                  </Typography>
                  <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                  {finOptS?.fin.filters[0].end}%
                  </Typography>
                  </Stack>
                  </Box>
                  <Slider sx={{width:'25vw',mt:2,color:'#000000'}} value={value} onChange={(event, newValue) => setValue(newValue)}
                  aria-labelledby="input-slider"
                   min={finOptS.fin.filters[0].start} max={finOptS.fin.filters[0].end} step={17}/>
             
              </div>
          </Box>
    </Box>
  );
}

function LeftBlockPBPRP() {
  
  const { bfinancialProfile,
          bChoiceB,
          selectedProp,
          bChoiceItwo,
          finOptP,
          adjust} = (useSelector((state)=>state.borrower));
  const propOpt = adjust ;
  const dispatch = useDispatch();
  
  const [value,setValue] = React.useState(finOptP.fin.percentOwnership);
  const [valueA, setValueA] = React.useState(finOptP.fin.upfront)
  const navigate = useNavigate();

  var propFinRequest = {
    propertyPrice    : selectedProp?.prop.offerPrice,
    financeType      : bChoiceB === "firsthome"? 'primary' : 'secondary',
    loanTerm         : bfinancialProfile?.finance.loanTerm,
    creditScore      : bfinancialProfile?.income.ficoScore,
    annualIncome     : bfinancialProfile?.income.annualIncome,
    monthlyOutgoing  : bfinancialProfile?.income.monthlyOutgoing,
    upfront          : bfinancialProfile?.income.upfront,
    financeDocs      : bfinancialProfile?.financeDocs.docsAll,
    creditEvents     : bfinancialProfile?.finance.creditEvents,
    houseEvents      : bfinancialProfile?.finance.houseEvents,
    monthlyBudget    : bfinancialProfile?.income.monthlyBudget, 
    emailId          : 'vivek@gmail.com',
  };
  React.useEffect(()=>{
    propFinRequest.percentOwnership = value;
    dispatch(borrowerPBPR(propFinRequest,propOpt));
    setTimeout(() =>{setValueA(bChoiceItwo[4]?.upfront)},1000)
    
  },[value])

  React.useEffect(()=>{
    propFinRequest.upfront = valueA;
    dispatch(borrowerPBPR(propFinRequest,propOpt));
    setValue(bChoiceItwo[4]?.percentOwnership)
  },[valueA])
  
  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'90vh' }}>
            <Box sx={{ ml:'8.3vw', mt:'11.25vw' , width:'20vw',background:'inherit', height: '50vh' }}> 
              <div>
             
                <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Upfront ?</Typography>
                <Box sx={{p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                    <Stack spacing={0} direction="row" justifyContent="space-between">
                        <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                        ${withCommas(finOptP?.fin.filters[1].start)}
                        </Typography>
                        <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                        ${withCommas(valueA)}
                        </Typography>
                        <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                        ${withCommas(finOptP?.fin.filters[1].end)}
                        </Typography>
                      </Stack>
                </Box>
                  <Slider sx={{ width:'25vw',mt:2,color:'#000000'}} value={valueA} onChange={(event, newValue) => setValueA(newValue)}
                  aria-labelledby="input-slider"
                   min={finOptP?.fin.filters[1].start} max={finOptP?.fin.filters[1].end} step={1000}/>
             
              </div>
              <div>
              
                <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Percent Ownership ?</Typography>
                <Box sx={{ p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                <Stack spacing={0} direction="row" justifyContent="space-between">
                  <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                  {finOptP?.fin.filters[0].start}%
                  </Typography>
                  <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                  {value}%
                  </Typography>
                  <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                  {finOptP?.fin.filters[0].end}%
                  </Typography>
                  </Stack>
                  </Box>
                  <Slider sx={{mt:2,width:'25vw',color:'#000000'}} value={value} onChange={(event, newValue) => setValue(newValue)}
                  aria-labelledby="input-slider"
                   min={finOptP?.fin.filters[0].start} max={finOptP?.fin.filters[0].end} step={17}/>
              
              </div>
          </Box>
          <Box sx={{ bottom:'0', position:'absolute', height:'11.4vw',width: 'inherit' }}>  
              <img src={leftbottom} alt = "Loading..." width="100%" height="100%"/> 
          </Box>
    </Box>
  );
}

function LeftBlockLTS() {
  
  const { adjust,
          finOptS,
          bChoiceB,
          bChoiceE,
          selectedProp,
          bChoiceIone,
          bfinancialProfile
        } = useSelector((state)=>state.borrower);
  const propOpt = adjust
  const dispatch = useDispatch();
  
  const [value,setValue] = React.useState(finOptS.loanTerm);
  const [valueA,setValueA] = React.useState(finOptS.monthlyEMI);
  const navigate = useNavigate();

  var propFinRequest = {
    propertyPrice     : selectedProp?.offerPrice,
    financeType       : bChoiceB === "firsthome"? 'primary' : 'secondary',
    loanTerm          : bChoiceE?.loanTerm,
    creditScore       : bfinancialProfile?.ficoScore,
    annualIncome      : bfinancialProfile?.annualIncome,
    monthlyOutgoing   : bfinancialProfile?.monthlyOutgoing,
    upfront           : bChoiceE?.upfront,
    financeDocs       : bfinancialProfile?.financeDocs.docsAll,
    creditEvents      : bChoiceE?.creditEvents,
    houseEvents       : bChoiceE?.houseEvents,
    monthlyBudget     : bChoiceE?.monthlyBudget,
    percentOwnership  : 100,
    emailId           : 'vivek@gmail.com'
  };
    if(DebugOutput) console.log("Part buy part rent Finance Request",propFinRequest);

  React.useEffect(()=>{
    propFinRequest.loanTerm = value;
    dispatch(borrowerLT(propFinRequest,propOpt));
    setValueA(bChoiceIone[5]?.monthlyEMI)
  },[value])

  React.useEffect(()=>{
    propFinRequest.monthlyBudget = valueA;
    dispatch(borrowerLT(propFinRequest,propOpt));
    setValue(bChoiceIone[5]?.loanTerm)
  },[valueA])

  
  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'90vh' }}>
        <Box sx={{ ml:'8.3vw', mt:'11.25vw' , width:'20vw',background:'inherit', height: '50vh' }}> 
              <div>
             
                <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Monthly Payment ?</Typography>
                <Box sx={{p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                    <Stack spacing={0} direction="row" justifyContent="space-between">
                        <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                        ${withCommas(finOptS?.fin.filters[1].start)}
                        </Typography>
                        <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                        ${valueA}
                        </Typography>
                        <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                        ${withCommas(finOptS?.fin.filters[1].end)}
                        </Typography>
                      </Stack>
            
                  <Slider sx={{ mt:2,color:'#000000'}} value={valueA} onChange={(event, newValue) => setValueA(newValue)}
                  aria-labelledby="input-slider"
                   min={finOptS?.fin.filters[1].start} max={finOptS?.fin.filters[1].end} step={50}/>
              </Box>
              </div>
              <div>
             
                <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Loan Term </Typography>
                <Box sx={{ p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                <Stack spacing={0} direction="row" justifyContent="space-between">
                  <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                  {finOptS?.fin.filters[0].start}
                  </Typography>
                  <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                  {value}
                  </Typography>
                  <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                  {finOptS?.fin.filters[0].end}
                  </Typography>
                  </Stack>
                  </Box>
                  <Slider sx={{mt:2,color:'#000000'}} value={value} onChange={(event, newValue) => setValue(newValue)}
                  aria-labelledby="input-slider"
                   min={finOptS?.fin.filters[0].start} max={finOptS?.fin.filters[0].end} step={5}/>
           
              </div>
          </Box>
          <Box sx={{ bottom:'0', position:'absolute', height:'11.4vw',width: 'inherit' }}>  
              <img src={leftbottom} alt = "Loading..." width="100%" height="100%"/> 
          </Box>
    </Box>
  );
}

function LeftBlockLTP() {
  
  const { bChoiceE,
          bfinancialProfile,
          bChoiceB,
          selectedProp,
          bChoiceItwo,
          adjust,
          finOptP
        } = useSelector((state)=>state.borrower);
  const propOpt = adjust
  const dispatch = useDispatch();
  
  const [value,setValue] = React.useState(finOptP?.loanTerm);
  const [valueA,setValueA] = React.useState(finOptP?.monthlyEMI);
  const navigate = useNavigate();

  var propFinRequest = {
    propertyPrice     : selectedProp?.offerPrice,
    financeType       : bChoiceB === "firsthome"? 'primary' : 'secondary',
    creditScore       : bfinancialProfile?.ficoScore,
    annualIncome      : bfinancialProfile?.annualIncome,
    monthlyOutgoing   : bfinancialProfile?.monthlyOutgoing,
    financeDocs       : bfinancialProfile?.financeDocs.docsAll,
    upfront           : bChoiceE?.upfront,
    loanTerm          : bChoiceE?.loanTerm,
    creditEvents      : bChoiceE?.creditEvents,
    houseEvents       : bChoiceE?.houseEvents,
    monthlyBudget     : bChoiceE?.monthlyBudget, 
    percentOwnership  : 100,
    emailId           : 'vivek@gmail.com',
  };
    if(DebugOutput) console.log("Part buy part rent Finance Request",propFinRequest);

  React.useEffect(()=>{
    propFinRequest.loanTerm = value;
    dispatch(borrowerLT(propFinRequest,propOpt));
    setValueA(bChoiceItwo[5].monthlyEMI)
  },[value])

  React.useEffect(()=>{
    propFinRequest.monthlyBudget = valueA;
    dispatch(borrowerLT(propFinRequest,propOpt));
    setValue(bChoiceItwo[5].loanTerm)
  },[valueA])

  
  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'90vh' }}>
            <Box sx={{ ml:'8.3vw', mt:'11.25vw' , width:'20vw',background:'inherit', height: '50vh' }}> 
              <div>
             
                <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Monthly Payment ?</Typography>
                <Box sx={{p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                    <Stack spacing={0} direction="row" justifyContent="space-between">
                        <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                        ${withCommas(finOptP.fin.filters[1].start)}
                        </Typography>
                        <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                        ${valueA}
                        </Typography>
                        <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                        ${withCommas(finOptP.fin.filters[1].end)}
                        </Typography>
                      </Stack>
                </Box>
                  <Slider sx={{ mt:2,color:'#000000'}} value={valueA} onChange={(event, newValue) => setValueA(newValue)}
                  aria-labelledby="input-slider"
                   min={finOptP.fin.filters[1].start} max={finOptP?.fin.filters[1].end} step={50}/>
            
              </div>
              <div>
             
                <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Loan Term </Typography>
                <Box sx={{ p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                <Stack spacing={0} direction="row" justifyContent="space-between">
                  <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                  {finOptP?.fin.filters[0].start}
                  </Typography>
                  <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                  {value}
                  </Typography>
                  <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                  {finOptP.fin.filters[0].end}
                  </Typography>
                  </Stack>
                  </Box>
                  <Slider sx={{mt:2,color:'#000000'}} value={value} onChange={(event, newValue) => setValue(newValue)}
                  aria-labelledby="input-slider"
                   min={finOptP.fin.filters[0].start} max={finOptP.fin.filters[0].end} step={5}/>
           
              </div>
        </Box>
        <Box sx={{ bottom:'0', position:'absolute', height:'11.4vw',width: 'inherit' }}>  
              <img src={leftbottom} alt = "Loading..." width="100%" height="100%"/> 
          </Box>
    </Box>
  );
}

function LeftBlockRIMS() {
  
  const { selectedProp,
          bChoiceB,
          bChoiceE,
          finOptS,
          bfinancialProfile,
          bChoiceIone,
          adjust
        } = useSelector((state)=>state.borrower);
  const propOpt = adjust;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = React.useState(finOptS?.fin.loanTerm);
  const [valueA, setValueA] = React.useState(finOptS?.fin.monthlyEMI);

  var propFinRequest = {
      propertyPrice    : finOptS?.fin.propertyPrice,
      financeType      : bChoiceB === "firsthome"? 'primary' : 'secondary',
      loanTerm         : bChoiceE.loanTerm,
      creditScore      : bfinancialProfile.ficoScore,
      annualIncome     : bfinancialProfile.annualIncome,
      monthlyOutgoing  : bfinancialProfile.monthlyOutgoing,
      upfront          : bChoiceE.upfront,
      financeDocs      : bfinancialProfile.financeDocs.docsAll,
      creditEvents     : bChoiceE.creditEvents,
      houseEvents      : bChoiceE.houseEvents,
      percentOwnership : 100,
      emailId          : 'vivek@gmail.com'
  };
    if(DebugOutput) console.log("Part buy part rent Finance Request",propFinRequest);
  
    
    React.useEffect(()=>{
      propFinRequest.monthlyBudget = value;
      dispatch(borrowerRIM(propFinRequest,propOpt));
      setValueA(bChoiceIone[3].monthlyUnsecureTermEMI);
    },[value])

    React.useEffect(()=>{
      propFinRequest.monthlyBudget = valueA;
      dispatch(borrowerRIM(propFinRequest,propOpt));
      setValue(bChoiceIone[3].monthlyEMI);
    },[valueA])
 
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'90vh' }}>
            <Box sx={{ ml:'8.3vw', mt:'11.25vw' , width:'20vw',background:'inherit', height: '50vh' }}> 
                <div>
                
                  <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Initial Monthly Payment ?</Typography>
                  <Box sx={{p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                      <Stack spacing={0} direction="row" justifyContent="space-between">
                          <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                          ${withCommas(finOptS.fin.filters[1].start)}
                          </Typography>
                          <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                          ${withCommas(valueA)}
                          </Typography>
                          <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                          ${withCommas(finOptS.fin.filters[1].end)}
                          </Typography>
                        </Stack>
                  </Box>
                    <Slider sx={{ width:'25vw',mt:2,color:'#000000'}} value={valueA} onChange={(event, newValue) => setValueA(newValue)}
                    aria-labelledby="input-slider"
                     min={finOptS.fin.filters[1].start} max={finOptS.fin.filters[1].end} step={50}/>
               
                </div>
                <div>
               
                  <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Post 3 Years Monthly Payment?</Typography>
                  <Box sx={{ p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                  <Stack spacing={0} direction="row" justifyContent="space-between">
                    <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                    ${withCommas(finOptS.fin.filters[2].start)}
                    </Typography>
                    <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                    ${withCommas(value)}
                    </Typography>
                    <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                    ${withCommas(finOptS.fin.filters[2].end)}
                    </Typography>
                    </Stack>
                    </Box>
                    <Slider sx={{width:'25vw',mt:2,color:'#000000'}} value={value} onChange={(event, newValue) => setValue(newValue)}
                    aria-labelledby="input-slider"
                     min={finOptS.fin.filters[2].start} max={finOptS.fin.filters[2].end} step={50}/>
               
                </div>
          </Box>
          <Box sx={{ bottom:'0', position:'absolute', height:'11.4vw',width: 'inherit' }}>  
              <img src={leftbottom} alt = "Loading..." width="100%" height="100%"/> 
          </Box>
      </Box>
    );
}

function LeftBlockRIMP() {
  
  const { adjust,
          finOptP,
          bfinancialProfile,
          proposedProp,
          bChoiceItwo,
          bChoiceB,
          bChoiceE
        } = useSelector((state)=>state.borrower);
  const propOpt = adjust;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = React.useState(finOptP.fin.monthlyUnsecureTermEMI);
  const [valueA, setValueA] = React.useState(finOptP.fin.monthlyEMI);

  var propFinRequest = {
    propertyPrice    : finOptP?.fin.propertyPrice,
    financeType      : bChoiceB === "firsthome"? 'primary' : 'secondary',
    loanTerm         : bChoiceE.loanTerm,
    creditScore      : bfinancialProfile.ficoScore,
    annualIncome     : bfinancialProfile.annualIncome,
    monthlyOutgoing  : bfinancialProfile.monthlyOutgoing,
    upfront          : bChoiceE.upfront,
    financeDocs      : bfinancialProfile.financeDocs.docsAll,
    creditEvents     : bChoiceE.creditEvents,
    houseEvents      : bChoiceE.houseEvents,
    percentOwnership : 100,
    emailId          : 'vivek@gmail.com'
  };
    if(DebugOutput) console.log("Part buy part rent Finance Request",propFinRequest);

  
    
    React.useEffect(()=>{
      propFinRequest.monthlyBudget = value;
      dispatch(borrowerRIM(propFinRequest,propOpt));
      setValueA(bChoiceItwo[3].monthlyUnsecureTermEMI);
    },[value])

    React.useEffect(()=>{
      propFinRequest.monthlyBudget = valueA;
      dispatch(borrowerRIM(propFinRequest,propOpt));
      setValue(bChoiceItwo[3].monthlyEMI);
    },[valueA])
 
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'90vh' }}>
            <Box sx={{ ml:'8.3vw', mt:'11.25vw' , width:'20vw',background:'inherit', height: '50vh' }}> 
                <div>
               
                  <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Initial Monthly Payment ?</Typography>
                  <Box sx={{p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                      <Stack spacing={0} direction="row" justifyContent="space-between">
                          <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                          ${withCommas(finOptP.fin.filters[1].start)}
                          </Typography>
                          <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                          ${withCommas(valueA)}
                          </Typography>
                          <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                          ${withCommas(finOptP.fin.filters[1].end)}
                          </Typography>
                        </Stack>
                  </Box>
                    <Slider sx={{ width:'25vw',mt:2,color:'#000000'}} value={valueA} onChange={(event, newValue) => setValueA(newValue)}
                    aria-labelledby="input-slider"
                     min={finOptP.fin.filters[1].start} max={finOptP.fin.filters[1].end} step={50}/>
              
                </div>
                <div>
               
                  <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Post 3 Years Monthly Payment?</Typography>
                  <Box sx={{ p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                  <Stack spacing={0} direction="row" justifyContent="space-between">
                    <Typography sx={{width:'25vw',mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                    ${withCommas(finOptP.fin.filters[2].start)}
                    </Typography>
                    <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                    ${withCommas(value)}
                    </Typography>
                    <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                    ${withCommas(finOptP.fin.filters[2].end)}
                    </Typography>
                    </Stack>
                    </Box>
                    <Slider sx={{mt:2,color:'#000000'}} value={value} onChange={(event, newValue) => setValue(newValue)}
                    aria-labelledby="input-slider"
                     min={finOptP.fin.filters[2].start} max={finOptP.fin.filters[2].end} step={50}/>
            
                </div>
          </Box>
          <Box sx={{ bottom:'0', position:'absolute', height:'11.4vw',width: 'inherit' }}>  
              <img src={leftbottom} alt = "Loading..." width="100%" height="100%"/> 
          </Box>
      </Box>
    );
}

function LeftBlockPPS() {
  
  const { adjust,
          bChoiceB,
          bChoiceE,
          selectedProp,
          bfinancialProfile,
          finOptS
        } = useSelector((state)=>state.borrower);
  const propOpt = adjust;
  const dispatch = useDispatch();
  
  const [value,setValue] = React.useState(finOptS.fin.points)
  const [valueA, setValueA] = React.useState(finOptS.fin.monthlyEMI);
  const navigate = useNavigate();

  var propFinRequest = {
    propertyPrice     : selectedProp.offerPrice,
    financeType       : bChoiceB === "firsthome"? 'primary' : 'secondary',
    loanTerm          : bChoiceE.loanTerm,
    creditScore       : bfinancialProfile.ficoScore,
    annualIncome      : bfinancialProfile.annualIncome,
    monthlyOutgoing   : bfinancialProfile.monthlyOutgoing,
    upfront           : bChoiceE.upfront,
    financeDocs       : bfinancialProfile.financeDocs.docsAll,
    creditEvents      : bChoiceE.creditEvents,
    houseEvents       : bChoiceE.houseEvents,
    monthlyBudget     : Math.min(bfinancialProfile.frontEndD,bChoiceE.monthlyBudget),
    percentOwnership  : 100,
    points            : 1,
    discountedCost    : null,
    emailId           : 'vivek@gmail.com'
  
    };
    if(DebugOutput) console.log("Part buy part rent Finance Request",propFinRequest);

    React.useEffect(()=>{
          const _prin = selectedProp.offerPrice - bChoiceE.upfront;
          var rate = 4.0 -value*0.25
          const emi = loanToEMI(_prin,rate,30)
        // dispatch(borrowerIO(propFinRequest,propOpt));
        setValueA(emi);
      },[value]);

    React.useEffect(()=>{
        var emi = valueA
        var lowestE = loanToEMI(bChoiceE.offerPrice - bChoiceE.upfront,3.0,30)
        var highestE = loanToEMI(bChoiceE.offerPrice - bChoiceE.upfront,5.0,30)
        var points = Math.round(80*(emi - loanToEMI)/(highestE - loanToEMI))/10.0;
        // dispatch(borrowerPP(propFinRequest,propOpt));
        setValue(points)
    },[valueA]);
  
      return (
        <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'90vh' }}>
            <Box sx={{ ml:'8.3vw', mt:'11.25vw' , width:'20vw',background:'inherit', height: '50vh' }}> 
                  <div>
                 
                    <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Monthly ?</Typography>
                    <Box sx={{p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                        <Stack spacing={0} direction="row" justifyContent="space-between">
                            <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                            ${withCommas(finOptS.fin.filters[1].start)}
                            </Typography>
                            <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                            ${withCommas(valueA)}
                            </Typography>
                            <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                            ${withCommas(finOptS.fin.filters[1].end)}
                            </Typography>
                          </Stack>
                    </Box>
                      <Slider sx={{ mt:2,color:'#000000'}} value={valueA} onChange={(event, newValue) => setValueA(newValue)}
                      aria-labelledby="input-slider"
                       min={finOptS.fin.filters[1].start} max={finOptS.fin.filters[1].end} step={1000}/>
             
                  </div>
                  <div>
                  
                    <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Pay Points ?</Typography>
                    <Box sx={{ p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                    <Stack spacing={0} direction="row" justifyContent="space-between">
                      <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                      {finOptS.fin.filters[0].start}
                      </Typography>
                      <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                      {value}
                      </Typography>
                      <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                      {finOptS.fin.filters[0].end}
                      </Typography>
                      </Stack>
                      </Box>
                      <Slider sx={{mt:2,color:'#000000'}} value={value} onChange={(event, newValue) => setValue(newValue)}
                      aria-labelledby="input-slider"
                       min={finOptS.fin.filters[0].start} max={finOptS.fin.filters[0].end} step={0.2}/>
               
                  </div>
            </Box>
            <Box sx={{ bottom:'0', position:'absolute', height:'11.4vw',width: 'inherit' }}>  
              <img src={leftbottom} alt = "Loading..." width="100%" height="100%"/> 
          </Box>
        </Box>
      );
}

function LeftBlockPPP() {
  
  const { proposedProp,
    adjust,
    bChoiceB,
    bChoiceE,
    bfinancialProfile,
    finOptP
  } = (useSelector((state)=>state.borrower));
const propOpt = adjust;
const dispatch = useDispatch();
const navigate = useNavigate();

var propFinRequest = {
    propertyPrice       : proposedProp.offerPrice,
    financeType         : bChoiceB === "firsthome"? 'primary' : 'secondary',
    loanTerm            : bChoiceE.loanTerm,
    creditScore         : bfinancialProfile.ficoScore,
    annualIncome        : bfinancialProfile.annualIncomem,
    monthlyOutgoing     : bfinancialProfile.monthlyOutgoing,
    upfront             : bChoiceE.upfront,
    financeDocs         : bfinancialProfile.financeDocs.docsAll,
    creditEvents        : bChoiceE.creditEvents,
    houseEvents         : bChoiceE.houseEvents,
    monthlyBudget       : bChoiceE.monthlyBudget,
    percentOwnership    : 100,
    points              :1,
    discountedCost      : null,
    emailId             : 'vivek@gmail.com'
    };
  const [value,setValue] = React.useState(finOptP?.fin.points)
  const [valueA, setValueA] = React.useState(finOptP?.fin.monthlyEMI);

  React.useEffect(()=>{
    const _prin = proposedProp.offerPrice - bChoiceE.upfront;
    var rate = 4.0 -value*0.25
    const emi = loanToEMI(_prin,rate,30)
  // dispatch(borrowerIO(propFinRequest,propOpt));
  setValueA(emi);
},[value]);

React.useEffect(()=>{
  var emi = valueA
  var lowestE = loanToEMI(bChoiceE.offerPrice - bChoiceE.upfront,3.0,30)
  var highestE = loanToEMI(bChoiceE.offerPrice - bChoiceE.upfront,5.0,30)
  var points = Math.round(80*(emi - loanToEMI)/(highestE - loanToEMI))/10.0;
  // dispatch(borrowerPP(propFinRequest,propOpt));
  setValue(points)
},[valueA]);
  
      return (
        <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'90vh' }}>
        <Box sx={{ ml:'8.3vw', mt:'11.25vw' , width:'20vw',background:'inherit', height: '50vh' }}> 
                  <div>
                    <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Upfront ?</Typography>
                    <Box sx={{p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                        <Stack spacing={0} direction="row" justifyContent="space-between">
                            <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                            ${withCommas(finOptP.fin.filters[1].start)}
                            </Typography>
                            <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                            ${withCommas(valueA)}
                            </Typography>
                            <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                            ${withCommas(finOptP.fin.filters[1].end)}
                            </Typography>
                          </Stack>
                    </Box>
                      <Slider sx={{ mt:2,color:'#000000'}} value={valueA} onChange={(event, newValue) => setValueA(newValue)}
                      aria-labelledby="input-slider"
                       min={finOptP.fin.filters[1].start} max={finOptP.fin.filters[1].end} step={1000}/>
                  </div>
                  <div>
                    <Typography fontSize='1.2vw'color='#000000' textAlign='center'> Pay Points ?</Typography>
                    <Box sx={{ p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: "4.5vw" }}>
                    <Stack spacing={0} direction="row" justifyContent="space-between">
                      <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                      {finOptP.fin.filters[0].start}
                      </Typography>
                      <Typography sx={{mt:2,color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                      {value}
                      </Typography>
                      <Typography sx={{mt:2,color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                      {finOptP.fin.filters[0].end}
                      </Typography>
                      </Stack>
                      </Box>
                      <Slider sx={{mt:2,color:'#000000'}} value={value} onChange={(event, newValue) => setValue(newValue)}
                      aria-labelledby="input-slider"  min={Math.min(finOptP.fin.filters[0].start,finOptP.fin.filters[0].end)} 
                                                      max={Math.max(finOptP.fin.filters[0].start,finOptP.fin.filters[0].end)} step={0.2}/>
                  </div>
            </Box>
            <Box sx={{ bottom:'0', position:'absolute', height:'11.4vw',width: 'inherit' }}>  
              <img src={leftbottom} alt = "Loading..." width="100%" height="100%"/> 
          </Box>
        </Box>
      );
}

function LeftBlockIOS() {
  const { selectedProp,
    adjust,
    bChoiceB,
    bChoiceE,
    bfinancialProfile,
    finOptS
  } = (useSelector((state)=>state.borrower));
const propOpt = adjust;
const dispatch = useDispatch();
const navigate = useNavigate();

var propFinRequest = {
    propertyPrice       : selectedProp.offerPrice,
    financeType         : bChoiceB === "firsthome"? 'primary' : 'secondary',
    loanTerm            : bChoiceE.loanTerm,
    creditScore         : bfinancialProfile.ficoScore,
    annualIncome        : bfinancialProfile.annualIncomem,
    monthlyOutgoing     : bfinancialProfile.monthlyOutgoing,
    upfront             : bChoiceE.upfront,
    financeDocs         : bfinancialProfile.financeDocs.docsAll,
    creditEvents        : bChoiceE.creditEvents,
    houseEvents         : bChoiceE.houseEvents,
    monthlyBudget       : bChoiceE.monthlyBudget,
    percentOwnership    : 100,
    discountedCost      : null,
    emailId             : 'vivek@gmail.com'
    };

const [value, setValue]   = React.useState(finOptS?.fin.monthlyEMI)
const [valueA, setValueA] = React.useState(finOptS?.fin.upfront)
                
  function onChangeA(e,newValue){
        var emi     = newValue
        var rate    = finOptS.fin.interestRate;
        var upfront = Math.floor(finOptS.fin.propertyPrice*1.03 -  emi*1200/rate);
        setValueA(upfront);
        setValue(emi);
  }
  function onChangeB(e,newValue){
        var upfront1 = newValue;
        var rate1    = finOptS.fin.interestRate;
        var emi1    = Math.floor((finOptS.fin.propertyPrice*1.03 -upfront1)*rate1/1200);
        setValue(emi1);
        setValueA(upfront1);
  }

return (
<Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'90vh' }}>
            <Box sx={{ ml:'8.3vw', mt:'11.25vw' , width:'20vw',background:'inherit', height: '50vh' }}> 
        <div>
          <Typography fontSize='1.4vw'color='#000000' textAlign='center'> Monthly EMI ?</Typography>
          <Box sx={{mt:2,p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: '6vh' }}>
              <Stack sx={{verticalAlign:'middle'}} spacing={0} direction="row" justifyContent="space-between">
                  <Typography sx={{color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                  ${withCommas(Math.min(finOptS?.fin.filters[1].start,finOptS?.fin.filters[1].end))}
                  </Typography>
                  <Typography sx={{color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                  ${withCommas(value)}
                  </Typography>
                  <Typography sx={{color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                  ${withCommas(Math.max(finOptS?.fin.filters[1].start,finOptS?.fin.filters[1].end))}
                  </Typography>
              </Stack>
          </Box>
          <Slider sx={{width:'25vw', mt:2,color:'#000000'}} value={value} onChange={onChangeA}
          aria-labelledby="input-slider"  min={Math.min(finOptS?.fin.filters[1].start,finOptS?.fin.filters[1].end)} 
                                          max={Math.max(finOptS?.fin.filters[1].start,finOptS?.fin.filters[1].end)} step={1000}/>
        </div>

        <div>
          <Typography fontSize='1.4vw'color='#000000' textAlign='center'> Upfront ?</Typography>
          <Box sx={{ p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: '6vh' }}>
              <Stack spacing={0} mb='0.56vw'direction="row" justifyContent="space-between">
                <Typography sx={{color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                ${withCommas(Math.min(finOptS?.fin.filters[0].start,finOptS?.fin.filters[0].end))}</Typography>
                <Typography sx={{color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                ${withCommas(valueA)}</Typography>
                <Typography sx={{color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                ${withCommas(Math.max(finOptS?.fin.filters[0].start,finOptS?.fin.filters[0].end))}</Typography>
              </Stack>
          </Box>
          <Slider sx={{width:'25vw',mt:2,color:'#000000'}} value={valueA} onChange={onChangeB}
            aria-labelledby="input-slider"  min={Math.min(finOptS?.fin.filters[0].start,finOptS?.fin.filters[0].end)} 
                                            max={Math.max(finOptS?.fin.filters[0].start,finOptS?.fin.filters[0].end)} step={1000}/>
        </div>
        </Box>
        <Box sx={{ bottom:'0', position:'absolute', height:'11.4vw',width: 'inherit' }}>  
              <img src={leftbottom} alt = "Loading..." width="100%" height="100%"/> 
        </Box>
</Box>
);
}


function LeftBlockIOP() {
  const { proposedProp,
          adjust,
          bChoiceB,
          bChoiceE,
          bfinancialProfile,
          finOptP
        } = (useSelector((state)=>state.borrower));
  const propOpt = adjust;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  var propFinRequest = {
          propertyPrice       : proposedProp.offerPrice,
          financeType         : bChoiceB === "firsthome"? 'primary' : 'secondary',
          loanTerm            : bChoiceE.loanTerm,
          creditScore         : bfinancialProfile.ficoScore,
          annualIncome        : bfinancialProfile.annualIncomem,
          monthlyOutgoing     : bfinancialProfile.monthlyOutgoing,
          upfront             : bChoiceE.upfront,
          financeDocs         : bfinancialProfile.financeDocs.docsAll,
          creditEvents        : bChoiceE.creditEvents,
          houseEvents         : bChoiceE.houseEvents,
          monthlyBudget       : bChoiceE.monthlyBudget,
          percentOwnership    : 100,
          discountedCost      : null,
          emailId             : 'vivek@gmail.com'
          };

    const [value, setValue]   = React.useState(finOptP.fin.monthlyEMI)
    const [valueA, setValueA] = React.useState(finOptP.fin.upfront)
                      
  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "emi":
        var emi     = e.currentTarget.value;
        var rate    = finOptP.fin.interestRate;
        var upfront = Math.floor(finOptP.fin.propertyPrice*1.03 -  emi*1200/rate);
        setValueA(upfront);
        setValue(emi);
        break;
      case "upfront":
        var upfront1 = e.currentTarget.value;
        var rate1    = finOptP.fin.interestRate;
        var emi1    = Math.floor((finOptP.fin.propertyPrice*1.03 -upfront1)*rate1/1200);
        setValue(emi1);
        setValueA(upfront1);
        break;
      default:break;
    }
  }

  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'90vh' }}>
                <Box sx={{ ml:'8.3vw', mt:'11.25vw' , width:'20vw',background:'inherit', height: '50vh' }}> 
            <div>
              <Typography fontSize='1.4vw'color='#000000' textAlign='center'> Monthly EMI ?</Typography>
              <Box sx={{mt:2,p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: '6vh' }}>
                  <Stack sx={{verticalAlign:'middle'}} spacing={0} direction="row" justifyContent="space-between">
                      <Typography sx={{color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                      ${withCommas(Math.min(finOptP?.fin.filters[1].start,finOptP?.fin.filters[1].end))}
                      </Typography>
                      <Typography sx={{color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                      ${withCommas(value)}
                      </Typography>
                      <Typography sx={{color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                      ${withCommas(Math.max(finOptP?.fin.filters[1].start,finOptP?.fin.filters[1].end))}
                      </Typography>
                  </Stack>
              </Box>
              <Slider id="emi" sx={{width:'25vw', mt:2,color:'#000000'}} value={value} onChange={onClickFun}
              aria-labelledby="input-slider"  min={Math.min(finOptP?.fin.filters[1].start,finOptP?.fin.filters[1].end)} 
                                              max={Math.max(finOptP?.fin.filters[1].start,finOptP?.fin.filters[1].end)} step={100}/>
            </div>
    
            <div>
              <Typography fontSize='1.4vw'color='#000000' textAlign='center'> Upfront ?</Typography>
              <Box sx={{ p:2, backgroundColor:"", border:1, borderColor:'#000000', borderRadius:2, width: '25vw', height: '6vh' }}>
                  <Stack spacing={0} mb='0.56vw'direction="row" justifyContent="space-between">
                    <Typography sx={{color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                    ${withCommas(Math.min(finOptP?.fin.filters[0].start,finOptP?.fin.filters[0].end))}</Typography>
                    <Typography sx={{color:'#000000'}}fontSize='1.2vw' textAlign='center'> 
                    ${withCommas(valueA)}</Typography>
                    <Typography sx={{color:'#000000'}}fontSize='1.0vw' textAlign='center'> 
                    ${withCommas(Math.max(finOptP?.fin.filters[0].start,finOptP?.fin.filters[0].end))}</Typography>
                  </Stack>
              </Box>
              <Slider id="upfront" sx={{width:'25vw',mt:2,color:'#000000'}} value={valueA} onChange={onClickFun}
                aria-labelledby="input-slider"  min={Math.min(finOptP?.fin.filters[0].start,finOptP?.fin.filters[0].end)} 
                                                max={Math.max(finOptP?.fin.filters[0].start,finOptP?.fin.filters[0].end)} step={1000}/>
            </div>
            </Box>
            <Box sx={{ bottom:'0', position:'absolute', height:'11.4vw',width: 'inherit' }}>  
                  <img src={leftbottom} alt = "Loading..." width="100%" height="100%"/> 
            </Box>
    </Box>
    );
}


export function loanToEMI(principal,rate,termY){
  console.log("parameters, principal,rate,term",principal,rate,termY)
  const tt_1 = Math.pow((1+rate/1200),termY*12.0) 
  const emi = principal*rate*tt_1/(1200*(tt_1-1))
  console.log("emi calculated",tt_1,emi)
  return (emi);
}

export function emiToLoan(emi,rate,termY){
  console.log("parameters, emi,rate,term",emi,rate,termY)
  const tt_1 = Math.pow((1+rate/1200),termY*12.0) 
  const prince = emi*1200*(tt_1 -1)/(rate*tt_1)
  console.log("Principal calculated",tt_1,prince)
  return (prince);
}
export default AdjustOptions;