import React from "react";
import { Box, CssBaseline, CircularProgress, Typography, Button,Stack} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { borrowerCheapestFin} from "../../actions/borrower";
import { Centerbottom, LeftbottomA, RightBlockApply, RightBlockCompare, RightBlockEB, RightBlockPropConf, RightBlockSPBPRate } from "./RightBlock";
import leftbottom from '../../assets/images/bg-10@1x.png'
import { LeftBlockMsg } from "./Messenger";
import { PROPROP } from "../../constants";

const DebugOutput = true;
function withCommas(x) {return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};

function Affordability() {
  const [incomeSuff, setIncomeSuff] = React.useState(0)
  const { selectedProp,
          bfinancialProfile,
          bChoiceE,
          bChoiceF,
          isLoading,
          bChoiceB } = useSelector((state)=>state.borrower);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  
  React.useEffect(()=>{
    bChoiceF.monthly === 'Sufficient' || bChoiceF.monthly === 'High' 
    ? setIncomeSuff(1) 
    : setIncomeSuff(0)
  },[bfinancialProfile.annualIncome])

    function onClickFun(e){
      e.preventDefault();
      const buttonId = e.currentTarget.id;
      switch(buttonId) {
        case "ChangeIncome": navigate("/AnnualIncome", { replace: false }); break;
        case "DesignFinance": 
          dispatch({type: PROPROP, payload:selectedProp});
          const price = selectedProp?.prop?.offerPrice;
          const upfront = bChoiceE?.upfront
          const mortAmt = Math.min(price *1.03 - upfront, 0.8*price )
          const unsecure = Math.max( price - mortAmt -upfront,0)
          const propFinRequest = {
            propertyPrice   : price,
            mortgageAmount  : mortAmt,
            unsecureAmount  : unsecure,
            financeType     : (bChoiceB === 'firsthome' || bChoiceB === 'nexthome') ? 'primary' :(bChoiceB === 'investment')? 'investment': 'secondary',
            loanTerm        : bChoiceE?.loanTerm,
            creditScore     : bfinancialProfile.ficoScore,
            annualIncome    : bfinancialProfile.annualIncome,
            upfront         : bChoiceE?.upfront,
            financeDocs     : bfinancialProfile.financeDocs.docsAll,
            creditEvents    : bChoiceE?.creditEvents,
            houseEvents     : bChoiceE?.creditEvents,
            monthlyOutgoing : bfinancialProfile.monthlyOutgoing,
            monthlyBudget   : bChoiceE?.monthlyBudget,
            emailId         : 'amitkd4@gmail.com'
          } 
          if(DebugOutput) console.log("Cheapest Finance Request",propFinRequest);
          dispatch(borrowerCheapestFin(propFinRequest,'selected'));
          navigate("/AffordabilityChF", { replace: false });
          break;
        case "ChangeProperty": 
          navigate("/ShowProperty", { replace: false });
          break;
        case "ChangeBudget": 
        navigate("/ChangeBudget", { replace: false });
        break;
        default: 
          break;
      }
    }

  return(
    isLoading ? <CircularProgress /> : (
      <React.Fragment>
            <CssBaseline/>
            <Box sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
              <Navbar pathname={location.pathname}/>
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
              { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
              
                <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
                <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> 
              ...</Typography>
              <div align='center'>
            <Stack sx={{mt:'3.5vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
              { incomeSuff ===0
                ?  <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="ChangeIncome" className='hexBtnCenter'
                      onClick={onClickFun}> 
                      <HexButton titleText="Change Income" type='base' fontSize='24'/></Button>
                : <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="DesignFinance" className='hexBtnCenter'
                      onClick={onClickFun}> 
                      <HexButton titleText="Design Finance" type='confirm' fontSize='24'/></Button>
              }
          </Stack>
          <Stack sx={{mt:'2.25vw',height:'3.5vw', width:'12vw'}} spacing={'-0.5vw'} direction="row" justifyContent="space-around">
              <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="ChangeProperty" className='hexBtnCenter'
                onClick={onClickFun}> 
                <HexButton titleText="Change Property" type='base' fontSize='24'/></Button>
              <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="ChangeBudget" className='hexBtnCenter'
                onClick={onClickFun}> 
                <HexButton titleText="Change Budget" type='base' fontSize='18'/></Button>
          </Stack>
          <Centerbottom navigateForward='-1'/>
          </div>
      </Box>
      <RightBlockCompare/>
      </Box>
    </Box>
    </React.Fragment>
    ));
}

  function LeftBlock() {
    const { bChoiceE,
            bChoiceF,
            bfinancialProfile
          } = useSelector((state)=>state.borrower);

    const navigate = useNavigate();
     var incomeIN=0, upfrontIN = 0;
      var textA='', textB='', textC='';
      if(bChoiceF?.minMonthlySufficient > bfinancialProfile.frontEndDTI){
        textA = "Insufficient"
        textC = "Not"
      }else{
        textA = "Sufficient";
        textC = " "
        incomeIN = 1;
      }
      if(bChoiceF?.minUpfrontSufficient > parseInt(bChoiceE?.upfront)){
        textB = "Cash Insufficient";
        console.log("DDD",bChoiceF?.minUpfrontSufficient,parseInt(bChoiceE?.upfront));
        upfrontIN = 1;
      } else{
        textB = "Cash Sufficient";
        console.log("DDD",bChoiceF?.minUpfrontSufficient,parseInt(bChoiceE?.upfront))
      }

    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46vw' }}>
          <Box sx={{ ml:'7vw',mt:'10vw' , width:'20vw',background:'inherit', height: '19vw' }}>
          <Typography sx={{color:'#31312C', width:'20vw',
              fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                Nestfuel Smart
            </Typography>
            <Typography sx={{color:'#31312C', width:'20vw',mt:1,
              fontSize:'1.2vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                For the selected property
            </Typography>
            <div>
                <Stack sx={{mt:2}} spacing={0} direction="row" justifyContent="space-between">
                  <Stack spacing={0} direction="row" justifyContent="space-between">
                    <Typography sx={{mt:0}} fontSize='1vw' color='#000000'> 
                        Income: 
                    </Typography>
                    <Typography sx={{mt:0}} fontSize='1.1vw' color='#000000' fontWeight={700}> 
                      {bChoiceF?.monthly}
                    </Typography>
                  </Stack>
                  <Stack spacing={0} direction="row" justifyContent="space-between">
                    <Typography fontSize='1vw' fontStyle="regular" component='div'color='#000000'> 
                        Cash:
                    </Typography>
                    <Typography fontSize='1.1vw' fontWeight={700} component='div'color='#000000'> 
                      {bChoiceF?.upfront}
                    </Typography>
                  </Stack>
                </Stack> 
                <Stack sx={{mt:2}}spacing={0} direction="row" justifyContent="space-between">
                    <Typography fontSize='1vw' fontStyle="regular" component='div' color='#000000'> Your Income : </Typography>
                    <Typography fontSize='1.2vw' fontWeight='bold' width='8vw' color='#000000'> ${withCommas(bfinancialProfile.annualIncome)} </Typography>
                  </Stack>
                  <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between">
                    <Typography fontSize='1vw' fontStyle="regular" component='div' color='#000000'> Your Upfront : </Typography>
                    <Typography fontSize='1.2vw' fontWeight='bold' width='8vw' component='div' color='#000000'> ${withCommas(bChoiceE.upfront)}  </Typography>
                  </Stack>
                  <Stack sx={{mt:1}} spacing={0} direction="row" justifyContent="space-between">
                    <Typography fontSize='1vw' fontStyle="regular" component='div' color='#000000'> Your Monthly : </Typography>
                    <Typography fontSize='1.2vw' fontWeight='bold' width='8vw' component='div' color='#000000'> ${withCommas(bChoiceE.monthlyBudget)}  </Typography>
                  </Stack>
                  <Typography sx={{ ml:'0vw',mt:'1vw', width:'20vw'}} fontSize='1.1vw' component="div"
                              color='#000' align='justify'>
                      You are {bChoiceF?.monthly === 'Sufficient' || bChoiceF?.monthly === 'High'? '': 'not'} eligible for a loan for this properrty as your income is {bChoiceF?.monthly === 'Sufficient' || bChoiceF?.monthly === 'High'? 'sufficient': 'not sufficient'} enough to prove your ability
                      to repay.
                  </Typography>
                </div>
                </Box>
                <LeftbottomA leftbottom={leftbottom}/>
      </Box>
    );
  }

  
export default Affordability;