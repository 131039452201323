import React from "react";
import { useNavigate } from "react-router-dom";
// material-ui
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  CssBaseline, InputAdornment, IconButton, Divider, FormControlLabel, Checkbox, Link, Select, InputLabel, Textarea
} from '@mui/material';
import { HexButton } from './ButtonIcons1';
import { getWindowDimensions } from '../../computations/helperFunctions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import leftbottom from '../../assets/svg/assets svg/image-leftbottom02.png'








import mask_group from '../../assets/staticPageAssets/MaskGroup107.png'
import bullet from '../../assets/staticPageAssets/Polygon.svg'
import useStyles from './styles';
import { BottomBox, LeftbottomA } from "./RightBlock";
import { _debug } from "../../constants";
import { border, fontWeight, width } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex";
import NavBarDashBoardUpdate from "./NavBarDashBoardUpdate";
import NavBarBreadCrumbUpdate from "./NavBarBreadCrumbUpdate";
import AnalyticTeamMember from './AnalyticTeamMember';
//import IncomeAreaChart from './IncomeAreaChart';
//import MonthlyBarChart from './MonthlyBarChart';
import { Bar, Line } from "react-chartjs-2";
import MainCard from './MainCard';
import "chartjs-plugin-datalabels";
import person from '../../assets/svg/person.svg'
import dollarsolid from '../../assets/svg/dollar-solid.svg'
import briefcasefill from '../../assets/svg/briefcase-fill.svg'
import stopWatch from '../../assets/svg/stopwatch-fill.svg'
import graduationcap from '../../assets/svg/graduation-cap.svg'
import dateSolid from '../../assets/svg/date-solid.svg'
import thumbsSolid from '../../assets/svg/thumbs-up-solid.svg'
import ContractTable from './ContractTable';
import InvoiceTable from './InvoiceTable';
import bxslike from '../../assets/svg/bxs-like.svg'
import ResourceTable from './ResourceTable';
import avatar_boy from '../../assets/svg/avatar-boy.png'
import emp1 from '../../assets/emp1.png'
import rectangle from '../../assets/Rectangle2x.png'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement, PointElement,
  Title,
  Tooltip,
  Legend, LineElement

} from 'chart.js';
var allowed = "no"


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip, LineElement,

  Legend, PointElement
);




function EmployerTeamMember() {
  const navigate = useNavigate();
  const { height, width } = getWindowDimensions();
  const [seek, setSeek] = React.useState(allowed);
  const [hexaText1, setHexaText1] = React.useState('Investors')
  const [hexaText2, setHexaText2] = React.useState('Technology Partners')
  const [hexaText3, setHexaText3] = React.useState('Employers')
  const [hexaText4, setHexaText4] = React.useState('Mortgage Industry')


  const [value, setValue] = React.useState('today');
  const [slot, setSlot] = React.useState('week');
  // if(_debug === 'development') allowed = 'yes'
  allowed = 'yes'


  const state = {
    labels: ['January', 'February', 'March',
      'April', 'May'],
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [65, 59, 80, 81, 56]
      }
    ]
  }


  // const dataBar = {
  //   labels: ["January", "February", "March", "April", "May", "June", "July"],
  //   datasets: [
  //     {
  //       label: "My First dataset",
  //       backgroundColor: "#EC932F",
  //       borderColor: "rgba(255,99,132,1)",
  //       borderWidth: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [65, 59, 80, 81, 56, 55, 40]
  //     }
  //   ]
  // };

  // const options = {
  //   plugins: {
  //     datalabels: {
  //       display: true,
  //       color: "black",
  //       align: "end",
  //       anchor: "end",
  //       font: { size: "14" }
  //     }
  //   },
  //   legend: {
  //     display: false
  //   }
  // };


  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
      //   {
      //     label: "Second dataset",
      //     data: [33, 25, 35, 51, 54, 76],
      //     fill: false,
      //     borderColor: "#742774"
      //   }
    ]
  };


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };

  // avatar style
  const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem'
  };

  // action style
  const actionSX = {
    mt: 0.75,
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',
    transform: 'none'
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  //  const data = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'Dataset 1',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //     },
  //     {
  //       label: 'Dataset 2',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //     },
  //   ],
  // };

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [age, setAge] = React.useState('');


  return (
    <React.Fragment><CssBaseline />
      <Box sx={{ overflow: 'hidden', width: '100%', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <NavBarDashBoardUpdate />
        <NavBarBreadCrumbUpdate />


        <Box sx={{ ml: '7.34vw', mr: '7.34vw', mt: '4vh', mb: '4vh' }} >
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>

            <Grid sx={{ ml: '1.5vw', width: '100%', mt: '4.07vh' }}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography sx={{ font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '1.15vw' }}>Team Member</Typography>
                </Grid>
              </Grid>
              <MainCard sx={{ mt: 2 }} content={false}>
                <Grid>
                  <Typography sx={{mt: '3.05vh', ml: '1.09vw', fontWeight: 'bold',  font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '1.15vw' }}>Darrel Steward</Typography>
                  <Typography sx={{mt: '0.46vh', ml: '1.09vw', mb: '1vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: '0.73vw' }}>Marketing Specialist</Typography>
                  <Divider sx={{ borderBottomWidth: 1, ml: '1.09vw' , mr: '0.80vw'}} color='#616161' />
                </Grid>

                <List
                  component="nav"
                  sx={{
                    px: 0,
                    py: 0,
                    '& .MuiListItemButton-root': {
                      py: 1.5,
                      '& .MuiAvatar-root': avatarSX,
                      '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                    }
                  }}
                >

                  <ListItemButton>
                    <ListItemAvatar>
                      <Box sx={{ mr: '0.00vw', ml: '0.0vw', mt: '3vh', mb: '12vh', width: '10.97vw', height: '16.11vh' }}>
                        <img src={rectangle} alt = "Loading..." width="100%" heigh="100%" />
                      </Box>
                    </ListItemAvatar>
                    <ListItemSecondaryAction>
                      <Box sx={{
                        mt: '0.0vh',
                        mb:'0vh',
                        ml: '0vh',
                        mr: '0vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      >
                        <Box component="form" noValidate sx={{ mt: '2vh' }}>
                          <Grid container>
                            <Grid sx={{ mr: '6vw', ml: '2.00vw' }}>
                              <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: '0.73vw' }}>
                                Full Name</Typography>

                            </Grid>
                            <Grid sx={{ mr: '8.81vw', mt: '0.74vh', ml: '2.00vw',mb: '0.83vh' }}>
                            <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Bold', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                              Darrel Steward
                              </Typography>

                            </Grid>
                          </Grid>

                        </Box>
                        <Box component="form" noValidate sx={{ mt: '14.50vh' }} justify-content='bottom'>
                          <Grid container justify="bottom">
                          <Grid sx={{ mr: '6vw', mt: '0.0vh', ml: '2.00vw' }}>
                            <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: '0.73vw' }}>
                              Email ID
                              </Typography>

                            </Grid>
                            <Grid sx={{ mr: '8.81vw', mt: '0.74vh', ml: '2.00vw',mb: '0.83vh' }}>
                            <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Bold', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                              andrew@outlook.com
                              </Typography>

                            </Grid>
                          </Grid>
                        </Box>
                      </Box>

                    </ListItemSecondaryAction>


                    <ListItemSecondaryAction>

                      <Box sx={{
                       mt: '0.0vh',
                       mb:'0vh',
                       ml: '0vh',
                       mr: '0vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      >
                        <Box component="form" noValidate sx={{ mt: '2vh' }}>
                          <Grid container>
                          <Grid sx={{ mr: '6vw', ml: '2.00vw' }}>
                            <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: '0.73vw' }}>
                              Address
                              </Typography>
                            </Grid>
                            <Grid sx={{ mr: '8.81vw', mt: '0.74vh', ml: '2.00vw',mb: '0.83vh' }}>
                            <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Bold', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                              1950 Royal Archers Ln,
                              Monument,CO 80132
                              </Typography>

                            </Grid>
                          </Grid>

                        </Box>
                        <Box component="form" noValidate sx={{ mt: '11.50vh'  }} justify-content='bottom'>
                          <Grid container justify="bottom">
                            <Grid sx={{ mr: '6vw', mt: '0.0vh', ml: '2.00vw' }}>
                            <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: '0.73vw' }}>
                              Phone
                              </Typography>

                            </Grid>
                            <Grid sx={{ mr: '8.81vw', mt: '0.74vh', ml: '2.00vw',mb: '0.83vh' }}>
                            <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Bold', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                              (555)555-1234
                              </Typography>

                            </Grid>
                          </Grid>
                        </Box>
                      </Box>

                    </ListItemSecondaryAction>






                    <ListItemSecondaryAction>

                      <Box sx={{
                       mt: '0.0vh',
                       mb:'0vh',
                       ml: '0vh',
                       mr: '0vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      >
                        <Box component="form" noValidate sx={{ mt: '2vh' }}>
                          <Grid container>
                            <Grid sx={{ mr: '6vw', ml: '2.00vw' }}>
                            <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: '0.73vw' }}>
                              Date Joining
                              </Typography>

                            </Grid>
                            <Grid sx={{ mr: '8.81vw', mt: '0.74vh', ml: '2.00vw',mb: '0.83vh' }}>
                            <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Bold', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                              August 12,2020
                              </Typography>

                            </Grid>
                          </Grid>

                        </Box>
                        <Box component="form" noValidate sx={{ mt: '11.69vh' }} justify-content='bottom'>
                          <Grid container justify="bottom">
                          <Grid sx={{ mr: '6vw', mt: '0.0vh', ml: '1.00vw' }}>
                          <Typography sx={{mt: '0.0vh', ml: '1vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: '0.73vw' }}>
                              Title
                              </Typography>

                            </Grid>
                            <Grid sx={{ mr: '8.81vw', mt: '0.74vh', ml: '2.00vw',mb: '0.83vh' }}>
                            <Typography sx={{ mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Bold', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                              Marketing Specialist
                              </Typography>

                            </Grid>
                          </Grid>
                        </Box>
                      </Box>

                    </ListItemSecondaryAction>




                    <ListItemSecondaryAction>

                      <Box sx={{
                        mt: '0.0vh',
                        mb:'0vh',
                        ml: '0vh',
                        mr: '0vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      >
                        <Box component="form" noValidate sx={{ mt: '2vh' }}>
                          <Grid container>
                          <Grid sx={{ mr: '6vw', ml: '2.00vw' }}>
                          <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: '0.73vw' }}>
                              Country
                              </Typography>

                            </Grid>
                            <Grid sx={{ mr: '8.81vw', mt: '0.74vh', ml: '2.00vw',mb: '0.83vh' }}>
                            <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Bold', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                              Colorado
                              </Typography>

                            </Grid>
                          </Grid>

                        </Box>
                        <Box component="form" noValidate sx={{ mt: '13.89vh' }} justify-content='bottom'>
                          <Grid container justify="bottom">
                          <Grid sx={{ mr: '6vw', mt: '0.0vh', ml: '2.00vw' }}>
                          <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: '0.73vw' }}>
                              Job type
                              </Typography>

                            </Grid>
                            <Grid sx={{ mr: '8.81vw', mt: '0.74vh', ml: '2.00vw',mb: '0.83vh' }}>
                            <Typography sx={{mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Bold', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                              Full time
                              </Typography>

                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </ListItemSecondaryAction>



                  </ListItemButton>

                </List>
              </MainCard>

              <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ mt: '2vh' }}>

                <Grid item xs={12} sm={6} md={4} lg={3} >
                  <AnalyticTeamMember title="Mortgage Program" count="Jan-01-2023" image={thumbsSolid} percentage={59.3} extra="35,000" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <AnalyticTeamMember title="Start Date" count="Aug-122020" image={dateSolid} percentage={70.5} extra="8,900" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <AnalyticTeamMember title="Learning Hours" count="Jan-01-2023" image={graduationcap} percentage={27.4} isLoss color="warning" extra="1,943" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <AnalyticTeamMember title="Last Log In" count="Jan-01-2023" image={stopWatch} percentage={27.4} isLoss color="warning" extra="$20,395" />
                </Grid>

              </Grid>


            </Grid>


          </Grid>
        </Box>


      </Box>
    </React.Fragment>
  );
}

function InputKey() {

  const name = 'password'
  const type = 'password'
  const [cur, setCur] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);

  function onClickFun(e) {
    const val1 = e.currentTarget.value
    val1 === "N3$tfu3L" ? allowed = 'yes' : allowed = 'no'


  };

  return (
    <div position='fixed' align='center'>
      <Typography sx={{ fontSize: '1vw', width: '12vw' }}>Please answer the following to activae DISCOVER NESTFUEL (Hint:Army man of the Raj earns working days by a week 7-6-3)</Typography>
      <Typography sx={{ mt: 2, fontSize: '1vw', width: '12vw' }}></Typography>
      <TextField sx={{ fontsize: '0.5vw', mt: 1, width: '12vw', border: 1, borderColor: 'black', borderRadius: 2 }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label
        autoFocus={true}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </div>
  )
}

export default EmployerTeamMember;