import React from "react";
import { Slider,Box, Input, CssBaseline, CircularProgress, Stack, Typography, Button} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { borrowerChoiceF, borrowerfinancialProfile, borrowerProfileStatus, calcPropfinOptionsA, calcPropfinOptionsEmpA } from "../../actions/borrower";
import { Centerbottom, LeftbottomA, RightBlockSPBP } from "./RightBlock";
import leftbottom from '../../assets/images/bg-10@1x.png'
import { emiToLoan, emiToLoanDefault } from "./HelperFunctions";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { IS_PROFILE_INFO_CHANGED, UPDATE_PROFILEJOURNEY, withCommas } from "../../constants";
import { LeftBlockMsg } from "./Messenger";
// import { type } from "@testing-library/user-event/dist/types/utility";
import { rspvw } from "./styles";
function AnnualIncome() {
  const { bfinancialProfile,
          bProfileStatus,
          bProfileJourney,
          bChoiceD,
          bChoiceB,
          bChoiceE,
          isLoading,
        }= useSelector((state)=>state.borrower);

  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 

  const [comp,setComp] = React.useState(0)

  React.useEffect(()=>{
    if(comp ===0){
      // dispatch(calcPropfinOptions(bChoiceD,bfinancialProfile,bChoiceB))
      setComp(-1)
    } 
    // if(isEmbFlow ==true){
    //   // setNavigateLink("ShowPurchaseRate")
    //   setNavigateLink("EmployerSupportDtReg")

    // }
    if(comp ===1 && !isLoading){
      
      // dispatch(calcPropfinOptionsEmpA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE,bChoiceL))
      // dispatch(calcPropfinOptionsEmpA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE,bChoiceL))


      setTimeout(setComp(2),0.002)
    }  
    if(comp ===2 && !isLoading){
      // dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
        // dispatch(calcPropfinOptionsEmpA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE,bChoiceL))

            // dispatch(borrowerPostFinanceData(bfinancialProfile))
            // setTimeout(bProfile.employmentType !=='Employed' ? navigate(`/${navigateLink}`,{replace:false})
            // :navigate(`/${navigateLink}`, { replace: false }),5000)
    }
  },[comp,isLoading])

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
 
  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "Work": 
            const income = {...bfinancialProfile.income, incomeSource:"Work Only"};
            dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income }));
            dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, economicsIncome: true} });
            dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
            dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});


      break;
      case "Other": 
            const income1 = {...bfinancialProfile.income, incomeSource:"Other Sources"};
            dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income1 }));
            dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, economicsIncome: true} });
            dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
            dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});


      break;
      case "Invest":
            const income2 = {...bfinancialProfile.income, incomeSource:"Investments"};
            dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income2 }));
            dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, economicsIncome: true} });
            dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
            dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});


      break;
      default:
        break;
    }
    dispatch(borrowerProfileStatus({...bProfileStatus,incomeSourceProvided:1}))
    navigate(-1);
  }

  return(
    isLoading ? <CircularProgress /> : (
      <React.Fragment><CssBaseline/>
           <Box mt={marginT} ml={marginL}sx={{ overflow:'hidden', width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
            <Navbar pathname={location.pathname}/>
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
                { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
              
                <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <div align='center'>
                <Typography sx={{ color: '#000000', mt: '11.42vw', fontSize: '1.4vw', textAlign: 'center' }}>
                  Update Settings</Typography>

                <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Work" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Work Only" type={bfinancialProfile.income.incomeSource === 'Work Only' ? 'visited' : 'base'} fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} width='6.5vw' id="Invest" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="+ Investments" type={bfinancialProfile.income.incomeSource === 'Investments' ? 'visited' : 'base'} fontSize='24' /></Button>
                  <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Other" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="+ Other Sources" type={bfinancialProfile.income.incomeSource === 'Other Sources' ? 'visited' : 'base'} fontSize='24' /></Button>
                </Stack>
                <Centerbottom navigateForward='-1' />
              </div>
          </Box>
          <RightBlockSPBP/>
      </Box>
      </Box>
      </React.Fragment>
      ));
}

function LeftBlock() {
  var input;
  const dispatch = useDispatch();
  const { bfinancialProfile,
          bProfileJourney,
          bProfileStatus,
          bChoiceD,
          bChoiceB,
          bChoiceE,
          isLoading,
          selectedProp
        } = useSelector((state)=>state.borrower);
  const [annualIncome,setAnnualIncome] = React.useState(bfinancialProfile.income.annualIncome);
  const [upfront,setUpfront] = React.useState(bfinancialProfile.income.upfront);   

  React.useEffect(()=>{
    const backEndDTI = Math.floor(annualIncome*0.43/12);
    const frontEndDTI = Math.min(backEndDTI - bfinancialProfile.income.outgoing,
                                  Math.floor(annualIncome*0.25/12));
    const maxAffordabilityU = upfront*8.3;
    const maxAffordabilityI = emiToLoanDefault(frontEndDTI) + upfront;
    const maxAffordability = Math.ceil(Math.min(maxAffordabilityI,maxAffordabilityU)/100)*100;
    const propFinRequest = {
      propertyPrice  : selectedProp?.prop.price,
      offerPrice     : selectedProp?.prop.offerPrice,
      monthlyBudget  : frontEndDTI,
      upfront        : upfront
      }
    dispatch(borrowerChoiceF(propFinRequest));
    dispatch(borrowerProfileStatus({...bProfileStatus,incomeProvided:1}));

    const income = {...bfinancialProfile.income, annualIncome:annualIncome, maxLoanAffordability:maxAffordability};
    dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income }));
    dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, economicsIncome: true} });

  },[annualIncome])
  const [comp,setComp] = React.useState(0)

  React.useEffect(()=>{
    if(comp ===0){
      // dispatch(calcPropfinOptions(bChoiceD,bfinancialProfile,bChoiceB))
      setComp(-1)
    } 
    // if(isEmbFlow ==true){
    //   // setNavigateLink("ShowPurchaseRate")
    //   setNavigateLink("EmployerSupportDtReg")

    // }
    if(comp ===1 && !isLoading){
      
      dispatch(calcPropfinOptionsEmpA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE,bChoiceL))
      // dispatch(calcPropfinOptionsEmpA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE,bChoiceL))


      setTimeout(setComp(2),5000)
    }  
    if(comp ===2 && !isLoading){
      dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
        dispatch(calcPropfinOptionsEmpA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE,bChoiceL))

            // dispatch(borrowerPostFinanceData(bfinancialProfile))
            // setTimeout(bProfile.employmentType !=='Employed' ? navigate(`/${navigateLink}`,{replace:false})
            // :navigate(`/${navigateLink}`, { replace: false }),5000)
    }
  },[comp,isLoading])
  function onClickFun(e) {
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "AnnualIncome":
          var input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
          setAnnualIncome(isNaN(input) ? 0 : input)
          // dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))

          break;
      default:
        break;
      }
  }      

  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box', position: 'relative',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height:'46.75vw'
    }}>
      <Box sx={{ ml: '7vw', mt: '13vw', width: '21vw', background: 'inherit', height: '20vw' }}>
        <div align='center'>
          <Typography mt={1} fontSize='1.4vw' fontWeight='bold' component='div' color='#000000'>
            Your Income & Source
          </Typography>
        </div>
        <div align='center'>
          <Box sx={{ mt: "5%", background: 'inherit', width: '21vw', height: '5.6vw' }}>
            <Stack direction='row' justifyContent='space-between'>
              <Typography sx={{ mt: '0.56vw', fontSize: '1vw' }}>{withCommas(30000)}</Typography>
              <Input disableUnderline={true} id="AnnualIncome" variant="standard" value={withCommas(annualIncome)} sx={{ pl: '2vw', border: 0, color: "#000000", fontSize: '1.4vw', fontWeight: 'bold', width: "10vw" }}
                onChange={onClickFun} InputProps={{}} />
              <Typography sx={{ mt: '0.56vw', fontSize: '1vw' }}>{withCommas(1000000)}</Typography>
            </Stack>
            <Slider sx={{ color: '#000000', mt: 1, width: "22vw" }} aria-label="Income" value={annualIncome}
              min={30000} max={1000000} step={100} onChange={(e, newValue) => setAnnualIncome(newValue)} />
          </Box>
        </div>
        <div align='center'>
          <Typography sx={{ mt: '2.8vw', width: '18vw' }} fontSize='1.1vw' color='#000000' align='center'>
            Only use other sources and investments if you have document proofs to substantiate.
          </Typography>
        </div>
      </Box>
      <LeftbottomA leftbottom={leftbottom} />
    </Box>
    );
}

export default AnnualIncome;