import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button, Input} from "@mui/material";
import { getAnalytics, logEvent } from "firebase/analytics";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/bg-10@1x.png'
import {  useDispatch, 
          useSelector } from 'react-redux';
import { Centerbottom, LeftbottomA, RightBlockEB, RightBlockSPBP, RightBlockSPBPRate } from "./RightBlock";
import { _debug } from "../../constants";
import { borrowerChoiceI, calcPropfinOptions, borrowerProfile, borrowerProfileStatus,updateIsNewEmployer } from "../../actions/borrower";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { rspvw } from "./styles";
export function EmployerSupportDt() {
  const { isLoading,
    bProfile,
    bProfileStatus,
    bChoiceE,
    bChoiceB,
    bChoiceD,
    selectedProp,
    bfinancialProfile,
    bChoiceF
  } = useSelector((state)=>state.borrower);
const navigate = useNavigate();
const location = useLocation();
const dispatch = useDispatch();
const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 

  
  
  const [yesOn,setYesOn]  = React.useState(false)
  React.useEffect(()=>{
    if((bProfile.firstName.length > 3) && (isValidEmail(bProfile.emailId)) && (bProfile.employer.companyName.length > 3)){
      setYesOn(true);
    }else{
      setYesOn(false);
    }
  },[bProfile]) 

  const analytics = getAnalytics();
  logEvent(analytics, 'employersupportdt', { name: 'employersupportdt'});

  function onClickFun(e) {
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch (buttonId) {
      case "eyes":
        navigate("/EmployerSupportDtExtend", { replace: false });
      dispatch(updateIsNewEmployer(false));

        break;
      case "eno": navigate("/EmployerSupportDtReg", { replace: false }); 
    
      dispatch(updateIsNewEmployer(true));

      break;
      case "eself":

        // const propFinRequest1 = {
        //   selectedPropertyPrice: selectedProp?.prop.offerPrice,
        //   proposedPropertyPrice: selectedProp?.prop.offerPrice,
        //   financeType: bChoiceB === 'firsthome' || bChoiceB === 'nexthome' ? 'primary' : 'secondary',
        //   loanTerm: bChoiceE?.loanTerm,
        //   creditScore: bfinancialProfile?.ficoScore,
        //   annualIncome: bfinancialProfile?.annualIncome,
        //   upfront: bChoiceE?.upfront,
        //   financeDocs: bfinancialProfile?.financeDocs,
        //   creditEvents: bChoiceE?.creditEvents,
        //   houseEvents: bChoiceE?.creditEvents,
        //   monthlyOutgoing: bfinancialProfile?.monthlyOutgoing,
        //   monthlyBudget: bChoiceE?.monthlyBudget,
        //   emailId: 'amitkd4@gmail.com'
        // }
        // if (_debug === 'debug') console.log("Compare Finance Request", propFinRequest1);
        // dispatch(borrowerChoiceI(propFinRequest1));
        // navigate("/SmartChoice", { replace: false })

        var employmentType='';
        employmentType= "Self Employed";
        dispatch(borrowerProfile({...bProfile,employmentType:employmentType}));
        dispatch(borrowerProfileStatus({...bProfileStatus,employmentProvided:1}));
        navigate("/BorrowerProfileEmp", { replace: false });
          dispatch(updateIsNewEmployer(false));

        break;
      default: break;
    }
  }

  return(
    isLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
      <React.Fragment><CssBaseline/>
             <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
              <Navbar pathname={location.pathname}/>
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
                { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
              
                <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <div align='center'>
                <Typography sx={{ color: '#000000', mt: '11.42vw', fontSize: '1.14vw', textAlign: 'center' }}>
                Invite your Employer
                </Typography>

                <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="eyes" className='hexBtnCenter'
                    disabled={!yesOn}
                    onClick={onClickFun}>
                    <HexButton titleText="Yes" type={yesOn ? 'base' : 'disabled'} fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="eno" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="No" type='base' fontSize='24' /></Button>
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="eself" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Self Employed" type='base' fontSize='24' /></Button>
                </Stack>
                <Centerbottom navigateForward="EmployerSupportDt" />
              </div>
          </Box>
        <RightBlockSPBPRate/>
      </Box>
    </Box>
    </React.Fragment>
  ));
}

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

function LeftBlock () {

  const dispatch = useDispatch();
  const { bChoiceE,
          bfinancialProfile,
          bProfile
        } = useSelector((state)=>state.borrower);
  // const [name,setName] = React.useState(bProfile.firstName+' '+bProfile.lastName)
  // const [employerEmail,setEmployerEmail] = React.useState('aivanna.maraea@nestfuel.com')
  // const [employerContact,setEmployerContact] = React.useState('Rajesh Pai')
  // const [contactEmail,setContactEmail] = React.useState('rajesh.pai@nestfuel.com')

  const [name,setName] = React.useState(bProfile.firstName+' '+bProfile.lastName)
  const [employerName,setEmployerName] = React.useState(bProfile?.employer?.companyName)
  const [contactEmail,setContactEmail] = React.useState(bProfile?.emailId)
  
  if((bProfile.firstName.length > 3) && (isValidEmail(bProfile.emailId)) && (bProfile.employer.companyName.length > 3)){
    var flagVal = false;
  }else{
    var flagVal = true;
  }
  const [dataErr, setDataErr] = React.useState(flagVal);
  
  
  
  function onClickFun(e) {
    const buttonId  = e.currentTarget.id
    const input     = e.currentTarget.value
    switch(buttonId) {
      case "fname": 
        const nameA   = input.split(" ")
        const length  = nameA.length
        const firstName = (length > 2) ? nameA[0].concat(" ",nameA[1]) : nameA[0]
        const lastName  = (length > 2) ? nameA[2]: nameA[1]
        dispatch(borrowerProfile({...bProfile,firstName:firstName, lastName:lastName})); 
        setName(input)
        break;
      case "empName": 
        const companyName = {...bProfile.employer, companyName:input};
        dispatch(borrowerProfile({...bProfile, employer:companyName }));
        setEmployerName(input);
        break;
      case "userEmail":
        dispatch(borrowerProfile({...bProfile,emailId:input}));
        setContactEmail(input)
        break;
      // case "relContact": 
      //   const relevantContact = {...bProfile.employer, relevantContact:input};
      //   dispatch(borrowerProfile({...bProfile, employer:relevantContact }));
      //   break;
      // case "contactEmail": 
      //   const contactEmail = {...bProfile.employer, contactEmail:input};
      //   dispatch(borrowerProfile({...bProfile, employer:contactEmail }));
      //   break;
      default: break;
    }

    if((bProfile.firstName.length > 3) && (isValidEmail(bProfile.emailId)) && (bProfile.employer.companyName.length > 3)){
      setDataErr(false);
    }else{
      setDataErr(true);
    }
    console.log(dataErr);    
  }
  function validateAllField(){
    if((bProfile.firstName.length > 3) && (isValidEmail(bProfile.emailId)) && (bProfile.employer.companyName.length > 3)){
      setDataErr(false);
    }else{
      setDataErr(true);
    }
  }

  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box', position: 'relative', position: 'relative',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height:'46.75vw'
    }}>
      <Box sx={{ mt: '8.2vw', ml: '7.3vw', width: '26vw', height: '10vw' }}>
        <Typography sx={{
          color: '#31312C', width: '24vw',
          fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          Get your employer to know Nestfuel
        </Typography>
        <Typography sx={{
          color: '#000000', width: '20vw', mt: '1.7vw',
          fontSize: '1.35vw', fontFamily: 'Roboto', fontWeight: 400, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          Confirm your employer's name and your details
        </Typography>
      </Box>
      <Box sx={{ mt: '2vw', ml: '7.3vw', width: '28vw', height: '14vw', pr: '1vw' }}>
        <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw', width: '12vw', justifyContent: 'space-between' }} color='#000000'>Employer name</Typography>
          <Input disableUnderline={true} id="empName" value={employerName} sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '1vw', height: '2.4vw', width: "14vw", border: 1, borderRadius: '0.4vw' }}
            inputProps={{ style: { textAlign: 'center' } }} placeholder="Employer name" onChange={onClickFun} onBlur={validateAllField} />
        </Stack>
        <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw', width: '12vw' }} color='#000000'>Your full name</Typography>
          <Input disableUnderline={true} id="fname" value={name} sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '1vw', height: '2.4vw', width: "14vw", border: 1, borderRadius: '0.5vw' }}
            inputProps={{ style: { textAlign: 'center' } }} placeholder="Your First & Last Name" onChange={onClickFun} onBlur={validateAllField} />
        </Stack>
        <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw', width: '12vw', justifyContent: 'space-between' }} color='#000000'>Your email address</Typography>
          <Input disableUnderline={true} id="userEmail" value={contactEmail} sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '1vw', height: '2.4vw', width: "14vw", border: 1, borderRadius: '0.4vw' }}
            inputProps={{ style: { textAlign: 'center' } }} placeholder="Your email address" onChange={onClickFun} onBlur={validateAllField} />
        </Stack>

        {
          dataErr 
          ? 
            <>
            <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
              <Typography sx={{ fontSize: '0.7vw' }} color='#000000'>&nbsp;</Typography>
              <Typography sx={{ fontSize: '0.7vw' }} color='#B83B46'> Provide valid information </Typography>
            </Stack>
            </>
          : ''
        }

        

        {/* <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1vw', width: '10vw', justifyContent: 'space-between' }} color='#000000'>Employer email:</Typography>
          <Input disableUnderline={true} id="empEmail" value={bProfile?.employer?.employerEmail} sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '1vw', height: '2.4vw', width: "14vw", border: 1, borderRadius: '0.4vw' }}
            inputProps={{ style: { textAlign: 'center' } }} onChange={onClickFun} />
        </Stack>
        <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1vw', width: '10vw', justifyContent: 'space-between' }} color='#000000'>Relevant Contact:</Typography>
          <Input disableUnderline={true} id="relContact" value={bProfile?.employer?.relevantContact} sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '1vw', height: '2.4vw', width: "14vw", border: 1, borderRadius: '0.4vw' }}
            inputProps={{ style: { textAlign: 'center' } }} onChange={onClickFun} />
        </Stack>
        <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1vw', width: '10vw', justifyContent: 'space-between' }} color='#000000'>Contact email:</Typography>
          <Input disableUnderline={true} id="contactEmail" value={bProfile?.employer?.contactEmail} sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '1vw', height: '2.4vw', width: "14vw", border: 1, borderRadius: '0.4vw' }}
            inputProps={{ style: { textAlign: 'center' } }} onChange={onClickFun} />
        </Stack> */}
      </Box>
      <LeftbottomA leftbottom={leftbottom} />
    </Box>
  );
}

export default EmployerSupportDt