import {  MODIFY_D,UPDATE_A,UPDATE_B,UPDATE_C,UPDATE_D,ADDETO_D, UPDATE_E,UPDATE_F,SELPROP,PROPROP, UPDATE_PropertyDetailNew,
    UPDATE_H,UPDATE_I1,UPDATE_I2,UPDATE_J,UPDATE_Profile,UPDATE_ProfileNew,UPDATE_EMP_MORTGAGE_PROFILE,UPDATE_fProfile,UPDATE_fProfileNew, UPDATE_PBPR, 
    UPDATE_PP, UPDATE_LT, UPDATE_IO, UPDATE_RIM, ADJUST, INIT, REVIEW, REFIAP, REFIMAX, UPDATE_LocalStorage, USERUPDATE,
    REFIINIT, DIMENSIONS, UPDATE_CP, START_LOADING,START_EMPLOYER_LOADING,START_BCHOICED_LOADING,END_BCHOICED_LOADING,END_EMPLOYER_LOADING, END_LOADING, REGCOMP, _debug, UPDATE_MTG, UPDATE_ProfileS, UPDATE_DSF, UPDATE_DSFE, UPDATE_DSFB, UPDATE_DSFEM, SIGNIN, LOGOUT, SIGNUP, ISSIGNUP, ISSIGNIN, PROFILEC, LOGGEDIN, NAVFLAG, EMBFLOWFLAG,IS_PROFILE_INFO_CHANGED, IS_LOACTION_CHANGED,GENERATEDUSERID, USERFINANCEUPDATE, USERPROPERTYUPDATE, defaultEmployer, NAVEMPLOYER, UPDATE_DSF1, UPDATE_SWAPRATE, UPDATE_SPREAD, UPDATE_LOANTYPE, UPDATE_HOUSETYPE, UPDATE_SPREADONE, UPDATE_SWAPRATEONE, NEWEMPLOYER, UPDATE_PROFILEJOURNEY,UPDATE_PROPDET, PROPCHANGE,COUNTRYCODEFLAG,STATECODEFLAG,BREADCRUMBFLAG, UPDATE_DSFEMB, UPDATE_DEMB, UPDATE_SAVING_EMB, UPDATE_DSF_EMB, UPDATE_DSFUPDATE, IS_INCOME_SAVING_CHANGED, UPDATE_PREPOPULATEDDSF, BORROWERFLOWFLAG, UPDATE_RATES,ISFIRSTTIMEBUYER } from '../constants';
import CryptoJS from 'crypto-js'

//Lincoln Street, Denver, CO, USA
// const location=  {  
//             country: "United States",
//             county: "Arapahoe County",
//             locality: "Aurora",
//             name: "Town Center at Aurora",
//             position: {
//               latitude: 39.708493,
//               longiitude: -104.824081,
//             },
//             region: "Colorado",
//             zipcode:80012,
//             type:'home'
// };

const location=  {  
country: "United States",
county: "County",
locality: "Denver County",
name: "Lincoln Street",
position: {
latitude: 39.7320529,
longiitude: -104.9883101,
},
region: "Colorado",
zipcode:80012,
type:'home'
};
const profileJourney = {

locationArea: false,
locationZipcode: false,
economicsIncome: false,
economicsCash:false,
creditEmployment:false,
creditOutgoing:false,
creditFICO:false,
creditCreditEvents:false,
propertyPreferences:false,
propertyViewing:false,
propertyShortlisted:false,
propertySubmitOffer:false,
propertyOfferAccepted:false,
financeTerm:false,
financeType:false,
financeRate:false,
personalContact:false,
personalName:false,
personalDOB:false,
personalSSN:false,
benefitsEmployer:false,
benefitsPersonal:false,
verificationIdentity:false,
verificationIncome:false,
verificationBanks:false,
verificationTax:false,
verificationAssets:false,
applicationAppraisal:false,
applicationSigned1003:false,
applicationSubmission:false,
completionKeys:false,
completionFunding:false,
completionAccepted:false,
}

// nameProvided:0,
// emailProvided:0,
const ratesInfo ={
  rate:0,
  apr:0
}
const profileStatus = {
      incomeProvided:0,
      // upfrontProvided:0,            
      employmentProvided:0,
      residencyProvided:0,
      incomeSourceProvided:0,
      taxdocProvided:0,
      bankdocProvided:0,
      payStubProvided:0,
      ficoProvided:0,
      bankruptcyProvided:0,
      ownershipProvided:0,
      outgoingProvided:0,
      govtloanEligibilityProvided:0,
      monthlybudgetProvided:0 
}
   
const profile = { 
        messageCount: 0,
        selectedDate:'',
        profileUpdate:'',
        deleted: false,
        ifBorrower: false,
        coBorrowerId: '',
        middleName: '', 
        coageB:'',
        marriageStatus:'',
        emailVerified: false,
        maritalStatus: '',
        photoURL: '',               
        userId: '',
        emailId: '',
        firstName: 'Guest',
        lastName: 'User',
        gender: '',
        dateOfBirth: 'MMM-DD-YYYY',
        address: {
            street: ' ',
            city: ' ',
            state: 'CO',
            zipCode: ' ',
        },
        phoneNumber: '',
        employmentType: 'Employed',
        govtLoanEligibility: 'No',
        residency: 'US Citizen',
        employer: {
           
            employerEmail: ' ',
            relevantContact: ' ',
            contactEmail: ' ',
            companyName: ' ',
            companyAddress: {
                street: ' ',
                city: ' ',
                state: 'CO',
                zipCode: '',
            },
            nestfuelEmployerId: 'Nest20345',
            nestfuelEmployerDiscount: 1.2,
            workEmail: ' ',
            jobTitle: ' ',
            contactPersonName: ' ',
        }
      }

const employeeMortgageInfo = {
  name:'',
  displayName: '',
  employerIdentifier:'',
  assumedSalary:'',
  assumedFico:'',
  assumedLtv:'',
  assumedUpfront:'',
  saving:'',
  empPayPoints:'',
  address: {
    lat: 39.7310435,
    lon: -104.8755231,
    city: "Denver county",
    searchString: "East 10th Avenue, East, Denver, CO, USA"
    }
}
const finProfileNew = {
      income: {
          annualIncome: 100000,
          incomeSource: 'Salary',
          additionalIncome: 0,
          additionalIncomeSource: '',
          outgoing: 600,
          upfront: 50000,
          ficoScore: 740,
          monthlyBudget: 4200,
          frontEndDTI: 0,
          backEndDTI: 0,
          maxLoanAffordability: 0,
          bankruptcy:'Never',
      },
      finance: {
          financeType: 'primary',
          creditEvents: 0,
          houseEvents: 0,
          ownershipType: 'Sole-Owner Sole-Borrower',
          loanAmount: 0,
          loanTerm: 30,
          percentOwnership:100
      },
      financeDocs: {
          dTax: true,
          dBank12: true,
          dBank24: false,
          dPStubs: true,
          noDocs: false,
          docsAll: true,
      }
}
const propertyDetailNew  = {
  propertyPrefs:{
    minBeds : 1,
    maxBeds : 3,
    minBaths : 1,
    maxBaths : 3,
    homeType : "Single Family Home",
    homeTypesAll : ['Single Family Home', "Condominium", "Town House", "Co Operative","Multi Family Home", "Land"],
    minFlSize : 1000,
    maxFlSize : 2000,
    yearBuilt : "1995",
},
      mortgageDetails: {
          price: 0,
          offerPrice: 0,
          mortgageType: '',
          nestFuelFinanceOption: '',
          closingCost: 0,
          financeType: 'primary',
          loanAmount: 0,
          loanTerm: 30,
          percentLTV: 0,
          upfront: 0,
          totalCost: 0,
          interestRate: 0,
          apr: 0,
          propTax: 0,
          pmi: 0,
          monthlyEMI: 0,
          insurance: 0,
    },
    propertiesViewed: [],
    selectedProperty: {
        mlsId: '',
        address: {
            street: '',
            city: '',
            state: '',
            zipCode: 0,
        },
        thumbnail: '',
    },
}
         
const finProfile =  { 
    financeDocs:'true',
    dTax:'true',
    dBank12:'true',
    dBank24:'false',
    dPStubs:'true',
    dNoDocs:'false',
    annualIncome: 100000,
    incomeSource: 'Salary',
    monthlyOutgoing: 600,
    ficoScore:740,
    frontEndDTI:0, 
    backEndDTI:0, 
    maxAffordability:0,
    bankruptcy:'Never',
}
const propFinance = { 
    mlsId:'',
    propId_in_D:0,
    financeType:'firsthome', 
    cashoutAmount:120000,
    ownership: 'Sole-Owner Sole-Borrower',
    loanTerm: 30,
    propertyPrice:450000,
    outStandingLoan: 380000,
    upfront:50000,
    creditEvents:0,
    houseEvents:0, 
    monthlyBudget:4200,
    percentOwnership:100
};
const swapRate = [
      {tenure:10,rate:3.4},
      {tenure:15,rate:3.9},
      {tenure:20,rate:4.4},
      {tenure:25,rate:4.7},
      {tenure:30,rate:5.1},
      {tenure:35,rate:5.5},
      {tenure:35,rate:6.0}
          ]
const spreadTable = [
{
loanType:'QM',
homeType:'primary',
spArray: [ 
  { minFico:800, maxFico:840, minLtv:0,  maxLtv:50, spread: 2.5 },
  { minFico:800, maxFico:840, minLtv:51, maxLtv:60, spread: 2.5 },
  { minFico:800, maxFico:840, minLtv:61, maxLtv:70, spread: 2.5 },
  { minFico:800, maxFico:840, minLtv:71, maxLtv:80, spread: 2.5 },
  { minFico:800, maxFico:840, minLtv:81, maxLtv:85, spread: 2.5 },
  { minFico:800, maxFico:840, minLtv:86, maxLtv:90, spread: 2.8 },
  { minFico:800, maxFico:840, minLtv:91, maxLtv:85, spread: 2.8 },
  { minFico:780, maxFico:799, minLtv:0,  maxLtv:50, spread: 2.5 },
  { minFico:780, maxFico:799, minLtv:51, maxLtv:60, spread: 2.5 },
  { minFico:780, maxFico:799, minLtv:61, maxLtv:70, spread: 2.5 },
  { minFico:780, maxFico:799, minLtv:71, maxLtv:80, spread: 2.5 },
  { minFico:780, maxFico:799, minLtv:81, maxLtv:85, spread: 2.5 },
  { minFico:780, maxFico:799, minLtv:86, maxLtv:90, spread: 2.8 },
  { minFico:780, maxFico:799, minLtv:91, maxLtv:85, spread: 2.8 },
  { minFico:760, maxFico:779, minLtv:0,  maxLtv:50, spread: 2.5 },
  { minFico:760, maxFico:779, minLtv:51, maxLtv:60, spread: 2.5 },
  { minFico:760, maxFico:779, minLtv:61, maxLtv:70, spread: 2.5 },
  { minFico:760, maxFico:779, minLtv:71, maxLtv:80, spread: 2.5 },
  { minFico:760, maxFico:779, minLtv:81, maxLtv:85, spread: 2.5 },
  { minFico:760, maxFico:779, minLtv:86, maxLtv:90, spread: 2.8 },
  { minFico:760, maxFico:779, minLtv:91, maxLtv:85, spread: 2.8 },
  { minFico:740, maxFico:759, minLtv:0,  maxLtv:50, spread: 2.5 },
  { minFico:740, maxFico:759, minLtv:51, maxLtv:60, spread: 2.5 },
  { minFico:740, maxFico:759, minLtv:61, maxLtv:70, spread: 2.5 },
  { minFico:740, maxFico:759, minLtv:71, maxLtv:80, spread: 2.5 },
  { minFico:740, maxFico:759, minLtv:81, maxLtv:85, spread: 2.5 },
  { minFico:740, maxFico:759, minLtv:86, maxLtv:90, spread: 2.8 },
  { minFico:740, maxFico:759, minLtv:91, maxLtv:85, spread: 2.8 },
  { minFico:720, maxFico:739, minLtv:0,  maxLtv:50, spread: 2.5 },
  { minFico:720, maxFico:739, minLtv:51, maxLtv:60, spread: 2.5 },
  { minFico:720, maxFico:739, minLtv:61, maxLtv:70, spread: 2.5 },
  { minFico:720, maxFico:739, minLtv:71, maxLtv:80, spread: 2.5 },
  { minFico:720, maxFico:739, minLtv:81, maxLtv:85, spread: 2.5 },
  { minFico:720, maxFico:739, minLtv:86, maxLtv:90, spread: 2.8 },
  { minFico:720, maxFico:739, minLtv:91, maxLtv:85, spread: 2.8 },
  { minFico:700, maxFico:719, minLtv:0,  maxLtv:50, spread: 2.5 },
  { minFico:700, maxFico:719, minLtv:51, maxLtv:60, spread: 2.5 },
  { minFico:700, maxFico:719, minLtv:61, maxLtv:70, spread: 2.5 },
  { minFico:700, maxFico:719, minLtv:71, maxLtv:80, spread: 2.5 },
  { minFico:700, maxFico:719, minLtv:81, maxLtv:85, spread: 2.5 },
  { minFico:700, maxFico:719, minLtv:86, maxLtv:90, spread: 2.8 },
  { minFico:700, maxFico:719, minLtv:91, maxLtv:85, spread: 2.8 },
  { minFico:680, maxFico:699, minLtv:0,  maxLtv:50, spread: 2.5 },
  { minFico:680, maxFico:699, minLtv:51, maxLtv:60, spread: 2.5 },
  { minFico:680, maxFico:699, minLtv:61, maxLtv:70, spread: 2.5 },
  { minFico:680, maxFico:699, minLtv:71, maxLtv:80, spread: 2.5 },
  { minFico:680, maxFico:699, minLtv:81, maxLtv:85, spread: 2.5 },
  { minFico:680, maxFico:699, minLtv:86, maxLtv:90, spread: 2.8 },
  { minFico:680, maxFico:699, minLtv:91, maxLtv:85, spread: 2.8 },
  { minFico:660, maxFico:679, minLtv:0,  maxLtv:50, spread: 2.5 },
  { minFico:660, maxFico:679, minLtv:51, maxLtv:60, spread: 2.5 },
  { minFico:660, maxFico:679, minLtv:61, maxLtv:70, spread: 2.5 },
  { minFico:660, maxFico:679, minLtv:71, maxLtv:80, spread: 2.5 },
  { minFico:660, maxFico:679, minLtv:81, maxLtv:85, spread: 2.5 },
  { minFico:660, maxFico:679, minLtv:86, maxLtv:90, spread: 2.8 },
  { minFico:660, maxFico:679, minLtv:91, maxLtv:85, spread: 2.8 },
  { minFico:640, maxFico:659, minLtv:0,  maxLtv:50, spread: 2.5 },
  { minFico:640, maxFico:659, minLtv:51, maxLtv:60, spread: 2.5 },
  { minFico:640, maxFico:659, minLtv:61, maxLtv:70, spread: 2.5 },
  { minFico:640, maxFico:659, minLtv:71, maxLtv:80, spread: 2.5 },
  { minFico:640, maxFico:659, minLtv:81, maxLtv:85, spread: 2.5 },
  { minFico:640, maxFico:659, minLtv:86, maxLtv:90, spread: 2.8 },
  { minFico:640, maxFico:659, minLtv:91, maxLtv:85, spread: 2.8 },
  { minFico:500, maxFico:639, minLtv:0,  maxLtv:50, spread: 2.5 },
  { minFico:500, maxFico:639, minLtv:51, maxLtv:60, spread: 2.5 },
  { minFico:500, maxFico:639, minLtv:61, maxLtv:70, spread: 2.5 },
  { minFico:500, maxFico:639, minLtv:71, maxLtv:80, spread: 2.5 },
  { minFico:500, maxFico:639, minLtv:81, maxLtv:85, spread: 2.5 },
  { minFico:500, maxFico:639, minLtv:86, maxLtv:90, spread: 2.8 },
  { minFico:500, maxFico:639, minLtv:91, maxLtv:85, spread: 2.8 },
]
},
{
  loanType:'QM',
  homeType:'secondary',
  spArray: [ 
      { minFico:800, maxFico:840, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:800, maxFico:840, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:91, maxLtv:85, spread: 2.8 },
  ]
},
{
  loanType:'QM',
  homeType:'investment',
  spArray: [ 
      { minFico:800, maxFico:840, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:800, maxFico:840, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:91, maxLtv:85, spread: 2.8 },
  ]
},
{
  loanType:'nonQM',
  homeType:'primary',
  spArray: [ 
      { minFico:800, maxFico:840, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:800, maxFico:840, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:91, maxLtv:85, spread: 2.8 },
  ]
},
{
  loanType:'nonQM',
  homeType:'secondary',
  spArray: [ 
      { minFico:800, maxFico:840, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:800, maxFico:840, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:91, maxLtv:85, spread: 2.8 },
  ]
},
{
  loanType:'nonQM',
  homeType:'investment',
  spArray: [ 
      { minFico:800, maxFico:840, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:800, maxFico:840, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:91, maxLtv:85, spread: 2.8 },
  ]
},
{
  loanType:'nonQMHigh',
  homeType:'primary',
  spArray: [ 
      { minFico:800, maxFico:840, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:800, maxFico:840, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:91, maxLtv:85, spread: 2.8 },
  ]
},
{
  loanType:'nonQMHigh',
  homeType:'secondary',
  spArray: [ 
      { minFico:800, maxFico:840, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:800, maxFico:840, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:91, maxLtv:85, spread: 2.8 },
  ]
},
{
  loanType:'nonQMHigh',
  homeType:'investment',
  spArray: [ 
      { minFico:800, maxFico:840, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:800, maxFico:840, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:800, maxFico:840, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:780, maxFico:799, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:780, maxFico:799, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:760, maxFico:779, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:760, maxFico:779, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:740, maxFico:759, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:740, maxFico:759, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:720, maxFico:739, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:720, maxFico:739, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:700, maxFico:719, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:700, maxFico:719, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:680, maxFico:699, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:680, maxFico:699, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:660, maxFico:679, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:660, maxFico:679, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:640, maxFico:659, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:640, maxFico:659, minLtv:91, maxLtv:85, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:0,  maxLtv:50, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:51, maxLtv:60, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:61, maxLtv:70, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:71, maxLtv:80, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:81, maxLtv:85, spread: 2.5 },
      { minFico:500, maxFico:639, minLtv:86, maxLtv:90, spread: 2.8 },
      { minFico:500, maxFico:639, minLtv:91, maxLtv:85, spread: 2.8 },
  ]
},

]
const encryptAllow = (_debug === 'development') ? false : true;
function encFun(data){
var responseR='';
if(encryptAllow){
  responseR = CryptoJS.AES.encrypt(data, process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString()
}else{
  responseR = (data)
}
return responseR;
}
function decFun(){
var responseR='';
if(encryptAllow){
  responseR = CryptoJS.AES.decrypt(window.sessionStorage.getItem("borrowerProfile"),process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString(CryptoJS.enc.Utf8);
}else{
  responseR = (window.sessionStorage.getItem("borrowerProfile"))
}
return responseR;
}

const defaultState = {
  isLoading: false,
  isEmployerLoading: true,
  isBchoiceDLoading: false,
  isProfileInfoChanged: false,
  isLocationChanged: false,
  isIncomeSavingChanged: false,
  isSignup: false,
  isLoggedin: false,
  propChange: false,
  profileComplete:0,
  profilePercentComplete: 0.0,
  swapRate: swapRate,
  spreadTable: spreadTable,
  authData:{},
  bChoiceA:'purchase',
  bChoiceB:'firsthome',
  bChoiceC:location,
  bDimensions:{height: 1464, width: 2880},
  bChoiceD:[],
  bChoiceDEMB:[],
  bChoiceD1:[],
  bChoiceDSF:[],
  bChoicePrePoplulatedDSF:{},
  bChoiceDSF_EMB:[],
  bChoiceDSFEMB:[],
  bChoiceDSFB:{},
  bChoiceDSFE:[],
  bChoiceDSFEM:{},
  bChoiceE:propFinance,
  bProfileJourney:profileJourney,
  bChoiceF:{},
  selectedProp:{},
  proposedProp:{},
  bChoiceH:{},
  bChoiceIone:{},
  bChoiceItwo: {},
  bChoiceCheapOne:{},
  bChoiceCheapTwo:{},
  bchoiceK:{},
  bChoiceKMax:{},
  bChoiceKInit:{},
  rates: ratesInfo,
  bChoiceL:defaultEmployer,
  bProfileStatus:profileStatus,
  bProfile:profile,
  employeeMortgageBenifitProfile:employeeMortgageInfo,
  bfinancialProfile:finProfileNew,
  bPropertyDetail:propertyDetailNew,
  bProfileNew:{},
  bfinancialProfileNew:{},
  bpropertyDetailNew:{},
  navFlag:false,
  isEmbFlow:false,
  isBorrowerFlow:false,
  generatedUserId:'',
  countryCodeFlag:'IN',
  stateCodeFlag:'DL',
  breadcrumbData:'',
  isfirstTime:true,
  loanType:'QM',
  houseType:'primary',
  navEmployer:true,
  isNewEmployer:false,
}

export default (
  state = window.sessionStorage.getItem("borrowerProfile") !== null
      ? JSON.parse(decFun())
      : { isLoading: false,
          isEmployerLoading: true,
          isBchoiceDLoading: false,
          isProfileInfoChanged: false,
          isLocationChanged: false,
          isIncomeSavingChanged: false,
          isSignup: false,
          isLoggedin: false,
          propChange: false,
          profileComplete:0,  
          profilePercentComplete: 0.0,
          swapRate: swapRate,
          spreadTable: spreadTable,
          authData:{},
          bChoiceA:'purchase', 
          bChoiceB:'firsthome', 
          bChoiceC:location,
          bDimensions:{height: 1464, width: 2880},
          bChoiceD:[], 
          bChoiceDEMB:[],
          bChoiceD1:[],
          bChoiceDSF:[],
          bChoicePrePoplulatedDSF:{},
          bChoiceDSF_EMB:[],
          bChoiceDSFEMB:[],
          bChoiceDSFB:{},
          bChoiceDSFE:[],
          bChoiceDSFEM:{},
          bChoiceE:propFinance, 
          bProfileJourney:profileJourney,
          bChoiceF:{}, 
          selectedProp:{} , 
          proposedProp:{},
          bChoiceH:{}, 
          bChoiceIone:{}, 
          bChoiceItwo: {},
          bChoiceCheapOne:{},
          bChoiceCheapTwo:{},
          bchoiceK:{},
          bChoiceKMax:{},
          bChoiceKInit:{},
          rates: ratesInfo,
          bChoiceL:defaultEmployer,
          bProfileStatus:profileStatus,
          bProfile:profile, 
          employeeMortgageBenifitProfile:employeeMortgageInfo,
          bfinancialProfile:finProfileNew,
          bPropertyDetail:propertyDetailNew,
          bProfileNew:{},                            
          bfinancialProfileNew:{},
          bpropertyDetailNew:{},
          navFlag:false,
          isEmbFlow:false,
          isBorrowerFlow:false,
          generatedUserId:'',
          countryCodeFlag:'IN',
          stateCodeFlag:'DL',
          breadcrumbData:'',
          isfirstTime:true,
          

          loanType:'QM',
          houseType:'primary',
          navEmployer:true, 
          isNewEmployer:false,  

                  },action) => {
var stateA = {}
var encryptedStateA = '' 
switch (action.type) {
  case START_LOADING:
    stateA = { ...state, isLoading: true };
    //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
    encryptedStateA = encFun(JSON.stringify(stateA));
    window.sessionStorage.setItem("borrowerProfile", encryptedStateA)
    return stateA
  case START_EMPLOYER_LOADING:
    stateA = { ...state, isEmployerLoading: true };
    //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
    encryptedStateA = encFun(JSON.stringify(stateA));
    window.sessionStorage.setItem("borrowerProfile", encryptedStateA)
    return stateA
  case START_BCHOICED_LOADING:
      stateA = { ...state, isBchoiceDLoading: true };
      //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
      encryptedStateA = encFun(JSON.stringify(stateA));
      window.sessionStorage.setItem("borrowerProfile", encryptedStateA)
      return stateA
  case IS_PROFILE_INFO_CHANGED:
    stateA = { ...state, isProfileInfoChanged: true };
    //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
    encryptedStateA = encFun(JSON.stringify(stateA));
    window.sessionStorage.setItem("borrowerProfile", encryptedStateA)
    return stateA
  case IS_LOACTION_CHANGED:
    stateA = { ...state, isLocationChanged: true };
    //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
    encryptedStateA = encFun(JSON.stringify(stateA));
    window.sessionStorage.setItem("borrowerProfile", encryptedStateA)
    return stateA
  case IS_INCOME_SAVING_CHANGED:
  stateA = { ...state, isIncomeSavingChanged: true };
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile", encryptedStateA)
  return stateA

  case PROPCHANGE:
    const propChange = state.propChange
    stateA = { ...state, propChange: !propChange };
    //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
    encryptedStateA = encFun(JSON.stringify(stateA));
    window.sessionStorage.setItem("borrowerProfile", encryptedStateA)
    return stateA

case END_LOADING: 
  stateA =  { ...state, isLoading: false };
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
case END_EMPLOYER_LOADING: 
  stateA =  { ...state, isEmployerLoading: false };
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
case END_BCHOICED_LOADING:
    stateA = { ...state, isBchoiceDLoading: false };
    //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
    encryptedStateA = encFun(JSON.stringify(stateA));
    window.sessionStorage.setItem("borrowerProfile", encryptedStateA)
    return stateA
case ISSIGNUP: 
  stateA =  { ...state, isSignup: true };
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
case ISSIGNIN: 
  stateA = { ...state, isSignup: false };
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
case LOGGEDIN: 
  stateA = {...state, isLoggedin: true };
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
case PROFILEC: 
  stateA = {...state, profileComplete:action.data}
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
case DIMENSIONS: 
  stateA = {...state, bDimensions:action.payload}
  if(_debug === 'development') console.log("Dimensions",stateA);
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_A: 
  const profileJourneyA = {...state.bProfileJourney, financeType: true}
  stateA = {...state, bChoiceA: action.payload};
  if(_debug === 'development') console.log("update A",stateA);
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_B: 
  const financeType = action.payload === ('firsthome' || action.payload === 'nexthome' )? 'primary' : (action.payload==='investment')?'investment':'secondary'
  const finprof = state.bfinancialProfile
  finprof.finance.financeType = financeType 
  const profileJourneyB = {...state.bProfileJourney, financeType: true}
  stateA = {...state, bChoiceB: action.payload, bfinancialProfile: finprof,bProfileJourney:profileJourneyB};
  if(_debug === 'development') console.log("update B",stateA,finprof);
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_C: 
  stateA = {...state, bChoiceC: action.payload};
  const profileJourneyC = {...state.bProfileJourney, locationArea: true, locationZipcode: true,}
  stateA = {...stateA,bProfileJourney:profileJourneyC}
  if(_debug === 'development') console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_D: 
  stateA = {...state, bChoiceD: action.payload};
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  if(_debug === 'development') console.log(stateA)
  return stateA;
case UPDATE_DEMB: 
  stateA = {...state, bChoiceDEMB: action.payload};
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  if(_debug === 'development') console.log(stateA)
  return stateA;
case MODIFY_D:
  // dispatch({ type: START_BCHOICED_LOADING});
  const bChoiceD1 = state.bChoiceD.map(a => {return {...a}})
  stateA = {...state, bChoiceD: action.payload,bChoiceD1:bChoiceD1};
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  // dispatch({ type: END_BCHOICED_LOADING});

  if(_debug === 'development') console.log(stateA)
  return stateA
case UPDATE_MTG:
  stateA = {...state, maxMortgage:action.payload};
 // if(_debug === 'development') console.log("Update Morgage Calculations",stateA);
 //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
 encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case ADDETO_D: 
  var payload = action.payload
  state.bChoiceD.map((itemS) => payload = payload.filter((itemA) => itemA.mlsId !== itemS.mlsId))
//  if(_debug === 'development') console.log("new properties",payload);
  stateA = {...state, bChoiceD: [...state.bChoiceD, payload]};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
case UPDATE_DSF: 
  stateA = {...state, bChoiceDSF: action.payload}   
  if(_debug === 'development')  console.log("UPDATE_DSF",stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_PREPOPULATEDDSF:
  state = {...state, bChoicePrePoplulatedDSF: action.payload}
  if(_debug === 'development')  console.log("UPDATE_PREPOPULATEDDSF",stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_DSFUPDATE:
stateA = {...state, bChoiceDSF: action.payload}   
if(_debug === 'development')  console.log("UPDATE_DSF",stateA)
//encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
encryptedStateA = encFun(JSON.stringify(stateA));
window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
return stateA;
case UPDATE_DSF_EMB: 
  stateA = {...state, bChoiceDSF_EMB: action.payload}   
  if(_debug === 'development')  console.log("UPDATE_DSF",stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_DSFEMB: 
  stateA = {...state, bChoiceDSFEMB: action.payload}   
  if(_debug === 'development')  console.log("UPDATE_DSFEMB",stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_DSF1: 
  const bChoiceDSF = state.bChoiceDSF.map((item,index) =>  {return index===action.indexS ? action.payload[0] : index===action.indexP ? action.payload[1] : item})
  stateA = {...state, bChoiceDSF: bChoiceDSF}   
  if(_debug === 'development')  console.log("UPDATE_DSF1",stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_DSFB: 
  stateA = {...state, bChoiceDSFB: action.payload}   
  if(_debug === 'development')  console.log("UPDATE_DSFB",stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;

case UPDATE_DSFEM: 
  const _bChoiceDSF = state.bChoiceDSF
  for ( var i = 0 ; i < _bChoiceDSF.length ; i++ ){
      _bChoiceDSF[i][2] = action.payload[i]
  }
  stateA = {...state, bChoiceDSF: _bChoiceDSF }
  if(_debug === 'development')  console.log("UPDATE_DSFEM",stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;

case UPDATE_DSFE: 
  const index1 = action.payload.index
  const _bChoiceDSFE = state.bChoiceDSFE || [{index:'abc'}]
  if( Array.isArray(_bChoiceDSFE) && _bChoiceDSFE?.length <=index1){
      _bChoiceDSFE.push(action.payload.list)
  }else{
      _bChoiceDSFE[index1]   = action.payload.list
  }
  stateA = {...state, bChoiceDSFE: _bChoiceDSFE}   
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  if(_debug === 'development')  console.log("UPDATE_DSFE",stateA)
  return stateA;
case UPDATE_E: 
  stateA = {...state, bChoiceE:action.payload};
  if(_debug === 'development') console.log("Update after E",stateA);
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_F: 
  stateA = {...state, bChoiceF: action.payload};
  if(_debug === 'development')  console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case SELPROP: 
  stateA = {...state, selectedProp: action.payload};
  if(_debug === 'development') console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case PROPROP: 
  stateA = {...state, proposedProp: action.payload};
  if(_debug === 'development') console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_H: 
  stateA = {...state, bChoiceH: action.payload};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  if(_debug === 'development') console.log(stateA)
  return stateA;
case UPDATE_I1: 
stateA = {...state, bChoiceIone: action.payload.listA, 
                  bChoiceItwo:action.payload.listB,
                  finOptS:{fin:action.payload.listA[0],property: 'selected'},
                  finOptP:{fin:action.payload.listB[0],property: 'proposed'}
                };
                //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
                encryptedStateA = encFun(JSON.stringify(stateA));
                window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
if(_debug === 'development')  console.log("Update I",stateA)
return stateA;
case UPDATE_IO: 
  stateA = action.payload.propOpt === 'selected' 
  ? {...state, bChoiceIone: state.bChoiceIone.map((item,index) => (index===1 ? action.payload.io : item)) }
  : {...state, bChoiceItwo: state.bChoiceItwo.map((item,index) => (index===1 ? action.payload.io : item)) }
  if(_debug === 'development') console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_PP: 
  stateA = action.payload.propOpt === 'selected' 
  ? {...state, bChoiceIone: state.bChoiceIone.map((item,index) => (index===2 ? action.payload.pp : item)) }
  : {...state, bChoiceItwo: state.bChoiceItwo.map((item,index) => (index===2 ? action.payload.pp : item)) }
  if(_debug === 'development') console.log("Pay points change",stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;

  case UPDATE_CP: 
  stateA = action.payload.propOpt === 'selected' 
  ? {...state, bChoiceCheapOne: action.payload.cp }
  : {...state, bChoiceCheapTwo: action.payload.cp }
  if(_debug === 'development') console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_RIM: 
  stateA = action.payload.propOpt === 'selected' 
  ? {...state, bChoiceIone: state.bChoiceIone.map((item,index) => (index===3 ? action.payload.rim : item)) }
  : {...state, bChoiceItwo: state.bChoiceItwo.map((item,index) => (index===3 ? action.payload.rim : item)) }
  if(_debug === 'development') console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_PBPR: 
  stateA = action.payload.propOpt === 'selected' 
  ? {...state, bChoiceIone: state.bChoiceIone.map((item,index) => (index===4 ? action.payload.pbpr : item)) }
  : {...state, bChoiceItwo: state.bChoiceItwo.map((item,index) => (index===4 ? action.payload.pbpr : item)) }
  if(_debug === 'development') console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_LT: 
  stateA = action.payload.propOpt === 'selected' 
  ? {...state, bChoiceIone: state.bChoiceIone.map((item,index) => (index===5 ? action.payload.lt : item)) }
  : {...state, bChoiceItwo: state.bChoiceItwo.map((item,index) => (index===5 ? action.payload.lt : item)) }
  if(_debug === 'development') console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_J: 
  stateA = action.payload.property === 'selected' 
  ?{...state, finOptS: action.payload}
  :{...state, finOptP: action.payload}
  const profileJourneyJ = {...state.bProfileJourney,financeTerm:true,financeType:true,financeRate:true}
  stateA = {...stateA,bProfileJourney:profileJourneyJ}
  if(_debug === 'development')  console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case REFIAP: 
  stateA = {...state, bChoiceK: action.payload}
  if(_debug === 'development')  console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case REFIMAX: 
  stateA = {...state, bChoiceKMax: action.payload}
  if(_debug === 'development')  console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case REFIINIT: 
  stateA = {...state, bChoiceKInit: action.payload}
  if(_debug === 'development')  console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case ADJUST: 
  stateA = {...state, adjust: action.payload}
  if(_debug === 'development')  console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case REVIEW: 
  stateA = {...state, review: action.payload}
  if(_debug === 'development')  console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;
case UPDATE_Profile: 
  stateA = {...state, bProfile: action.payload};
  const profileJourneyPr = {...state.bProfileJourney,personalContact:true,personalName:true,personalDOB:true,personalSSN:true}
  stateA = {...stateA,bProfileJourney:profileJourneyPr}
  if(_debug === 'development')  console.log(stateA)
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA;

case UPDATE_ProfileNew: 
  stateA = {...state, bProfile: action.payload};
  if(_debug === 'development')  console.log(stateA)
  return stateA;

case UPDATE_EMP_MORTGAGE_PROFILE:
  stateA ={... state, employeeMortgageBenifitProfile: action.payload};
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  if(_debug ==='development') console.log(stateA)
  return stateA
  case UPDATE_SAVING_EMB:
    console.log("Action Payload",action.payload)
  const updatedSaving= {...state.employeeMortgageBenifitProfile,saving:action.payload}
  stateA ={... state, employeeMortgageBenifitProfile: updatedSaving};

  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  if(_debug ==='development') console.log(stateA)
  return stateA
case UPDATE_fProfileNew: 
  stateA = {...state, bfinancialProfile: action.payload};
  if(_debug === 'development')  console.log(stateA)
  return stateA;
case UPDATE_PropertyDetailNew: 
  stateA = {...state, bPropertyDetail:action.payload};
  if(_debug === 'development') console.log("Update after E",stateA);
  return stateA;
case UPDATE_RATES:
  const updateRate = {...state.rates, rate:action.payload.rate, apr:action.payload.apr}

  stateA = {...state,rates:updateRate};
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  if(_debug === 'development') console.log(stateA)
  return stateA

case UPDATE_ProfileS: 
  const profileS = state.bProfileStatus 
  console.log(profileS)
  if(profileS === null || typeof profileS ==='undefined'){
      stateA = {...state, bProfileStatus: action.payload,profilePercentComplete:0.0};
      //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
      encryptedStateA = encFun(JSON.stringify(stateA));
      window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
      return stateA
  }else { 
      const sum = Object.keys(profileS).reduce((sum,key)=>sum+parseInt(profileS[key]||0),0);
      const count = Object.keys(profileS).length;
      let percent = ( (count > 0) ? ((100*sum)/count) : 0.0 );
      percent = Math.ceil(percent)
      stateA = {...state, bProfileStatus: action.payload,profilePercentComplete:percent};
      //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
      encryptedStateA = encFun(JSON.stringify(stateA));
      window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
      if(_debug === 'development')  console.log("Profile status",stateA,sum,count)
      return stateA;
  }
case UPDATE_fProfile: 
  stateA = {...state, bfinancialProfile: action.payload};
//const profileJourneyPf = {...state.bProfileJourney,crditEmployment:true,creditOutgoing:true, creditFICO:true,creditCreditEvents:true,} 
const profileJourneyPf = {...state.bProfileJourney,creditOutgoing:true, creditFICO:true,creditCreditEvents:true,}
stateA = {...stateA,bProfileJourney:profileJourneyPf}
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  if(_debug === 'development')  console.log(stateA)
  return stateA;
case REGCOMP: 
  stateA = {...state, bChoiceL: action.payload};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  if(_debug === 'development')  console.log(stateA)
  return stateA;
case SIGNIN:
  var profile = state.bProfile
 if(_debug === 'development') console.log("Profile Update",action.data,action)
  profile = {...profile,
      userId      : action.data.userId,
      token       : action.data.token,
      emailId     : action.data.emailId,
      docId       : action.data.docId,

      firstName   : action.data.firstName,
      lastName    : action.data.lastName,
      address     : action.data.address,
      phone       : action.data.phone,
  }
  window.sessionStorage.setItem('profile', JSON.stringify(profile));
  stateA = { ...state, bProfile:profile,isLoggedin:true};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
case SIGNUP:
  var profile = state.bProfile
  if(_debug === 'development') console.log("Profile Update",action.data,action)
  profile = {...profile,
      userId      : action.data.userId,
      token       : action.data.token,
      firstName   : action.data.firstName,
      lastName    : action.data.lastName,
      emailId     : action.data.emailId,
      address     : action.data.address,
      phone       : action.data.phone,
      docId       : action.data.docId,
  }
  window.sessionStorage.setItem('profile', JSON.stringify(profile));
  stateA = { ...state, bProfile:profile,isLoggedin:true};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
case LOGOUT:
  stateA = defaultState;
  encryptedStateA = encFun(JSON.stringify(defaultState));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  // window.sessionStorage.clear();
  return stateA
case UPDATE_LocalStorage:
  var profile = state.bProfile
  if(_debug === 'development') console.log("Profile Update 111",action.data,action)
  localStorage.setItem('profile', JSON.stringify(profile));
  break
case USERUPDATE:
  stateA = { ...state, bProfile:action.payload};
  window.sessionStorage.setItem('borrowerProfile', JSON.stringify(profile));
  return stateA
  case NAVFLAG:
  return{ ...state,navFlag:action.payload}
  case EMBFLOWFLAG:
    stateA = { ...state,isEmbFlow:action.payload}
    encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
  case BORROWERFLOWFLAG:
    stateA = { ...state,isBorrowerFlow:action.payload}
    encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
  case GENERATEDUSERID:
    stateA = { ...state,generatedUserId:action.payload}
    encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
    
 case COUNTRYCODEFLAG:
  stateA ={ ...state,countryCodeFlag:action.payload};
  // window.sessionStorage.setItem('borrowerProfile', JSON.stringify(profile));
  return stateA

  case STATECODEFLAG:
  stateA ={ ...state,stateCodeFlag:action.payload};
  // window.sessionStorage.setItem('borrowerProfile', JSON.stringify(profile));
  return stateA

  case BREADCRUMBFLAG:
    stateA ={ ...state,breadcrumbData:action.payload};
    // window.sessionStorage.setItem('borrowerProfile', JSON.stringify(profile));
    return stateA

    case ISFIRSTTIMEBUYER:
      stateA ={ ...state,isfirstTime:action.payload};
      // window.sessionStorage.setItem('borrowerProfile', JSON.stringify(profile));
      return stateA

case NAVEMPLOYER:
  return{ ...state,navEmployer:action.payload}
case NEWEMPLOYER:
  return{ ...state,isNewEmployer:action.payload}
case USERFINANCEUPDATE:
  stateA = { ...state, bfinancialProfile:action.payload};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
case USERPROPERTYUPDATE:
  stateA = { ...state, bPropertyDetail:action.payload};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA

case UPDATE_SWAPRATE:
  stateA = { ...state, swapRate:action.payload};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
case UPDATE_SWAPRATEONE:
  const swapRate = state.swapRate
  swapRate[action.payload.index].rate = action.payload.value
  stateA = { ...state, swapRate:swapRate};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
case UPDATE_SPREAD:
  const spreadTable = state.spreadTable.map((item) =>{item.loanType === action.payload.loanType && item.homeType? action.payload:item})
  stateA = { ...state, spreadTable:spreadTable};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
case UPDATE_SPREADONE:
  const _spreadTable = state.spreadTable
  _spreadTable[action.payload.index].spArray[action.payload.index1] = action.payload.spArrayE
  stateA = { ...state, spreadTable:_spreadTable};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA

case UPDATE_LOANTYPE:
  
  stateA = { ...state, loanType:action.payload};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA

case UPDATE_HOUSETYPE:
  stateA = { ...state, houseType:action.payload};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA



case UPDATE_PROFILEJOURNEY:
  stateA = { ...state, bProfileJourney:action.payload};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA

case UPDATE_PROPDET:
  const bPropDet = {...state.bPropertyDetail,propertyPrefs:action.payload}
  console.log("DDDEE",action.payload)
  stateA = { ...state, bPropertyDetail:bPropDet};
  //encryptedStateA = CryptoJS.AES.encrypt(JSON.stringify(stateA), process.env.REACT_APP_GOOGLE_AUTH_API_KEY).toString();
  encryptedStateA = encFun(JSON.stringify(stateA));
  window.sessionStorage.setItem("borrowerProfile",encryptedStateA)
  return stateA
  default: return state;

}
};
