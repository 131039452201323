import React from "react";
import { Box, Stack, CssBaseline, TextField, InputAdornment, IconButton, Typography, Button} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate } from 'react-router-dom';
import homeImage from '../../assets/images/Rectangle 9810.png';
import imagekeyfuture from '../../assets/images/Image 20@2x.png';
import imagedesignfinance from '../../assets/images/Image 17@2x.png'
import imagehardyards from '../../assets/images/Image 19@2x.png'
import imageamericandream from '../../assets/images/Image 18@2x.png'
import {getWindowDimensions} from '../../computations/helperFunctions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


import useStyles from './styles';
import { borderRadius } from "@mui/system";
var allowed = "no"

export function Disclosures() {
  const navigate = useNavigate();
  return(
    <React.Fragment>
      <CssBaseline/>
      <div align='center'>
        <Box sx={{ width: '100vw',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
          <Typography mt='4vh'variant="h4">Disclosures And Licensing</Typography>
        </Box>
        <Button variant="outlined" sx={{minHeight: 0, minWidth: 0,width:'3vw'}}onClick={()=>navigate(-1)}>          
          <Typography>Back</Typography>
        </Button>
      </div>
    </React.Fragment>
  );
}