import React from "react";
import { Box, CssBaseline, Stack, Typography, Button} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import GoogleMaps from './Googlemaps'
import Autosuggest from './Autosuggest';
import leftbottom from '../../assets/svg/assets svg/image-leftbottom05.png'
import {  useDispatch, useSelector } from 'react-redux';
import {  borrowerChoiceD,borrowerfinancialProfile} from "../../actions/borrower";
import { Centerbottom, LeftbottomA } from "./RightBlock";
import { IS_LOACTION_CHANGED, UPDATE_PROFILEJOURNEY, _debug } from "../../constants";
import { useWindowDimensions } from "../../computations/helperFunctions";
import {  useLoadScript } from "@react-google-maps/api";
import { LeftBlockMsg } from "./Messenger";
import { getAnalytics, logEvent } from "firebase/analytics";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createBreakpoints } from "@mui/system";
import { rspvw } from "./styles";
const DebugOutput = true; */

export function SearchProperty() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { bChoiceA, 
          bChoiceB,
          bChoiceC,
          bProfileJourney,
          bfinancialProfile,
          isEmbFlow,
          isBorrowerFlow,
          countryCodeFlag, stateCodeFlag }= useSelector((state) => state.borrower);
  const [locChanged,setLocChanged] = React.useState(false);
  console.log(countryCodeFlag, "==Country Code is")
 
  React.useEffect(()=>{
    setLocChanged(true)
  },[bChoiceC]) 
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey:  process.env.REACT_APP_GOOGLE_MAPS_API_KEY, libraries: ['places'], });
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  },[height,width]) 
  const analytics = getAnalytics();
  logEvent(analytics, 'searchProperty', { name: 'searchProperty'});
  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "Locate":
      console.log("====buttonId_bChoiceA",bChoiceA);
      dispatch(borrowerfinancialProfile({ ...bfinancialProfile, isFirstTimeBuyer: true }));
      console.log(locChanged, "==locChangede is")
      console.log("====buttonId",buttonId);
      if(countryCodeFlag == "USA" &&  stateCodeFlag.toString().trim().includes("CO")){
        console.log("===countryCodeif",countryCodeFlag);
 
        const finType  =  bChoiceB === ("firsthome" || bChoiceB === 'nexthome' )? 'primary' :(bChoiceB === "investment") ?'investment': 'secondary'
        const propertyAddress = bChoiceC.name;
        const annualIncome = typeof bfinancialProfile.income.annualIncome === 'string' 
          ? parseInt(bfinancialProfile.income.annualIncome.replace(/\$|,/g, ''))
          : parseInt(bfinancialProfile.income.annualIncome) 
        const cashAvailable = typeof bfinancialProfile.income.upfront == 'string'
          ? parseInt(bfinancialProfile.income.upfront.replace(/\$|,/g, ''))
          : parseInt(bfinancialProfile.income.upfront)
        const position = bChoiceC.position;
        const locality = bChoiceC.locality;
        const lon = parseFloat(position.longiitude);
        const lat  = parseFloat(position.latitude); 
        if(_debug === 'development') console.log("Getproperty Parameters - add,ptyp,lat,long,local,cash,income,mls",
            propertyAddress,bChoiceA,lat,lon,locality,cashAvailable,annualIncome);
        if(locChanged){
          console.log("LOCATION CHANGED")
          // dispatch(borrowerChoiceD(propertyAddress,bChoiceA,lat,lon,locality,cashAvailable,annualIncome,'00',finType))
          dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, locationArea: true, locationZipcode: true} });
          dispatch({type: IS_LOACTION_CHANGED, payload:true})
          setLocChanged(false)
        }
        // bChoiceA === 'refinance' && 
        isEmbFlow? navigate("/ShowPropertyEmb", { replace: false})
                                // : navigate("/ShowProperty",{replace: false})
                                : isBorrowerFlow ? navigate("/PersonalizeRate",{replace: false})
                                : navigate("/ShowProperty",{state:{preRoute:"SearchProperty"}},{replace: false})
      }else{
        console.log("only usa country code");
        toast("Please enter address of Colorado ,USA!", {position: toast.POSITION.BOTTOM_LEFT})
        navigate("/SearchProperty",{replace: false})
      }
      break;
      default:
        break;
    }
  }

  function getLableText() {
    return isEmbFlow?'':bChoiceB === 'nexthome' 
    ?  bChoiceA !=="refinance"? 'Locate My Next Home': 'Locate my Second Home' 
    :  bChoiceB === 'investmentproperty'? 'Locate My Investment Property' 
    :  bChoiceB === 'holidayhome'?'Locate My Holiday Home' 
    // :  bChoiceA !=="refinance"? 'Locate My First Home'
    :  bChoiceA !=="refinance"? 'Locate my home'

    :  'Locate My Principal Home'
  }

      return(
        <React.Fragment><CssBaseline/>
        <Box mt={marginT} ml={marginL}  sx={{overflow:'hidden', width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
          <Navbar pathname={location.pathname}/>
          <Box sx={{ display: 'flex', flexDirection: 'row'}}>
            { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
          
            <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
            <div align='center'>
            <ToastContainer />
              <Typography sx={{color:'#000000',mt:'21.8vw',fontSize:'1.4vw', textAlign:'center', width:'12vw'}}> 
              { getLableText() }</Typography>
          
                  <Stack sx={{mt:'1vw',height:'3.5vw',width:'6.5vw'}} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow:'hidden', textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id="Locate" className='hexBtnCenter'
                  onClick={onClickFun}> 
                <HexButton titleText="Locate" type='base' fontSize='24'/></Button>
                </Stack>

                <Centerbottom navigateForward='ShowProperty'/>

              </div>
              
            </Box>

            {isLoaded && <RightBlock/>}
        </Box>
      </Box>
      </React.Fragment>
    );
}

function RightBlock() {
      const {bChoiceC,bChoiceD} = useSelector((state) => state.borrower);
      const [dummy, SetDummy] = React.useState(bChoiceD)
      return (
        <React.Fragment>
          <CssBaseline/>
          <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',p:0.5,opacity: 1, width: '50vw',height:'46.75vw' }}>
              <div>
                <GoogleMaps position={{ lat:parseFloat(bChoiceC.position.latitude), 
                                        lng: parseFloat(bChoiceC.position.longiitude)}}
                                        markerInput={bChoiceD} wHeight='46vw'/>
                </div>
          </Box>
        </React.Fragment>
      );

}

function LeftBlock() {
  const {bChoiceA,isEmbFlow} = useSelector((state) => state.borrower);
  return (
    <Box sx={{overflow:'hidden',background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
        <Box sx={{ mt:'5vw' , ml: '7.3vw', width: '28.7vw',height:'23.6vw', border:0 }}> 
            <Typography sx={{color:'#31312C', width:'25vw', height:'20.6vw',
              fontSize:'5.2vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.32,letterSpacing: '-.25vw'}}> 
                {isEmbFlow?'Your preferred location':
                bChoiceA === "refinance"? 'Refinance personalize for you' :'Finance personalize for you'}
            </Typography>
            <Box mt='1.5vw'width= '27vw' background= '#FFFFFF 0% 0% no-repeat padding-box' boShadow='0px 23px 59px #00000029'>
                <Autosuggest sx={{mt:'5.1vw'}}/>
            </Box>
        </Box>
        <LeftbottomA leftbottom={leftbottom}/>
    </Box>
  );
}

export default SearchProperty;