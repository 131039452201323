import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// material-ui
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  CssBaseline, InputAdornment, IconButton, Divider, FormControlLabel, Checkbox, Link, Select, InputLabel, Textarea, Tooltip
} from '@mui/material';
import { HexButton } from './ButtonIcons1';
import { getWindowDimensions } from '../../computations/helperFunctions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import download from '../../assets/svg/download.svg'
import mask_group from '../../assets/staticPageAssets/MaskGroup107.png'
import bullet from '../../assets/staticPageAssets/Polygon.svg'
import useStyles from './styles';
import { BottomBox, LeftbottomA } from "./RightBlock";
import { border, fontWeight, width } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex";
import NavBarDashBoardUpdate from "./NavBarDashBoardUpdate";
import NavBarBreadCrumbUpdate from "./NavBarBreadCrumbUpdate";
import AnalyticEcommerce from './AnalyticEcommerce';
//import IncomeAreaChart from './IncomeAreaChart';
//import MonthlyBarChart from './MonthlyBarChart';
import { Bar, Line } from "react-chartjs-2";
import MainCard from './MainCard';
import "chartjs-plugin-datalabels";
import person from '../../assets/svg/person.svg'
import dollarsolid from '../../assets/svg/dollar-solid.svg'
import briefcasefill from '../../assets/svg/briefcase-fill.svg'
import graduationcap from '../../assets/svg/graduation-cap.svg'
import ContractTable from './ContractTable';
import InvoiceTable from './InvoiceTable';
import bxslike from '../../assets/svg/bxs-like.svg'
import ResourceTable from './ResourceTable';
import avatar_boy from '../../assets/svg/avatar-boy.png'
import info from '../../assets/svg/info.svg'
import emp1 from '../../assets/emp1.png'
import { rspvw } from "./styles";
import { breadcrumbData } from "../../actions/borrower";
import { useDispatch, useSelector } from 'react-redux';
var allowed = "no"
import { withCommasWithoutCurrency, _debug } from "../../constants";



import { ThemeProvider, createTheme } from '@mui/material/styles';



const ALPHA_NUMERIC_DASH_REGEX = /^[0-9\b]+$/;

function EmployerCompanyCal() {
 
  const theme = createTheme({
    props: {
      // Name of the component
    MuiBox: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application!
      }
    }
  });


  const navigate = useNavigate();
  const dispatch = useDispatch()
  dispatch(breadcrumbData("Your Calculator"));
  const { height, width } = getWindowDimensions();
  const [seek, setSeek] = React.useState(allowed);
  const [hexaText1, setHexaText1] = React.useState('Investors')
  const [hexaText2, setHexaText2] = React.useState('Technology Partners')
  const [hexaText3, setHexaText3] = React.useState('Employers')
  const [hexaText4, setHexaText4] = React.useState('Mortgage Industry')

  const [valuee, setValuee] = React.useState("");
  const [valOne, setValOne] = React.useState("0")
  const [valTwo, setValTwo] = React.useState("0")
  const [valThree, setValThree] = React.useState("0")
  const [valFour, setValFour] = React.useState("0")
  const [valFive, setValFive] = React.useState("0")
  const [valSix, setValSix] = React.useState("0")
  const [valSeven, setValSeven] = React.useState("0")
  const [valEight, setValEight] = React.useState("0")
  const [valNine, setValNine] = React.useState("0")
  const [valTen, setValTen] = React.useState("0")
  const [valEleven, setValEleven] = React.useState("0")
  const [totalSum, setSum] = React.useState(0)


  const [value, setValue] = React.useState('today');
  const [slot, setSlot] = React.useState('week');
  // if(_debug === 'development') allowed = 'yes'
  allowed = 'yes'


  //const [num, setNum] = React.useState();
  const [calvalOne, setCalValOne] = useState('0');
  const [calvalTwo, setCalValTwo] = useState('0');
  const [calvalThree, setCalValThree] = useState('0');
  const [calvalFour, setCalValFour] = useState('0');
  const [calvalFive, setCalValFive] = useState('0');
  const [calvalSix, setCalValSix] = useState('0');
  const [calvalSeven, setCalValSeven] = useState('0');
  const [calvalEight, setCalValEight] = useState('0');
  const [calvalNine, setCalValNine] = useState('0');
  const [calvalTen, setCalValTen] = useState('0');
  const [calvalEleven, setCalValEleven] = useState('0');
  const [total, setTotal] = useState('0');
  const [totalOne, setTotalOne] = useState('0');
  const [totalFinal, setTotalFinal] = useState('0');

  const [totalRecu, setFinalRecu] = useState('0');
  const [totalManag, setFinalMan] = useState('0');

   const [totalFive, setFinalFive] = useState('0');


  const [totalJob, setFinalJob] = useState('0');


  const [totalResult, setFinalResult] = useState('0');
  const [input, setInput] = useState({
    first: "",
    second: "",
    three: "",
    four:"",
  });



  const [inputOne, setInputOne] = useState({
    five: "",
    six: "",
    seven: "",
    eight: "",
  });

  const [inputTwo, setInputTwo] = useState({
    nine: "",
    ten: "",
    eleven: "",
  });

  const [isDisabled, setDisabled] = useState(false);

  const handleSubmit = () => {
    console.log('Your box was clicked and is now disabled');
    setDisabled(true);
  }




  const onchangeHandel = (e) => {

    const value = e.target.value;
    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
      return;
    }


    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });

console.log("inputData",[e.target.name])
console.log("inputDataValue",[e.target.value])

  };
  useEffect(() => {
    
    calc_total(input);
   
  }, [input]);

  const calc_total = (newValues) => {

    
    console.log("==totalnewValues",newValues['first'])
    let totalCompensation=parseInt(newValues['first']/(220*8))
    console.log("==totalCompensationNEW",totalCompensation)
    console.log("==totalCompensation",totalCompensation)

    console.log("==totaltotalResult",totalResult)
    // console.log("==TTtotaltotalResulttrim",totalResult.trim().length)

 

   
    setCalValOne(newValues['first'])
    setCalValTwo(totalCompensation)
   
   // setInput(totalCompensation)
    
   // const sumValues = Object.keys(newValues).reduce((sum,key)=>sum+parseFloat(newValues[key]||0),0);
   // setFinalResult(sumValues)
  
}



const onchangeHandelThree = (e) => {

  const value = e.target.value;
  if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
    return;
  }


  setInput({
    ...input,
    [e.target.name]: e.target.value,
  });

console.log("inputData",[e.target.name])
console.log("inputDataValue",[e.target.value])

};
useEffect(() => {
  
  calc_totalThree(input);
 
}, [input,calvalFour,calvalOne,calvalThree,totalResult]);

const calc_totalThree = (newValues) => {

  console.log("==totalnewValuesThree",newValues['three'])
  console.log("==totalnewValuesThreeend",calvalTwo)
  let calvalFour=parseInt(newValues['three']* calvalTwo)
 // console.log("==calValFour",withCommasWithoutCurrency(calvalFour))
   setCalValThree(newValues['three']) 
   setCalValFour(calvalFour)
   setFinalRecu(calvalFour)
   //setFinalResult(calvalFour)
   //let totalCompensation=parseInt(newValues['first']/(220*8))
 //  console.log("==totalCompensationNEW",totalCompensation)
 //  console.log("==totalCompensation",totalCompensation)
  // setCalValTwo(totalCompensation)
  // const sumValues = Object.keys(newValues).reduce((sum,key)=>sum+parseFloat(newValues[key]||0),0);
  // setTotal(sumValues)

}




const onchangeHandelFive = (e) => {

  const value = e.target.value;
  if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
    return;
  }


  setInputOne({
    ...inputOne,
    [e.target.name]: e.target.value,
  });

console.log("inputData",[e.target.name])
console.log("inputDataValue",[e.target.value])

};
useEffect(() => {
  
  calc_totalSix(inputOne);
 
}, [inputOne,calvalSeven,calvalSix]);

const calc_totalSix = (newValues) => {

  console.log("==totalSIX",newValues['five'])

  console.log("==totalnewValues",newValues['five'])
  let totalCompensation=parseInt(newValues['five']/(220*8))
  console.log("==totalCSIX",totalCompensation)
  console.log("==totalCompensation",totalCompensation)
  setCalValFive(newValues['five'])
  setCalValSix(totalCompensation)
  if(totalCompensation == '')
  {
    console.log("==totalCompensationIF",totalCompensation)
    setCalValSix(totalCompensation)
  }
 // setFinalFive(totalCompensation)
 // setFinalJob(0)

 

}





const onchangeHandelSeven = (e) => {

  const value = e.target.value;
  if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
    return;
  }


  setInputOne({
    ...inputOne,
    [e.target.name]: e.target.value,
  });

console.log("inputData",[e.target.name])
console.log("inputDataValue",[e.target.value])

};
useEffect(() => {
  
  calc_totalSeven(inputOne);
 
}, [inputOne,calvalEight,calvalSeven,calvalSix]);

const calc_totalSeven = (newValues) => {

  console.log("==totalnewValuesSeven",newValues['seven'])

 
 // console.log("==totalnewValuesTWO",calvalTwo)
 // let calvalseven=parseInt(newValues['seven']-50)

  let calvalseven=parseInt(newValues['seven'])
 // console.log("==calValseven",withCommasWithoutCurrency(calvalseven))
  //setCalValSeven(calvalseven)
//  console.log("==calValSIX",calvalSix)
  // if(calvalseven==-50)
  // {
  //  calvalseven=0;
  // console.log("==calValIF")
  // let calvalResult=parseInt(calvalSix * calvalseven)
  // console.log("==calvalResult",calvalResult)
 
  // console.log("==calvalFour==",calvalFour)
  // console.log("==calvalEight==",calvalEight)
  // setCalValSeven(calvalseven)
  // setCalValEight(calvalResult)
  // setFinalMan(calvalResult)
  // }else{
  //   let calvalResult=parseInt(calvalSix * calvalseven)
  //   console.log("==calvalResultELSE",calvalResult)
   
  //   console.log("==calvalFour==",calvalFour)
  //   console.log("==calvalEight==",calvalEight)
  //   setCalValSeven(calvalseven)
  //   setCalValEight(calvalResult)
  //   setFinalMan(calvalResult)
  // }
  // sum()

  console.log("==calcalvalSIX",calvalSix)
  console.log("==calcalvalSEVEN",calvalseven)
  if(calvalseven){
  console.log("==IFcalcalvalSEVENLENGTH",calvalseven)
  }
  else{
  console.log("==ELSEcalcalvalSEVENLENGTH",calvalseven)
  calvalseven=0
  }
  console.log("==OUTcalcalvalSEVENLENGTH",calvalseven)
 // if(calvalseven.trim().length == 0)
    let calvalResult=parseInt(calvalSix * calvalseven)
     setCalValSeven(calvalseven)
     setCalValEight(calvalResult)
     setFinalMan(calvalResult)
    // setFinalResult()

}
function sum(){
  console.log("==rrrtrrcalvalEight==",calvalEight)
  let calSecResult=parseInt(calvalEight + calvalFour)
  console.log("==calSecResult",calSecResult)
  setFinalResult(calSecResult)
}




const onchangeHandelNine = (e) => {

  const value = e.target.value;
  if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
    return;
  }


  setInputTwo({
    ...inputTwo,
    [e.target.name]: e.target.value,
  });

console.log("inputData",[e.target.name])
console.log("inputDataValue",[e.target.value])

};
useEffect(() => {
  
  calc_totalNine(inputTwo);
 
}, [inputTwo,calvalTen]);

const calc_totalNine = (newValues) => {

  console.log("==newValuesNine",newValues['nine'])
  setCalValNine(newValues['nine'])

  if(newValues['nine'] == "")
  {
    console.log("==newValuesNineIF",newValues['nine'])
   // 
    console.log("==newValuesNineIFCALVAL",calvalTen)
    setCalValTen(0)
  }else{
    console.log("==newValuesNineELSE",newValues['nine'])
  }


  
 
}




const onchangeHandelTen = (e) => {

  const value = e.target.value;
  if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
    return;
  }


  setInputTwo({
    ...inputTwo,
    [e.target.name]: e.target.value,
  });

console.log("inputData",[e.target.name])
console.log("inputDataValue",[e.target.value])

};
useEffect(() => {
  
  calc_totalTen(inputTwo);
 
}, [inputTwo,calvalNine,calvalTen,calvalEleven]);

const calc_totalTen = (newValues) => {

  console.log("==totalnewValues",newValues['ten'])
  console.log("==calvalNine",calvalNine)
  let calvalResult=parseInt(newValues['ten'] * calvalNine)
 // setCalValNine(calvalNine)
  setCalValTen(newValues['ten'])
  setCalValEleven(calvalResult)
  console.log("==calvalFour",calvalFour)
  console.log("==calvalEight",calvalEight)
  console.log("==calvalResult",calvalResult)
 
  setFinalJob(calvalResult)
  // let totalResult=parseInt(calvalFour) + parseInt(calvalEight) + parseInt(calvalResult)
  // console.log("==totalResult",totalResult)
  // setFinalResult(totalResult )

}







// const onchangeHandelOne = (e) => {

//   const value = e.target.value;
//   if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
//     return;
//   }


//   setInputOne({
//     ...inputOne,
//     [e.target.name]: e.target.value,
//   });

// console.log("inputData",[e.target.name])
// console.log("inputDataValue",[e.target.value])

// };



// useEffect(() => {
    
//   calc_total_One(inputOne);
 
// }, [inputOne]);

// const calc_total_One = (newValues) => {

//    const sumValues = Object.keys(newValues).reduce((sum,key)=>sum+parseFloat(newValues[key]||0),0);
//  setTotalOne(sumValues)

// }










useEffect(() => {
    
  calc_total_final();
 
}, [totalRecu,totalManag,totalJob,totalFive]);


const calc_total_final = () => {

  //const sumValues = Object.keys(newValues).reduce((sum,key)=>sum+parseFloat(newValues[key]||0),0);
  console.log("===i am final")
  console.log("===i am total Rec",totalRecu)
  console.log("===i am totalmanag",totalManag)
  console.log("===i am totalJob",totalJob)
  const sumValues = totalRecu  + totalManag + totalJob 
  setFinalResult(sumValues)

}




  const handleChangeRec = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      //setNum(e.target.value);
    }
  };



  const state = {
    labels: ['January', 'February', 'March',
      'April', 'May'],
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [65, 59, 80, 81, 56]
      }
    ]
  }


  // const dataBar = {
  //   labels: ["January", "February", "March", "April", "May", "June", "July"],
  //   datasets: [
  //     {
  //       label: "My First dataset",
  //       backgroundColor: "#EC932F",
  //       borderColor: "rgba(255,99,132,1)",
  //       borderWidth: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [65, 59, 80, 81, 56, 55, 40]
  //     }
  //   ]
  // };

  // const options = {
  //   plugins: {
  //     datalabels: {
  //       display: true,
  //       color: "black",
  //       align: "end",
  //       anchor: "end",
  //       font: { size: "14" }
  //     }
  //   },
  //   legend: {
  //     display: false
  //   }
  // };


  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
      //   {
      //     label: "Second dataset",
      //     data: [33, 25, 35, 51, 54, 76],
      //     fill: false,
      //     borderColor: "#742774"
      //   }
    ]
  };


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };

  // avatar style
  const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem'
  };

  // action style
  const actionSX = {
    mt: 0.75,
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',
    transform: 'none'
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  //  const data = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'Dataset 1',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //     },
  //     {
  //       label: 'Dataset 2',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //     },
  //   ],
  // };

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [age, setAge] = React.useState('');


  return (
    <React.Fragment><CssBaseline />
      <Box sx={{ overflow: 'hidden', width: '100%', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <NavBarDashBoardUpdate />
        <NavBarBreadCrumbUpdate />


        {/* <Box   disableRipple    sx={{ ml: `${rspvw(141)}`, mr: `${rspvw(141)}`, mt: `${rspvw(27)}`, mb: `${rspvw(44)}` }} 
        onLoad={handleSubmit} disabled={isDisabled}        aria-disabled={isDisabled}
        className={(isDisabled) ? 'is-disabled' : ''}      > */}


<Box   disableRipple    sx={{ ml: `${rspvw(141)}`, mr: `${rspvw(141)}`, mt: `${rspvw(27)}`, mb: `${rspvw(44)}` }}>



          <Grid container sx={{ mt: `${rspvw(0)}` }}>
            <Grid sx={{ ml: `${rspvw(21)}`, width: '100%', mt: `${rspvw(0)}` }}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography sx={{ font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(24)}` }}>XYZ Company Calculator</Typography>
                </Grid>
              </Grid>
              <MainCard sx={{ mt: `${rspvw(24)}` }} content={false} disableRipple >
                <Typography sx={{ mt: `${rspvw(35.17)}`, ml: `${rspvw(30)}`, font: 'Roboto', fontWeight: 'bold', color: '#31312C', opacity: '1', fontSize: `${rspvw(22)}` }}>
                  Calculate Cost of Hiring *
                </Typography>
<Box sx={{backgroundColor:''}} >
              
<ThemeProvider theme={theme}>
              
                <List
                  component="nav"
                  
                  disableRipple 

                  sx={{
                    px: 0,
                    
                    py: 0,
                    '& .MuiListItemButton-root': {
                      py: 1.5,
                      '& .MuiAvatar-root': avatarSX,
                      '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                    }
                  }}
                >

                  <ListItemButton   disableRipple disableTouchRipple disableFocusRibble>
                    <Box
                      sx={{
                        mt: '0.0vw',
                        ml: `${rspvw(35.17)}`,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Box component="form" noValidate sx={{ mt: `${rspvw(12)}` }}>
                        <Grid container>
                          <Grid sx={{ mr: `${rspvw(0)}`, mt: `${rspvw(70)}` }}>
                            <Typography sx={{ font: 'Roboto', fontSize: `${rspvw(20)}`, mr: `${rspvw(0)}`, color: '#31312C', opacity: '1' }}>
                              Recruiter total compensation(yearly)
                            </Typography>
                          </Grid>
                          <Grid sx={{ mt: `${rspvw(65)}`, ml: `${rspvw(92)}` }}>      
                          
                            <TextField
                              placeholder=""
                              variant="standard"
                              type={"number"}
                              // pattern= "^[0-9]*$"
                              //value={valOne}
                              // value={valOne}
                              // onChange={(e) => handleChangeOne(e)}

                              autoComplete="off"
                              name="first"
                              value={input.first}
                              onChange={onchangeHandel}

                              // onChange={(event) => {
                              //   const value = event.target.value;
                              //   if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
                              //     return;
                              //   }
                              //   setValOne(value);


                              // }}




                              sx={{ textAlign: 'left', width: `${rspvw(298)}`, height: `${rspvw(60)}`, border: 1, borderColor: 'black', borderRadius: 1 }}
                              InputProps={{
                                disableUnderline: true, fontSize: `${rspvw(1)}`,
                                type: 'search',
                                sx: { ml: `${rspvw(19)}`, cursor: 'none', mt: `${rspvw(5)}` }

                              }}


                            />
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid sx={{ mr: `${rspvw(0)}`, mt: `${rspvw(28)}` }}>
                            <Typography sx={{ font: 'Roboto', fontSize: `${rspvw(20)}`, mr: '0vw', color: '#31312C', opacity: '1' }}>
                              Recruiter total compensation (hourly)
                            </Typography>
                          </Grid>
                          <Grid sx={{ mt: `${rspvw(19)}`, ml: `${rspvw(85)}` }}>                 
                            <TextField
                              placeholder=""
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              name="second"
                              value={calvalTwo ? calvalTwo: ""}
                             // value={calvalTwo}
                              onChange={onchangeHandel}

                              // value={valTwo}
                              // onChange={(e) => handleChangeTwo(e)}
                             
                             
                             
                             
                             
                             
                              sx={{ textAlign: 'left', width: `${rspvw(298)}`, height: `${rspvw(60)}`, border: 1, borderColor: 'black', borderRadius: 1 }}
                              InputProps={{
                                disableUnderline: true, fontSize: `${rspvw(1)}`,
                                sx: { ml: `${rspvw(19)}`, cursor: 'none', mt: `${rspvw(5)}` },
                                type: 'search',
                              }}
                            />


                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid sx={{ mr: `${rspvw(0)}`, mt: `${rspvw(28)}` }}>
                            <Typography sx={{ mt: '00vh', mr: '0vw', ml: '0vh', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(20)}` }}>
                              Recruiter hours spent
                            </Typography>
                          </Grid>
                          <Grid sx={{ mt: `${rspvw(19)}`, ml: `${rspvw(229)}` }}>
                            <TextField
                              placeholder=""
                              variant="standard"
                              type="number"
                               
                              autoComplete="off"
                              name="three"
                              onChange={onchangeHandelThree} 
                              
                              sx={{ textAlign: 'left', width: `${rspvw(298)}`, height: `${rspvw(60)}`, border: 1, borderColor: 'black', borderRadius: 1 }}

                              InputProps={{
                                disableUnderline: true, fontSize: `${rspvw(1)}`,
                                sx: { ml: `${rspvw(19)}`, cursor: 'none', mt: `${rspvw(5)}` },
                                type: 'search',
                              }}
                            />
                          </Grid>
                        </Grid>


                        <Grid container>
                          <Grid sx={{ mr: `${rspvw(0)}`, mt: `${rspvw(28)}` }}>
                            <Stack direction="row" justifyContent="flex-end">
                              <Typography sx={{ mt: '0vw', mr: '0vw', ml: '0vh', mb: '0vw', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(20)}` }}>
                                Total recruiter costs</Typography>
                              <Tooltip title="Extra Information" placement="top-start" arrow>
                                <Box sx={{ mr: '0.00vw', ml: `${rspvw(10)}`, mt: '0vh', mb: '0vw', width: `${rspvw(27)}`, height: `${rspvw(27)}` }}>
                                  <img src={info} alt="Info" width="100%" heigh="100%" />
                                </Box>
                              </Tooltip>
                            </Stack>
                          </Grid>
                          <Grid sx={{ mt: `${rspvw(19)}`, ml: `${rspvw(205)}` }}>
                            <TextField id="outlined-basic"
                              placeholder=""
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              name="four"
                              //value={input.four}
                              value={calvalFour ? calvalFour: ""}
                             
                             // onChange={onchangeHandelOne}
                             
                             
                              sx={{ textAlign: 'left', width: `${rspvw(298)}`, height: `${rspvw(60)}`, border: 1, borderColor: 'black', borderRadius: 1 }}
                              InputProps={{
                                disableUnderline: true, fontSize: `${rspvw(1)}`,
                                sx: { ml: `${rspvw(19.2)}`, cursor: 'none', mt: `${rspvw(5)}` },
                                type: 'search',
                              }}

                            />
                          </Grid>
                        </Grid>


                        <Grid container>
                          <Grid sx={{ mr: `${rspvw(0)}`, mt: `${rspvw(28)}` }}>
                            <Typography sx={{ mt: '00vh', mr: '0vw', ml: '0vh', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(20)}` }}>
                              Hiring manager total compensation (yearly)
                            </Typography>
                          </Grid>
                          <Grid sx={{ mt: `${rspvw(19)}`, ml: `${rspvw(30)}` }}>
                            <TextField id="outlined-basic"
                              placeholder=""
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              name="five"
                              onChange={onchangeHandelFive}


                              sx={{ textAlign: 'left', width: `${rspvw(298)}`, height: `${rspvw(60)}`, border: 1, borderColor: 'black', borderRadius: 1 }}
                              InputProps={{
                                disableUnderline: true, fontSize: `${rspvw(1)}`,
                                sx: { ml: `${rspvw(19.2)}`, cursor: 'none', mt: `${rspvw(5)}` },
                                type: 'search',
                              }}
                            />
                          </Grid>
                        </Grid>


                        <Grid container>
                          <Grid sx={{ mr: `${rspvw(0)}`, mt: `${rspvw(28)}` }}>
                            <Typography sx={{ mt: '00vh', mr: '0vw', ml: '0vh', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(20)}` }}>
                              Hiring manager total compensation (hourly)
                            </Typography>
                          </Grid>
                          <Grid sx={{ mt: `${rspvw(19)}`, ml: `${rspvw(28)}` }}>
                            <TextField id="outlined-basic"
                              placeholder=""
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              name="six"
                             
                              value={calvalSix ? calvalSix: ""}
                            
                           
                              sx={{ textAlign: 'left', width: `${rspvw(298)}`, height: `${rspvw(60)}`, border: 1, borderColor: 'black', borderRadius: 1 }}
                              InputProps={{
                                disableUnderline: true, fontSize: `${rspvw(1)}`,
                                sx: { ml: `${rspvw(19.2)}`, cursor: 'none', mt: `${rspvw(5)}` },
                                type: 'search',
                              }}
                            />
                          </Grid>
                        </Grid>


                        <Grid container>
                          <Grid sx={{ mr: `${rspvw(0)}`, mt: `${rspvw(28)}` }}>
                            <Typography sx={{ mt: '00vh', mr: '0vw', ml: '0vh', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(20)}` }}>
                              Hiring manager hours spent
                            </Typography>
                          </Grid>
                          <Grid sx={{ mt: `${rspvw(19)}`, ml: `${rspvw(170)}` }}>
                            <TextField id="outlined-basic"
                              placeholder=""
                              variant="standard"
                              type="number"
                              autoComplete="off"
                             
                              name="seven"
                            

                             onChange={onchangeHandelSeven}
                             
                              sx={{ textAlign: 'left', width: `${rspvw(298)}`, height: `${rspvw(60)}`, border: 1, borderColor: 'black', borderRadius: 1 }}
                              InputProps={{
                                disableUnderline: true, fontSize: `${rspvw(1)}`,
                                sx: { ml: `${rspvw(19.2)}`, cursor: 'none', mt: `${rspvw(5)}` },
                                type: 'search',
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid sx={{ mr: `${rspvw(0)}`, mt: `${rspvw(28)}` }}>
                            <Typography sx={{ mt: '00vh', mr: '0vw', ml: '0vh', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(20)}` }}>
                              Total hiring manager costs
                            </Typography>
                          </Grid>
                          <Grid sx={{ mt: `${rspvw(19)}`, ml: `${rspvw(180)}` }}>
                            <TextField id="outlined-basic"
                              placeholder=""
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              
                              name="eight"
                            

                            
                             value={calvalEight ? calvalEight: ""}
                              sx={{ textAlign: 'left', width: `${rspvw(298)}`, height: `${rspvw(60)}`, border: 1, borderColor: 'black', borderRadius: 1 }}
                              InputProps={{
                                disableUnderline: true, fontSize: `${rspvw(1)}`,
                                sx: { ml: `${rspvw(19.2)}`, cursor: 'none', mt: `${rspvw(5)}` },
                                type: 'search',
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid sx={{ mr: `${rspvw(0)}`, mt: `${rspvw(28)}` }}>
                            <Typography sx={{ mt: '00vh', mr: '0vw', ml: '0vh', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(20)}` }}>
                              Job advertising cost per day posted
                            </Typography>
                          </Grid>
                          <Grid sx={{ mt: `${rspvw(19)}`, ml: `${rspvw(100)}` }}>
                            <TextField id="outlined-basic"
                              placeholder=""
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              name="nine"
                             // value={input.nine}
                             // onChange={onchangeHandel}
                             onChange={onchangeHandelNine}
                             
                             
                              sx={{ textAlign: 'left', width: `${rspvw(298)}`, height: `${rspvw(60)}`, border: 1, borderColor: 'black', borderRadius: 1 }}
                              InputProps={{
                                disableUnderline: true, fontSize: `${rspvw(1)}`,
                                sx: { ml: `${rspvw(19.2)}`, cursor: 'none', mt: `${rspvw(5)}` },
                                type: 'search',
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid sx={{ mr: `${rspvw(0)}`, mt: `${rspvw(28)}` }}>
                            <Typography sx={{ mt: '00vh', mr: '0vw', ml: '0vh', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(20)}` }}>
                              Days job posted
                            </Typography>
                          </Grid>
                          <Grid sx={{ mt: `${rspvw(19)}`, ml: `${rspvw(276)}` }}>
                            <TextField id="outlined-basic"
                              placeholder=""
                              variant="standard"
                              type="number"
                              autoComplete="off"

                              name="ten"
                            //  value={input.ten}
                             // onChange={onchangeHandel}
                             onChange={onchangeHandelTen}

                              sx={{ textAlign: 'left', width: `${rspvw(298)}`, height: `${rspvw(60)}`, border: 1, borderColor: 'black', borderRadius: 1 }}
                              InputProps={{
                                disableUnderline: true, fontSize: `${rspvw(1)}`,
                                sx: { ml: `${rspvw(19.2)}`, cursor: 'none', mt: `${rspvw(5)}` },
                                type: 'search',
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid sx={{ mr: `${rspvw(0)}`, mt: `${rspvw(28)}` }}>
                            <Typography sx={{ mt: '00vh', mr: '0vw', ml: '0vh', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(20)}` }}>
                              Total job advertising cost
                            </Typography>
                          </Grid>
                          <Grid sx={{ mt: `${rspvw(19)}`, ml: `${rspvw(190)}` }}>
                            <TextField
                              placeholder=""
                              variant="standard"
                              type="number"
                              autoComplete="off"
                              name="eleven"
                            
                            
                             value={calvalEleven ? calvalEleven: ""}
                             
                              sx={{ textAlign: 'left', width: `${rspvw(298)}`, height: `${rspvw(60)}`, border: 1, borderColor: 'black', borderRadius: 1 }}
                              InputProps={{
                                disableUnderline: true, fontSize: `${rspvw(1)}`,
                                sx: { ml: `${rspvw(19.2)}`, cursor: 'none', mt: `${rspvw(5)}` },
                                type: 'search',
                              }}

                            />

                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid sx={{ mr: `${rspvw(0)}`, mt: `${rspvw(28)}` }}>
                            <Typography sx={{ font: 'Roboto', fontSize: `${rspvw(20)}`, mr: '0vw', color: '#31312C', opacity: '1', fontWeight: 'bold', }}>
                              Total hiring cost
                            </Typography>
                          </Grid>
                          <Grid sx={{ mt: `${rspvw(19)}`, ml: `${rspvw(270)}` }}>
                            <Typography sx={{ font: 'Roboto', fontSize: '2.96vh', mr: '0vw', color: '#31312C', opacity: '1', fontWeight: 'bold', }}>
                              {/* USD {totalSum} */}
                            {/* USD  {totalFinal} */}
                            USD {totalResult}
                           
                            </Typography>
                          </Grid>
                        </Grid>

                      </Box>
                    </Box>

                    <ListItemSecondaryAction>
                      <Grid sx={{ mr: `${rspvw(96)}`, ml: `${rspvw(70)}`, mt: `${rspvw(-60)}`, mb: '0vh' }}>
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Grid item>
                            <Typography sx={{ fontSize: `${rspvw(22)}`, mt: '0vh', mr: `${rspvw(96)}`, font: 'Roboto', fontWeight: 'bold', color: '#31312C', opacity: '1', fontSize: `${rspvw(22)}` }}>View Presentation Video</Typography>
                          </Grid>

                        </Grid>

                        <Box sx={{ mr: '0.00vw', ml: '0vw', mt: `${rspvw(90.2)}`, mb: '0vh', width: `${rspvw(497.6)}`, height: `${rspvw(529.4)}` }}>
                          <img src={emp1} alt = "Loading..." width="100%" heigh="100%" />
                        </Box>

                        <Typography sx={{
                          fontSize: `${rspvw(22)}`, mt: `${rspvw(-205.2)}`, mr: `${rspvw(96)}`,
                          font: 'Roboto', fontWeight: 'bold', color: '#31312C', opacity: '1',
                        }}>View Presentation</Typography>
                        <Stack spacing={0} direction="row" justifyContent="space-between">
                          <Box sx={{ mr: '0vw', ml: '0vw', mt: `${rspvw(29.2)}`, mb: `${rspvw(-10.8)}`, width: `${rspvw(23)}`, height: `${rspvw(26.7)}` }}>
                            <img src={download} alt = "Loading..." width="100%" heigh="100%" />
                          </Box>

                          <Typography sx={{ fontSize: `${rspvw(22)}`, ml: `${rspvw(15.64)}`, mt: `${rspvw(32.4)}`, mr: `${rspvw(188.3)}`, font: 'Roboto', fontWeight: 'normal', color: '#31312C', opacity: '1' }}>
                            Download Presentation Material
                          </Typography>
                        </Stack>

                        <Stack spacing={0} direction="row" justifyContent="space-between">
                          <Box sx={{ mr: '0vw', ml: '0vw', mt: `${rspvw(29.2)}`, mb: `${rspvw(-10.8)}`, width: `${rspvw(23)}`, height: `${rspvw(26.7)}` }}>
                            <img src={download} alt = "Loading..." width="100%" heigh="100%" />
                          </Box>

                          <Typography sx={{ fontSize: `${rspvw(22)}`, ml: `${rspvw(15.64)}`, mt: `${rspvw(32.4)}`, mr: `${rspvw(188.3)}`, font: 'Roboto', fontWeight: 'normal', color: '#31312C', opacity: '1' }}>
                            Download Presentation Material
                          </Typography>

                        </Stack>

                        <Stack spacing={0} direction="row" justifyContent="space-between">
                          <Box sx={{ mr: '0vw', ml: '0vw', mt: `${rspvw(29.2)}`, mb: `${rspvw(-10.8)}`, width: `${rspvw(23)}`, height: `${rspvw(26.7)}` }}>
                            <img src={download} alt = "Loading..." width="100%" heigh="100%" />
                          </Box>

                          <Typography sx={{ fontSize: `${rspvw(22)}`, ml: `${rspvw(15.64)}`, mt: `${rspvw(32.4)}`, mr: `${rspvw(188.3)}`, font: 'Roboto', fontWeight: 'normal', color: '#31312C', opacity: '1' }}>
                            Download Presentation Material
                          </Typography>
                        </Stack>
                        <Stack spacing={0} direction="row" justifyContent="space-between">
                          <Box sx={{ mr: '0vw', ml: '0vw', mt: `${rspvw(29.2)}`, mb: `${rspvw(-10.8)}`, width: `${rspvw(23)}`, height: `${rspvw(26.7)}` }}>
                            <img src={download} alt = "Loading..." width="100%" heigh="100%" />
                          </Box>

                          <Typography sx={{ fontSize: `${rspvw(22)}`, ml: `${rspvw(15.64)}`, mt: `${rspvw(32.4)}`, mr: `${rspvw(188.3)}`, font: 'Roboto', fontWeight: 'normal', color: '#31312C', opacity: '1' }}>
                            Download Presentation Material
                          </Typography>

                        </Stack>
                      </Grid>
                    </ListItemSecondaryAction>

                  </ListItemButton>

                </List>
                </ThemeProvider>
                </Box>
                <Grid container>
                  <Grid sx={{ ml: '5vw', mt: '8.65vh' }}>
                    <Typography sx={{ font: 'Roboto', fontSize: '2.04vh', color: '#31312C', opacity: '1', }}>
                      DISCLAIMER
                    </Typography>
                  </Grid>
                  <Grid sx={{ ml: '5vw', mt: '1.20vh', mr: '7.45vw', mb: '2.20vh' }}>
                    <Typography sx={{ font: 'Roboto', fontSize: '1.90vh', color: '#616161', opacity: '1', }}>
                    *The calculator is based on some simplified assumptions, is to be used for illustration and educative purposes only and is not intended to be exhaustive. Customers should conduct their own due diligence and analysis. Nestfuel does not take liability for the consequences of any decisions or actions taken in reliance upon or as a result of the information or calculations provided by the calculator.
                    </Typography>
                  </Grid>
                </Grid>
              </MainCard>

            </Grid>
          </Grid>
        </Box>

       
      </Box>
    </React.Fragment>
  );
}

function InputKey() {

  const name = 'password'
  const type = 'password'
  const [cur, setCur] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);

  function onClickFun(e) {
    const val1 = e.currentTarget.value
    val1 === "N3$tfu3L" ? allowed = 'yes' : allowed = 'no'


  };

  return (
    <div position='fixed' align='center'>
      <Typography sx={{ fontSize: '1vw', width: '12vw' }}>Please answer the following to activae DISCOVER NESTFUEL (Hint:Army man of the Raj earns working days by a week 7-6-3)</Typography>
      <Typography sx={{ mt: 2, fontSize: '1vw', width: '12vw' }}></Typography>
      <TextField sx={{ fontsize: '0.5vw', mt: 1, width: '12vw', border: 1, borderColor: 'black', borderRadius: 2 }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label
        autoFocus={true}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </div>
  )
}

export default EmployerCompanyCal;