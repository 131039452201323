import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/bg-10@1x.png'
import {  useDispatch, 
          useSelector } from 'react-redux';
import { Centerbottom, LeftbottomA, RightBlockEB, RightBlockSPBP, RightBlockSPBPE} from "./RightBlock";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { withCommas } from "../../constants";

import { borrowerProfile, borrowerProfileStatus, borrowerProfileNew } from "../../actions/borrower";

import { LeftBlockMsg } from "./Messenger";
import { rspvw } from "./styles";
export function EmployerBenefit() {
  const { isLoading,
    isEmbFlow,
    isBchoiceDLoading
        } = useSelector((state)=>state.borrower);
  
  const { bProfile, bProfileStatus } = useSelector((state)=>state.borrower);
  const dispatch = useDispatch();
  
  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 

  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "employer": 
        navigate("/EmployerSupport", { replace: false });
        break;
      case "profile": 
        navigate("/BorrowerProfileEmp", { replace: false });
        break;
      case "self": 
        var employmentType='';
        employmentType= "Self Employed";
        dispatch(borrowerProfile({...bProfile,employmentType:employmentType}));
        dispatch(borrowerProfileStatus({...bProfileStatus,employmentProvided:1}));
        //navigate("/ShowPurchaseSelfEmp", { replace: false });
        navigate("/BorrowerProfileEmp", { replace: false });

        //navigate("/ShowPurchaseSelfEmp", { replace: false });
        break;
      default:break;
    }
  }

  return(
    isLoading || isBchoiceDLoading? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
      <React.Fragment><CssBaseline/>
             <Box mt={marginT} ml={marginL} sx={{overflow:'hidden', width: '100%',
             background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
              <Navbar pathname={location.pathname}/>
              
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1}}>
                { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
                
                <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
                  <div align='center'>
                    {/* <Stack sx={{mt:'18.5vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
                      <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: 0,width:'5.23vw',height:'3.5vw',border:0}} id="employer" 
                      onClick={onClickFun}> 
                      <HexButton titleText="Evaluate Benefit" type='base' fontSize='24'/></Button>
                    </Stack> */}
                    <Stack sx={{mt:'14.5vw',height:'3.5vw', width:'12vw'}} spacing={0} direction="row" justifyContent="space-around">
                  {isEmbFlow ?
                    <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="employer" className='hexBtnCenter'
                      disabled='true'>
                      <HexButton titleText="Evaluate Benefit" type='disabledNew' fontSize='24' /></Button> :
                    <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="employer" className='hexBtnCenter'
                      onClick={onClickFun}>
                      <HexButton titleText="Evaluate Benefit" type='base' fontSize='24' /></Button>
                  }

                      <Button sx={{ overflow: 'hidden',textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',width:'5.23vw',height:'6.80vw',border:0}} id="profile" className='hexBtnCenter'
                      onClick={onClickFun}> 
                      <HexButton titleText="Update Profile" type='base' fontSize='24'/></Button>
                      {/* <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: 0,mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="self" 
                      onClick={onClickFun}> 
                      <HexButton titleText="Self Employed" type='base' fontSize='24'/></Button> */}
                    </Stack>
                    <Centerbottom navigateForward = "BorrowerProfileEmp"/>
                  </div>                  
                </Box>
        <RightBlockSPBPE rbops='employer'/>
      </Box>
    </Box>
    </React.Fragment>
  ));
}

function LeftBlock () {

  const dispatch = useDispatch();
  const { bChoiceE,
          bChoiceD,
          bChoiceA,
          bChoiceDSFB
        } = useSelector((state)=>state.borrower);
  const max = bChoiceA === "purchase" ? Math.ceil(bChoiceDSFB?.extendedAfford?.propPrice/100)*100 : Math.ceil(Math.min(bChoiceDSFB?.extendedAfford?.propPrice, bChoiceD[0].offerPrice*0.92)/100)*100

  // const max     = bChoiceA === "purchase" ? Math.ceil(bChoiceDSFB?.saving?.propPrice)  : Math.ceil(Math.min(bChoiceDSFB?.tradAfford?.loan,bChoiceD[0].offerPrice*0.92)/100)*100
  // const max     =1000000000000000000000000000

  
  
  const savings = Math.floor(bChoiceDSFB?.saving?.savings)
  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
        <Box sx={{ mt:'6.2vw' , ml: '7.3vw', width: '26vw',height:'10vw' }}> 
            <Typography sx={{color:'#31312C', width:'26vw',
              fontSize:'2.4vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '-0.11vw'}}> 
                Employer Benefit Scheme</Typography>
            <Typography sx={{color:'#31312C',width:'20vw', mt:'1.2vw',
              fontSize:'5.21vw', fontFamily:'Roboto',fontWeight:500,textAlign:'justify',lineHeight:1.1,letterSpacing: '-0.23vw'}}> 
                {withCommas(max)}</Typography>
            <Typography sx={{color:'#31312C', width:'26vw', mt:'1.2vw',
              fontSize:'2.4vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '-0.11vw'}}> 
                Extend purchasing power</Typography>
            <Typography sx={{color:'#31312C', width:'26vw', mt:'1.2vw',
              fontSize:'1.67vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                Or reduce your annual payment by
            </Typography>
            <Typography sx={{color:'#31312C',width:'20vw', mt:'1.2vw',
              fontSize:'3.64vw', fontFamily:'Roboto',fontWeight:500,textAlign:'justify',lineHeight:1.1,letterSpacing: '-0.167vw'}}> 
                {withCommas(savings)}
            </Typography>
        </Box>

        <LeftbottomA leftbottom={leftbottom}/>
    </Box>
  );
}

export default EmployerBenefit