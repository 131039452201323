import React from "react";
import { Grid,Box, Stack,CssBaseline, Typography, Button} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/bg-10@1x.png'
import {Card, ListGroup, ListGroupItem} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Centerbottom, LeftbottomA, RightBlockApply } from "./RightBlock";
import { withCommas } from "../../constants";
import { LeftBlockMsg } from "./Messenger";

function SummaryApplication() {
      const navigate = useNavigate();
      const location = useLocation();

      function onClickFun(e){
        e.preventDefault();
        const buttonId = e.currentTarget.id;
        switch(buttonId) {
          case "eyes":
            navigate("/NestfuelMessenger", { replace: false });
            break;
          default:
            break;
        }
      }

      return(
        <React.Fragment>
        <CssBaseline/>
        <Box sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
          <Navbar pathname={location.pathname}/>
          <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, 
                  borderRadius: 1,}}>
          { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
          
            <Box sx={{ width: '14vw', display:'block', alignItems:'center',position:'relative'}}>
            <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> 
              Do you want to Apply
            </Typography>
            <div align='center'>
            <Stack sx={{mt:'2vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
              <Button sx={{ overflow: 'hidden',textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',width:'5.23vw',height:'6.80vw',border:0}} id="eyes"  className='hexBtnCenter'
                  onClick={onClickFun}> 
                  <HexButton titleText="Yes" type='base' fontSize='24'/></Button>
            </Stack>
              <Centerbottom/>
              </div>
            </Box>
            <RightBlockApply/>
          </Box>
        </Box>
      </React.Fragment>
    );
  }

  function RightBlock() {
    const { selectedProp,
            bChoiceH,
            bfinancialProfile
          } = useSelector((state)=>state.borrower);
   
    return (
      <React.Fragment>
        <CssBaseline/>
        <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '50vw',height:'90vh' }}>
          <Box sx={{p:1,background:'#31312C 0% 0% no-repeat padding-box', width: "100%",height:"90vh" }}>
              <ImageTextCard CardTitle="Your Finance Options" 
                  CardElement   = {selectedProp.prop}
                  propertyPrice = {bChoiceH.propertyPrice}
                  mortgageType  = {bChoiceH.mortgageType}
                  loanAmount    = {bChoiceH.loanAmount}
                  Income        = {bfinancialProfile.annualIncome}
                  upfront       = {bChoiceH.upfront}
                  monthly       = {bChoiceH.monthlyEMI}
                  interestRate  = {bChoiceH.interestRate} 
                  loanTerm      = {bChoiceH.loanTerm}/>    
                </Box>
        </Box>
  </React.Fragment>
    );

  }

  function LeftBlock() {
      const {bChoiceH,selectedProp} = (useSelector((state)=>state.borrower));
      const dispatch = useDispatch();
      const navigate = useNavigate();

    function onClickFun(e){
      e.preventDefault();
      const buttonId = e.currentTarget.id;
      switch(buttonId) {
        case "Apply": 
        break;
        default: break;
      }
    }
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
                <Box sx={{ ml:"20%", mt:'10%' , background:'inherit', width:'60%', height: '60vh' }}> 
                <div>
                <Stack spacing={1} direction="row" justifyContent="space-between">
                    <Typography fontSize='1.1vw' fontStyle="regular" component='div' color='#000000'> Rate Estimate</Typography>
                    <Typography fontSize='1.3vw' fontStyle="regular" component='div' color='#000000'> {bChoiceH.interestRate}%</Typography>
                  </Stack>
                  <Stack spacing={1} direction="row" justifyContent="space-between">
                    <Typography fontSize='1.1vw' fontStyle="regular" component='div' color='#000000'> Price </Typography>
                    <Typography fontSize='1.3vw' fontStyle="regular" component='div' color='#000000'> {withCommas(selectedProp.prop.offerPrice)}</Typography>
                  </Stack>
                  <Stack spacing={1} direction="row" justifyContent="space-between">
                    <Typography fontSize='1.1vw' fontStyle="regular" component='div' color='#000000'> Borrowing </Typography>
                    <Typography fontSize='1.3vw' fontStyle="regular" component='div' color='#000000'> {withCommas(bChoiceH.loanAmount)}</Typography>
                  </Stack>
                </div>

                <div align='center'>
                  <Typography sx={{ mt:4, width:'24vw', align:'center' ,height:'5vh'}} fontSize='1.5vw' component="div"
                              color='#000' align='center'>
                      Summary of your Profile
                  </Typography>
                </div>
                <div>
                <DataSummary/>
                </div>
                
                </Box>
          <LeftbottomA leftbottom={leftbottom}/>
      </Box>
    );
  }

  function DataSummary(){
      const { bProfile,
              bChoiceE,
              bfinancialProfile
            } = useSelector((state)=>state.borrower);
   return(
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
          <Box sx={{pt:1,pl:1,pr:1, border:1,borderColor:"#000000",borderRadius:2, height:'10vh' }}>
            <Typography sx={{color:"#000000", textAlign:'center'}} fontSize='0.8vw'>Ownership</Typography>
            <Typography sx={{color:"#000000", textAlign:'center'}} fontSize='0.7vw' fontWeight='bold'>{bChoiceE.ownership}</Typography>
          </Box>
          </Grid>
          <Grid item xs={4}>
          <Box sx={{p:1, border:1,borderColor:"#000000",borderRadius:2,height:'10vh'}}>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw'>Employment</Typography>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw' fontWeight={700}>{bProfile.employmentType}</Typography>
           
          </Box>
          </Grid>
          <Grid item xs={4}>
          <Box sx={{p:1, border:1,borderColor:"#000000",borderRadius:2 ,height:'10vh'}}>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw'>FICO Score</Typography>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw' fontWeight={700}>{bfinancialProfile.ficoScore}</Typography>
          </Box>
          </Grid>
          <Grid item xs={4}>
          <Box sx={{p:1, border:1,borderColor:"#000000",borderRadius:2 ,height:'10vh'}}>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw'>Income</Typography>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw'fontWeight={700}>{withCommas(bfinancialProfile.annualIncome)}</Typography>
          </Box>
          </Grid>
          <Grid item xs={4}>
          <Box sx={{p:1, border:1,borderColor:"#000000",borderRadius:2 ,height:'10vh'}}>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw'>Outgoing</Typography>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw'fontWeight={700}>{withCommas(bfinancialProfile.monthlyOutgoing)}</Typography>
          </Box>
          </Grid>
          <Grid item xs={4}>
          <Box sx={{p:1, border:1,borderColor:"#000000",borderRadius:2 ,height:'10vh'}}>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw'>Documents</Typography>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw'fontWeight={700}>{bfinancialProfile.financeDocs}</Typography>
          </Box>
          </Grid>
          <Grid item xs={4}>
          <Box sx={{p:1, border:1,borderColor:"#000000",borderRadius:2 ,height:'10vh'}}>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw'>Bankruptcy</Typography>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw'fontWeight='bold'>{bfinancialProfile.bankruptcy}</Typography>
          </Box>
          </Grid>
          <Grid item xs={4}>
          <Box sx={{p:1, border:1,borderColor:"#000000",borderRadius:2 ,height:'10vh',alignItems:'center'}}>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw'>Residency</Typography>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw' fontWeight='bold'>{bProfile.residency}</Typography>
          </Box>
          </Grid>
          <Grid item xs={4}>
          <Box sx={{p:1, border:1,borderColor:"#000000",borderRadius:2 ,height:'10vh',alignItems:'center'}}>
            <Typography sx={{color:"#000000", textAlign:"center" }}fontSize='0.8vw'>Govt Loan Eligibility </Typography>
            <Typography sx={{color:"#000000", textAlign:"center", fontWeight:'bold' }}fontSize='0.8vw'>{bProfile.govtloanEligibility}</Typography>
          </Box>
          </Grid>
          
        </Grid>
      </Box>
    );
   
  }



  function ImageTextCard(props){

    const { CardElement,
            propertyPrice, 
            mortgageType,
            loanAmount,
            Income,
            upfront,
            monthly,
            interestRate,
            loanTerm } = props; 
            
  return (
  <Card style={{ width: '24rem' }}>
    <Card.Body>
      <Card.Title>
      <Box sx={{p:0.25, border:4, borderColor:"#FFFFFF", borderRadius:4, width:"100%", height:"100%"}} >
     <img src={CardElement.thumbnail} width="100%"/>
      </Box>
      </Card.Title>
      <Card.Text>
      <Stack spacing={1} direction="row" justifyContent="center">
      <Typography width='15vw' fontSize='1vw' color='#000000'>{CardElement.address}</Typography>
      <Typography fontSize='1vw' color='#000000' fontWeight={700}>{CardElement.city}</Typography>
      </Stack>
      </Card.Text>
    </Card.Body>
    <ListGroup className="list-group-flush">
      <ListGroupItem>
      <Stack spacing={1} direction="row" justifyContent="space-around">
      <Typography width='15vw' fontSize='1vw' color='#707070'> Property Price</Typography>
      <Typography fontSize='1vw' color="#000000" fontWeight={700}>$ {withCommas(propertyPrice)}</Typography>
      </Stack>
      </ListGroupItem>
      <ListGroupItem>
      <Stack spacing={1} direction="row" justifyContent="space-around">
      <Typography width='15vw' fontSize='1vw' color='#707070'> Loan Amount</Typography>
      <Typography fontSize='1vw' color="#000000" fontWeight={700}>$ {withCommas(loanAmount)}</Typography>
      </Stack>
      </ListGroupItem>
      <ListGroupItem>
      <Stack spacing={0} direction="row" justifyContent="space-around">
      <Typography width='15vw' fontSize='1vw' color='#707070'> Upfront </Typography>
      <Typography fontSize='1vw' color="#000000" fontWeight={700}>$ {withCommas(upfront)}</Typography>
      </Stack>
      </ListGroupItem>
      <ListGroupItem>
      <Stack spacing={0} direction="row" justifyContent="space-around">
      <Typography width='15vw' fontSize='1vw' color='#707070'> Monthly </Typography>
      <Typography fontSize='1vw' color="#000000" fontWeight={700}>$ {withCommas(monthly)}</Typography>
      </Stack>
      </ListGroupItem>
      <ListGroupItem>
      <Stack spacing={0} direction="row" justifyContent="space-around">
      <Typography width='15vw' fontSize='1vw' color='#707070'> Rate </Typography>
      <Typography fontSize='1vw' color="#000000" fontWeight={700}> {interestRate}%</Typography>
      </Stack>
      </ListGroupItem>
      <ListGroupItem>
      <Stack spacing={0} direction="row" justifyContent="space-around">
      <Typography fwidth='15vw' ontSize='1vw' color='#707070'> Loan Term </Typography>
      <Typography fontSize='1vw' color="#000000" fontWeight={700}> {loanTerm} months</Typography>
      </Stack>
      </ListGroupItem>
    </ListGroup>
    <Card.Body>
    </Card.Body>
  </Card>
    );
  }

export default SummaryApplication;