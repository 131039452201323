import PropTypes from 'prop-types';

// material-ui
import { Box, Chip, Grid, Stack, Typography, Divider, IconButton, Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
//import person from '../../assets/svg/person.svg'
// project import
import MainCard from './MainCard';

// assets
//import { RiseOutlined, FallOutlined } from '@ant-design/icons';
import OutlinedInput from '@mui/material/OutlinedInput';
import { width } from '@mui/system';
import { rspvw } from './styles';

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const AnalyticResourceOne = ({ color, title, count, image, percentage, isLoss, extra }) => (
    <MainCard contentSX={{ p: 2.25, elevation: 10, border: 0, width: '13.68vw', height: '12.94', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }} >
        <Stack spacing={0.5}  >
            <Typography sx={{ fontWeight: 'Regular', fontSize: '2.04vh', textTransform: 'none', font: 'Roboto', color: '#31312C', ml: '0.1vh' }}>
                {title}
            </Typography>
            <Divider sx={{
                border
                    : '0.5px solid #DEDEDE', opacity: 1, ml: '0.1vw'
            }} />

        </Stack>
        <Stack spacing={0} direction="row" justifyContent="space-between" sx={{ mt: 1.25, mb: -1 }}>
            <Box sx={{ mt: `${rspvw(37)}` }}>

                <Typography sx={{
                    width: `${rspvw(96)}`, mt: `${rspvw(40)}`, fontWeight: 'Bold', fontSize: `${rspvw(32)}`, font: 'Roboto', color: '#31312C', ml: `${rspvw(0.2)}`,
                }}>
                    {count}

                </Typography>
            </Box>


            <Box
                sx={{ mt: `${rspvw(84.2)}`, ml: '0vw', width: `${rspvw(40.4)}`, height: `${rspvw(38)}` }}
            >
                <img src={image} height='100%'></img>
            </Box>
        </Stack>

    </MainCard>
);

AnalyticResourceOne.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    count: PropTypes.string,
    image: PropTypes.string,
    percentage: PropTypes.number,
    isLoss: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

AnalyticResourceOne.defaultProps = {
    color: 'primary'
};

export default AnalyticResourceOne;
