import { _debug } from '../constants';
import axios from 'axios';

import { initializeApp,getApp, } from 'firebase/app';
import { getAuth ,getIdToken,onAuthStateChanged, PhoneAuthProvider} from "firebase/auth";
import {mongoborrowerPostUserData} from '../actions/borrower';
import {mongoborrowerPostUserDataFun} from '../actions/borrower';

var API;
var APIMSG;
var APIEMB
var app;
var firebaseConfig;


if(_debug === 'development'){

    API = axios.create({ baseURL: process.env.REACT_APP_ApiBaseUrlUAT });
    APIMSG = axios.create({ baseURL:process.env.REACT_APP_ApiBaseUrlNESUAT});
    console.log("development===>",process.env.REACT_APP_ApiBaseUrlUAT )


    
    
    firebaseConfig = {
        apiKey            : process.env.REACT_APP_GOOGLE_AUTH_API_KEY_UAT,
        authDomain        : process.env.REACT_APP_GOOGLE_AUTH_DOMAIN_UAT,
        projectId         : process.env.REACT_APP_GOOGLE_AUTH_PROJECTID_UAT,
        storageBucket     : process.env.REACT_APP_GOOGLE_AUTH_BUCKET_UAT,
        messagingSenderId : process.env.REACT_APP_GOOGLE_AUTH_MSG_SNDR_ID_UAT,
        appId             : process.env.REACT_APP_GOOGLE_AUTH_APP_ID_UAT,
        measurementId     : process.env.REACT_APP_GOOGLE_AUTH_MSRMT_ID_UAT
    };

    try{app = getApp()}catch(error){ app = initializeApp(firebaseConfig);}
    const auth = getAuth(app);
    auth.onAuthStateChanged(async (user) => {
        console.log("user onauth changed===>",user);
        if(sessionStorage.getItem('authToken') == null && user?.providerData[0]?.providerId == PhoneAuthProvider.PROVIDER_ID){
            console.log("inside phone provider");
            let apiBodyData = {
            "data":{
              "isBorrower":true,
              "phoneNumber": auth.currentUser.phoneNumber,
                }
            }
            const authToken = await getIdToken(user,true)
            sessionStorage.setItem('authToken', authToken);
            const response = await mongoborrowerPostUserDataFun(apiBodyData);
            console.log("response12===>",response);
         }
        if (user) {
            const authToken = await getIdToken(user,true);  
            sessionStorage.setItem('authToken', authToken);            
            console.log("authToken onauth changed===>",sessionStorage.getItem('authToken'));
        } else {
            // User is signed out
            console.log("user is signed out");
        }
    }); 
    API.interceptors.request.use( async (req) => {
        try{ 
    const authToken = sessionStorage.getItem('authToken');
            if(authToken){
            // const authToken = sessionStorage.getItem('authToken');
            console.log("authtoken interceptor===>",authToken);
            req.headers.token = authToken;
            req.headers.Authorization = "Bearer "+authToken;
            console.log("req interceptor===>",req, req.headers.authorization);
            }
            if(!req.headers.token) req.headers.token = '5a1f750ee6aca2ce1c6654be5'
            return req;
        }catch(error){
            req.headers.token = '5a1f750ee6aca2ce1c6654be5'
            return req;
        }
    });

}else{
    API = axios.create({ baseURL: process.env.REACT_APP_ApiBaseUrlDev });
    APIMSG = axios.create({ baseURL:process.env.REACT_APP_ApiBaseUrlNESDEV});
    console.log("else===>",process.env.REACT_APP_ApiBaseUrlDev )
   



    firebaseConfig = {
        apiKey            : process.env.REACT_APP_GOOGLE_AUTH_API_KEY,
        authDomain        : process.env.REACT_APP_GOOGLE_AUTH_DOMAIN,
        projectId         : process.env.REACT_APP_GOOGLE_AUTH_PROJECTID,
        storageBucket     : process.env.REACT_APP_GOOGLE_AUTH_BUCKET,
        messagingSenderId : process.env.REACT_APP_GOOGLE_AUTH_MSG_SNDR_ID,
        appId             : process.env.REACT_APP_GOOGLE_AUTH_APP_ID,
        measurementId     : process.env.REACT_APP_GOOGLE_AUTH_MSRMT_ID
    };

    try{app = getApp()}catch(error){ app = initializeApp(firebaseConfig);}
    const auth = getAuth(app);
    auth.onAuthStateChanged(async (user) => {
        if(sessionStorage.getItem('authToken') == null && user?.providerData[0]?.providerId == PhoneAuthProvider.PROVIDER_ID){
            let apiBodyData = {
            "data":{
              "isBorrower":true,
              "phoneNumber": auth.currentUser.phoneNumber,
                }
            }
            const authToken = await getIdToken(user,true)
            sessionStorage.setItem('authToken', authToken);
            const response = await mongoborrowerPostUserDataFun(apiBodyData);
         }
        if (user) {
            const authToken = await getIdToken(user,true);  
            sessionStorage.setItem('authToken', authToken);            
            console.log("authToken onauth changed===>",sessionStorage.getItem('authToken'));
        } else {
            // User is signed out
            console.log("user is signed out");
        }
    }); 
    API.interceptors.request.use( async (req) => {
        try{ 
    const authToken = sessionStorage.getItem('authToken');
            if(authToken){
            // const authToken = sessionStorage.getItem('authToken');
            req.headers.token = authToken;
            req.headers.Authorization = "Bearer "+authToken;
            }
            if(!req.headers.token) req.headers.token = '5a1f750ee6aca2ce1c6654be5'
            return req;
        }catch(error){
            req.headers.token = '5a1f750ee6aca2ce1c6654be5'
            return req;
        }
    });
    // const { currentUser } = auth
    // if(currentUser) API.interceptors.request.use( async (req) => {
    //     try{
    //         req.headers.token = await getIdToken(currentUser,true)
    //         if(!req.headers.token) req.headers.token = '5a1f750ee6aca2ce1c6654be5'
    //         return req;
    //     }catch(error){
    //         req.headers.token = '5a1f750ee6aca2ce1c6654be5'
    //         return req;
    //     }
    // });
      
}


// const APIX = axios.create({ baseURL: process.env.REACT_APP_Z_ACCESS });

//const APIX = axios.create({ baseURL: ''});





// const API = axios.create({ baseURL: 'https://api-uat.nestfuel.com/' });

//API.interceptors.request.use((req) => {
//  if (localStorage.getItem('profile')) {
//    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
//  }

//  return req;
//});

//export const getAutoComplete = (addressQuery) => API.get('/address/v1/autocomplete/property?text=' + encodeURIComponent(addressQuery));
export const getAutoComplete = (addressQuery) => API.get('/address/v1/autocomplete?text=' + encodeURIComponent(addressQuery));
//export const getPropertyAt = (propertyAddress,propertyType,latitude,longitude,locality,cashAvailable,annualIncome) => //console.log(longitude);
//console.log('properties/v1/search/address/' + encodeURIComponent(propertyAddress) + `?lat=${latitude}&lon=${longitude}&city=` + encodeURIComponent(locality) +`&type=${propertyType}`);           
//API.get('properties/v1/search/address/' + encodeURIComponent(propertyAddress) + `?lat=${latitude}&lon=${longitude}&city=` + encodeURIComponent(locality) +`&type=${propertyType}`);
export const getPropertyAt = (propertyAddress,propertyType,latitude,longitude,locality,cashAvailable,annualIncome,mlsId,finType) => //console.log(longitude);
           API.get('properties/v1/search/address/' + encodeURIComponent(propertyAddress) + `?lat=${latitude}&lon=${longitude}&city=` + encodeURIComponent(locality) +`&type=${propertyType}&cashAvailable=${cashAvailable}&annualIncome=${annualIncome}&mlsId=${mlsId}&finType=${finType}`);
export const getPropertyCloseto = (mlsId,workLocation) => API.get(`properties/v1/search/${mlsId}?wl=${workLocation}`);
export const getProperty = (mlsId) => API.get(`properties/v1/search/${mlsId}`);
export const preferredLocationListing = (data) => API.post(`properties/v1/search/preferred`,data);

export const getLowerMonthly = (emailId) => API.get(`properties/v1/lowerMonthly/${emailId.toLowerCase()}`);
export const getPreferredProperty = (propertyAddress, type,latitude, longitude) =>
            API.get('properties/v1/finance/'+ encodeURIComponent(propertyAddress) + `?type=${type}&lat=${latitude}&lon=${longitude}`);
export const getBorrowerFinanceDetail = (upfront, fico, investmentType, income) =>
            API.get(`/properties/v1/finance/lowestRate?upfront=${upfront}&fico=${fico}&investmentType=${investmentType}&income=${income}`)
export const partBuyPartRent = (data) => API.post('properties/v1/lowerUpfront/partBuyPartRent', data);
export const rentToBuy = (data) => API.post('properties/v1/lowerUpfront/RentToBuy', data);
export const pledgeAssets = (data) => API.post('properties/v1/lowerUpfront/pledgeAssets', data);
export const depositFinancing = (data) => API.post('properties/v1/lowerUpfront/depositFinancing', data);

export const interestOnly = (data) => API.post('properties/v1/lowerMonthly/interestOnly', data);
export const reducedMonthly = (data) => API.post('properties/v1/lowerMonthly/reducedMonthly', data);
export const payPoints = (data) => API.post('properties/v1/lowerMonthly/payPoints', data);
export const longerTerm = (data) => API.post('properties/v1/lowerMonthly/longerTerm', data);

export const mortgageData = (data) => API.post('properties/v1/finance', data);
export const CheapestFinance = (data) => API.post('properties/v1/finance/cheapest', data);
export const mortgagePartitions = (data) => API.post('properties/v1/finance/partitions', data);
export const smartFinanceOptions = (data) => API.post('properties/v1/finance/smart', data);
export const financeEligibility = (data) => API.post('properties/v1/finance/eligibility', data);
export const compareFinance = (data) => API.post('properties/v1/finance/compare', data);
export const compareFinanceA = (data) => API.post('properties/v1/search/extendedAffordability', data);
export const employerFinance = (data) => API.post('properties//v1/search/empSupport', data);

export const adminGetSwapRates = () => API.get('messages/v1/admin/swapTable');
export const adminPutSwapRates = (data) => API.put('messages/v1/admin/swapTable',data);

export const adminGetSpreadTable = (loanType,financeType) => API.get('messages/v1/admin/spreadTable'+ `/${loanType}/${financeType}`);
export const adminPutSpreadTable = (data) => API.put('messages/v1/admin/spreadTable',data);

export const refiRequest = (data) => API.post('properties/v1/refinance', data);
export const refiMaxCashout = (data) => API.post('properties/v1/refinance/cashoutlimit', data);
export const refiCompare = (data) => API.post('properties/v1/refinance/compare', data);
export const refiInit = (data) => API.post('properties/v1/refinance/init', data);

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);


//https://api-dev.nestfuel.com/messages/v1/admin/user/{userID} - GET. To get all messages for user UserID
//https://api-dev.nestfuel.com/messages/v1/admin/user - POST call for posting 
/*
{   data: 
    {
        userId: PvOvTrMegIVoNZ645Pn3En3OoR62, 
        Msg: Sachin 52, 
        MsgId: eff01aa7-0f25-490b-a66e-f07e665c1b5f, 
        MsgLabel: ALabel, 
        ActionStatus: true, 
        MsgType: text, 
        Answer: sachine 52 test new new
    }
}
*/
export const getMsg = (userID) => API.get(`messages/v1/admin/user/${userID}`);
export const sendMsg = (data) => API.post('messages/v1/admin/user', data);
export const getAllTelMsg = (userID) => APIMSG.post(`/api/v1/getAllMsgs`,{u:userID});
export const sendTelMsg = (data) => APIMSG.post('/api/v1/postnessmess',data)
export const getEmployeeDetails = (info) =>API.get(`employer/v1/search/getEmp/${info}`)
//https://api-dev.nestfuel.com/messages/v1/admin/user-detail
export const postPreApproval = (data) => APIMSG.post('api/v1/postPreapproval',data)
export const sendEmployeeIdentifier = (data) => API.post('properties/v1/search/employerProperties', data);

export const userRegister = (data) => API.post('messages/v1/admin/user-detail', data);



export const getUserDetail = () => API.get('messages/v1/admin/user-detail');

export const updateUserDetail = (data) => API.post('messages/v1/admin/user-detail', data);


export const getFinanceDetail = () => API.get('messages/v1/admin/user-finance-detail');

export const updateFinanceDetail = (data) => API.post('messages/v1/admin/user-finance-detail', data);

export const getPropertyDetail = () => API.get('messages/v1/admin/user-property-detail');

export const updatePropertyDetail = (data) => API.post('messages/v1/admin/user-property-detail', data);

//user-detail API
export const getUserDetailData = () => API.get('/user/v1/admin/user-detail');
export const postUserDetailData = (data) => API.post('/user/v1/admin/user-detail', data);
export const updateUserDetailData = (data) => API.put('/user/v1/admin/user-detail', data);
export const deleteUserDetailData = (data) => API.delete('/user/v1/admin/user-detail', data);

//user-property-detail API
export const getUserFinanceDetailData = () => API.get('/user/v1/admin/user-finance-detail');
export const postUserFinanceDetailData = (data) => API.post('/user/v1/admin/user-finance-detail', data);
export const updateUserFinanceDetailData = (data) => API.put('/user/v1/admin/user-finance-detail', data);
export const deleteUserFinanceDetailData = (data) => API.delete('/user/v1/admin/user-finance-detail', data);

//v1/admin/user-property-detail API
export const getUserPropertyDetailData = () => API.get('/user/v1/admin/user-property-detail');
export const postUserPropertyDetailData = (data) => API.post('/user/v1/admin/user-property-detail', data);
export const updateUserPropertyDetailData = (data) => API.put('/user/v1/admin/user-property-detail', data);
export const deleteUserPropertyDetailData = (data) => API.delete('/user/v1/admin/user-property-detail', data);

//v1/admin/preapproval-application
export const encompassPostPropertyDetail = (data) => API.post('/user/v1/admin/preapproval-application', data);
export const encompassGetPropertyDetail = () => API.get('/user/v1/admin/preapproval-application');


//export const getImages = (url) => APIX.post('https://graph.facebook.com/v14.0/?access_token=426134456165258|4R5AoCSHXBpaGdG_ihR1KDEiYws&scrape=true&id='+ url);
//export const getImages = (url) => APIX.post('https://graph.facebook.com/v14.0/?access_token=426134456165258|4R5AoCSHXBpaGdGYws&scrape=true&id='+ url);