import React from "react";
import { useNavigate } from "react-router-dom";
// material-ui
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  CssBaseline, InputAdornment, IconButton, Divider, FormControlLabel, Checkbox, Link, Select, InputLabel, Textarea
} from '@mui/material';
import { HexButton } from './ButtonIcons1';
import { getWindowDimensions } from '../../computations/helperFunctions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import leftbottom from '../../assets/svg/assets svg/image-leftbottom02.png'
import mask_group from '../../assets/staticPageAssets/MaskGroup107.png'
import bullet from '../../assets/staticPageAssets/Polygon.svg'
import useStyles from './styles';
import { BottomBox, LeftbottomA } from "./RightBlock";
import { _debug } from "../../constants";
import { border, fontWeight, width } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex";
import NavBarDashBoardUpdate from "./NavBarDashBoardUpdate";
import NavBarBreadCrumbUpdate from "./NavBarBreadCrumbUpdate";
import HumbergerMenu from "./DrawerMenu/HumbergerMenu";
import AnalyticEcommerce from './AnalyticEcommerce';
//import IncomeAreaChart from './IncomeAreaChart';
//import MonthlyBarChart from './MonthlyBarChart';
import { Bar, Line } from "react-chartjs-2";
import MainCard from './MainCard';
import "chartjs-plugin-datalabels";
import person from '../../assets/svg/person.svg'
import dollarsolid from '../../assets/svg/dollar-solid.svg'
import briefcasefill from '../../assets/svg/briefcase-fill.svg'
import graduationcap from '../../assets/svg/graduation-cap.svg'
import ContractTable from './ContractTable';
import InvoiceTable from './InvoiceTable';
import bxslike from '../../assets/svg/bxs-like.svg'
import ResourceTable from './ResourceTable';
import avatar_boy from '../../assets/svg/avatar-boy.png'
import circle from '../../assets/circle.png'
import viewfilled from '../../assets/svg/view-filled.svg'
import documentPDF from '../../assets/svg/document-pdf.svg'
import expandMore from '../../assets/svg/expand_more.svg'
import calender from '../../assets/svg/ios-calendar-outline.svg'
import ellipse from '../../assets/Ellipse.png'
import BreadCrumbMenu from "./DrawerMenu/BreadCrumbMenu";

import emp1 from '../../assets/emp1.png'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement, PointElement,
  Title,
  Tooltip,
  Legend, LineElement

} from 'chart.js';
import { breadcrumbData} from "../../actions/borrower";
import { useDispatch, useSelector } from 'react-redux';
var allowed = "no"


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip, LineElement,

  Legend, PointElement
);




function EmployerContract() {
  const navigate = useNavigate();
  const dispatch= useDispatch()
  dispatch(breadcrumbData("Your Admin"));
  const { height, width } = getWindowDimensions();
  const [seek, setSeek] = React.useState(allowed);
  const [hexaText1, setHexaText1] = React.useState('Investors')
  const [hexaText2, setHexaText2] = React.useState('Technology Partners')
  const [hexaText3, setHexaText3] = React.useState('Employers')
  const [hexaText4, setHexaText4] = React.useState('Mortgage Industry')


  const [value, setValue] = React.useState('today');
  const [slot, setSlot] = React.useState('week');
  // if(_debug === 'development') allowed = 'yes'
  allowed = 'yes'


  const state = {
    labels: ['January', 'February', 'March',
      'April', 'May'],
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [65, 59, 80, 81, 56]
      }
    ]
  }


  // const dataBar = {
  //   labels: ["January", "February", "March", "April", "May", "June", "July"],
  //   datasets: [
  //     {
  //       label: "My First dataset",
  //       backgroundColor: "#EC932F",
  //       borderColor: "rgba(255,99,132,1)",
  //       borderWidth: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [65, 59, 80, 81, 56, 55, 40]
  //     }
  //   ]
  // };

  // const options = {
  //   plugins: {
  //     datalabels: {
  //       display: true,
  //       color: "black",
  //       align: "end",
  //       anchor: "end",
  //       font: { size: "14" }
  //     }
  //   },
  //   legend: {
  //     display: false
  //   }
  // };


  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
      //   {
      //     label: "Second dataset",
      //     data: [33, 25, 35, 51, 54, 76],
      //     fill: false,
      //     borderColor: "#742774"
      //   }
    ]
  };


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };

  // avatar style
  const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem'
  };

  // action style
  const actionSX = {
    mt: 0.75,
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',
    transform: 'none'
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  //  const data = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'Dataset 1',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //     },
  //     {
  //       label: 'Dataset 2',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //     },
  //   ],
  // };

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [age, setAge] = React.useState('');


  return (
    <React.Fragment><CssBaseline />
      <Box sx={{ overflow: 'hidden', width: '100%', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
      {/* <HumbergerMenu/> */}
      {/* <BreadCrumbMenu/> */}
        <NavBarDashBoardUpdate />
        <NavBarBreadCrumbUpdate />


        <Box sx={{ ml: '7.34vw', mr: '7.34vw', mt: '4vh', mb: '4vh' }} >
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>

            <Grid sx={{ ml: '1.5vw', width: '100%', mt: '4.07vh' }}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography sx={{ font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '1.15vw' }}>Contracts</Typography>
                </Grid>

              </Grid>



              {/* <MainCard sx={{ mt: 2 }} content={false}>
                <List
                  component="nav"
                  sx={{
                    px: 0,
                    py: 0,
                    '& .MuiListItemButton-root': {
                      py: 1.5,
                      '& .MuiAvatar-root': avatarSX,
                      '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                    }
                  }}
                >


                  <ListItemButton>
                    <ListItemAvatar>
                      <Box sx={{ mr: '5.00vw', ml: '4.27vw', mt: '0.27vh', mb: '0vh', width: '12.18vw', height: '22.86vh' }}>
                        <img src={circle} alt = "Loading..." width="100%" heigh="100%" />
                      </Box>

                      <ListItemText primary={
                        <Box sx={{mt: '10.76vh'}}>
                          <Typography sx={{color: '#31312C', fontWeight: 'Regular', font: 'Roboto', fontSize: '3.70vh',  mr: '0vw', ml: '5.00vw', mb: '0vh' }} >Bank Of America</Typography>
                        </Box>

                      }

                      />
                    </ListItemAvatar>
                    <ListItemText />
                    <ListItemSecondaryAction>
                      <Stack sx={{mr: '20.23vw'}}>
                        <ContractTable />
                      </Stack>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </List>
              </MainCard> */}



              <MainCard sx={{ mt: 2 }} content={false}>

                <List
                  component="nav"
                  sx={{
                    px: 0,
                    py: 0,
                    '& .MuiListItemButton-root': {
                      py: 1.5,
                      '& .MuiAvatar-root': avatarSX,
                      '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                    }
                  }}
                >

                  <ListItemButton>
                    <ListItemAvatar>
                      <Box sx={{ mr: '5.00vw', ml: '4.27vw', mt: '5.27vh', mb: '0vh', width: '12.18vw', height: '22.86vh' }}>
                        <img src={ellipse} alt = "Loading..." width="100%" heigh="100%" />
                      </Box>

                      <ListItemText primary={
                        <Box sx={{ mt: '8.76vh' }}>
                          <Typography sx={{ color: '#31312C', fontWeight: 'Regular', font: 'Roboto', fontSize: '3.70vh', mr: '0vw', ml: '5.00vw', mb: '0vh' }} >Bank Of America</Typography>
                        </Box>

                      }

                      />
                    </ListItemAvatar>
                    <ListItemSecondaryAction>
                      <Box sx={{
                        mt: '0.0vh',
                        mb: '0vh',
                        ml: '0vh',
                        mr: '0vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      >
                        <Box component="form" noValidate sx={{ mt: '6.11vh' }}>
                          <Grid container>
                            <Grid sx={{ mr: '13.18vw', ml: '2.48vw' }}>
                              <Typography sx={{ mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Medium', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '0.89vw' }}>
                                DocumentType</Typography>

                            </Grid>
                            <Grid sx={{ mr: '13.18vw', ml: '2.48vw', mt: '3.7vh' }}>
                              <Typography sx={{ mt: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                Letter of Intent</Typography>

                            </Grid>
                            <Grid sx={{ mr: '13.18vw', ml: '2.48vw', mt: '3.7vh' }}>
                              <Typography sx={{ mr: '-1vw', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                Master Agreement
                              </Typography>

                            </Grid>

                            <Grid sx={{ mr: '13.18vw', ml: '2.48vw', mt: '3.7vh' }}>
                              <Typography sx={{ mr: '-2.30vw', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                Administration Benefit
                              </Typography>

                            </Grid>


                            <Grid sx={{ mr: '13.18vw', ml: '2.48vw', mt: '3.7vh' }}>
                              <Typography sx={{ mr: '-5.50vw', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                Mortgage Benefit Agreement
                              </Typography>

                            </Grid>

                            <Grid sx={{ mr: '13.18vw', ml: '2.48vw', mt: '3.7vh' }}>
                              <Typography sx={{ mr: '-8.50vw', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                Amendments to Master Agreement
                              </Typography>

                            </Grid>
                          </Grid>

                        </Box>

                      </Box>

                    </ListItemSecondaryAction>


                    <ListItemSecondaryAction>

                      <Box sx={{
                        mt: '0.0vh',
                        mb: '0vh',
                        ml: '0vh',
                        mr: '0vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      >
                        <Box component="form" noValidate sx={{ mt: '6.11vh' }}>
                          <Grid container>
                            <Grid sx={{ mr: '0vw', ml: '2.48vw' }}>
                              <Typography sx={{ mr: '0vw', ml: '0vw', mb: '0vh', fontWeight: 'Medium', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '0.89vw' }}>
                                Date Completed
                              </Typography>
                            </Grid>

                            <Grid sx={{ mr: '2.18vw', ml: '2.48vw', mt: '3.7vh' }}>
                              <Typography sx={{ mr: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                1 Jan 2023
                              </Typography>
                            </Grid>

                            <Grid sx={{ mr: '2.18vw', ml: '2.48vw', mt: '3.7vh' }}>
                              <Typography sx={{ mr: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                2 Jan 2023
                              </Typography>
                            </Grid>

                            <Grid sx={{ mr: '2.18vw', ml: '2.48vw', mt: '3.7vh' }}>
                              <Typography sx={{ mr: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                3 Jan 2023
                              </Typography>
                            </Grid>


                            <Grid sx={{ mr: '2.18vw', ml: '2.48vw', mt: '3.7vh' }}>
                              <Typography sx={{ mr: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                3 Jan 2023
                              </Typography>

                            </Grid>
                            <Grid sx={{ mr: '13.18vw', ml: '2.48vw', mt: '3.7vh' }}>
                              <Typography sx={{ mr: '-5vw', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                13 Jan 2023
                              </Typography>

                            </Grid>
                          </Grid>

                        </Box>

                      </Box>

                    </ListItemSecondaryAction>





                    <ListItemSecondaryAction>

                      <Box sx={{
                        mt: '0.0vh',
                        mb: '0vh',
                        ml: '0vh',
                        mr: '0vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      >
                        <Box component="form" noValidate sx={{ mt: '6.11vh' }}>
                          <Grid container>
                            <Grid sx={{ mr: '13.18vw', ml: '2.48vw' }}>
                              <Typography sx={{ mr: '0vw', ml: '0vw', mb: '0vh', fontWeight: 'Medium', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '0.89vw' }}>
                                Action
                              </Typography>
                            </Grid>

                            <Grid sx={{ mr: '13.18vw', ml: '3.48vw', mt: '3.2vh' }}>

                              <Box sx={{ mr: '0.00vw', ml: '-1vw', mt: '0vh', mb: '-1vh', width: '0.76vw', height: '0.72vw' }}>
                                <img src={viewfilled} alt = "Loading..." width="100%" heigh="100%" />
                              </Box>
                              <Typography sx={{ mr: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                View
                              </Typography>
                            </Grid>

                            <Grid sx={{ mr: '13.18vw', ml: '3.48vw', mt: '3.2vh' }}>
                              <Box sx={{ mr: '0.00vw', ml: '-1vw', mt: '0vh', mb: '-1vh', width: '0.76vw', height: '0.72vw' }}>
                                <img src={viewfilled} alt = "Loading..." width="100%" heigh="100%" />

                              </Box>
                              <Typography sx={{ mr: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                View
                              </Typography>
                            </Grid>

                            <Grid sx={{ mr: '13.18vw', ml: '3.48vw', mt: '3.2vh' }}>
                              <Box sx={{ mr: '0.00vw', ml: '-1vw', mt: '0vh', mb: '-1vh', width: '0.76vw', height: '0.72vw' }}>
                                <img src={viewfilled} alt = "Loading..." width="100%" heigh="100%" />
                              </Box>
                              <Typography sx={{ mr: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                View
                              </Typography>
                            </Grid>


                            <Grid sx={{ mr: '13.18vw', ml: '3.48vw', mt: '3.2vh' }}>
                              <Box sx={{ mr: '0.00vw', ml: '-1vw', mt: '0vh', mb: '-1vh', width: '0.76vw', height: '0.72vw' }}>
                                <img src={viewfilled} alt = "Loading..." width="100%" heigh="100%" />
                              </Box>
                              <Typography sx={{ mr: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                View
                              </Typography>

                            </Grid>
                            <Grid sx={{ mr: '13.18vw', ml: '3.48vw', mt: '3.2vh' }}>
                              <Box sx={{ mr: '0.00vw', ml: '-1vw', mt: '0vh', mb: '-1vh', width: '0.76vw', height: '0.72vw' }}>
                                <img src={viewfilled} alt = "Loading..." width="100%" heigh="100%" />
                              </Box>
                              <Typography sx={{ mr: '-5vw', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                View
                              </Typography>

                            </Grid>
                          </Grid>

                        </Box>

                      </Box>

                    </ListItemSecondaryAction>




                    <ListItemSecondaryAction>

                      <Box sx={{
                        mt: '0.0vh',
                        mb: '0vh',
                        ml: '0vh',
                        mr: '0vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      >
                        <Box component="form" noValidate sx={{ mt: '6.11vh', mr: '0vw' }}>
                          <Grid container>
                            <Grid sx={{ mr: '0.18vw', ml: '-3vw' }}>
                              <Typography sx={{ mr: '0vw', ml: '0vw', mb: '0vh', fontWeight: 'Medium', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '0.89vw' }}>
                                Download
                              </Typography>
                            </Grid>

                            <Grid sx={{ mr: '13.18vw', ml: '-2vw', mt: '3.2vh' }}>
                              <Box sx={{ mr: '0.00vw', ml: '-1vw', mt: '0vh', mb: '-1vh', width: '0.76vw', height: '0.72vw' }}>
                                <img src={documentPDF} alt = "Loading..." width="100%" heigh="100%" />

                              </Box>
                              <Typography sx={{ mr: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                download
                              </Typography>
                            </Grid>

                            <Grid sx={{ mr: '13.18vw', ml: '-2vw', mt: '3.2vh' }}>
                              <Box sx={{ mr: '0.00vw', ml: '-1vw', mt: '0vh', mb: '-1vh', width: '0.76vw', height: '0.72vw' }}>
                                <img src={documentPDF} alt = "Loading..." width="100%" heigh="100%" />

                              </Box>
                              <Typography sx={{ mr: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                download
                              </Typography>
                            </Grid>

                            <Grid sx={{ mr: '13.18vw', ml: '-2vw', mt: '3.2vh' }}>
                              <Box sx={{ mr: '0.00vw', ml: '-1vw', mt: '0vh', mb: '-1vh', width: '0.76vw', height: '0.72vw' }}>
                                <img src={documentPDF} alt = "Loading..." width="100%" heigh="100%" />

                              </Box>
                              <Typography sx={{ mr: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                download
                              </Typography>
                            </Grid>


                            <Grid sx={{ mr: '13.18vw', ml: '-2vw', mt: '3.2vh' }}>
                              <Box sx={{ mr: '0.00vw', ml: '-1vw', mt: '0vh', mb: '-1vh', width: '0.76vw', height: '0.72vw' }}>
                                <img src={documentPDF} alt = "Loading..." width="100%" heigh="100%" />

                              </Box>
                              <Typography sx={{ mr: '0.0vh', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                download
                              </Typography>

                            </Grid>
                            <Grid sx={{ mr: '13.18vw', ml: '-2vw', mt: '3.2vh' }}>
                              <Box sx={{ mr: '0.00vw', ml: '-1vw', mt: '0vh', mb: '-1vh', width: '0.76vw', height: '0.72vw' }}>
                                <img src={documentPDF} alt = "Loading..." width="100%" heigh="100%" />

                              </Box>
                              <Typography sx={{ mr: '0vw', ml: '0vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: '0.83vw' }}>
                                download
                              </Typography>

                            </Grid>
                          </Grid>

                        </Box>

                      </Box>

                    </ListItemSecondaryAction>



                  </ListItemButton>





                </List>
              </MainCard>





              <MainCard sx={{ mt: 2 }} content={false}>
                <Grid container justifyContent="space-between">
                  <Grid>
                    <Typography sx={{ mt: '3.00vh', mr: '0vw', ml: '1.85vh', mb: '0vh', fontWeight: 'bold', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '1.15vw' }}>Invoice</Typography>
                  </Grid>
                  <Stack direction="row" justifyContent="flex-end">
                    <Typography sx={{ mt: '3.00vh', mr: '0vw', ml: '1.85vh', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: '0.78vw' }}>Transactions</Typography>
                    <Box sx={{ mr: '0.00vw', ml: '1.46vw', mt: '2.40vh', mb: '-1vh', width: '1.25vw', height: '0.77vw' }}>
                      <img src={expandMore} alt = "Loading..." width="100%" heigh="100%" />

                    </Box>

                    <Box sx={{ mr: '0.00vw', ml: '4.44vw', mt: '2.40vh', mb: '0vh', width: '0.76vw', height: '0.72vw' }}>
                      <img src={calender} alt = "Loading..." width="100%" heigh="100%" />

                    </Box>
                    <Typography sx={{ mt: '3.00vh', mr: '2.24vw', ml: '2.20vw', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: '0.78vw' }}>JAN-APR</Typography>

                  </Stack>
                </Grid>
                <InvoiceTable />
              </MainCard>


              <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Grid item xs={12} md={7} lg={8} sx={{ mb: '5vh' }}>
                  <MainCard sx={{ mt: 2 }} content={false}>
                    <Typography sx={{ mt: '3.00vh', mr: '0vw', ml: '1.85vh', mb: '0vh', fontWeight: 'bold', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '1.15vw' }} >
                      Help
                    </Typography>


                    <Box
                      sx={{
                        mt: '11vh',
                        ml: '7.14vw'

                      }}
                    >
                      <Box component="form" noValidate sx={{ mt: 1 }}>
                        <Grid container>
                          <Grid sx={{ mr: '7.80vw', mt: '0vh', ml: '', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '1.17vw' }}>

                            Select Category

                          </Grid>
                          <Grid >


                            <Select
                              value={age}
                              onChange={handleChange}
                              sx={{fontWeight: 'Medium', font: 'Roboto', textTransform:'none',ml:'0vw', fontSize:'0.7vw', color:'#AFAFAF', width:'28.7vw',height:'3.70vh'}}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                            >
                              <MenuItem value="">
                               Select Category
                              </MenuItem>
                              <MenuItem value={10}>Category1</MenuItem>
                              <MenuItem value={20}>Category2</MenuItem>
                              <MenuItem value={30}>Category3</MenuItem>
                            </Select>
                          </Grid>

                        </Grid>




                        <Grid container>
                          <Grid sx={{ mr: '5.52vw', mt: '1.50vw', ml: '', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '1.17vw' }}>

                            Select Sub Category

                          </Grid>
                          <Grid sx={{ mt: '2.99vh' }}>
                            <Select
                              value={age}
                              onChange={handleChange}
                              sx={{fontWeight: 'Medium', font: 'Roboto', textTransform:'none',ml:'0vw', fontSize:'0.7vw', color:'#AFAFAF', width:'28.7vw',height:'3.70vh'}}
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                            >
                              <MenuItem value="" >
                                Select Sub Category
                              </MenuItem>
                              <MenuItem value={10}>SubCategory1</MenuItem>
                              <MenuItem value={20}>SubCategory2</MenuItem>
                              <MenuItem value={30}>SubCategory3</MenuItem>
                              <MenuItem value={40}>SubCategory4</MenuItem>
                            </Select>
                          </Grid>

                        </Grid>


                        <Box
                          sx={{
                            mt: '3.24vh',
                            ml: '15.78vw',
                            mb: '11.11vh'
                          }}
                        >
                          <Button
                            type="submit" 
                            disabled
                            variant="outlined" 
                            sx={{border:'1px solid #D0D0D0',borderRadius:'0.2vw',borderColor:'#D0D0D0',backgroundColor:'#000000', ml: '0.20vw', 
                          mt: '0.3vw', fontSize: '0.5vw', mr: "0", width:'7.81vw', height:'1.88vw' }}>
                            <Typography sx={{textTransform:'none', mt: '00vh', mr: '0vw', ml: '0', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#FFFFFF', opacity: '1', fontSize: '0.70vw' }}>
                          Send
                          </Typography>
                          </Button>
                        </Box>
                      </Box>
                    </Box>


                  </MainCard>
                </Grid>

                <Grid item xs={12} md={5} lg={4}>
                 
                  <MainCard sx={{ mt: 2 }} content={false}>
                  <Typography sx={{ mt: '3.00vh', mr: '0vw', ml: '1.85vh', mb: '0vh', fontWeight: 'bold', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '1.15vw' }} >
                      Unable to find something? Contact us here
                    </Typography>
                    {/* <Stack
                spacing={0.75}
                sx={{
                  mt: -1.5
                }}
              >
              </Stack> */}


                    <Box
                      sx={{
                        mt: '11vh',
                       
                      }}
                    >
                      <Box component="form" noValidate sx={{ mt: 1 }}>
                        <Grid container>
                          <Grid sx={{ mr: '1vw', mt: '1.10vh' }}>
                             <Typography sx={{ mt: '0', mr: '0vw', ml: '1.85vh', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '1.15vw' }} >
                              E-mail
                            </Typography>

                          </Grid>
                          <Grid >
                            <TextField
                              placeholder="Insert question/request/other here"
                              multiline
                              rows={3}
                              
                              sx={{ width: '19vw',height: '0', border:'1px solid #D0D0D0',borderRadius:'0.2vw',borderColor:'#D0D0D0' }}
                              maxRows={4}
                            />
                          </Grid>

                        </Grid>
                        <Box
                          sx={{
                            mt: '3.24vh',
                            ml: '5vw',
                            mb: '11.11vh'
                          }}
                        >
                          
                        <Button
                          type="submit"
                          disabled
                          variant="outlined" 
                          sx={{border:'1px solid #D0D0D0',borderRadius:'0.2vw',borderColor:'#D0D0D0',backgroundColor:'#000000', ml: '0.20vw', 
                          mt: '0.3vw', fontSize: '0.5vw', mr: "0", width:'7.81vw', height:'1.88vw' }}>
                         <Typography sx={{ textTransform:'none' , mt: '00vh', mr: '0vw', ml: '0', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#FFFFFF', opacity: '1', fontSize: '0.70vw' }}>
                          Send
                          </Typography>
                        </Button>
                        </Box>

                      </Box>
                    </Box>
                  </MainCard>
                </Grid>
              </Grid>



            </Grid>


          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
}

function InputKey() {

  const name = 'password'
  const type = 'password'
  const [cur, setCur] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);

  function onClickFun(e) {
    const val1 = e.currentTarget.value
    val1 === "N3$tfu3L" ? allowed = 'yes' : allowed = 'no'


  };

  return (
    <div position='fixed' align='center'>
      <Typography sx={{ fontSize: '1vw', width: '12vw' }}>Please answer the following to activae DISCOVER NESTFUEL (Hint:Army man of the Raj earns working days by a week 7-6-3)</Typography>
      <Typography sx={{ mt: 2, fontSize: '1vw', width: '12vw' }}></Typography>
      <TextField sx={{ fontsize: '0.5vw', mt: 1, width: '12vw', border: 1, borderColor: 'black', borderRadius: 2 }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label
        autoFocus={true}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </div>
  )
}

export default EmployerContract;