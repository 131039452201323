
import { UPDATE_A,UPDATE_B,UPDATE_C,UPDATE_D,ADDETO_D, UPDATE_E,UPDATE_F,SELPROP,PROPROP, MODIFY_D,
  UPDATE_H,UPDATE_I1,UPDATE_PBPR,UPDATE_J,UPDATE_Profile,UPDATE_fProfile, UPDATE_LT, UPDATE_IO, 
  UPDATE_RIM, UPDATE_PP,ADJUST, INIT, REVIEW, REFIAP, REFIMAX, REFIINIT, DIMENSIONS, UPDATE_CP, 
  START_LOADING, END_LOADING, _debug, REGCOMP, UPDATE_MTG, UPDATE_ProfileS, UPDATE_DSF, UPDATE_DSFE, 
  UPDATE_DSFB, employerList, UPDATE_DSFEM, SIGNUP, SIGNIN, UPDATE_LocalStorage} from '../constants';
import * as api from '../api/index.js';
import { initializeApp,getApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { borrowerProfile} from "./borrower";


// const firebaseConfig = {
//   apiKey        : process.env.REACT_APP_GOOGLE_AUTH_API_KEY,
//   authDomain    : process.env.REACT_APP_GOOGLE_AUTH_DOMAIN,
//   projectId     : process.env.REACT_APP_GOOGLE_AUTH_PROJECTID,
//   storageBucket : process.env.REACT_APP_GOOGLE_AUTH_BUCKET,
//   messagingSenderId: "425163920527",
//   appId: "1:425163920527:web:ec8c4329bd64fbe48a4cfa",
//   measurementId: "G-BYL3ENMVRK"
// };

const firebaseConfig = _debug === 'development' 
    ?{
      apiKey            : process.env.REACT_APP_GOOGLE_AUTH_API_KEY_UAT,
      authDomain        : process.env.REACT_APP_GOOGLE_AUTH_DOMAIN_UAT,
      projectId         : process.env.REACT_APP_GOOGLE_AUTH_PROJECTID_UAT,
      storageBucket     : process.env.REACT_APP_GOOGLE_AUTH_BUCKET_UAT,
      messagingSenderId : process.env.REACT_APP_GOOGLE_AUTH_MSG_SNDR_ID_UAT,
      appId             : process.env.REACT_APP_GOOGLE_AUTH_APP_ID_UAT,
      measurementId     : process.env.REACT_APP_GOOGLE_AUTH_MSRMT_ID_UAT
    }
    :{
        apiKey            : process.env.REACT_APP_GOOGLE_AUTH_API_KEY,
        authDomain        : process.env.REACT_APP_GOOGLE_AUTH_DOMAIN,
        projectId         : process.env.REACT_APP_GOOGLE_AUTH_PROJECTID,
        storageBucket     : process.env.REACT_APP_GOOGLE_AUTH_BUCKET,
        messagingSenderId : process.env.REACT_APP_GOOGLE_AUTH_MSG_SNDR_ID,
        appId             : process.env.REACT_APP_GOOGLE_AUTH_APP_ID,
        measurementId     : process.env.REACT_APP_GOOGLE_AUTH_MSRMT_ID
    }  

let app
try{app = getApp()}catch(error){ app = initializeApp(firebaseConfig);}
const auth = getAuth(app);

export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const nameA = user.displayName.split(" ")
    const length  = nameA.length
    const data = {  
      userId    : user.uid,
      firstName : length > 2 ? nameA[0].concat(" ",nameA[1]): nameA[0],
      lastName  : length > 2 ? nameA[2]: nameA[1],
      token     : user.accessToken,
      authProvider: "google",
      emailId   : user.email
    } 
    console.log("Data SignIn",data)
    if(_debug==='development')console.log("user Credentials SignUp",user);
    dispatch({ type: SIGNIN, data: data });
  } catch (err) {console.error(err);alert(err.message);}
};


export const signUp = (form)=> async (dispatch) => {
  var user = {}
  var data ={}
  if(_debug==='development')console.log("form passed SignUp",form);
  // try {
  //   const userCredential = await createUserWithEmailAndPassword(auth, form.emailId, form.password);
  //   user = userCredential.user;
  //   console.log("DB name, collection name",app, dbFirebase,collectionName)
  //   console.log("Collection reference",collection(dbFirebase, collectionName))
    const dbRef =  doc(dbFirebase,collectionName)
    const docA = {
      emailId   : "dd@eemail.com",
      firstName : "Shyam Rangeela",
      lastName  : "Gunashyam",
    }
    // setDoc(docRef, {
    //     uid       : user.uid,
    //     userId    : user.uid,
    //     token     : user.accessToken,
    //     firstName : form.firstName,
    //     lastName  : form.lastName,
    //     EmailId   : form.emailId,
    //     emailId   : form.emailId,
    //     password  : form.password,
    //     address   : form.address,
    //     phone     : form.phone,
    // //     created: Timestamp.now()
    // });
    setDoc(dbRef,docA).then(docRef=>{console.log("Document has been added successfully",docRef)}).catch(error=>{console.log(error)})
    // console.log("Document written with ID: ", docRef.id);
    // dispatch({ type: SIGNUP, data: data })


    // createUserWithEmailAndPassword(auth, form.emailId, form.password)
    // .then((userCredential) => {
    //   user = userCredential.user
    //   data = {  
    //     uid       : user.uid,
    //     userId    : user.uid,
    //     token     : user.accessToken,
    //     firstName : form.firstName,
    //     lastName  : form.lastName,
    //     EmailId   : form.emailId,
    //     emailId   : form.emailId,
    //     password  : 'notThatFool',
    //     address   : '20 Gal Street, Fonsecka, CA',
    //     phone     : '670-123-4567',
    //     created: Timestamp.now()
    //   }
      
    //   dispatch({ type: SIGNUP, data: data })
    //   if(_debug==='development') console.log("user Credentials SignUp",user);
    //   //sessionStorage.setItem('Auth Token', userCredential._tokenResponse.refreshToken)
    //   addDoc(collection(dbFirebase, collectionName), data)

    // })
    // .catch ((error)=> {
    //   console.log("Signup Error",error.code,error.message)
    // });
      
  // } catch (error) {
  //   console.log("Registration Error",error.code,error.message);
  // }
  //try {
  //console.log("Data Signup",data)
  //let response = await api.userRegister({data:data});
  //response = response["data"];
  //if(_debug === 'development') console.log("Registration Succeeded",response);
  //}catch(error) {
  //console.log("Registration Error",error.code,error.message);
  //}  
}

export const signIn = (form)=> async (dispatch) => {
    var user = {}
    var docId = 0;
    var docData = 0;
    if(_debug==='development')console.log("form passed signIn",form);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, form.emailId, form.password);
      user = userCredential.user;
      if(_debug==='development')console.log("user Credentials SignUp",user);
      const q = await query(collection(dbFirebase, collectionName), 
        where('userId','==', user.uid),
        limit(1)
      )
        await onSnapshot(q, (querySnapshot) => {
          querySnapshot.docs.map(doc => {
            docId = doc.id;
            docData = doc.data();
          })
          
          const data = {  
            userId    : user.uid,
            token     : user.accessToken,
            emailId   : user.email,
            docId     : docId,

            firstName : docData.firstName,
            lastName  : docData.lastName,
            address   : docData.address,
            phone     : docData.phone,
          }

          console.log("Data SignIn",data)
          dispatch({ type: SIGNIN, data: data });
          dispatch(borrowerProfile(docData));
          dispatch({ type: UPDATE_LocalStorage, data: docData });
          
        })
      console.log('end');
    } catch (error) {
      console.log("SignIn Error",error.code,error.message)
    }
    
    // signInWithEmailAndPassword(auth, form.emailId, form.password).then((userCredential) => {
    //   user = userCredential.user
    //   const data = {  
    //     userId    : user.uid,
    //     token     : user.accessToken,
    //     emailId   : user.email
    //   } 
    //   console.log("Data SignIn",data)
    //   if(_debug==='development')console.log("user Credentials SignUp",user);
    //   dispatch({ type: SIGNIN, data: data });
    // })
    // .catch ((error)=> { 
    //   console.log("SignIn Error",error.code,error.message)
    // });
}

export const currentUser = () =>{
  const auth = getAuth();
  const user = auth.currentUser;

if (user) {
  
} else {
}

}

export const getUserDetails = (uid)=> async (dispatch) => {
  if(_debug==='development')console.log("uid",uid); 

  try {   
    const q = query(collection(dbFirebase, collectionName), 
      where('userId','==', uid),
      limit(1)
    )
    let retObj = [];
    let docId = 0;
    let docData = 0;
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.docs.map(doc => {
        retObj['id'] = doc.id;
        retObj['data'] = doc.data();
      })
    })
    // onSnapshot(q, (querySnapshot) => {
    //   retObj.push(
    //     querySnapshot.docs.map(doc => ({
    //       id: doc.id,
    //       data: doc.data()
    //     })
    //     )
    //   )
    // })
    console.log(retObj);
    return retObj;

  } catch (error) {
    console.log("getUserDetails Error",error.code,error.message);
  }
  
};

/*
await setDoc(doc(dbFirebase, "cities", "LA"), {
  name: "Los Angeles",
  state: "CA",
  country: "USA"
});
*/
export const updateUserInfo = (form)=> async (dispatch) => {
  var user = {}
  var data ={}
  if(_debug==='development')console.log("form passed updateUserInfo",form);
  data = form;
  delete data['token'];
  delete data['userId'];
  delete data['propertiesViewed'];
  delete data['password'];
  //delete data['docId'];
  if(_debug==='development')console.log("filter data",data);
  var currentUser = JSON.parse(localStorage.getItem('profile'));
  console.log('currentUser',currentUser)
  
  try {
    const taskDocRef = doc(dbFirebase, collectionName, currentUser?.docId);
    dispatch({ type: UPDATE_LocalStorage, data: data });
    await updateDoc(taskDocRef, data);
    
  } catch (error) {
    console.log("updateUserInfo Error",error.code,error.message);
  }
}