import React from "react";
import {
  Box,
  Stack,
  CssBaseline,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from "./ButtonIcons1";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import empleft1 from "../../assets/svg/assets svg/image-leftbottom03.png";
import empright1 from "../../assets/staticPageAssets/employer-right-1-withouttext.gif";
import employer2A from "../../assets/staticPageAssets/employer2-a.png";
import employer2B from "../../assets/staticPageAssets/employer2-b.png";
import employer2C from "../../assets/staticPageAssets/employer2-c.png";
import empright3 from "../../assets/staticPageAssets/employer-right-3.gif";
import employerPartnerHexa1 from "../../assets/staticPageAssets/hexa-1.png";
import employerPartnerHexa2 from "../../assets/staticPageAssets/hexa-2.png";
import employerPartnerHexa4 from "../../assets/staticPageAssets/hexa-4.png";
import bullet from "../../assets/staticPageAssets/Polygon.svg";
import layerOne from "../../assets/svg/assets svg/layer1.svg";
import layerTwo from "../../assets/svg/assets svg/layer2.svg";
import layerThree from "../../assets/svg/assets svg/layer3.svg";
import circleSvg from "../../assets/svg/assets svg/circle.svg";
import circleSvgDark from "../../assets/svg/assets svg/circle_Dark.svg";
// import "../../style/animations.styles.scss";

import useStyles from "./styles";
import { border, borderRadius } from "@mui/system";
import { BottomBox, LeftbottomA } from "./RightBlock";

import { _debug } from "../../constants";

var allowed = "no";

function EmployerPartnership() {
  const navigate = useNavigate();

  const [seek, setSeek] = React.useState(allowed);
  const classes = useStyles();
  // if(_debug === 'development') allowed = 'yes'
  allowed = "yes";
  const [slideState, setSlideState] = React.useState({
    heading: ["Employees ", "at the work place"],
    leftContent: ["29% ", "84%", "90%"],
    rightContent: [
      "of millennials are engaged ",
      "of those happy with high benefit report higher job satisfaction",
      "of millennials value benefits over pay",
    ],
    image: employer2A,
    active: [true, false, false],
    footer: [
      "Most employees lack engagement at their place of work. Increasing satisfaction motivates your people and improves your company output. An effective and tangible",
      "benefit is desired more than simple pay and will boost your productivity and value",
    ],
  });
  const [stateUpdate, setStateUpdate] = React.useState(0);
  const [check, setCheck] = React.useState(false);
  const [visibilityFirst, setVisibilityFirst] = React.useState(true);
  const [visibilitySecond, setVisibilitySecond] = React.useState(true);
  const [visibilityThird, setVisibilityThird] = React.useState(true);

  const handleSlide1 = () => {
    const headingItems = ["Employees ", "at the work place"];
    const leftContentItems = ["29% ", "84%", "90%"];
    const rightContentItems = [
      "of Millennials are engaged ",
      "of those happy with high benefit report higher job satisfaction",
      "of Millennials value benefits over pay",
    ];
    const footerItems = [
      "Most employees lack engagement at their place of work. Simply increasing satisfaction motivates your people and improves your company output. An effective and tangible",
      "benefit is desired more than simple pay and will boost your productivity and value",
    ];
    setSlideState({
      heading: headingItems,
      leftContent: leftContentItems,
      rightContent: rightContentItems,
      image: employer2A,
      active: [true, false, false],
      footer: footerItems,
    });
  };
  const handleSlide2 = () => {
    const headingItems = ["Today's ", "main HR challenges"];
    const leftContentItems = ["65% ", "86%"];
    const rightContentItems = [
      "of employers struggle to engage with employees",
      "of employers struggle to attract & retain talent",
    ];
    const footerItems = [
      "Employees hold most of the cards when it comes to where to work.",
      "Employers need to offer more - what other benefit offers keys and a connection to a shared vision",
    ];
    setSlideState({
      heading: headingItems,
      leftContent: leftContentItems,
      rightContent: rightContentItems,
      image: employer2B,
      active: [false, true, false],
      footer: footerItems,
    });
  };
  const handleSlide3 = () => {
    const headingItems = ["Employees ", "care about finance"];
    const leftContentItems = ["13% ", "72%", "87%"];
    const rightContentItems = [
      "of employees have basic financial literacy",
      "of employees are stressed about finances",
      "of employees want financial education",
    ];
    const footerItems = [
      "Employees are increasingly concerned about financial wellbeing. Moreover most demonstrate lack of financial literacy, and are looking for help.",
      "Nestfuel employer partnership is built on helping employees by adding financial wellbeing to employer toolkit",
    ];
    setSlideState({
      heading: headingItems,
      leftContent: leftContentItems,
      rightContent: rightContentItems,
      image: employer2C,
      active: [false, false, true],
      footer: footerItems,
    });
  };

  const handleOnHover = (index) => {
    if (index === 1) setVisibilityFirst(false);
    if (index === 2) setVisibilitySecond(false);
    if (index === 3) setVisibilityThird(false);
    console.log("hello");
  };

  const handleHoverOut = (index) => {
    if (index === 1) setVisibilityFirst(true);
    if (index === 2) setVisibilitySecond(true);
    if (index === 3) setVisibilityThird(true);
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        sx={{
          overflow: "hidden",
          width: "100%",
          background:
            "transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box",
        }}
      >
        <Navbar />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LeftBlockA />
          <Box sx={{ width: "13.6vw", justifyContent: "center" }}>
            <Stack
              sx={{
                mr: "2.0vw",
                ml: "3.0vw",
                mt: "19vw",
                height: "10vw",
                width: "10vw",
              }}
              direction="row"
            >
              <Button
                sx={{
                  overflow: "hidden",
                  mt: 0,
                  zIndex: 1,
                  border: 0,
                  width: "8.0vw",
                  position: "fixed",
                  height: "10vw",
                  minHeight: 0,
                  minWidth: 0,
                  padding: "0.2vw",
                }}
                id="Discover"
                className="hexBtnCenter"
                onClick={() =>
                  allowed === "yes"
                    ? navigate("/LetsTalk", { replace: false })
                    : setSeek("ddd")
                }
              >
                <HexButton
                  titleText="LET's TALK"
                  type="large"
                  fontSize="24"
                  fontWeight="bold"
                />
              </Button>
            </Stack>
            {seek == "ddd" ? <InputKey /> : <></>}
          </Box>
          <RightBlockA />
        </Box>
        <Box
          id={"OurMission"}
          sx={{
            mt: ".1vw",
            width: "100%",
            height: "50vw",
            background: "inherit",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ width: "36vw" }}>
            <Typography
              sx={{
                ml: "7.2vw",
                mt: "8vw",
                color: "#31312C",
                fontWeight: 300,
                font: "Roboto",
                textAlign: "left",
              }}
              fontSize="5vw"
              lineHeight={1.2}
              letterSpacing="-1.6px"
            >
              Our Mission
            </Typography>
            <Typography
              sx={{
                mt: "2vw",
                ml: "7.2vw",
                color: "#8f8f8f",
                font: "Roboto",
                fontWeight: "light",
                textAlign: "left",
              }}
              fontSize="2.4vw"
              lineHeight={1.2}
            >
              Supporting financial
            </Typography>
            <Typography
              sx={{
                mt: "2vw",
                ml: "7.2vw",
                color: "#8f8f8f",
                font: "Roboto",
                fontWeight: "light",
                textAlign: "left",
              }}
              fontSize="2.4vw"
              lineHeight={1.2}
            >
              well-being through
            </Typography>
            <Typography
              sx={{
                mt: "2vw",
                ml: "7.2vw",
                color: "#8f8f8f",
                font: "Roboto",
                fontWeight: "light",
                textAlign: "left",
              }}
              fontSize="2.4vw"
              lineHeight={1.2}
            >
              employee benefits
            </Typography>
            <Box sx={{ display: "flex", marginTop: "9.07vh" }}>
              <Box
                sx={{ width: "1.15vw", height: "2.31vh", marginLeft: "8.28vw" }}
              >
                <img src={bullet} height="100%"></img>
              </Box>

              <Box sx={{ width: "21.46vw", height: "3.43vh" }}>
                <Typography
                  sx={{
                    mt: "0vh",
                    ml: "0.50vw",
                    color: "#31312C",
                    font: "Roboto",
                    textAlign: "left",
                  }}
                  fontSize="1.4vw"
                  lineHeight={1.2}
                >
                  Provide greater access to finance
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: "9.07vh" }}>
              <Box
                sx={{ width: "1.15vw", height: "2.31vh", marginLeft: "8.28vw" }}
              >
                <img src={bullet} height="100%"></img>
              </Box>

              <Box sx={{ width: "18vw", height: "0.28vh", marginTop: "0vh" }}>
                <Typography
                  sx={{
                    mt: "0vh",
                    ml: ".5vw",
                    color: "#31312C",
                    font: "Roboto",
                    textAlign: "left",
                  }}
                  fontSize="1.4vw"
                  lineHeight={1.2}
                >
                  Support financial education
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: "9.07vh" }}>
              <Box
                sx={{ width: "1.15vw", height: "2.31vh", marginLeft: "8.28vw" }}
              >
                <img src={bullet} height="100%"></img>
              </Box>
              <Box sx={{ width: "21.46vw", marginTop: "0vh" }}>
                <Typography
                  sx={{
                    mt: "0vh",
                    ml: ".5vw",
                    color: "#31312C",
                    font: "Roboto",
                    textAlign: "left",
                  }}
                  fontSize="1.4vw"
                  lineHeight={1.2}
                >
                  Help sustain & develop local communities
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              ml: "14vw",
              width: "49vw",
              height: "50vw",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box sx={{ width: "28.28vw", height: "50vw" }}>
                <Box
                  sx={{
                    marginLeft: "5vw",
                    marginTop: "9.5vw",
                    width: "19vw",
                    height: "6.5vw",
                  }}
                >
                  <Typography
                    sx={{
                      mt: "0.5vh",
                      ml: ".5vw",
                      color: "#31312c",
                      font: "Roboto",
                      textAlign: "left",
                    }}
                    fontSize="2.0vw"
                    lineHeight={1.2}
                    fontWeight="medium"
                  >
                    {slideState.heading[0] ? slideState.heading[0] : ""}
                  </Typography>
                  <Typography
                    sx={{
                      mt: "0.5vh",
                      ml: ".5vw",
                      color: "#31312c",
                      font: "Roboto",
                      textAlign: "left",
                    }}
                    fontSize="2.0vw"
                    lineHeight={1.2}
                    fontWeight="medium"
                  >
                    {slideState.heading[1] ? slideState.heading[1] : ""}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    marginLeft: "5vw",
                    marginTop: "2.07vw",
                    width: "22.6vw",
                    height: "5.5vw",
                    display: "flex",
                  }}
                >
                  <Box sx={{ width: "7.40vw", height: "5.52vw" }}>
                    <Typography
                      sx={{
                        mt: "0.5vh",
                        ml: ".5vw",
                        color: "#123347",
                        font: "Roboto",
                        textAlign: "left",
                      }}
                      fontSize="4.17vw"
                      lineHeight={1.2}
                    >
                      {slideState.leftContent[0]
                        ? slideState.leftContent[0]
                        : ""}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "1.46vw",
                      marginTop: "0.56vw",
                      width: "12.55vw",
                      height: "3.65vw",
                      display: "flex",
                    }}
                  >
                    <Typography
                      sx={{
                        mt: "1.7vh",
                        ml: ".5vw",
                        color: "#123347",
                        font: "Roboto",
                        textAlign: "left",
                      }}
                      fontSize="1.04vw"
                      lineHeight={1.2}
                    >
                      {slideState.rightContent[0]
                        ? slideState.rightContent[0]
                        : ""}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginLeft: "5vw",
                    marginTop: "1.46vw",
                    width: "22.6vw",
                    height: "5.5vw",
                    display: "flex",
                  }}
                >
                  <Box sx={{ width: "7.40vw", height: "5.52vw" }}>
                    <Typography
                      sx={{
                        mt: "0.5vh",
                        ml: ".5vw",
                        color: "#123347",
                        font: "Roboto",
                        textAlign: "left",
                      }}
                      fontSize="4.17vw"
                      lineHeight={1.2}
                    >
                      {slideState.leftContent[1]
                        ? slideState.leftContent[1]
                        : ""}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "1.46vw",
                      marginTop: "0.56vw",
                      width: "12.55vw",
                      height: "3.65vw",
                      display: "flex",
                    }}
                  >
                    <Typography
                      sx={{
                        mt: "0.5vh",
                        ml: ".5vw",
                        color: "#123347",
                        font: "Roboto",
                        textAlign: "left",
                      }}
                      fontSize="1.04vw"
                      lineHeight={1.2}
                    >
                      {slideState.rightContent[1]
                        ? slideState.rightContent[1]
                        : ""}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    marginLeft: "5vw",
                    marginTop: "1.46vw",
                    width: "22.6vw",
                    height: "5.5vw",
                    display: "flex",
                  }}
                >
                  <Box sx={{ width: "7.40vw", height: "5.52vw" }}>
                    <Typography
                      sx={{
                        mt: "0.5vh",
                        ml: ".5vw",
                        color: "#123347",
                        font: "Roboto",
                        textAlign: "left",
                      }}
                      fontSize="4.17vw"
                      lineHeight={1.2}
                    >
                      {slideState.leftContent[2]
                        ? slideState.leftContent[2]
                        : ""}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "1.46vw",
                      marginTop: "0.56vw",
                      width: "12.55vw",
                      height: "3.65vw",
                      display: "flex",
                    }}
                  >
                    <Typography
                      sx={{
                        mt: "1.7vh",
                        ml: ".5vw",
                        color: "#123347",
                        font: "Roboto",
                        textAlign: "left",
                      }}
                      fontSize="1.04vw"
                      lineHeight={1.2}
                    >
                      {slideState.rightContent[2]
                        ? slideState.rightContent[2]
                        : ""}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginLeft: "5vw",
                    marginTop: "2.1vw",
                    width: "22.6vw",
                    height: "5.5vw",
                  }}
                >
                  <Typography
                    sx={{
                      mt: "0.5vh",
                      ml: ".5vw",
                      color: "#585858",
                      font: "Roboto",
                      textAlign: "left",
                    }}
                    fontSize="0.83vw"
                    lineHeight={1.2}
                  >
                    {slideState.footer[0] ? slideState.footer[0] : ""}
                  </Typography>
                  <Typography
                    sx={{
                      mt: "0.5vh",
                      ml: ".5vw",
                      color: "#585858",
                      font: "Roboto",
                      textAlign: "left",
                    }}
                    fontSize="0.83vw"
                    lineHeight={1.2}
                  >
                    {slideState.footer[1] ? slideState.footer[1] : ""}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginLeft: "16.6vw",
                    marginTop: "1.67vw",
                    width: "4.2vw",
                    height: "1.18vw",
                    postion: "absolute",
                    bottom: "0vw",
                  }}
                >
                  <Box
                    sx={{
                      width: ".9vw",
                      height: "0.9vw",
                      borderRadius: "50%",
                      display: "inline-block",
                      border: "1px solid #707070",
                      backgroundColor:
                        slideState.active[0] == true ? "#707070" : "#fff",
                    }}
                    onMouseEnter={handleSlide1}
                  ></Box>
                  <Box
                    sx={{
                      width: ".9vw",
                      height: "0.9vw",
                      borderRadius: "50%",
                      display: "inline-block",
                      border: "1px solid #707070",
                      backgroundColor:
                        slideState.active[1] == true ? "#707070" : "#fff",
                    }}
                    onMouseEnter={handleSlide2}
                  ></Box>
                  <Box
                    sx={{
                      width: ".9vw",
                      height: "0.9vw",
                      borderRadius: "50%",
                      display: "inline-block",
                      border: "1px solid #707070",
                      backgroundColor:
                        slideState.active[2] == true ? "#707070" : "#fff",
                    }}
                    onMouseEnter={handleSlide3}
                  ></Box>
                </Box>
              </Box>
              <Box
                sx={{ marginLeft: "1.7vw", width: "21.25vw", height: "50vw" }}
              >
                <img
                  src={slideState.image}
                  alt = "Loading..."
                  width="100%"
                  height="100%"
                />
              </Box>
            </Box>
            <Box
              sx={{ marginLeft: "3.6vw", height: "43.5vw", width: "37.5vw" }}
            ></Box>
          </Box>
          {/* </Box> */}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LeftBlockB />
          <Box
            sx={{ width: "14vw", background: "inherit", height: "52vw" }}
          ></Box>
          <RightBlockB />
        </Box>
        <Box id={"WhyDoes"} sx={{ width: "100%", height: "50vw" }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: "36vw" }}>
              <Typography
                sx={{
                  mt: "4.6vw",
                  ml: "7.2vw",
                  color: "#31312C",
                  fontWeight: 300,
                  font: "Roboto",
                  textAlign: "left",
                }}
                fontSize="5vw"
                lineHeight={1.2}
                letterSpacing="-1.6px"
              >
                What does{" "}
              </Typography>
              <Typography
                sx={{
                  ml: "7.2vw",
                  color: "#31312C",
                  fontWeight: 300,
                  font: "Roboto",
                  textAlign: "left",
                }}
                fontSize="5vw"
                lineHeight={1.2}
                letterSpacing="-1.6px"
              >
                it mean ?{" "}
              </Typography>
              <Typography
                sx={{
                  mt: "3vw",
                  ml: "7.2vw",
                  color: "#8f8f8f",
                  fontWeight: "300",
                  fontSize: "2.5vw",
                  font: "Roboto",
                }}
                align="left"
                lineHeight={1.2}
                letterSpacing="-1.6px"
              >
                Nestfuel rewrites{" "}
              </Typography>
              <Typography
                sx={{
                  mt: "1vw",
                  ml: "7.2vw",
                  color: "#8f8f8f",
                  fontWeight: "300",
                  fontSize: "2.5vw",
                  font: "Roboto",
                }}
                align="left"
                lineHeight={1.2}
                letterSpacing="-1.6px"
              >
                the pact between
              </Typography>
              <Typography
                sx={{
                  mt: "1vw",
                  ml: "7.2vw",
                  color: "#8f8f8f",
                  fontWeight: "300",
                  fontSize: "2.5vw",
                  font: "Roboto",
                }}
                align="left"
                lineHeight={1.2}
                letterSpacing="-1.6px"
              >
                Employer & Employees{" "}
              </Typography>
            </Box>
            <Box sx={{ width: "14vw", background: "inherit", height: "52vw" }}>
              {/* add here----> */}
            </Box>
            <Box sx={{ ml: "0vw", width: "50vw" }}>
              {/* <img src={empright4} alt = "Loading..." width="100%" height="100%" /> */}

              <Box sx={{ marginLeft: "4.22vw", marginTop: "1.15vw" }}>
                <Box
                  class="main-hover"
                  onMouseEnter={() => {
                    handleOnHover(1);
                  }}
                  onMouseLeave={() => {
                    handleHoverOut(1);
                  }}
                >
                  <Box class="inner_both">
                    <Box class="inner-one">
                      <span class="circle-img"></span>
                      <Box sx={{marginLeft:'1vw'}}>

                     
                      <Typography
                        sx={{ color: "#FFFFFF", fontSize: "1.15vw" }}
                        className="innerText"
                      >
                        - Stronger connection with Employer
                        <br /> <br />- Increased Purchasing Power for your
                        future home <br /> <br />- Personalize experience &
                        tailored financing
                      </Typography>
                      </Box>
                    </Box>
                    <Box class="inner-two">
                      {visibilityFirst ? (
                        <Box
                          class="circle-img"
                          onMouseEnter={() => {
                            setVisibilityFirst(visibilityFirst);
                          }}
                          onMouseOut={() => {
                            setVisibilityFirst(visibilityFirst);
                          }}
                        >
                          <Box
                            sx={{
                              marginTop: "2vw",
                              width: "4.59vw",
                              height: "5.59vw",
                            }}
                          >
                            <img src={layerOne} height="100%"></img>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          class="circle-img"
                          onMouseEnter={() => {
                            setVisibilityFirst(visibilityFirst);
                          }}
                          onMouseOut={() => {
                            setVisibilityFirst(visibilityFirst);
                          }}
                        >
                          <Box
                            sx={{
                              marginTop: "-1.5vw",
                              marginLeft: "2.5vw",
                              width: "4.59vw",
                              height: "5.59vw",
                            }}
                          >
                            <img src={layerOne} height="100%"></img>
                          </Box>
                        </Box>
                      )}

                      <Box
                        className="innerText"
                        sx={{ marginLeft: "2vw", width: "35vw" }}
                      >
                        <Typography
                          sx={{
                            color: "#8F8F8F",
                            fontSize: "2.50vw",
                            fontWeight: "medium",
                            textAlign: "right",
                          }}
                        >
                          Outcomes for Employee
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{ marginLeft: "4.22vw", marginTop: "1.15vw" }}
                onMouseEnter={() => {
                  handleOnHover(2);
                }}
                onMouseLeave={() => {
                  handleHoverOut(2);
                }}
              >
                <Box class="main-hover-right right-hover">
                  <Box class="inner_both">
                    <Box class="inner-one">
                      <span class="circle-img"></span>
                      <Box sx={{ marginLeft: "4vw" }}>
                        <Typography
                          sx={{
                            marginLeft: "2vw",
                            color: "#FFFFFF",
                            fontSize: "1.15vw",
                            fontWeight: "normal",
                            fontFamily: "Roboto",
                          }}
                          className="innerText"
                        >
                          - Increased employee satisfaction
                          <br /> <br />- Increased employee engagement 
                          <br/><br/>- Boost Performance
                        </Typography>
                      </Box>
                    </Box>
                    <Box class="inner-two">
                      {visibilitySecond ? (
                        <Box class="circle-img">
                          <Box
                            sx={{
                              marginTop: "0vw",
                              marginLeft: "-0.3vw",
                              height: "3.96vw",
                              width: "4vw",
                            }}
                          >
                            <img src={layerTwo} height="100%"></img>
                          </Box>
                        </Box>
                      ) : (
                        <Box class="circle-img">
                          <Box
                            sx={{
                              marginTop: "3vw",
                              marginLeft: "3vw",
                              height: "3.96vw",
                              width: "4vw",
                            }}
                          >
                            <img src={layerTwo} height="100%"></img>
                          </Box>
                        </Box>
                      )}
                      <Box
                        className="innerText"
                        sx={{
                          marginLeft: "-2vw",
                          width: "35vw",
                          height: "6.56vw",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#8F8F8F",
                            fontSize: "2.50vw",
                            fontWeight: "medium",
                            textAlign: "center",
                            fontFamily: "Roboto",
                          }}
                        >
                          Outcomes for Employer
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {visibilityThird ? (
                <Box
                  sx={{
                    marginLeft: "4.22vw",
                    marginTop: "1.51vw",
                    width: "41.88vw",
                    height: "13.91VW",
                    borderRadius: "2.08vw",
                    boxShadow: "0px 0.31vw 0.78vw #00000024",
                    position: "relative",
                  }}
                  onMouseEnter={() => {
                    handleOnHover(3);
                  }}
                  onMouseLeave={() => {
                    handleHoverOut(3);
                  }}
                >
                  <Box sx={{ dispaly: "flex", position: "relative" }}>
                    <Box
                      sx={{
                        marginLeft: "4.53vw",
                        marginTop: "2.71vw",
                        width: "6.41vw",
                        height: "6.35vw",
                        position: "absolute",
                      }}
                    >
                      <img src={circleSvg} height="100%"></img>
                      <Box
                        sx={{
                          marginLeft: "1.5vw",
                          marginTop: "-4.5vw",
                          width: "5.10vw",
                          height: "5.51vw",
                          position: "absolute",
                        }}
                      >
                        <img src={layerThree} height="100%"></img>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        marginLeft: "17.92vw",
                        marginTop: "3.28vw",
                        width: "18.88vw",
                        height: "6.56vw",
                        position: "absolute",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#8F8F8F",
                          fontSize: "2.50vw",
                          fontWeight: "medium",
                          textAlign: "center",
                          fontFamily: "Roboto",
                        }}
                        lineHeight="3.28vw"
                        letterSpacing="-0.12px"
                      >
                        Outcomes for Community
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    marginLeft: "4.22vw",
                    marginTop: "1.51vw",
                    width: "41.88vw",
                    height: "13.91VW",
                    borderRadius: "2.08vw",
                    background: "#31312C",
                    boxShadow: "0px 0.31vw 0.78vw #00000024",
                    position: "relative",
                  }}
                  onMouseEnter={() => {
                    handleOnHover(3);
                  }}
                  onMouseLeave={() => {
                    handleHoverOut(3);
                  }}
                >
                  <Box sx={{ dispaly: "flex", position: "relative" }}>
                    <Box
                      sx={{
                        marginLeft: "6.53vw",
                        marginTop: "4.71vw",
                        width: "6.41vw",
                        height: "6.35vw",
                        position: "absolute",
                      }}
                    >
                      <img src={circleSvgDark} height="100%"></img>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "4vw",
                        marginTop: "3.5vw",
                        width: "5.10vw",
                        height: "5.51vw",
                        position: "absolute",
                      }}
                    >
                      <img src={layerThree} height="100%"></img>
                    </Box>

                    <Box
                      sx={{
                        marginLeft: "20vw",
                        marginTop: "4vw",
                        width: "18.88vw",
                        height: "6.56vw",
                        position: "absolute",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#FFFFFF",
                          fontSize: "1.15vw",
                          fontWeight: "normal",
                          textAlign: "left",
                          fontFamily: "Roboto",
                        }}
                        lineHeight="0vw"
                        letterSpacing="0px"
                      >
                        {" "}
                        - Better Connection
                      </Typography>
                      <Typography
                        sx={{
                          mt: "3vw",
                          color: "#FFFFFF",
                          fontSize: "1.15vw",
                          fontWeight: "normal",
                          textAlign: "left",
                          fontFamily: "Roboto",
                        }}
                        lineHeight="0vw"
                        letterSpacing="0px"
                      >
                        - Greater Support
                      </Typography>
                      <Typography
                        sx={{
                          mt: "3vw",
                          color: "#FFFFFF",
                          fontSize: "1.15vw",
                          fontWeight: "normal",
                          textAlign: "left",
                          fontFamily: "Roboto",
                        }}
                        lineHeight="0vw"
                        letterSpacing="0px"
                      >
                        {" "}
                        - Stronger Communities
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LeftBlockC />
          <Box
            sx={{ width: "14vw", background: "inherit", height: "52vw" }}
          ></Box>
          <RightBlockC />
        </Box>
        <Box>
          <BottomBox />
        </Box>
      </Box>
    </React.Fragment>
  );
}

function RightBlockA() {
  return (
    <Box
      sx={{
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        boxShadow: "-1px -6px 46px #0000003D",
        p: 0,
        opacity: 1,
        width: "50vw",
        height: "46.75vw",
        borderRadius: "0.5vw",
        position: "relative",
        zIndex: "0",
      }}
    >
      <img
        src={empright1}
        alt = "Loading..."
        height="100%"
        width="100%"
        borderRadius="0.5vw"
        style={{ position: "relative", zIndex: "0" }}
      />
      <Box
        sx={{
          marginLeft: "7.60vw",
          marginTop: "-33.1vw",
          width: "37vw",
          height: "12.19vw",
          zIndex: "1",
          position: "absolute",
        }}
      >
        <Typography
          sx={{
            mt: "0vw",
            ml: "0vw",
            color: "#8f8f8f",
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="4.2vw"
          lineHeight={1.2}
          fontWeight="bold"
          position="absolute"
          zIndex="1"
        >
          The right partner for your workforce{" "}
        </Typography>
      </Box>
    </Box>
  );
}
function RightBlockB() {
  return (
    <Box
      sx={{
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        boxShadow: "-1px -6px 46px #0000003D",
        opacity: 1,
        width: "50vw",
        height: "50vw",
      }}
    >
      <img src={empright3} alt = "Loading..." width="100%" height="100%" />
    </Box>
  );
}

function RightBlockC() {
  const [hexaText1, setHexaText1] = React.useState("Tailored Home Financing");
  const [hexaText2, setHexaText2] = React.useState("Communication & Education");
  const [hexaText3, setHexaText3] = React.useState("All-in-one platform");

  const [hexaHoverText1, setHexaHoverText1] = React.useState("");
  const [hexaHoverText2, setHexaHoverText2] = React.useState("");
  const [hexaHoverText3, setHexaHoverText3] = React.useState("");

  const handleHexa1 = () => {
    setHexaText1("Tailored Home Financing");
    setHexaHoverText1("");
  };
  const handleHexa2 = () => {
    setHexaText2("Communication & Education");
    setHexaHoverText2("");
  };

  const handleHexa3 = () => {
    setHexaText3("All-in-one platform");
    setHexaHoverText3("");
  };

  const handleHoverHexa1 = () => {
    setHexaText1("");
    setHexaHoverText1("Nestfuel creates up to 20% advance amount");
  };
  const handleHoverHexa2 = () => {
    setHexaText2("");
    setHexaHoverText2(
      "Assist employees with their financial health & well-being"
    );
  };

  const handleHoverHexa3 = () => {
    setHexaText3("");
    setHexaHoverText3(
      "Simple, integrated platform built and managed by Nestfuel"
    );
  };
  return (
    <Box
      sx={{
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        boxShadow: "-1px -6px 46px #0000003D",
        opacity: 1,
        width: "50vw",
        height: "52vw",
      }}
    >
      <Box sx={{ ml: "0vw", width: "50vw" }}>
        {/* <img src={empright4} alt = "Loading..." width="100%" heigh="100%" background='inherit'/> */}
        <Box
          sx={{
            marginLeft: "16.78vw",
            marginTop: "1.07vw",
            width: "16.88vw",
            height: "18.60vw",
            postion: "relative",
            zIndex: "0",
          }}
        >
          <img
            src={employerPartnerHexa1}
            alt = "Loading..."
            width="100%"
            height="100%"
          />

          {hexaText1 !== "" ? (
            <Typography
              sx={{
                mt: "-11.2vw",
                ml: "0vw",
                color: "#e3e3e3",
                width: "16.2vw",
                font: "Roboto",
                textAlign: "center",
              }}
              fontSize="1.7vw"
              lineHeight={1.2}
              fontWeight="bold"
              position="absolute"
              zIndex="1"
              onMouseEnter={handleHoverHexa1}
              onMouseOut={handleHexa1}
            >
              {hexaText1}
            </Typography>
          ) : (
            <Typography
              sx={{
                mt: "-11.5vw",
                ml: "2.5vw",
                color: "#e3e3e3",
                width: "11.5vw",
                font: "Roboto",
                textAlign: "center",
              }}
              fontSize="1.37vw"
              lineHeight={1.2}
              fontWeight="bold"
              position="absolute"
              zIndex="1"
              onMouseEnter={handleHoverHexa1}
              onMouseOut={handleHexa1}
            >
              {hexaHoverText1}
            </Typography>
          )}

          {/* <img src={ourPartnerHexa1} alt = "Loading..." width="100%" heigh="100%"/> */}
        </Box>
        <Box sx={{ display: "flex", marginTop: "-5vw" }}>
          <Box
            sx={{
              marginLeft: "9.2vw",
              marginTop: "-1.2vw",
              width: "16.88vw",
              height: "18.60vw",
              position: "relative",
              zIndex: "0",
            }}
          >
            <img
              src={employerPartnerHexa2}
              alt = "Loading..."
              width="100%"
              heigh="100%"
              style={{ position: "relative" }}
            />

            {hexaText2 !== "" ? (
              <Typography
                sx={{
                  mt: "-18.0vw",
                  ml: "2.5vw",
                  color: "#e3e3e3",
                  marginTop: "-12vw",
                  width: "10.2vw",
                  font: "Roboto",
                  textAlign: "center",
                }}
                fontSize="1.7vw"
                lineHeight={1.2}
                fontWeight="bold"
                position="absolute"
                zIndex="1"
                onMouseEnter={handleHoverHexa2}
                onMouseOut={handleHexa2}
              >
                {hexaText2}{" "}
              </Typography>
            ) : (
              <Typography
                sx={{
                  mt: "-18.0vw",
                  ml: "2.5vw",
                  color: "#e3e3e3",
                  marginTop: "-12vw",
                  width: "12.2vw",
                  font: "Roboto",
                  textAlign: "center",
                }}
                fontSize="1.3vw"
                lineHeight={1.2}
                fontWeight="bold"
                position="absolute"
                zIndex="1"
                onMouseEnter={handleHoverHexa2}
                onMouseOut={handleHexa2}
              >
                {hexaHoverText2}{" "}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              marginLeft: "-1.7vw",
              marginTop: "-1.2vw",
              width: "16.88vw",
              height: "18.60vw",
              position: "relative",
              zIndex: "0",
            }}
          >
            <img
              src={employerPartnerHexa4}
              alt = "Loading..."
              width="100%"
              heigh="100%"
              style={{ position: "relative" }}
            />
            {hexaText3 !== "" ? (
              <Typography
                sx={{
                  mt: "-11.7vw",
                  ml: "3vw",
                  color: "#e3e3e3",
                  width: "10.2vw",
                  height: "2.9vw",
                  zIndex: "1",
                  position: "absolute",
                  font: "Roboto",
                  textAlign: "center",
                }}
                fontSize="1.7vw"
                lineHeight={1.2}
                fontWeight="bold"
                position="absolute"
                zIndex="1"
                onMouseEnter={handleHoverHexa3}
                onMouseOut={handleHexa3}
              >
                {hexaText3}{" "}
              </Typography>
            ) : (
              <Typography
                sx={{
                  mt: "-13.4vw",
                  ml: "3vw",
                  color: "#e3e3e3",
                  width: "10.2vw",
                  height: "2.9vw",
                  zIndex: "1",
                  position: "absolute",
                  font: "Roboto",
                  textAlign: "center",
                }}
                fontSize="1.3vw"
                lineHeight={1.2}
                fontWeight="bold"
                position="absolute"
                zIndex="1"
                onMouseEnter={handleHoverHexa3}
                onMouseOut={handleHexa3}
              >
                {hexaHoverText3}{" "}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function LeftBlockA() {
  return (
    <Box
      sx={{
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        marginLeft: "0.4vw",
        boxShadow: "1px 6px 46px #0000003D",
        opacity: 1,
        width: "35.12vw",
        height: "46.75vw",
      }}
    >
      <Box
        id="EmployerPartnership"
        sx={{
          marginLeft: "7.76vw",
          marginTop: "7.76vw",
          width: "15.89vw",
          height: "12.60vw",
          fontsize: "4.64vw",
          font: "Roboto",
        }}
      >
        <Typography
          sx={{
            color: "#8f8f8f",
            fontWeight: "bold",
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="4.6vw"
          lineHeight={1.2}
          letterSpacing="-1.6px"
        >
          Focus on your Employees
        </Typography>
      </Box>
      <Box sx={{ width: "35.15vw", height: "12.4vw", marginTop: "19.1vw" }}>
        <img src={empleft1} alt = "Loading..." width="100%" height="100%" />
      </Box>
    </Box>
  );
}

function LeftBlockB() {
  return (
    <Box
      id="Meaning"
      sx={{
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        boxShadow: "1px 6px 46px #0000003D",
        opacity: 1,
        width: "36vw",
        height: "50vw",
      }}
    >
      {/* <img src={empleft3} alt = "Loading..." width='100%' heigh="100%" background='inherit'/>
       */}
      <Box sx={{ width: "36vw" }}>
        <Typography
          sx={{
            ml: "7.2vw",
            mt: "8vw",
            color: "#31312C",
            fontWeight: 300,
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="5vw"
          lineHeight={1.2}
          letterSpacing="-1.6px"
        >
          Why does it matter?
        </Typography>

        <Box sx={{ display: "flex", marginTop: "9.07vh" }}>
          <Box sx={{ width: "1.15vw", height: "2.31vh", marginLeft: "8.28vw" }}>
            <img src={bullet} height="100%"></img>
          </Box>

          <Box sx={{ width: "21.46vw", height: "3.43vh" }}>
            <Typography
              sx={{
                mt: "0vh",
                ml: "0.50vw",
                color: "#31312C",
                font: "Roboto",
                textAlign: "left",
              }}
              fontSize="1.5vw"
              lineHeight={1.2}
            >
              $50k is the annual cost of employee advocacy and goodwill – don't
              waste it
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", marginTop: "9.07vh" }}>
          <Box sx={{ width: "1.15vw", height: "2.31vh", marginLeft: "8.28vw" }}>
            <img src={bullet} height="100%"></img>
          </Box>

          <Box sx={{ width: "18vw", height: "0.28vh", marginTop: "0vh" }}>
            <Typography
              sx={{
                mt: "0vh",
                ml: ".5vw",
                color: "#31312C",
                font: "Roboto",
                textAlign: "left",
              }}
              fontSize="1.5vw"
              lineHeight={1.2}
            >
              Employees want benefits that meet their goals and aspirations
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", marginTop: "9.07vh" }}>
          <Box sx={{ width: "1.15vw", height: "2.31vh", marginLeft: "8.28vw" }}>
            <img src={bullet} height="100%"></img>
          </Box>

          <Box sx={{ width: "21.46vw", marginTop: "0vh" }}>
            <Typography
              sx={{
                mt: "0vh",
                ml: ".5vw",
                color: "#31312C",
                font: "Roboto",
                textAlign: "left",
              }}
              fontSize="1.5vw"
              lineHeight={1.2}
            >
              Employees want benefits that are personal, real, and substantial
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: "1.07vh" }}>
          <Box sx={{ width: "1.15vw", height: "2.31vh", marginLeft: "8.28vw" }}>
            <img src={bullet} height="100%"></img>
          </Box>
          <Box sx={{ width: "21.46vw", marginTop: "0vh" }}>
            <Typography
              sx={{
                mt: "0vh",
                ml: ".5vw",
                color: "#31312C",
                font: "Roboto",
                textAlign: "left",
              }}
              fontSize="1.5vw"
              lineHeight={1.2}
            >
              Benefits are  critical tools to attract, motivate and retain
              talent
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function LeftBlockC() {
  return (
    <Box
      id="Services"
      sx={{
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        boxShadow: "1px 6px 46px #0000003D",
        opacity: 1,
        width: "36vw",
        height: "52vw",
      }}
    >
      <Box sx={{ width: "36vw" }}>
        <Typography
          sx={{
            mt: "4.6vw",
            ml: "7.2vw",
            color: "#31312C",
            fontWeight: 300,
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="5vw"
          lineHeight={1.2}
          letterSpacing="-1.6px"
        >
          Our Services
        </Typography>

        <Typography
          sx={{
            mt: "3vw",
            ml: "7.2vw",
            color: "#8f8f8f",
            fontWeight: "300",
            fontSize: "2.5vw",
            font: "Roboto",
          }}
          align="left"
          lineHeight={1.2}
          letterSpacing="-1.6px"
        >
          Comprehensive{" "}
        </Typography>
        <Typography
          sx={{
            mt: "1vw",
            ml: "7.2vw",
            color: "#8f8f8f",
            fontWeight: "300",
            fontSize: "2.5vw",
            font: "Roboto",
          }}
          align="left"
          lineHeight={1.2}
          letterSpacing="-1.6px"
        >
          solutions for
        </Typography>
        <Typography
          sx={{
            mt: "1vw",
            ml: "7.2vw",
            color: "#8f8f8f",
            fontWeight: "300",
            fontSize: "2.5vw",
            font: "Roboto",
          }}
          align="left"
          lineHeight={1.2}
          letterSpacing="-1.6px"
        >
          Employer & Employees{" "}
        </Typography>
      </Box>
    </Box>
  );
}

function InputKey() {
  const name = "password";
  const type = "password";
  const [cur, setCur] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);

  function onClickFun(e) {
    const val1 = e.currentTarget.value;
    val1 === "N3$tfu3L" ? (allowed = "yes") : (allowed = "no");
  }

  return (
    <div position="fixed" align="center">
      <Typography sx={{ fontSize: "1vw", width: "12vw" }}>
        (Please answer the following to activae DISCOVER NESTFUEL)
      </Typography>
      <Typography sx={{ mt: 2, fontSize: "1vw", width: "12vw" }}></Typography>
      <TextField
        sx={{
          fontsize: "0.5vw",
          mt: 1,
          width: "12vw",
          border: 1,
          borderColor: "black",
          borderRadius: 2,
        }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label="No flying ends Indian penny"
        autoFocus={true}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default EmployerPartnership;
