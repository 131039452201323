import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import { Typography, Stack }  from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

 
export default function ImageCard(props) {

  const {imageUrl,price, address} = props ;

  return (
    <Card sx={{ maxWidth: 600, borderRadius:4 }}>
      <CardMedia
        component="img"
        height="294"
        image={imageUrl}
        alt="Image didn't load"
      />
      <CardContent>
      <Stack spacing={1} direction="row" justifyContent="space-between">
          <Typography variant='h5'>${price}</Typography>
          <Stack spacing={-15} direction="row" justifyContent="space-around">
            <LocationOnIcon/>
            <Typography variant='h5' width='60%'>{address}</Typography>
          </Stack>
      </Stack>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="share">
        </IconButton>
      </CardActions>
    </Card>
  );
}
