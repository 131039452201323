import PropTypes from 'prop-types';

// material-ui
import { Box, Chip, Grid, Stack, Typography, Divider, IconButton, Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
//import person from '../../assets/svg/person.svg'
// project import
import MainCard from './MainCard';

// assets
//import { RiseOutlined, FallOutlined } from '@ant-design/icons';
import OutlinedInput from '@mui/material/OutlinedInput';
import { width } from '@mui/system';

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const AnalyticTeamMember = ({ color, title, count, image, percentage, isLoss, extra }) => (
    <MainCard contentSX={{ elevation: 10, border: 0, width: '13.68vw', height: '23vh' }} >
        <Stack spacing={0.5}  >
            <Typography sx={{ fontWeight: 'Regular', fontSize: '2.04vh', textTransform: 'none', font: 'Roboto', color: '#31312C', ml: '0.1vh' }}>
                {title}
            </Typography>
            <Divider sx={{
                border
                    : '0.5px solid #DEDEDE', opacity: 1, ml: '0.1vh'
            }} />

        </Stack>
        <Stack spacing={0} direction="row" sx={{ mt: 1.25, mb: -1 }}>
            <Box sx={{ mt: '3.25vh', mr: '11vw' }}>

                <Typography sx={{
                   width:'5vw', mt: '7.00vh', fontWeight: 'Bold', fontSize: '1.5vh', font: 'Roboto', color: '#31312C', ml: '0.1vw',
                }}>
                    {count}
                </Typography>
            </Box>
            <Box


                sx={{ mt: '9.00vh', ml: '0vw', width: '2.10vw', height: '3.52vh' }}
            >
                <img src={image} height='100%'></img>
            </Box>
        </Stack>

    </MainCard>
);

AnalyticTeamMember.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    count: PropTypes.string,
    image: PropTypes.string,
    percentage: PropTypes.number,
    isLoss: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

AnalyticTeamMember.defaultProps = {
    color: 'primary'
};

export default AnalyticTeamMember;
