import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  HashRouter,
  Router
} from 'react-router-dom';
import AdminInterfaceRate from './components/Pages/AdminInterfaceRates';
import Home from './components/Pages/Home';
import EmployerPartnership from './components/Pages/EmployerPartnership';

import AdjustOptions from './components/Pages/AdjustOptions.js';
import AnnualIncome from './components/Pages/AnnualIncome.js';
import Affordability from './components/Pages/Affordability.js';
import AffordabilityChF from './components/Pages/AffordabilityChF';

import BorrowerProfile from './components/Pages/BorrowerProfile.js';
import BorrowerProfileEmp from './components/Pages/BorrowerProfileEmp';
import BorrowerProfileInd from './components/Pages/BorrowerProfileInd';
import Bankruptcy from './components/Pages/Bankruptcy.js';
import BiggerAndBetter from './components/Pages/BiggerBetter.js';

import CompareFinance from './components/Pages/CompareFinance.js';
import ChangeBudget from './components/Pages/ChangeBudget.js';

import DashboardMain from './components/Pages/DashboardMain';
import DashboardProfile from './components/Pages/DashboardProfile.js';
import DashboardProperty from './components/Pages/DashboardProperty.js';
import Dashboard from './components/Pages/Dashboard.js';
import DashboardCreditProfile from './components/Pages/DashboardCreditProfile.js';
import DashboardEconomicProfile from './components/Pages/DashboardEconomicProfile.js';
import DashboardPropertyPreferences from './components/Pages/DashboardPropertyPreferences.js';
import DashboardPropertyPreferencesFilter from './components/Pages/DashboardPropertyPreferencesFilter.js';

import DashboardBenefits from './components/Pages/DashboardBenefits.js';
import Discover from './components/Pages/Discover';
import DashboardFinance from './components/Pages/DashboardFinance.js';
import DesignFinance from './components/Pages/DesignFinance.js';
import DesignFinanceBB from './components/Pages/DesignFinanceBB.js';

import Employment from './components/Pages/Employment.js';
import EmployerSupport from './components/Pages/EmployerSupport';
import EmployerSupportDt from './components/Pages/EmployerSupportDt';
import EmployerBenefitP from './components/Pages/EmployerBenefitP';
import EmployerBenefit  from './components/Pages/EmployerBenefit';
import EmployerBenefitR from './components/Pages/EmployerBenefitR';
import EmployerSupportDtReg from './components/Pages/EmployerSupportDtReg';

import EmployerSupportDtExtend from './components/Pages/EmployerSupportDtExtend';

import FicoScore from './components/Pages/FicoScore.js';
import GovtLoan from './components/Pages/GovtLoan.js';


import MonthlyOutgoing from './components/Pages/MonthlyOutgoing.js';

import NestFuel from './components/Pages/WhyNestfuel';
import NestfuelMessenger from './components/Pages/Messenger';

import LoanDocs from './components/Pages/LoanDocs.js';
import Ownership from './components/Pages/OwnershipType.js';

import PropertyType from './components/Pages/PropertyType';
import Properties from './components/Pages/Properties.js';

import ResidencyStatus from './components/Pages/ResidencyStatus.js';

import SmartChoiceSelfEmp from './components/Pages/SmartChoiceSelfEmp';
import ShowPropertyRefiExp from './components/Pages/ShowPropertyRefiExp';
import SearchProperty from './components/Pages/SearchProperty.js';
import ShowProperty from './components/Pages/ShowProperty.js';
import ShowPropertyEmb from './components/Pages/ShowPropertyEmb';
import ShowPurchaseOpt from './components/Pages/ShowPurchaseOpt';
import ShowPurchaseRate from './components/Pages/ShowPurchaseOptRate';
import ShowPurchaseSelfEmp from './components/Pages/ShowPurchaseSelfEmp';
import ShowPropertyRefi from './components/Pages/ShowPropertyRefi.js';
import ShowImages from './components/Pages/ShowImages.js';
import ShowPropertyImages from './components/Pages/ShowPropertyImages'
import SummaryApplication from './components/Pages/SummaryApplication.js';
import SignUp from './components/Pages/userAuth';

import WhyUs from './components/Pages/WhyUs';
import AboutUs from './components/Pages/AboutUs';
import SelectFinancing from './components/Pages/SelectFinancing';
import SmartChoice from './components/Pages/SmartChoice';
import StartApplication from './components/Pages/StartApplication';
import { Disclosures} from './components/Pages/Disclosures';
import { PrivacyPoliocy} from './components/Pages/Policies';
import { TermsAndConditions } from './components/Pages/Terms';

import DashboardProfileUpdate from './components/Pages/DashboardProfileUpdate.js';
import DashboardPersonalProfile from './components/Pages/DashboardPersonalProfile.js';
import { UpdateOfferPrice } from './components/Pages/updateOfferPrice';
import LetsTalk from './components/Pages/LetsTalk';
import EmployerPartnershipWhyUs from './components/Pages/EmployerPatnershipWhyUs';
import LegalTerms from './components/Pages/LegalTerms';
import LegalPrivacy from './components/Pages/LegalPrivacy';
import LegalDisclosure from './components/Pages/LegalDisclosure';
import EmployerDashboard from './components/Pages/EmployerDashboard';
import EmployerTeam from './components/Pages/EmployerTeam';
import EmployerCompanyMaterial from './components/Pages/EmployerCompanyMaterial';
import EmployerContract from './components/Pages/EmployerContract';
import EmployerTeamMember from './components/Pages/EmployerTeamMember';
import EmployerCompanyCal from './components/Pages/EmployerCompanyCal';
import EmployeeMortgageBenefit from './components/Pages/EmployeeMortgageBenefit';
import EmployeeMortgageSupportDtReg from './components/Pages/EmployeeMortageSupportDtReg';
import EmployeeMoprtgageShowPurchaseOpt from './components/Pages/EmployeeMortgageSupportShowProperty';
import EmployerSupportDtRegEMB from './components/Pages/EmployerSupportDtRegEMB';
import SmartChoiceEmb from './components/Pages/SmartChoiceEmb';
import PersonalizeRate from './components/Pages/PersonalizeRate';
import { ShowPurchaseBorrower } from './components/Pages/ShowPropertyBorrower';
import StartApplicationDefault from './components/Pages/StartApplicatioDefault';
// import SaelunHome from './components/Pages/SaelunHome';
// import SaelunHomeShowProperty from './components/Pages/SaelunHomeShowProperty';
// import SaelunEmployerSupportDtReg from './components/Pages/SaelunEmployerSupportDtReg';
// import SaelunHome from './components/Pages/SaelunHome';
// import SaelunHomeShowProperty from './components/Pages/SaelunHomeShowProperty';
const AppTwo = () => {

  return (
    <HashRouter>
      <div>
        <Routes>
          <Route path='/AdminInterfaceRates' element={<AdminInterfaceRate/>}/>
          <Route path='/Auth' element={<SignUp />} />
          <Route path='/' element={<Navigate replace to="/Home" />} />
          <Route path='/Home' element={<Discover />} />
          <Route path='/Discover' element={<Discover />} />
          
          <Route path='/EmployerPartnership' element={<EmployerPartnership />} />
          <Route path='/AdjustOptions' element={<AdjustOptions />} />
          <Route path='/Affordability' element={<Affordability />} />
          <Route path='/AffordabilityChF' element={<AffordabilityChF />} />
          <Route path='/AnnualIncome' element={<AnnualIncome />} />
          <Route path="/Bankruptcy" element={<Bankruptcy />} />
          <Route path='/BiggerAndBetter' element={<BiggerAndBetter />} />
          <Route path='/BorrowerProfile' element={<BorrowerProfile />} />
          <Route path='/BorrowerProfileInd' element={<BorrowerProfileInd />} />
          <Route path='/BorrowerProfileEmp' element={<BorrowerProfileEmp />} />
          <Route path='/ChangeBudget' element={<ChangeBudget />} />
          <Route path='/CompareFinance' element={<CompareFinance />} />
          <Route path='/Dashboard' element={<Dashboard />} />
          <Route path='/DashboardFinance' element={<DashboardFinance />} />
          <Route path='/DashboardMain' element={<DashboardMain />} />
          <Route path='/DashboardProfile' element={<DashboardProfile />} />
          <Route path='/DashboardProperty' element={<DashboardProperty />} />
          <Route path='/DashboardPropertyPreferences' element={<DashboardPropertyPreferences />} />          
          <Route path='/DashboardPropertyPreferencesFilter' element={<DashboardPropertyPreferencesFilter />} />          
          <Route path='/DesignFinanceBB' element={<DesignFinanceBB />} />
          <Route path='/DesignFinance' element={<DesignFinance />} />
          <Route path='/Discover' element={<Discover />} />
          <Route path='/Disclosures' element={<Disclosures/>} />
          <Route path="/Employment" element={<Employment />} />
          <Route path="/EmployerBenefit" element={<EmployerBenefit />} />
          <Route path="/EmployerBenefitP" element={<EmployerBenefitP />} />
          <Route path="/EmployerBenefitR" element={<EmployerBenefitR />} />
          <Route path="/EmployerSupport" element={<EmployerSupport />} />
          <Route path="/EmployerSupportDt" element={<EmployerSupportDt />} />
          <Route path="/EmployerSupportDtExtend" element={<EmployerSupportDtExtend />} />
          <Route path="/EmployerSupportDtReg" element={<EmployerSupportDtReg />} />
          <Route path="/EmployerSupportDtRegEMB"element={<EmployerSupportDtRegEMB/>}/>
          <Route path='/FicoScore' element={<FicoScore />} />
          <Route path='/GovtLoan' element={<GovtLoan />} />
          <Route path='/ReactHome' element={<Home />} />
          <Route path='/LoanDocs' element={<LoanDocs />} />
          <Route path='/MonthlyOutgoing' element={<MonthlyOutgoing />} />
          <Route path='/NestFuel' element={<NestFuel />} />
          <Route path='/NestfuelMessenger' element={<NestfuelMessenger />} />
          <Route path='/Ownership' element={<Ownership />} />
          <Route path='/Properties' element={<Properties />} />
          {/* <Route path='/PrivacyPolicy' element={<PrivacyPoliocy />} /> */}
          <Route path='/PrivacyPolicy' element={<LegalPrivacy/>} />
          <Route path='/PropertyType' element={<PropertyType />} />
          <Route path='/PersonalizeRate' element={<PersonalizeRate/>}/>
          {/* <Route path='/Home' element={<PersonalizeRate/>}/> */}
          <Route path='/ResidencyStatus' element={<ResidencyStatus />} />
          <Route path='/SearchProperty' element={<SearchProperty />} />
          <Route path='/SearchProperty' element={<SearchProperty />} />
          <Route path='/SelectFinancing' element={<SelectFinancing />} />
          <Route path='/ShowProperty' element={<ShowProperty/>} />
          <Route path='/ShowPropertyEmb' element={<ShowPropertyEmb />} />
          <Route path='/ShowPropertyBorrower' element={<ShowPurchaseBorrower />} />

          {/* <Route path='/ShowProperty' element={<SaelunHomeShowProperty/>} /> */}

          {/* <Route path='/ShowProperty' element={<SaelunHome/>}/> */}
          <Route path='/ShowPurchaseOpt' element={<ShowPurchaseOpt />} />
          <Route path='/ShowPurchaseRate' element={<ShowPurchaseRate />} />
          <Route path='/ShowPurchaseSelfEmp' element={<ShowPurchaseSelfEmp />} />
          <Route path='/ShowPropertyRefi' element={<ShowPropertyRefi />} />
          <Route path='/ShowPropertyRefiExp' element={<ShowPropertyRefiExp />} />
          <Route path='/ShowImages' element={<ShowImages />} />
          <Route path='/ShowPropertyImages' element={<ShowPropertyImages />} />
          <Route path='/SmartChoice' element={<SmartChoice />} />
          <Route path ='SmartChoiceEmb' element={<SmartChoiceEmb/>}/>
          <Route path='/SmartChoiceSelfEmp' element={<SmartChoiceSelfEmp />} />
          <Route path='/StartApplication' element={<StartApplication />} />
          <Route path='/StartApplicationDefault' element ={<StartApplicationDefault/>} />
          <Route path='/SummaryApplication' element={<SummaryApplication />} />
          {/* <Route path='/TermsAndConditions' element={<TermsAndConditions />} /> */}
          <Route path='/TermsAndConditions' element={<LegalTerms/>} />
          <Route path='/DisclosureAndLicensing' element={<LegalDisclosure/>} />
          <Route path='/DashboardProfile' element={<DashboardProfile />} />
          <Route path='/DashboardBenefits' element={<DashboardBenefits />} />
          <Route path='/UpdateOfferPrice' element={<UpdateOfferPrice />} />
          <Route path='/WhyUs' element={<WhyUs />} />
          <Route path='/AboutUs' element={<AboutUs />} />
          <Route path='/LetsTalk' element={<LetsTalk/>} />
          <Route path='/DashboardProfileUpdate' element={<DashboardProfileUpdate />} />
          <Route path='/DashboardPersonalProfile' element={<DashboardPersonalProfile />} />
          <Route path='/EmployerPartnerShipWhyUs' element={<EmployerPartnershipWhyUs />} />
          <Route path='/DashboardCreditProfile' element={<DashboardCreditProfile />} />
          <Route path='/DashboardEconomicProfile' element={<DashboardEconomicProfile />} />

          <Route path='/EmployerDashboard' element={<EmployerDashboard />} />
          <Route path='/EmployerTeam' element={<EmployerTeam />} />
          <Route path='/EmployerCompanyMaterial' element={<EmployerCompanyMaterial />} />
          <Route path='/EmployerContract' element={<EmployerContract />} />
          <Route path='/EmployerTeamMember' element={<EmployerTeamMember />} />
          <Route path='/EmployerCompanyCal' element={<EmployerCompanyCal />} />

          <Route path='/EmployeeMortgageBenefit/' element={<EmployeeMortgageBenefit/>}/>
          <Route path ='/EmployeeMortageSupportDtReg' element={<EmployeeMortgageSupportDtReg/>}/>
          <Route path ='/EmployeeMoprtgageShowProperty/' element={<EmployeeMoprtgageShowPurchaseOpt/>}/>
          {/* <Route exact path='/post/:postId' element={<Post />} /> */}


        </Routes>
      </div>
    </HashRouter>
  );
};

export default AppTwo;