import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { ThemeProvider } from '@mui/styles';
import { createTheme} from '@mui/material/styles';
import thunk from 'redux-thunk';
import { reducers } from './reducers';
import App from './App';
//import App from './components/AnimaPackage-React-mWn2t/App'
//import Hexgrid from './components/Pages/Hexgrid';
//import Home from './components/Pages/Home'
import './index.css';
//require('dotenv').config()

const store = createStore(reducers, {}, compose(applyMiddleware(thunk)));
const theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

// ReactDOM.render(<App />, document.getElementById("app"));

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
      {/* <Hexgrid /> */}
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
