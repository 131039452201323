import React from "react";
import { Box, Stack, Input, CircularProgress, CssBaseline, Typography, Button} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import paymentImageSvg from '../../assets/svg/assets svg/Group 8313.svg';
import { useDispatch, useSelector } from 'react-redux';
import { borrowerChoiceE, borrowerChoiceF } from "../../actions/borrower";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import leftbottom from '../../assets/images/bg-10@1x.png'
import ItemsCarousel from "react-items-carousel";
import { Centerbottom, LeftbottomA, RightBlockEB, RightBlockSPBP } from "./RightBlock";
import { withCommas } from "../../constants";
import { LeftBlockMsg } from "./Messenger";

import leftArrow from '../../assets/svg/left-arrow.svg'
import rightArrow from '../../assets/svg/right-arrow.svg'

const DebugOutput = true;

function ChangeBudget() {
      const { bfinancialProfile,
              isLoading
            } = useSelector((state)=>state.borrower);
      const dispatch = useDispatch();

      const navigate = useNavigate();
      const location = useLocation();
      return(
        isLoading ? <CircularProgress /> : (
          <React.Fragment>
              <CssBaseline/>
              <Box sx={{ width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
                <Navbar pathname={location.pathname}/>
                <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
                { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
                
                  <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center', position:'relative'}}>
                  <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> 
                  ...</Typography>
                  <div align='center'>
            <Stack sx={{mt:'4vh',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
              <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}}id="Confirm" className='hexBtnCenter' onClick={()=>navigate(-1)}> 
                <HexButton titleText="Confirm" type='base' fontSize='24'/></Button>
            </Stack>
           <Centerbottom/>
        </div>
      </Box>
      <RightBlockSPBP/>
      </Box>
    </Box>
    </React.Fragment>
    ));
}

function RightBlock() {
  const { bChoiceD,
          bChoiceH,
          bChoiceF,
          selectedProp}= useSelector((state)=>state.borrower);
  const [upfront,setUpfront] = React.useState(bChoiceF.upfront)
  const [monthly,setMonthly] = React.useState(bChoiceF.monthly);
  const [intR,setIntR] = React.useState(bChoiceH.interestRate)
  
  return (
    <React.Fragment>
      <CssBaseline/>
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '50vw',height:'46.75vw' }}>
          <Box sx={{mr:0, pt:'1vw',background:'#31312C 0% 0% no-repeat padding-box', width: "99%",height:'18vw' ,border:1, borderRadius:2}}>
            <InnerCarousel imageArray={selectedProp.prop.images} numCards={1.7}/>
          </Box>
          <Box sx={{mt:1, p:1, background: "#31312C 0% 0% no-repeat padding-box", width: "99%",height:"60vh",border:1 ,borderRadius:2, borderColor:"#FFFFFF" }}>
            <Stack sx={{mt:'14vw',ml:'6vw'}}spacing={2} direction="row" justifyContent="space-between"> 
                  <Typography sx={{ml:10}} fontSize='1.2vw' color='#FFFFFF'>Rate Estimate</Typography>   
                  <Typography sx={{mr:10}} fontSize='1.2vw' width='16vw' color='#FFFFFF'>{intR}%</Typography>   
            </Stack>
            <Stack sx={{mt:2, ml:'6vw'}}spacing={2} direction="row" justifyContent="space-between"> 
                  <Typography sx={{ml:10}} fontSize='1.2vw'color='#FFFFFF'>Upfront</Typography>  
                  <Typography sx={{mr:10}} fontSize='1.2vw' width='16vw' color='#FFFFFF'>{upfront}</Typography>  
            </Stack>
            <Stack sx={{mt:2, ml:'6vw'}}spacing={2} direction="row" justifyContent="space-between"> 
                  <Typography sx={{ml:10}} fontSize='1.2vw' color='#FFFFFF'>Monthly</Typography>  
                  <Typography sx={{mr:10}} fontSize='1.2vw' width='16vw' color='#FFFFFF'>{monthly}</Typography>  
            </Stack>
          </Box>
        </Box>
    </React.Fragment>
  );
}


  function LeftBlock() {
    const { bChoiceE,
            selectedProp,
            bfinancialProfile
          } = useSelector((state)=>state.borrower);
    const dispatch = useDispatch();
    const [upfront,setUpfront] = React.useState(bChoiceE.upfront)
    const [monthlyBudget,setMonthlyBudget] = React.useState(bChoiceE.monthlyBudget)
    const [max,setMax] = React.useState(bfinancialProfile.frontEndDTI);
    const [diff,setDiff] = React.useState(Math.round(0.23*selectedProp?.prop.offerPrice))

    React.useEffect(()=>{

      const propFinRequest = {
      propertyPrice  : selectedProp?.prop.price,
      offerPrice     : selectedProp?.prop.offerPrice,
      monthlyBudget  : bChoiceE?.monthlyBudget,
      upfront        : bChoiceE?.upfront
      }

      dispatch(borrowerChoiceF(propFinRequest));
    },[monthlyBudget,upfront])

    function onClickFun(e){
      var upfront = 0.0;
      var monthlyBudget = 0.0;
      e.preventDefault();
      const buttonId = e.currentTarget.id;
      switch(buttonId) {
        case "Upfront": 
              var input = parseInt(e.currentTarget.value.replace(/\$|,/g, '')); 
              setUpfront(isNaN(input) ? 0 : input)
              if(input !==bChoiceE?.upfront) dispatch(borrowerChoiceE({...bChoiceE, upfront:input}));
              break;
        case "Monthly": 
              var locVal = parseInt(e.currentTarget.value.replace(/\$|,/g, ''));
              setMonthlyBudget(isNaN(locVal) ? 0 : Math.min(max,locVal))
              if(locVal !==bChoiceE?.monthlyBudget)dispatch(borrowerChoiceE({...bChoiceE, monthlyBudget:locVal}));
        break;
        default:
          break;
      }
      
    }
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
          <Typography sx={{ ml:'7vw',mt:'6vw' }} fontSize='1.7vw' color='#000000'> 
                      Change Budget </Typography>
          <div width='20vw'>      
                <Typography sx={{ ml:'7vw',mt:'2vw' }} fontSize='1.2vw' fontStyle="regular" component='div' color='#000000'>Modify Budget to Qualify</Typography>
                <Stack sx={{ ml:'7vw',mt:'2vw',width:'28vw' }} spacing={1} direction="row" justifyContent="space-between">
                  <Typography fontSize='1.0vw' fontStyle="regular" component='div' color='#000000'> Upfront:</Typography>
                  <Input disableUnderline={true} id="Upfront" variant="standard" value={withCommas(upfront)} sx={{pl:10, border:1, borderRadius:2,color:"#000000",fontSize:'1.1vw', width:"14vw"}} 
                        onChange={onClickFun}InputProps={{disableUnderline:true }}/>
                </Stack>
                <Stack sx={{ ml:'7vw',mt:'2vh', width:'28vw' }}spacing={1} direction="row" justifyContent="space-between">
                   <Typography fontSize='1vw' fontStyle="regular" component='div' color='#000000'> Monthly(max {withCommas(max)}):</Typography>
                   <Input disableUnderline={true} id="Monthly" variant="standard" value={withCommas(monthlyBudget)} sx={{pl:'5vw', border:1, fontSize:'1vw',borderRadius:2,color:"#000000", width:"14vw"}} 
                        onChange={onClickFun}InputProps={{disableUnderline:true }}/>
                </Stack>
          </div>

          <div align='center'>
            { bChoiceE.upfront > diff
                 ? <Typography sx={{ mt:'5vh', width:'25vw'}} fontSize='1.1vw'
                 color='#000' align='center'>
                    Your upfront is sufficient for best rates on this property
                  </Typography>
                 
                 : <Typography sx={{ mt:'5vh', width:'25vw'}} fontSize='1.1vw'
                              color='#000' align='center'>
                      To qualify for the best rates, you need to increase your upfront budget to at least {withCommas(diff)}
                  </Typography>
              }
                </div>
                <LeftbottomA leftbottom={leftbottom}/>
      </Box>
    );
  }
  function InnerCarousel(props) {
    const {imageArray,numCards} = props;
    const [active, setActive] = React.useState(0);
    return (
      <div className="InnerCarousel">
        <ItemsCarousel
           autoPlay={true}
           infiniteLoop={true}
           gutter={20}
           timeout={1}
           activePosition={"center"}
           chevronWidth={-2}
           disableSwipe={false}
           alwaysShowChevrons={false}
           numberOfCards={numCards}
           slidesToScroll={1}
           outsideChevron={false}
           showSlither={true}
           firstAndLastGutter={true}
           activeItemIndex={active}
          requestToChangeActive={(value) => setActive(value)}
          //rightChevron={<ArrowForwardIosIcon sx={{color:"#FFFFFF" , fontSize:40}}/>}
          //leftChevron={<ArrowBackIosIcon    sx={{color:"#FFFFFF", fontSize:40}}/>}
          rightChevron={ <img src={rightArrow} alt='right-arrow' style={{ width:'1.77vw', height:'3.28vw' }} /> }
          leftChevron={ <img src={leftArrow} alt='left-arrow' style={{ width:'1.77vw', height:'3.28vw' }} /> }
          // rightChevron={<ArrowForwardIosIcon sx={{color:"#F1F2F4", fontSize:40,
          //   borderRadius: '15px 0px 0px 15px',
          //   width: '34px',
          //   height: '63px',
          //   background: "rgba(0,0,0,0.40)",
          //   color:'#FFFFFF',
          //   paddingLeft: '0.3vw'
          // }}/>}
          // leftChevron={<ArrowBackIosIcon    sx={{ color:"#F1F2F4", fontSize:40,
          //   borderRadius: '0px 15px 15px 0px',
          //   width: '34px',
          //   height: '63px',
          //   background: "rgba(0,0,0,0.40)",
          //   color:'#FFFFFF',
          //   paddingLeft: '0.3vw'
          // }}/>}
        >
         {imageArray.map((item) => 
        <div>
        <Box sx={{border:1, borderColor:"#FFFFFF", borderRadius:2, height:"28vh"}} >
        <img src={(typeof(item) ==='undefined' ? nestfuelImage : item)} alt={nestfuelImage} width="100%" height="100%"/>
         </Box>
         </div>)}
        </ItemsCarousel>
      </div>
    );
  }

export default ChangeBudget;