import React from "react";
import { Box, CssBaseline, Input, CircularProgress,  Typography, Button, Stack} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate } from 'react-router-dom';
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import ItemsCarousel from "react-items-carousel";
import leftbottom from '../../assets/images/bg-10@1x.png'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GoogleMaps from './Googlemaps';
import {  borrowerProfile,
          borrowerProfileNew,
          borrowerPropertyDetail,
          borrowerProfileStatus} from "../../actions/borrower";
import { BedsAndBaths, Centerbottom, LeftbottomA, RightBlockApply } from "./RightBlock";
import { UPDATE_PROFILEJOURNEY, withCommas } from "../../constants";
import {useLocation} from 'react-router-dom';
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import * as api from '../../api/index';
import { fontFamily } from "@mui/system";
import { rspvw } from "./styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { mongoborrowerPostPropertyData,mongoborrowerPostUserData,encompassPostPropertyData } from "../../actions/borrower";
import {  mongoborrowerGetUserData, mongoborrowerGetFinanceData } from '../../actions/borrower';


function StartApplication() {
  const { selectedProp,
          proposedProp,
          isLoading,
          bProfile,
          bfinancialProfile,
          bProfileJourney,
          bChoiceDSF,
          bPropertyDetail,
         } = useSelector((state)=>state.borrower);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  const [isvalidEmail,setIsValidEmail] = React.useState(true)
  const [isValidName, setIsValidName] = React.useState(true)
  const [navCheck, setNavCheck] = React.useState(false)
  const [warning, setWarning] = React.useState(false)

  React.useEffect(()=>{
    dispatch(mongoborrowerGetUserData(bProfile));
    dispatch(mongoborrowerGetFinanceData(bfinancialProfile));
  },[])


  const [stB, SetstB] = React.useState('');
  const { firstName, middleName, lastName } = bProfile;
  // const nameParts = [firstName, middleName, lastName].filter(Boolean);
  const fullName =(firstName!=null&firstName!=''?firstName.trim():'')+(middleName!=null&middleName!=''?' '+middleName.trim():'')+(lastName!=null&lastName!=''?' '+lastName.trim():'');

  const [nameEn, setNameEn] = React.useState(fullName);
  const [phoneEn, setPhoneEn] = React.useState(parseInt(bProfile.phoneNumber.slice(-10)));
  const [emailEn, setEmailEn] = React.useState(bProfile.emailId);
  const [ssnEn, setSsnEn] = React.useState(0);
  const [incomeEn, setIncomeEn] = React.useState(bfinancialProfile.income.annualIncome);
  const [propValueEn, setPropValueEn] = React.useState(selectedProp?.prop?.price != null ? selectedProp.prop.price : 0);
  const loanAmountEnValue = propValueEn - bfinancialProfile.income.upfront;
  const [loanAmountEn, setLoanAmountEn] = React.useState(loanAmountEnValue);
  console.log("here is enValue",nameEn,phoneEn,emailEn,ssnEn,propValueEn,loanAmountEn,incomeEn)  
  // console.log("here is the stb",stB)
  const [propViewed,setPropViewed] = React.useState(Array.isArray(bProfile.propertiesViewed)?bProfile.propertiesViewed:[])
  // const [propC,setPropC]    = React.useState(location?.state?.name)
  // const [finObj,setFinObj]  = React.useState(bChoiceDSF[propC?.propId][propC?.finOpt])
  // console.log("here is the bChoiceDSF", bChoiceDSF[propC?.propId][propC?.finOpt])
  const [financeOptionDetails, setFinanceOptionDetails] = React.useState('')
  const [propertyDetails, setPropertyDetails] = React.useState('')

  console.log(financeOptionDetails,'<===here are the financeDetails')
  function isValidEmail() {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(!bProfile.emailId || regex.test(bProfile.emailId) === false){
        return false;
    }
  
    return true;
  }
  function isValidate() {
    // const regex = /^(\w+\S+)$/;
    // if(!isValidEmail() || bProfile.firstName==''||regex.test(bProfile.firstName) === false){
    //    setWarning('Enter valid details')
    //     return false;
    // }
    // check for enName, enEmail, enPhone, enSsn
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
    // nameRegex name should not start with space, should not end with space, should not have multiple spaces, should not have special characters, should not have numbers, there should be atleast three characters
    const nameRegex = /^(?!.*\s$)(?!.*\s{2})(?!.*[^a-zA-Z\s])(?!.*\d)[A-Za-z\s]{1,}[A-Za-z]$/;

    const phoneRegex = /^(\d{10})$/
    const ssnRegex = /^(\d{9})$/

    if(nameEn==''){
      toast.error("Please enter your name", { position: toast.POSITION.TOP_CENTER, autoClose: 2000}); return false;
    } else if(!nameRegex.test(nameEn)){
      toast.error("Please enter a valid name", { position: toast.POSITION.TOP_CENTER, autoClose: 2000}); return false;
    } else if(phoneEn==''){
      toast.error("Please enter your phone number", { position: toast.POSITION.TOP_CENTER, autoClose: 2000}); return false;
    } else if(!phoneRegex.test(phoneEn)){
      toast.error("Please enter a valid phone number", { position: toast.POSITION.TOP_CENTER, autoClose: 2000}); return false;
    } else if(emailEn==''){
      toast.error("Please enter your email", { position: toast.POSITION.TOP_CENTER, autoClose: 2000}); return false;
    } else if(!emailRegex.test(emailEn)){
      toast.error("Please enter a valid email", { position: toast.POSITION.TOP_CENTER, autoClose: 2000}); return false;
    } else if(ssnEn==''){
      toast.error("Please enter your SSN", { position: toast.POSITION.TOP_CENTER, autoClose: 2000}); return false;
    } else if(!ssnRegex.test(ssnEn)){
      toast.error("Please enter a valid SSN", { position: toast.POSITION.TOP_CENTER, autoClose: 2000}); return false;
    }
    return true;
    setWarning(false)
  }
  const postPreApprovalObj ={
    firstName:nameEn,
    lastName:'',
    emailId:emailEn,
    borrowerProfile:bfinancialProfile,
    bProfile:bProfile,
    propertyDetails:propertyDetails,
    financeOptionDetails:financeOptionDetails

  }
  console.log("Here is the payload object===>",postPreApprovalObj)
  const sendPostPreApproval = async () => {
  
    let response = '';

    try {
const apiBodyData = {
  "data": {
      "selectedProperty": {
          "mlsId": postPreApprovalObj.propertyDetails.mlsId,
          "address": {
              "street": postPreApprovalObj.propertyDetails.address,
              "city": postPreApprovalObj.propertyDetails.city,
              "state": postPreApprovalObj.propertyDetails.state,
              "zipCode": postPreApprovalObj.propertyDetails.zipcode
          },
          "thumbnail": postPreApprovalObj.propertyDetails.thumbnail
      },
      "mortgageDetails": {
          "price": postPreApprovalObj.propertyDetails.price,
          "offerPrice": postPreApprovalObj.propertyDetails.offerPrice,
          "mortgageType": postPreApprovalObj.propertyDetails.mortgageDetails.mortgageType,
          "nestFuelFinanceOption": "Yes",
          "closingCost": postPreApprovalObj.propertyDetails.mortgageDetails.closingCost,
          "financeType": postPreApprovalObj.financeOptionDetails.financeType,
          "loanAmount": postPreApprovalObj.financeOptionDetails.loanAmount,
          "loanTerm": postPreApprovalObj.financeOptionDetails.loanTerm,
          "percentLTV": postPreApprovalObj.financeOptionDetails.percentLTV,
          "upfront": postPreApprovalObj.financeOptionDetails.upfront,
          "totalCost": postPreApprovalObj.financeOptionDetails.totalCost,
          "interestRate": postPreApprovalObj.financeOptionDetails.interestRate,
          "apr": postPreApprovalObj.financeOptionDetails.apr,
          "propTax": postPreApprovalObj.financeOptionDetails.propTax,
          "pmi": postPreApprovalObj.financeOptionDetails.pmi,
          "monthlyEMI": postPreApprovalObj.financeOptionDetails.monthlyEMI,
          "insurance": postPreApprovalObj.financeOptionDetails.insurance
      },
      "propertiesViewed": [
          {
            "mlsId": postPreApprovalObj.propertyDetails.mlsId,
            "address": {
                "street": postPreApprovalObj.propertyDetails.address,
                "city": postPreApprovalObj.propertyDetails.city,
                "state": postPreApprovalObj.propertyDetails.state,
                "zipCode": postPreApprovalObj.propertyDetails.zipcode
            }
          }
      ]
  }
}

  dispatch(mongoborrowerPostPropertyData(apiBodyData))
    const apiBodyDataforEncompass = {
      "name": nameEn,
      "phoneNumber": parseInt(phoneEn),
      "email": emailEn,
      "income": parseInt(incomeEn),
      "ssn": parseInt(ssnEn),
      "propertyValue": parseInt(propValueEn),
      "mortgageAmount": parseInt(loanAmountEn),
      "address": {
          "street": postPreApprovalObj.propertyDetails.address,
          "city": postPreApprovalObj.propertyDetails.city,
          "state": postPreApprovalObj.propertyDetails.state,
          "zipCode": parseInt(postPreApprovalObj.propertyDetails.zipcode)
      },
      "mortgageDetails": {
          "price": parseInt(propValueEn),
          "closingCost": parseInt(postPreApprovalObj.propertyDetails.mortgageDetails.closingCost),
          "loanAmount": parseInt(loanAmountEn),
          "upfront": parseInt(postPreApprovalObj.financeOptionDetails.upfront),
          "totalCost": parseInt(postPreApprovalObj.financeOptionDetails.totalCost),
          "interestRate": parseInt(postPreApprovalObj.financeOptionDetails.interestRate),
          "apr": parseInt(postPreApprovalObj.financeOptionDetails.apr),
          "propTax": parseInt(postPreApprovalObj.financeOptionDetails.propTax),
          "pmi": parseInt(postPreApprovalObj.financeOptionDetails.pmi),
          "monthlyEMI": parseInt(postPreApprovalObj.financeOptionDetails.monthlyEMI),
          "insurance": parseInt(postPreApprovalObj.financeOptionDetails.insurance)
      }
  }
  console.log("Here is the payload object===>",apiBodyDataforEncompass)
  dispatch(encompassPostPropertyData(apiBodyDataforEncompass));
  const apiBodyDataforphoneandemail = {
    "data":{
    "phoneNumber": phoneEn,
    "emailId": emailEn
    }
}
dispatch(mongoborrowerPostUserData(apiBodyDataforphoneandemail));


      response = await api.postPreApproval(postPreApprovalObj);
      const data = await response;
      // if(_debug === 'development'){
        console.log(data);
    } catch (error) {
       
        console.log(error);
    }
  }
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 
  console.log("location passed",location)
  const len = propViewed?.length
  if(location?.state?.name === 'selected' || location?.state?.name === 'alternative'){
    propViewed.filter((item)=> item.mlsId === selectedProp.prop.mlsId).length < 1
        ? propViewed.push(selectedProp?.prop)
        : console.log(" Selected property already in the array")
  }else{
    propViewed.filter((item)=> item.mlsId === proposedProp.prop.mlsId).length < 1
        ? propViewed.push(proposedProp?.prop)
        : console.log("Proposed property already in the array")
  }
  if(propViewed.length> len) {
    dispatch(borrowerProfile({...bProfile,propertiesViewed:propViewed}))

    dispatch(borrowerPropertyDetail({...bPropertyDetail,propertiesViewed:propViewed}))
  }
console.log("Here is the Prop view===>",propViewed)
  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "changeP":
          navigate("/ShowProperty", { replace: false });
          break;
      case "changeF":
          navigate(-1, { replace: false });
          break;
      case "arrV":
      case "preA":
        // navigate("/NestfuelMessenger",{state:{name:location.state.name,flow :'preapproval'}}, { replace: false });
        dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, propertyShortlisted:true} });
        if(bProfile.emailId =='-' || bProfile.emailId==''){
            setIsValidEmail(false)
            setNavCheck(true)
            console.log("here is HERE")
        }
        
        console.log(isvalidEmail)
        if(isValidate()){
        sendPostPreApproval()

          navigate("/NestfuelMessenger",{state:{name:location.state.name,flow :'preapproval'}}, { replace: false });
        }
       
      
       
        break;
      default:
        break;
    }
  }

  return(
    isLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
      <React.Fragment><CssBaseline/>
          <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
            <Navbar pathname={location.pathname}/>
            <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
              { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlockA warning={warning} setA={SetstB} setIsValidEmail={setIsValidEmail} isvalidEmail={isvalidEmail} isValidName={isValidName} setIsValidName={setIsValidName} setNavCheck={setNavCheck} enName={setNameEn} enPhone={setPhoneEn} enEmail={setEmailEn} enSSN={setSsnEn} /> }
              <ToastContainer />
              <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> </Typography>
              <div align='center'>
                <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="preA" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Preapprove" type='base' fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="changeP" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Change property" type='base' fontSize='24' /></Button>
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="changeF" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Change financing" type='base' fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="arrV" className='hexBtnCenter' disabled='true'
                    // onClick={onClickFun}
                    >
                    <HexButton titleText="Arrange viewing" type='disabledNew' fontSize='24' /></Button>
                </Stack>

                <Centerbottom navigateForward="NestfuelMessenger" />

              </div>
            
          </Box>
          <RightBlockApply  setFinDetails={setFinanceOptionDetails}  setPropertyDetails={setPropertyDetails} opt={location?.state?.name}/>
        </Box>
        </Box>
        </React.Fragment>
      ));
}

    function LeftBlockA (props) {

      const dispatch = useDispatch();
      const { bProfile,
              bProfileStatus,
              bProfileJourney,
              bfinancialProfile,
              selectedProp
            } = useSelector((state)=>state.borrower);
    
          console.log(props,'<====here are some props', bfinancialProfile, bProfile,selectedProp)  
      // const [fname, setValueF]   = React.useState(bProfile.firstName)
      // const [lname, setValueL] = React.useState(bProfile.lastName)
      const [email, setValueE] = React.useState(bProfile.emailId)
      // const [mname, setValueM] = React.useState(bProfile.middleName)
      const { firstName, middleName, lastName } = bProfile;
      const fullName =(firstName!=null&firstName!=''?firstName.trim():'')+(middleName!=null&middleName!=''?' '+middleName.trim():'')+(lastName!=null&lastName!=''?' '+lastName.trim():'');

      const [name, setName] = React.useState(fullName)
      const [phoneNumber, setValuePN] = React.useState(bProfile.phoneNumber.slice(-10))
      const [income, setValueI] = React.useState(bfinancialProfile.income.annualIncome)
      const [ssn, setValueS] = React.useState('')
      const [propValue, setValuePV] = React.useState(selectedProp.prop.price!=null?selectedProp.prop.price:0)
      const getLoanAmount = propValue - bfinancialProfile.income.upfront;
      const [loanAmount, setValueLA] = React.useState(getLoanAmount)
      const [error, setError] = React.useState(null);
      //const [lname, setValueL] = React.useState(bProfile.LastName)
      function onClickFun(e) {
        const buttonId = e.currentTarget.id;
        //const input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
        const input = (e.currentTarget.value)
        //console.log(e);
        switch(buttonId) {
          case "email":
            const formattedValueEmail = input.trim().substring(0,30);
            props.enEmail(formattedValueEmail);
            setValueE(formattedValueEmail);
            dispatch(borrowerProfile({...bProfile, emailId: formattedValueEmail}));
            break;
          case "name":
            const formattedValueName = input.replace(/[^a-zA-Z ]/g, "").substring(0,30);
            // above and not starting with space
            // const formattedValueName = input.replace(/[^a-zA-Z ]/g, "").replace(/\s\s+/g, ' ').trim().substring(0,30);
            props.enName(formattedValueName);
            setName(formattedValueName);
            break;
          case "phoneNumber":
            const formattedValue = input.replace(/\D/g, '').substring(0,10);
            props.enPhone(formattedValue);
            // props.setA(input);
            setValuePN(formattedValue);
            dispatch(borrowerProfile({...bProfile, phoneNumber: formattedValue}));
            break;
          case "income":
            break;
          case "ssn":
            const formattedValueSSN = input.replace(/\D/g, '').substring(0,9);
            props.enSSN(formattedValueSSN);
           setValueS(formattedValueSSN);
            break;
          case "propValue":
            break;
          case "loanAmount":
            break;

          default:
            // props.enEmail(email);
            // props.enName(name);
            // props.enPhone(phoneNumber);
            // props.enSSN(ssn);

            break;
          }
          
      }
      return (
        <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
          boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' ,position:'relative'}}>
            <Box sx={{ mt:'7.2vh' , ml: '7.3vw', width: '26vw',height:'7.25vw' }}> 
                <Typography sx={{color:'#31312C', width:'21vw',
                  fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                  Please provide the following information prior to pre-approval
                </Typography>
              
            </Box>
            <Box sx={{ mt:'0.24vw' , ml: '7.3vw', width: '27vw',height:'14vw' }}> 
           
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                  <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Full Name :</Typography>  
                  <Input placeholder="Full Name" disableUnderline={true} id="name" value={name} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                        pattern="[A-Za-z]" inputProps={{style: { textAlign: 'center' }}} onChange={onClickFun}/>
                  
              </Stack>
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Phone Number :</Typography>  
                  <Input placeholder="Phone Number" disableUnderline={true} id="phoneNumber" value={phoneNumber} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{style: { textAlign: 'center' },inputMode: 'numeric',pattern: '[0-9]*'}} onChange={onClickFun}/>
              </Stack>
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Email :</Typography>  
                  <Input placeholder="Email" disableUnderline={true} id="email" value={email} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{style: { textAlign: 'center' }}} onChange={onClickFun}/>
              </Stack>
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between">
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Income :</Typography>
                  <Input placeholder="Income" disableUnderline={true} id="income" value={income} sx={{backgroundColor:'#e0e0e0',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:0, borderRadius:'0.5vw'}}
                        inputProps={{style: { textAlign: 'center' },readOnly: true}} onChange={onClickFun}/>
              </Stack>
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between">
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>SSN :</Typography>
                  <Input placeholder="xxx-xxx-xxx" disableUnderline={true} id="ssn" value={ssn} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}}
                        inputProps={{style: { textAlign: 'center' }}} onChange={onClickFun}/>
              </Stack>
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between">
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Property Value :</Typography>
                  <Input placeholder="Property Value" disableUnderline={true} id="propValue" value={propValue} sx={{backgroundColor:'#e0e0e0',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:0, borderRadius:'0.5vw'}}
                        inputProps={{style: { textAlign: 'center' },readOnly: true}} onChange={onClickFun}/>
              </Stack>
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between">
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Mortgage Amount :</Typography>
                  <Input placeholder="Mortgage Amount" disableUnderline={true} id="loanAmount" value={loanAmount} sx={{backgroundColor:'#e0e0e0',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:0, borderRadius:'0.5vw'}}
                        inputProps={{style: { textAlign: 'center' },readOnly: true}} onChange={onClickFun}/>
              </Stack>
              {
             props.warning ==false?
             <Typography sx={{color:'red' , ml:'12.7vw'}}>
             </Typography>:
              <Typography sx={{color:'red' ,mt:'1vw', ml:'12.9vw', textAlign:'center', fontFamily:'Roboto'}}>
              {props.warning}
              </Typography>
            }
              {/* <Stack sx={{mt:1}} spacing={0} direction="row" justifyContent="space-between"> 
              <Typography sx={{mt:1,fontSize:'1.35vw', width:'10  vw', justifyContent:'space-between'}} color='#000000'>SSN :</Typography>  
                  <Input placeholder="xxx-xxx-xxx" disableUnderline={true} id="ssn" value='xxx-xxx-xxx' sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw",border:1, borderRadius:'0.4vw'}} 
                        inputProps={{style: { textAlign: 'center' }}} onChange={onClickFun}/>
              </Stack> */}
            </Box>
    
            <LeftbottomA leftbottom={leftbottom}/>
        </Box>
      );
    }

    
function InnerCarousel(props) {
  const {imageArray,numCards,chevron} = props;
  const [active, setActive] = React.useState(0);
  console.log("chevron",chevron)
  return (
    <div className="InnerCarousel">
      <ItemsCarousel
         autoPlay={true}
         infiniteLoop={true}
         gutter={0}
         timeout={1}
         activePosition={"center"}
         chevronWidth={-2}
         disableSwipe={false}
         alwaysShowChevrons={false}
         numberOfCards={numCards}
         slidesToScroll={1}
         outsideChevron={false}
         showSlither={true}
         firstAndLastGutter={true}
         activeItemIndex={active}
        requestToChangeActive={(value) => setActive(value)}
        rightChevron={<ArrowForwardIosIcon sx={{color:"#FFFFFF" , fontSize:'3vw'}}/>}
         leftChevron={<ArrowBackIosIcon    sx={{color:"#FFFFFF" , fontSize:'3vw'}}/>}
      >
      {imageArray.map((item) => 
      <div>
       <Box sx={{ border:1, borderColor:"#FFFFFF", borderRadius: '10px 10px 0px 0px', height:'13.5vw'}} >
       <img src={(typeof(item) ==='undefined' ? nestfuelImage : item)} alt={nestfuelImage} style={{borderRadius: '10px 10px 0px 0px'}} width="100%" height="100%"/>
        </Box>
        </div>)}
      </ItemsCarousel>
    </div>
  );
}

export default StartApplication ;