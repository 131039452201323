import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button, Input} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/bg-10@1x.png'
import {  useDispatch, 
          useSelector } from 'react-redux';
import { Centerbottom, LeftbottomA, RightBlockEB, RightBlockSPBP, RightBlockSPBPRate } from "./RightBlock";
import { _debug } from "../../constants";
import { borrowerChoiceI, calcPropfinOptions, borrowerProfile, borrowerProfileStatus,borrowerPostUserData } from "../../actions/borrower";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";

export function EmployerSupportDtExtend() {
  const { isLoading,
    bProfile,
    bProfileStatus,
    bChoiceE,
    bChoiceB,
    bChoiceD,
    selectedProp,
    bfinancialProfile,
    bChoiceF
  } = useSelector((state)=>state.borrower);
const navigate = useNavigate();
const location = useLocation();
const dispatch = useDispatch();
const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 


  function onClickFun(e) {
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch (buttonId) {
      case "sendInvite":
        dispatch(borrowerPostUserData(bProfile));
        navigate("/EmployerSupportDtReg", { replace: false });
        break;
      case "empName":
        navigate("/EmployerSupportDt", { replace: false });
        break;
      case "skip":
        navigate("/EmployerSupportDtReg", { replace: false });
        break;
      
      default: break;
    }
  }

  return(
    isLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
      <React.Fragment><CssBaseline/>
             <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
              <Navbar pathname={location.pathname}/>
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
                { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
              
                <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <div align='center'>
                <Typography sx={{ color: '#000000', mt: '11.42vw', fontSize: '1.14vw', textAlign: 'center' }}></Typography>

                <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', width: '5.23vw', height: '6.80vw', border: 0 }} 
                  id="sendInvite" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Send invite" type='base' fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', width: '5.23vw', height: '6.80vw', border: 0 }} 
                  id="empName" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Employer name" type='base' fontSize='24' /></Button>
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} 
                  id="skip" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Skip" type='base' fontSize='24' /></Button>
                </Stack>
                <Centerbottom navigateForward="EmployerSupportDt" />
              </div>
          </Box>
        <RightBlockSPBPRate/>
      </Box>
    </Box>
    </React.Fragment>
  ));
}

function LeftBlock () {

  const dispatch = useDispatch();
  const { bChoiceE,
          bfinancialProfile,
          bProfile
        } = useSelector((state)=>state.borrower);
  // const [name,setName] = React.useState(bProfile.firstName+' '+bProfile.lastName)
  // const [employerEmail,setEmployerEmail] = React.useState('aivanna.maraea@nestfuel.com')
  // const [employerContact,setEmployerContact] = React.useState('Rajesh Pai')
  // const [contactEmail,setContactEmail] = React.useState('rajesh.pai@nestfuel.com')
  const [employerName,setEmployerName] = React.useState((bProfile?.employer?.companyName).trim())
  const [jobTitle,setJobTitle] = React.useState((bProfile?.employer?.jobTitle).trim())
  const [fullName,setFullName] = React.useState((bProfile?.employer?.contactPersonName).trim())
  const [workEmail,setWorkEmail] = React.useState((bProfile?.employer?.workEmail).trim())
  
  function onClickFun(e) {
    const buttonId  = e.currentTarget.id
    const input     = e.currentTarget.value
    switch(buttonId) {
      case "fullName": 
        const contactPersonName = {...bProfile.employer, contactPersonName:input};
        dispatch(borrowerProfile({...bProfile, employer:contactPersonName }));
        setFullName(input)
        break;
      case "jobTitle": 
        const jobTitle = {...bProfile.employer, jobTitle:input};
        dispatch(borrowerProfile({...bProfile, employer:jobTitle }));
        setJobTitle(input);
        break;
      case "workEmail":
        const workEmail = {...bProfile.employer, workEmail:input};
        dispatch(borrowerProfile({...bProfile, employer:workEmail }));
        setWorkEmail(input)
        break;
      default: break;
    }
  }

  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box', position: 'relative', position: 'relative',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '46.75vw'
    }}>
      <Box sx={{ mt: '8.2vw', ml: '7.3vw', width: '26vw', height: '12vw' }}>
        <Typography sx={{
          color: '#31312C', width: '24vw',
          fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          Contact details at [{employerName}]
        </Typography>
        <Typography sx={{
          color: '#000000', width: '24vw', mt: '1.7vw',
          fontSize: '1.35vw', fontFamily: 'Roboto', fontWeight: 400, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          Help us get to the right person at [{employerName}]
        </Typography>
      </Box>
      <Box sx={{ mt: '1vw', ml: '7.3vw', width: '28vw', height: '14vw', pr: '1vw' }}>
        <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw', width: '13vw', justifyContent: 'space-between' }} color='#000000'>Full name</Typography>
          <Input disableUnderline={true} id="fullName" value={fullName} sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '1vw', height: '2.4vw', width: "13vw", border: 1, borderRadius: '0.4vw' }}
            inputProps={{ style: { textAlign: 'center' } }} placeholder="First and last name" onChange={onClickFun} />
        </Stack>
        <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw', width: '13vw' }} color='#000000'>Job title</Typography>
          <Input disableUnderline={true} id="jobTitle" value={jobTitle} sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '1vw', height: '2.4vw', width: "13vw", border: 1, borderRadius: '0.5vw' }}
            inputProps={{ style: { textAlign: 'center' } }} placeholder="Job title" onChange={onClickFun} />
        </Stack>
        <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw', width: '13vw', justifyContent: 'space-between' }} color='#000000'>Work email address</Typography>
          <Input disableUnderline={true} id="workEmail" value={workEmail} sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '1vw', height: '2.4vw', width: "13vw", border: 1, borderRadius: '0.4vw' }}
            inputProps={{ style: { textAlign: 'center' } }} placeholder="Work email address" onChange={onClickFun} />
        </Stack>

        

        {/* <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1vw', width: '10vw', justifyContent: 'space-between' }} color='#000000'>Employer email:</Typography>
          <Input disableUnderline={true} id="empEmail" value={bProfile?.employer?.employerEmail} sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '1vw', height: '2.4vw', width: "14vw", border: 1, borderRadius: '0.4vw' }}
            inputProps={{ style: { textAlign: 'center' } }} onChange={onClickFun} />
        </Stack>
        <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1vw', width: '10vw', justifyContent: 'space-between' }} color='#000000'>Relevant Contact:</Typography>
          <Input disableUnderline={true} id="relContact" value={bProfile?.employer?.relevantContact} sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '1vw', height: '2.4vw', width: "14vw", border: 1, borderRadius: '0.4vw' }}
            inputProps={{ style: { textAlign: 'center' } }} onChange={onClickFun} />
        </Stack>
        <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1vw', width: '10vw', justifyContent: 'space-between' }} color='#000000'>Contact email:</Typography>
          <Input disableUnderline={true} id="contactEmail" value={bProfile?.employer?.contactEmail} sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '1vw', height: '2.4vw', width: "14vw", border: 1, borderRadius: '0.4vw' }}
            inputProps={{ style: { textAlign: 'center' } }} onChange={onClickFun} />
        </Stack> */}
      </Box>
      <LeftbottomA leftbottom={leftbottom} />
    </Box>
  );
}

export default EmployerSupportDtExtend