import React from "react";
import {Box, CssBaseline, Stack, Typography, Button} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import paymentImageSvg from '../../assets/svg/assets svg/contract-loan-svgrepo-com.svg'
import { useDispatch, useSelector } from 'react-redux';
import { borrowerProfile, borrowerProfileStatus, borrowerProfileNew, borrowerPropertyDetail } from "../../actions/borrower";
import leftbottom from '../../assets/images/bg-10@1x.png'

import {Centerbottom, LeftbottomA, RightBlockPropConf, RightBlockSPBP} from './RightBlock'
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { IS_PROFILE_INFO_CHANGED } from "../../constants";
import { rspvw } from "./styles";
function ResidencyStatus() {
  const { bProfile,
          bProfileStatus,
        } = (useSelector((state)=>state.borrower));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 

  function onClickFun(e){
      e.preventDefault();
      const buttonId = e.currentTarget.id;
      var residency = '';
      switch(buttonId) {
        case "Citizen": residency = 'US Citizen';
        dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});
        break;
        case "Foreigner":residency = 'Resident Foreigner';
        dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});
        break;
        case "TempRes":residency = 'Temporary Resident';
        dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});
        break;
        case "Green":residency = 'Green Card';
        dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});
        break;
        default:
          break;
      }
      dispatch(borrowerProfile({...bProfile, residency:residency}));
      dispatch(borrowerProfileStatus({...bProfileStatus,residencyProvided:1}))

      //dispatch(borrowerProfileNew({...bProfile, residency:residency }));

      navigate(-1);
  }

  return(
    <React.Fragment><CssBaseline/>
    <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
      <Navbar pathname={location.pathname}/>
      <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
        { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
      
        <Box sx={{ width: '14vw', height:{xs:`${rspvw(815.9)}`,lg:`${rspvw(815.9)}`,
        md:`${rspvw(915)}`, xl:`${rspvw(815.9)}`,sm:`${rspvw(815.9)}`}, display:'block', alignItems:'center',position:'relative'}}>
        <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> 
      Update Settings
    </Typography>
            <div align='center'>
              <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Citizen" className='hexBtnCenter'
                  onClick={onClickFun}>
                  <HexButton titleText="US Citizen" type={bProfile.residency === 'US Citizen' ? 'visited' : 'base'} fontSize='24' /></Button>
              </Stack>
              <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} direction="row" justifyContent="space-around">
                <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Foreigner" className='hexBtnCenter'
                  onClick={onClickFun}>
                  <HexButton titleText="Resident Foreigner" type={bProfile.residency === 'Resident Foreigner' ? 'visited' : 'base'} fontSize='24' /></Button>
                <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="TempRes" className='hexBtnCenter'
                  onClick={onClickFun}>
                  <HexButton titleText="Temporary Resident" type={bProfile.residency === 'Temporary Resident' ? 'visited' : 'base'} fontSize='24' /></Button>
              </Stack>
              <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '6.5vw' }} direction="row" justifyContent="space-around">
                <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Green" className='hexBtnCenter'
                  onClick={onClickFun}>
                  <HexButton titleText="Green Card" type={bProfile.residency === 'Green Card' ? 'visited' : 'base'} fontSize='24' /></Button>
              </Stack>
              <Centerbottom navigateForward='-1' />
            </div>
          </Box>
          <RightBlockSPBP/>
      </Box>
    </Box>
    </React.Fragment>
    );
}

  function LeftBlock() {
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:{xs:`${rspvw(815)}`,lg:`${rspvw(815)}`,
      md:`${rspvw(915)}`, xl:`${rspvw(915)}`,sm:`${rspvw(815)}`} }}>
          <Box sx={{ ml:'7.2vw',mt:'30%' , width:'21vw',background:'inherit', height: '19.7vw' }}>
            <div align='center'>
                  <Box width='5.2vw'height='5.2vw'><img src={paymentImageSvg} alt = "Loading..." heigh="100%" width='100%'/></Box>
                  <Typography mt={5} fontSize='1.4vw' fontStyle="regular" component='div'color='#000000'> 
                      Residency Status
                  </Typography>
                  <Typography sx={{ mt:2}} fontSize='1.1vw' component="div" color='#000000' align='center'>
                      US Citizen or resident aliens
                  </Typography>
            </div>
          </Box>
         <LeftbottomA leftbottom={leftbottom}/>
        </Box>
      );
  }
 
export default ResidencyStatus;