import React, { useState } from 'react';
import { Box, CssBaseline, Input,  Typography, Button, Stack,Avatar,Divider} from "@mui/material";
import Navbar from "./NavBar";
import { rspvw } from "./styles";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import leftbottom from '../../assets/images/bg-10@1x.png'
import { Centerbottom, LeftbottomA} from "./RightBlock";
import { _debug, withCommas } from "../../constants";
import useStyles from './styles';
import { useWindowDimensions } from '../../computations/helperFunctions';
import { LeftBlockMsg } from "./Messenger";
import SignInScreen from './FirebaseUI';
import { RightBlockProfile } from "./DashboardProfile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ALPHA_NUMERIC_DASH_REGEX = /^[0-9\b]+$/;
const ALPHA_CHAR_DASH_REGEX = /^[a-zA-Z\s]+$/;
import {  borrowerProfile} from "../../actions/borrower";
import { mongoborrowerPostUserData, mongoborrowerUpdateUserData } from "../../actions/borrower";

function DashboardPersonalProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  const [numPage,setNumPage] = React.useState(0)
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
    //isLoggedin && setNumPage(1)
  },[height,width])
  
  const { isLoggedin, isSignup,bProfile} = useSelector((state)=>state.borrower);  

  React.useEffect(()=>{
    isLoggedin && setNumPage(1)
  },[isLoggedin])
  
 
    function onClickFun(e){
        e.preventDefault();
        const buttonId = e.currentTarget.id;
        switch(buttonId) {
            case "skip1"           : navigate("/DashboardMain",        { replace: false }); break;
            case "back"            : navigate("/DashboardMain", { replace: false }); break;
            case "creditProfile"   : navigate("/DashboardCreditProfile",     { replace: false }); break;
            case "ecoProfile"      : navigate("/DashboardEconomicProfile",    { replace: false }); break;
            case "save" : 
            //dispatch(borrowerPostUserData(bProfile));

           
           
           

            if(bProfile.marriageStatus == '' || bProfile.marriageStatus == undefined){
              toast("Marital Status Should not be blank!", {position: toast.POSITION.TOP_CENTER})
            }

            else if(bProfile.marriageStatus.trim() !== "" && !ALPHA_CHAR_DASH_REGEX.test(bProfile.marriageStatus.trim())){
              toast("Marital Status Should be string!", {position: toast.POSITION.TOP_CENTER})
            }

            else if(bProfile.coageB == "" || bProfile.coageB == undefined){
              toast("Age Dependents Should not be blank!", {position: toast.POSITION.TOP_CENTER})
            }
            else if(bProfile.coageB.trim() !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(bProfile.coageB.trim())){
              toast("Age Should be number!", {position: toast.POSITION.TOP_CENTER})
            }
            else{
              const apiBodyData = {
                "data":{
                "age": bProfile.coageB==''?null:parseInt(bProfile.coageB),
                "maritalStatus": bProfile.marriageStatus,
                
            }
            }
            dispatch(mongoborrowerUpdateUserData(apiBodyData));
            navigate("/DashboardProfileUpdate",        { replace: false });
            break;
            }
            
            
            default:
            break;
        }
    }
  
  return(
  <React.Fragment>
    <CssBaseline/>
    <Box mt={marginT} ml={marginL}  sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
      <Navbar pathname={location.pathname} />
        <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1}}>
        <ToastContainer />
          { location.hash === "#messenger" ? <LeftBlockMsg/> : isLoggedin ? <LeftBlock/> : <LeftBlockB/>}

          <Box sx={{ width: '13vw',height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
          <div align='center'>
              {
                isLoggedin
                ?
                <>
                  <Stack sx={{ mt: '15.85vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="save" className='hexBtnCenter'
                      onClick={onClickFun}>
                      <HexButton titleText='Save' type='base' fontSize='24' />
                    </Button>
                  </Stack>
                  <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="creditProfile" className='hexBtnCenter'
                      onClick={onClickFun}>
                      <HexButton titleText="Credit Profile" type='base' fontSize='24' />
                    </Button>
                    <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="ecoProfile" className='hexBtnCenter'
                      onClick={onClickFun}>
                      <HexButton titleText="Economic Profile" type='base' fontSize='24' />
                    </Button>
                  </Stack>
                  <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '6.5vw' }} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="back" className='hexBtnCenter'
                      onClick={onClickFun}>
                      <HexButton titleText="Back&nbsp;to Overview" type='base' fontSize='24' />
                    </Button>
                  </Stack>
                </>
                :
                <>
                  <Stack sx={{ mt: '15.85vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="skip1" className='hexBtnCenter'
                      onClick={onClickFun}>
                      <HexButton titleText='Skip' type='base' fontSize='24' />
                    </Button>
                  </Stack>
                </>
              }
              
            </div>
              <Centerbottom navigateForward='SearchProperty'/>
            </Box>
          <RightBlockProfile/>
      </Box>
    </Box>
    </React.Fragment>
  );
}


function LeftBlock () {

    const [isSignup, setIsSignup] = useState(true);
    const [empType,setEmpType] = React.useState('')
    const [empAge,setEmpAge] = React.useState('')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { bProfile} = useSelector((state)=>state.borrower);  
   console.log("==bProfile",bProfile)
   console.log("==bProfilemStatus",bProfile.maritalStatus)
   console.log("==bProfilemStatus",bProfile.coBorrowerId)
   console.log("==bProfilemStatus ffff",bProfile.coageB)
   
    function onClickFun(e) {
      const buttonId  = e.currentTarget.id
      const input     = e.currentTarget.value
      console.log("==bProfileID",buttonId)
      console.log("==bProfileIMPUT",input)
      var obj = {}
      switch(buttonId) {
        
        case "empType":
          console.log("ELSEbuttonID",buttonId)
          console.log("E.CURRENTTarget",input)
          setEmpType(input)
         // obj = {...bProfile,marriageStatus:input}
         // dispatch({ type: UPDATE_C, payload: obj}); 
         dispatch(borrowerProfile({...bProfile,marriageStatus:input})); 
      break;

      case "empName":
        console.log("ELSEbuttonIDNAME",buttonId)
        console.log("E.CURRENTTarget",input)
        setEmpAge(input)
        dispatch(borrowerProfile({...bProfile,coageB:input})); 
        break;
   


        default: break;
        }
    }
    
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
        boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
          <Box sx={{ mt:'6.20vw' , ml: '7.3vw', width: '26vw' }}> 
              <Typography sx={{color:'#31312C', width:'26vw',
                fontSize:'2.5vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                  Personal Profile
              </Typography>
              <Typography sx={{color:'#31312C', width:'24vw',mt:'1.7vw',
                fontSize:'1.35vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                  Please provide the following to personalize your experience
              </Typography>
          </Box>
          <Box sx={{ mt:'0.56vw' , ml: '7.3vw', width: '28vw',height:'14vw',pr:'1vw' }}> 
            <form>
                <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Marital Status</Typography>  
                    
                    <Input disableUnderline={true} id="empType" sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'0.93vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{style: { textAlign: 'left', padding: '0.52vw' }}} name="empType" value={bProfile.marriageStatus} placeholder='Status' onChange={onClickFun} autoComplete="off"/>
                </Stack>
                <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Age Dependents</Typography>  
                    <Input disableUnderline={true} id="empName" sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'0.93vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                      inputProps={{style: { textAlign: 'left', padding: '0.52vw' }}} name="empName" value={bProfile.coageB}  placeholder='Age' onChange={onClickFun} autoComplete="off"/>
                </Stack>
            </form>
          </Box>
  
          <LeftbottomA leftbottom={leftbottom}/>
      </Box>
    );
}
function LeftBlockB () {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);
    const { bProfile } = useSelector((state)=>state.borrower);  
  
    const googleError = () => console.log('Google Sign In was unsuccessful. Try again later');
    
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
        boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
          <Box sx={{ mt:'12.7vh' , ml: '7.3vw', width: '26vw' }}> 
              <Typography sx={{color:'#31312C', width:'26vw',
                fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                  Dashboard : Profile
              </Typography>
              <Typography sx={{color:'#31312C', width:'24vw',mt:'1.7vw',
                fontSize:'1.35vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                  Please SignIn with your Social Media Profile
              </Typography>
          </Box>
          <Box sx={{ mt:'0.56vw' , ml: '7.3vw', width: '28vw',height:'14vw' }}> 
          <SignInScreen/>
          </Box>
  
          <LeftbottomA leftbottom={leftbottom}/>
      </Box>
    );
  }

export default DashboardPersonalProfile ;