import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button, Input} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/bg-10@1x.png'
import {  useDispatch, 
          useSelector } from 'react-redux';
import { Centerbottom, LeftbottomA } from "./RightBlock";
import { UPDATE_SPREADONE, UPDATE_SWAPRATEONE, _debug } from "../../constants";
import { borrowerGetSpreadTable, borrowerGetSwapRates, borrowerPutSpreadTable, borrowerPutSwapRates } from "../../actions/borrower";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { AutosuggestHouseType, AutosuggestLoanType } from "./Autosuggest";


export function AdminInterfaceRate() {
  const { isLoading,
          spreadTable,
          swapRate,
  } = useSelector((state)=>state.borrower);
const navigate = useNavigate();
const location = useLocation();
const dispatch = useDispatch();
const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 

  const [swapr,setSwapR] = React.useState(true)
  const [spread,setSpread] = React.useState(false)

  function onClickFun(e) {
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch (buttonId) {
      case "swaprate":
        setSpread(false)
        setSwapR(true)  
      break;
      case "spread":
        setSpread(true)
        setSwapR(false)  
      break;
      case "done": 
      if(spread)dispatch(borrowerPutSpreadTable(spreadTable))
      if(swapr) dispatch(borrowerPutSwapRates(swapRate))
      navigate(-1); break;
      default: break;
    }
  }

  return(
    isLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
      <React.Fragment><CssBaseline/>
             <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
              <Navbar pathname={location.pathname}/>
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
                { location.hash === "#messenger" ? <LeftBlockMsg/> : swapr? <LeftBlockSwap/>: <LeftBlock/>}
              
                <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <div align='center'>
                <Typography sx={{ color: '#000000', mt: '11.42vw', fontSize: '1.14vw', textAlign: 'center' }}>
                </Typography>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="swaprate" className='hexBtnCenter'
                      onClick={onClickFun}>
                      <HexButton titleText="Swap Rate"
                        type='base' fontSize='24' />
                    </Button>

                    <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="spread" className='hexBtnCenter'
                      onClick={onClickFun}>
                      <HexButton titleText="Spread Table"
                        type='base' fontSize='24' />
                    </Button>
                  </Stack>

                <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="done" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText={swapr ? "Update Swap Rates":"Update Spread Table"} type='base' fontSize='24' /></Button>
                </Stack>
                <Centerbottom navigateForward="EmployerSupportDt" />
              </div>
          </Box>
        <RightBlock/>
      </Box>
    </Box>
    </React.Fragment>
  ));
}

function LeftBlock () {

  const { spreadTable,
          swapRate,
          loanType,
          houseType,
          bProfile
        } = useSelector((state)=>state.borrower);
  const dispatch = useDispatch();
  React.useEffect(()=>{
      dispatch(borrowerGetSpreadTable(loanType,houseType))
      dispatch(borrowerGetSwapRates())
  },[loanType,houseType])
  const dummyArray=[1,2,3,4,5,6,7,8,9,10]   
  const [spIndex,setSpIndex]      = React.useState(0);
  React.useEffect(()=>{
    if(loanType === 'QM'        && houseType === 'primary')       setSpIndex(0)
    if(loanType === 'QM'        && houseType === 'secondary')     setSpIndex(1)
    if(loanType === 'QM'        && houseType === 'investment')    setSpIndex(2)
    if(loanType === 'nonQM'     && houseType === 'primary')       setSpIndex(3)
    if(loanType === 'nonQM'     && houseType === 'secondary')     setSpIndex(4)
    if(loanType === 'nonQM'     && houseType === 'investment')    setSpIndex(5)
    if(loanType === 'nonQMHigh' && houseType === 'primary')       setSpIndex(6)
    if(loanType === 'nonQMHigh' && houseType === 'secondary')     setSpIndex(7)
    if(loanType === 'nonQMHigh' && houseType === 'investment')    setSpIndex(8)
  },[loanType,houseType])
  
  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box', position: 'relative', position: 'relative',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '46.75vw'
    }}>
      <Box sx={{ mt: '3.2vw', ml: '7.3vw', width: '26vw', height: '7vw' }}>
        <Typography sx={{
          color: '#31312C', width: '24vw', fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          Update Swap and Spread Rate Table
        </Typography>
      </Box>
      <Box sx={{ mt: '0vw', ml: '4.3vw', width: '30vw', height: '10vw', pr: '1vw' }}>
        <Stack sx={{ mt: 0 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: '0.5vw', fontSize: '1vw', width: '10vw', justifyContent: 'space-between' }} color='#000000'>Loan Type</Typography>
          <AutosuggestLoanType/>
          <Typography sx={{ ml:'1vw', mt: '0.5vw', fontSize: '1vw', width: '10vw', justifyContent: 'space-between' }} color='#000000'>House Type</Typography>
          <AutosuggestHouseType/>
        </Stack>
        {dummyArray.map((item,index)=>(
          <LTableRow minFico={spreadTable[0].spArray[index*7].minFico} maxFico={spreadTable[0].spArray[index*7].maxFico} spIndex={spIndex} rowIndex={index%7}/>
        ))}
      </Box>
      <LeftbottomA leftbottom={leftbottom} />
    </Box>
  );
}

function LeftBlockSwap () {

  const dispatch = useDispatch();
  const { swapRate,
          loanType,
          houseType,
          bProfile
        } = useSelector((state)=>state.borrower);
  const dummyArray=[1,2,3,4,5,6,7,8,9,10]   
  
  
  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box', position: 'relative', position: 'relative',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '46.75vw'
    }}>
      <Box sx={{ mt: '3.2vw', ml: '7.3vw', width: '26vw', height: '7vw' }}>
        <Typography sx={{
          color: '#31312C', width: '24vw', fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          Update Swap Rate Table
        </Typography>
      </Box>
      <Box sx={{ mt: '0vw', ml: '7.3vw', width: '16vw', height: '10vw', pr: '1vw' }}>
      <Stack sx={{ mt: 0 }} spacing={0} direction="row" justifyContent="space-between">
              <Box sx={{width: '7vw', border:'0.1vw', borderColor:"#000000"}}><Typography sx={{ ml:'0.1vw',mt: 1, fontSize: '0.8vw', width: '7vw'}} color='#000000'>Loan Term</Typography></Box>
              <Box sx={{width: '7vw', border:'0.1vw'}}><Typography sx={{ ml:'0.1vw',mt: 1, fontSize: '0.8vw', width: '7vw'}} color='#000000'>Swap Rate</Typography></Box>
              </Stack>
          {swapRate.map((item,index)=>(
            <>
            <Stack sx={{ mt: 0 }} spacing={0} direction="row" justifyContent="space-between">
              <Box sx={{width: '7vw', border:'0.1vw'}}><Typography sx={{ ml:'0.1vw',mt: 1, fontSize: '0.8vw', width: '7vw'}} color='#000000'>{item.tenure} years</Typography></Box>
              <Input disableUnderline={true} id="f85"  sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '0.8vw', height: '2vw', width: "8vw", border: 1, }}
                inputProps={{ style: { textAlign: 'center' } }} onChange={(e)=>dispatch({ type: UPDATE_SWAPRATEONE , payload:{index:index,value:parseFloat(e.currentTarget.value)}})} />
           </Stack>
          </>))}
      </Box>
      <LeftbottomA leftbottom={leftbottom} />
    </Box>
  );
}

function LTableRow(props){
  const { minFico,maxFico, spIndex,rowIndex } = props;
  const ficoString = minFico >=800 ? 'FICO 800+': maxFico <640 ? 'FICO < 640' : 'FICO '+minFico + '-' + maxFico
  const arrayIndex = rowIndex*7;
  const dispatch = useDispatch();
  return (
  <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ ml:'0.1vw',mt: 1, fontSize: '0.8vw', width: '7vw' }} color='#000000'>{ficoString}</Typography>
          <Input disableUnderline={true} id="f85"  sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '0.8vw', height: '2vw', width: "4vw", border: 1, }}
            inputProps={{ style: { textAlign: 'center' } }} placeholder="" onChange={(e,value)=>dispatch({ type: UPDATE_SPREADONE , payload:{index:spIndex,index1:arrayIndex, spArrayE: {minFico:minFico,maxFico:maxFico,minLtv:0,maxLtv:50,spread:parseFloat(e.currentTarget.value)}}})} />
          <Input disableUnderline={true} id="f86"  sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '0.8vw', height: '2vw', width: "4vw", border: 1, }}
            inputProps={{ style: { textAlign: 'center' } }} placeholder="" onChange={(e,value)=>dispatch({ type: UPDATE_SPREADONE , payload:{index:spIndex,index1:arrayIndex+1, spArrayE: {minFico:minFico,maxFico:maxFico,minLTV:51,maxLTV:60,spread:parseFloat(e.currentTarget.value)}}})} />
          <Input disableUnderline={true} id="f87"  sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '0.8vw', height: '2vw', width: "4vw", border: 1, }}
            inputProps={{ style: { textAlign: 'center' } }} placeholder="" onChange={(e,value)=>dispatch({ type: UPDATE_SPREADONE , payload:{index:spIndex,index1:arrayIndex+2, spArrayE: {minFico:minFico,maxFico:maxFico,minLTV:61,maxLTV:70,spread:parseFloat(e.currentTarget.value)}}})} />
          <Input disableUnderline={true} id="f88"  sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '0.8vw', height: '2vw', width: "4vw", border: 1, }}
            inputProps={{ style: { textAlign: 'center' } }} placeholder="" onChange={(e,value)=>dispatch({ type: UPDATE_SPREADONE , payload:{index:spIndex,index1:arrayIndex+3, spArrayE: {minFico:minFico,maxFico:maxFico,minLTV:71,maxLTV:80,spread:parseFloat(e.currentTarget.value)}}})} />
          <Input disableUnderline={true} id="f885"  sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '0.8vw', height: '2vw', width: "4vw", border: 1, }}
            inputProps={{ style: { textAlign: 'center' } }} placeholder="" onChange={(e,value)=>dispatch({ type: UPDATE_SPREADONE , payload:{index:spIndex,index1:arrayIndex+4, spArrayE: {minFico:minFico,maxFico:maxFico,minLTV:81,maxLTV:85,spread:parseFloat(e.currentTarget.value)}}})} />
          <Input disableUnderline={true} id="f89"  sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '0.8vw', height: '2vw', width: "4vw", border: 1, }}
            inputProps={{ style: { textAlign: 'center' } }} placeholder="" onChange={(e,value)=>dispatch({ type: UPDATE_SPREADONE , payload:{index:spIndex,index1:arrayIndex+5, spArrayE: {minFico:minFico,maxFico:maxFico,minLTV:86,maxLTV:90,spread:parseFloat(e.currentTarget.value)}}})} />
          <Input disableUnderline={true} id="f895"  sx={{ backgroundColor: '#FFFFFF', color: "#000000", fontSize: '0.8vw', height: '2vw', width: "4vw", border: 1, }}
            inputProps={{ style: { textAlign: 'center' } }} placeholder="" onChange={(e,value)=>dispatch({ type: UPDATE_SPREADONE , payload:{index:spIndex,index1:arrayIndex+6, spArrayE: {minFico:minFico,maxFico:maxFico,minLTV:91,maxLTV:95,spread:parseFloat(e.currentTarget.value)}}})} />
        </Stack>
  );

}

function RightBlock() {
  const { swapRate,
          spreadTable,
          loanType,
          houseType
          } = useSelector((state)=>state.borrower);
  const [spIndex,setSpIndex]      = React.useState(0);
  React.useEffect(()=>{
    if(loanType === 'QM'        && houseType === 'primary')       setSpIndex(0)
    if(loanType === 'QM'        && houseType === 'secondary')     setSpIndex(1)
    if(loanType === 'QM'        && houseType === 'investment')    setSpIndex(2)
    if(loanType === 'nonQM'     && houseType === 'primary')       setSpIndex(3)
    if(loanType === 'nonQM'     && houseType === 'secondary')     setSpIndex(4)
    if(loanType === 'nonQM'     && houseType === 'investment')    setSpIndex(5)
    if(loanType === 'nonQMHigh' && houseType === 'primary')       setSpIndex(6)
    if(loanType === 'nonQMHigh' && houseType === 'secondary')     setSpIndex(7)
    if(loanType === 'nonQMHigh' && houseType === 'investment')    setSpIndex(8)
  },[loanType,houseType])
  
  return (
    <React.Fragment><CssBaseline/>
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',opacity: 1, width: '50vw',height:'46.75vw' }}>
        <Box mt='3vw' width='100%'><RTableSwap    data={swapRate} _width='30%'/></Box>
        <Box mt='3vw' width='100%'><RTableSpread  data={spreadTable[spIndex]} _width='80%'/></Box>
      </Box>
    </React.Fragment>
  );
}

function RTableSwap(props) {
  const {data,_width} = props;

  const headerSwap = [
    {tag:'Loan Term'},
    {tag:'Swap Rate'}
  ]
  const Caption = 'Swap Rates'
  if(_debug === 'development') console.log("LTable Props",data,headerSwap,Caption,_width)
  return (
    <div align='center'>  
      <table style={{width:_width,borderSpacing:'1vw',border:'0.1vw solid', fontSize:'0.8vw'}}>
      <caption style={{captionSide:"top",textAlign:"center"}}>{Caption}</caption>
          <tr style={{border:'0.1vw solid'}}>
            {headerSwap.map((item)=>(<th align="center"  style={{border:'0.1vw solid'}}>{item.tag}</th>))}
          </tr>
          {data.map((item) => (
            <tr key={item.tenure} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <td align='center' style={{border:'0.1vw solid'}}>{item.tenure} years</td> 
              <td align="center" style={{border:'0.1vw solid'}}>{item.rate} % </td>
            </tr>
          ))}
      </table>
    </div>
  );

}

function RTableSpread(props) {
  const {data,_width} = props;
  const dummyArray=[1,2,3,4,5,6,7,8,9,10]
  const headerSpread = [
    {tag:'LTV 50%'},
    {tag:'LTV 60%'},
    {tag:'LTV 70%'},
    {tag:'LTV 80%'},
    {tag:'LTV 85%'},
    {tag:'LTV 90%'},
    {tag:'LTV 95%'},
  ]
  const Caption = "Spread Table for " + data.loanType + ' ' + data.homeType;
  if(_debug === 'development') console.log("LTable Props",data,Caption,_width)
  return (
    <div align='center'>
      <table style={{width:_width,borderSpacing:'1vw',border:'0.1vw solid', fontSize:'0.8vw'}}>
      <caption style={{captionSide:"top",textAlign:"center"}}>{Caption}</caption>
          <tr style={{border:'0.1vw solid'}}>
            <th align="center"  style={{border:'0.1vw solid'}}>FICO</th>
            {headerSpread.map((item,index)=>(<th key={index} align="center"  style={{border:'0.1vw solid'}}>{item.tag}</th>))}
          </tr>
          {dummyArray.map((item,index)=>(
            <tr key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <td  style={{border:'0.1vw solid'}} align="center" >{data.spArray[index*7].minFico >=800 ? 'FICO ' +data.spArray[index*7].minFico+'+': data.spArray[index*7].maxFico <=640 ? 'FICO  < ' +640: 'FICO '+data.spArray[index*7].minFico +'-' + data.spArray[index*7].maxFico}</td> 
            <td  style={{border:'0.1vw solid'}} align="center">{data.spArray[index*7+0].spread}%</td>
            <td  style={{border:'0.1vw solid'}} align="center">{data.spArray[index*7+1].spread}%</td>
            <td  style={{border:'0.1vw solid'}} align="center">{data.spArray[index*7+2].spread}%</td>
            <td  style={{border:'0.1vw solid'}} align="center">{data.spArray[index*7+3].spread}%</td>
            <td  style={{border:'0.1vw solid'}} align="center">{data.spArray[index*7+4].spread}%</td>
            <td  style={{border:'0.1vw solid'}} align="center">{data.spArray[index*7+5].spread}%</td>
            <td  style={{border:'0.1vw solid'}} align="center">{data.spArray[index*7+6].spread}%</td>
          </tr>
          ))}
      </table>
    </div>
  );

}

export default AdminInterfaceRate