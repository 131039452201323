import React from "react";
import { Box, Stack, CssBaseline, TextField, InputAdornment, IconButton, Typography, Button } from "@mui/material";
import { HexButton } from './ButtonIcons1';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { InlineWidget } from 'react-calendly';
import letstalkLeft from '../../assets/svg/assets svg/image-leftbottom03.png'
import useStyles from './styles';
import Navbar from "./NavBar";
import { BottomBox } from "./RightBlock";
import { _debug } from "../../constants";
import { display } from "@mui/system";
var allowed = "no"

function LetsTalk() {
  const navigate = useNavigate();
  const [seek, setSeek] = React.useState(allowed)
  const classes = useStyles();
  // if(_debug === 'development') allowed = 'yes'
  allowed = 'yes'

  return (
    <React.Fragment><CssBaseline />
      <Box sx={{ overflow: 'hidden', width: '100%', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <Navbar />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <LeftBlockA />

          <Box sx={{ width: '14vw', justifyContent: 'center' }}>
            <Stack sx={{ mr: '2.0vw', ml: '3.0vw', mt: '19vw', height: '10vw', width: '10vw' }} direction="row">
              <Button sx={{ border: 0, width: '8.0vw', position: 'fixed', height: '7vw' }} id="Discover" className='hexBtnCenter'
                onClick={() => allowed === 'yes' ? navigate("/EmployerPartnership", { replace: false }) : setSeek("ddd")}>
                <HexButton titleText="CLOSE" type='large' fontSize='24' />
              </Button>
            </Stack>
            {seek == 'ddd' ? <InputKey /> : <></>}
          </Box>

          <Box sx={{
            background: '#F3F3F3 0% 0% no-repeat padding-box',marginLeft:'-0.5vw',
            boxShadow: '-1px -6px 46px #0000003D', p: 0, opacity: 1, width: '51.5vw', height: '50vw'
          }}>
            {/* <img src={empright1} alt = "Loading..."  height='100%' width='100%' borderRadius='0.5vw'/> */}
            <InlineWidget sx={{ height: "100%" }} url='https://calendly.com/scottmcmunn/30min?month=2022-09' />

          </Box>


        </Box>

        {/* <Box className={classes.bottomBar}><BottomBox /></Box> */}

      </Box>
    </React.Fragment>
  );
}



function LeftBlockA() {
  return (

    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '35.5vw', height: '50vw'
    }}>
      {/* <img src={whyusleftOne} alt = "Loading..." width="100%" height="100%" /> */}
      <Box sx={{ marginLeft: '6.72vw', marginTop: '7.76vw', width: '18.89vw', height: '12.60vw', fontsize: '4.64vw', font: 'Roboto' }}>
        <Typography sx={{
          color: '#8f8f8f', fontWeight: 'bold',
          font: "Roboto", textAlign: 'left'
        }} fontSize='4.6vw' lineHeight={1.2} letterSpacing='-1.6px'>
          Let's Talk
        </Typography>
      </Box>
<Box sx={{height:'3vw'}}>

</Box>


      <Box sx={{ marginTop: '15.15vw', width: '35.55vw', height: '12.9vw' }}>


        <img src={letstalkLeft} alt = "Loading..." width="100%" height="100%" />


      </Box>

    </Box>
  );
}
function InputKey() {

  const name = 'password'
  const type = 'password'
  const [cur, setCur] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);


  function onClickFun(e) {
    const val1 = e.currentTarget.value
    val1 === "N3$tfu3L" ? allowed = 'yes' : allowed = 'no'

  };

  return (
    <div position='fixed' align='center'>
      <Typography sx={{ fontSize: '1vw', width: '12vw' }}>(Please answer the following to activae DISCOVER NESTFUEL)</Typography>
      <Typography sx={{ mt: 2, fontSize: '1vw', width: '12vw' }}></Typography>
      <TextField sx={{ fontsize: '0.5vw', mt: 1, width: '12vw', border: 1, borderColor: 'black', borderRadius: 2 }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label='No flying ends Indian penny'
        autoFocus={true}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </div>
  )
}

export default LetsTalk;