import React from "react";
import {  swapRates, 
          mortgageRatesMaster, 
          unsecureRates, 
          linkedInProfiles, 
          monthlyRentalYieldSwaprate, 
          rentalYieldMargin, 
          upfrontMargins, 
          salaryBumps, 
          appConstants, 
          percentClosingcost,
          mortgageRatesMasterQMPrimary,
          mortgageRatesMasterQMSecondary,
          mortgageRatesMasterQMInvestment,
          mortgageRatesMasterNonQMPrimary,
          mortgageRatesMasterNonQMSecondary,
          mortgageRatesMasterNonQMInvestment,
          mortgageRatesMasterNonQMHighPrimary,
          mortgageRatesMasterNonQMHighSecondary,
          mortgageRatesMasterNonQMHighInvestment,
          intR,
          terM,
          intRQM,
          intRNQM,
          employerList,
          maxUnsecEMIpercentDTI
        } from '../../constants.js';

import {  useDispatch, 
          useSelector } from 'react-redux';
import { borrowerChoiceDSF, borrowerChoiceDSFE, borrowerChoiceI } from "../../actions/borrower.js";


export function loanToEmiDefault(principal){
  const tt_1 = Math.pow((1+intR/1200),terM*12.0) 
  const emi = Math.round(principal*intR*tt_1/(1200*(tt_1-1)))
  return (emi);
}

export function emiToLoanDefault(emi){
  const tt_1 = Math.pow((1+intR/1200),terM*12.0) 
  const prince = Math.round(emi*1200*(tt_1 -1)/(intR*tt_1))
  return (prince);
}

export function loanToEmi(principal,rate,termY){
 
  const tt_1 = Math.pow((1+rate/1200),termY*12.0) 
  const emi = Math.ceil(principal*rate*tt_1/(1200*(tt_1-1)))
  return (emi);
}

export function emiToLoan(emi,rate,termY){
  const tt_1 = Math.pow((1+rate/1200),termY*12.0) 
  const prince = Math.floor(emi*1200*(tt_1 -1)/(rate*tt_1))
  return (prince);
}
export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }

  React.useEffect(() => {
    setTimeout(window.addEventListener('resize', handleResize),3000)
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
export function smartFinanceOptionRTB(bfinancialProfile,bChoiceE,bChoiceB,selectedProp) {

  const financeType = bChoiceB === "firsthome" || bChoiceB === 'nexthome' ? 'primary' : 'secondary';
  const mortgageChart = []
  const rPrem  = 3.0
  const rYeild = appConstants.rentalYield + rPrem;
  var rentPd   = Math.ceil(selectedProp.offerPrice*rYeild/1200)
  for (let ltv = 80; ltv <=95; ltv = ltv + 1.0){
    var upfrontRq = selectedProp.offerPrice*(percentClosingcost+100-ltv)/100
    var upfrontGp = upfrontRq > bChoiceE.upfront ? upfrontRq - bChoiceE.upfront : 0.0;
    var rentPer   = Math.ceil(upfrontGp/(rPrem*selectedProp.offerPrice)*12)
    var totalEMI  = rentPd
    var inBudget  = totalEMI > bChoiceE.monthlyBudget ? 'No':'Yes'
    console.log("mortgageT RTB",rentPd,rentPer)
    mortgageChart[ltv-80] = {inBudget:inBudget, rentPd:rentPd,lTV:ltv,rentPer:rentPer,totalPayout:totalEMI,propPrice:selectedProp.offerPrice}
  }
  console.log("Max mortgage",mortgageChart)
  mortgageChart.sort((a,b) => b.rentPer - a.rentPer);
  console.log("Max mortgage",mortgageChart)
  return(mortgageChart)
}


export function smartFinanceOptionPBPR(bfinancialProfile,bChoiceE,bChoiceB,selectedProp) {

  const financeType = bChoiceB === "firsthome" || bChoiceB === 'nexthome' ? 'primary' : 'secondary';
  const unsecureR = unsecureRates.filter((item)=> item.min >= bfinancialProfile.ficoScore)[0].rate;
  const mortgageChart = []
  const rYeild = appConstants.rentalYield
 
  for (let ltv = 80; ltv <=95; ltv = ltv + 1.0){
    var intR = interestRate(financeType,bChoiceE.loanTerm,'NQM',bfinancialProfile.ficoScore,ltv)
    var mortgageT = Math.ceil(selectedProp.offerPrice*ltv/100)
    var upfrontRq = selectedProp.offerPrice*(percentClosingcost+100-ltv)/100
    var maxOwnsp  = upfrontRq > bChoiceE.upfront ? Math.floor(10*upfrontRq/bChoiceE.upfront)*10 : 100.0;
    mortgageT     = 0.01*maxOwnsp*Math.ceil(selectedProp.offerPrice*ltv/100)
    var hTax      = Math.ceil(mortgageT*(100/ltv)*0.52/1200.0) // 0.52% of property value
    var hIns      = Math.ceil(mortgageT*0.50/1200.0)           // 0.5% of total outstanding
    var pmiEst    = Math.ceil(mortgageT*(ltv - 80)*0.1/1200.0) // 0.1% for each % gap betwween 80 and ltv.
    var rentP      = Math.ceil(selectedProp.offerPrice*(1-maxOwnsp)*rYeild/1200)
    var loanEMI   = Math.ceil(loanToEmi(mortgageT,intR,bChoiceE.loanTerm))
    var totalEMI  = loanEMI + hTax + hIns + pmiEst +rentP
    var inBudget  = totalEMI > bChoiceE.monthlyBudget ? 'No':'Yes'
    console.log("mortgageT PBPR",maxOwnsp,totalEMI)
    mortgageChart[ltv-80] = {inBudget:inBudget, maxOwnsp:maxOwnsp,lTV:ltv,tax:hTax,hInsurance:hIns,pmi:pmiEst,totalPayout:totalEMI,loanAmt:mortgageT}
  }
  console.log("Max mortgage",mortgageChart)
  mortgageChart.sort((a,b) => b.maxOwnsp - a.maxOwnsp);
  console.log("Max mortgage",mortgageChart)
  return(mortgageChart)

}

export function smartFinanceOptionIO(bfinancialProfile,bChoiceE,bChoiceB,selectedProp) {

    const financeType = bChoiceB === "firsthome" || bChoiceB === 'nexthome' ? 'primary' : 'secondary';
    const mortgageChart = []
    const intrPrem = 2.0;
   
    for (let ltv = 80; ltv <=95; ltv = ltv + 1.0){
      var intR = interestRate(financeType,bChoiceE.loanTerm,'NQM',bfinancialProfile.ficoScore,ltv)
      var mortgageT = Math.ceil(selectedProp.offerPrice*ltv/100)
      var loanEMIN  = Math.ceil(mortgageT*intR/1200)
      var loanEMIF  = Math.ceil(loanToEmi(mortgageT,intR+intrPrem,bChoiceE.loanTerm-3.0))
      var upfrontRq = selectedProp.offerPrice*(percentClosingcost+100-ltv)/100
      var upfrontGp = upfrontRq > bChoiceE.upfront ? upfrontRq - bChoiceE.upfront : 0.0;
      var hTax      = Math.ceil(mortgageT*(100/ltv)*0.52/1200.0) // 0.52% of property value
      var hIns      = Math.ceil(mortgageT*0.50/1200.0)           // 0.5% of total outstanding
      var pmiEst    = Math.ceil(mortgageT*(ltv - 80)*0.1/1200.0) // 0.1% for each % gap betwween 80 and ltv.
      var loanEMI   = Math.floor(loanToEmi(mortgageT,intR,bChoiceE.loanTerm))
      var totalEMI  = loanEMIN + hTax + hIns + pmiEst
      var inBudget  = upfrontGp > 0 || totalEMI ? 'No':'Yes'
      console.log("mortgageT AIO",loanEMIN,loanEMIF,totalEMI)
      mortgageChart[ltv-80] = {inBudget:inBudget,lTV:ltv,intRate:intR,upfrontGp,tax:hTax,hInsurance:hIns,pmi:pmiEst,loanEMI:loanEMI, totalPayout:totalEMI,loanAmt:mortgageT}
    }
    console.log("Max mortgage",mortgageChart)
    mortgageChart.sort((a,b) => a.upfrontGp - b.upfrontGp);
    console.log("Max mortgage",mortgageChart)
    return(mortgageChart)
  
}

export function smartFinanceOptionTrad(bfinancialProfile,bChoiceE,bChoiceB,selectedProp) {

  const financeType = bChoiceB === "firsthome" || bChoiceB === 'nexthome' ? 'primary' : 'secondary';
  const unsecureR = unsecureRates.filter((item)=> item.min >= bfinancialProfile.ficoScore)[0].rate;
  const mortgageChart = []
 
  for (let ltv = 80; ltv <=95; ltv = ltv + 1.0){
    var intR = interestRate(financeType,bChoiceE.loanTerm,'NQM',bfinancialProfile.ficoScore,ltv)
    var mortgageT = Math.ceil(selectedProp.offerPrice*ltv/100)
    var upfrontRq = mortgageT*(percentClosingcost+100-ltv)/100
    var upfrontGp = upfrontRq > bChoiceE.upfront ? upfrontRq - bChoiceE.upfront : 0.0;
    var unsecEMI  = loanToEmi(upfrontGp,unsecureR,3)
    var hTax      = Math.ceil(mortgageT*(100/ltv)*0.52/1200.0) // 0.52% of property value
    var hIns      = Math.ceil(mortgageT*0.50/1200.0)           // 0.5% of total outstanding
    var pmiEst    = Math.ceil(mortgageT*(ltv - 80)*0.1/1200.0) // 0.1% for each % gap betwween 80 and ltv.
    var loanEMI   = Math.ceil(loanToEmi(mortgageT,intR,bChoiceE.loanTerm))
    var totalEMI  = loanEMI + hTax + hIns + pmiEst + unsecEMI
    var inBudget  = totalEMI > bChoiceE.monthlyBudget ? 'No':'Yes'
    console.log("mortgageT AA",loanEMI,intR,totalEMI)
    mortgageChart[ltv-80] = {inBudget:inBudget,lTV:ltv,intRate:intR,unsecRate:unsecureR,tax:hTax,hInsurance:hIns,pmi:pmiEst,unsecEMI:unsecEMI,loanEMI:loanEMI, totalPayout:totalEMI,loanAmt:mortgageT}
  }
  console.log("Max mortgage",mortgageChart)
  mortgageChart.sort((a,b) => a.totalEMI - b.totalEMI);
  console.log("Max mortgage",mortgageChart)
  return(mortgageChart)

}

export function maxMortgageQM(monthly,upfront) {

    var mortgageT = emiToLoan(monthly,intRQM,terM)
    var upfrontReq = mortgageT*23.0/80.0
    var mortgageTQM = upfront > upfrontReq 
          ? Math.floor((mortgageT + upfrontReq*20.0/23.0 + (upfront - upfrontReq))/10)*10
          : Math.floor((upfront*100/23.0)/10)*10
    return(mortgageTQM)

}

export function MaxMortgage(bfinancialProfile,bChoiceE,bChoiceB) {
  const financeType = bChoiceB === "firsthome" || bChoiceB === 'nexthome' ? 'primary' : 'secondary';
  const unsecureR = unsecureRates.filter((item)=> item.min >= bfinancialProfile.ficoScore)[0].rate;
  const mortgageChart = []
  const maxUnsecEMI = bfinancialProfile.frontEndDTI*maxUnsecEMIpercentDTI/100;
  const maxUnsec    = Math.ceil(emiToLoan(maxUnsecEMI,unsecureR,appConstants.unsecureLoanTermInYears))
  var avaDTI    = bfinancialProfile.frontEndDTI - maxUnsecEMI;

  for (let ltv = 80; ltv <=95; ltv = ltv + 1.0){
    var intR = interestRate(financeType,bChoiceE.loanTerm,'NQM',bfinancialProfile.ficoScore,ltv)
    var mortgageT = Math.ceil(emiToLoan(avaDTI,intR,bChoiceE.loanTerm)/10)*10 + maxUnsec
    var mortgageTLimit = Math.ceil((maxUnsec + bChoiceE.upfront)*10/(percentClosingcost+100-ltv))*10
    mortgageT = Math.min(mortgageT,mortgageTLimit)
    console.log("mortgageT AA",mortgageT)
    var loanEMI = Math.floor(loanToEmi(mortgageT,intR,bChoiceE.loanTerm))
    var hTax      = Math.ceil(mortgageT*(100/ltv)*0.52/1200.0) // 0.52% of property value
    var hIns      = Math.ceil(mortgageT*0.50/1200.0)           // 0.5% of total outstanding
    var pmiEst    = Math.ceil(mortgageT*(ltv - 80)*0.1/1200.0) // 0.1% for each % gap betwween 80 and ltv.
    var upfrontRq = mortgageT*(percentClosingcost+100-ltv)/100
    var upfrontGp = upfrontRq > bChoiceE.upfront ? upfrontRq - bChoiceE.upfront : 0.0;
    var unsecEMI  = loanToEmi(upfrontGp,unsecureR,3)

    var totalPayout =  loanEMI + hTax + hIns + pmiEst + unsecEMI
    avaDTI        = totalPayout > bfinancialProfile.frontEndDTI ? bfinancialProfile.frontEndDTI - hTax - hIns -pmiEst -unsecEMI : loanEMI
    mortgageT     = Math.ceil(emiToLoan(avaDTI,intR,bChoiceE.loanTerm)/10)*10
    loanEMI       = Math.floor(loanToEmi(mortgageT,intR,bChoiceE.loanTerm))
    console.log("mortgageT BB",mortgageT)
    hTax          = Math.ceil(mortgageT*(100/ltv)*0.52/1200.0) 
    hIns          = Math.ceil(mortgageT*0.50/1200.0)           
    pmiEst        = Math.ceil(mortgageT*(ltv - 80)*0.1/1200.0)
    upfrontRq     = mortgageT*(percentClosingcost+100-ltv)/100
    upfrontGp     = upfrontRq > bChoiceE.upfront ? upfrontRq - bChoiceE.upfront : 0.0;
    unsecEMI      = loanToEmi(upfrontGp,unsecureR,3)
    totalPayout   =  loanEMI + hTax + hIns + pmiEst + unsecEMI
    mortgageChart[ltv-80] = {lTV:ltv,intRate:intR,unsecRate:unsecureR,tax:hTax,hInsurance:hIns,pmi:pmiEst,unsecEMI:unsecEMI,loanEMI:loanEMI, totalPayout:totalPayout,mortgageT:mortgageT}
  }
  //console.log("Max mortgage",mortgageChart)
  mortgageChart.sort((a,b) => b.mortgageT - a.mortgageT);
  //console.log("Max mortgage",mortgageChart)
  return(mortgageChart)
}

export function MaxMortgageEmployer(bfinancialProfile,bChoiceE,bChoiceB,Employer,PMI) {

  const financeType = bChoiceB === "firsthome" || bChoiceB === 'nexthome' ? 'primary' : 'secondary';
  const empDiscount = employerList.filter((item)  =>(item.title === Employer.title))[0]?.rateDiscount
  const unsecureR = (unsecureRates.filter((item)=> item.min >= bfinancialProfile.ficoScore)[0].rate - empDiscount).toFixed(4);
  const mortgageChart = []
  const maxUnsecEMI = bfinancialProfile.frontEndDTI*maxUnsecEMIpercentDTI/100;
  const maxUnsec    = Math.ceil(emiToLoan(maxUnsecEMI,unsecureR,appConstants.unsecureLoanTermInYears))
  var avaDTI    = bfinancialProfile.frontEndDTI - maxUnsecEMI;

  for (let ltv = 80; ltv <=95; ltv = ltv + 1.0){
    var intR = interestRate(financeType,bChoiceE.loanTerm,'NQM',bfinancialProfile.ficoScore,ltv)
    var mortgageT = Math.ceil(emiToLoan(avaDTI,intR,bChoiceE.loanTerm)/10)*10 + maxUnsec
    var mortgageTLimit = Math.ceil((maxUnsec + bChoiceE.upfront)*10/(percentClosingcost+100-ltv))*10
    mortgageT = Math.min(mortgageT,mortgageTLimit)
    console.log("mortgageT A",mortgageT)
    var loanEMI = Math.floor(loanToEmi(mortgageT,intR,bChoiceE.loanTerm))
    var hTax      = Math.ceil(mortgageT*(100/ltv)*0.52/1200.0) // 0.52% of property value
    var hIns      = Math.ceil(mortgageT*0.50/1200.0)           // 0.5% of total outstanding
    var pmiEst    = PMI ==='Yes' ?Math.ceil(mortgageT*(ltv - 80)*0.1/1200.0) : 0.0 // 0.1% for each % gap betwween 80 and ltv.
    var upfrontRq = mortgageT*(percentClosingcost+100-ltv)/100
    var upfrontGp = upfrontRq > bChoiceE.upfront ? upfrontRq - bChoiceE.upfront : 0.0;
    var unsecEMI  = loanToEmi(upfrontGp,unsecureR,3)

    var totalPayout =  loanEMI + hTax + hIns + pmiEst + unsecEMI
    avaDTI        = totalPayout > bfinancialProfile.frontEndDTI ? bfinancialProfile.frontEndDTI - hTax - hIns -pmiEst -unsecEMI : loanEMI
    mortgageT     = Math.ceil(emiToLoan(avaDTI,intR,bChoiceE.loanTerm)/10)*10
    loanEMI       = Math.floor(loanToEmi(mortgageT,intR,bChoiceE.loanTerm))
    console.log("mortgageT B",mortgageT)
    hTax          = Math.ceil(mortgageT*(100/ltv)*0.52/1200.0) 
    hIns          = Math.ceil(mortgageT*0.50/1200.0)           
    var pmiEst    = PMI ==='Yes' ?Math.ceil(mortgageT*(ltv - 80)*0.1/1200.0) : 0.0
    upfrontRq     = mortgageT*(percentClosingcost+100-ltv)/100
    upfrontGp     = upfrontRq > bChoiceE.upfront ? upfrontRq - bChoiceE.upfront : 0.0;
    unsecEMI      = loanToEmi(upfrontGp,unsecureR,3)
    totalPayout   =  loanEMI + hTax + hIns + pmiEst + unsecEMI
    mortgageChart[ltv-80] = {lTV:ltv,intRate:intR,unsecRate:unsecureR,tax:hTax,hInsurance:hIns,pmi:pmiEst,unsecEMI:unsecEMI,loanEMI:loanEMI, totalPayout:totalPayout,mortgageT:mortgageT}
  }
  //console.log("Max mortgage Employer",mortgageChart)
  mortgageChart.sort((a,b) => b.mortgageT - a.mortgageT);
  //console.log("Max mortgage Employer",mortgageChart)
  return(mortgageChart)
}



export function maxMortgageNQM90(monthly,upfront) {

  var mortgageT = emiToLoan(monthly,intRNQM,terM)
  var pmiEst = mortgageT*1.0/1080.0
  mortgageT = emiToLoan(monthly - pmiEst,intRNQM,terM)
  var upfrontReq = mortgageT*13.0/90.0
  var mortgageTNQM = upfront > upfrontReq 
        ? Math.floor((mortgageT + upfrontReq*10.0/13.0 + (upfront - upfrontReq))/10)*10
        : Math.floor((mortgageT = upfront*100/13.0)/10)*10
  return(mortgageTNQM)

}

export function maxMortgageNQM90E(monthly,upfront) {

  var mortgageT = emiToLoan(monthly,intRNQM,terM)
  var pmiEst = mortgageT*1.0/90.0
 // mortgageT = emiToLoan(monthly - pmiEst,intRNQM,terM)
  var upfrontReq = mortgageT*13.0/90.0
  var mortgageTNQM = upfront > upfrontReq 
        ? mortgageT + upfrontReq*10.0/13.0 + (upfront - upfrontReq)
        : mortgageT = upfront*100/13.0
      pmiEst = mortgageTNQM*1.0/98.0
  return({maxMortgage:Math.floor(mortgageTNQM/10)*10,pmi:Math.floor(pmiEst/10)*10})

}
export function maxMortgageNQM92(monthly,upfront) {

  var mortgageT = emiToLoan(monthly,intRNQM,terM)
  var pmiEst = mortgageT*1.2/1104.0
  mortgageT = emiToLoan(monthly - pmiEst,intRNQM,terM)
  var upfrontReq = mortgageT*11.0/92.0
  var mortgageTNQM = upfront > upfrontReq 
        ? mortgageT + upfrontReq*8.0/11.0 + (upfront - upfrontReq)
        : mortgageT = upfront*100/11.0
      pmiEst = Math.floor(mortgageT*1.2/98.0)
  return(Math.floor(mortgageTNQM))

}

export function loanParams(propPrice,upfront,monthly) {
  
  if (propPrice <=0 || upfront <=0 || monthly <=0 )
    return({message:"Error: Wrong Input Parameters,All input values should be positive numbers",
    closingCost:0,downPayment:0,minLTV:0,upfront:0,monthly:0})
  
  const closingCost = Math.ceil(0.01*percentClosingcost*propPrice);
  const downPayment = upfront >= closingCost ? upfront - closingCost : 0.0
  const minLTV = Math.floor(100 - 100*downPayment/propPrice);
  const minEmiR = 100*monthly/loanToEmiDefault(propPrice - downPayment).toFixed(2)
  
  var upfrontS = '', monthlyS = '';
  if(minEmiR <=70)        monthlyS= 'Very Low'
  else if(minEmiR <=85)   monthlyS = 'Low'
  else if(minEmiR <=100)  monthlyS = 'Adequate'
  else if(minEmiR <=120)  monthlyS = 'Sufficient';
  else                    monthlyS = 'High'
  if(minLTV >=95)         upfrontS = 'Very Low'
  else if(minLTV >=90)    upfrontS = 'Low'
  else if(minLTV >=85)    upfrontS = 'Adequate'
  else if(minLTV >=80)    upfrontS = 'Sufficient'
  else                    upfrontS = 'High'
  const message = "Success: Values are calculated at" + percentClosingcost + "% closing cost"
  return({message:message,statusCode:200,closingCost:closingCost,downPayment:downPayment,minLTV:minLTV>=50?minLTV:50,upfront:upfrontS,monthly:monthlyS})
}

export function interestRate(purchaseType,loanTerm,loanType,ficoScore,lTV) {
  var swapR =0,spread =0;
  //console.log("Interest rate input",purchaseType,loanTerm,loanType,ficoScore,lTV)
  swapR = swapRates.filter((item)=>item.term <= loanTerm)[0].swapRate
  if(swapR === -1) return({message:"no Data for the given loan term" , statusCode:404});
  switch (loanType){
    case 'QM':
      if(purchaseType === 'primary')     spread = mortgageRatesMasterQMPrimary.filter(  (item)  =>(item.ltv >= lTV && item.min >= ficoScore))[0].spread
      if(purchaseType === 'secondary')   spread = mortgageRatesMasterQMSecondary.filter((item)  =>(item.ltv >= lTV && item.min >= ficoScore))[0].spread
      if(purchaseType === 'investment')  spread = mortgageRatesMasterQMInvestment.filter((item) =>(item.ltv >= lTV && item.min >= ficoScore))[0].spread
      break;
    case 'NQM':
      if(purchaseType === 'primary')     spread = mortgageRatesMasterNonQMPrimary.filter(  (item)  =>(item.ltv >= lTV && item.min >= ficoScore))[0]?.spread
      if(purchaseType === 'secondary')   spread = mortgageRatesMasterNonQMSecondary.filter((item)  =>(item.ltv >= lTV && item.min >= ficoScore))[0]?.spread
      if(purchaseType === 'investment')  spread = mortgageRatesMasterNonQMInvestment.filter((item) =>(item.ltv >= lTV && item.min >= ficoScore))[0]?.spread
      break;
    case 'NQMH':
      if(purchaseType === 'primary')     spread = mortgageRatesMasterNonQMHighPrimary.filter(  (item)  =>(item.ltv >= lTV && item.min >= ficoScore))[0].spread
      if(purchaseType === 'secondary')   spread = mortgageRatesMasterNonQMHighSecondary.filter((item)  =>(item.ltv >= lTV && item.min >= ficoScore))[0].spread
      if(purchaseType === 'investment')  spread = mortgageRatesMasterNonQMHighInvestment.filter((item) =>(item.ltv >= lTV && item.min >= ficoScore))[0].spread
      break;
  }
  if(spread ===-1) return(-1);
  //console.log("Interest rates",swapR,spread)
  return(swapR + spread);
}



export function maxMortgageNQM92E(monthly,upfront) {

  var mortgageT = emiToLoan(monthly,intRNQM,terM)
  var pmiEst = mortgageT*1.2/92.0
  // mortgageT = emiToLoan(monthly - pmiEst,intRNQM,terM)
  var upfrontReq = mortgageT*11.0/92.0
  var mortgageTNQM = upfront > upfrontReq 
        ? mortgageT + upfrontReq*8.0/11.0 + (upfront - upfrontReq)
        : mortgageT = upfront*100/11.0
      pmiEst = mortgageT*1.2/95.0
  return({maxMortgage:Math.floor(mortgageTNQM/10)*10,pmi:Math.floor(pmiEst/10)*10})
}

export function maxMortgageNQM95(monthly,upfront) {

  var mortgageT = emiToLoan(monthly,intRNQM,terM)
  var pmiEst = mortgageT*1.5/1140.0
  mortgageT = emiToLoan(monthly - pmiEst,intRNQM,terM)
  var upfrontReq = mortgageT*8.0/95.0
  var mortgageTNQM = upfront > upfrontReq 
        ? mortgageT + upfrontReq*5.0/8.0 + (upfront - upfrontReq)
        : mortgageT = upfront*100/8.0
      pmiEst = Math.floor(mortgageT*1.5/98.0)
  return(Math.floor(mortgageTNQM))

}

export function maxMortgageNQM95E(monthly,upfront) {

  var mortgageT = emiToLoan(monthly,intRNQM,terM)
  var pmiEst = mortgageT*1.5/95.0
  // mortgageT = emiToLoan(monthly - pmiEst,intRNQM,terM)
  var upfrontReq = mortgageT*8.0/95.0
  var mortgageTNQM = upfront > upfrontReq 
        ? mortgageT + upfrontReq*5.0/8.0 + (upfront - upfrontReq)
        : mortgageT = upfront*100/8.0
      pmiEst = mortgageT*1.5/95.0
  return({maxMortgage:Math.floor(mortgageTNQM/10)*10,pmi:Math.floor(pmiEst/10)*10})
}

export function estimateBorrowingParams(  loanTerm,
                                          purchaseType,
                                          propPrice,
                                          genProfile,
                                          finProfile,
                                          upfront,
                                          monthly){

  const loanP = loanParams(propPrice,upfront,monthly)
  var swapR,spread,loanType;
  var errorMessage = '';

  if (  genProfile.residency          === "citizen" 
        && genProfile.employmentType  === "Salaried"
        && finProfile.dPStubs         === "true"
        && finProfile.bankruptcy      === "Never" ) loanType = 'QM';
  else if ( finProfile.ficoScore >=700) loanType = 'NQM';
  else loanType = 'NQMH'
  
  swapR = swapRates.map((item)=>(item.term ===loanTerm? item.swapRate:-1))
  if(swapR === -1) return({message:"no Data for the given loan term" , statusCode:404});
  switch (loanType){
    case 'QM':
      if(purchaseType === 'primary')     spread = mortgageRatesMasterQMPrimary.map(  (item)  =>(item.ltv >= loanP.minLTV && item.min >= finProfile.ficoScore ? item.spread:-1))
      if(purchaseType === 'secondary')   spread = mortgageRatesMasterQMSecondary.map((item)  =>(item.ltv >= loanP.minLTV && item.min >= finProfile.ficoScore ? item.spread:-1))
      if(purchaseType === 'investment')  spread = mortgageRatesMasterQMInvestment.map((item) =>(item.ltv >= loanP.minLTV && item.min >= finProfile.ficoScore ? item.spread:-1))
      break;
    case 'NQM':
      if(purchaseType === 'primary')     spread = mortgageRatesMasterNonQMPrimary.map(  (item)  =>(item.ltv >= loanP.minLTV && item.min >= finProfile.ficoScore ? item.spread:-1))
      if(purchaseType === 'secondary')   spread = mortgageRatesMasterNonQMSecondary.map((item)  =>(item.ltv >= loanP.minLTV && item.min >= finProfile.ficoScore ? item.spread:-1))
      if(purchaseType === 'investment')  spread = mortgageRatesMasterNonQMInvestment.map((item) =>(item.ltv >= loanP.minLTV && item.min >= finProfile.ficoScore ? item.spread:-1))
      break;
    case 'NQMH':
      if(purchaseType === 'primary')     spread = mortgageRatesMasterNonQMHighPrimary.map(  (item)  =>(item.ltv >= loanP.minLTV && item.min >= finProfile.ficoScore ? item.spread:-1))
      if(purchaseType === 'secondary')   spread = mortgageRatesMasterNonQMHighSecondary.map((item)  =>(item.ltv >= loanP.minLTV && item.min >= finProfile.ficoScore ? item.spread:-1))
      if(purchaseType === 'investment')  spread = mortgageRatesMasterNonQMHighInvestment.map((item) =>(item.ltv >= loanP.minLTV && item.min >= finProfile.ficoScore ? item.spread:-1))
      break;
  }
  if(spread ===-1) return(-1);
  console.log("EEEEE",swapR,spread)
  return(swapR + spread);
}


// export function getLocation(){
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(displayLocation, showError, options);
//   } else {
//     console.log("Error")
//   }
// }


export function CalcPropfinOptions(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE,bChoiceIone,bChoiceItwo) {

      const dispatch = useDispatch();
      for(let i=0;i< bChoiceD.length;i=i+2){
        var index = i === bChoiceD.length-1?i-1:i
        var propFinRequest = {
                selectedPropertyPrice   : bChoiceD[index].offerPrice,
                proposedPropertyPrice   : bChoiceD[index+1].offerPrice,
                financeType             : bChoiceB === 'firsthome' || bChoiceB === 'nexthome' ? 'primary' : 'secondary',
                loanTerm                : bChoiceE.loanTerm,
                creditScore             : bfinancialProfile.ficoScore,
                annualIncome            : bfinancialProfile.annualIncome,
                upfront                 : bChoiceE.upfront,
                financeDocs             : bfinancialProfile.financeDocs,
                creditEvents            : bChoiceE.creditEvents,
                houseEvents             : bChoiceE.creditEvents,
                monthlyOutgoing         : bfinancialProfile.monthlyOutgoing,
                monthlyBudget           : bChoiceE.monthlyBudget,
                emailId                 : 'amitkd4@gmail.com'
              } ; 
        dispatch(borrowerChoiceI(propFinRequest))
        dispatch(borrowerChoiceDSF(index,bChoiceIone,bChoiceItwo))
      }
  }

  export function CalcPropfinOptionsEmp(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE,bChoiceIone,bChoiceItwo) {

    const dispatch = useDispatch();
    for(let i=0;i< bChoiceD.length;i=i+2){
      var index = i === bChoiceD.length-1?i-1:i
      var propFinRequest = {
              selectedPropertyPrice   : bChoiceD[index].offerPrice,
              proposedPropertyPrice   : bChoiceD[index+1].offerPrice,
              financeType             : bChoiceB === 'firsthome' || bChoiceB === 'nexthome' ? 'primary' : 'secondary',
              loanTerm                : bChoiceE.loanTerm,
              creditScore             : bfinancialProfile.ficoScore,
              annualIncome            : bfinancialProfile.annualIncome,
              upfront                 : bChoiceE.upfront,
              financeDocs             : bfinancialProfile.financeDocs,
              creditEvents            : bChoiceE.creditEvents,
              houseEvents             : bChoiceE.creditEvents,
              monthlyOutgoing         : bfinancialProfile.monthlyOutgoing,
              monthlyBudget           : bChoiceE.monthlyBudget,
              emailId                 : 'amitkd4@gmail.com'
            } ; 
      dispatch(borrowerChoiceI(propFinRequest))
      dispatch(borrowerChoiceDSFE(index,bChoiceIone,bChoiceItwo))
    }
}