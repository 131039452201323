import React from "react";
import { Box, CssBaseline, Typography, Button,Stack} from "@mui/material";
import { useDispatch, useSelector} from 'react-redux';
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate,useLocation } from 'react-router-dom';
import {borrowerChoiceB} from "../../actions/borrower";
import leftbottom from '../../assets/svg/assets svg/image-leftbottom02.png'
import { RightBlockA,LeftbottomA, Centerbottom } from "./RightBlock";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { _debug } from "../../constants";
import { getAnalytics, logEvent } from "firebase/analytics";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createBreakpoints } from "@mui/system";
import { rspvw } from "./styles";

export function PropertyType() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { bChoiceB } = useSelector((state)=>state.borrower)
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 
  const analytics = getAnalytics();
  const { bChoiceA
  } = useSelector((state)=>state.borrower);
  logEvent(analytics, 'propertyType', { name: 'propertyType'});

  const breakpoints = createBreakpoints({});
  const theme = useTheme();
  const matchesLG = useMediaQuery(theme.breakpoints.only('lg'));
  console.log("hiiLGmatches",matchesLG)

  const matchesXL = useMediaQuery(theme.breakpoints.only('xl'));
  console.log("hiiXLmatches",matchesXL)

  const matches = useMediaQuery(theme.breakpoints.only('sm'));
  console.log("hiiSMALLmatches",matches)

  const matchesES = useMediaQuery(theme.breakpoints.only('xs'));
  console.log("hiiEXTRASMALLmatches",matchesES)

  const matchesMD = useMediaQuery(theme.breakpoints.only('md'));
  console.log("hiiMEDIUMmatches",matchesMD)

  function onClickFun(e){
      e.preventDefault();
      const buttonId = e.currentTarget.id;
        switch(buttonId) {
          case "First": dispatch(borrowerChoiceB("firsthome"));break;
          case "Next": dispatch(borrowerChoiceB("nexthome"));break;
          case "Invest": dispatch(borrowerChoiceB("investment")); break;
          case "Holiday":dispatch(borrowerChoiceB("holidayhome"));break;
          default:break;
        }
        navigate("/SearchProperty", { replace: false });
  }

  return(
    <React.Fragment>
    <CssBaseline/>
    <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden',height:'100%',width: '100%',
    background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
      <Navbar pathname={location.pathname}/>
        <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
          { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}

          <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
            <div align='center' position='relative'>
              {
                bChoiceA === "refinance" ? <Typography sx={{color:'#000000',mt:'11.5vw',fontSize:'1.4vw', textAlign:'center'}}> 
                Refinance my</Typography>
                : <Typography sx={{color:'#000000',mt:'11.5vw',fontSize:'1.4vw', textAlign:'center'}}> 
                Finance my</Typography>
              }
                <Stack sx={{mt:'2vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}}id="First"  className='hexBtnCenter'
                       onClick={onClickFun}> 
                        <HexButton titleText= {bChoiceA === "refinance" ?"Principal Home" :"First Home"} type={ bChoiceB ==='firsthome' ? 'visited': 'base'} fontSize='24'/></Button>
                </Stack>
                <Stack sx={{mt:'2.25vw',height:'3.5vw',width:'12vw'}} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}}id="Next" className='hexBtnCenter'
                      onClick={onClickFun}> 
                      <HexButton titleText={bChoiceA === "refinance" ?"Second Home" :"Next Home"} type={bChoiceB ==='nexthome' ? 'visited':'base'} fontSize='24'/></Button>
                      <Button sx={{ overflow:'hidden', textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}}id="Invest" className='hexBtnCenter'
                      onClick={onClickFun}> 
                      <HexButton titleText="Investment Property" type={bChoiceB ==='invest' ? 'visited':'base'} fontSize='24'/></Button>
                </Stack> 
                {  bChoiceA === "refinance" 
                ? <></>
                :<Stack sx={{mt:'2.25vw',height:'3.5vw', width:'6.5vw'}} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id="Holiday" className='hexBtnCenter'
                      onClick={onClickFun}> 
                      <HexButton titleText="Holiday Home/Other" type={bChoiceB ==='holiday' ? 'visited':'base'} fontSize='24'/></Button>
                </Stack>
                }
                <Centerbottom navigateForward='SearchProperty'/>
                
            </div>
                  
          </Box>

          <RightBlockA/>
      </Box>
    </Box>
    </React.Fragment>
  );
}


function LeftBlock() {
  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
    boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
      <Box sx={{ mt:'6.4vw' , ml: '7.3vw', width: '22vw',height:'25vw' }}> 
            <Typography sx={{color:'#31312C',
             fontSize:'5.21vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '-0.24vw'}}> 
                 Simpler Cheaper Smarter
            </Typography>
        </Box>  
          <LeftbottomA leftbottom={leftbottom}/>
    </Box>
  );
}

export default PropertyType;