import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import calender from '../../assets/svg/ios-calendar-outline.svg'
import bLikeGrey from '../../assets/svg/bxs-like.svg'
import pptOpen from '../../assets/ppt-open.png'
import viewfilledGrey from '../../assets/svg/view-filled.svg'
import { rspvw } from './styles';
import { borderColor } from '@mui/system';
function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData('How to improve your credit score?', '1 Jan 2023', '1,450', 67, 4.3),
  createData('Nestfuel Employer Assisted Homeownership', '12 Jan 2023', '1,250', 51, 4.9),
  createData('Should you rent or buy?', '14 Jan 2023', '1,150', 24, 6.0),
  createData('What do you need to get a mortgage?', '1 Feb 2023', '1,350', 24, 4.0),
  createData('What are the benefits available to First time home buyers?', '5 Feb 2023', '1,850', 49, 3.9),
  createData('Is it a good time to buy a house?', '9 March 2023', '1,500', 87, 6.5),
  createData('Why are mortgages rates so high?', '3 Jan 2023', '1,700', 37, 4.3),
  createData('Top tips when buying a house', '19 Jan 2023', '1,250', 94, 0.0),
  createData('How much down payment do I need to buy?', '28 Jan 2023', '1,223', 65, 7.0),
  createData('What is the difference between mortgage interest rate and APR?', '5 Feb 2023','1,111', 98, 0.0),
  createData('Which government assisted mortgages are available to me?', '9 Feb 2023', '1,500', 81, 2.0),
  createData('Timeline for buying a home with a mortgage', '11 Jan 2023', '2,222', 9, 37.0),
  createData('Fixed rate mortgage vs ARM -how to choose?', '11 March 2023', '3,000', 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Presentation',
  },
  {
    id: 'calories',
    numeric: true,
    disablePadding: false,
    label: 'Date Completed',
  },
  {
    id: 'fat',
    numeric: true,
    disablePadding: false,
    label: 'Views',
  },
  {
    id: 'carbs',
    numeric: true,
    disablePadding: false,
    label: 'Likes',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ml:`${rspvw(37)}`}}>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ml:`${rspvw(57)}`,  mt: `${rspvw(65)}`}}
            >
             
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))} */}


                <TableCell hover align="left" sx={{backgroundColor:''}}>
               
                  <Typography sx={{ml: `${rspvw(37)}` , mr: '0vw' , mb: '0vw', fontWeight: 'Bold', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(22)}` }}>
                    Presentation</Typography>
                  </TableCell>
                <TableCell align="left" sx={{ml:`${rspvw(0)}`}}>
                <Typography sx={{ml: `${rspvw(57)}` , mr: '0vw' , mb: '0vw', fontWeight: 'medium ', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(17)}` }}>
                  Date Completed
                  </Typography>
                  </TableCell>
                <TableCell align="left">
                <Typography sx={{ml: `${rspvw(57)}` , mr: '0vw' , mb: '0vw', fontWeight: 'medium ', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(17)}` }}>
                  Views
                  </Typography>
                </TableCell>
                <TableCell align="left">
                <Typography sx={{ml: `${rspvw(57)}` , mr: '0vw' , mb: '0vw', fontWeight: 'medium ', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(17)}` }}>
                  Likes
                  </Typography>
                  </TableCell>
         
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

 function AnalyticResourceOneNew() {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', boxShadow: "none" }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer sx={{border:"none"}}>
          <Table
            sx={{ minWidth: 750 ,[`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            }}}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      sx={{ml:`${rspvw(370)}`, opacity: 1,ml:`${rspvw(0.1)}` }}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="left"
                        padding="none"
                        borderBottom="none"
                        sx={{mt: `${rspvw(29.19)}`}}
                      >
                         <Stack direction="row" >
                         <Box sx={{ mr: `${rspvw(0.63)}`, ml: `${rspvw(37)}`, mb: `${rspvw(38.4)}`, width: `${rspvw(14.5)}`, height: `${rspvw(13.9)}` }}>
                                <img src={pptOpen} alt = "Loading..." width="100%" heigh="100%" />
                              </Box>
                        <Box sx={{ml:`${rspvw(57.6)}` , mb:'0vw'}}>
                        <Typography sx={{ mr: '0vw', ml: `${rspvw(-38.4)}` , mb: '0vw', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(18.2)}` }}>
                        {row.name}
                       </Typography>   
                        </Box>
                        </Stack>
                      </TableCell>

                      <TableCell align="center">
                      <Typography sx={{ mr: '0vw', ml: '0vw', mb: `${rspvw(30)}`, fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: `${rspvw(16)}` }}>
                        {row.calories}
                        </Typography>
                        
                        </TableCell>
                      <TableCell align="center"  sx={{mt: `${rspvw(29.19)}`}}>
                       
                        
                        <Stack direction="row">
                         <Box sx={{ mr: `${rspvw(12.1)}`, ml: `${rspvw(57.6)}`, mt: `${rspvw(-0.7)}`, mb: `${rspvw(38.4)}`, width: `${rspvw(17.77)}`, height:  `${rspvw(13.03)}` }}>
                                <img src={viewfilledGrey} alt = "Loading..." width="100%" heigh="100%" />
                              </Box>
                        <Box sx={{ml:`${rspvw(5.3)}` , mb:'0vw'}}>
                       
                        <Typography sx={{ mr: '0vw', ml: `${rspvw(-1.5)}`, mt:'0vw' , mb: '0vw', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: `${rspvw(16)}` }}>
                        {row.fat}
                       </Typography>
                             
                        </Box>
                        </Stack>

                        </TableCell>
                      <TableCell align="center"  sx={{mt: `${rspvw(29.19)}`}}>
                        
                      <Stack direction="row">
                         <Box sx={{ mr: `${rspvw(12.1)}`, ml: `${rspvw(57.6)}`, mt: '0.0vw', mb: `${rspvw(38.4)}`, width: `${rspvw(14.5)}`, height: `${rspvw(13.9)}` }}>
                                <img src={bLikeGrey} alt = "Loading..." width="100%" heigh="100%" />
                              </Box>
                        <Box sx={{ml:`${rspvw(38.4)}` , mb:'0vw'}}>
                       
                        <Typography sx={{ mr: '0vw', ml: `${rspvw(-30)}`, mt:`${rspvw(3)}` , mb: '0vw', fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: `${rspvw(16)}` }}>
                        {row.carbs}
                       </Typography>
                             
                        </Box>
                        </Stack>
                        
                        
                        </TableCell>
                     
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box>
        <TablePagination
   sx={{
      backgroundColor: '', // gets overridden if not important
      height: '65px',
     
      '.MuiInputBase-root': {
        backgroundColor: '',
        mb:'0.9vw'
      },
      '.MuiSvgIcon-root': {
        backgroundColor: '',
        mb:'0.9vw'
      },
      '.MuiTablePagination-toolbar': {
        backgroundColor: '',
        width: '54.70vw',
        color: 'rgb(41, 39, 39)',
        height: '35px',
      },
      '.MuiBox-root': {
        backgroundColor: 'yellow',
        color: 'black',

        '& .MuiSvgIcon-root': {
          backgroundColor: 'purple',
          color: 'black',
        },
      },
    }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Box>
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}
export default AnalyticResourceOneNew;
