import React from "react";
import { Box, Stack, CssBaseline, TextField, InputAdornment, IconButton, Typography, Button } from "@mui/material";
import { HexButton } from './ButtonIcons1';
import { useNavigate } from 'react-router-dom';
import { getWindowDimensions } from '../../computations/helperFunctions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import whyusleftOne from '../../assets/staticPageAssets/whyus-left-1.png'
import whyusrightOne from '../../assets/staticPageAssets/whyus-right-1.png'

import whyusleftTwo from '../../assets/staticPageAssets/whyus-left-2.png'
import whyusrightTwo from '../../assets/staticPageAssets/whyus-right-2.gif'

import whyusleftThree from '../../assets/staticPageAssets/whyus-left-3.png'
import whyusrightThree from '../../assets/staticPageAssets/whyus-right-3.png'

import useStyles from './styles';
import Navbar from "./NavBar";
import { BottomBox } from "./RightBlock";
import { _debug } from "../../constants";
var allowed = "no"

function WhyUs() {
  const navigate = useNavigate();
  const [hover, setHover] = React.useState(0);
  const { height, width } = getWindowDimensions();
  const [seek, setSeek] = React.useState(allowed)
  const classes = useStyles();
  // if(_debug === 'development') allowed = 'yes'
  allowed = 'yes'
  return (
    <React.Fragment><CssBaseline />
      <Box sx={{ overflow:'hidden', width: '100vw', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <Navbar />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <LeftBlockA />

          <Box sx={{ width: '14vw', justifyContent: 'center' }}>
            <Stack sx={{ mr: '2.0vw', ml: '3.0vw', mt: '19vw', height: '10vw', width: '10vw' }} direction="row">
              <Button sx={{ border: 0, width: '8.0vw', position: 'fixed', height: '7vw' }} id="Discover" className='hexBtnCenter'
                onClick={() => allowed === 'yes' ? navigate("/Discover", { replace: false }) : setSeek("ddd")}>
                <HexButton titleText="Discover Nestfuel" type='large' fontSize='24' />
              </Button>
            </Stack>
            {seek == 'ddd' ? <InputKey /> : <></>}
          </Box>

          <RightBlockA />
        </Box>
      <Box id="WhyUs" sx={{mt:1,width: '100vw',height:'50vw'}}>
        <Stack spacing={0} direction="row">
          <Box sx={{width: '36vw' }}>
            <img src={whyusleftTwo} alt = "Loading..." width="100%" heigh="100%" />
          </Box>
          <Box sx={{ ml: '14vw',width:'50vw',height:'60vw' }}>
            <img src={whyusrightTwo} alt = "Loading..." width="100%" heigh="100%" />
          </Box>
        </Stack>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <LeftBlockB />
        <Box sx={{ width: '14vw', justifyContent: 'center' }}></Box>
        <RightBlockB />
      </Box>




      <Box mt='0.2vw'>
        <BottomBox />
      </Box>

      </Box>
    </React.Fragment>
  );
}

function RightBlockA() {
  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '-1px -6px 46px #0000003D', opacity: 1, width: '50vw', height: '50vw'
    }}>
          <img src={whyusrightOne} alt = "Loading..." width="100%" height="100%" />
    </Box>
  );
}


function RightBlockB() {
  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '-1px -6px 46px #0000003D', opacity: 1, width: '50vw', height: '50vw'
    }}>
        <img src={whyusrightThree} alt = "Loading..." width="100%" height="100%" />
    </Box>
  );
}



function LeftBlockA() {
  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '50vw'
    }}>
        <img src={whyusleftOne} alt = "Loading..." width="100%" height="100%" />

    </Box>
  );
}

function LeftBlockB() {
  return (
    <Box id="Meaning" sx={{ background: '#F3F3F3 0% 0% no-repeat padding-box', 
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '50vw' }}>
        <img src={whyusleftThree} alt = "Loading..." width="100%" height="100%" />
    </Box>
  );
}



function InputKey() {

  const name = 'password'
  const type = 'password'
  const [cur, setCur] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);


  function onClickFun(e) {
    const val1 = e.currentTarget.value
    val1==="N3$tfu3L"? allowed = 'yes' : allowed = 'no'

  };

  return (
    <div position='fixed' align='center'>
      <Typography sx={{ fontSize: '1vw', width: '12vw' }}>(Please answer the following to activae DISCOVER NESTFUEL)</Typography>
      <Typography sx={{ mt: 2, fontSize: '1vw', width: '12vw' }}></Typography>
      <TextField sx={{ fontsize: '0.5vw', mt: 1, width: '12vw', border: 1, borderColor: 'black', borderRadius: 2 }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label='No flying ends Indian penny'
        autoFocus={true}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </div>
  )
}

export default WhyUs;