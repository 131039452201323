import React from "react";
import {
  Box,
  Stack,
  CssBaseline,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { HexButton } from "./ButtonIcons1";
import { useNavigate } from "react-router-dom";
import { getWindowDimensions } from "../../computations/helperFunctions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import plus from "../../assets/svg/plus.svg";
import less from "../../assets/svg/less.svg";
import whyusleftOne from "../../assets/staticPageAssets/whyus-left-1.png";

import whyusleftTwo from "../../assets/staticPageAssets/whyus-left-2.png";
import whyusrightTwo from "../../assets/staticPageAssets/whyus-right-2.gif";

import whyusleftThree from "../../assets/staticPageAssets/whyus-left-3.png";
import whyusrightThree from "../../assets/staticPageAssets/whyus-right-3.png";
import hexaOne from "../../assets/staticPageAssets/whyus1a.png";
import hexaTwoFade from "../../assets/staticPageAssets/whyus1b.png";
import hexaThreeFade from "../../assets/staticPageAssets/whyus1c.png";
import hexaBig1 from "../../assets/staticPageAssets/big-1.png";
import hexaBig2 from "../../assets/staticPageAssets/big-2.png";
import hexaBig3 from "../../assets/staticPageAssets/big-3.png";
import hexaMedium1 from "../../assets/staticPageAssets/medium-1.png";
import hexaMedium2 from "../../assets/staticPageAssets/medium-2.png";
import hexaMedium3 from "../../assets/staticPageAssets/medium-3.png";
import hexaSmall1 from "../../assets/staticPageAssets/small-1.png";
import hexaSmall2 from "../../assets/staticPageAssets/small-2.png";
import hexaSmall3 from "../../assets/staticPageAssets/small-3.png";
import bullet from '../../assets/staticPageAssets/polygon.png'

import useStyles from "./styles";
import Navbar from "./NavBar";
import { BottomBox } from "./RightBlock";
import { _debug } from "../../constants";
import { display } from "@mui/system";
var allowed = "no";

function WhyUs() {
  const navigate = useNavigate();
  const [hover, setHover] = React.useState(0);
  const [viewOne, setViewOne] = React.useState(false);
  const [viewTwo, setViewTwo] = React.useState(false);
  const [viewThree, setViewThree] = React.useState(false);

  const { height, width } = getWindowDimensions();
  const [seek, setSeek] = React.useState(allowed);
  const classes = useStyles();
  // if(_debug === 'development') allowed = 'yes'
  allowed = "yes";
  const handleClick = (index) => {
    if (index === 1) {
      setViewOne(true);
    }
    if (index === 2) {
      setViewTwo(true);
    }
    if (index === 3) {
      setViewThree(true);
    }
  };

  const handleCollapse = (index) => {
    if (index === 1) {
      setViewOne(false);
    }
    if (index === 2) {
      setViewTwo(false);
    }
    if (index === 3) {
       setViewThree(false);
    }
   
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        sx={{
          overflow: "hidden",
          width: "100%",
          background:
            "transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box",
        }}
      >
        <Navbar />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LeftBlockA />

          <Box sx={{ width: "14vw", justifyContent: "center" }}>
            <Stack
              sx={{
                mr: "2.0vw",
                ml: "3.0vw",
                mt: "19vw",
                height: "10vw",
                width: "10vw",
              }}
              direction="row"
            >
              <Button
                sx={{
                  overflow: "hidden",
                  mt: 0,
                  zIndex: 1,
                  border: 0,
                  width: "8.0vw",
                  position: "fixed",
                  height: "10vw",
                  minHeight: 0,
                  minWidth: 0,
                  padding: "0.2vw",
                }}
                id="Discover"
                className="hexBtnCenter"
                onClick={() =>
                  allowed === "yes"
                    ? navigate("/Discover", { replace: false })
                    : setSeek("ddd")
                }
              >
                <HexButton
                  titleText="Discover Nestfuel"
                  type="large"
                  fontSize="24"
                />
              </Button>
            </Stack>
            {seek == "ddd" ? <InputKey /> : <></>}
          </Box>

          <RightBlockA />
        </Box>
        <Box id="WhyUs" sx={{ mt: 1, width: "100vw", height: "50vw" }}>
          <Stack spacing={0} direction="row">
            <Box sx={{ width: "36vw" }}>
              {/* <img src={whyusleftTwo} alt = "Loading..." width="100%" heigh="100%" /> */}
              <Box
                sx={{
                  marginLeft: "7.19vw",
                  marginTop: "3.37vw",
                  marginRight: "14.38vw",
                  width: "14.95vw",
                  height: "6vw",
                }}
              >
                <Typography
                  sx={{ color: "#000", font: "Roboto", textAlign: "left" }}
                  fontSize="4.64vw"
                  lineHeight={1.2}
                  letterSpacing="-1.6px"
                >
                  Why us
                </Typography>
              </Box>
              <Box
                sx={{
                  marginLeft: "7.29vw",
                  marginTop: "3.70vw",
                  marginRight: "4.90vw",
                  width: "23.85vw",
                  height: "19.95vw",
                }}
              >
                <Typography
                  sx={{
                    mt: "2vw",
                    color: "#8f8f8f",
                    font: "Roboto",
                    textAlign: "left",
                  }}
                  fontSize="2.2vw"
                  lineHeight={1.2}
                  letterSpacing="0px"
                  fontWeight="300"
                >
                  Bringing technology &
                </Typography>
                <Typography
                  sx={{
                    mt: "2vw",
                    color: "#8f8f8f",
                    font: "Roboto",
                    textAlign: "left",
                  }}
                  fontSize="2.2vw"
                  lineHeight={1.2}
                  letterSpacing="0px"
                  fontWeight="300"
                >
                  {" "}
                  financing innovation
                </Typography>
                <Typography
                  sx={{
                    mt: "2vw",
                    color: "#8f8f8f",
                    font: "Roboto",
                    textAlign: "left",
                  }}
                  fontSize="2.2vw"
                  lineHeight={1.2}
                  letterSpacing="0px"
                  fontWeight="300"
                >
                  to enhance your
                </Typography>
                <Typography
                  sx={{
                    mt: "2vw",
                    color: "#8f8f8f",
                    font: "Roboto",
                    textAlign: "left",
                  }}
                  fontSize="2.2vw"
                  lineHeight={1.2}
                  letterSpacing="0px"
                  fontWeight="300"
                >
                  purchasing
                </Typography>
                <Typography
                  sx={{
                    mt: "2vw",
                    color: "#8f8f8f",
                    font: "Roboto",
                    textAlign: "left",
                  }}
                  fontSize="2.2vw"
                  lineHeight={1.2}
                  letterSpacing="0px"
                  fontWeight="300"
                >
                  power
                </Typography>
              </Box>
            </Box>
            <Box sx={{ ml: "14vw", width: "50vw", height: "60vw" }}>
              {/* Right Content should be here */}
              {/* <img src={whyusrightTwo} alt = "Loading..." width="100%" heigh="100%" /> */}
              {viewOne ? (
                <Box
                  sx={{
                    marginLeft: "4.45vw",
                    marginTop: "11.45vw",
                    width: "42.24vw",
                    height: "28.85vw",
                    background: "#515151",
                    borderRadius: "1.04vw",
                    position: "relative",
                  }}
                  onMouseLeave={() => handleCollapse(1)}
                >
                  <Box
                    sx={{
                      marginLeft: "5.73vw",
                      marginTop: "3.13vw",
                      width: "45.51vw",
                      height: "2.71vw",
                      display: "flex",
                      position: "absolute",
                    }}
                  >
                    <Box sx={{ width: "32.76vw", height: "2.71vw"}}>
                      <Typography
                        sx={{
                          mt: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontSize:"2.03vw",
                          fontWeight:"medium"
                        }}
                      >
                        INNOVATIVE  FINANCING
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "18.9vw",
                        marginTop: "0vw",
                        width: "2.51vw",
                        height: "1vw",
                      }}
                     
                    >
                      <img src={less} alt = "Loading..." width="100%" heigh="100%" />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      marginLeft: "5.86vw",
                      marginTop: "8.44vw",
                      width: "32.79vw",
                      height: "17.55vw",
                      position: "absolute",
                      dispaly: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        width: "32.79vw",
                        height: "17.55vw",
                      }}
                    >
                      <Box
                        sx={{
                          width: "1.09vw",
                          height: "1.30vw",
                          dispaly: "flex",
                        }}
                      >
                          <img src={bullet} alt = "Loading..." width="100%" heigh="100%" />
                      </Box>
                      <Box
                        sx={{
                          marginTop: "3.70vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bullet} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                      <Box
                        sx={{
                          marginTop: "3.70vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bullet} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                      <Box
                        sx={{
                          marginTop: "3.70vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bullet} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                    </Box>
                    {/* <Box sx={{width:'1.09vw',height:'1.30vw'}}>
  </Box> */}
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "-17.55vw",
                        width: "29.19",
                        height: "3vw",
                      
                      }}
                    >
                      <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                       Tailor financing for you the person not just a general mortgage for anyone
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "2vw",
                        width: "29.19",
                        height: "5vw",
                        
                      }}
                    >
                           <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                     Utilize broader solutions considering you and your needs 
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "0vw",
                        width: "29.19",
                        height: "5vw",
                      }}
                    >
                       <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                     Design and create wider choices with you front and foremost
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "0vw",
                        width: "29.19",
                        height: "5vw",
                      }}
                    >
                       <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                   Optimizing to deliver rate, amount, and experience meeting your goals
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )
              
               :
               viewTwo ? (
                <Box
                  sx={{
                    marginLeft: "4.45vw",
                    marginTop: "11.45vw",
                    width: "42.24vw",
                    height: "28.85vw",
                    background: "#515151",
                    borderRadius: "1.04vw",
                    position: "relative",
                  }}
                  onMouseLeave={() => handleCollapse(2)}
                >
                  <Box
                    sx={{
                      marginLeft: "5.73vw",
                      marginTop: "3.13vw",
                      width: "36.51vw",
                      height: "2.71vw",
                      display: "flex",
                      position: "absolute",
                    }}
                  >
                    <Box sx={{ width: "11.77vw", height: "2.71vw" }}>
                      <Typography
                        sx={{
                          mt: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                        }}
                        fontSize="2.03vw"
                        fontWeight="medium"
                      >
                       TECHNOLOGY
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "18.9vw",
                        marginTop: "0vw",
                        width: "2.51vw",
                        height: "1vw",
                      }}
                      
                    >
                      <img src={less} alt = "Loading..." width="100%" heigh="100%" />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      marginLeft: "5.86vw",
                      marginTop: "8.44vw",
                      width: "32.79vw",
                      height: "17.55vw",
                      position: "absolute",
                      dispaly: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        width: "32.79vw",
                        height: "17.55vw",
                      }}
                    >
                      <Box
                        sx={{
                          width: "1.09vw",
                          height: "1.30vw",
                          dispaly: "flex",
                        }}
                      >
                          <img src={bullet} alt = "Loading..." width="100%" heigh="100%" />
                      </Box>
                      <Box
                        sx={{
                          marginTop: "1.88vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bullet} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                      <Box
                        sx={{
                          marginTop: "4vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bullet} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                      <Box
                        sx={{
                          marginTop: "3.5vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bullet} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                    </Box>
                    {/* <Box sx={{width:'1.09vw',height:'1.30vw'}}>
  </Box> */}
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "-17.55vw",
                        width: "30.5",
                        height: "3vw",
                      
                      }}
                    >
                      <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                       Automation of smart home financing
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "0.5vw",
                        width: "30.5",
                        height: "5vw",
                        
                      }}
                    >
                           <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                       Use leading available technology and trusted partners
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "0vw",
                        width: "35.5",
                        height: "5vw",
                      }}
                    >
                       <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                   Integrated single platform solution
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "0vw",
                        width: "35.5",
                        height: "5vw",
                      }}
                    >
                       <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                    Embed and use data and price feeds to create customized outcomes
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ):
              viewThree ? (
                <Box
                  sx={{
                    marginLeft: "4.45vw",
                    marginTop: "11.45vw",
                    width: "42.24vw",
                    height: "28.85vw",
                    background: "#515151",
                    borderRadius: "1.04vw",
                    position: "relative",
                  }}
                  onMouseLeave={() => handleCollapse(3)}
                >
                  <Box
                    sx={{
                      marginLeft: "5.73vw",
                      marginTop: "3.13vw",
                      width: "36.51vw",
                      height: "2.71vw",
                      display: "flex",
                      position: "absolute",
                    }}
                  >
                    <Box sx={{ width: "11.77vw", height: "2.71vw" }}>
                      <Typography
                        sx={{
                          mt: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                        }}
                        fontSize="2.03vw"
                        fontWeight="medium"
                      >
                        EXPERIENCE
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "18.9vw",
                        marginTop: "0vw",
                        width: "2.51vw",
                        height: "1vw",
                      }}
                      
                    >
                      <img src={less} alt = "Loading..." width="100%" heigh="100%" />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      marginLeft: "5.86vw",
                      marginTop: "8.44vw",
                      width: "32.79vw",
                      height: "17.55vw",
                      position: "absolute",
                      dispaly: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        width: "32.79vw",
                        height: "17.55vw",
                      }}
                    >
                      <Box
                        sx={{
                          width: "1.09vw",
                          height: "1.30vw",
                          dispaly: "flex",
                        }}
                      >
                          <img src={bullet} alt = "Loading..." width="100%" heigh="100%" />
                      </Box>
                      <Box
                        sx={{
                          marginTop: "3.70vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bullet} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                      <Box
                        sx={{
                          marginTop: "5.36vw",
                          width: "1.09vw",
                          height: "1.30vw",
                        }}
                      >
                          <img src={bullet} alt = "Loading..." width="100%" heigh="100%" />

                      </Box>
                    </Box>
                    {/* <Box sx={{width:'1.09vw',height:'1.30vw'}}>
  </Box> */}
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "-17.55vw",
                        width: "29.19",
                        height: "3vw",
                      
                      }}
                    >
                      <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                        Founders bringing decades of experience within the
                        mortgage finance space 
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "2vw",
                        width: "29.19",
                        height: "5vw",
                        
                      }}
                    >
                           <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                       Success in delivering positive customer outcomes through digital transformation and providing an alternate choice
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginLeft: "2.5vw",
                        marginTop: "1.5vw",
                        width: "29.19",
                        height: "5vw",
                      }}
                    >
                       <Typography
                        sx={{
                          mt: "0vw",
                          ml: "0vw",
                          color: "#FFFFFF",
                          font: "Roboto",
                          textAlign: "left",
                          fontWeight:'regular',
                          fontSize:"1.25vw"

                        }}
                        
                        lineHeight={1.2}
                        fontWeight={300}
                      >
                      Supported by strong financial backers, leading technology partners and a great team of people
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ):
               (
                <Box
                  sx={{
                    marginLeft: "4.45vw",
                    marginTop: "11.45vw",
                    width: "42.24vw",
                    height: "24.22vw",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        width: "42.24vw",
                        height: "8.07vw",
                        boxShadow: "0vw 0.16vw 2.03vw #00000029",
                        borderRadius: "1.04vw",
                        display: "flex",
                        position: "relative",
                      }}
                      onMouseOver={() =>handleClick(1)}
                    >
                      <Box
                        sx={{
                          marginLeft: "5.73vw",
                          marginTop: "2.66vw",
                          width: "22.76vw",
                          height: "2.71vw",
                          position: "absolute",
                        }}
                        
                      >
                        <Typography
                          sx={{
                            mt: "0vw",
                            color: "#C2C2C2",
                            font: "Roboto",
                            textAlign: "left",
                          }}
                          fontSize="2.03vw"
                          lineHeight={1.2}
                          letterSpacing="0px"
                          fontWeight="medium"
                        >
                          INNOVATIVE FINANCING
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          marginLeft: "36.48vw",
                          marginTop: "2.66vw",
                          width: "2.6vw",
                          height: "2.6vw",
                          position: "absolute",
                        }}
                       
                      >
                        <img
                          src={plus}
                          alt = "Loading..."
                          width="100%"
                          heigh="100%"
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "1.98vw",
                        width: "42.24vw",
                        height: "8.07vw",
                        boxShadow: "0vw 0.16vw 2.03vw #00000029",
                        borderRadius: "1.04vw",
                        display: "flex",
                        position: "relative",
                      }}
                      onMouseOver={() =>handleClick(2)}
                    >
                      <Box
                        sx={{
                          marginLeft: "5.73vw",
                          marginTop: "2.66vw",
                          width: "22.76vw",
                          height: "2.71vw",
                          position: "absolute",
                        }}
                      >
                        <Typography
                          sx={{
                            mt: "0vw",
                            color: "#C2C2C2",
                            font: "Roboto",
                            textAlign: "left",
                          }}
                          fontSize="2.03vw"
                          lineHeight={1.2}
                          letterSpacing="0px"
                          fontWeight="medium"
                        >
                          TECHNOLOGY
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          marginLeft: "36.48vw",
                          marginTop: "2.66vw",
                          width: "2.6vw",
                          height: "2.6vw",
                          position: "absolute",
                        }}
                        onClick={() => handleClick(2)}
                      >
                                 <img
                          src={plus}
                          alt = "Loading..."
                          width="100%"
                          heigh="100%"
                        /> 
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        marginTop: "1.98vw",
                        width: "42.24vw",
                        height: "8.07vw",
                        boxShadow: "0vw 0.16vw 2.03vw #00000029",
                        borderRadius: "1.04vw",
                        display: "flex",
                        position: "relative",
                      }}
                      onMouseOver={() =>handleClick(3)}
                    >
                      <Box
                        sx={{
                          marginLeft: "5.73vw",
                          marginTop: "2.66vw",
                          width: "22.76vw",
                          height: "2.71vw",
                          position: "absolute",
                        }}
                      >
                        <Typography
                          sx={{
                            mt: "0vw",
                            color: "#C2C2C2",
                            font: "Roboto",
                            textAlign: "left",
                          }}
                          fontSize="2.03vw"
                          lineHeight={1.2}
                          letterSpacing="0px"
                          fontWeight="medium"
                        >
                          EXPERIENCE
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          marginLeft: "36.48vw",
                          marginTop: "2.66vw",
                          width: "2.6vw",
                          height: "2.6vw",
                          position: "absolute",
                        }}
                        onClick={() => handleClick(3)}
                      >
                                  <img
                          src={plus}
                          alt = "Loading..."
                          width="100%"
                          heigh="100%"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              
            </Box>
          </Stack>
        </Box>
        <Box mt="0.2vw">
          <BottomBox />
        </Box>
      </Box>
    </React.Fragment>
  );
}

function RightBlockA() {
  const [hexaA, setHexA] = React.useState([hexaBig1, hexaBig2, hexaBig3]);
  const [hexaB, setHexaB] = React.useState([
    hexaMedium1,
    hexaMedium2,
    hexaMedium3,
  ]);
  const [hexaC, setHexaC] = React.useState([
    hexaSmall1,
    hexaSmall2,
    hexaSmall3,
  ]);
  const [bigHexa, setBigHexa] = React.useState(hexaBig1);
  const [mediumHexa, setMediumHexa] = React.useState(hexaMedium2);
  const [smallHexa, setSmallHexa] = React.useState(hexaSmall3);
  const handleFirstHover = () => {
    if (bigHexa == hexaBig2) {
      setBigHexa(hexaBig3);
      setMediumHexa(hexaMedium1);
      setSmallHexa(hexaSmall2);
    }
    if (bigHexa == hexaBig3) {
      setBigHexa(hexaBig1);
      setMediumHexa(hexaMedium2);
      setSmallHexa(hexaSmall3);
    }
    if (bigHexa == hexaBig1) {
      setBigHexa(hexaBig2);
      setMediumHexa(hexaMedium3);
      setSmallHexa(hexaSmall1);
    }
  };

  const handleSecondHover = () => {
    if (mediumHexa == hexaMedium2) {
      setBigHexa(hexaBig2);
      setMediumHexa(hexaMedium3);
      setSmallHexa(hexaSmall1);
    }
    if (mediumHexa == hexaMedium3) {
      setBigHexa(hexaBig3);
      setMediumHexa(hexaMedium1);
      setSmallHexa(hexaSmall2);
    }
    if (mediumHexa == hexaMedium1) {
      setBigHexa(hexaBig1);
      setMediumHexa(hexaMedium2);
      setSmallHexa(hexaSmall3);
    }
  };

  const handleThirdHover = () => {
    if (smallHexa == hexaSmall3) {
      setBigHexa(hexaBig3);
      setMediumHexa(hexaMedium1);
      setSmallHexa(hexaSmall2);
    }
    if (smallHexa == hexaSmall2) {
      setBigHexa(hexaBig2);
      setMediumHexa(hexaMedium3);
      setSmallHexa(hexaSmall1);
    }
    if (smallHexa == hexaSmall1) {
      setBigHexa(hexaBig1);
      setMediumHexa(hexaMedium2);
      setSmallHexa(hexaSmall3);
    }
  };

  return (
    <Box
      sx={{
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        boxShadow: "-1px -6px 46px #0000003D",
        opacity: 1,
        width: "49.5vw",
        height: "46.75vw",
      }}
    >
      {/* <img src={whyusrightOne} alt = "Loading..." width="100%" height="100%" />
       */}
      <Box
        sx={{
          marginLeft: "7.66vw",
          marginTop: "7.71vw",
          marginRight: "8.85vw",
          width: "33.91vw",
          height: "12.19vw",
        }}
      >
        <Typography
          sx={{
            color: "#8f8f8f",
            fontWeight: "bold",
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="4.64vw"
          lineHeight={1.2}
          letterSpacing="-1.6px"
        >
          Homes for more Americans
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            marginLeft: "33.8vw",
            marginTop: "-2vw",
            width: "9vw",
            height: "10vw",
          }}
          onMouseEnter={handleThirdHover}
        >
          <img src={smallHexa} alt = "Loading..." width="100%" height="100%" />
        </Box>
        <Box
          sx={{
            marginLeft: "22.47vw",
            marginTop: "-6.42vw",
            width: "13.8vw",
            height: "15.98vw",
          }}
          onMouseEnter={handleSecondHover}
        >
          <img src={mediumHexa} alt = "Loading..." width="100%" height="100%" />
        </Box>
        <Box
          sx={{
            marginLeft: "5.78vw",
            marginTop: "-10vw",
            width: "20.6vw",
            height: "22vw",
            display: "flex",
            flexDirection: "row",
          }}
          onMouseEnter={handleFirstHover}
        >
          <img src={bigHexa} alt = "Loading..." width="100%" height="100%" />
        </Box>
      </Box>
    </Box>
  );
}

function LeftBlockA() {
  return (
    <Box
      sx={{
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        boxShadow: "1px 6px 46px #0000003D",
        opacity: 1,
        width: "35.5vw",
        height: "46.75vw",
      }}
    >
      <Box
        sx={{
          marginLeft: "6.72vw",
          marginTop: "7.76vw",
          width: "15.89vw",
          height: "12.60vw",
          fontsize: "4.64vw",
          font: "Roboto",
        }}
      >
        <Typography
          sx={{
            color: "#8f8f8f",
            fontWeight: "bold",
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="5vw"
          lineHeight={1.2}
          letterSpacing="-1.6px"
        >
          Inspire Change
        </Typography>
      </Box>

      <Box
        sx={{
          marginLeft: "7vw",
          marginTop: "5.52vw",
          marginRight: "2.34vw",
          width: "26.61vw",
          height: "8.7vw",
          fontsize: "3vw",
          font: "Roboto",
        }}
      >
        <Typography
          sx={{
            color: "#8f8f8f",
            fontWeight: "bold",
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="2.8vw"
          lineHeight={1.2}
          letterSpacing="-1.6px"
        >
          Bringing social media to bear
        </Typography>
      </Box>

      <Box sx={{ width: "35.65vw", height: "12.6vw", marginTop: "4.64vw" }}>
        <img src={whyusleftOne} alt = "Loading..." width="100%" height="100%" />
      </Box>
    </Box>
  );
}

function LeftBlockB() {
  return (
    <Box
      id="Meaning"
      sx={{
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        boxShadow: "1px 6px 46px #0000003D",
        opacity: 1,
        width: "36vw",
        height: "50vw",
      }}
    >
      {/* <img src={whyusleftThree} alt = "Loading..." width="100%" height="100%" /> */}
    </Box>
  );
}

function InputKey() {
  const name = "password";
  const type = "password";
  const [cur, setCur] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);

  function onClickFun(e) {
    const val1 = e.currentTarget.value;
    val1 === "N3$tfu3L" ? (allowed = "yes") : (allowed = "no");
  }

  return (
    <div position="fixed" align="center">
      <Typography sx={{ fontSize: "1vw", width: "12vw" }}>
        (Please answer the following to activae DISCOVER NESTFUEL)
      </Typography>
      <Typography sx={{ mt: 2, fontSize: "1vw", width: "12vw" }}></Typography>
      <TextField
        sx={{
          fontsize: "0.5vw",
          mt: 1,
          width: "12vw",
          border: 1,
          borderColor: "black",
          borderRadius: 2,
        }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label="No flying ends Indian penny"
        autoFocus={true}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default WhyUs;
