import React from "react";
import { Box, Stack, CssBaseline, Input, Typography, Button} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import leftbottom from '../../assets/images/bg-10@1x.png'

import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate } from 'react-router-dom';
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import ItemsCarousel from "react-items-carousel";
import GoogleMaps from "./Googlemaps";

import { useDispatch, useSelector } from 'react-redux';
import { borrowerChoiceF} from "../../actions/borrower";
import { SELPROP } from "../../constants";

const DebugOutput = true;
function withCommas(x) {return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};

function ShowImages() {
  const { selectedProp,
          bChoiceE,
          bChoiceD  } = useSelector((state)=>state.borrower);
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const noProps = bChoiceD?.length;

  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "Selected":
        const data = {
        propertyPrice   : selectedProp?.prop.price,
        offerPrice      : selectedProp?.prop.offerPrice,
        monthlyBudget   : bChoiceE?.monthlyBudget,
        upfront         : bChoiceE?.upfront,
        }
        dispatch(borrowerChoiceF(data));
        navigate("/BorrowerProfile", { replace: true });
        break;
      case "Skip":
        dispatch({type: SELPROP, payload:{}})
        break;
      case "NoProps":
        navigate("/SearchProperty", { replace: true });
        break;
      case "NewLocation":
          navigate("/SearchProperty", { replace: true });
          break;
      default:
        break;
    }
    
  }

  return(
    <React.Fragment>
          <CssBaseline/>
          <Box sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
            <Navbar/>
            <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, 
                    borderRadius: 1,}}>
              <LeftBlock/>
            
              <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center'}}>
              <Typography sx={{color:'#000000',mt:'4vw',fontSize:'2vw',font:'Roboto', textAlign:'center'}}> 
                  Explore Financing for the property</Typography>
              <div align='center'>
                  <Stack sx={{ml:'3.0vw',mr:'2.7vw',mt:'0.8vw',height:'6.5vw',width:'6.5vw'}} spacing={0} direction="row" justifyContent="space-between">
                      <Button sx={{ textTransform:'none', width:'6.5vw',border:0}} id="Selected" onClick={onClickFun}> 
                      <HexButton titleText="Selected" type='base' fontSize='24'/></Button>
                  </Stack>
                  <Stack sx={{ml:'0.6vw',mr:'0.2vw',mt:'-0.5vw',height:'6.5vw'}} spacing={0} direction="row" justifyContent="space-around">
                      <Button sx={{ textTransform:'none', border:0}} width='6.5vw' id="NewLocation" onClick={onClickFun}> 
                      <HexButton titleText="At New Address" type='base' fontSize='24'/></Button>
                      <Button sx={{ textTransform:'none', border:0}}width='6.5vw' id="Skip" onClick={onClickFun}> 
                      <HexButton titleText="Skip" type='base' fontSize='24'/></Button>
                  </Stack>
                  <Stack sx={{bottom:'0.1vw',position:'absolute', width:'14vw'}} direction="row" justifyContent="space-between">
                      <Button id="Back" onClick={()=>navigate(-1)}><ArrowBackIosIcon sx={{color:"#000000" , fontSize:'3vw'}}/></Button>
                      <Button sx={{mr:-3}}id="Forward" onClick={()=>navigate("/PropertyType", { replace: false })}>
                        <ArrowForwardIosIcon sx={{color:"#000000" , fontSize:'3vw'}}/></Button>
                  </Stack>
              </div>
          </Box>
        <RightBlock/>
      </Box>
    </Box>
    </React.Fragment>
  );
}

function RightBlock() {
  const {bChoiceD}= (useSelector((state)=>state.borrower));
  const [dummy, SetDummy] = React.useState(bChoiceD)
  const nprops = bChoiceD.length;
  return (
    <React.Fragment>
      <CssBaseline/>
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '50vw',height:'46.75vw' }}>
          <Box sx={{mr:2,ml:0, p:2,background:'#31312C 0% 0% no-repeat padding-box', width: "99%",height:'18vw' ,border:1, borderRadius:2}}>
         { nprops>=1 ?<OuterCarousel itemArray={bChoiceD} numCards={1.7}/>:<>
            <Typography sx={{mt:20, textAlign:"center"}} variant="h3" color="#C4C4C4">No properties in your budget, Please change your Budget and income</Typography>
         </>}
        </Box>
        <Box sx={{background: 'inherit', width: "100%",height:"60vh" }}>
          
        <GoogleMaps position={{lat: 37.6408041, lng: -122.4295378}}
                                  markerInput={bChoiceD}/>
          </Box>
        </Box>
    </React.Fragment>
  );
}


function LeftBlock() {
    var dummy;
    const dispatch = useDispatch();
    const { selectedProp,
            bfinancialProfile,
            bChoiceD} = (useSelector((state)=>state.borrower));
    const [offerPrice,setOfferPrice] = React.useState(0);
    const [guidePrice,setGuidePrice] = React.useState(0);
    
    React.useEffect(()=>{ 
      typeof selectedProp?.prop === 'undefined' || isNaN(selectedProp?.prop?.offerPrice) ? dummy = true : setOfferPrice(selectedProp?.prop.offerPrice)
      typeof selectedProp?.prop === 'undefined' || isNaN(selectedProp?.prop?.price)?       dummy = true : setGuidePrice(selectedProp?.prop.price)
    },[selectedProp?.prop]);


    function onClickFun(e) {
      const buttonId = e.currentTarget.id;
      switch(buttonId) {
        case "OfferPrice":
            const input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
            setOfferPrice(isNaN(input) ? 0 : input)
            const seleprop = {...selectedProp,prop: {...selectedProp.prop,offerPrice:isNaN(input)?0:input}}
            console.log("selected prop",offerPrice,seleprop,e.currentTarget.value,input);
            dispatch({type: SELPROP, payload:seleprop}); 
            break;
        default:
          break;
        }
    }
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
        <Box sx={{ mt:'15vh' , ml: '7.3vw', width: '28vw',height:'44vh' }}> 
            <Stack spacing={2} direction="row" justifyContent="space-between"> 
                <Typography fontSize='1.1vw' color='#000000'>Offer Price :</Typography>  
                <Input id="OfferPrice" value={'$'.concat(withCommas(offerPrice))} sx={{fontSize:'1.1vw', backgroundColor:'#FFFFFF',ml:'6vw', height: '2.5vw',width:"18vw",border:1, borderRadius:'0.5vw'}} 
                   inputProps={{style: { textAlign: 'center' }}}   onChange={onClickFun} onBlur={()=>{if (offerPrice <= 100) setOfferPrice(100)}}/>
            </Stack>
            <Stack sx={{mt:'1vw'}}spacing={2} direction="row" justifyContent="space-between"> 
                  <Typography  fontSize='1.1vw' color='#000000'>Guide price :</Typography>  
                  <Typography width='12vw' fontSize='1.2vw' fontWeight='bold' color='#000000'>{'$'.concat(withCommas(guidePrice))}</Typography> 
            </Stack>
            <Stack sx={{mt:'1vw'}}spacing={2} direction="row" justifyContent="space-between"> 
                  <Typography fontSize='1.1vw' color='#000000'>Estimated Affordability :</Typography>  
                  <Typography width='12vw' fontSize='1.2vw' fontWeight='bold' color='#000000'>{'$'.concat(withCommas(bfinancialProfile.maxAffordability))}</Typography> 
            </Stack>
            <Typography sx={{color:'#000000',mt:'6vh',fontSize:'1.3vw',fontWeight:'bold',font:'Roboto'}}> {bChoiceD.length} properties for sale in this area</Typography>  
          
          <Stack sx={{mt:5}}spacing={2} direction="row" justifyContent="space-between"> 
                  <Typography fontSize='1.2vw' color='#000000'>Affordable :</Typography>  
                  <Typography width='12vw' fontSize='1.2vw' fontWeight='bold' color='#000000'>{bChoiceD.filter(item=>item.affordableType==="Affordable"||item.affordableType=== "Median-Affordable").length}</Typography> 
          </Stack>
          <Stack sx={{mt:3}}spacing={2} direction="row" justifyContent="space-between"> 
                  <Typography fontSize='1.2vw' color='#000000'>Potentially Affordable :</Typography>  
                  <Typography width='12vw' fontSize='1.2vw' fontWeight='bold' color='#000000'>{bChoiceD.filter(item=>item.affordableType==="Potentially Affordable").length}</Typography> 
          </Stack>
          <Stack sx={{mt:3}}spacing={2} direction="row" justifyContent="space-between"> 
                  <Typography fontSize='1.2vw' color='#000000'>Unaffordable :</Typography>  
                  <Typography width='12vw' fontSize='1.2vw' fontWeight='bold' color='#000000'>{bChoiceD.filter(item=>item.affordableType==="Unaffordable").length}</Typography> 
          </Stack>
        </Box>
        <Box sx={{ bottom:'0',height:'10.8vw',width: 'inherit',position:'absolute'}}>  
           <img src={leftbottom} alt = "Loading..." width="100%" height="100%"/> 
        </Box>
      </Box>
    );
  
}

function OuterCarousel(props) {
  const {itemArray, numCards } = props;
  const {bChoiceD}= useSelector((state)=>state.borrower);
  const dispatch = useDispatch();
  const [active, setActive] = React.useState(0);
  React.useEffect(()=>{
    dispatch({type: SELPROP, payload:{prop:bChoiceD[active],propId:active}});
  },[active]);
  
  
  return (
    <div className="OuterCarousel">
      <ItemsCarousel
        autoPlay={true}
        infiniteLoop={true}
        gutter={20}
        timeout={1}
        activePosition={"center"}
        chevronWidth={-2}
        disableSwipe={false}
        alwaysShowChevrons={false}
        numberOfCards={numCards}
        slidesToScroll={1}
        outsideChevron={false}
        showSlither={true}
        firstAndLastGutter={true}
        activeItemIndex={active}
        requestToChangeActive={(value) => value >=0 && value < itemArray.length 
                                  ? setActive(value)
                                  :value <0 ?  setActive(itemArray.length -1)
                                  : setActive(0)}
        rightChevron={<ArrowForwardIosIcon  sx={{mt:'-4.5vh',color:"#F1F2F4" , fontSize:'2vw'}}/>}
        leftChevron={<ArrowBackIosIcon      sx={{mt:'-4.5vh',color:"#F1F2F4" , fontSize:'2vw'}}/>}
      >
      {itemArray.map((item,index) => 
      <Box key={index} sx={{background: '#FFFFFF 0% 0% no-repeat padding-box', height: "98%",border:0, borderColor:"#000000", borderRadius:3} } >
       <InnerCarousel imageArray={item.images} numCards={1} chevron={active}/>
        <Stack sx={{ml:2, mr:2,mb:0}} spacing={0} direction="row" justifyContent="space-between"> 
            <Typography mt='0.5vw' fontSize='1.1vw' fontWeight='bold' color='black' align='center'>${withCommas(item.offerPrice)}</Typography>
            <Stack direction="column">
              <Typography fontSize='0.9vw' color='black' align='center'> {item.address}</Typography>
              <Typography fontSize='0.9vw' color='black' align='center'> {item.city}, {item.state} - {item.zipcode}</Typography>
            </Stack>
        </Stack>
        
      </Box>)}
      </ItemsCarousel>
    </div>
  );
}

function InnerCarousel(props) {
  const {imageArray,numCards,chevron} = props;
  const [active, setActive] = React.useState(0);
  console.log("chevron",chevron)
  return (
    <div className="InnerCarousel">
      <ItemsCarousel
         autoPlay={true}
         infiniteLoop={true}
         gutter={0}
         timeout={1}
         activePosition={"center"}
         chevronWidth={-2}
         disableSwipe={false}
         alwaysShowChevrons={false}
         numberOfCards={numCards}
         slidesToScroll={1}
         outsideChevron={false}
         showSlither={true}
         firstAndLastGutter={true}
         activeItemIndex={active}
        requestToChangeActive={(value) => setActive(value)}
        rightChevron={<ArrowForwardIosIcon sx={{color:"#FFFFFF" , fontSize:'2vw'}}/>}
         leftChevron={<ArrowBackIosIcon    sx={{color:"#FFFFFF" , fontSize:'2vw'}}/>}
      >
      {imageArray.map((item) => 
      <div>
       <Box sx={{ border:1, borderColor:"#FFFFFF", borderRadius:1, height:'13.5vw'}} >
       <img src={(typeof(item) ==='undefined' ? nestfuelImage : item)} alt={nestfuelImage} width="100%" height="100%"/>
        </Box>
        </div>)}
      </ItemsCarousel>
    </div>
  );
}

export default ShowImages ;