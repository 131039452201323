import React from "react";
import { Box, Stack, CssBaseline, TextField, InputAdornment, IconButton, Typography, Button } from "@mui/material";
import { HexButton } from './ButtonIcons1';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import useStyles from './styles';
import Navbar from "./NavBar";
import { BottomBox } from "./RightBlock";
import { _debug } from "../../constants";

var allowed = "no"

function LegalPrivacy() {
  const navigate = useNavigate();
  const [seek, setSeek] = React.useState(allowed)
  const classes = useStyles();
  // if(_debug === 'development') allowed = 'yes'
  allowed = 'yes'

  return (
    <React.Fragment><CssBaseline />
      <Box sx={{ overflow:'hidden', width: '100vw', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <Navbar />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <LeftBlockA />

          <Box sx={{ width: '14vw', justifyContent: 'center' }}>
            <Stack sx={{ mr: '2.0vw', ml: '3.0vw', mt: '19vw', height: '10vw', width: '10vw' }} direction="row">
              <Button sx={{overflow:'hidden', mt:0,zIndex:1,border:0,width:'8.0vw',position:'fixed',height:'10vw', minHeight: 0, minWidth: 0,padding: '0.2vw'}}  id="Discover" className='hexBtnCenter'
                onClick={() => allowed === 'yes' ? navigate(-1, { replace: false }) : setSeek("ddd")}>
                <HexButton titleText="CLOSE" type='large' fontSize='24' />
              </Button>
            </Stack>
            {seek == 'ddd' ? <InputKey /> : <></>}
          </Box>

          {/* <RightBlockA/> */}
          <Box sx={{
        background: '#F3F3F3 0% 0% no-repeat padding-box',
        boxShadow: '-1px -6px 46px #0000003D', p:0,opacity: 1, width: '50vw',borderRadius:'0.5vw'}}>

<Box sx={{marginLeft:'4.84vw',marginTop:'7.465vw',width:'39.32vw',height:'34.22vw' ,overflowX:'none',overflowY:'auto','&::-webkit-scrollbar': { width: 0, }}}>
<h2 sx ={{fontWeight:'bold'}}>Privacy Policy </h2>
<Box sx={{marginLeft:'5vw'}}>
<p>Last Update Date: September 7th, 2022</p>
<p>Effective Date: September 7th, 2022</p>
</Box>
<p></p>
<p></p>
<Box sx={{marginTop:'2vw' }}>
<p  align='justify'>This Privacy Policy describes how Nestfuel Realty LLC, and its related companies, affiliates, 
and subsidiaries (collectively, as used herein, "we," "us," and "our") collect, use, and share your information 
when you visit our website located at www.nestfuel.com and any of our other websites (our "Websites"), use our 
mobile applications, if any, (our "Apps"), interact with our application program interfaces and other services 
we make to interact with you through social networks (such social networks, "Social Networks"; our services that 
interact with Social Networks, "Social Network Services"), or use other services (our Websites, Apps, Social 
Network Services and related services are collectively our "Services").
This Privacy Policy does not apply to any websites or applications that we do not operate or other websites that
 have their own privacy policies, including Social Networks. By visiting or using any of our Services, you are 
 consenting to the practices described in this Privacy Policy.
 </p>
</Box>

 <Box sx={{marginTop:'2vw'}}>
 <h5 align='left'>How we collect your Personal Information </h5>
 
 <p align='justify'>We collect Personal Information from you directly through our Services (for example, when you create an account on 
our websites or Apps, fill out a form or otherwise submit content through our services) and by communicating directly with you by 
telephone, by email, by any other communication channels broadly used and by using our embedded chat messaging system.  </p>

<p align='justify'>Personal Information that we may collect from you directly includes but is not limited to the following: </p>

<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}> Name</Typography></Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}>
     <Typography sx={{marginLeft:'0vw'}}> Mailing address</Typography></Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}> Email address</Typography></Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}> 	Phone number</Typography></Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>Username and password</Typography></Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>Demographic information</Typography></Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>Social Security Number</Typography></Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>Asset information</Typography></Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>Income and employment information</Typography></Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>Any other information that you choose to provide.</Typography></Box>
</Box>

</Box>

<Box sx={{marginTop:'2vw'}}>
<h5 align='left'>How we share personal information</h5>

<p align='justify'>We may also collect certain information related to you and your activities on our websites when you visit 
the websites. This information may include but is not limited to your IP address, your internet browser and operating system 
type, the date and time of your visit, and the website that referred you to us (refer to “Use of Cookies and Analytical Tools 
for more information about information collected by cookies and similar tracking technologies).</p>

<p align='justify'>In accordance with your device permissions, we may collect information about the precise location of your device.</p>
</Box>

<Box sx={{marginTop:'2vw'}}>

<h5 align='left'>How we use your Personal Information</h5>

<p align='justify'>Our first products and services are expected to be launched in Colorado, USA. Any reference to products and services 
below are intended to imply those that may be available in future.  </p>

<p align='justify'>Your eligibility for these particular products and services shall be subject to our and/or our subsidiaries’ final 
determination, restrictions, and acceptance. We and/or our subsidiaries may discontinue or make changes to the information, products, 
licenses, or services described on this website at any time. Any dated information is published as of its publication date only. We 
and/or our subsidiaries do not undertake any obligation or responsibility to update or amend any such information. We and/or our 
subsidiaries reserve the right to terminate any or all offerings without prior notice. Furthermore, by offering information, 
products, or services via this website, no solicitation is made by us and/or our subsidiaries to any person to use such information, 
products, or services in jurisdictions where the provision of information, products, or services is prohibited by law. </p>

 <p align='justify'>We may also use your Personal information to: </p>

 <Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>
    Communicate with you about our Services and products, about our company, 
  and about your account generally. 
  </Typography>
  </Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>	
  Provide you customer service. 
  </Typography></Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>
    Improve the security of our Services and to anticipate and respond to unauthorized, fraudulent, or unlawful activity.
    </Typography>
    </Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> <Typography sx={{marginLeft:'0vw'}}>
    Personalize the Services to you, including in communications to you through our Social Network Services and in 
    advertising through our Services and through third-party services (including advertising on other websites that we do not operate). 
    If you give us permission on your mobile device, we may send you promotional and non-promotional "push" notifications to your mobile 
    devices about our Services and products available on our Services.
    </Typography>
    </Box>
</Box>

</Box>

<h5 align='left'>How we share personal information</h5>

 <p align='justify'>We may share your personal Information with our employees, independent contractors, and any parent, 
 subsidiary, or affiliated business entity. </p>

 <p align='justify'>We may share your personal information with third-party vendors, consultants and service providers 
 (collectively “Third-Party Service Providers”) in the following circumstances: </p>

 <Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>We may share your personal information with Third-Party Service 
  Providers that access personal information to perform services for us. 
  </Typography>
  </Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}>
     <Typography sx={{marginLeft:'0vw'}}>	We may disclose personal information if we believe that disclosure 
  is in accordance with, or required by, any applicable law or legal process, including lawful requests by public authorities to meet national 
  security or law enforcement requirements. 
  </Typography>
  </Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>
    	We may share personal information if we believe (1) that your actions 
  are inconsistent with our user agreements or policies, (2) that you have violated the law, or (3) it's necessary to protect the rights, 
  property and safety of Rocket, our users, the public, or others.
  </Typography>
  </Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>	We share personal information with our lawyers and other professional 
  advisors where necessary to obtain advice or otherwise protect and manage our business interests.
  </Typography>
  </Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>	
  We may share personal information in connection with, or during 
  negotiations concerning, any merger, sale of company assets, financing or acquisition of all or a portion of our business by 
  another company.
  </Typography>
  </Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}>
     <Typography sx={{marginLeft:'0vw'}}>	We may share personal information in connection with, or during 
  negotiations concerning, any merger, sale of company assets, financing or acquisition of all or a portion of our business by 
  another company.
  </Typography>
  </Box> 
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}>
  <Typography sx={{marginLeft:'0vw'}}>	We share personal information with your consent or at your direction, 
  including but not limited to through third-party integrations you choose to enable.</Typography>
  </Box>
</Box>
<Box sx={{display:'flex'}}>
  <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
  <Box sx={{marginLeft:'1vw'}}> 
  <Typography sx={{marginLeft:'0vw'}}>We also share aggregated or de-identified information that cannot 
  reasonably be used to identify you.</Typography>
  </Box>
</Box>
<p></p>
  <p align="justify">
  We limit the information that we share to third-party service providers, and require that our third-party service 
  providers only use shared personal information to perform the services requested in accordance with this Privacy 
  Policy and have adequate security measures in place to protect any of your shared personal information.
  </p>
 
  <h5 align='left'>Use of Cookies and Analytical Tools</h5>

<p align='justify'>We (and third parties who perform work for us) may use tracking technologies, such as cookies and 
web beacons, to collect information about your interactions with the Services. If your browser is set to reject cookies, 
you may continue to use the Website, but some aspects of the Website may not function as intended. </p>

<p align='justify'>Some definitions:</p>

<Box sx={{display:'flex'}}>
 <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
 <Box sx={{marginLeft:'1vw'}}> 
 <Typography sx={{marginLeft:'0vw'}}>We may share your personal information with Third-Party Service 
 Providers that access personal information to perform services for us. 
 </Typography></Box>
</Box>
<Box sx={{display:'flex'}}>
 <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
 <Box sx={{marginLeft:'1vw'}}> 
 <Typography sx={{marginLeft:'0vw'}}>	Cookies are small data files stored on your hard drive or in device memory that help us
  improve our Services and your experience, see which areas and features of our Services are popular and which emails and 
  advertisements you view, authenticate users, reconstruct or observe activity relating to a session or by a user for troubleshooting, 
  issue resolution, and other purposes, and count visits to our websites.
  </Typography>
  </Box>
</Box>
<Box sx={{display:'flex'}}>
 <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
 <Box sx={{marginLeft:'1vw'}}> 
 <Typography sx={{marginLeft:'0vw'}}>
 	Web beacons (also known as "pixel tags" or "clear GIFs") are electronic images that we use on our Services and in our emails to help 
 deliver cookies, count visits, and understand usage and campaign effectiveness. In our mobile apps, we may also use technologies that are 
 not browser-based like cookies.
 </Typography>
 </Box>
</Box>
<Box sx={{display:'flex'}}>
 <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
 <Box sx={{marginLeft:'1vw'}}> 
 <Typography sx={{marginLeft:'0vw'}}>
  	We share personal information with our lawyers and other professional 
 advisors where necessary to obtain advice or otherwise protect and manage our business interests.
 </Typography>
 </Box>
</Box>
<Box sx={{display:'flex'}}>
 <Box sx={{marginLeft:'1.5vw'}}> <Typography sx={{marginLeft:'0vw'}}>-</Typography></Box>
 <Box sx={{marginLeft:'1vw'}}>
   <Typography sx={{marginLeft:'0vw'}}>
   	For example, our apps may include software development kits (known as SDKs) which are code that sends 
   information about your use to a server. These SDKs allow us to track our conversions, bring you advertising 
   both on and off the Service, and provide you additional functionality. For more information about these tracking 
   technologies and how to disable them, see the Advertising and Analytics section below.
 </Typography>
 <p></p>
 </Box>
</Box>

 <h5 align='left'>Right to Access to and Control Over Information </h5>

 <p align='justify'>You may have certain rights under applicable data protection law with respect to personal information 
 about you that is collected through our services and our websites, or when you contact or otherwise engage with us. You 
 also have the right to opt-out of receiving electronic direct marketing communications from us.</p>

 <p align='justify'>However, we may retain personal information as authorized under applicable law, such as personal
  information required as necessary to provide our services, protect our business and systems from fraudulent activity,
   to debug and identify errors that impair existing functionality, comply with law enforcement requests pursuant to 
   lawful process, for scientific or historical research, for our own internal purposes reasonably related to your 
   relationship with us, or to comply with legal obligations.  </p>

 <p align='justify'>We require certain types of information so that we can provide our services. If you ask us to 
 delete it, you may no longer be able to access or use our services. </p>

 <p align='justify'>We may have a reason under the law why we do not have to comply with your request, or why we may 
 comply with it in a more limited way than you anticipated. If we do, we will explain that to you in our response. </p>

 <p align='justify'>You may email us at <a href="mailto:legal@nestfuel.com ">legal@nestfuel.com</a>  to exercise any of these rights. To protect our customers’ 
 Personal Information, we are required to verify your identity before we can act on your request.</p>

 <p align='justify'></p> 

<h5 align='left'>Links to Other Websites </h5>

<p align='justify'>The Services and websites may contain links to third-party websites and other sites that are not operated by us. 
If you click on a third-party link, you will be directed to that third party's site. </p>

<p align='justify'>This Privacy Policy is only applicable to our Services. We have no control over, and assume no responsibility for 
the content, privacy policies or practices of any third-party sites or services.</p>
 
<p align='justify'>We strongly advise you to review the Privacy Policy of every site you visit.</p>

 <h5 align='left'>Security</h5> 

<p align='justify'>We are committed to protecting your Personal Information. All of our operational and data processing systems are in 
a secure environment that protects your information from unauthorized access, use or disclosure. </p>

<h5 align='left'>Children's Privacy </h5>

<p align='justify'>This Website is not intended for persons under the age of 18. We do not knowingly collect, maintain, or use personal 
information from children under the age of 13. If we become aware that we have collected Personal Information from a child under 13, we 
will take steps to delete such information as soon as possible. </p>

<h5 align='left'>Updates to this Privacy Policy</h5>

<p align='justify'>We may change this Privacy Policy from time to time. If we change this Privacy Policy, we will post the updated version 
on this website and update the "Last Updated" date above. </p>

<h5 align='left'>Contact information </h5>

<p align='justify'>If you have questions regarding this Privacy Policy, please contact us by email at <a href="mailto: legal@nestfuel.com">legal@nestfuel.com</a>.  </p>

</Box>

   </Box>

        
        </Box>
<Box className={classes.bottomBar}><BottomBox/></Box>

      </Box>
    </React.Fragment>
  );
}


  
  
  function LeftBlockA() {
    return (
      
      <Box sx={{
        background: '#F3F3F3 0% 0% no-repeat padding-box',
        boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '45vw'
      }}>

          <Box sx={{marginLeft:'6.72vw',marginTop:'7.76vw',width:'18.89vw',height:'12.60vw',fontsize:'4.64vw',font:'Roboto'}}>
          <Typography sx={{color:'#8f8f8f', fontWeight:'bold',
                  font: "Roboto", textAlign:'left'}} fontSize='4.6vw' lineHeight={1.2} letterSpacing='-1.6px'> 
                Privacy Policy
                  </Typography>
          </Box>
       

  
          <Box sx={{marginLeft:'7.34vw',marginTop:'6.51vw',width:'24.01vw',height:'12.60vw',fontsize:'4.64vw',font:'Roboto'}}>
          <Typography sx={{color:'#8f8f8f', fontWeight:'bold',
                  font: "Roboto", textAlign:'left'}} fontSize='2.08vw' lineHeight={1.2} letterSpacing='-1.84px'> 
                No products or services 
                  </Typography>
                  <Typography sx={{color:'#8f8f8f', fontWeight:'bold',
                  font: "Roboto", textAlign:'left'}} fontSize='2.08vw' lineHeight={3} letterSpacing='-1.84px'> 
                 are currently offered directly 
                  </Typography>
                  <Typography sx={{color:'#8f8f8f', fontWeight:'bold',
                  font: "Roboto", textAlign:'left'}} fontSize='2.08vw' lineHeight={1.2} letterSpacing='-1.84px'> 
                 to the consumers.
                  </Typography>
          </Box>
  
      </Box>
      );
    }







function InputKey() {

  const name = 'password'
  const type = 'password'
  const [cur, setCur] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);


  function onClickFun(e) {
    const val1 = e.currentTarget.value
    val1==="N3$tfu3L"? allowed = 'yes' : allowed = 'no'

  };

  return (
    <div position='fixed' align='center'>
      <Typography sx={{ fontSize: '1vw', width: '12vw' }}>(Please answer the following to activae DISCOVER NESTFUEL)</Typography>
      <Typography sx={{ mt: 2, fontSize: '1vw', width: '12vw' }}></Typography>
      <TextField sx={{ fontsize: '0.5vw', mt: 1, width: '12vw', border: 1, borderColor: 'black', borderRadius: 2 }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label='No flying ends Indian penny'
        autoFocus={true}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </div>
  )
}

export default LegalPrivacy;