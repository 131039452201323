import React, { useState } from 'react';
import { Box, CssBaseline, Input,  Typography, Button, Stack,Avatar,Divider} from "@mui/material";
import Navbar from "./NavBar";
import { rspvw } from "./styles";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import ItemsCarousel from "react-items-carousel";
import leftbottom from '../../assets/images/bg-10@1x.png'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {  borrowerGetUserData, borrowerPostUserData, borrowerProfile,borrowerProfileNew, borrowerPostFinanceData, borrowerGetFinanceData, borrowerPostPropertyData, borrowerGetPropertyData } from "../../actions/borrower";
import { Centerbottom, LeftbottomA} from "./RightBlock";
import { LOGGEDIN, ISSIGNIN, ISSIGNUP, _debug, withCommas } from "../../constants";
import creditScoreImg from '../../assets/credit_score.png';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as api from '../../api/index';
import moment from 'moment';
import { TextField, InputAdornment, IconButton, Grid } from '@mui/material';
import useStyles from './styles';
//import { signUp,signIn,getUserDetails,updateUserInfo } from '../../actions/profile.js';
//import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import Gauge from './HighmarkGauge';
//import ReactSpeedometer from "react-d3-speedometer"
import { useWindowDimensions } from '../../computations/helperFunctions';
import { LeftBlockMsg } from "./Messenger";
import { AutosuggestStUpdateEmp } from './Autosuggest';
import SignInScreen from './FirebaseUI';
import { RightBlockProfile } from "./DashboardProfile";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import dayjs, { Dayjs } from 'dayjs';

import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';



//import DatePicker from "react-datepicker";  

const ALPHA_NUMERIC_DASH_REGEX = /^[0-9\b]+$/;
const ALPHA_CHAR_DASH_REGEX = /^[a-zA-Z\s]+$/;
const ALPHANUMERIC_CHAR_DASH_REGEX = /^[0-9a-zA-Z(\- '' . , /)\\]+$/;
// numeric, alphabets, space, dot, comma, forward slash, backward slash, hyphen, apostrophe
const ALPHA_NUMERIC_DASH_REGEX1 = /^[0-9a-zA-Z\s\.\,\-''\/\\]+$/;


const initialState = { firstName: '', lastName: '', email: '', password: '', address: '', phone: '', mname:'' };


function DashboardProfileUpdate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  const [numPage,setNumPage] = React.useState(0)
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
    //isLoggedin && setNumPage(1)
  },[height,width])
  
  const { isLoggedin, isSignup,bProfile} = useSelector((state)=>state.borrower);  

  React.useEffect(()=>{
    isLoggedin && setNumPage(1)
  },[isLoggedin])

 // const [startDateN, setStartDate] = useState((new Date()).toString());
  const [startDateN, setStartDate] = useState('');
  
  function onClickFun(e){
      e.preventDefault();
      const buttonId = e.currentTarget.id;
        switch(buttonId) {
          case "save": 
 
           if(bProfile.employmentType.trim() == '' || bProfile.employmentType == undefined){
             toast("Employment Type Should not be blank!", {position: toast.POSITION.TOP_CENTER})
           }
           else if(bProfile.employer.companyName.trim() == '' || bProfile.employer.companyName == undefined){
            toast("Employer Should not be blank!", {position: toast.POSITION.TOP_CENTER})
          }
          //  else if(bProfile.employer.companyName.trim() !== "" && !ALPHANUMERIC_CHAR_DASH_REGEX.test(bProfile.employer.companyName)){
          //    toast("Employer Should alphanumeric!", {position: toast.POSITION.TOP_CENTER})
          //  }
           else if(bProfile.employer.companyName.trim() !== "" && !ALPHA_NUMERIC_DASH_REGEX1.test(bProfile.employer.companyName)){
            toast("Employer Should be string!", {position: toast.POSITION.TOP_CENTER})
          }

           else if(bProfile.employer.companyAddress.street.trim() == ''|| bProfile.employer.companyAddress.street == undefined){
             toast("Street should not be blank!", {position: toast.POSITION.TOP_CENTER})
           }
 
           else if(bProfile.employer.companyAddress.city.trim() == '' || bProfile.employer.companyAddress.city == undefined){
             toast("City Should not be blank!", {position: toast.POSITION.TOP_CENTER})
           }
           else if(bProfile.employer.companyAddress.city !== "" && !ALPHA_CHAR_DASH_REGEX.test(bProfile.employer.companyAddress.city)){
             toast("City Should be string!", {position: toast.POSITION.TOP_CENTER})
           }
           else if(bProfile.employer.companyAddress.zipCode.trim() == '' || bProfile.employer.companyAddress.zipCode == undefined){
             toast("ZipCode Should not be blank!", {position: toast.POSITION.TOP_CENTER})
           }
           else if(bProfile.employer.companyAddress.zipCode.trim() !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(bProfile.employer.companyAddress.zipCode.trim())){
             toast("ZipCode Should be number!", {position: toast.POSITION.TOP_CENTER})
           }
          //  else if(bProfile.startDate == '' || bProfile.startDate == undefined){
          //   toast("Start Date Should not be blank!", {position: toast.POSITION.TOP_CENTER})
          // }
           else{
            
            dispatch(borrowerPostUserData(bProfile))
            navigate("/DashboardProfile",        { replace: false });
           }
            
          break;
          case "creditProfile"   : navigate("/DashboardCreditProfile",     { replace: false }); break;
          case "ecoProfile"      : navigate("/DashboardEconomicProfile",    { replace: false }); break;
          case "skip1"           : navigate("/DashboardMain",        { replace: false }); break;
          case "back"            : navigate("/DashboardMain", { replace: false }); break;
          default:
          break;
        }
  }
  

  return(
  <React.Fragment>
    <CssBaseline/>
    <Box mt={marginT} ml={marginL}  sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
      <Navbar pathname={location.pathname} />
        <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1}}>
          { location.hash === "#messenger" ? <LeftBlockMsg/> : isLoggedin ? <LeftBlock startDateN={startDateN} setStartDate={setStartDate}/> : <LeftBlockB/>}

          <Box sx={{ width: '13vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
          <div align='center'>
              {
                isLoggedin
                ?
                <>
                  <Stack sx={{ mt: '15.85vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="save" className='hexBtnCenter'
                      onClick={onClickFun}>
                      <HexButton titleText='Save' type='base' fontSize='24' />
                    </Button>
                  </Stack>
                  <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="creditProfile" className='hexBtnCenter'
                      onClick={onClickFun}>
                      <HexButton titleText="Credit Profile" type='base' fontSize='24' />
                    </Button>
                    <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="ecoProfile" className='hexBtnCenter'
                      onClick={onClickFun}>
                      <HexButton titleText="Economic Profile" type='base' fontSize='24' />
                    </Button>
                  </Stack>
                  <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '6.5vw' }} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="back" className='hexBtnCenter'
                      onClick={onClickFun}>
                      <HexButton titleText="Back&nbsp;to Overview" type='base' fontSize='24' />
                    </Button>
                  </Stack>
                </>
                :
                <>
                  <Stack sx={{ mt: '15.85vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="skip1" className='hexBtnCenter'
                      onClick={onClickFun}>
                      <HexButton titleText='Skip' type='base' fontSize='24' />
                    </Button>
                  </Stack>
                </>
              }
              
            </div>
              <Centerbottom navigateForward='SearchProperty'/>
            </Box>
          <RightBlockProfile/>
      </Box>
    </Box>
    </React.Fragment>
  );
}


function LeftBlock (props) {

    const [isSignup, setIsSignup] = useState(true);
    const [wPass,setWPass] = React.useState({ helperText: '', error: false })
    var googleUser = {};
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
   
    const handleShowPassword = () => setShowPassword(!showPassword);
    const { bProfile} = useSelector((state)=>state.borrower);  
    
    //const [value, setValue] = React.useState<Dayjs | null>(dayjs('2022-04-17')); 
    const switchMode = () => {
      !isSignup ? dispatch({ type: ISSIGNUP }):dispatch({ type: ISSIGNIN });
      setIsSignup((prevIsSignup) => !prevIsSignup);
      setShowPassword(false);
    }
    const current = new Date();  
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
   
    
    
    const currentMonth = ("0" + (current.getMonth() + 1)).slice(-2);
    const currentYear = current.getFullYear()
    const minDt = currentYear+'-'+currentMonth;
    console.log('stateDate_date',date);
    console.log('stateDate',props.startDateN);
    console.log('sbProfile.startDate',bProfile.startDate);
    //console.log('==stateDateffff',value);
   // const dateb = `${startDateN.getDate()}/${startDateN.getMonth()+1}/${startDateN.getFullYear()}`;
   
    //console.log('stateDateafter',startDateN);
    console.log('dt--',minDt);
    // console.log("==startDate",startDate)

  


     console.log("==startDate",bProfile.startDate)
     console.log("==employerName", bProfile.employer.companyName)
     if(bProfile.employer.companyName == "-"){
      bProfile.employer.companyName=""
     }
     console.log("==employerNameAfter", bProfile.employer.companyName)
     console.log("==getselectedDate",bProfile.selectedDate)
    const onChangeStartDate = (name, date) => {
      console.log("e.targetDateOnly",name.$d.toISOString())
      console.log("e.target_date",date)
      let getdate=moment(name.$d.toISOString()).utc().format('YYYY-MM-DD')
      console.log("====cdate",getdate)

      let currentDate=new Date()
      const dateb = `${currentDate.getMonth()+1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
      const currentDateResult = `${currentDate.getFullYear()}-${getMonth(currentDate)}-${currentDate.getDate()}`;
      let selected_Date = new Date(name.$d.toISOString());
      const selected_Result = `${selected_Date.getFullYear()}-${getMonth(selected_Date)}-${selected_Date.getDate()}`;
      const selected_Result_state = `${getMonth(selected_Date)}/${selected_Date.getDate()}/${selected_Date.getFullYear()}`;
      console.log("==selected_Result",selected_Result)
      console.log("==selected_Result_state",selected_Result_state)

     
      const diffInDays = moment(currentDateResult).diff(moment(selected_Date), 'years');
      const diffInMonths = moment(currentDateResult).diff(moment(selected_Date), 'months');
     // const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      console.log("==no of Days",diffInDays)
      console.log("==no of Months",diffInMonths)
      dispatch(borrowerProfile({...bProfile,startDate:diffInDays,selectedDate:selected_Result_state}));  
      //dispatch(borrowerProfile({...bProfile,selectedDate:selected_Result_state})); 
      console.log("==bProfile.selectedDatelatest",bProfile.selectedDate)
      props.setStartDate(bProfile.selectedDate)
      console.log("==dateb---",dateb)
      console.log("==startDateNlll---",props.startDateN)
      
     
    };

    function getMonth(date) {
      var month = date.getMonth() + 1;
      return month < 10 ? '0' + month : '' + month; // ('' + month) for string result
    } 



  const onKeyDown = (e) => {
    e.preventDefault();
  };

    function onClickFun(e) {
      const buttonId  = e.currentTarget.id
      const input     = e.currentTarget.value

      console.log("==btnId",buttonId)
      console.log("==btnId_input",input)
      switch(buttonId) {
        case "empType":     
          dispatch(borrowerProfile({...bProfile,employmentType:input})); 
          //dispatch(borrowerProfileNew({...bProfile,employmentType:input})); 
        break;
        case "empName":     
          //dispatch(borrowerProfile({...bProfile,empName:input})); 

          const abc1 = {...bProfile.employer, companyName:input};
          dispatch(borrowerProfile({...bProfile, employer:abc1 }));

        break;
        case "startDate":     
          //dispatch(borrowerProfile({...bProfile,startDate:input}));  
        break;
        case "empstreet":     
          //dispatch(borrowerProfile({...bProfile,empAddress:input}));
          
          const abc2 = {...bProfile.employer.companyAddress, street:input};
          const abc3 = {...bProfile.employer, companyAddress:abc2};
          dispatch(borrowerProfile({...bProfile, employer:abc3 }));

        break;
        case "empzip":  
          //dispatch(borrowerProfile({...bProfile,empZip:input}));  

          const abc4 = {...bProfile.employer.companyAddress, zipCode:input};
          const abc5 = {...bProfile.employer, companyAddress:abc4};
          dispatch(borrowerProfile({...bProfile, employer:abc5 }));

        break;
        case "empcity":  
          //dispatch(borrowerProfile({...bProfile,empCity:input})); 
          
          const abc6 = {...bProfile.employer.companyAddress, city:input};
          const abc7 = {...bProfile.employer, companyAddress:abc6};
          dispatch(borrowerProfile({...bProfile, employer:abc7 }));

        break;        
        case "confirm":  
          // if (input === bProfile.password) {setWPass({ helperText: '', error: false });
          // } else { setWPass({ helperText: "Passwords don't match", error: true });
          // }
        break;
        default: break;
        }
    }
    
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
        boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
          <Box sx={{ mt:'1vw' , ml: '7.3vw', width: '26vw' }}> 
              <Typography sx={{color:'#31312C', width:'26vw',
                fontSize:'2.5vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                  Personal Profile
              </Typography>
              <Typography sx={{color:'#31312C', width:'24vw',mt:'1.7vw',
                fontSize:'1.35vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                  Please provide employer details to personalize your experience
              </Typography>
          </Box>
          <Box sx={{ mt:'0.56vw' , ml: '7.3vw', width: '28vw',height:'14vw',pr:'1vw' }}> 
            <form>
              { isSignup && (
              <>
                <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Employment type</Typography>  
                    
                    <Input disableUnderline={true} id="empType" sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'0.93vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{style: { textAlign: 'left', padding: '0.52vw' }}} name="empType" value={bProfile.employmentType} placeholder='Employment type' onChange={onClickFun} autoComplete="off"/>
                </Stack>
                <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Employer/Business</Typography>  
                    <Input disableUnderline={true} id="empName" sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'0.93vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                      inputProps={{style: { textAlign: 'left', padding: '0.52vw' }}} name="empName" value={bProfile.employer.companyName} placeholder='Name' onChange={onClickFun} autoComplete="off"/>
                </Stack>
              </>
              )}
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Street</Typography>  
                    <Input disableUnderline={true} id="empstreet" sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'0.93vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                    inputProps={{style: { textAlign: 'left', padding: '0.52vw' }}} name="empstreet" value={bProfile.employer.companyAddress.street} placeholder='Street Name' onChange={onClickFun}  autoComplete="off"/>
              </Stack>
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>City</Typography>  
                    <Input disableUnderline={true} id="empcity" sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'0.93vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                    inputProps={{style: { textAlign: 'left', padding: '0.52vw' }}} name="empcity" value={bProfile.employer.companyAddress.city} placeholder='City' onChange={onClickFun} autoComplete="off"/>
              </Stack>
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>State</Typography>  
                    
                    <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between">
                      <AutosuggestStUpdateEmp />

                      <Input disableUnderline={true} id="empzip" sx={{ ml:'0.5vw', backgroundColor:'#FFFFFF',color:"#000000",fontSize:'0.93vw', height: '2.5vw',width:"6.90vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{style: { textAlign: 'left', padding: '0.52vw' }}} name="empzip" value={bProfile.employer.companyAddress.zipCode} placeholder='Zip Code' onChange={onClickFun} autoComplete="off"/>
                    </Stack>
              </Stack>
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Start Date</Typography>  
                   
                    {/* <Box sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'0.93vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}}>
             <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}     sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'0.93vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}}/>

             </Box> */}
                   
                    {/* <Input type="date"   disableUnderline={true} id="startDate" sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'0.93vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                    inputProps={{style: { textAlign: 'center', padding: '0.52vw' ,max: "2023-04-20"}}} name="startDate" value={bProfile?.startDate} placeholder='MM/YYYY' onChange={onClickFun}  autoComplete="off"/> */}
             
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
        disableFuture={true}
        autoComplete="off"
        id="startDate"
       // onChange={onChangeStartDate}
        value={bProfile?.startDate}
        selected={bProfile?.startDate}
       // renderInput={(params) => <TextField {...params} />}
        sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'0.93vw',ml:'6.5vw',mr:'0vw', height: '3vw', width:"0vw", border:1, borderRadius:'0.5vw'}}
        inputProps={{style: { textAlign: 'center', padding: '0.52vw'}}}
        slotProps={{ textField: { size: 'small' } }}

       
        />
      </DemoContainer>
    </LocalizationProvider> */}




    
    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DemoContainer
                                    components={[
                                      'DatePicker',
                                      'DateTimePicker',
                                      'TimePicker',
                                      'DateRangePicker',
                                      'DesktopDatePicker',
                                    ]}
                                  >
                                    <DemoItem label="">
                                     

<DesktopDatePicker   disableFuture={true}  
 sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'0.93vw',ml:'0vw',mr:'0vw', height: '3vw', width:"14vw", border:1, borderRadius:'0.5vw'}}
 inputProps={{style: { textAlign: 'center', padding: '0.52vw'}}}
 slotProps={{ textField: { size: 'small' ,readOnly:true, error: false} }}
 //selected={props.startDateN}
//defaultValue={dayjs('2022-04-17')}
value={dayjs(bProfile.selectedDate)}
//value={dayjs(props.startDateN)}
 //value={startDateN}
 
 id="startDate"
 
 //onChange={(newValue) => setValue(newValue)}
 onChange={onChangeStartDate}

/>

                                    </DemoItem>



                                  </DemoContainer>
                                </LocalizationProvider>
             
             
             
              </Stack>
              
              
            </form>
          </Box>
  
          <LeftbottomA leftbottom={leftbottom}/>
      </Box>
    );
}



function LeftBlockB () {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const { bProfile } = useSelector((state)=>state.borrower);  

  const googleError = () => console.log('Google Sign In was unsuccessful. Try again later');
  
  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
        <Box sx={{ mt:'12.7vh' , ml: '7.3vw', width: '26vw' }}> 
            <Typography sx={{color:'#31312C', width:'26vw',
              fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                Dashboard : Profile
            </Typography>
            <Typography sx={{color:'#31312C', width:'24vw',mt:'1.7vw',
              fontSize:'1.35vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                Please SignIn with your Social Media Profile
            </Typography>
        </Box>
        <Box sx={{ mt:'0.56vw' , ml: '7.3vw', width: '28vw',height:'14vw' }}> 
        <SignInScreen/>
        </Box>

        <LeftbottomA leftbottom={leftbottom}/>
    </Box>
  );
}

export default DashboardProfileUpdate ;