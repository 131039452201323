import React from "react";
import { Box, Stack, CssBaseline, TextField, InputAdornment, IconButton, Typography, Button } from "@mui/material";
import { HexButton } from './ButtonIcons1';
import { useNavigate } from 'react-router-dom';
import { getWindowDimensions } from '../../computations/helperFunctions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import leftbottom from '../../assets/svg/assets svg/image-leftbottom02.png'
import aboutUsOne from '../../assets/staticPageAssets/aboutus-1.gif'

import aboutUsTwo from '../../assets/staticPageAssets/about-right-2.gif'

import aboutUsThree from '../../assets/staticPageAssets/about-right-3.png'

import aboutUsFour from '../../assets/staticPageAssets/aboutus-4.png'

// import aboutUsFive from '../../assets/staticPageAssets/-5.png'
import aboutUsFive from '../../assets/staticPageAssets/map-aboutus.png'

import aboutUsSix from '../../assets/staticPageAssets/about-right-6.png'
import aboutUsLFive from '../../assets/staticPageAssets/about-left-5.png'
import aboutUsLFour from '../../assets/staticPageAssets/about-left-4.png'
import hexaAbout1 from '../../assets/staticPageAssets/about-1.png'
import hexaAbout2 from '../../assets/staticPageAssets/about-2.png'
import hexaAbout3 from '../../assets/staticPageAssets/about-3.png'
import nestLogo from '../../assets/staticPageAssets/nest-bg.png'
import slide1 from '../../assets/staticPageAssets/about2-a.png'
import slide2 from '../../assets/staticPageAssets/about2-b.png'
import slide3 from '../../assets/staticPageAssets/about2-c.png'
import hexSlideBg from '../../assets/staticPageAssets/about-bg.png'
import ourPartnerHexa1 from '../../assets/staticPageAssets/hexa-1.png'
import ourPartnerHexa2 from '../../assets/staticPageAssets/hexa-2.png'
import ourPartnerHexa3 from '../../assets/staticPageAssets/hexa-3.png'
import ourPartnerHexa4 from '../../assets/staticPageAssets/hexa-4.png'
import locationIcon from '../../assets/staticPageAssets/ios-location-outline.png'
import mask_group from '../../assets/staticPageAssets/MaskGroup107.png'
import bullet from '../../assets/staticPageAssets/Polygon.svg'
import useStyles from './styles';
import NavbarS from "./NavBarS";
import Navbar from "./NavBar";
import { BottomBox, LeftbottomA } from "./RightBlock";
import { _debug } from "../../constants";
import { border, fontWeight, width } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex";
var allowed = "no"

function AboutUs() {
  const navigate = useNavigate();
  const { height, width } = getWindowDimensions();
  const [seek, setSeek] = React.useState(allowed);
  const [hexaText1, setHexaText1] = React.useState('Investors')
  const [hexaText2, setHexaText2] = React.useState('Technology Partners')
  const [hexaText3, setHexaText3] = React.useState('Employers')
  const [hexaText4, setHexaText4] = React.useState('Mortgage Industry')


  const [slideState, setSlideState] = React.useState({
    heading: 'Transformational Innovation',
    content: ['Thinking outside the box is in our DNA. We feel energized at the thought of new  ',
      ' challenges and embrace the idea of trying new things. Nestfuel faces old and tried',
      'problems with creativity and an open mind, allowing us to question the status quo,',
      'push boundaries and achieve real, sustainable change.'
    ],
    image: slide1,
    active: [true, false, false],


  })

  const handleSlide1 = () => {
    const headingItems = ['Transformational Innovation']
    const contentItems = ['Homeownership is a key tool for building wealth but households of ', 'color have lower ownership due to access, education, and', 'tooling']
    const footerItems = ['Urban Institute, 1990–2010 Decennial Censuses and 2007–2018 American Community Surveys.', 'Values for 2020, 2030, and 2040 are projected values.The “Other” category includes Asians, American Indians, Alaska', 'Native Hawaiians, other Pacific Islanders, and multiracial individuals.']
    setSlideState({
      heading: headingItems,
      content: contentItems,
      image: slide1,
      active: [true, false, false],




    })
  }


  const handleSlide2 = () => {
    const headingItems = ['Unfiltered Education']
    const contentItems = ['We are committed to education as a means of risk reduction, empowering ',
      'homebuyers to understand the ins and outs of the process and make well-informed',
      'decisions about their finances.']
    const footerItems = ['Urban Institute, 1990–2010 Decennial Censuses and 2007–2018 American Community Surveys.', 'Values for 2020, 2030, and 2040 are projected values.The “Other” category includes Asians, American Indians, Alaska', 'Native Hawaiians, other Pacific Islanders, and multiracial individuals.']
    setSlideState({
      heading: headingItems,
      content: contentItems,
      image: slide2,
      active: [false, true, false],




    })

  }
  //There is a disparity in financial education - credit scores dont 
  //tell the whole story and adversely impact certain segments of society, 
  //preventing access to finance
  const handleSlide3 = () => {
    const headingItems = ['Inspired Community']
    const contentItems = ['We are stronger with supporting relationships, with our neighbours, our friends, our employers ',
      'that tell stories of advserse impact... to certain segments of society,',
      'employers within our communities... share ideas, passion and goals.']

    setSlideState({
      heading: headingItems,
      content: contentItems,
      image: slide3,
      active: [false, false, true],
    })
  }


  const handleHexa1 = () => {
    setHexaText1('Investors')
    setHexaText2('')
    setHexaText3('')
    setHexaText4('')
  }
  const handleHexa2 = () => {
    setHexaText1('')
    setHexaText2('Technology Partners')
    setHexaText3('')
    setHexaText4('')
  }

  const handleHexa3 = () => {
    setHexaText1('')
    setHexaText2('')
    setHexaText3('Employers')
    setHexaText4('')
  }

  const handleHexa4 = () => {
    setHexaText1('')
    setHexaText2('')
    setHexaText3('')
    setHexaText4('Mortgage Industry')
  }

  // if(_debug === 'development') allowed = 'yes'
  allowed = 'yes'
  return (
    <React.Fragment><CssBaseline />
      <Box sx={{ overflow: 'hidden', width: '100%', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <Navbar />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <LeftBlockA />

          <Box sx={{ width: '14vw', justifyContent: 'center' }}>
            <Stack sx={{ mr: '2.0vw', ml: '3.0vw', mt: '19vw', height: '10vw', width: '10vw' }} direction="row">
              <Button sx={{ overflow: 'hidden', mt: 0, zIndex: 1, border: 0, width: '8.0vw', position: 'fixed', height: '10vw', minHeight: 0, minWidth: 0, padding: '0.2vw' }} id="Discover" className='hexBtnCenter'
                onClick={() => allowed === 'yes' ? navigate("/Discover", { replace: false }) : setSeek("ddd")} >
                <HexButton titleText="Discover Nestfuel" type='large' fontSize='24' />
              </Button>
            </Stack>
            {seek == 'ddd' ? <InputKey /> : <></>}
          </Box>

          <RightBlockA />
        </Box>

        <Box id="Values" sx={{ mt: 0, width: '100%', height: '56vw' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: '36vw' }}>
              <Typography sx={{
                ml: '7.2vw', mt: '8vw', color: '#31312C', fontWeight: 300,
                font: "Roboto", textAlign: 'left'
              }} fontSize='5vw' lineHeight={1.2} letterSpacing='-1.6px'>
                Our Values</Typography>
              <Typography sx={{
                mt: '1.9vw', ml: '7.2vw', color: '#8f8f8f',
                font: "Roboto", textAlign: 'left'
              }} fontSize='2.5vw' lineHeight={1.2} fontWeight='300'>
                Our vision of transforming home buying experience is built on our core values</Typography>
            </Box>
            <Box sx={{ ml: '14vw', width: '50vw', border: 0, height: '50vw' }} >
              {/* <img src={aboutUsTwo} alt = "Loading..." width="100%" heigh="100%"/>  */}
              <Box sx={{ marginLeft: '34.4vw', marginTop: '10.6vw', width: '8.6vw', height: '5.7vw' }}>
                <img src={hexSlideBg} alt = "Loading..." width="100%" heigh="100%" />

              </Box>
              <Box sx={{ marginLeft: '12.6vw', marginTop: '-5vw', width: '25.6vw', height: '17.14vw' }}>
                <img src={slideState.image} alt = "Loading..." width="100%" heigh="100%" />

              </Box>
              <Box sx={{ marginLeft: '7vw', marginTop: '-6vw', width: '8.6vw', height: '5.7vw' }}>
                <img src={hexSlideBg} alt = "Loading..." width="100%" heigh="100%" />

              </Box>
              <Box sx={{ marginLeft: '10.89vw', marginTop: '3.85vw', width: '28.6vw', height: '3.28vw' }}>
                <Typography sx={{
                  color: '#31312c',
                  font: "Roboto", textAlign: 'left'
                }} fontSize='2.0vw' lineHeight={1.2} fontWeight='medium'>
                  {slideState.heading}</Typography>
              </Box>

              <Box sx={{ marginLeft: '9.5vw', marginTop: '2.4vw', width: '31.35vw', height: '7vw' }}>
                <Typography sx={{
                  color: '#585858',
                  font: "Roboto", textAlign: 'center'
                }} fontSize='0.8vw' lineHeight={1.2} fontWeight='normal'>
                  {slideState.content[0] ? slideState.content[0] : ''}    </Typography>
                <Typography sx={{
                  mt: '0.5vw', color: '#585858',
                  font: "Roboto", textAlign: 'center'
                }} fontSize='0.8vw' lineHeight={1.2} fontWeight='normal'>
                  {slideState.content[1] ? slideState.content[1] : ''}    </Typography>
                <Typography sx={{
                  mt: '0.5vw', color: '#585858',
                  font: "Roboto", textAlign: 'center'
                }} fontSize='0.8vw' lineHeight={1.2} fontWeight='normal'>
                  {slideState.content[2] ? slideState.content[2] : ''}    </Typography>
                <Typography sx={{
                  mt: '0.5vw', color: '#585858',
                  font: "Roboto", textAlign: 'center'
                }} fontSize='0.8vw' lineHeight={1.2} fontWeight='normal'>
                  {slideState.content[3] ? slideState.content[3] : ''}    </Typography>


              </Box>
              <Box sx={{ marginLeft: '24vw', marginTop: '1.67vw', width: '4.2vw', height: '1.18vw' }}>
                <Box sx={{ width: '.9vw', height: '0.9vw', borderRadius: '50%', display: 'inline-block', border: '1px solid #707070', backgroundColor: slideState.active[0] == true ? '#707070' : '#fff' }} onMouseEnter={handleSlide1}></Box>
                <Box sx={{ width: '.9vw', height: '0.9vw', borderRadius: '50%', display: 'inline-block', border: '1px solid #707070', backgroundColor: slideState.active[1] == true ? '#707070' : '#fff' }} onMouseEnter={handleSlide2}></Box>
                <Box sx={{ width: '.9vw', height: '0.9vw', borderRadius: '50%', display: 'inline-block', border: '1px solid #707070', backgroundColor: slideState.active[2] == true ? '#707070' : '#fff' }} onMouseEnter={handleSlide3}></Box>



              </Box>

            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <LeftBlockB />
          <RightBlockB />
        </Box>

        <Box id="Partners" sx={{ mt: 0, width: '100%', height: '50vw' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: '36vw' }}>
              {/* <img src={aboutUsLFour} alt = "Loading..." width="100%" heigh="100%"/>  */}
              <Box sx={{ marginLeft: '7.19vw', marginTop: '8.7vw', width: '25.6vw', height: '6vw' }}>
                <Typography sx={{
                  color: '#000c',
                  font: "Roboto", textAlign: 'left'
                }} fontSize='4.6vw' lineHeight={1.2} fontWeight='300'>
                  <p style={{ "position": 'relative' }}> Our Partners</p></Typography>
              </Box>

              <Box sx={{ marginLeft: '7.19vw', marginTop: '5.6vw', width: '22.2vw', height: '6.5vw' }}>
                <Typography sx={{
                  color: '#8f8f8f',
                  font: "Roboto", textAlign: 'left'
                }} fontSize='2.5vw' lineHeight={1.2} fontWeight='300'>
                  Who is helping us on our mission?</Typography>
              </Box>
              <Box sx={{ marginLeft: '7.19vw', marginTop: '5vw', width: '26.4vw', height: '2.9vw', display: 'flex', flexDirection: 'row' }}>
                {/* <Box sx={{marginLeft:'0vw',marginTop:'0.8vw',width:'20vw',height:'2.9vw',border:'1px solid #fff'}}> */}
                {/* <Typography sx={{mt:'1vw',ml:'.5vw',color:'#31312c',
                        font: "Roboto", textAlign:'left'}} fontSize='1.1vw'lineHeight={1.2} fontWeight='300'> 
                      Interested in partnering with Nestfuel</Typography> */}
                {/* <input style={{'width':'100%','height':'100%','border':'1px sloid #fffs'}} placeholder="Interested in partnering with Nestfuel"></input> */}
                {/* </Box> */}
                <Box sx={{ marginLeft: '6vw', marginTop: '0.8vw', width: '6.2vw', height: '2.9vw', background: '#31312c', borderRadius: '0.26vw' }}>
                  <a href="mailto: info@nestfuel.com" style={{ 'textDecoration': "none" }}>



                    <Typography sx={{
                      mt: '.7vw', ml: '0.8vw', color: '#e3e3e3',
                      font: "Roboto", textAlign: 'left'
                    }} fontSize='1.1vw' lineHeight={1.2} fontWeight='bold'>
                      Nestfuel</Typography>  </a>
                </Box>
              </Box>

            </Box>


            <Box sx={{ ml: '14vw', mt: '1vw', width: '50vw' }}>

              <Box sx={{ marginLeft: '17.43vw', marginTop: '1.07vw', width: '16.88vw', height: '18.60vw', postion: 'relative', zIndex: '0' }}>
                <img src={ourPartnerHexa1} alt = "Loading..." width="100%" height="100%" />


                <Typography sx={{
                  mt: '-10.5vw', ml: '4.2vw', color: '#e3e3e3',
                  font: "Roboto", textAlign: 'center'
                }} fontSize='2.0vw' lineHeight={1.2} fontWeight='bold' position='absolute' zIndex='1'>
                  {hexaText1} </Typography>

                {/* <img src={ourPartnerHexa1} alt = "Loading..." width="100%" heigh="100%"/> */}


              </Box>
              <Box sx={{ display: 'flex', marginTop: '-5vw' }}>

                <Box sx={{ marginLeft: '9.5vw', marginTop: '-1.2vw', width: '16.88vw', height: '18.60vw', position: 'relative', zIndex: '0' }}>
                  <img src={ourPartnerHexa2} alt = "Loading..." width="100%" heigh="100%" style={{ 'position': 'relative' }} />

                  <Box sx={{ marginLeft: '3.5vw', marginTop: '6.5vw', width: '6.2vw', height: '2.9vw', zIndex: '1', position: 'absolute' }}>


                    <Typography sx={{
                      mt: '-18.0vw', ml: '-.5vw', color: '#e3e3e3',
                      font: "Roboto", textAlign: 'center'
                    }} fontSize='2.0vw' lineHeight={1.2} fontWeight='bold' position='absolute' zIndex='1'>
                      {hexaText2} </Typography>
                  </Box>

                  {/* <img src={ourPartnerHexa1} alt = "Loading..." width="100%" heigh="100%"/> */}

                </Box>
                <Box sx={{ marginLeft: '-1.5vw', marginTop: '-1.2vw', width: '16.88vw', height: '18.60vw', position: 'relative', zIndex: '0' }}>
                  <img src={ourPartnerHexa4} alt = "Loading..." width="100%" heigh="100%" style={{ 'position': 'relative' }} />

                  <Box sx={{ marginLeft: '3.5vw', marginTop: '8vw', width: '6.2vw', height: '2.9vw', zIndex: '1', position: 'absolute' }}>
                    <Typography sx={{
                      mt: '-18.4vw', ml: '.7vw', color: '#e3e3e3',
                      font: "Roboto", textAlign: 'left'
                    }} fontSize='2.0vw' lineHeight={1.2} fontWeight='bold' position='absolute' zIndex='1'>
                      {hexaText3} </Typography>
                  </Box>
                  {/* <img src={ourPartnerHexa1} alt = "Loading..." width="100%" heigh="100%"/> */}

                </Box>
              </Box>

              <Box sx={{ marginLeft: '17.43vw', marginTop: '-6.2vw', width: '16.88vw', height: '18.60vw' }}>
                <img src={ourPartnerHexa3} alt = "Loading..." width="100%" heigh="100%" style={{ 'position': 'relative' }} />
                <Box sx={{ marginLeft: '3.5vw', marginTop: '8vw', width: '6.2vw', height: '2.9vw', zIndex: '1', position: 'absolute' }}>
                  <Typography sx={{
                    mt: '-19.4vw', ml: '.7vw', color: '#000',
                    font: "Roboto", textAlign: 'center'
                  }} fontSize='2.0vw' lineHeight={1.2} fontWeight='bold' position='absolute' zIndex='1'>
                    {hexaText4} </Typography>
                </Box>

              </Box>


              <Box>

              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <LeftBlockC />
          <RightBlockC />
        </Box>

        <Box id="Positions" sx={{ width: '100%', height: '50vw' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ width: '36vw' }}>
              <Typography sx={{
                mt: '7.6vw', ml: '7.2vw', color: '#31312C', fontWeight: 300,
                font: "Roboto", textAlign: 'left'
              }} fontSize='5vw' lineHeight={1.2} letterSpacing='-1.6px'>
                Grow </Typography>
              <Typography sx={{
                ml: '7.2vw', color: '#31312C', fontWeight: 300,
                font: "Roboto", textAlign: 'left'
              }} fontSize='5vw' lineHeight={1.2} letterSpacing='-1.6px'>
                with us</Typography>
              <Typography sx={{
                mt: 3, ml: '7.2vw', color: '#8f8f8f', fontWeight: '300',
                fontSize: '3vw', font: "Roboto"
              }} align='left' lineHeight={1.2} letterSpacing='-1.6px'>
                Join the team </Typography>
              <Typography sx={{
                mt: 1, ml: '7.2vw', color: '#8f8f8f', fontWeight: '300',
                fontSize: '3vw', font: "Roboto"
              }} align='left' lineHeight={1.2} letterSpacing='-1.6px'>
                democratising</Typography>
              <Typography sx={{
                mt: 1, ml: '7.2vw', color: '#8f8f8f', fontWeight: '300',
                fontSize: '3vw', font: "Roboto"
              }} align='left' lineHeight={1.2} letterSpacing='-1.6px'>
                home ownership </Typography>
            </Box>
            <Box sx={{ ml: '14vw', width: '50vw', height: '50vw' }}>
              {/* <img src={aboutUsSix} alt = "Loading..." width="100%" heigh="100%"/> */}
              <Box sx={{ marginLeft: '7vw', marginTop: '3.33vw', width: '10.31vw', height: '3vw' }}>
                <Typography sx={{
                  mt: '0', ml: '1.7vw', color: '#31312c', fontWeight: 'medium',
                  fontSize: '1.87vw', font: "Roboto"
                }} align='left' lineHeight={1.2} letterSpacing='-1.1px'>
                  Open roles </Typography>
              </Box>
              <Box sx={{ marginLeft: '7vw', marginTop: '3.07vw', width: '37.92vw', height: '3.65vw', border: '1px solid #BCBCBC', borderRadius: '0.78vw' }}>
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ marginLeft: '1.82vw', marginTop: '1.09vw', width: '14.22vw', height: '1.51vw' }}>
                    <Typography sx={{
                      mt: '0vw', ml: '0vw', color: '#000', fontWeight: 'bold',
                      fontSize: '1.1vw', font: "Roboto"
                    }} align='left' justifyContent='center' lineHeight={1.2} letterSpacing='0px'>
                      MORTGAGE LOAN OFFICER </Typography>
                  </Box>
                  <Box sx={{ marginLeft: '5vw', marginTop: '1.1vw', width: '0.56vw', height: '0.86vw' }}>
                    <img src={locationIcon} alt = "Loading..." width="100%" heigh="100%" />

                  </Box>
                  <Box sx={{ marginLeft: '1.22vw', marginTop: '1.4vw', width: '3.33vw', height: '0.99vw' }}>
                    <Typography sx={{
                      mt: '0vw', ml: '0vw', color: '#000', fontWeight: 'normal',
                      fontSize: '0.53vw', font: "Roboto"
                    }} align='left' justifyContent='left' lineHeight={1.2} letterSpacing='0px'>
                      Hybrid CO </Typography>
                  </Box>
                  <Box sx={{ marginLeft: '4.05vw', marginTop: '.36vw', width: '7.45vw', height: '2.97vw', background: "#000", borderRadius: '0.52vw' }}>
                    <a style={{ textDecoration: 'none' }} href='https://www.indeed.com/viewjob?t=mortgage+loan+officer+hybrid+colorado&
jk=564a56e319978cdf&_gl=1*mlrz6w*_gcl_aw*R0NMLjE2NTI3OTk0NzAuQ2p3S0NBandqNDJVQmhBQUVpd0FDSWhBRHNZOUt0UFNaQ2tPUjR2
TE12bjBxanozbXBOUVhQb1dQbjJBcDdrcDdJcFFubXh0M200SWtCb0NqWG9RQXZEX0J3RQ..&_ga=2.212940628.1773515000.1657659248-364243141.1649067406'
                      target="popup"
                      onclick="window.open('https://www.indeed.com/viewjob?t=mortgage+loan+officer+hybrid+colorado&
  jk=564a56e319978cdf&_gl=1*mlrz6w*_gcl_aw*R0NMLjE2NTI3OTk0NzAuQ2p3S0NBandqNDJVQmhBQUVpd0FDSWhBRHNZOUt0UFNaQ2tPUjR2
  TE12bjBxanozbXBOUVhQb1dQbjJBcDdrcDdJcFFubXh0M200SWtCb0NqWG9RQXZEX0J3RQ..&_ga=2.212940628.1773515000.1657659248-364243141.1649067406','popup','width=600,height=600'); return false;"> <Button><Typography sx={{
                        mt: '0.65vw', ml: '1.7vw', color: '#FFFFFF', fontWeight: 'bold',
                        fontSize: '1vw', font: "Roboto"
                      }} align='center' justifyContent='center' lineHeight={1.2} letterSpacing='0px'>
                        Apply </Typography> </Button>
                    </a>
                  </Box>
                </Box>

              </Box>
              <Box sx={{ marginLeft: '7vw', marginTop: '0.73vw', width: '37.92vw', height: '3.65vw', border: '1px solid #BCBCBC', borderRadius: '0.78vw' }}>
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ marginLeft: '1.82vw', marginTop: '1.09vw', width: '14.22vw', height: '1.51vw' }}>
                    <Typography sx={{
                      mt: '0vw', ml: '0vw', color: '#000', fontWeight: 'bold',
                      fontSize: '1.1vw', font: "Roboto"
                    }} align='left' justifyContent='center' lineHeight={1.2} letterSpacing='0px'>
                      BUSINESS ANALYST </Typography>
                  </Box>
                  <Box sx={{ marginLeft: '5vw', marginTop: '1.1vw', width: '0.56vw', height: '0.86vw' }}>
                    <img src={locationIcon} alt = "Loading..." width="100%" heigh="100%" />


                  </Box>
                  <Box sx={{ marginLeft: '1.22vw', marginTop: '1.4vw', width: '3.33vw', height: '0.99vw' }}>
                    <Typography sx={{
                      mt: '0vw', ml: '0vw', color: '#000', fontWeight: 'normal',
                      fontSize: '0.53vw', font: "Roboto"
                    }} align='left' justifyContent='left' lineHeight={1.2} letterSpacing='0px'>
                      Denver, CO</Typography>
                  </Box>
                  <Box sx={{ marginLeft: '4.05vw', marginTop: '.36vw', width: '7.45vw', height: '2.97vw', background: "#000", borderRadius: '0.52vw' }}>
                    <a style={{ textDecoration: 'none' }} href='https://www.indeed.com/viewjob?t=business+analyst+mortgage+company&
jk=09556221abd252df&_gl=1*mlrz6w*_gcl_aw*R0NMLjE2NTI3OTk0NzAuQ2p3S0NBandqNDJVQmhBQUVpd0FDSWhBRHNZOUt0UFNaQ2t
PUjR2TE12bjBxanozbXBOUVhQb1dQbjJBcDdrcDdJcFFubXh0M200SWtCb0NqWG9RQXZEX0J3RQ..&
_ga=2.212940628.1773515000.1657659248-364243141.1649067406'
                      target="popup"
                      onclick="window.open('https://www.indeed.com/viewjob?t=business+analyst+mortgage+company&
  jk=09556221abd252df&_gl=1*mlrz6w*_gcl_aw*R0NMLjE2NTI3OTk0NzAuQ2p3S0NBandqNDJVQmhBQUVpd0FDSWhBRHNZOUt0UFNaQ2t
  PUjR2TE12bjBxanozbXBOUVhQb1dQbjJBcDdrcDdJcFFubXh0M200SWtCb0NqWG9RQXZEX0J3RQ..&
  _ga=2.212940628.1773515000.1657659248-364243141.1649067406','popup','width=600,height=600'); return false;"><Button><Typography sx={{
                        mt: '0.65vw', ml: '1.7vw', color: '#FFFFFF', fontWeight: 'bold',
                        fontSize: '1vw', font: "Roboto"
                      }} align='center' justifyContent='center' lineHeight={1.2} letterSpacing='0px'>
                        Apply </Typography> </Button></a>
                  </Box>
                </Box>

              </Box>

              <Box sx={{ marginLeft: '7vw', marginTop: '0.73vw', width: '37.92vw', height: '3.65vw', border: '1px solid #BCBCBC', borderRadius: '0.78vw' }}>
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ marginLeft: '1.82vw', marginTop: '1.09vw', width: '14.22vw', height: '1.51vw' }}>
                    <Typography sx={{
                      mt: '0vw', ml: '0vw', color: '#000', fontWeight: 'bold',
                      fontSize: '1.1vw', font: "Roboto"
                    }} align='left' justifyContent='center' lineHeight={1.2} letterSpacing='0px'>
                      LOAN ADMIN </Typography>
                  </Box>
                  <Box sx={{ marginLeft: '5vw', marginTop: '1.1vw', width: '0.56vw', height: '0.86vw' }}>
                    <img src={locationIcon} alt = "Loading..." width="100%" heigh="100%" />

                  </Box>
                  <Box sx={{ marginLeft: '1.22vw', marginTop: '1.4vw', width: '5.36vw', height: '0.99vw' }}>
                    <Typography sx={{
                      mt: '0vw', ml: '0vw', color: '#000', fontWeight: 'normal',
                      fontSize: '0.53vw', font: "Roboto"
                    }} align='left' justifyContent='left' lineHeight={1.2} letterSpacing='0px'>
                      Los Angeles, CA </Typography>
                  </Box>
                  <Box sx={{ marginLeft: '1.97vw', marginTop: '.36vw', width: '7.45vw', height: '2.97vw', background: "#000", borderRadius: '0.52vw' }}>
                    <a style={{ textDecoration: 'none' }} href='https://www.indeed.com/viewjob?t=entry+level+loan+admin&jk=639fc8f2c1eb40aa&_gl=1*mlrz6w*
_gcl_aw*R0NMLjE2NTI3OTk0NzAuQ2p3S0NBandqNDJVQmhBQUVpd0FDSWhBRHNZOUt0UFNaQ2tPUjR2TE12bjBxanozbXBOUVhQb1dQbjJBcDdrcDdJcFFubXh0M200SWtCb0NqWG9RQXZEX0J3RQ..&
_ga=2.212940628.1773515000.1657659248-364243141.1649067406'
                      target="popup"
                      onclick="window.open('https://www.indeed.com/viewjob?t=entry+level+loan+admin&jk=639fc8f2c1eb40aa&_gl=1*mlrz6w*
  _gcl_aw*R0NMLjE2NTI3OTk0NzAuQ2p3S0NBandqNDJVQmhBQUVpd0FDSWhBRHNZOUt0UFNaQ2tPUjR2TE12bjBxanozbXBOUVhQb1dQbjJBcDdrcDdJcFFubXh0M200SWtCb0NqWG9RQXZEX0J3RQ..&
  _ga=2.212940628.1773515000.1657659248-364243141.1649067406','width=600,height=600'); return false;"><Button><Typography sx={{
                        mt: '0.65vw', ml: '1.7vw', color: '#FFFFFF', fontWeight: 'bold',
                        fontSize: '1.1vw', font: "Roboto"
                      }} align='center' justifyContent='center' lineHeight={1.2} letterSpacing='0px'>
                        Apply </Typography> </Button></a>
                  </Box>
                </Box>

              </Box>

              <Box sx={{ marginLeft: '4.75vw', marginTop: '3.5vw', width: '43.65vw', height: '13.39vw' }}>
                <Typography sx={{
                  mt: '0', ml: '0vw', color: '#31312c', fontWeight: '300',
                  fontSize: '1.15vw', font: "Roboto"
                }} align='left' lineHeight={1.2} letterSpacing='0px'>
                  We are changing the entire experience of home ownership providing a catalyst for change across the entire journey.
                  We set out to provide these goals in a new way – people first, enabled by technology and innovation. Nestfuel is always looking for talented people who share our passions and goals.

                  <Typography sx={{
                    mt: '2vw', ml: '0vw', color: '#31312c', fontWeight: '300',
                    fontSize: '1.15vw', font: "Roboto"
                  }} align='left' lineHeight={1.2} letterSpacing='0px'>
                    If you have that spirit of embracing change,
                    are unafraid to challenge existing models and curious to see what is possible, contact us here.</Typography> </Typography>
              </Box>

              <Box sx={{ marginLeft: '19.53vw', marginTop: '2.76vw', width: '13.5vw', height: '3vw', background: "#000", borderRadius: '0.52vw' }}>

                <Button>
                  <a href="mailto: info@nestfuel.com" style={{ 'textDecoration': "none" }}>
                    <Typography sx={{
                      mt: '0.65vw', ml: '3vw', color: '#FFFFFF', fontWeight: 'bold',
                      fontSize: '1vw', font: "Roboto"
                    }} align='center' justifyContent='center' lineHeight={1.2} letterSpacing='0px'>
                      Contact us </Typography>
                  </a>
                </Button>
              </Box>




            </Box>
          </Box>

        </Box>
        <Box></Box>
        <BottomBox />
      </Box>
    </React.Fragment>
  );
}

function RightBlockA() {
  const [content, setContent] = React.useState('Transforming Home Finance Experience')
  const [counter, setCounter] = React.useState(0);
  const [img, setImg] = React.useState(hexaAbout1)
  const rightContentA = [
    "Transforming Home Finance Experience",
    "Affordability for more Americans",
    "Supported by Employer Benefits",
  ]
  const imgArr = [hexaAbout1, hexaAbout2, hexaAbout3]

const handleHover =()=>{
  setContent(rightContentA[counter])
  setImg(imgArr[counter])
  if(counter>=2){
    setCounter(0)
  }
  else{
    setCounter(counter+1)
  }
  console.log("Here")
}
  return ( 
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',margin:0,
        boxShadow: '-1px -6px 46px #0000003D',opacity: 1, width: '50vw',height:'46.75vw' }}>
        
              {/* <img src={aboutUsOne} alt = "Loading..." width="100%" heigh="100%"/>    */}
              <Box sx={{ mt:'7.7vw' , ml: '7.6vw', width: '40.36vw',height:'12.19vw' }}> 
            <Typography sx={{color:'#8f8f8f',
             fontSize:'4.64vw', fontFamily:'Roboto',fontWeight:'bold',textAlign:'left',lineHeight:1.55,letterSpacing: '-0.13vw'}}> 
                {content}
            </Typography>
        </Box> 
        <Box sx={{ mt:'0.5vw' , ml: '5.89vw', width: '39.89vw',height:'30.89vw'}}onMouseOver={handleHover}> 
              <img src={img} alt = "Loading..." width="100%" heigh="100%"/>   
           
        </Box> 
       
      </Box>
    );
}


function RightBlockB() {
  return (
    <Box sx={{
      ml: '14vw', background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '-1px -6px 46px #0000003D', opacity: 1, width: '50vw', height: '50vw'
    }}>
      {/* ,backgroundImage:`url(${nestLogo})`,backgroundRepeat:'no-repeat',postion:'absolute'  */}
      <Box sx={{ marginLeft: '4.17vw', marginTop: '7.5vw', width: '43.65vw', height: '22.9vw', position: 'relative' }}>
        {/* <img style={{"marginLeft":"10vw","marginTop":'-2vw'}} src={nestLogo} alt = "Loading..." width="50%" height="100%"/>   */}



        <Box sx={{ marginTop: '0vw', width: '40vw' }}>
          <Box sx={{ width: '20vw', height: '3vw' }}>
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '1vw', fontWeight: 'bold', textDecoration: 'underline' }}>
              My story - Scott, Co-founder of Nestfuel

            </Typography>
          </Box>
          <Typography sx={{ color: '#31312C', fontFamily: 'Roboto', fontSize: '1vw' }}>
            Nearly 10% of mortgage applications are denied, many more Americans don’t even bother trying and yet homeownership is part of achieving the American dream and building your wealth – its becoming more and more out of reach. Digging into these numbers they are staggeringly worse when we look at minorities and our youth. The existing lending model is backward looking and built on loans geared to certain historic underwriting standards, the world is changing and yet mortgage finance is not.

          </Typography>
          <Typography sx={{ mt: '1vw', fontFamily: 'Roboto', color: '#31312C', fontSize: '1vw' }}>
            From my journey I know there are other ways to allow access to mortgage finance, not as lazy and requiring more outreach but they exist and we want to bring these to more Americans,democratizing home ownership. I look at my children and their future and see how the existing model doesn’t serve them – lack of deposit, rigid debt to income ratios, unable to tap into their community. The Nestfuel solution will help them and many more like them.

          </Typography>
        </Box>
        {/* 
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',position:'relative'}}>

              <Box sx={{width:"32vw",height:'32vw'}}>

              </Box>
              <Box sx={{marginLeft:'4.17vw',marginTop:'13.13vw',width:'43.65vw',height:'22.9vw'}}>
            My story - Scott, Co-founder of Nestfuel Nearly 10% of mortgage applications are denied, many more americans don’t even bother trying and yet homeownership is part of achieving the American dream and building your wealth – its becoming more and more out of reach. Digging into these numbers they are staggeringly worse when we look at minorities and our youth. The existing model is backward looking and built on loans geared to certain standards… the world is changing and yet mortgage finance is not. From my journey I know there are other ways to allow access, not as lazy and requiring more outreach but they exist and we want to bring these to more Americans…democratizing home ownership. I look at my children and their future and see how the existing model doesn’t serve them – lack of deposit, rigid debt to income ratios, unable to tap into their community. The Nestfuel solution will help them and many more like them.
</Box>
            </Box> */}


        {/*  */}
        {/* <img src={nestLogo} align='center' alt = "Loading..." width="50%" height="100%"/>    */}

      </Box>

      <Box sx={{ width: '50vw', height: '19.7vw' }}>
        <img src={mask_group} align='center' alt = "Loading..." width="100%" height="100%" />


      </Box>


    </Box>
  );
}

function RightBlockC() {
  return (
    <Box sx={{
      ml: '14vw', background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '-1px -6px 46px #0000003D', opacity: 1, width: '50vw', height: '50vw'
    }}>
      <Box sx={{ marginLeft: '8.6vw', marginTop: '7.2vw' }}>
        {/* map */}
        <img src={aboutUsFive} alt = "Loading..." width="100%" heigh="100%" />

      </Box>

    </Box>
  );
}


function LeftBlockA() {
  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box' ,position:'relative',
    boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
      <Box sx={{ mt:'8.4vw' , ml: '7.34vw', width: '14.43vw',height:'12.6vw' }}> 
            <Typography sx={{color:'#8f8f8f',
             fontSize:'4.64vw', fontFamily:'Roboto',fontWeight:'bold',textAlign:'left',lineHeight:1.55,letterSpacing: '-0.13vw'}}> 
                 Smart Lender
            </Typography>
        </Box>  
              <Box sx={{ mt:'1.51vw' , ml: '7.34vw', width: '26.6vw',height:'3vw' }}> 
            <Typography sx={{color:'#8f8f8f',
             fontSize:'3vw', fontFamily:'Roboto',fontWeight:'bold',textAlign:'left',lineHeight:1.55,letterSpacing: '-0.13vw'}}> 
                 Driven by Technology
            </Typography>
        </Box>
          <LeftbottomA leftbottom={leftbottom}/>
    </Box>
  );
}

function LeftBlockB() {
  return (
    <Box id="Story" sx={{ background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '50vw' }}>
      <Box sx={{ ml: '7.2vw', mt: '5.2vw', width: '33vw' }}>
        <Typography sx={{
          color: '#000000', fontWeight: '300',
          font: "Roboto", textAlign: 'left'
        }} fontSize='5vw' lineHeight={1.2} letterSpacing='-1.6px'>
          Our Story
        </Typography>
        <Typography sx={{
          mt: '1.6vw', color: '#8f8f8f', fontWeight: '400',
          font: "Roboto", textAlign: 'left'
        }} fontSize='3vw' lineHeight={1.2}>
          There has to be
        </Typography>
        <Typography sx={{
          mt: 0, color: '#8f8f8f', fontWeight: '400',
          font: "Roboto", textAlign: 'left'
        }} fontSize='3vw' lineHeight={1.2}>
          a better way
        </Typography>
      </Box>
    </Box>
  );
}

function LeftBlockC() {
  return (
    <Box id="Locations" sx={{ background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '50vw' }}>
      {/* <Box width='36vw' height='33vh'> 
              <img src={aboutUsLFive} alt = "Loading..." width="90%" heigh="100%"/>   
            </Box> */}
      <Box sx={{ width: '36vw' }}>
        <Typography sx={{
          ml: '7.2vw', mt: '8vw', color: '#31312C', fontWeight: 300,
          font: "Roboto", textAlign: 'left'
        }} fontSize='5vw' lineHeight={1.2} letterSpacing='-1.6px'>
          Our Presence
        </Typography>
        <Typography sx={{
          mt: '2vw', ml: '7.2vw', color: '#8f8f8f',
          font: "Roboto", fontWeight: 'light', textAlign: 'left'
        }} fontSize='2.4vw' lineHeight={1.2}>
          Making a difference
        </Typography>
        <Typography sx={{
          mt: '2vw', ml: '7.2vw', color: '#8f8f8f',
          font: "Roboto", fontWeight: 'light', textAlign: 'left'
        }} fontSize='2.4vw' lineHeight={1.2}>
          in your Community
        </Typography>
        <Box sx={{ display: 'flex', marginTop: '9.07vh' }}>
          {/* <svg width={15} height={15} style={{marginLeft:'8.24vw',marginTop:'9.07vh'}}>
      <polygon
        points={{
          top: [`${15 / 2},0`, `0,${15}`, `${15},${15}`],
          right: [`0,0`, `0,${15}`, `${15},${15 / 2}`],
          bottom: [`0,0`, `${15},0`, `${15 / 2},${15}`],
          left: [`${15},0`, `${15},${15}`, `0,${15 / 2}`],
        }["right"].join(' ')}
        fill={'#44a6e8'}
      />
      
      
    
    </svg> */}
          <Box sx={{ width: '1.15vw', height: '2.31vh', marginLeft: '8.28vw' }}>
            <img src={bullet} height='100%'></img>
          </Box>


          <Box sx={{ width: '21.46vw', height: '3.43vh', marginTop: '-0.3vw' }}>
            <Typography sx={{
              mt: '0vh', ml: '0.50vw', color: '#31312C',
              font: "Roboto", textAlign: 'left'
            }} fontSize='1.5vw' lineHeight={1.2}>
              We apply local knowledge
            </Typography>
          </Box>


        </Box>
        <Box sx={{ display: 'flex', marginTop: '9.07vh' }}>
          {/* <svg width={15} height={15} style={{marginLeft:'8.24vw',marginTop:'9.07vh'}}>
      <polygon
        points={{
          top: [`${15 / 2},0`, `0,${15}`, `${15},${15}`],
          right: [`0,0`, `0,${15}`, `${15},${15 / 2}`],
          bottom: [`0,0`, `${15},0`, `${15 / 2},${15}`],
          left: [`${15},0`, `${15},${15}`, `0,${15 / 2}`],
        }["right"].join(' ')}
        fill={'#44a6e8'}
      />
      
      
    
    </svg> */}
          <Box sx={{ width: '1.15vw', height: '2.31vh', marginLeft: '8.28vw' }}>
            <img src={bullet} height='100%'></img>
          </Box>


          <Box sx={{ width: '18vw', height: '0.28vh', marginTop: '-0.5vh' }}>
            <Typography sx={{
              mt: '0vh', ml: '.5vw', color: '#31312C',
              font: "Roboto", textAlign: 'left'
            }} fontSize='1.5vw' lineHeight={1.2}>
              Understand your market
            </Typography>
          </Box>


        </Box>
        <Box sx={{ display: 'flex', marginTop: '9.07vh' }}>
          {/* <svg width={15} height={15} style={{marginLeft:'8.24vw',marginTop:'9.07vh'}}>
      <polygon
        points={{
          top: [`${15 / 2},0`, `0,${15}`, `${15},${15}`],
          right: [`0,0`, `0,${15}`, `${15},${15 / 2}`],
          bottom: [`0,0`, `${15},0`, `${15 / 2},${15}`],
          left: [`${15},0`, `${15},${15}`, `0,${15 / 2}`],
        }["right"].join(' ')}
        fill={'#44a6e8'}
      />
      
      
    
    </svg> */}
          <Box sx={{ width: '1.15vw', height: '2.31vh', marginLeft: '8.28vw' }}>
            <img src={bullet} height='100%'></img>
          </Box>


          <Box sx={{ width: '21.46vw', marginTop: '-0.5vh' }}>
            <Typography sx={{
              mt: '0vh', ml: '.5vw', color: '#31312C',
              font: "Roboto", textAlign: 'left'
            }} fontSize='1.5vw' lineHeight={1.2}>
              Innovate to fit your Community
            </Typography>
          </Box>


        </Box>

      </Box>
    </Box>
  );
}

function InputKey() {

  const name = 'password'
  const type = 'password'
  const [cur, setCur] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);

  function onClickFun(e) {
    const val1 = e.currentTarget.value
    val1 === "N3$tfu3L" ? allowed = 'yes' : allowed = 'no'


  };

  return (
    <div position='fixed' align='center'>
      <Typography sx={{ fontSize: '1vw', width: '12vw' }}>Please answer the following to activae DISCOVER NESTFUEL (Hint:Army man of the Raj earns working days by a week 7-6-3)</Typography>
      <Typography sx={{ mt: 2, fontSize: '1vw', width: '12vw' }}></Typography>
      <TextField sx={{ fontsize: '0.5vw', mt: 1, width: '12vw', border: 1, borderColor: 'black', borderRadius: 2 }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label
        autoFocus={true}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </div>
  )
}

export default AboutUs;