import React from 'react'
import {  swapRates, 
          percentClosingcost,
          mortgageRatesMasterQMPrimary,
          mortgageRatesMasterQMSecondary,
          mortgageRatesMasterQMInvestment,
          mortgageRatesMasterNonQMPrimary,
          mortgageRatesMasterNonQMSecondary,
          mortgageRatesMasterNonQMInvestment,
          mortgageRatesMasterNonQMHighPrimary,
          mortgageRatesMasterNonQMHighSecondary,
          mortgageRatesMasterNonQMHighInvestment,
          intR,
          terM
        } from '../constants.js';


export function loanToEmiDefault(principal){
  const tt_1 = Math.pow((1+intR/1200),terM*12.0) 
  const emi = Math.round(principal*intR*tt_1/(1200*(tt_1-1)))
  return (emi);
}

export function emiToLoanDefault(emi){
  const tt_1 = Math.pow((1+intR/1200),terM*12.0) 
  const prince = Math.round(emi*1200*(tt_1 -1)/(intR*tt_1))
  return (prince);
}

export function loanToEmi(principal,rate,termY){
  const tt_1 = Math.pow((1+rate/1200),termY*12.0) 
  const emi = Math.ceil(principal*rate*tt_1/(1200*(tt_1-1)))
  return (emi);
}

export function emiToLoan(emi,rate,termY){
  const tt_1 = Math.pow((1+rate/1200),termY*12.0) 
  const prince = Math.floor(emi*1200*(tt_1 -1)/(rate*tt_1))
  return (prince);
}
export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }

  React.useEffect(() => {
    setTimeout(window.addEventListener('resize', handleResize),3000)
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function loanParams(propPrice,upfront,monthly) {
  
  if (propPrice <=0 || upfront <=0 || monthly <=0 )
    return({message:"Error: Wrong Input Parameters,All input values should be positive numbers",
    closingCost:0,downPayment:0,minLTV:0,upfront:0,monthly:0})
  
  const closingCost = Math.ceil(0.01*percentClosingcost*propPrice);
  const downPayment = upfront >= closingCost ? upfront - closingCost : 0.0
  const minLTV = Math.floor(100 - 100*downPayment/propPrice);
  const minEmiR = 100*monthly/loanToEmiDefault(propPrice - downPayment).toFixed(2)
  
  var upfrontS = '', monthlyS = '';
  if(minEmiR <=70)        monthlyS= 'Very Low'
  else if(minEmiR <=85)   monthlyS = 'Low'
  else if(minEmiR <=100)  monthlyS = 'Adequate'
  else if(minEmiR <=120)  monthlyS = 'Sufficient';
  else                    monthlyS = 'High'
  if(minLTV >=95)         upfrontS = 'Very Low'
  else if(minLTV >=90)    upfrontS = 'Low'
  else if(minLTV >=85)    upfrontS = 'Adequate'
  else if(minLTV >=80)    upfrontS = 'Sufficient'
  else                    upfrontS = 'High'
  const message = "Success: Values are calculated at" + percentClosingcost + "% closing cost"
  return({message:message,statusCode:200,closingCost:closingCost,downPayment:downPayment,minLTV:minLTV>=50?minLTV:50,upfront:upfrontS,monthly:monthlyS})
}

export function interestRate(purchaseType,loanTerm,loanType,ficoScore,lTV) {
  var swapR =0,spread =0;
  swapR = swapRates.filter((item)=>item.term <= loanTerm)[0].swapRate
  //console.log("EEEEEE",swapR)
  if(swapR === -1) return({message:"no Data for the given loan term" , statusCode:404});
  switch (loanType){
    case 'QM':
      if(purchaseType === 'primary')     spread = mortgageRatesMasterQMPrimary.filter(  (item)  =>(item.ltv >= lTV && item.min >= ficoScore))[0].spread
      if(purchaseType === 'secondary')   spread = mortgageRatesMasterQMSecondary.filter((item)  =>(item.ltv >= lTV && item.min >= ficoScore))[0].spread
      if(purchaseType === 'investment')  spread = mortgageRatesMasterQMInvestment.filter((item) =>(item.ltv >= lTV && item.min >= ficoScore))[0].spread
      break;
    case 'NQM':
      if(purchaseType === 'primary')     spread = mortgageRatesMasterNonQMPrimary.filter(  (item)  =>(item.ltv >= lTV && item.min >= ficoScore))[0]?.spread
      if(purchaseType === 'secondary')   spread = mortgageRatesMasterNonQMSecondary.filter((item)  =>(item.ltv >= lTV && item.min >= ficoScore))[0]?.spread
      if(purchaseType === 'investment')  spread = mortgageRatesMasterNonQMInvestment.filter((item) =>(item.ltv >= lTV && item.min >= ficoScore))[0]?.spread
      break;
    case 'NQMH':
      if(purchaseType === 'primary')     spread = mortgageRatesMasterNonQMHighPrimary.filter(  (item)  =>(item.ltv >= lTV && item.min >= ficoScore))[0].spread
      if(purchaseType === 'secondary')   spread = mortgageRatesMasterNonQMHighSecondary.filter((item)  =>(item.ltv >= lTV && item.min >= ficoScore))[0].spread
      if(purchaseType === 'investment')  spread = mortgageRatesMasterNonQMHighInvestment.filter((item) =>(item.ltv >= lTV && item.min >= ficoScore))[0].spread
      break;
  }
  //console.log("interest rate EEEEEE",swapR,spread)
  if(spread ===-1) return(-1);
  return(swapR + spread);
}
