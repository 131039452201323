import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import {Menu, MenuItem ,IconButton, Box, Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// third-party
import NumberFormat from 'react-number-format';
import avatar_boy from '../../assets/svg/avatar-boy.png'
import { rspvw } from './styles';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import { visuallyHidden } from '@mui/utils';
import MoreVert from '@mui/icons-material/MoreVert'

// project import
//import Dot from 'components/@extended/Dot';

function createData(one, two, three, four, five,six,seven) {
    return { one, two, three, four, five, six, seven };
}

const rows = [
    createData(84564564, 'Andrew Johnston', 'Executive','1-Jan-2023', 'no', 11, '8:06 am | 20 jan 23 '),
    createData(98764564, 'Darrell Steward', 'Associate','23-Feb-2023', 'yes', 12 , '6:06 am | 21 jan 23 '),
    createData(98756325, 'Jennifier aniston', 'Executive','12-Jan-2023','no', 19 , '8:06 am | 22 jan 23 '),
    createData(98652366, 'Darrell Steward', 'Salesperson','17-Jan-2023','yes', 14 , '9:06 am | 24 jan 23 '),
    createData(13286564, 'Brain Lara', 'Executive','28-Jan-2023', 'no', 18 , '2:06 am | 27 jan 23 '),
    createData(86739658, 'Andrew Hall', 'Associate','15-Feb-2023', 'no', 20 , '3:06 pm | 20 jan 23 '),
    createData(13256498, 'Darrell Steward', 'Excutive','1-March-2023', 'yes', 31 , '11:06 am | 24 jan 23 '),
    createData(98753263, 'Darrell Steward', 'Analyst','8-Jan-2023', 'no', 32 , '7:06 am | 17 jan 23 '),
    createData(98753275, 'Andrew Johnston', 'Salesperson','6-Jeb-2023', 'yes', 10 , '6:06 am | 10 jan 23 '),
    createData(98753291, 'Andrew Johnston', 'Associate','18-Feb-2023', 'no', 14 , '8:06 am | 15 jan 23 ')
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
    {
        id: 'one',
        align: 'left',
        disablePadding: false,
        label: 'Employee ID.'
    },
    {
        id: 'two',
        align: 'left',
        disablePadding: true,
        label: 'Name'
    },
    {
        id: 'three',
        align: 'right',
        disablePadding: false,
        label: 'Position'
    },
    {
        id: 'four',
        align: 'left',
        disablePadding: false,
        label: 'Start Date'
    },
    {
        id: 'five',
        align: 'right',
        disablePadding: false,
        label: 'Nestfuel Mortgage'
    }
,
    {
        id: 'six',
        align: 'right',
        disablePadding: false,
        label: 'Learning Points in Month'
    }
    ,
    {
        id: 'seven',
        align: 'right',
        disablePadding: false,
        label: 'Last Login'
    }
];

// ==============================|| ORDER TABLE - HEADER ||============================== //



function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
             // align={headCell.numeric ? 'left' : 'left'}
             align={headCell.align}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
               // sx={{ml:`${rspvw(57)}`,  mt: `${rspvw(65)}`}}
              >
               
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
  
  
                 

{/* {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ml:`${rspvw(37)}`}}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))} */}
           
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };



function OrderTableHead({ order, orderBy }) {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

OrderTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string
};

// ==============================|| ORDER TABLE - STATUS ||============================== //

const OrderStatus = ({ status }) => {
    let color;
    let title;

    switch (status) {
        case 0:
            color = 'warning';
            title = 'Pending';
            break;
        case 1:
            color = 'success';
            title = 'Approved';
            break;
        case 2:
            color = 'error';
            title = 'Rejected';
            break;
        default:
            color = 'primary';
            title = 'None';
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {/* <Dot color={color} /> */}
            <Typography>{title}</Typography>
        </Stack>
    );
};

OrderStatus.propTypes = {
    status: PropTypes.number
};

// ==============================|| ORDER TABLE ||============================== //



  
export default function OrderTable() {
    const [order, setOrder] = useState('asc');
  
    const [orderBy, setOrderBy] = useState('calories');
   // const [selected] = useState([]);
    const [selected, setSelected] = useState([]);


    // const [order, setOrder] = React.useState('asc');
    
    // const [selected, setSelected] = React.useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };


      const [anchorEl, setAnchorEl] = useState(null);

      const MyOptions = [
          "View",
          "Edit",
          "Delete",
      ];
  
      const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
      };
  
      const open = Boolean(anchorEl);
  
      const handleClose = () => {
          setAnchorEl(null);
      };

    const isSelected = (trackingNo) => selected.indexOf(trackingNo) !== -1;

    return (
        <Box>
            <TableContainer
                sx={{
                   
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-child': {
                            pl: 2
                        },
                        '& .MuiTableCell-root:last-child': {
                            pr: 3
                        }
                    }}
                >
                  

                    <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
                    <TableBody>
                        {/* {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                            const isItemSelected = isSelected(row.trackingNo);
                            const labelId = `enhanced-table-checkbox-${index}`; */}



                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                  const isItemSelected = isSelected(row.two);
                                  const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.one}
                                    selected={isItemSelected}
                                >
                                    <TableCell component="th" id={labelId} scope="row" align="left">
                                       
                                            {row.one}
                                        
                                    </TableCell>
                                    <TableCell align="left">
                                    <Box sx={{ mr:'5.00vw', ml:'-1.50vw', mt:'-0.2vh', mb:'-1.5vh', width: '1.10vw', height: '0.80vw'}}>
                        <img src={avatar_boy} alt = "Loading..." width="100%" heigh="100%" />
                        </Box> 
                                        
                                        {row.two}</TableCell>
                                    <TableCell align="right">{row.three}</TableCell>
                                    <TableCell align="left">
                                    {row.four}
                                    </TableCell>
                                    <TableCell align="center">
                                    {row.five}
                                    </TableCell>
                                    <TableCell align="center">
                                    {row.six}
                                    </TableCell>
                                    <TableCell align="right">
                                    {row.seven}    <IconButton
                                            aria-label="more"
                                            onClick={handleClick}
                                            aria-haspopup="true"
                                            aria-controls="long-menu"
                                        >

                                            <MoreVert />
                                        </IconButton>

                                        <Menu
                                            anchorEl={anchorEl}
                                            keepMounted onClose={handleClose}
                                            open={open}>
                                            {MyOptions.map((option) => (
                                                <MenuItem
                                                    key={option}
                                                    onClick={handleClose}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </TableCell>
                                    
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>


            <Box>
        <TablePagination
   sx={{
      backgroundColor: '', // gets overridden if not important
      height: '65px',
      ml:'30vw',
     
      '.MuiInputBase-root': {
        backgroundColor: '',
        mb:'0.9vw'
      },
      '.MuiSvgIcon-root': {
        backgroundColor: '',
        mb:'0.9vw'
      },
      '.MuiTablePagination-toolbar': {
        backgroundColor: '',
        width: '54.70vw',
        color: 'rgb(41, 39, 39)',
        height: '35px',
      },
      '.MuiBox-root': {
        backgroundColor: 'yellow',
        color: 'black',

        '& .MuiSvgIcon-root': {
          backgroundColor: 'purple',
          color: 'black',
        },
      },
    }}
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Box>
        </Box>

        
    );
}
