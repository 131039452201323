import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Box, Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// third-party
import NumberFormat from 'react-number-format';
import viewfilled from '../../assets/svg/view-filled.svg'
import bxslike from '../../assets/svg/bxs-like.svg'
// project import
//import Dot from 'components/@extended/Dot';

function createData(trackingNo, name, fat, carbs, protein) {
    return { trackingNo, name, fat, carbs, protein };
}

const rows = [
    createData(84564564, '1 Jan 2023', '1460', 2, 40570),
    createData(98764564, '1 Jan 2023', '1450', 0, 180139),
    createData(98756325, '1 Jan 2023', '1450', 1, 90989),
    createData(98652366, '1 Jan 2023', '1450', 1, 10239),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

// ==============================|| ORDER TABLE - HEADER CELL ||============================== //

const headCells = [
    {
        id: 'empid',
        align: 'left',
        disablePadding: false,
        label: 'Presentations'
    },
    {
        id: 'name',
        align: 'left',
        disablePadding: true,
        label: 'Date Completed'
    },
    {
        id: 'fat',
        align: 'right',
        disablePadding: false,
        label: 'Views'
    },
    {
        id: 'carbs',
        align: 'left',
        disablePadding: false,

        label: 'Likes'
    }
];

// ==============================|| ORDER TABLE - HEADER ||============================== //

function OrderTableHead({ order, orderBy }) {
    return (
        <TableHead >
            <TableRow sx={{mr:'100vh'}}>
                {headCells.map((headCell) => (
                    <TableCell
                        
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

OrderTableHead.propTypes = {
    order: PropTypes.string,
    orderBy: PropTypes.string
};

// ==============================|| ORDER TABLE - STATUS ||============================== //

const OrderStatus = ({ status }) => {
    let color;
    let title;

    switch (status) {
        case 0:
            color = 'warning';
            title = 'Pending';
            break;
        case 1:
            color = 'success';
            title = 'Approved';
            break;
        case 2:
            color = 'error';
            title = 'Rejected';
            break;
        default:
            color = 'primary';
            title = 'None';
    }

    return (
        <Stack direction="row" sx={{ml:'17vw'}} spacing={1} alignItems="center">
            {/* <Dot color={color} /> */}
            
        </Stack>
    );
};

OrderStatus.propTypes = {
    status: PropTypes.number
};

// ==============================|| ORDER TABLE ||============================== //

export default function ContractTable() {
    const [order] = useState('asc');
    const [orderBy] = useState('trackingNo');
    const [selected] = useState([]);

    const isSelected = (trackingNo) => selected.indexOf(trackingNo) !== -1;

    return (
        <Box>
            <TableContainer
                sx={{
                   
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-child': {
                            pl: 2
                        },
                        '& .MuiTableCell-root:last-child': {
                            pr: 3
                        }
                    }}
                >
                    <OrderTableHead order={order} orderBy={orderBy} />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                            const isItemSelected = isSelected(row.trackingNo);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.trackingNo}
                                    selected={isItemSelected}
                                >
                                    <TableCell  style={{font: 'Roboto', fontWeight: 'Regular', color: '#585858', opacity: '1', fontSize: '2vh',borderBottom:"none", marginLeft:'9.48vw',marginTop:'100'}} component="th" id={labelId} scope="row" align="left">
                                       Letter of Intent
                                    </TableCell>
                                   
                                   
                                    {/* <TableCell  style={{font: 'Roboto', fontWeight: 'Regular', color: '#585858', opacity: '1', fontSize: '2vh',borderBottom:"none", marginLeft:'9.48vw',marginTop:'4.54vh'}} component="th" id={labelId} scope="row" align="left">
                                        <Link color="secondary" component={RouterLink} to="">
                                            {row.trackingNo}
                                        </Link>
                                    </TableCell> */}
                                    <TableCell  style={{font: 'Roboto', fontWeight: 'Regular', color: '#585858', opacity: '1', fontSize: '2vh',borderBottom:"none", marginLeft:'9.48vw',marginTop:'4.54vh'}} component="th" id={labelId} scope="row" align="left">
                                        {row.name}
                                        </TableCell>
                                        <TableCell  style={{font: 'Roboto', fontWeight: 'Regular', color: '#585858', opacity: '1', fontSize: '2vh',borderBottom:"none", marginLeft:'9.48vw',marginTop:'4.54vh'}} component="th" id={labelId} scope="row" align="left">
                        <Box sx={{ mr:'5.00vw', ml:'-1.50vw', mt:'-0.10vh', mb:'-1vh', width: '0.76vw', height: '0.72vw'}}>
                        <img src={viewfilled} alt = "Loading..." width="100%" heigh="100%" />
                        </Box> 
                                    view
                                    </TableCell> 
                                    
                       
                                    <TableCell  style={{font: 'Roboto', fontWeight: 'Regular', color: '#585858', opacity: '1', fontSize: '2vh',borderBottom:"none", marginLeft:'9.48vw',marginTop:'4.54vh'}} component="th" id={labelId} scope="row" align="left">
                        <Box sx={{ mr:'5.00vw', ml:'-1.50vw', mt:'-0.10vh', mb:'-1vh', width: '0.76vw', height: '0.72vw'}}>
                        <img src={bxslike} alt = "Loading..." width="100%" heigh="100%" />
                        </Box> 
                                    download
                                    </TableCell> 
                       
                                   
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
