import React from "react";
import { Box, CssBaseline, CircularProgress, Typography, Stack, Button} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import paymentImageSvg from '../../assets/svg/assets svg/contract-loan-svgrepo-com.svg'
import { useDispatch, useSelector } from 'react-redux';
import {borrowerfinancialProfile, borrowerProfileStatus, calcPropfinOptionsA, calcPropfinOptionsEmpA} from "../../actions/borrower";
import RightBlockPropConf, { Centerbottom, LeftbottomA, RightBlockSPBP } from './RightBlock'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import leftbottom from '../../assets/images/bg-10@1x.png'
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { IS_PROFILE_INFO_CHANGED } from "../../constants";
import { rspvw } from "./styles";
function LoanDocs() {
  let dTax,dBank12,dBank24,dPStubs,noDocs;
  const { bfinancialProfile,
          isLoading,
          bProfileStatus,
          bChoiceD,bChoiceB,
          bChoiceE
        } = (useSelector((state)=>state.borrower));
  const dispatch = useDispatch();
  var [docStatus,SetDocstatus] = React.useState({
      B2: bfinancialProfile.financeDocs.dBank24,
      B1: bfinancialProfile.financeDocs.dBank12,
      TX: bfinancialProfile.financeDocs.dTax,
      PS: bfinancialProfile.financeDocs.dPStubs,
      NO: bfinancialProfile.financeDocs.noDocs,
    });
  
  dTax    = bfinancialProfile.financeDocs.dTax;
  dBank12 = bfinancialProfile.financeDocs.dBank12;
  dBank24 = bfinancialProfile.financeDocs.dBank24;
  dPStubs = bfinancialProfile.financeDocs.dPStubs;
  noDocs  = bfinancialProfile.financeDocs.noDocs;

  const location = useLocation();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 


  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    //console.log(buttonId);

    switch(buttonId) {
      case "Confirm": 
      const docsAll = !(dTax || (dBank12||dBank24) ||  dPStubs|| noDocs) ||(dTax && (dBank12||dBank24) &&  dPStubs)
        const financeDocs = {...bfinancialProfile.financeDocs, dTax:dTax, dBank12:dBank12, dBank24:dBank24, dPStubs:dPStubs, noDocs:noDocs, docsAll:docsAll };
        dispatch(borrowerfinancialProfile({...bfinancialProfile, financeDocs:financeDocs }));
        // dispatch(calcPropfinOptionsEmpA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
        dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});


        navigate(-1);
        break;
      case "Bank24":
        if(dBank24){
          dBank24 = false;
          SetDocstatus({...docStatus,B2:dBank24});
          dispatch(borrowerProfileStatus({...bProfileStatus,bankdocProvided:0}));
          financeDocs = {...bfinancialProfile.financeDocs, dBank24:dBank24 };
          dispatch(borrowerfinancialProfile({...bfinancialProfile, financeDocs:financeDocs }));
          dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
          dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true})

        }else{
          dBank24 = true;
          dBank12 = false;
          noDocs = false;
          SetDocstatus({...docStatus,B1:dBank12,B2:dBank24,NO:noDocs});
          dispatch(borrowerProfileStatus({...bProfileStatus,bankdocProvided:1}));
          const financeDocs = {...bfinancialProfile.financeDocs, dBank12:dBank12, dBank24:dBank24, noDocs:noDocs };
          dispatch(borrowerfinancialProfile({...bfinancialProfile, financeDocs:financeDocs }));
          dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
          dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});

        }
        break;
      case "Bank12":
        if(dBank12){
          dBank12 = false;
          SetDocstatus({...docStatus, B1:dBank12});
          const financeDocs3 = {...bfinancialProfile.financeDocs, dBank12:dBank12 };
          dispatch(borrowerfinancialProfile({...bfinancialProfile, financeDocs:financeDocs3 }));
          dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
          dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});

        }else{
          dBank24 = false;
          dBank12 = true;
          noDocs = false;
          SetDocstatus({...docStatus,B1:dBank12,B2:dBank24,NO:noDocs});
          dispatch(borrowerProfileStatus({...bProfileStatus,bankdocProvided:1}));
          const financeDocs= {...bfinancialProfile.financeDocs, dBank12:dBank12, dBank24:dBank24, noDocs:noDocs };
          dispatch(borrowerfinancialProfile({...bfinancialProfile, financeDocs:financeDocs }));
          dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
          dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});

        }        
        break;
      case "PayStub":
        if(dPStubs){
          dPStubs = false;
          SetDocstatus({...docStatus,PS:dPStubs});
          dispatch(borrowerProfileStatus({...bProfileStatus,payStubProvided:0}));
          const financeDocs = {...bfinancialProfile.financeDocs, dPStubs:dPStubs };
          dispatch(borrowerfinancialProfile({...bfinancialProfile, financeDocs:financeDocs }));
          dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
          dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});

        }else{
          dPStubs = true;
          noDocs = false;
          SetDocstatus({...docStatus,PS:dPStubs,NO:noDocs});
          dispatch(borrowerProfileStatus({...bProfileStatus,payStubProvided:1}));
          const financeDocs = {...bfinancialProfile.financeDocs, dPStubs:dPStubs, noDocs:noDocs };
          dispatch(borrowerfinancialProfile({...bfinancialProfile, financeDocs:financeDocs }));
          dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
          dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});


        }        
        break;
      case "Tax":
        if(dTax){
          dTax = false;
          SetDocstatus({...docStatus, TX:dTax});
          const financeDocs = {...bfinancialProfile.financeDocs, dTax:dTax };
          dispatch(borrowerfinancialProfile({...bfinancialProfile, financeDocs:financeDocs }));
          dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
          dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});

        }else{
          dTax = true;
          noDocs = false;
          SetDocstatus({...docStatus,TX:dTax,NO:noDocs});
          const financeDocs = {...bfinancialProfile.financeDocs, dTax:dTax, noDocs:noDocs };
          dispatch(borrowerfinancialProfile({...bfinancialProfile, financeDocs:financeDocs}));
          dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
          dispatch({type: IS_PROFILE_INFO_CHANGED, payload:true})

        }        
        break;
      case "NoDocs":
        if(noDocs){
          noDocs = false;
          SetDocstatus({NO:noDocs});
          const financeDocs9 = {...bfinancialProfile.financeDocs, noDocs:noDocs };
          dispatch(borrowerfinancialProfile({...bfinancialProfile, financeDocs:financeDocs9 }));
          dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
          dispatch({type: IS_PROFILE_INFO_CHANGED, payload:true})

        }else{
          noDocs = true;
          dPStubs = false;
          dBank12 = false;
          dBank24 = false;
          dTax = false;
          SetDocstatus({B1:dBank12, B2:dBank24, TX:dTax, PS:dPStubs, NO:noDocs});
          const docsAll = ( dTax && (dBank12||dBank24) &&  dPStubs) ;
          const financeDocs = {...bfinancialProfile.financeDocs, dTax:dTax, dBank12:dBank12, dBank24:dBank24, dPStubs:dPStubs, noDocs:noDocs, docsAll:docsAll };
          dispatch(borrowerfinancialProfile({...bfinancialProfile, financeDocs:financeDocs }));
          dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
          dispatch({type: IS_PROFILE_INFO_CHANGED, payload:true})


          
        }
        break;
      case "Skip":
        const _docsAll =( dTax && (dBank12||dBank24) &&  dPStubs)
        const _financeDocs = {...bfinancialProfile.financeDocs, docsAll:_docsAll };
        dispatch(borrowerfinancialProfile({...bfinancialProfile, financeDocs:_financeDocs }));
        dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))

        navigate(-1);
        break;
      default:
        break;
    }
    dispatch(borrowerProfileStatus({...bProfileStatus,taxdocProvided: (dTax ? 1 : 0), bankdocProvided: ((dBank12||dBank24) ? 1 : 0), payStubProvided: (dPStubs ? 1 : 0) }))
  }
  console.log("Doc status", dTax,dBank12,dBank24,dPStubs,noDocs,bfinancialProfile.financeDocs);

  return(
    isLoading ? <CircularProgress /> : (
      <React.Fragment>
        <CssBaseline/>
        <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
          <Navbar pathname={location.pathname}/>
          <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
            { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
            <Box sx={{ width: '14vw',height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <Typography sx={{color:'#000000',mt:'16.52vh',fontSize:'1.4vw', textAlign:'center'}}> Update Settings </Typography>
              <div align='center'>
                <Stack sx={{mt:'2vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}}  id="PayStub"  className='hexBtnCenter'
                    onClick={onClickFun}> 
                    <HexButton titleText="Pay Stubs" type= {docStatus.PS? 'visited':'base'} fontSize='24'/>
                  </Button>
                </Stack>
                <Stack sx={{mt:'2.25vw',height:'3.5vw',width:'12vw'}} direction="row" justifyContent="space-around">
                <Button sx={{ overflow: 'hidden',textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id="Bank12"   className='hexBtnCenter'
                    onClick={onClickFun}> 
                    <HexButton titleText="12Months Bank Statements" type= {docStatus.B1? 'visited':'base'} fontSize='24'/>
                  </Button>
                  <Button sx={{ overflow: 'hidden',textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id='Bank24'  className='hexBtnCenter' 
                    onClick={onClickFun}> 
                    <HexButton titleText="24Months Bank Statements" type= {docStatus.B2? 'visited':'base'} fontSize='24'/>
                  </Button>
                </Stack>
                <Stack sx={{mt:'2.25vw',height:'3.5vw', width:'6.5vw'}}  direction="row" justifyContent="space-around">
                <Button sx={{ overflow: 'hidden',textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id="Tax" className='hexBtnCenter'     
                    onClick={onClickFun}> 
                    <HexButton titleText="Tax Returns"   type={docStatus.TX? 'visited':'base'} fontSize='24'/>
                  </Button>
                  
                </Stack>
                <Stack sx={{mt:'2.25vw',height:'3.5vw',width:'12vw'}}  direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id="NoDocs"  className='hexBtnCenter' 
                    onClick={onClickFun}> 
                    <HexButton titleText="No Documents"  type={docStatus.NO?  'visited':'base'} fontSize='24'/>
                  </Button> 
                  <Button sx={{ overflow: 'hidden',textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id="Confirm"  className='hexBtnCenter'
                    onClick={onClickFun}> 
                    <HexButton titleText="&nbsp; Confirm &nbsp;" type="confirm"  fontSize='24'/>
                  </Button>
                </Stack>
                <Stack sx={{mt:'2.25vw',height:'3.5vw',width:'6.5vw'}}  direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id="Skip"    className='hexBtnCenter' 
                    onClick={onClickFun}> 
                    <HexButton titleText="Skip" type='base' fontSize='24'/>
                  </Button>
                </Stack>
              </div>
              <Centerbottom navigateForward='-1'/>
            </Box>
            <RightBlockSPBP/>  
          </Box>
        </Box>
      </React.Fragment>
    ));
}

function LeftBlock() {
      return (
        <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',overflow:'hidden',position:'relative',
        boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
            <Box sx={{ ml:'7vw',mt:'15vh' , width:'21vw',background:'inherit', height: '25vh' }}>
              <div align='center'>
                    <img src={paymentImageSvg} alt = "Loading..." heigh="100px" width='100px'/>
                    <Typography mt={5} fontSize='1.6vw' fontStyle="regular" component='div'color='#000000'> 
                      Income Verification
                    </Typography>
                    <Typography sx={{ mt:2}} fontSize='1.2vw' component="div" color='#000000' align='center'>
                    Please select the documents that you will use to prove your source of income and available funds
                    </Typography>
              </div>
              </Box>
              <LeftbottomA leftbottom={leftbottom}/>
        </Box>
      );
}



export default LoanDocs;