import React from "react";
import { Box, CssBaseline, Input, CircularProgress,  Typography, Button, Stack} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import leftbottom from '../../assets/images/bg-10@1x.png'
import {  borrowerChoiceE, borrowerfinancialProfile,
          borrowerPostPropertyData,
          calcPropfinOptions, 
          } from "../../actions/borrower";
import { RightBlockCompareSmartChoiceEmb} from "./RightBlockCompare";
import { Centerbottom, LeftbottomA } from "./RightBlock";
import { UPDATE_PROFILEJOURNEY, withCommas } from "../../constants";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { rspvw } from "./styles";
function SmartChoiceEmb() {
  const { selectedProp,
          proposedProp,
          bChoiceB,
          bChoiceD,
          isLoading,
          bChoiceDSF,
          bChoiceDSF_EMB,
          bChoiceDSF1B,
          bProfileJourney,
          bfinancialProfile,
          bPropertyDetail
        } = useSelector((state)=>state.borrower);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [tTextS,setTTextS]  = React.useState('Smart Traditional')
  const [tTextP,setTTextP]  = React.useState('Smart Traditional')
  const [typeS,setTypeS]    = React.useState("TradP")
  const [typeP,setTypeP]    = React.useState("TradS")
  const [colBS,setColBS]    = React.useState('#00657C')
  const [colTS,setColTS]    = React.useState('#FEFEFE')
  const [colBP,setColBP]    = React.useState('#00657C')
  const [colTP,setColTP]    = React.useState('#FEFEFE')
  const [sbenOn,setSbenOn]  = React.useState(false)
  const [pbenOn,setPbenOn]  = React.useState(false)
  const [bChoiceDSF1, setBChoiceDSF1] = React.useState( location?.state?.info? location?.state?.info:bChoiceDSF_EMB)

  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 

  React.useEffect(()=>{
    switch( bChoiceDSF_EMB[proposedProp?.propId][proposedProp?.finOpt]?.label)
    {
      case 'Smart Traditional':
        setColBP('#66A3B0')
        setColTP('#00657C')
        setTTextP('Smart Traditional')
        setTypeP('TradP')
        break
      case "Employer Benefit":
        setColBP('#82C494')
        setColTP('#43634B')
        setTTextP('Employer Benefit'||"Employer Support")
        setTypeP('ESP')
      case "Employer Support":
          setColBP('#82C494')
          setColTP('#43634B')
          setTTextP('Employer Benefit')
          setTypeP('ESP')
          break
      default:
        break;
    }
  },[proposedProp?.propId,proposedProp?.finOpt])
  
  React.useEffect(()=>{
    switch( bChoiceDSF_EMB[selectedProp?.propId][selectedProp?.finOpt]?.label)
    {
      case 'Smart Traditional':
        setColBS('#66A3B0')
        setColTS('#C9EAE6')
        setTTextS('Smart Traditional')
       setTypeS('TradS')
        break
      case "Employer Benefit" ||"Employer Support":
          setColBP('#82C494')
          setColTP('#43634B')
          setTTextP('Employer Benefit')
          setTypeP('ESP')
       case "Employer Support":
        setColBS('#82C494')
        setColTS('#BFDEC7')
        setTTextS('Employer Benefit')
       setTypeS('ESS')
        break
      default:
        break;
    }
  },[selectedProp?.propId,selectedProp?.finOpt])

  React.useEffect(()=>{
    const affordable = bChoiceDSF1[proposedProp.propId][proposedProp.finOpt]?.propPrice <= bChoiceDSF1B?.tradAfford.propPrice ? false:true
    setPbenOn(affordable)
  },[proposedProp.finOpt])
  React.useEffect(()=>{
    const affordable = bChoiceDSF1[selectedProp.propId][selectedProp.finOpt]?.propPrice <=  bChoiceDSF1B?.tradAfford.propPrice ? false:true
    setSbenOn(affordable)
  },[selectedProp.finOpt])
  var newArr1=[]
  React.useEffect(()=>{
console.log("DSF Changed")
var newArr1=[]
for (let i = 0; i < bChoiceDSF.length; i++) {
  var newArr = bChoiceDSF[i].filter((val) => {
    return val.label == "Smart Traditional" || val.label == "Employer Support"
  })
  newArr = newArr.map((item, index) => {
    if (item.label == "Employer Support") {
      item.benefits = Math.ceil(((newArr[0].emi * newArr[0].loanTerm * 12) - newArr[0].loanAmount) - ((item.initialMonthlyEMI * item.loanTerm * 12) - item.loanAmount))
      return item;
    }
    return item;
})
// console.log("BENEfits check",JSON.stringify(newArr))
console.log("Here is array")
newArr1[i]=newArr
}
setBChoiceDSF1(newArr1)
  },[bChoiceDSF])
console.log(newArr1)
console.log("location",location)
  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "selected":
         const address = {
          city    : selectedProp.prop.address,
          state   : selectedProp.prop.city,
          street  : selectedProp.prop.state, 
          zipCode : selectedProp.prop.zipcode
         }
         const thumbnail = selectedProp.prop.thumbnail
         const mlsId = selectedProp.prop.mlsId
         const selectedProperty = {...bPropertyDetail.selectedProperty,address:address,thumbnail:thumbnail,mlsId:mlsId}

         const mortgageDetails = {
          apr           : selectedProp.prop.mortgageDetails?.apr || selectedProp.prop.mortgageDetails.interestRate +0.25,
          closingCost   : selectedProp.prop.mortgageDetails.closingCost,
          financeType   : selectedProp.prop.mortgageDetails.financeType,
          insurance     : selectedProp.prop.mortgageDetails.insurance,
          interestRate  : selectedProp.prop.mortgageDetails.interestRate,
          loanAmount    : selectedProp.prop.mortgageDetails.loanAmount,
          loanTerm      : selectedProp.prop.mortgageDetails.loanTerm,
          monthlyEMI    : selectedProp.prop.mortgageDetails.monthlyEMI,
          mortgageType  : selectedProp.prop.mortgageDetails.mortgageType,
          offerPrice    : selectedProp.prop.offerPrice,
          percentLTV    : selectedProp.prop.mortgageDetails.percentLTV,
          pmi           : selectedProp.prop.mortgageDetails.pmi,
          price         : selectedProp.prop.mortgageDetails.propertyPrice,
          propTax       : selectedProp.prop.mortgageDetails.propTax,
          totalCost     : selectedProp.prop.mortgageDetails.totalCost,
          upfront       : selectedProp.prop.mortgageDetails.upfront,
          nestFuelFinanceOption :bChoiceDSF1[selectedProp.propId][selectedProp.finOpt]?.label,
         }
         const propertyViewed = bPropertyDetail.propertyViewed === undefined ? []:bPropertyDetail.propertyViewed
         propertyViewed.push(selectedProperty)
         dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, financeRate:true} });
          dispatch(borrowerPostPropertyData({...bPropertyDetail,selectedProperty:selectedProperty,
                                                mortgageDetails:mortgageDetails,
                                                propertiesViewed:propertyViewed}));
          navigate("/StartApplication",{state:{name:'selected'}}, { replace: false });
          break;
      case "proposed":
        const _address = {
          city    : proposedProp.prop.address,
          state   : proposedProp.prop.city,
          street  : proposedProp.prop.state, 
          zipCode : proposedProp.prop.zipcode
         }
         const _thumbnail = proposedProp.prop.thumbnail
         const _mlsId = proposedProp.prop.mlsId
         const _selectedProperty = {...bPropertyDetail.selectedProperty,address:_address,thumbnail:_thumbnail,mlsId:_mlsId}

         const _mortgageDetails = {
          apr           : proposedProp.prop.mortgageDetails?.apr || proposedProp.prop.mortgageDetails.interestRate +0.25,
          closingCost   : proposedProp.prop.mortgageDetails.closingCost,
          financeType   : proposedProp.prop.mortgageDetails.financeType,
          insurance     : proposedProp.prop.mortgageDetails.insurance,
          interestRate  : proposedProp.prop.mortgageDetails.interestRate,
          loanAmount    : proposedProp.prop.mortgageDetails.loanAmount,
          loanTerm      : proposedProp.prop.mortgageDetails.loanTerm,
          monthlyEMI    : proposedProp.prop.mortgageDetails.monthlyEMI,
          mortgageType  : proposedProp.prop.mortgageDetails.mortgageType,
          offerPrice    : proposedProp.prop.offerPrice,
          percentLTV    : proposedProp.prop.mortgageDetails.percentLTV,
          pmi           : proposedProp.prop.mortgageDetails.pmi,
          price         : proposedProp.prop.mortgageDetails.propertyPrice,
          propTax       :   proposedProp.prop.mortgageDetails.propTax,
          totalCost     : proposedProp.prop.mortgageDetails.totalCost,
          upfront       : proposedProp.prop.mortgageDetails.upfront,
          nestFuelFinanceOption :bChoiceDSF_EMB[proposedProp.propId][proposedProp.finOpt].label,
         }
         const _propertyViewed = bPropertyDetail.propertyViewed === undefined ? []:bPropertyDetail.propertyViewed
         _propertyViewed.push(_selectedProperty)
        dispatch(borrowerPostPropertyData({...bPropertyDetail,selectedProperty:_selectedProperty,
                                                mortgageDetails:_mortgageDetails,
                                                propertiesViewed:_propertyViewed}));
          navigate("/StartApplication",{state:{name:'proposed'}}, { replace: false });
      //  console.log("Clicked")

          break;
      case "adjusP":

        const objNew = [bChoiceD[selectedProp.propId], bChoiceD[proposedProp.propId]]
        dispatch(calcPropfinOptions(objNew,bfinancialProfile,bChoiceB, selectedProp.propId, proposedProp.propId));
        console.log("Clicked")
        //navigate("/BorrowerProfileEMP", { replace: false });
        break;
      default:
        break;
    }
  }

  return(
    isLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
      <React.Fragment> <CssBaseline/>
           <Box mt={marginT} ml={marginL}  sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
            <Navbar pathname={location.pathname}/>
            <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
            { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlockA/>}
            
              <Box sx={{ width: '14vw',height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <Typography sx={{color:'#31312C',mt:'11.3vw',fontSize:'1.4vw', textAlign:'center'}}> 
              Select Financing</Typography>
              <div align='center'>
                <Stack sx={{ mt: '2vw', height: '3.5vw', width: '12vw' }} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="selected" className='hexBtnCenter' disabled={!sbenOn}
                    onClick={onClickFun}>
                    <HexButton titleText={tTextS} type={typeS} fontSize='24' /></Button>
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="proposed" className='hexBtnCenter' disabled={!pbenOn}
                    onClick={onClickFun}>
                    <HexButton titleText={tTextP} type={typeP} fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="adjusP" className='hexBtnCenter'
                  onClick={onClickFun} >
                    <HexButton titleText="Adjust Preferences" type='base' fontSize='24' /></Button>
                </Stack>
                <Centerbottom navigateForward="SmartChoice" />
              </div>
            
          </Box>
          <RightBlockCompareSmartChoiceEmb rbops = 'smartChoiceEmb' info={bChoiceDSF1}/>
        </Box>
        </Box>
        </React.Fragment>
      ));
}


    function LeftBlockA () {

      const dispatch = useDispatch();
      const { bChoiceE,
              bChoiceA,
              bfinancialProfile,
              bChoiceDSF,
              selectedProp
            } = useSelector((state)=>state.borrower);
      const [upfront,setUpfront] = React.useState(bChoiceA === "refinance"? bChoiceE?.cashoutAmount : bfinancialProfile.income.upfront)
      const [monthlyBudget,setMonthlyBudget] = React.useState(bfinancialProfile.income.monthlyBudget)
      const [max,setMax] = React.useState(bfinancialProfile.frontEndDTI);
      const [percentO,setPercentO] = React.useState(bChoiceE.percentOwnership)
      //console.log(bChoiceE)
      function onClickFun(e) {
        const buttonId = e.currentTarget.id;
        const input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
        switch(buttonId) {
          case "monthly":
            setMonthlyBudget(isNaN(input) ? 0 : input<0?0:input)
            const income = {...bfinancialProfile.income,monthlyBudget:input<0?0:input}
            dispatch(borrowerfinancialProfile({...bfinancialProfile,income:income}));
            break;
          case "upfront":
            setUpfront(isNaN(input) ? 0 : input<0?0:input)
            if(bChoiceA === "refinance"){
              const upfront = bChoiceE?.propertyPrice - bChoiceE?.outStandingLoan - bChoiceE?.cashoutAmount
              dispatch(borrowerChoiceE({...bChoiceE,cashoutAmount:input<0?0:input}));
              const income = {...bfinancialProfile.income,upfront:upfront<0?0:upfront}
            dispatch(borrowerfinancialProfile({...bfinancialProfile,income:income}));
            }else{
              const income = {...bfinancialProfile.income,upfront:input}
              dispatch(borrowerfinancialProfile({...bfinancialProfile,income:income}));
            }
           
            break;
          case "ownership":
            setPercentO(isNaN(input) ? 0 : input<0?0:input)
            const financr = {...bfinancialProfile.finance,percentOwnership:input}
            dispatch(borrowerfinancialProfile({...bfinancialProfile,finance:financr}));
            break;
          default:
            break;
          }
      }
      return (
        <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
          boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
            <Box sx={{ mt:'12.7vh' , ml: '7.3vw', width: '26vw',height:'11.25vw' }}> 
            { bChoiceA === "refinance"
              ?<> <Typography sx={{color:'#31312C', width:'26vw',
              fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                Refinancing Tailored for you
            </Typography>
            <Typography sx={{color:'#31312C', width:'26vw',
                  fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                    {selectedProp?.prop?.address}, {selectedProp?.prop?.city} can be Refinanced
                </Typography>

            </>
              : <><Typography sx={{color:'#31312C', width:'26vw',
                  fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                    Financing Tailored for you
                </Typography> 
                 <Typography sx={{color:'#31312C', width:'26vw',
                 fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                   {selectedProp?.prop?.address}, {selectedProp?.prop?.city} is Affordable
               </Typography>
               </>
            }
               
                <Typography sx={{color:'#31312C', width:'24vw',mt:'1.7vw',
                  fontSize:'1.67vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                    Adjust your preferences below
                </Typography>
            </Box>
            <Box sx={{ mt:'4vh' , ml: '7.3vw', width: '27vw',height:'14vw' }}> 
              <Stack sx={{mt:'0.5vw'}}spacing={0} direction="row" justifyContent="space-between"> 
                  <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Monthly Payment :</Typography>  
                  <Input disableUnderline={true} id="monthly" value={withCommas(monthlyBudget)} sx={{backgroundColor:'#FFFFFF',color:"#8F8F8F",fontSize:'1.1vw', height: '2.5vw',width:"13vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{min:0,max:{max},style: { textAlign: 'center' }}}onChange={onClickFun}/>
              </Stack>
              <Stack sx={{mt:'0.5vw'}}spacing={0} direction="row" justifyContent="space-between"> 
              { bChoiceA === "refinance"
                 ? <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'> Cashout Required :</Typography> 
                 : <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Upfront Cash/Asset :</Typography> 
              }
                  <Input disableUnderline={true} id="upfront" value={withCommas(upfront)} sx={{backgroundColor:'#FFFFFF',color:"#8F8F8F",fontSize:'1.1vw', height: '2.5vw',width:"13vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{style: { textAlign: 'center' }}}onChange={onClickFun}/>
              </Stack>
              { bChoiceDSF[selectedProp.propId][selectedProp.finOpt].label === "Part Buy Part Rent" 
              ? <Stack sx={{mt:'0.5vw', position:'relative'}} spacing={0} direction="row" justifyContent="space-between"> 
              <Typography sx={{mt:1,fontSize:'1.35vw', width:'10  vw', justifyContent:'space-between'}} color='#000000'>Ownership :</Typography>  
                  
                  <Input type="number" disableUnderline={true} id="ownership" value={percentO} sx={{backgroundColor:'#FFFFFF',color:"#8F8F8F",fontSize:'1.1vw', height: '2.5vw',width:"13vw",border:1, borderRadius:'0.5vw'}} 
                        inputProps={{min: 0, max: 100 ,style: { textAlign: 'center',width: '70%', margin:'0 auto' }}}onChange={onClickFun} />
                  <span id="percent-sign" style={{color:"#8F8F8F",fontSize:'1.1vw', top: '0.5vw', right: '1vw', position: 'absolute',zIndex: 1}}>%</span>
              </Stack>
              :<></>
              }
            </Box>
            
            <LeftbottomA leftbottom={leftbottom}/>
        </Box>
      );
    }



export default SmartChoiceEmb ;