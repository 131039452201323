export const _debug = process.env.NODE_ENV
export const INIT = 'INIT'
export const REGCOMP = 'REGCOMP'
export const UPDATE_A = "UPDATE_A"
export const UPDATE_B = "UPDATE_B"
export const UPDATE_C = "UPDATE_C"
export const UPDATE_D = "UPDATE_D"
export const UPDATE_DEMB ="UPDATE_DEMB"
export const ADDETO_D = "ADDETO_D"
export const UPDATE_E = "UPDATE_E"
export const UPDATE_F = "UPDATE_F"
export const UPDATE_G = "UPDATE_G"
export const UPDATE_H = "UPDATE_H"
export const UPDATE_I1 = "UPDATE_I1"
export const UPDATE_I2 = "UPDATE_I2"
export const UPDATE_J = "UPDATE_J"
export const MODIFY_D = "MODIFY_D"
export const UPDATE_DSF = "UPDATE_DSF"
export const UPDATE_PREPOPULATEDDSF = "UPDATE_PREPOPULATEDDSF"
export const UPDATE_DSFUPDATE = "UPDATE_DSFUPDATE"
export const UPDATE_DSF_EMB = "UPDATE_DSF_EMB"
export const UPDATE_DSFEMB = "UPDATE_DSFEMB"
export const UPDATE_DSF1 = "UPDATE_DSF1"
export const UPDATE_DSFB = "UPDATE_DSFB"
export const UPDATE_DSFE = "UPDATE_DSFE"
export const UPDATE_DSFEM = "UPDATE_DSFEM"
export const UPDATE_MTG = 'UPDATE_MTG'
export const SELPROP = "SELPROP"
export const PROPROP = "PROPROP"
export const PROPCHANGE = "PROPCHANGE"
export const UPDATE_SWAPRATE = "UPDATE_SWAPRATE"
export const UPDATE_SWAPRATEONE = "UPDATE_SWAPRATEONE"
export const UPDATE_SPREAD = "UPDATE_SPREAD"
export const UPDATE_SPREADONE = "UPDATE_SPREADONE"
export const UPDATE_Profile = "UPDATE_PROFILE"
export const UPDATE_ProfileNew = "UPDATE_PROFILE_NEW"
export const UPDATE_fProfile = "UPDATE_FPROFILE"
export const UPDATE_fProfileNew = "UPDATE_FPROFILE_NEW"
export const UPDATE_EMP_MORTGAGE_PROFILE ="UPDATE_EMP_MORTGAGE_PROFILE"
export const UPDATE_SAVING_EMB ="UPDATE_SAVING_EMB"
export const UPDATE_RATES = "UPDATE_RATES"
export const UPDATE_PropertyDetailNew = "UPDATE_PROPERTYDETAIL_NEW"
export const UPDATE_ProfileS = "UPDATE_FPROFILE_STATUS"
export const UPDATE_PBPR = "UPDATE_PBPR"
export const UPDATE_LOANTYPE = "UPDATE_LOANTYPE"
export const UPDATE_HOUSETYPE = "UPDATE_HOUSETYPE"
export const UPDATE_LT = "UPDATE_LT"
export const UPDATE_PROPDET = "UPDATE_PROPERTY_DETAILS"
export const UPDATE_PP = "UPDATE_PP"
export const UPDATE_IO = "UPDATE_IO"
export const UPDATE_RIM = "UPDATE_RIM"
export const ADJUST = 'ADJUST'
export const REVIEW = 'REVIEW'
export const UPDATEPROFILE = 'UPDATEPROFILE'
export const CREATEPROFILE = 'CREATEPROFILE'
export const UPDATE_CP = "UPDATE_CP"
export const REFIINIT = "REFIINIT"
export const REFIMAX = "REFIMAX"
export const REFICOMP = "REFICOMP"
export const REFIAP = "REFIAP"
export const SIGNUP = "SIGNUP"
export const SIGNIN = "SIGNIN"
export const LOGOUT = "LOGOUT"
export const ISSIGNUP = "ISSIGNUP"
export const ISSIGNIN = "ISSIGNIN"
export const LOGGEDIN = "LOGGEDIN"
export const DIMENSIONS = "DIMENSIONS"
export const PROFILEC = "PROFILEC"
export const START_LOADING = "START_LOADING"
export const START_EMPLOYER_LOADING = "START_E_LOADING"
export const END_LOADING = "END_LOADING"
export const END_EMPLOYER_LOADING = "END_E_LOADING"
export const START_BCHOICED_LOADING ="START_BCHOICED_LOADING"
export const END_BCHOICED_LOADING ="END_BCHOICED_LOADING"

export const USERUPDATE = "USERUPDATE"
export const USERFINANCEUPDATE = "USERFINANCEUPDATE"
export const USERPROPERTYUPDATE = "USERPROPERTYUPDATE"
export const UPDATE_PROFILEJOURNEY = "UPDATE_PROFILEJOURNEY"
export const NAVFLAG = "NAVFLAG"
export const EMBFLOWFLAG = "EMBFLOWFLAG"
export const BORROWERFLOWFLAG = "BORROWERFLOWFLAG"
export const IS_PROFILE_INFO_CHANGED = "IS_PROFILE_INFO_CHANGED"
export const IS_LOACTION_CHANGED = "IS_LOACTION_CHANGED"
export const IS_INCOME_SAVING_CHANGED = "IS_INCOME_SAVING_CHANGED"
export const GENERATEDUSERID = "GENERATEDUSERID"
export const NAVEMPLOYER = "NAVEMPLOYER"
export const NEWEMPLOYER = "NEWEMPLOYER"
export const COUNTRYCODEFLAG = "COUNTRYCODEFLAG"
export const STATECODEFLAG = "STATECODEFLAG"
export const BREADCRUMBFLAG = "BREADCRUMBFLAG"
export const ISFIRSTTIMEBUYER = "ISFIRSTTIMEBUYER"

export const UPDATE_LocalStorage = "UPDATE_LOCALSTORAGE"

export function withCommas(x) {
	if (typeof (x) === 'undefined') return '$0.0001'
	return '$'.concat(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
};

export function withCommasWithoutCurrency(x) {
	if (typeof (x) === 'undefined') return '0.0000'
	return ''.concat(x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
};

export function convertToInternationalCurrencySystem(labelValue) {

	// Nine Zeroes for Billions
	return Math.abs(Number(labelValue)) >= 1.0e+9

		? Math.round(((Math.abs(Number(labelValue)) / 1.0e+9) / 100) * 100) + "B"
		// Six Zeroes for Millions 
		: Math.abs(Number(labelValue)) >= 1.0e+6

			? Math.round(((Math.abs(Number(labelValue)) / 1.0e+6) / 100) * 100) + "M"
			// Three Zeroes for Thousands
			: Math.abs(Number(labelValue)) >= 1.0e+3

				? Math.round(((Math.abs(Number(labelValue)) / 1.0e+3) / 100) * 100) + "K"

				: Math.abs(Number(labelValue));

}

export const defaultEmployer = {
	title: 'Nestfuel Reality Inc',
	_nestfuelId: '00128',
	nestfuelRelLevel: 'basic',
	address: '10 Downing Street',
	city: 'London',
	state: 'CA'
};

export const stateList = [
	{ full: 'Alabama', short: 'AL' },
	{ full: 'Alaska', short: 'AL' },
	{ full: 'Arizona', short: 'AZ' },
	{ full: 'California', short: 'CA' },
	{ full: 'Colorado', short: 'CO' },
	{ full: 'Florida', short: 'FL' },
	{ full: 'Georgia', short: 'GE' },
	{ full: 'Hawaii', short: 'HA' },
	{ full: 'Illinois', short: 'IL' },
	{ full: 'Indiana', short: 'IN' },
	{ full: 'Maryland', short: 'MD' },
	{ full: 'Massachusetts', short: 'MA' },
	{ full: 'Montana', short: 'MO' },
	{ full: 'Minnesota', short: 'MN' },
	{ full: 'Michigan', short: 'MI' },
	{ full: 'New Jersey', short: 'NJ' },
	{ full: 'New York', short: 'NY' },
	{ full: 'North Carolina', short: 'NC' },
	{ full: 'North Dakota', short: 'ND' },
	{ full: 'Ohio', short: 'OH' },
	{ full: 'Oregon', short: 'OG' },
	{ full: 'South Carolina', short: 'SC' },
	{ full: 'South Dakota', short: 'SD' },
	{ full: 'Texas', short: 'TX' },
	{ full: 'Tenessee', short: 'TN' },
	{ full: 'Utah', short: 'UT' },
	{ full: 'Verginia', short: 'VG' },
	{ full: 'West Verginia', short: 'WV' },
	{ full: 'Washington', short: 'WN' },
]
export const stateList1 = [
	{ full: 'Colorado', short: 'CO' }
]
// export const employerList = [
//   { title: 'Google Inc',                    _nestfuelId:'00125', nestfuelRelLevel:'default',  address:'1234 Downing Street', city:'Santa Clara'   , state:'CO', rateDiscount:1.2},
//   { title: 'Hewlett Packard',               _nestfuelId:'00126', nestfuelRelLevel:'default',  address:'1234 Downing Street', city:'Cupertino'     , state:'CA', rateDiscount:2.5},
//   { title: 'Microsoft Corporation',         _nestfuelId:'00127', nestfuelRelLevel:'default',  address:'1234 Downing Street', city:'Harrisberg'    , state:'CA', rateDiscount:1.8},
//   { title: 'Nestfuel Reality Inc',          _nestfuelId:'00128', nestfuelRelLevel:'basic',    address:'1234 Downing Street', city:'Portmouth'     , state:'CA', rateDiscount:2.0},
//   { title: 'ASIL Partners LLP',             _nestfuelId:'00129', nestfuelRelLevel:'basic',    address:'0123 45 The Kremlin', city:'Boulder'       , state:'CA', rateDiscount:1.8},
//   { title: "Anqueke Energy Efficiency Ltd", _nestfuelId:'00130', nestfuelRelLevel:'basic',    address:'123 Briarwood Drive', city:'New Hampshire' , state:'CO', rateDiscount:2.5},
//   { title: 'Philips Engineering Inc',       _nestfuelId:'00131', nestfuelRelLevel:'basic',    address:'1234 Downing Street', city:'Santa Monika'  , state:'CO', rateDiscount:1.2},
//   { title: 'Tata Consultancy Services Ltd', _nestfuelId:'00132', nestfuelRelLevel:'default',  address:'1234 AL Mashael Str', city:'Sandersberg'   , state:'CO', rateDiscount:2.0},
//   { title: 'Infosys Corporation',           _nestfuelId:'00133', nestfuelRelLevel:'default',  address:'123 Briarwood Drive', city:'Blair Sharif'  , state:'CA', rateDiscount:1.8},
//   { title: 'Cap Gemini Ltd',                _nestfuelId:'00134', nestfuelRelLevel:'default',  address:'1234 56Al Janubiyah', city:'Lauhore'       , state:'CA', rateDiscount:2.5},
//   { title: 'Apple Inc',                     _nestfuelId:'00135', nestfuelRelLevel:'default',  address:'1234 Downing Street', city:'New York'      , state:'CA', rateDiscount:1.8},
//   { title: 'Samsung Corporation',           _nestfuelId:'00136', nestfuelRelLevel:'premium',  address:'1234 Downing Street', city:'Denver'        , state:'CA', rateDiscount:1.2},
//   { title: 'Burrow Computers Ltd',          _nestfuelId:'00137', nestfuelRelLevel:'premium',  address:'1234 Downing Street', city:'Fort Collins'  , state:'CO', rateDiscount:1.2},
//   { title: 'Mittal Steel',                  _nestfuelId:'00138', nestfuelRelLevel:'premium',  address:'1234 College Street', city:'Philadelphia'  , state:'CO', rateDiscount:0.5},
//   { title: 'Bharti Airtel Inc',             _nestfuelId:'00139', nestfuelRelLevel:'premium',  address:'16834 Green Blvd', city:'Los Angeles'      , state:'CA', rateDiscount:0.5},
//   { title: "BSNL Ltd",                      _nestfuelId:'00140', nestfuelRelLevel:'default',  address:'1234 Downing Street', city:'Boulder'       , state:'CO', rateDiscount:2.0},
//   { title: 'Bank of America',               _nestfuelId:'00141', nestfuelRelLevel:'default',  address:'1234 Downing Street', city:'Greeley'       , state:'CO', rateDiscount:2.5},
//   { title: 'Wells Fargo Investments',       _nestfuelId:'00142', nestfuelRelLevel:'default',  address:'1234 College Street', city:'San Franscisco', state:'CA', rateDiscount:0.5},
//   { title: 'Lenovo Computers',              _nestfuelId:'00143', nestfuelRelLevel:'default',  address:'1234 Downing Street', city:'San Jose'      , state:'CA', rateDiscount:2.0},
//   { title: 'Sony Corporation',              _nestfuelId:'00144', nestfuelRelLevel:'default',  address:'1234 College Street', city:'Austin'        , state:'CA', rateDiscount:1.5},
//   { title: 'Plaza locks and keys',          _nestfuelId:'00145', nestfuelRelLevel:'superior', address:'12634 Borrow Street', city:'Dallas'        , state:'CA', rateDiscount:1.8},
//   { title: 'Haldiram Bhujiawala',           _nestfuelId:'00146', nestfuelRelLevel:'superior', address:'124 Himalayan Street', city:'Roundrock'     , state:'CO', rateDiscount:1.8},
//   { title: 'Plural Sight',                  _nestfuelId:'00147', nestfuelRelLevel:'superior', address:'1234 College Street', city:'New Delhi'     , state:'CO', rateDiscount:1.2},
//   { title: "Safari Lebolo",                 _nestfuelId:'00148', nestfuelRelLevel:'superior', address:'1234 Grapest Street', city:'London'        , state:'CO', rateDiscount:1.2},
//   { title: 'Dark and Pensors',              _nestfuelId:'00149', nestfuelRelLevel:'default',  address:'1234 Downing Street', city:'New Jersey'    , state:'CO', rateDiscount:1.2},
//   { title: 'Zara and Loya',                 _nestfuelId:'00150', nestfuelRelLevel:'default',  address:'1234 Downing Street', city:'New Portland'  , state:'CO', rateDiscount:2.0},
// 


export const employerList = [
	{ title: '10-4 Systems Inc.', _nestfuelId: 'PRE00274', nestfuelRelLevel: 'default',  address: '4888 Pearl East Circle Suite 300W Boulder', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: '3t Systems', _nestfuelId: 'PRE00275', nestfuelRelLevel: 'default',  address: '5990 Greenwood Plaza Blvd', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: '66degrees', _nestfuelId: 'PRE00276', nestfuelRelLevel: 'default',  address: '9155 E Nichols Ave Ste 300', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'A2Z Sync', _nestfuelId: 'PRE00277', nestfuelRelLevel: 'default',  address: '5690 DTC Blvd # 400E', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'A-Affordable Disposal', _nestfuelId: 'PRE00278', nestfuelRelLevel: 'default',  address: '1370 Halleck Ave', city: 'Pueblo'   , state: 'CO', rateDiscount: 1.8},
{ title: 'AbsenceSoft', _nestfuelId: 'PRE00279', nestfuelRelLevel: 'default',  address: '14143 Denver W Pkwy #350', city: 'Golden'   , state: 'O', rateDiscount: 1.8},
{ title: 'Accelo', _nestfuelId: 'PRE00280', nestfuelRelLevel: 'default',  address: '999 18th St 12th floor', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Accurence Inc', _nestfuelId: 'PRE00281', nestfuelRelLevel: 'default',  address: '305 S Arthur Ave', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Acquiro', _nestfuelId: 'PRE00282', nestfuelRelLevel: 'default',  address: '7400 E Orchard Road #350s Greenwood Village CO 80210', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'AdAction', _nestfuelId: 'PRE00283', nestfuelRelLevel: 'default',  address: '200 Union Blvd #305', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Adams 12 Five Star Schools', _nestfuelId: 'PRE00284', nestfuelRelLevel: 'default',  address: '1500 E 128th Ave', city: 'Thornton'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Adams County Sheriff’s Office', _nestfuelId: 'PRE00285', nestfuelRelLevel: 'default',  address: '4430 S Adams County Pkwy 1st Floor', city: 'Brighton'   , state: 'CO', rateDiscount: 1.8},
{ title: 'AdCellerant', _nestfuelId: 'PRE00286', nestfuelRelLevel: 'default',  address: '900 Bannock St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ADP', _nestfuelId: 'PRE00287', nestfuelRelLevel: 'default',  address: '12250 E Iliff Ave', city: 'Aurora'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Adrenal Fatigue Solution', _nestfuelId: 'PRE00288', nestfuelRelLevel: 'default',  address: '12207 Pecos Street', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Adswerve, Inc.', _nestfuelId: 'PRE00289', nestfuelRelLevel: 'default',  address: 'Denver Place', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Advanced Exercise', _nestfuelId: 'PRE00290', nestfuelRelLevel: 'default',  address: '861 Southpark Dr # 100', city: 'Littleton'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Aetna Digital', _nestfuelId: 'PRE00291', nestfuelRelLevel: 'default',  address: '1500 Wynkoop St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Aflac', _nestfuelId: 'PRE00292', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado Spring'   , state: 'CO', rateDiscount: 1.8},
{ title: 'aforza', _nestfuelId: 'PRE00293', nestfuelRelLevel: 'default',  address: '242 Linden St Fort Collins CO 80524', city: 'Fort Collins'   , state: 'CO', rateDiscount: 1.8},
{ title: 'AgentSync', _nestfuelId: 'PRE00294', nestfuelRelLevel: 'default',  address: '2734 Walnut St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Agility Recovery', _nestfuelId: 'PRE00295', nestfuelRelLevel: 'default',  address: '1225 17th St UNIT 2000', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'AIM Consulting', _nestfuelId: 'PRE00296', nestfuelRelLevel: 'default',  address: '4600 S Syracuse St Ste 600', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'AirDNA', _nestfuelId: 'PRE00297', nestfuelRelLevel: 'default',  address: '1507 Blake St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Alchemer', _nestfuelId: 'PRE00298', nestfuelRelLevel: 'default',  address: '168 Centennial Pkwy Suite #250', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Alpine Link Corp', _nestfuelId: 'PRE00299', nestfuelRelLevel: 'default',  address: '2345 Tiger Rd', city: 'Breckenridge'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Alterian', _nestfuelId: 'PRE00300', nestfuelRelLevel: 'default',  address: '1550 Larimer St Suite 105', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Altvia Solutions, LLC', _nestfuelId: 'PRE00301', nestfuelRelLevel: 'default',  address: '1800 Wazee St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Amadeus Consulting', _nestfuelId: 'PRE00302', nestfuelRelLevel: 'default',  address: '2580 55th St', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Ambra Health', _nestfuelId: 'PRE00303', nestfuelRelLevel: 'default',  address: 'Denver CO 80202', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'AMP Robotics', _nestfuelId: 'PRE00304', nestfuelRelLevel: 'default',  address: '2770 Industrial Ln', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Apostrophe, Inc.', _nestfuelId: 'PRE00305', nestfuelRelLevel: 'default',  address: '999 18th St Suite 700S', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'AppIt Ventures', _nestfuelId: 'PRE00306', nestfuelRelLevel: 'default',  address: '1800 Wazee St Suite 300', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ARM MLM Software', _nestfuelId: 'PRE00307', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Array', _nestfuelId: 'PRE00308', nestfuelRelLevel: 'default',  address: '7100 E Belleview Ave #350', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Arrive Health (formerly RxRevu)', _nestfuelId: 'PRE00309', nestfuelRelLevel: 'default',  address: '3001 Brighton Blvd Ste 103', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Arryved', _nestfuelId: 'PRE00310', nestfuelRelLevel: 'default',  address: '3002 Sterling Cir suite 100', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Ascend Analytics', _nestfuelId: 'PRE00311', nestfuelRelLevel: 'default',  address: '1877 Broadway #706', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Ascent Living Communities', _nestfuelId: 'PRE00312', nestfuelRelLevel: 'default',  address: '6025 S Quebec St', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ASF Payment Solutions', _nestfuelId: 'PRE00313', nestfuelRelLevel: 'default',  address: '9127 S Jamaica St', city: 'Englewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Ashford University', _nestfuelId: 'PRE00314', nestfuelRelLevel: 'default',  address: 'Arapahoe St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Assignar', _nestfuelId: 'PRE00315', nestfuelRelLevel: 'default',  address: '1644 Platte St Suite 410', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Auror', _nestfuelId: 'PRE00316', nestfuelRelLevel: 'default',  address: 'NA', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'AUTOPAY', _nestfuelId: 'PRE00317', nestfuelRelLevel: 'default',  address: '8055 E Tufts Ave', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Autowash Companies', _nestfuelId: 'PRE00318', nestfuelRelLevel: 'default',  address: '7005 E. 46th Avenue Drive', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Avoka', _nestfuelId: 'PRE00319', nestfuelRelLevel: 'default',  address: '385 Interlocken Crescent #1050', city: 'Broomfield'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Axcient', _nestfuelId: 'PRE00320', nestfuelRelLevel: 'default',  address: '707 17th St UNIT 3900', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'AXS Digital', _nestfuelId: 'PRE00321', nestfuelRelLevel: 'default',  address: '8269 E 23rd Ave', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Backbone', _nestfuelId: 'PRE00322', nestfuelRelLevel: 'default',  address: '1801 13th St #120', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Backflip Studios', _nestfuelId: 'PRE00323', nestfuelRelLevel: 'default',  address: '1690 38th St', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Background Information Services, Inc.', _nestfuelId: 'PRE00324', nestfuelRelLevel: 'default',  address: '1800 30th St', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Bala Srinivasa Rao Namburi', _nestfuelId: 'PRE00325', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Ball Aerospace', _nestfuelId: 'PRE00326', nestfuelRelLevel: 'default',  address: '1600 Commerce Street', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Ball HQ', _nestfuelId: 'PRE00327', nestfuelRelLevel: 'default',  address: '9200 W 108th Circle', city: 'Westminster'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Bang the Table', _nestfuelId: 'PRE00328', nestfuelRelLevel: 'default',  address: '2400 Spruce St #204', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Beaver Run Resort & Conference Center', _nestfuelId: 'PRE00329', nestfuelRelLevel: 'default',  address: '620 Village Rd', city: 'Breckenridge'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Bechtel & Santo', _nestfuelId: 'PRE00330', nestfuelRelLevel: 'default',  address: '205 N 4th St #401', city: 'Grand Junction'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Betterment', _nestfuelId: 'PRE00331', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'BG Buildingworks', _nestfuelId: 'PRE00332', nestfuelRelLevel: 'default',  address: '1626 Cole Blvd Suite 300', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Biarri Networks', _nestfuelId: 'PRE00333', nestfuelRelLevel: 'default',  address: 'NA', city: 'Centennial '   , state: 'CO', rateDiscount: 1.8},
{ title: 'Big Agnes Inc', _nestfuelId: 'PRE00334', nestfuelRelLevel: 'default',  address: '840 Yampa St', city: 'Steamboat Springs'   , state: 'CO', rateDiscount: 1.8},
{ title: 'BillGO', _nestfuelId: 'PRE00335', nestfuelRelLevel: 'default',  address: '3003 E Harmony Rd 5th floor', city: 'Fort Collins'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Billiard Congress of America', _nestfuelId: 'PRE00336', nestfuelRelLevel: 'default',  address: '500 Discovery Pkwy', city: 'Superior'   , state: 'CO', rateDiscount: 1.8},
{ title: 'BillingPlatform', _nestfuelId: 'PRE00337', nestfuelRelLevel: 'default',  address: '12650 E Briarwood Ave Suite 204', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Bitmovin', _nestfuelId: 'PRE00338', nestfuelRelLevel: 'default',  address: '1444 Wazee St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Blue Canyon Technologies', _nestfuelId: 'PRE00339', nestfuelRelLevel: 'default',  address: '5340 Airport Blvd', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Blue Collar Financial Advisors', _nestfuelId: 'PRE00340', nestfuelRelLevel: 'default',  address: '6080 Greenwood Plaza Boulevard Suite A', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Blue Moon Digital, Inc. (now MERGE)', _nestfuelId: 'PRE00341', nestfuelRelLevel: 'default',  address: '1512 Larimer St #800', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Bluestaq', _nestfuelId: 'PRE00342', nestfuelRelLevel: 'default',  address: '2 N Cascade Ave Suite 100', city: 'Colorado Springs'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Bombora', _nestfuelId: 'PRE00343', nestfuelRelLevel: 'default',  address: '1615 Platte Street Floor 2 Denver CO 80202', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Bonusly', _nestfuelId: 'PRE00344', nestfuelRelLevel: 'default',  address: '2755 Canyon Blvd', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Booyah Advertising', _nestfuelId: 'PRE00345', nestfuelRelLevel: 'default',  address: '3001 Brighton Blvd Suite 236', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Bossed Up', _nestfuelId: 'PRE00346', nestfuelRelLevel: 'default',  address: '2301 Blake St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Boulder Associates', _nestfuelId: 'PRE00347', nestfuelRelLevel: 'default',  address: '1426 Pearl St Ste 300. Boulder', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Boulder Heavy Industries', _nestfuelId: 'PRE00348', nestfuelRelLevel: 'default',  address: '2000 Central Ave #100', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Boulder Housing Partners', _nestfuelId: 'PRE00349', nestfuelRelLevel: 'default',  address: '4800 North Broadway', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Bounteous', _nestfuelId: 'PRE00350', nestfuelRelLevel: 'default',  address: '1099 18th St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Brandfolder by Smartsheet', _nestfuelId: 'PRE00351', nestfuelRelLevel: 'default',  address: '3501 Wazee St #300', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Bridge Diagnostics Inc.', _nestfuelId: 'PRE00352', nestfuelRelLevel: 'default',  address: '740 S Pierce Ave #15', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Bridgevine, Inc.', _nestfuelId: 'PRE00353', nestfuelRelLevel: 'default',  address: '165 S. Union Blvd. Suite 405 Lakewood CO 80228', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'brightfin', _nestfuelId: 'PRE00354', nestfuelRelLevel: 'default',  address: '8310 S Valley Hwy', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'brightwheel', _nestfuelId: 'PRE00355', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Broadnet', _nestfuelId: 'PRE00356', nestfuelRelLevel: 'default',  address: ' 8822 S Ridgeline Blvd', city: 'Highlands Ranch'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Brown & Brown', _nestfuelId: 'PRE00357', nestfuelRelLevel: 'default',  address: '1125 17th St Suite 1450', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Built In', _nestfuelId: 'PRE00358', nestfuelRelLevel: 'default',  address: '1062 Delaware St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Cachematrix', _nestfuelId: 'PRE00359', nestfuelRelLevel: 'default',  address: '44 Cook St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'CampMinder', _nestfuelId: 'PRE00360', nestfuelRelLevel: 'default',  address: '5766 Central Ave', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Candid Culture Inc.', _nestfuelId: 'PRE00361', nestfuelRelLevel: 'default',  address: '700 N. Colorado Blvd. Suite 410 Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Cardinal Peak', _nestfuelId: 'PRE00362', nestfuelRelLevel: 'default',  address: '1380 Forest Park Cir #202', city: 'Lafayette'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Catalyst Repository Systems', _nestfuelId: 'PRE00363', nestfuelRelLevel: 'default',  address: '1860 Blake Street', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Catalyze', _nestfuelId: 'PRE00364', nestfuelRelLevel: 'default',  address: '6325 Gunpark Drive', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Catholic Charities of Denver', _nestfuelId: 'PRE00365', nestfuelRelLevel: 'default',  address: '6240 Smith Rd', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'CBIZ Benefits & Insurance', _nestfuelId: 'PRE00366', nestfuelRelLevel: 'default',  address: '4600 S. Ulster St.', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'CE Broker and EverCheck', _nestfuelId: 'PRE00367', nestfuelRelLevel: 'default',  address: '2400 Spruce St', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'CesiumAstro', _nestfuelId: 'PRE00368', nestfuelRelLevel: 'default',  address: '105 Edgeview Dr Suite 310', city: 'Broomfield'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Cetera Financial Group', _nestfuelId: 'PRE00369', nestfuelRelLevel: 'default',  address: '5299 DTC Blvd.', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Ceylon Solutions', _nestfuelId: 'PRE00370', nestfuelRelLevel: 'default',  address: '6050 Greenwood Plaza Blvd #125', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Childrens Hospital Colorado', _nestfuelId: 'PRE00371', nestfuelRelLevel: 'default',  address: '13123 E 16th Ave', city: 'Aurora'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Choozle', _nestfuelId: 'PRE00372', nestfuelRelLevel: 'default',  address: '3513 Brighton Blvd Suite 510', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Cin7', _nestfuelId: 'PRE00373', nestfuelRelLevel: 'default',  address: '1444 Wazee St Suite 130', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'CircleCI', _nestfuelId: 'PRE00374', nestfuelRelLevel: 'default',  address: '201 Spear St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'CirrusMD', _nestfuelId: 'PRE00375', nestfuelRelLevel: 'default',  address: '3513 Brighton Blvd Suite 230', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'City and County of Denver', _nestfuelId: 'PRE00376', nestfuelRelLevel: 'default',  address: '201 W. Colfax Ave. Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'City of Brighton', _nestfuelId: 'PRE00377', nestfuelRelLevel: 'default',  address: '500 S 4th Ave', city: 'Brighton'   , state: 'CO', rateDiscount: 1.8},
{ title: 'City Of Lafayette', _nestfuelId: 'PRE00378', nestfuelRelLevel: 'default',  address: '1290 S Public Rd', city: 'Lafayette'   , state: 'CO', rateDiscount: 1.8},
{ title: 'City of Longmont', _nestfuelId: 'PRE00379', nestfuelRelLevel: 'default',  address: '350 Kimbark St. Longmont', city: 'Longmont'   , state: 'CO', rateDiscount: 1.8},
{ title: 'City of Loveland', _nestfuelId: 'PRE00380', nestfuelRelLevel: 'default',  address: '500 E 3rd St', city: 'Loveland'   , state: 'CO', rateDiscount: 1.8},
{ title: 'City of Pueblo', _nestfuelId: 'PRE00381', nestfuelRelLevel: 'default',  address: '230 S Mechanic St Pueblo', city: 'Pueblo'   , state: 'CO', rateDiscount: 1.8},
{ title: 'City of Westminster', _nestfuelId: 'PRE00382', nestfuelRelLevel: 'default',  address: '4800 W 92nd Ave', city: 'Westminster'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Classic Homes', _nestfuelId: 'PRE00383', nestfuelRelLevel: 'default',  address: '385 Corporate Dr Ste 200', city: 'Colorado Spring'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ClearCompany', _nestfuelId: 'PRE00384', nestfuelRelLevel: 'default',  address: '2821 Remington St', city: 'Fort Collins'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ClimaCell', _nestfuelId: 'PRE00385', nestfuelRelLevel: 'default',  address: '5541 Central Avenue West Suite 270 Boulder CO 80301', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Cloudrise, Inc.', _nestfuelId: 'PRE00386', nestfuelRelLevel: 'default',  address: '1601 Riverfront Dr #101', city: 'Grand Junction'   , state: 'CO', rateDiscount: 1.8},
{ title: 'CollegeInvest', _nestfuelId: 'PRE00387', nestfuelRelLevel: 'default',  address: '1600 Broadway', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Colorado Access', _nestfuelId: 'PRE00388', nestfuelRelLevel: 'default',  address: '11100 East Bethany Drive Aurora', city: 'Aurora'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'Colorado Christian University', _nestfuelId: 'PRE00389', nestfuelRelLevel: 'default',  address: '8787 W Alameda Ave', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Colorado College', _nestfuelId: 'PRE00390', nestfuelRelLevel: 'default',  address: '819 N. Tejon St. Colorado Spgs.', city: 'Colorado Spring'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Colorado Department of Labor and Employment', _nestfuelId: 'PRE00391', nestfuelRelLevel: 'default',  address: '633 17th Street', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Colorado Department of Public Health and Environment', _nestfuelId: 'PRE00392', nestfuelRelLevel: 'default',  address: '4300 Cherry Creek Dr S. Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Colorado Kidney Care', _nestfuelId: 'PRE00393', nestfuelRelLevel: 'default',  address: '30 Rampart Way Ste 300B', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Colorado Mesa University', _nestfuelId: 'PRE00394', nestfuelRelLevel: 'default',  address: '1100 North Ave', city: 'Grand Junction'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Computronix', _nestfuelId: 'PRE00395', nestfuelRelLevel: 'default',  address: '3900 S Wadsworth Blvd', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Conductor', _nestfuelId: 'PRE00396', nestfuelRelLevel: 'default',  address: 'NA', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Connect for Health Colorado', _nestfuelId: 'PRE00397', nestfuelRelLevel: 'default',  address: '4600 S Ulster St #300', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Conversocial', _nestfuelId: 'PRE00398', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Copper Mountain Resort', _nestfuelId: 'PRE00399', nestfuelRelLevel: 'default',  address: '0800 Copper Rd 3001', city: 'Frisco'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Cordial Experience, Inc.', _nestfuelId: 'PRE00400', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'CORHIO', _nestfuelId: 'PRE00401', nestfuelRelLevel: 'default',  address: ' 4500 Cherry Creek S. Dr. Suite 820. Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'CQG, Inc', _nestfuelId: 'PRE00402', nestfuelRelLevel: 'default',  address: '1999 Broadway #1550', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Craig hospital', _nestfuelId: 'PRE00403', nestfuelRelLevel: 'default',  address: '3425 South Clarkson Street', city: 'Englewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Crusoe Energy Systems LLC', _nestfuelId: 'PRE00404', nestfuelRelLevel: 'default',  address: '1641 California St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'CSU', _nestfuelId: 'PRE00405', nestfuelRelLevel: 'default',  address: '711 Oval Drive', city: 'Fort Collins'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'Cvent', _nestfuelId: 'PRE00406', nestfuelRelLevel: 'default',  address: '2191 Arapahoe St. Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'CyberGRX, Inc.', _nestfuelId: 'PRE00407', nestfuelRelLevel: 'default',  address: '1601 19th St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'DAS42', _nestfuelId: 'PRE00408', nestfuelRelLevel: 'default',  address: '1450 Broadway 35th floor', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'DAT Solutions', _nestfuelId: 'PRE00409', nestfuelRelLevel: 'default',  address: '5050 South Syracuse Street. 975. Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Deer Park Road', _nestfuelId: 'PRE00410', nestfuelRelLevel: 'default',  address: '1195 Bangtail Way', city: 'Steamboat Springs'   , state: 'CO', rateDiscount: 1.8},
{ title: 'DELVE', _nestfuelId: 'PRE00411', nestfuelRelLevel: 'default',  address: '183 S Taylor Ave Unit 156', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Denver Health', _nestfuelId: 'PRE00412', nestfuelRelLevel: 'default',  address: '777 Bannock St. Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Denver Office of the Clerk and Recorder', _nestfuelId: 'PRE00413', nestfuelRelLevel: 'default',  address: '201 W. Colfax Ave.', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Denver Regional Council of Governments', _nestfuelId: 'PRE00414', nestfuelRelLevel: 'default',  address: '1001 17th St #700', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Development Disabilities Resource Center', _nestfuelId: 'PRE00415', nestfuelRelLevel: 'default',  address: '11177 W 8th Ave', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'DHI Group, Inc.', _nestfuelId: 'PRE00416', nestfuelRelLevel: 'default',  address: '6465 Greenwood Plaza Blvd', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Diversified Machine Systems', _nestfuelId: 'PRE00417', nestfuelRelLevel: 'default',  address: '1068 Elkton Dr', city: 'Colorado Springs '   , state: 'CO', rateDiscount: 1.8},
{ title: 'Division Of Family Medical Leave Insurance', _nestfuelId: 'PRE00418', nestfuelRelLevel: 'default',  address: '633 17th St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'DMS', _nestfuelId: 'PRE00419', nestfuelRelLevel: 'default',  address: ' 1068 Elkton Drive Colorado Springs', city: 'Colorado Springs '   , state: 'CO', rateDiscount: 1.8},
{ title: 'DN2K', _nestfuelId: 'PRE00420', nestfuelRelLevel: 'default',  address: '12600 E Arapahoe Rd #175', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Double Encore, Inc.', _nestfuelId: 'PRE00421', nestfuelRelLevel: 'default',  address: '820 16th St Mall Suite 101', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'dpiX LLC', _nestfuelId: 'PRE00422', nestfuelRelLevel: 'default',  address: '1635 Aeroplaza Drive', city: 'Colorado Springs'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'DrChrono', _nestfuelId: 'PRE00423', nestfuelRelLevel: 'default',  address: '328 Gibraltar Dr', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Duda, Inc.', _nestfuelId: 'PRE00424', nestfuelRelLevel: 'default',  address: '1025 Cannon St #1C', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'E Source', _nestfuelId: 'PRE00425', nestfuelRelLevel: 'default',  address: '1745 38th St', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'East West Hospitality/Urban Mangement', _nestfuelId: 'PRE00426', nestfuelRelLevel: 'default',  address: '1550 Wewatta St Ste 540', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Edison Software', _nestfuelId: 'PRE00427', nestfuelRelLevel: 'default',  address: '2401 E 2nd Ave Suite 600', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Egg Strategy', _nestfuelId: 'PRE00428', nestfuelRelLevel: 'default',  address: '1555 Blake St Ste 450', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Eggplant', _nestfuelId: 'PRE00429', nestfuelRelLevel: 'default',  address: '2995 Wilderness Pl', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'EGYM', _nestfuelId: 'PRE00430', nestfuelRelLevel: 'default',  address: 'EGYM Inc 1630 30th St. Suite A546 Boulder', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Elastic Suite', _nestfuelId: 'PRE00431', nestfuelRelLevel: 'default',  address: '1444 Wazee St Suite 333', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Elementum 3D', _nestfuelId: 'PRE00432', nestfuelRelLevel: 'default',  address: '400 Young Ct Ste 1', city: 'Erie'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Elsmere Education', _nestfuelId: 'PRE00433', nestfuelRelLevel: 'default',  address: '90 Madison St #602', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Emerge', _nestfuelId: 'PRE00434', nestfuelRelLevel: 'default',  address: '2701 Lawrence Street', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Employers Council', _nestfuelId: 'PRE00435', nestfuelRelLevel: 'default',  address: '1799 Pennsylvania St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Empyreal Logistics', _nestfuelId: 'PRE00436', nestfuelRelLevel: 'default',  address: '101111 Po Box', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'EMS Software', _nestfuelId: 'PRE00437', nestfuelRelLevel: 'default',  address: '6465 Greenwood Plaza Blvd Ste 600', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Enquire', _nestfuelId: 'PRE00438', nestfuelRelLevel: 'default',  address: '7600 E Orchard Rd #100n', city: 'Greenwood Village '   , state: 'CO', rateDiscount: 1.8},
{ title: 'Enscicon Corporation', _nestfuelId: 'PRE00439', nestfuelRelLevel: 'default',  address: '2420 W 26th Ave #500D', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Ent Credit Union', _nestfuelId: 'PRE00440', nestfuelRelLevel: 'default',  address: '11550 Ent Pkwy', city: 'Colorado Springs'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'Envysion', _nestfuelId: 'PRE00441', nestfuelRelLevel: 'default',  address: ' 7237 Church Ranch Blvd Suite 406. Westminster', city: 'Superior'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Eventus Solutions Group', _nestfuelId: 'PRE00442', nestfuelRelLevel: 'default',  address: '9777 Pyramid Ct #160', city: 'Englewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Fairfield and Woods, P.C.', _nestfuelId: 'PRE00443', nestfuelRelLevel: 'default',  address: '1801 California St Ste 2600', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Fall River Benefits', _nestfuelId: 'PRE00444', nestfuelRelLevel: 'default',  address: '2829 Wyandot St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Fastly', _nestfuelId: 'PRE00445', nestfuelRelLevel: 'default',  address: '1451 Larimer St', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Finder', _nestfuelId: 'PRE00446', nestfuelRelLevel: 'default',  address: '1644 Platte St Denver CO 80202', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Fintech', _nestfuelId: 'PRE00447', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'FJA-US Inc.', _nestfuelId: 'PRE00448', nestfuelRelLevel: 'default',  address: '633 17th St #1400', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Flatirons Solutions, Inc', _nestfuelId: 'PRE00449', nestfuelRelLevel: 'default',  address: '5755 Central Ave', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Flexential', _nestfuelId: 'PRE00450', nestfuelRelLevel: 'default',  address: '6400 S', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Flood and Peterson', _nestfuelId: 'PRE00451', nestfuelRelLevel: 'default',  address: '2000 S Colorado Blvd Tower 1 4000', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Flowhub', _nestfuelId: 'PRE00452', nestfuelRelLevel: 'default',  address: '1630 Welton St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Fluid Truck', _nestfuelId: 'PRE00453', nestfuelRelLevel: 'default',  address: '400 W 48th Ave Suite 300', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'FORM', _nestfuelId: 'PRE00454', nestfuelRelLevel: 'default',  address: 'NA', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Formstack', _nestfuelId: 'PRE00455', nestfuelRelLevel: 'default',  address: '6 S Tejon St #700', city: 'Colorado Springs'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Forney Industries', _nestfuelId: 'PRE00456', nestfuelRelLevel: 'default',  address: '2057 Vermont Dr', city: 'Fort Collins'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'Foundation Learning', _nestfuelId: 'PRE00457', nestfuelRelLevel: 'default',  address: '165 S Union Blvd #777', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'FRONTSTEPS', _nestfuelId: 'PRE00458', nestfuelRelLevel: 'default',  address: '1290 Broadway Suite 1250', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Fruition', _nestfuelId: 'PRE00459', nestfuelRelLevel: 'default',  address: '1313 E 6th Ave', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'FTC Solar', _nestfuelId: 'PRE00460', nestfuelRelLevel: 'default',  address: '9020 N Capital of Texas Hwy', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Furniture For Life', _nestfuelId: 'PRE00461', nestfuelRelLevel: 'default',  address: '2125 32nd St', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Fuse - Igniting Communication', _nestfuelId: 'PRE00462', nestfuelRelLevel: 'default',  address: '80238 US Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'gaiacreative', _nestfuelId: 'PRE00463', nestfuelRelLevel: 'default',  address: '539 Fireside St', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Galloway & Company', _nestfuelId: 'PRE00464', nestfuelRelLevel: 'default',  address: '5265 Ronald Reagan Blvd. Suite 210. Johnstown', city: 'Johnstown'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Galvanize', _nestfuelId: 'PRE00465', nestfuelRelLevel: 'default',  address: '1644 Platte St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Garfield County Government', _nestfuelId: 'PRE00466', nestfuelRelLevel: 'default',  address: '8600 Old US Hwy 6', city: 'Parachute'   , state: 'CO', rateDiscount: 1.8},
{ title: 'GeoPoll', _nestfuelId: 'PRE00467', nestfuelRelLevel: 'default',  address: 'NA', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Gerard Peters', _nestfuelId: 'PRE00468', nestfuelRelLevel: 'default',  address: '692 S Bryant St', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'GetSwift', _nestfuelId: 'PRE00469', nestfuelRelLevel: 'default',  address: '7000 East Belleview Ave Denver CO 80111', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Gilpin County', _nestfuelId: 'PRE00470', nestfuelRelLevel: 'default',  address: '960 Dory Hill Rd Ste 100', city: 'Black Hawk'   , state: 'CO', rateDiscount: 1.8},
{ title: 'GitPrime', _nestfuelId: 'PRE00471', nestfuelRelLevel: 'default',  address: 'NA', city: 'Durango'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Gloo', _nestfuelId: 'PRE00472', nestfuelRelLevel: 'default',  address: '831 Pearl St', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Gogo Business Aviation', _nestfuelId: 'PRE00473', nestfuelRelLevel: 'default',  address: '105 Edgeview Dr Suite 300', city: 'Broomfield'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Gorilla Logic', _nestfuelId: 'PRE00474', nestfuelRelLevel: 'default',  address: '8001 Arista Pl Ste. 600', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Green House Data', _nestfuelId: 'PRE00475', nestfuelRelLevel: 'default',  address: '110 16th St. Suite 1240 Denver CO 80202', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'GridX', _nestfuelId: 'PRE00476', nestfuelRelLevel: 'default',  address: '1525 McCarthy Blvd Suite 1106 Milpitas', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Griffith Centers', _nestfuelId: 'PRE00477', nestfuelRelLevel: 'default',  address: '10 Farragut Ave Colorado Springs', city: 'Colorado Springs'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Gtmhub', _nestfuelId: 'PRE00478', nestfuelRelLevel: 'default',  address: '1630 Welton St Suite 500d', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Gunnison County', _nestfuelId: 'PRE00479', nestfuelRelLevel: 'default',  address: '200 E. Virginia Ave. Gunnison', city: 'Gunnison'   , state: 'CO', rateDiscount: 1.8},
{ title: 'GutCheck', _nestfuelId: 'PRE00480', nestfuelRelLevel: 'default',  address: '633 17th St #1300', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Helix', _nestfuelId: 'PRE00481', nestfuelRelLevel: 'default',  address: '1017 S Birch St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Heska Corporation', _nestfuelId: 'PRE00482', nestfuelRelLevel: 'default',  address: ' 3760 Rocky Mountain Ave', city: 'Loveland'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Homebase', _nestfuelId: 'PRE00483', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'HomeSphere', _nestfuelId: 'PRE00484', nestfuelRelLevel: 'default',  address: '14142 Denver W Pkwy #255', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Honey', _nestfuelId: 'PRE00485', nestfuelRelLevel: 'default',  address: '802 Brooklawn Dr', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Honeybee Robotics', _nestfuelId: 'PRE00486', nestfuelRelLevel: 'default',  address: '1830 Lefthand Cir', city: 'Longmont'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Hospital Cooperative Laundry', _nestfuelId: 'PRE00487', nestfuelRelLevel: 'default',  address: '6225 E 38th Ave', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Hotel Engine', _nestfuelId: 'PRE00488', nestfuelRelLevel: 'default',  address: '950 S Cherry St 10th Floor', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'HotSchedules', _nestfuelId: 'PRE00489', nestfuelRelLevel: 'default',  address: '4550 S Windermere St', city: 'Englewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Housecall Pro', _nestfuelId: 'PRE00490', nestfuelRelLevel: 'default',  address: '4180 Wynkoop St Suite 310', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'HR Advantage Group', _nestfuelId: 'PRE00491', nestfuelRelLevel: 'default',  address: '1615 Platte St Fl 2', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'HR Cert Prep Pros', _nestfuelId: 'PRE00492', nestfuelRelLevel: 'default',  address: '25523 N Turkey Creek Road Evergreen CO 80439', city: 'Evergreen'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'HRx Services LLC', _nestfuelId: 'PRE00493', nestfuelRelLevel: 'default',  address: '1919 65th Ave. Suite B Greeley', city: 'Greeley'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Hubble', _nestfuelId: 'PRE00494', nestfuelRelLevel: 'default',  address: '5613 DTC Pkwy #950', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Hudson River Trading', _nestfuelId: 'PRE00495', nestfuelRelLevel: 'default',  address: '1919 14th St', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Humane Society of Boulder Valley', _nestfuelId: 'PRE00496', nestfuelRelLevel: 'default',  address: '2323 55th St', city: 'Boulder'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'i4 Insight, Inc.', _nestfuelId: 'PRE00497', nestfuelRelLevel: 'default',  address: '6041 S. Syracuse Way', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'iBeta Quality Assurance', _nestfuelId: 'PRE00498', nestfuelRelLevel: 'default',  address: '2675 S Abilene St #300', city: 'Aurora'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ICOCLONE', _nestfuelId: 'PRE00499', nestfuelRelLevel: 'default',  address: '3139 Sampson Street', city: 'Aurora'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Identity Digital (Formerly Donuts Inc)', _nestfuelId: 'PRE00500', nestfuelRelLevel: 'default',  address: '10500 NE 8th St', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'IHYIP Templates', _nestfuelId: 'PRE00501', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'iLending', _nestfuelId: 'PRE00502', nestfuelRelLevel: 'default',  address: '7257 S Tucson Way', city: 'Englewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ILG Strategic Services', _nestfuelId: 'PRE00503', nestfuelRelLevel: 'default',  address: '1510 York Street', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'iN2L', _nestfuelId: 'PRE00504', nestfuelRelLevel: 'default',  address: '5889 Greenwood Plaza Blvd Ste 320', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Infinicept, Inc.', _nestfuelId: 'PRE00505', nestfuelRelLevel: 'default',  address: '543 Santa Fe Dr', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Infinity Systems Engineering', _nestfuelId: 'PRE00506', nestfuelRelLevel: 'default',  address: '13560 Northgate Estates Dr', city: 'Colorado Springs'   , state: 'CO', rateDiscount: 1.8},
{ title: 'InfoNow Corp.', _nestfuelId: 'PRE00507', nestfuelRelLevel: 'default',  address: '1875 LAWRENCE STREET', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'insightsoftware', _nestfuelId: 'PRE00508', nestfuelRelLevel: 'default',  address: '8529 Six Forks Rd. Raleigh', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'In-Situ Inc.', _nestfuelId: 'PRE00509', nestfuelRelLevel: 'default',  address: '221 E Lincoln Ave', city: 'Fort Collins'   , state: 'CO', rateDiscount: 1.8},
{ title: 'In-Situ, Inc.', _nestfuelId: 'PRE00510', nestfuelRelLevel: 'default',  address: '221 E Lincoln Ave', city: 'Fort Collins'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Integrate', _nestfuelId: 'PRE00511', nestfuelRelLevel: 'default',  address: '1730 Blake St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Integritek', _nestfuelId: 'PRE00512', nestfuelRelLevel: 'default',  address: '6850 W 52nd Ave #103', city: 'Arvada'   , state: 'CO', rateDiscount: 1.8},
{ title: 'IntelePeer', _nestfuelId: 'PRE00513', nestfuelRelLevel: 'default',  address: '5975 S Quebec St Suite 300', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Intelex Technologies', _nestfuelId: 'PRE00514', nestfuelRelLevel: 'default',  address: '2000 S Colorado Blvd Building 1', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Intelligent Demand', _nestfuelId: 'PRE00515', nestfuelRelLevel: 'default',  address: '50 Decatur St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Interior Business Center', _nestfuelId: 'PRE00516', nestfuelRelLevel: 'default',  address: '7301 W Mansfield Ave', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Investigations Law Group', _nestfuelId: 'PRE00517', nestfuelRelLevel: 'default',  address: '1612 Pennsylvania St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Istonish', _nestfuelId: 'PRE00518', nestfuelRelLevel: 'default',  address: '5500 Greenwood Plaza Blvd Suite 130', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'IZEA Worldwide', _nestfuelId: 'PRE00519', nestfuelRelLevel: 'default',  address: '8181 Arista Place', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Jeffco Public Schools', _nestfuelId: 'PRE00520', nestfuelRelLevel: 'default',  address: '1829 Denver West Drive #27', city: 'Golden'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Journal Technologies Inc', _nestfuelId: 'PRE00521', nestfuelRelLevel: 'default',  address: '949 E 2nd Street', city: 'Englewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Kapost', _nestfuelId: 'PRE00522', nestfuelRelLevel: 'default',  address: '2010 14th St', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Karrikins Group', _nestfuelId: 'PRE00523', nestfuelRelLevel: 'default',  address: '191 University Blvd Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Kemo Sabe', _nestfuelId: 'PRE00524', nestfuelRelLevel: 'default',  address: '217 S Galena St', city: 'Aspen'   , state: 'CO', rateDiscount: 1.8},
{ title: 'KEPHART', _nestfuelId: 'PRE00525', nestfuelRelLevel: 'default',  address: '555 Walnut Street Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'KORE Software', _nestfuelId: 'PRE00526', nestfuelRelLevel: 'default',  address: '259 W 30th St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'KPA', _nestfuelId: 'PRE00527', nestfuelRelLevel: 'default',  address: '1380 Forest Park Cir Suite 140', city: 'Lafayette'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Krayden, Inc.', _nestfuelId: 'PRE00528', nestfuelRelLevel: 'default',  address: 'W 124th Ave', city: 'Westminster'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Larimer County', _nestfuelId: 'PRE00529', nestfuelRelLevel: 'default',  address: '200 W Oak St Ste 5000', city: 'Fort Collins'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'Laser Technology, Inc.', _nestfuelId: 'PRE00530', nestfuelRelLevel: 'default',  address: '6912 S Quentin St Ste A', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Leiters', _nestfuelId: 'PRE00531', nestfuelRelLevel: 'default',  address: '13796 Compark Blvd', city: 'Englewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'LeoLabs, Inc.', _nestfuelId: 'PRE00532', nestfuelRelLevel: 'default',  address: '4005 Bohannon Drive', city: 'Menlo Park'   , state: 'CA', rateDiscount: 1.8},
{ title: 'Letsdothis', _nestfuelId: 'PRE00533', nestfuelRelLevel: 'default',  address: 'NA', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Liqid', _nestfuelId: 'PRE00534', nestfuelRelLevel: 'default',  address: '329 Interlocken Pkwy ste 200', city: 'Broomfield'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Livongo', _nestfuelId: 'PRE00535', nestfuelRelLevel: 'default',  address: '1875 Lawrence Street Suite 550. Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Local Motors', _nestfuelId: 'PRE00536', nestfuelRelLevel: 'default',  address: 'NA', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Logiq, Inc.', _nestfuelId: 'PRE00537', nestfuelRelLevel: 'default',  address: '2727 W 2nd Ave', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'LT Trust', _nestfuelId: 'PRE00538', nestfuelRelLevel: 'default',  address: '5975 S Quebec St Suite 330', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Madwire', _nestfuelId: 'PRE00539', nestfuelRelLevel: 'default',  address: '3405 S Timberline Rd', city: 'Fort Collins'   , state: 'CO', rateDiscount: 1.8},
{ title: 'MakeMusic (A Peaksware Company)', _nestfuelId: 'PRE00540', nestfuelRelLevel: 'default',  address: '285 Century Pl', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'MarillacHealth', _nestfuelId: 'PRE00541', nestfuelRelLevel: 'default',  address: '2333 N 6th St', city: 'Grand Junction'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Marketron Broadcast Solutions', _nestfuelId: 'PRE00542', nestfuelRelLevel: 'default',  address: 'NA', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Marrick', _nestfuelId: 'PRE00543', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'MedKeeper, a Grifols Company', _nestfuelId: 'PRE00544', nestfuelRelLevel: 'default',  address: '165 S Union Blvd # 950', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Melco International LLC', _nestfuelId: 'PRE00545', nestfuelRelLevel: 'default',  address: '1575 W 124th Ave Suite 110', city: 'Westminster'   , state: 'CO', rateDiscount: 1.8},
{ title: 'memoryblue', _nestfuelId: 'PRE00546', nestfuelRelLevel: 'default',  address: '1391 Speer Blvd Suite 705 Denver CO 80204', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Meridian Institute', _nestfuelId: 'PRE00547', nestfuelRelLevel: 'default',  address: 'PO Box 1829', city: 'Dillon'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Meritage Midstream · Full-time', _nestfuelId: 'PRE00548', nestfuelRelLevel: 'default',  address: '1331 17th St Ste 1100', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Mersive', _nestfuelId: 'PRE00549', nestfuelRelLevel: 'default',  address: '2399 Blake St Suite 150', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'MessageMedia', _nestfuelId: 'PRE00550', nestfuelRelLevel: 'default',  address: ' 2420 17th St', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Method', _nestfuelId: 'PRE00551', nestfuelRelLevel: 'default',  address: '1200 17th St Suite 1000', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Metigy', _nestfuelId: 'PRE00552', nestfuelRelLevel: 'default',  address: '1800 Wazee St. Floor 3 Denver CO 80202', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Metric Theory, A Media.Monks Company', _nestfuelId: 'PRE00553', nestfuelRelLevel: 'default',  address: '1517 Blake St #250', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Metro West Housing Solutions', _nestfuelId: 'PRE00554', nestfuelRelLevel: 'default',  address: '575 Union Blvd Ste 100', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'MGMA', _nestfuelId: 'PRE00555', nestfuelRelLevel: 'default',  address: '104 Inverness Terrace E', city: 'Englewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'MINES and Associates', _nestfuelId: 'PRE00556', nestfuelRelLevel: 'default',  address: '10367 W Centennial Rd Ste 100', city: 'Littleton'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Modern Family Law', _nestfuelId: 'PRE00557', nestfuelRelLevel: 'default',  address: '4500 E Cherry Creek S Dr #700', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'MojoTech', _nestfuelId: 'PRE00558', nestfuelRelLevel: 'default',  address: '1711 15th St', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Monarch Mountain', _nestfuelId: 'PRE00559', nestfuelRelLevel: 'default',  address: '123 N F St', city: 'Salida'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Monigle', _nestfuelId: 'PRE00560', nestfuelRelLevel: 'default',  address: '150 Adams St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Montrose County School District', _nestfuelId: 'PRE00561', nestfuelRelLevel: 'default',  address: '930 Colorado Ave Montrose', city: 'Montrose'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Moody Insurance Agency', _nestfuelId: 'PRE00562', nestfuelRelLevel: 'default',  address: '8055 E Tufts Ave Ste 1000', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Moogsoft Inc', _nestfuelId: 'PRE00563', nestfuelRelLevel: 'default',  address: 'East First Floor', city: 'Broomfield'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Motili', _nestfuelId: 'PRE00564', nestfuelRelLevel: 'default',  address: '1900 Wazee St #1533', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Mountain View Fire Rescue', _nestfuelId: 'PRE00565', nestfuelRelLevel: 'default',  address: '3561 N. Stagecoach Rd. Longmont', city: 'Longmont'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Mountain West Insurance & Financial Services, LLC', _nestfuelId: 'PRE00566', nestfuelRelLevel: 'default',  address: '242 W Main St 4', city: 'Montrose'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Mutual of America', _nestfuelId: 'PRE00567', nestfuelRelLevel: 'default',  address: '6400 S Fiddlers Green Cir Suite 500', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'mywedding.com', _nestfuelId: 'PRE00568', nestfuelRelLevel: 'default',  address: '4700 Castleton Way #210', city: 'Castle Rock'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Naranjo Civil', _nestfuelId: 'PRE00569', nestfuelRelLevel: 'default',  address: '627 27th St', city: 'Greeley'   , state: 'CO', rateDiscount: 1.8},
{ title: 'National Western Stock Show', _nestfuelId: 'PRE00570', nestfuelRelLevel: 'default',  address: '4655 Humboldt St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'NC-SARA', _nestfuelId: 'PRE00571', nestfuelRelLevel: 'default',  address: '3005 Center Green Dr', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'New Direction Trust Company', _nestfuelId: 'PRE00572', nestfuelRelLevel: 'default',  address: '1070 W Century Dr', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'NewsGator', _nestfuelId: 'PRE00573', nestfuelRelLevel: 'default',  address: '950 17th Street Suite 2500 Denver CO 80202', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'NexusTek LLC', _nestfuelId: 'PRE00574', nestfuelRelLevel: 'default',  address: '5889 Greenwood Plaza Blvd #201', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Nice Actimize', _nestfuelId: 'PRE00575', nestfuelRelLevel: 'default',  address: '7604 E Technology Way', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'NIMBL', _nestfuelId: 'PRE00576', nestfuelRelLevel: 'default',  address: '1401 Zuni St #102', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'NuMed Direct Primary Care', _nestfuelId: 'PRE00577', nestfuelRelLevel: 'default',  address: '7200 E Dry Creek Rd G-101', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Nylas', _nestfuelId: 'PRE00578', nestfuelRelLevel: 'default',  address: '1550 Wewatta St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Occipital', _nestfuelId: 'PRE00579', nestfuelRelLevel: 'default',  address: '1155 Canyon Blvd Suite 105', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Oceanside Ten', _nestfuelId: 'PRE00580', nestfuelRelLevel: 'default',  address: 'NA', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Ockom', _nestfuelId: 'PRE00581', nestfuelRelLevel: 'default',  address: '501 S Cherry St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ohmygreen', _nestfuelId: 'PRE00582', nestfuelRelLevel: 'default',  address: '1845 Rollins Road', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Ohos, Inc.', _nestfuelId: 'PRE00583', nestfuelRelLevel: 'default',  address: '3000 Lawrence St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'One Earth Future Foundation', _nestfuelId: 'PRE00584', nestfuelRelLevel: 'default',  address: '525 Zang St Ste C', city: 'Broomfield'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Ontra', _nestfuelId: 'PRE00585', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Open Technology Solutions', _nestfuelId: 'PRE00586', nestfuelRelLevel: 'default',  address: '8085 South Chester Street', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'OpsRamp', _nestfuelId: 'PRE00587', nestfuelRelLevel: 'default',  address: 'OpsRamp', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'OrthoFi', _nestfuelId: 'PRE00588', nestfuelRelLevel: 'default',  address: '900 S Broadway Suite 100', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Orthopaedics & Spine Center of the Rockies', _nestfuelId: 'PRE00589', nestfuelRelLevel: 'default',  address: '1610 Dry Creek Dr', city: 'Longmont'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'OTA Insight', _nestfuelId: 'PRE00590', nestfuelRelLevel: 'default',  address: '2930 Umatilla St. Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Outrider', _nestfuelId: 'PRE00591', nestfuelRelLevel: 'default',  address: '16163 W 45th Dr Unit H', city: 'Golden'   , state: 'CO', rateDiscount: 1.8},
{ title: 'OutSail', _nestfuelId: 'PRE00592', nestfuelRelLevel: 'default',  address: '2300 Walnut St 525', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Pana', _nestfuelId: 'PRE00593', nestfuelRelLevel: 'default',  address: '3459 Ringsby Ct', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Parsyl', _nestfuelId: 'PRE00594', nestfuelRelLevel: 'default',  address: '2825 Larimer St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Pax8', _nestfuelId: 'PRE00595', nestfuelRelLevel: 'default',  address: '5500 S Quebec St Ste 350', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'PaySimple', _nestfuelId: 'PRE00596', nestfuelRelLevel: 'default',  address: '3601 Walnut St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Peaksware', _nestfuelId: 'PRE00597', nestfuelRelLevel: 'default',  address: '285 Century Pl', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Percolate', _nestfuelId: 'PRE00598', nestfuelRelLevel: 'default',  address: '2420 17th St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Pex', _nestfuelId: 'PRE00599', nestfuelRelLevel: 'default',  address: '1495 Canyon Blvd. Boulder CO 80302', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Pie Insurance', _nestfuelId: 'PRE00600', nestfuelRelLevel: 'default',  address: '1755 Blake St 5th floor', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Pinnacol Assurance', _nestfuelId: 'PRE00601', nestfuelRelLevel: 'default',  address: '7501 E Lowry Blvd', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'PipelineRx', _nestfuelId: 'PRE00602', nestfuelRelLevel: 'default',  address: '1730 Blake Street', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'PItkin County Government', _nestfuelId: 'PRE00603', nestfuelRelLevel: 'default',  address: '530 East Main Street', city: 'Aspen'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Placewise', _nestfuelId: 'PRE00604', nestfuelRelLevel: 'default',  address: '1390 Lawrence St Suite 300', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Platform.sh', _nestfuelId: 'PRE00605', nestfuelRelLevel: 'default',  address: '2021 Fillmore Street #1252 San Francisco', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Platte River Power Authority', _nestfuelId: 'PRE00606', nestfuelRelLevel: 'default',  address: '2000 E Horsetooth Rd', city: 'Fort Collins'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'PlayerLync', _nestfuelId: 'PRE00607', nestfuelRelLevel: 'default',  address: '5690 DTC Blvd #450e', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Point Solutions Group', _nestfuelId: 'PRE00608', nestfuelRelLevel: 'default',  address: '1675 Larimer St Ste 623', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Powers Resource Center, LLC', _nestfuelId: 'PRE00609', nestfuelRelLevel: 'default',  address: '1750 30th St 352', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'PPSEL - Pikes Peak School of Expeditionary Learning', _nestfuelId: 'PRE00610', nestfuelRelLevel: 'default',  address: '11925 Antlers Ridge Dr', city: 'Falcon'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Procare Solutions', _nestfuelId: 'PRE00611', nestfuelRelLevel: 'default',  address: '1125 17th St.', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Project UV', _nestfuelId: 'PRE00612', nestfuelRelLevel: 'default',  address: '15 Inverness Way E', city: 'Englewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ProtectWise', _nestfuelId: 'PRE00613', nestfuelRelLevel: 'default',  address: '1601 Wewatta St #700', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Prove', _nestfuelId: 'PRE00614', nestfuelRelLevel: 'default',  address: '1550 Market St Suite #250', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Pushpay', _nestfuelId: 'PRE00615', nestfuelRelLevel: 'default',  address: '10807 New Allegiance Dr Ste 300', city: 'Colorado Springs'   , state: 'CO', rateDiscount: 1.8},
{ title: 'QRails, Inc.', _nestfuelId: 'PRE00616', nestfuelRelLevel: 'default',  address: '1855 Blake Street', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'QSC, LLC', _nestfuelId: 'PRE00617', nestfuelRelLevel: 'default',  address: '4888 Pearl E Cir #108', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Quantum Metric, Inc.', _nestfuelId: 'PRE00618', nestfuelRelLevel: 'default',  address: '10807 New Allegiance Dr Ste 155', city: 'Colorado Springs'   , state: 'CO', rateDiscount: 1.8},
{ title: 'QuantumBlack, AI by McKinsey.', _nestfuelId: 'PRE00619', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Quizlet', _nestfuelId: 'PRE00620', nestfuelRelLevel: 'default',  address: '1615 Platte St. Denver CO', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Qwilr', _nestfuelId: 'PRE00621', nestfuelRelLevel: 'default',  address: '7 Hudson St Redfern CO 80014', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'RA Nelson LLC', _nestfuelId: 'PRE00622', nestfuelRelLevel: 'default',  address: '1000 Dolores Way Ste A', city: 'Carbondale'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Rapyd', _nestfuelId: 'PRE00623', nestfuelRelLevel: 'default',  address: '5500 Greenwood Plaza Blvd', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Ready Foods Inc', _nestfuelId: 'PRE00624', nestfuelRelLevel: 'default',  address: '2645 W 7th Ave', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ReadyTalk', _nestfuelId: 'PRE00625', nestfuelRelLevel: 'default',  address: ' 1900 16th St Mall UNIT 600', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Real-Time Innovations, Inc.', _nestfuelId: 'PRE00626', nestfuelRelLevel: 'default',  address: '3900 East Mexico Avenue', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ReCharge', _nestfuelId: 'PRE00627', nestfuelRelLevel: 'default',  address: '1550 Wewatta St 3 3153', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Recondo Technology', _nestfuelId: 'PRE00628', nestfuelRelLevel: 'default',  address: '7900 E Union Ave #400', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Recurly', _nestfuelId: 'PRE00629', nestfuelRelLevel: 'default',  address: '1050 Walnut St', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Red Canary', _nestfuelId: 'PRE00630', nestfuelRelLevel: 'default',  address: '1601 19th St Suite 900', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Red Nova Labs', _nestfuelId: 'PRE00631', nestfuelRelLevel: 'default',  address: 'NA', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Reserve Trust', _nestfuelId: 'PRE00632', nestfuelRelLevel: 'default',  address: '5570 DTC Pkwy', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Resi Media', _nestfuelId: 'PRE00633', nestfuelRelLevel: 'default',  address: '3821 W 88th Ave Westminster', city: 'Westminster'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Restaurant365', _nestfuelId: 'PRE00634', nestfuelRelLevel: 'default',  address: '2420 17th St. Denver CO 80202', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Revelstoke Capital Partners', _nestfuelId: 'PRE00635', nestfuelRelLevel: 'default',  address: '3033 East 1st Avenue Suite 501 Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Ria Digital', _nestfuelId: 'PRE00636', nestfuelRelLevel: 'default',  address: '511 16th Street', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Rimilia', _nestfuelId: 'PRE00637', nestfuelRelLevel: 'default',  address: 'NA', city: 'Aurora'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Robots and Pencils', _nestfuelId: 'PRE00638', nestfuelRelLevel: 'default',  address: '360 Perry St', city: 'Castle Rock'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Rocky Mountain ADA Center', _nestfuelId: 'PRE00639', nestfuelRelLevel: 'default',  address: '3630 Sinton Rd Ste 103', city: 'Colorado Springs'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Rogue Wave Software', _nestfuelId: 'PRE00640', nestfuelRelLevel: 'default',  address: '1315 W Century Dr #150', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'SambaSafety', _nestfuelId: 'PRE00641', nestfuelRelLevel: 'default',  address: '5619 DTC Pkwy #1000', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Sashco', _nestfuelId: 'PRE00642', nestfuelRelLevel: 'default',  address: '10300 E 107th Pl', city: 'Brighton'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Say Media', _nestfuelId: 'PRE00643', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Scaled Agile, Inc.', _nestfuelId: 'PRE00644', nestfuelRelLevel: 'default',  address: '5400 Airport Blvd #300', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Scope Technologies, Inc.', _nestfuelId: 'PRE00645', nestfuelRelLevel: 'default',  address: '2420 Alcott St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'SCRAM Systems', _nestfuelId: 'PRE00646', nestfuelRelLevel: 'default',  address: '1241 W Mineral Ave', city: 'Littleton'   , state: 'CO', rateDiscount: 1.8},
{ title: 'SEAKR Engineering', _nestfuelId: 'PRE00647', nestfuelRelLevel: 'default',  address: '6221 S Racine Cir', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Selling Simplified, Inc.', _nestfuelId: 'PRE00648', nestfuelRelLevel: 'default',  address: '7400 E Orchard Rd Suite 350', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Semantic Arts, Inc', _nestfuelId: 'PRE00649', nestfuelRelLevel: 'default',  address: '11 Old Town Sq Ste 200', city: 'Fort Collins'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'SemiDot Infotech', _nestfuelId: 'PRE00650', nestfuelRelLevel: 'default',  address: '3024 Washington St', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Senior Housing Options', _nestfuelId: 'PRE00651', nestfuelRelLevel: 'default',  address: '1510 17th St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Sensory', _nestfuelId: 'PRE00652', nestfuelRelLevel: 'default',  address: '2060 Broadway', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ServiceCore', _nestfuelId: 'PRE00653', nestfuelRelLevel: 'default',  address: '1667 Cole Blvd suite 350', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Shalom Park', _nestfuelId: 'PRE00654', nestfuelRelLevel: 'default',  address: '14800 E Belleview Dr', city: 'Aurora'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ShapeShift', _nestfuelId: 'PRE00655', nestfuelRelLevel: 'default',  address: 'NA', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ShipCompliant', _nestfuelId: 'PRE00656', nestfuelRelLevel: 'default',  address: '2465 Central Ave STE 110', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Signiant', _nestfuelId: 'PRE00657', nestfuelRelLevel: 'default',  address: '1660 Lincoln St suite 1950', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Signifyd', _nestfuelId: 'PRE00658', nestfuelRelLevel: 'default',  address: '99 Almaden Blvd.', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Signpost', _nestfuelId: 'PRE00659', nestfuelRelLevel: 'default',  address: '2363 Blake St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'SimplePractice', _nestfuelId: 'PRE00660', nestfuelRelLevel: 'default',  address: '2834 Colorado Ave', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'simPRO Software Group', _nestfuelId: 'PRE00661', nestfuelRelLevel: 'default',  address: '329 Interlocken Pkwy #100', city: 'Broomfield'   , state: 'CO', rateDiscount: 1.8},
{ title: 'SketchUp', _nestfuelId: 'PRE00662', nestfuelRelLevel: 'default',  address: '10368 Westmoor Dr', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Skupos', _nestfuelId: 'PRE00663', nestfuelRelLevel: 'default',  address: 'Denver Place', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'SmartBug Media', _nestfuelId: 'PRE00664', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Smarter Sorting', _nestfuelId: 'PRE00665', nestfuelRelLevel: 'default',  address: '1007 Pearl St Ste 210', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'SmartEtailing', _nestfuelId: 'PRE00666', nestfuelRelLevel: 'default',  address: '280 E 1st Ave #1533', city: 'Broomfield'   , state: 'CO', rateDiscount: 1.8},
{ title: 'SnapLogic', _nestfuelId: 'PRE00667', nestfuelRelLevel: 'default',  address: '929 Pearl St #200', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Snell & Wilmer LLP', _nestfuelId: 'PRE00668', nestfuelRelLevel: 'default',  address: '1200 17th St UNIT 1900', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Solid Power, Inc.', _nestfuelId: 'PRE00669', nestfuelRelLevel: 'default',  address: '486 S. Pierce Ave.', city: 'Louisville'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'Solv Health', _nestfuelId: 'PRE00670', nestfuelRelLevel: 'default',  address: 'NA', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Solvere HR Consulting', _nestfuelId: 'PRE00671', nestfuelRelLevel: 'default',  address: '9556 Juniper Way', city: 'Arvada'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Sombra', _nestfuelId: 'PRE00672', nestfuelRelLevel: 'default',  address: '1550 Wewatta St Denver CO 80202', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'SonderMind', _nestfuelId: 'PRE00673', nestfuelRelLevel: 'default',  address: '1738 Wynkoop Street Ste 303 Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Sonnenalp Hotel of Vail', _nestfuelId: 'PRE00674', nestfuelRelLevel: 'default',  address: '20 Vail Rd', city: 'Vail'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Sopheon', _nestfuelId: 'PRE00675', nestfuelRelLevel: 'default',  address: '6870 W 52nd Ave', city: 'Arvada'   , state: 'CO', rateDiscount: 1.8},
{ title: 'SoundHound Inc.', _nestfuelId: 'PRE00676', nestfuelRelLevel: 'default',  address: '1468 Pearl St Suite 200', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'South Adams County Water & Sanitation District', _nestfuelId: 'PRE00677', nestfuelRelLevel: 'default',  address: '6595 E 70th Ave', city: 'Commerce City'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Sovrn', _nestfuelId: 'PRE00678', nestfuelRelLevel: 'default',  address: '5541 Central Ave Suite 100', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Space Foundation', _nestfuelId: 'PRE00679', nestfuelRelLevel: 'default',  address: '4425 Arrowswest Dr', city: 'Colorado Springs'   , state: 'CO', rateDiscount: 1.8},
{ title: 'SparkFun Electronics', _nestfuelId: 'PRE00680', nestfuelRelLevel: 'default',  address: '6333 Dry Creek Pkwy', city: 'Niwot'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Spectralink', _nestfuelId: 'PRE00681', nestfuelRelLevel: 'default',  address: '2560 55th St BoulderCO 80301', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Sphero', _nestfuelId: 'PRE00682', nestfuelRelLevel: 'default',  address: '4775 Walnut St', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Spire Digital', _nestfuelId: 'PRE00683', nestfuelRelLevel: 'default',  address: '3501 Wazee St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Spotzer', _nestfuelId: 'PRE00684', nestfuelRelLevel: 'default',  address: 'NA', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Spotzer Media', _nestfuelId: 'PRE00685', nestfuelRelLevel: 'default',  address: 'NA', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'SRS Acquiom', _nestfuelId: 'PRE00686', nestfuelRelLevel: 'default',  address: '950 17th St Suite 1400', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'St Julien Hotel & Spa', _nestfuelId: 'PRE00687', nestfuelRelLevel: 'default',  address: '900 Walnut St', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Stack Overflow', _nestfuelId: 'PRE00688', nestfuelRelLevel: 'default',  address: 'NA', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'StarRez', _nestfuelId: 'PRE00689', nestfuelRelLevel: 'default',  address: '6100 Greenwood Plaza Blvd', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Statera', _nestfuelId: 'PRE00690', nestfuelRelLevel: 'default',  address: '5619 DTC Pkwy #900', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Steamboat Springs School District', _nestfuelId: 'PRE00691', nestfuelRelLevel: 'default',  address: '325 7th St', city: 'Steamboat Springs'   , state: 'CO', rateDiscount: 1.8},
{ title: 'StoneAge Waterblast Tools', _nestfuelId: 'PRE00692', nestfuelRelLevel: 'default',  address: '466 S Skylane Dr', city: 'Durango'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Storable', _nestfuelId: 'PRE00693', nestfuelRelLevel: 'default',  address: '110 16th St. Unit 1300 Modworks Coworking Denver CO 80202', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Strava', _nestfuelId: 'PRE00694', nestfuelRelLevel: 'default',  address: '1444 Wazee St #205', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Stream', _nestfuelId: 'PRE00695', nestfuelRelLevel: 'default',  address: '1215 Spruce St Suite 300', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Streffco Consultants, Inc.', _nestfuelId: 'PRE00696', nestfuelRelLevel: 'default',  address: '8480 E Orchard Rd', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Stride Consulting', _nestfuelId: 'PRE00697', nestfuelRelLevel: 'default',  address: '127 West 26th Street ', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Strive Health', _nestfuelId: 'PRE00698', nestfuelRelLevel: 'default',  address: '1600 Stout St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Suited Connector', _nestfuelId: 'PRE00699', nestfuelRelLevel: 'default',  address: '8123 Interport Blvd', city: 'Englewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Sunday', _nestfuelId: 'PRE00700', nestfuelRelLevel: 'default',  address: '1155 Canyon Blvd.', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Swiftpage', _nestfuelId: 'PRE00701', nestfuelRelLevel: 'default',  address: '633 17th St #1410', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Swimlane', _nestfuelId: 'PRE00702', nestfuelRelLevel: 'default',  address: '363 Centennial Pkwy Suite 210', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Switch Automation', _nestfuelId: 'PRE00703', nestfuelRelLevel: 'default',  address: '1536 Wynkoop St UNIT 220', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'System76, Inc', _nestfuelId: 'PRE00704', nestfuelRelLevel: 'default',  address: '4240 Carson St #101', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'TalentReef', _nestfuelId: 'PRE00705', nestfuelRelLevel: 'default',  address: '950 17th St Ste 700', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Talisys', _nestfuelId: 'PRE00706', nestfuelRelLevel: 'default',  address: '25178 Genesee Trail Rd', city: 'Golden'   , state: 'O', rateDiscount: 1.8},
{ title: 'TapInfluence', _nestfuelId: 'PRE00707', nestfuelRelLevel: 'default',  address: '1317 Edgewater Dr. # 1880', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Targeted Job Fairs', _nestfuelId: 'PRE00708', nestfuelRelLevel: 'default',  address: '6465 Greenwood Plaza Blvd. Centennial CO 80015', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'TeamSupport', _nestfuelId: 'PRE00709', nestfuelRelLevel: 'default',  address: '5005 Lyndon B Johnson Fwy Suite 850 Dallas', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Techtonic', _nestfuelId: 'PRE00710', nestfuelRelLevel: 'default',  address: '1624 Market St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Tejas Software Inc', _nestfuelId: 'PRE00711', nestfuelRelLevel: 'default',  address: '7600 E Arapahoe Rd Ste 303', city: 'Centennial'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Teladoc Health', _nestfuelId: 'PRE00712', nestfuelRelLevel: 'default',  address: '2 Manhattanville Rd. Purchase', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'The Romero Group', _nestfuelId: 'PRE00713', nestfuelRelLevel: 'default',  address: '350 Market St Ste 304', city: 'Basalt'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Think Company', _nestfuelId: 'PRE00714', nestfuelRelLevel: 'default',  address: '1001 Bannock Street Shift Bannock Suite', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ThriveHive', _nestfuelId: 'PRE00715', nestfuelRelLevel: 'default',  address: '1550 Wynkoop St floor 2', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Tiempo Development', _nestfuelId: 'PRE00716', nestfuelRelLevel: 'default',  address: '1050 W Washington St #115', city: 'Tempe'   , state: 'CO', rateDiscount: 1.8},
{ title: 'TIFIN', _nestfuelId: 'PRE00717', nestfuelRelLevel: 'default',  address: '2440 Junction Place', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Tilled', _nestfuelId: 'PRE00718', nestfuelRelLevel: 'default',  address: '370 Interlocken Blvd Suite 300', city: 'Broomfield'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Timbers Bachelor Gulch', _nestfuelId: 'PRE00719', nestfuelRelLevel: 'default',  address: '100 Bachelor Rdg 3505', city: 'Avon'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Togetherwork', _nestfuelId: 'PRE00720', nestfuelRelLevel: 'default',  address: '1600 Range St Suite 102 Boulder CO 80301', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ToolWatch', _nestfuelId: 'PRE00721', nestfuelRelLevel: 'default',  address: '400 Inverness Pkwy #450', city: 'Englewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Top Hat', _nestfuelId: 'PRE00722', nestfuelRelLevel: 'default',  address: '900 S. Broadway Greater Denver Area CO 80204', city: 'Greater Denver Area'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Tortuga AgTech', _nestfuelId: 'PRE00723', nestfuelRelLevel: 'default',  address: '5075 Kalamath St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'TouchSource', _nestfuelId: 'PRE00724', nestfuelRelLevel: 'default',  address: '1370 Miners Dr #103', city: 'Lafayette'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Town of Buena Vista', _nestfuelId: 'PRE00725', nestfuelRelLevel: 'default',  address: '2002. Buena Vista CO 81211', city: 'Buena Vista'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Town of Erie', _nestfuelId: 'PRE00726', nestfuelRelLevel: 'default',  address: 'Town of Erie', city: 'Erie'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Town of Frisco', _nestfuelId: 'PRE00727', nestfuelRelLevel: 'default',  address: '300 Main St', city: 'Frisco'   , state: 'CO', rateDiscount: 1.8},
{ title: 'TrackVia', _nestfuelId: 'PRE00728', nestfuelRelLevel: 'default',  address: '950 17th St UNIT 2000', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'TrainingPeaks', _nestfuelId: 'PRE00729', nestfuelRelLevel: 'default',  address: '85 Century Pl', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Tri-State Generation and Transmission', _nestfuelId: 'PRE00730', nestfuelRelLevel: 'default',  address: '1100 West 116th Avenue Westminster', city: 'Westminster'   , state: 'CO', rateDiscount: 1.8},
{ title: 'TruStile', _nestfuelId: 'PRE00731', nestfuelRelLevel: 'default',  address: '1780 E 66th Ave', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'TurnKey Solutions Corp.', _nestfuelId: 'PRE00732', nestfuelRelLevel: 'default',  address: '143 Union Blvd Suite 1020 Lakewood', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Twillio', _nestfuelId: 'PRE00733', nestfuelRelLevel: 'default',  address: '1801 California St fl 5', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'UE.co', _nestfuelId: 'PRE00734', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Unsupervised', _nestfuelId: 'PRE00735', nestfuelRelLevel: 'default',  address: '4740 Pearl Pkwy Suite 300', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Uplight', _nestfuelId: 'PRE00736', nestfuelRelLevel: 'default',  address: '2530 Junction Place', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Upserve', _nestfuelId: 'PRE00737', nestfuelRelLevel: 'default',  address: '999 18th St N2101', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'US Venture', _nestfuelId: 'PRE00738', nestfuelRelLevel: 'default',  address: '19401 E 32ND Pkwy', city: 'Aurora'   , state: 'CO', rateDiscount: 1.8},
{ title: 'UserZoom', _nestfuelId: 'PRE00739', nestfuelRelLevel: 'default',  address: '1801 Broadway #720', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Vail Health', _nestfuelId: 'PRE00740', nestfuelRelLevel: 'default',  address: '180 S Frontage Rd W', city: 'Vail'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Validity Inc.', _nestfuelId: 'PRE00741', nestfuelRelLevel: 'default',  address: '11101 W 120th Ave STE 120', city: 'Broomfield'   , state: 'CO', rateDiscount: 1.8},
{ title: 'ValueCoders', _nestfuelId: 'PRE00742', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Vangst', _nestfuelId: 'PRE00743', nestfuelRelLevel: 'default',  address: '2601 Blake St ste 450', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Venminder', _nestfuelId: 'PRE00744', nestfuelRelLevel: 'default',  address: '7935 E Prentice Ave #110W', city: 'Greenwood Village'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Vera', _nestfuelId: 'PRE00745', nestfuelRelLevel: 'default',  address: '1200 17th St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'VerityStream', _nestfuelId: 'PRE00746', nestfuelRelLevel: 'default',  address: '361 Centennial Pkwy', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Verizon Location Technology', _nestfuelId: 'PRE00747', nestfuelRelLevel: 'default',  address: '1555 Blake St.', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Verkada Inc', _nestfuelId: 'PRE00748', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Versent Pty Ltd', _nestfuelId: 'PRE00749', nestfuelRelLevel: 'default',  address: '1700 Lincoln St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Vertiba, LLC', _nestfuelId: 'PRE00750', nestfuelRelLevel: 'default',  address: '1845 Folsom St Boulder CO 80302', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'VIATechnik LLC', _nestfuelId: 'PRE00751', nestfuelRelLevel: 'default',  address: '1800 Wazee St Suite 300', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'VidMob', _nestfuelId: 'PRE00752', nestfuelRelLevel: 'default',  address: 'NA', city: 'Colorado'   , state: 'CO', rateDiscount: 1.8},
{ title: 'VieCure', _nestfuelId: 'PRE00753', nestfuelRelLevel: 'default',  address: '5680 Greenwood Plaza Blvd Suite 350', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'VIEWRAY', _nestfuelId: 'PRE00754', nestfuelRelLevel: 'default',  address: '1099 18th St Suite 3000', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Virta Health', _nestfuelId: 'PRE00755', nestfuelRelLevel: 'default',  address: '633 17th Street', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Vivian Health', _nestfuelId: 'PRE00756', nestfuelRelLevel: 'default',  address: 'NA', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'VORTO', _nestfuelId: 'PRE00757', nestfuelRelLevel: 'default',  address: '1515 Wazee St #300', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Vorto, LLC', _nestfuelId: 'PRE00758', nestfuelRelLevel: 'default',  address: '1515 Wazee St Ste 300 Denver', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'VSP Vision Care', _nestfuelId: 'PRE00759', nestfuelRelLevel: 'default',  address: '1050 17th St #1430', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Welltok, Inc.', _nestfuelId: 'PRE00760', nestfuelRelLevel: 'default',  address: '3001 Brighton Blvd Suite 743', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Western Colorado Human Resources Association (WCHRA)', _nestfuelId: 'PRE00761', nestfuelRelLevel: 'default',  address: '2793 Skyline Ct Ste D', city: 'Grand Junction'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Western Industrial Contractors', _nestfuelId: 'PRE00762', nestfuelRelLevel: 'default',  address: '14805 E Moncrieff Pl', city: 'Aurora'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Westin Riverfront Resort & Spa', _nestfuelId: 'PRE00763', nestfuelRelLevel: 'default',  address: '126 Riverfront Lane', city: 'Avon'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'Westminster Public Schools', _nestfuelId: 'PRE00764', nestfuelRelLevel: 'default',  address: '6933 Raleigh St. Westminster', city: 'Westminster'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Whispir', _nestfuelId: 'PRE00765', nestfuelRelLevel: 'default',  address: '1007 Pearl St Suite 200', city: 'Boulder'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Wickham Tractor Co.', _nestfuelId: 'PRE00766', nestfuelRelLevel: 'default',  address: '15447 Us Highway 34. Fort Morgan', city: 'Fort Morgan'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'Wiland, Inc.', _nestfuelId: 'PRE00767', nestfuelRelLevel: 'default',  address: '7420 E Dry Creek Pkwy', city: 'Niwot'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Woodridge Software', _nestfuelId: 'PRE00768', nestfuelRelLevel: 'default',  address: '320 S Teller St', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'WorldRemit', _nestfuelId: 'PRE00769', nestfuelRelLevel: 'default',  address: '600 17th St suite 200s', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Wowza', _nestfuelId: 'PRE00770', nestfuelRelLevel: 'default',  address: '7171 W Alaska Dr', city: 'Lakewood'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Wunderman Data Products', _nestfuelId: 'PRE00771', nestfuelRelLevel: 'default',  address: ' 2051 Dogwood St', city: 'Louisville'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Wurk', _nestfuelId: 'PRE00772', nestfuelRelLevel: 'default',  address: '2162 Market St', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Xyleme', _nestfuelId: 'PRE00773', nestfuelRelLevel: 'default',  address: '1660 17th St Suite 200/201', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Yampa Valley Electric Association', _nestfuelId: 'PRE00774', nestfuelRelLevel: 'default',  address: '2211 Elk River Road. Steamboat Springs', city: 'Steamboat Springs'   , state: 'CO ', rateDiscount: 1.8},
{ title: 'Zivaro, Inc.', _nestfuelId: 'PRE00775', nestfuelRelLevel: 'default',  address: '3900 E Mexico Ave Ste. 1000', city: 'Denver'   , state: 'CO', rateDiscount: 1.8},
{ title: 'Zynex Medical', _nestfuelId: 'PRE00776', nestfuelRelLevel: 'default',  address: '9655 Maroon Circle', city: 'Englewood'   , state: 'CO', rateDiscount: 1.8}
];


export const employmentStatus = {
	accommodations: 'Employed',
	administration: 'Employed',
	agriculture: 'SelfEmployed',
	arts: 'SelfEmployed',
	construction: 'Employed',
	education: 'Employed',
	finance: 'Employed',
	government: 'Employed',
	healthcare: 'Employed',
	management: 'BusinessOwner',
	manufacturing: 'BusinessOwner',
	mining: 'Employed',
	other: 'Others',
	professional: 'Employed',
	realestate: 'SelfEmployed',
	retail: 'BusinessOwner',
	technology: 'Employed',
	transportation: 'BusinessOwner',
	utilities: 'BusinessOwner',
	wholesale: 'BusinessOwner'
};

export const intR = 5.0 // default in percent per year
export const terM = 30 // default in years
export const intRQM = 3.5
export const intRNQM = 4.75
export const intAPR = 5.12
export const percentClosingcost = 3.5;
export const maxUnsecEMIpercentDTI = 20;

export const swapRates = [
	{ term: 40, swapRate: 1.74 },
	{ term: 39, swapRate: 1.74 },
	{ term: 38, swapRate: 1.74 },
	{ term: 37, swapRate: 1.74 },
	{ term: 36, swapRate: 1.74 },
	{ term: 35, swapRate: 1.74 },
	{ term: 34, swapRate: 1.74 },
	{ term: 33, swapRate: 1.74 },
	{ term: 32, swapRate: 1.74 },
	{ term: 31, swapRate: 1.74 },
	{ term: 30, swapRate: 1.74 },
	{ term: 20, swapRate: 1.25 },
	{ term: 15, swapRate: 0.95 },
];

export const mortgageRatesMasterQMPrimary = [
	{ ltv: 50, min: 620, spread: 1.750 },
	{ ltv: 50, min: 640, spread: 1.750 },
	{ ltv: 50, min: 660, spread: 1.510 },
	{ ltv: 50, min: 680, spread: 1.510 },
	{ ltv: 50, min: 700, spread: 1.510 },
	{ ltv: 50, min: 720, spread: 1.510 },
	{ ltv: 50, min: 740, spread: 1.510 },

	{ ltv: 60, min: 620, spread: 1.750 },
	{ ltv: 60, min: 640, spread: 1.750 },
	{ ltv: 60, min: 660, spread: 1.510 },
	{ ltv: 60, min: 680, spread: 1.510 },
	{ ltv: 60, min: 700, spread: 1.510 },
	{ ltv: 60, min: 720, spread: 1.510 },
	{ ltv: 60, min: 740, spread: 1.510 },

	{ ltv: 70, min: 620, spread: 1.875 },
	{ ltv: 70, min: 640, spread: 1.875 },
	{ ltv: 70, min: 660, spread: 1.750 },
	{ ltv: 70, min: 680, spread: 1.625 },
	{ ltv: 70, min: 700, spread: 1.510 },
	{ ltv: 70, min: 720, spread: 1.510 },
	{ ltv: 70, min: 740, spread: 1.510 },

	{ ltv: 80, min: 620, spread: 2.250 },
	{ ltv: 80, min: 640, spread: 2.250 },
	{ ltv: 80, min: 660, spread: 2.010 },
	{ ltv: 80, min: 680, spread: 1.700 },
	{ ltv: 80, min: 700, spread: 1.510 },
	{ ltv: 80, min: 720, spread: 1.510 },
	{ ltv: 80, min: 740, spread: 1.510 },

	{ ltv: 85, min: 620, spread: 2.250 },
	{ ltv: 85, min: 640, spread: 2.250 },
	{ ltv: 85, min: 660, spread: 2.010 },
	{ ltv: 85, min: 680, spread: 1.700 },
	{ ltv: 85, min: 700, spread: 1.625 },
	{ ltv: 85, min: 720, spread: 1.625 },
	{ ltv: 85, min: 740, spread: 1.510 },

	{ ltv: 90, min: 620, spread: 2.250 },
	{ ltv: 90, min: 640, spread: 2.250 },
	{ ltv: 90, min: 660, spread: 2.010 },
	{ ltv: 90, min: 680, spread: 1.700 },
	{ ltv: 90, min: 700, spread: 1.625 },
	{ ltv: 90, min: 720, spread: 1.625 },
	{ ltv: 90, min: 740, spread: 1.510 },

	{ ltv: 95, min: 620, spread: 2.250 },
	{ ltv: 95, min: 660, spread: 2.010 },
	{ ltv: 95, min: 680, spread: 1.750 },
	{ ltv: 95, min: 700, spread: 1.625 },
	{ ltv: 95, min: 720, spread: 1.625 },
	{ ltv: 95, min: 740, spread: 1.510 }
];

export const mortgageRatesMasterQMSecondary = [
	{ ltv: 50, min: 620, spread: 1.885 },
	{ ltv: 50, min: 640, spread: 1.885 },
	{ ltv: 50, min: 660, spread: 1.510 },
	{ ltv: 50, min: 680, spread: 1.510 },
	{ ltv: 50, min: 700, spread: 1.510 },
	{ ltv: 50, min: 720, spread: 1.510 },
	{ ltv: 50, min: 740, spread: 1.510 },

	{ ltv: 60, min: 620, spread: 1.885 },
	{ ltv: 60, min: 640, spread: 1.885 },
	{ ltv: 60, min: 660, spread: 1.510 },
	{ ltv: 60, min: 680, spread: 1.510 },
	{ ltv: 60, min: 700, spread: 1.510 },
	{ ltv: 60, min: 720, spread: 1.510 },
	{ ltv: 60, min: 740, spread: 1.510 },

	{ ltv: 70, min: 620, spread: 2.135 },
	{ ltv: 70, min: 640, spread: 2.135 },
	{ ltv: 70, min: 660, spread: 1.760 },
	{ ltv: 70, min: 680, spread: 1.635 },
	{ ltv: 70, min: 700, spread: 1.635 },
	{ ltv: 70, min: 720, spread: 1.510 },
	{ ltv: 70, min: 740, spread: 1.510 },

	{ ltv: 80, min: 620, spread: 2.500 },
	{ ltv: 80, min: 640, spread: 2.500 },
	{ ltv: 80, min: 660, spread: 2.010 },
	{ ltv: 80, min: 680, spread: 1.760 },
	{ ltv: 80, min: 700, spread: 1.635 },
	{ ltv: 80, min: 720, spread: 1.625 },
	{ ltv: 80, min: 740, spread: 1.625 },

	{ ltv: 90, min: 620, spread: 2.500 },
	{ ltv: 90, min: 640, spread: 2.500 },
	{ ltv: 90, min: 660, spread: 2.010 },
	{ ltv: 90, min: 680, spread: 1.760 },
	{ ltv: 90, min: 700, spread: 1.635 },
	{ ltv: 90, min: 720, spread: 1.625 },
	{ ltv: 90, min: 740, spread: 1.625 },
];

export const mortgageRatesMasterQMInvestment = [
	{ ltv: 50, min: 620, spread: 2.385 },
	{ ltv: 50, min: 640, spread: 2.385 },
	{ ltv: 50, min: 660, spread: 2.075 },
	{ ltv: 50, min: 680, spread: 2.075 },
	{ ltv: 50, min: 700, spread: 2.075 },
	{ ltv: 50, min: 720, spread: 2.010 },
	{ ltv: 50, min: 740, spread: 2.010 },

	{ ltv: 60, min: 620, spread: 2.385 },
	{ ltv: 60, min: 640, spread: 2.385 },
	{ ltv: 60, min: 660, spread: 2.075 },
	{ ltv: 60, min: 680, spread: 2.075 },
	{ ltv: 60, min: 700, spread: 2.075 },
	{ ltv: 60, min: 720, spread: 2.010 },
	{ ltv: 60, min: 740, spread: 2.010 },

	{ ltv: 70, min: 620, spread: 2.500 },
	{ ltv: 70, min: 640, spread: 2.500 },
	{ ltv: 70, min: 660, spread: 2.260 },
	{ ltv: 70, min: 680, spread: 2.135 },
	{ ltv: 70, min: 700, spread: 2.135 },
	{ ltv: 70, min: 720, spread: 2.075 },
	{ ltv: 70, min: 740, spread: 2.075 },

	{ ltv: 80, min: 660, spread: 2.885 },
	{ ltv: 80, min: 680, spread: 2.885 },
	{ ltv: 80, min: 700, spread: 2.635 },
	{ ltv: 80, min: 720, spread: 2.385 },
	{ ltv: 80, min: 740, spread: 2.385 },

	{ ltv: 85, min: 720, spread: 2.635 },
	{ ltv: 85, min: 740, spread: 2.510 },
];

export const mortgageRatesMasterNonQMPrimary = [
	{ ltv: 50, min: 600, spread: 4.425 },
	{ ltv: 50, min: 620, spread: 3.550 },
	{ ltv: 50, min: 640, spread: 3.300 },
	{ ltv: 50, min: 660, spread: 2.425 },
	{ ltv: 50, min: 680, spread: 2.175 },
	{ ltv: 50, min: 700, spread: 2.175 },
	{ ltv: 50, min: 720, spread: 2.049 },
	{ ltv: 50, min: 740, spread: 1.800 },
	{ ltv: 50, min: 760, spread: 1.800 },
	{ ltv: 50, min: 780, spread: 1.800 },
	{ ltv: 50, min: 800, spread: 1.800 },

	{ ltv: 60, min: 600, spread: 4.675 },
	{ ltv: 60, min: 620, spread: 3.925 },
	{ ltv: 60, min: 640, spread: 3.425 },
	{ ltv: 60, min: 660, spread: 2.550 },
	{ ltv: 60, min: 680, spread: 2.425 },
	{ ltv: 60, min: 700, spread: 2.175 },
	{ ltv: 60, min: 720, spread: 2.049 },
	{ ltv: 60, min: 740, spread: 1.800 },
	{ ltv: 60, min: 760, spread: 1.800 },
	{ ltv: 60, min: 780, spread: 1.800 },
	{ ltv: 60, min: 800, spread: 1.800 },

	{ ltv: 70, min: 600, spread: 5.300 },
	{ ltv: 70, min: 620, spread: 4.550 },
	{ ltv: 70, min: 640, spread: 3.925 },
	{ ltv: 70, min: 660, spread: 2.800 },
	{ ltv: 70, min: 680, spread: 2.725 },
	{ ltv: 70, min: 700, spread: 2.300 },
	{ ltv: 70, min: 720, spread: 2.175 },
	{ ltv: 70, min: 740, spread: 1.925 },
	{ ltv: 70, min: 760, spread: 1.925 },
	{ ltv: 70, min: 780, spread: 1.925 },
	{ ltv: 70, min: 800, spread: 1.925 },

	{ ltv: 80, min: 620, spread: 5.800 },
	{ ltv: 80, min: 640, spread: 4.675 },
	{ ltv: 80, min: 660, spread: 3.425 },
	{ ltv: 80, min: 680, spread: 2.925 },
	{ ltv: 80, min: 700, spread: 2.550 },
	{ ltv: 80, min: 720, spread: 2.300 },
	{ ltv: 80, min: 740, spread: 2.175 },
	{ ltv: 80, min: 760, spread: 2.049 },
	{ ltv: 80, min: 780, spread: 2.049 },
	{ ltv: 80, min: 800, spread: 2.049 },

	{ ltv: 85, min: 660, spread: 4.049 },
	{ ltv: 85, min: 680, spread: 3.425 },
	{ ltv: 85, min: 700, spread: 2.925 },
	{ ltv: 85, min: 720, spread: 2.550 },
	{ ltv: 85, min: 740, spread: 2.425 },
	{ ltv: 85, min: 760, spread: 2.300 },
	{ ltv: 85, min: 780, spread: 2.300 },
	{ ltv: 85, min: 800, spread: 2.300 },

	{ ltv: 90, min: 700, spread: 4.175 },
	{ ltv: 90, min: 720, spread: 3.800 },
	{ ltv: 90, min: 740, spread: 3.300 },
	{ ltv: 90, min: 760, spread: 3.175 },
	{ ltv: 90, min: 780, spread: 3.175 },
	{ ltv: 90, min: 800, spread: 3.175 },

	{ ltv: 95, min: 720, spread: 4.800 },
	{ ltv: 95, min: 740, spread: 4.049 },
	{ ltv: 95, min: 760, spread: 3.800 },
	{ ltv: 95, min: 780, spread: 3.800 },
	{ ltv: 95, min: 800, spread: 3.800 }
];

export const mortgageRatesMasterNonQMSecondary = [
	{ ltv: 50, min: 640, spread: 3.550 },
	{ ltv: 50, min: 660, spread: 2.675 },
	{ ltv: 50, min: 680, spread: 2.425 },
	{ ltv: 50, min: 700, spread: 2.425 },
	{ ltv: 50, min: 720, spread: 2.300 },
	{ ltv: 50, min: 740, spread: 2.049 },
	{ ltv: 50, min: 760, spread: 2.049 },
	{ ltv: 50, min: 780, spread: 2.049 },
	{ ltv: 50, min: 800, spread: 2.049 },

	{ ltv: 60, min: 640, spread: 3.675 },
	{ ltv: 60, min: 660, spread: 2.800 },
	{ ltv: 60, min: 680, spread: 2.675 },
	{ ltv: 60, min: 700, spread: 2.425 },
	{ ltv: 60, min: 720, spread: 2.300 },
	{ ltv: 60, min: 740, spread: 2.049 },
	{ ltv: 60, min: 760, spread: 2.049 },
	{ ltv: 60, min: 780, spread: 2.049 },
	{ ltv: 60, min: 800, spread: 2.049 },

	{ ltv: 70, min: 640, spread: 4.175 },
	{ ltv: 70, min: 660, spread: 3.049 },
	{ ltv: 70, min: 680, spread: 2.925 },
	{ ltv: 70, min: 700, spread: 2.550 },
	{ ltv: 70, min: 720, spread: 2.425 },
	{ ltv: 70, min: 740, spread: 2.175 },
	{ ltv: 70, min: 760, spread: 2.175 },
	{ ltv: 70, min: 780, spread: 2.175 },
	{ ltv: 70, min: 800, spread: 2.175 },

	{ ltv: 80, min: 640, spread: 4.925 },
	{ ltv: 80, min: 660, spread: 3.675 },
	{ ltv: 80, min: 680, spread: 3.175 },
	{ ltv: 80, min: 700, spread: 2.800 },
	{ ltv: 80, min: 720, spread: 2.550 },
	{ ltv: 80, min: 740, spread: 2.425 },
	{ ltv: 80, min: 760, spread: 2.300 },
	{ ltv: 80, min: 780, spread: 2.300 },
	{ ltv: 80, min: 800, spread: 2.300 },

	{ ltv: 85, min: 660, spread: 4.300 },
	{ ltv: 85, min: 680, spread: 3.675 },
	{ ltv: 85, min: 700, spread: 3.175 },
	{ ltv: 85, min: 720, spread: 2.800 },
	{ ltv: 85, min: 740, spread: 2.675 },
	{ ltv: 85, min: 760, spread: 2.550 },
	{ ltv: 85, min: 780, spread: 2.550 },
	{ ltv: 85, min: 800, spread: 2.550 }
];

export const mortgageRatesMasterNonQMInvestment = [
	{ ltv: 50, min: 640, spread: 3.550 },
	{ ltv: 50, min: 660, spread: 2.675 },
	{ ltv: 50, min: 680, spread: 2.425 },
	{ ltv: 50, min: 700, spread: 2.425 },
	{ ltv: 50, min: 720, spread: 2.300 },
	{ ltv: 50, min: 740, spread: 2.049 },
	{ ltv: 50, min: 760, spread: 2.049 },
	{ ltv: 50, min: 780, spread: 2.049 },
	{ ltv: 50, min: 800, spread: 2.049 },

	{ ltv: 60, min: 640, spread: 3.675 },
	{ ltv: 60, min: 660, spread: 2.800 },
	{ ltv: 60, min: 680, spread: 2.675 },
	{ ltv: 60, min: 700, spread: 2.425 },
	{ ltv: 60, min: 720, spread: 2.300 },
	{ ltv: 60, min: 740, spread: 2.049 },
	{ ltv: 60, min: 760, spread: 2.049 },
	{ ltv: 60, min: 780, spread: 2.049 },
	{ ltv: 60, min: 800, spread: 2.049 },

	{ ltv: 70, min: 640, spread: 4.175 },
	{ ltv: 70, min: 660, spread: 3.049 },
	{ ltv: 70, min: 680, spread: 2.925 },
	{ ltv: 70, min: 700, spread: 2.550 },
	{ ltv: 70, min: 720, spread: 2.425 },
	{ ltv: 70, min: 740, spread: 2.175 },
	{ ltv: 70, min: 760, spread: 2.175 },
	{ ltv: 70, min: 780, spread: 2.175 },
	{ ltv: 70, min: 800, spread: 2.175 },

	{ ltv: 80, min: 640, spread: 4.925 },
	{ ltv: 80, min: 660, spread: 3.675 },
	{ ltv: 80, min: 680, spread: 3.175 },
	{ ltv: 80, min: 700, spread: 2.800 },
	{ ltv: 80, min: 720, spread: 2.550 },
	{ ltv: 80, min: 740, spread: 2.425 },
	{ ltv: 80, min: 760, spread: 2.300 },
	{ ltv: 80, min: 780, spread: 2.300 },
	{ ltv: 80, min: 800, spread: 2.300 },

	{ ltv: 85, min: 660, spread: 4.300 },
	{ ltv: 85, min: 680, spread: 3.675 },
	{ ltv: 85, min: 700, spread: 3.175 },
	{ ltv: 85, min: 720, spread: 2.800 },
	{ ltv: 85, min: 740, spread: 2.675 },
	{ ltv: 85, min: 760, spread: 2.550 },
	{ ltv: 85, min: 780, spread: 2.550 },
	{ ltv: 85, min: 800, spread: 2.550 },
];
export const mortgageRatesMasterNonQMHighPrimary = [
	{ ltv: 50, min: 600, spread: 4.425 },
	{ ltv: 50, min: 620, spread: 3.550 },
	{ ltv: 50, min: 640, spread: 3.300 },
	{ ltv: 50, min: 660, spread: 2.925 },
	{ ltv: 50, min: 680, spread: 2.675 },
	{ ltv: 50, min: 700, spread: 2.675 },
	{ ltv: 50, min: 720, spread: 2.425 },
	{ ltv: 50, min: 740, spread: 2.425 },
	{ ltv: 50, min: 760, spread: 2.425 },
	{ ltv: 50, min: 780, spread: 2.425 },
	{ ltv: 50, min: 800, spread: 2.425 },

	{ ltv: 60, min: 600, spread: 4.675 },
	{ ltv: 60, min: 620, spread: 3.925 },
	{ ltv: 60, min: 640, spread: 3.425 },
	{ ltv: 60, min: 660, spread: 3.175 },
	{ ltv: 60, min: 680, spread: 2.925 },
	{ ltv: 60, min: 700, spread: 2.675 },
	{ ltv: 60, min: 720, spread: 2.425 },
	{ ltv: 60, min: 740, spread: 2.425 },
	{ ltv: 60, min: 760, spread: 2.425 },
	{ ltv: 60, min: 780, spread: 2.425 },
	{ ltv: 60, min: 800, spread: 2.425 },

	{ ltv: 70, min: 600, spread: 5.300 },
	{ ltv: 70, min: 620, spread: 4.550 },
	{ ltv: 70, min: 640, spread: 3.925 },
	{ ltv: 70, min: 660, spread: 3.550 },
	{ ltv: 70, min: 680, spread: 3.175 },
	{ ltv: 70, min: 700, spread: 2.925 },
	{ ltv: 70, min: 720, spread: 2.675 },
	{ ltv: 70, min: 740, spread: 2.675 },
	{ ltv: 70, min: 760, spread: 2.675 },
	{ ltv: 70, min: 780, spread: 2.675 },
	{ ltv: 70, min: 800, spread: 2.675 },

	{ ltv: 80, min: 620, spread: 5.800 },
	{ ltv: 80, min: 640, spread: 4.675 },
	{ ltv: 80, min: 660, spread: 4.175 },
	{ ltv: 80, min: 680, spread: 3.675 },
	{ ltv: 80, min: 700, spread: 3.300 },
	{ ltv: 80, min: 720, spread: 2.925 },
	{ ltv: 80, min: 740, spread: 2.925 },
	{ ltv: 80, min: 760, spread: 2.925 },
	{ ltv: 80, min: 780, spread: 2.925 },
	{ ltv: 80, min: 800, spread: 2.925 },

	{ ltv: 85, min: 660, spread: 4.800 },
	{ ltv: 85, min: 680, spread: 4.175 },
	{ ltv: 85, min: 700, spread: 3.675 },
	{ ltv: 85, min: 720, spread: 3.300 },
	{ ltv: 85, min: 740, spread: 3.300 },
	{ ltv: 85, min: 760, spread: 3.300 },
	{ ltv: 85, min: 780, spread: 3.300 },
	{ ltv: 85, min: 800, spread: 3.300 },

	{ ltv: 90, min: 680, spread: 5.175 },
	{ ltv: 90, min: 700, spread: 4.550 },
	{ ltv: 90, min: 720, spread: 4.175 },
	{ ltv: 90, min: 740, spread: 4.175 },
	{ ltv: 90, min: 760, spread: 4.175 },
	{ ltv: 90, min: 780, spread: 4.175 },
	{ ltv: 90, min: 800, spread: 4.175 }
];

export const mortgageRatesMasterNonQMHighSecondary = [
	{ ltv: 50, min: 640, spread: 3.550 },
	{ ltv: 50, min: 660, spread: 3.175 },
	{ ltv: 50, min: 680, spread: 2.925 },
	{ ltv: 50, min: 700, spread: 2.925 },
	{ ltv: 50, min: 720, spread: 2.675 },
	{ ltv: 50, min: 740, spread: 2.675 },
	{ ltv: 50, min: 760, spread: 2.675 },
	{ ltv: 50, min: 780, spread: 2.675 },
	{ ltv: 50, min: 800, spread: 2.675 },

	{ ltv: 60, min: 640, spread: 3.725 },
	{ ltv: 60, min: 660, spread: 3.425 },
	{ ltv: 60, min: 680, spread: 3.175 },
	{ ltv: 60, min: 700, spread: 2.925 },
	{ ltv: 60, min: 720, spread: 2.675 },
	{ ltv: 60, min: 740, spread: 2.675 },
	{ ltv: 60, min: 760, spread: 2.675 },
	{ ltv: 60, min: 780, spread: 2.675 },
	{ ltv: 60, min: 800, spread: 2.675 },

	{ ltv: 70, min: 640, spread: 4.175 },
	{ ltv: 70, min: 660, spread: 3.800 },
	{ ltv: 70, min: 680, spread: 3.425 },
	{ ltv: 70, min: 700, spread: 3.175 },
	{ ltv: 70, min: 720, spread: 2.925 },
	{ ltv: 70, min: 740, spread: 2.925 },
	{ ltv: 70, min: 760, spread: 2.925 },
	{ ltv: 70, min: 780, spread: 2.925 },
	{ ltv: 70, min: 800, spread: 2.925 },

	{ ltv: 80, min: 640, spread: 4.925 },
	{ ltv: 80, min: 660, spread: 4.425 },
	{ ltv: 80, min: 680, spread: 3.925 },
	{ ltv: 80, min: 700, spread: 3.550 },
	{ ltv: 80, min: 720, spread: 3.175 },
	{ ltv: 80, min: 740, spread: 3.175 },
	{ ltv: 80, min: 760, spread: 3.175 },
	{ ltv: 80, min: 780, spread: 3.175 },
	{ ltv: 80, min: 800, spread: 3.175 },

	{ ltv: 85, min: 660, spread: 5.049 },
	{ ltv: 85, min: 680, spread: 4.425 },
	{ ltv: 85, min: 700, spread: 3.925 },
	{ ltv: 85, min: 720, spread: 3.550 },
	{ ltv: 85, min: 740, spread: 3.550 },
	{ ltv: 85, min: 760, spread: 3.550 },
	{ ltv: 85, min: 780, spread: 3.550 },
	{ ltv: 85, min: 800, spread: 3.550 }
];

export const mortgageRatesMasterNonQMHighInvestment = [
	{ ltv: 50, min: 640, spread: 3.550 },
	{ ltv: 50, min: 660, spread: 3.175 },
	{ ltv: 50, min: 680, spread: 2.925 },
	{ ltv: 50, min: 700, spread: 2.925 },
	{ ltv: 50, min: 720, spread: 2.675 },
	{ ltv: 50, min: 740, spread: 2.675 },
	{ ltv: 50, min: 760, spread: 2.675 },
	{ ltv: 50, min: 780, spread: 2.675 },
	{ ltv: 50, min: 800, spread: 2.675 },

	{ ltv: 60, min: 640, spread: 3.725 },
	{ ltv: 60, min: 660, spread: 3.425 },
	{ ltv: 60, min: 680, spread: 3.175 },
	{ ltv: 60, min: 700, spread: 2.925 },
	{ ltv: 60, min: 720, spread: 2.675 },
	{ ltv: 60, min: 740, spread: 2.675 },
	{ ltv: 60, min: 760, spread: 2.675 },
	{ ltv: 60, min: 780, spread: 2.675 },
	{ ltv: 60, min: 800, spread: 2.675 },

	{ ltv: 70, min: 640, spread: 4.175 },
	{ ltv: 70, min: 660, spread: 3.800 },
	{ ltv: 70, min: 680, spread: 3.425 },
	{ ltv: 70, min: 700, spread: 3.175 },
	{ ltv: 70, min: 720, spread: 2.925 },
	{ ltv: 70, min: 740, spread: 2.925 },
	{ ltv: 70, min: 760, spread: 2.925 },
	{ ltv: 70, min: 780, spread: 2.925 },
	{ ltv: 70, min: 800, spread: 2.925 },

	{ ltv: 80, min: 640, spread: 4.925 },
	{ ltv: 80, min: 660, spread: 4.425 },
	{ ltv: 80, min: 680, spread: 3.925 },
	{ ltv: 80, min: 700, spread: 3.550 },
	{ ltv: 80, min: 720, spread: 3.175 },
	{ ltv: 80, min: 740, spread: 3.175 },
	{ ltv: 80, min: 760, spread: 3.175 },
	{ ltv: 80, min: 780, spread: 3.175 },
	{ ltv: 80, min: 800, spread: 3.175 },

	{ ltv: 85, min: 660, spread: 5.049 },
	{ ltv: 85, min: 680, spread: 4.425 },
	{ ltv: 85, min: 700, spread: 3.925 },
	{ ltv: 85, min: 720, spread: 3.550 },
	{ ltv: 85, min: 740, spread: 3.550 },
	{ ltv: 85, min: 760, spread: 3.550 },
	{ ltv: 85, min: 780, spread: 3.550 },
	{ ltv: 85, min: 800, spread: 3.550 }
];

export const unsecureRates = [
	{ min: 720, rate: 11.20 },
	{ min: 690, rate: 15.50, },
	{ min: 630, rate: 20.50, },
	{ min: 350, rate: 25.30, },
];


export const nestFuelEmailId = 'test@nestfuel.com';

export const linkedInProfiles = [
	{
		emailId: 'george@gmail.com',
		firstName: 'George',
		lastName: 'Watson',
		profession: 'Teacher',
		yearsOfExperience: 10,
		preferredLocation: 'WatsonVille',
		state: 'CA',
		address: '128 Lester Ct, Watsonville, CA 95076',
		company: 'PVUSD/ Watsonville Hgh School',
		yearsInCompany: 5,
		highestEducation: 'Doctor of education - EdD',
		university: 'San Jose State University'
	},
	{
		emailId: 'vivek@gmail.com',
		firstName: 'Vivek',
		lastName: 'Singh',
		profession: 'Software Engineer',
		yearsOfExperience: 5,
		preferredLocation: 'San Jose',
		state: 'CA',
		address: '16054 County Rd 71, ALTURAS, CA 96101',
		company: 'Google',
		yearsInCompany: 5,
		highestEducation: 'Bachelor of Science - BS',
		university: 'Columbia University in the City of New York'
	},
	{
		emailId: 'jack@gmail.com',
		firstName: 'Jack',
		lastName: 'Dupont',
		profession: 'Neuro Surgeon',
		yearsOfExperience: 10,
		preferredLocation: 'Palo Alto',
		state: 'CA',
		address: '410 8th Ave, Menlo Park, CA 94025',
		company: 'Kaiser Permanente, Oakland, Full-Time',
		yearsInCompany: 10,
		highestEducation: 'Complete Spine Fellowship-Neurosurgery',
		university: 'Stanford University School of Medicine'
	},
	{
		emailId: nestFuelEmailId,
		firstName: 'Test',
		lastName: 'User',
		profession: 'Neuro Surgeon',
		yearsOfExperience: 10,
		preferredLocation: 'Palo Alto',
		state: 'CA',
		address: '410 8th Ave, Menlo Park, CA 94025',
		company: 'Kaiser Permanente, Oakland, Full-Time',
		yearsInCompany: 10,
		highestEducation: 'Complete Spine Fellowship-Neurosurgery',
		university: 'Stanford University School of Medicine'
	}
];

export const salaryBumps = [
	{
		profession: 'Teacher',
		bumps: [
			{ income: 1.32, outgoing: 10 },
			{ income: 2.60, outgoing: 10 },
			{ income: 3.80, outgoing: 10 },
			{ income: 3.66, outgoing: 10 },
			{ income: 3.53, outgoing: 10 },
		],
		bumpsIn3YearsInPercentage: 10,
		bumpsIn5YearsInPercentage: 30,
		annualIncome: 66596
	},
	{
		profession: 'Software Engineer',
		bumps: [
			{ income: 3.97, outgoing: 10 },
			{ income: 4.58, outgoing: 10 },
			{ income: 2.92, outgoing: 10 },
			{ income: 2.84, outgoing: 10 },
			{ income: 1.38, outgoing: 10 },
		],
		bumpsIn3YearsInPercentage: 20,
		bumpsIn5YearsInPercentage: 40,
		annualIncome: 180000
	},
	{
		profession: 'Neuro Surgeon',
		bumps: [
			{ income: 0.63, outgoing: 10 },
			{ income: 0.42, outgoing: 10 },
			{ income: 0.62, outgoing: 10 },
			{ income: 0.62, outgoing: 10 },
			{ income: 0.41, outgoing: 10 },
		],
		bumpsIn3YearsInPercentage: 30,
		bumpsIn5YearsInPercentage: 50,
		annualIncome: 392000
	}
];

export const monthlyRentalYieldSwaprate = 1.6;
export const rentalYieldMargin = [
	{
		rate: 1.75,
		tag: 'Low',
	},
	{
		rate: 2.50,
		tag: 'Low-Sufficient'
	},
	{
		rate: 4.25,
		tag: 'Sufficient'
	},
	{
		rate: 100000000000,
		tag: 'High'
	}
];

export const upfrontMargins = [
	{
		rate: 5,
		tag: 'Insufficient',
	},
	{
		rate: 10,
		tag: 'Low'
	},
	{
		rate: 20,
		tag: 'Sufficient'
	},
	{
		rate: 100,
		tag: 'High'
	}
];

export const appConstants = {
	dtiFrontEndThreshold: {
		QM: 25,
		nonQM: 25,
		nonQMHigh: 25,
	},
	dtiBackEndThreshold: {
		QM: 41,
		nonQM: 41,
		nonQMHigh: 41,
	},
	longerTermDuration: 40,
	reducedMonthlyInitialTerm: 3,
	reducedMonthlyunsecureTerm: 4,
	pointsToInterestRate: 0.25,
	unsecureLoanTermInYears: 3,
	rentalYield: 4,
};