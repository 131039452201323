import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button,Input} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import ItemsCarousel from "react-items-carousel";
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import GoogleMaps from './Googlemaps';
import leftbottom from '../../assets/images/bg-10@1x.png'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {  useDispatch, 
          useSelector } from 'react-redux';
import {  borrowerProfile } from "../../actions/borrower";

import { Centerbottom, LeftbottomA } from "./RightBlock";
import { intAPR, intRNQM, SELPROP, terM, withCommas, _debug } from "../../constants";

import { LeftBlockMsg } from "./Messenger";

import leftArrow from '../../assets/svg/left-arrow.svg'
import rightArrow from '../../assets/svg/right-arrow.svg'


export function SelectFinancing() {
  const { bChoiceD,
          bfinancialProfile,
          isLoading,
          bChoiceE,
          bChoiceA,
          bChoiceB } = useSelector((state)=>state.borrower);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  
  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "prea": 
        navigate("/PreaprovalFrame", { replace: false });
        break;
      case "chngp": 
        navigate("/SearchProperty", { replace: false });
        break;
      case "chngf":
        navigate("/ShowPurchaseRate", { replace: false });
        break;
      case "arngv":
        navigate("/ArrangeViewingForm", { replace: false });
        break;
      default:break;
    }
  }

  return(
    isLoading ? <CircularProgress /> : (
    <React.Fragment>
          <CssBaseline/>
          <Box sx={{overflow:'hidden', width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
            <Navbar pathname={location.pathname}/>
            <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
            { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
            
              <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> 
            ...
          </Typography>
          <div align='center'>
          <Stack sx={{mt:'3.43vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
          <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="prea" className='hexBtnCenter'
                  disabled={bChoiceD.length ===0 ? true:false}
                  onClick={onClickFun}> 
                  <HexButton titleText="Preaprove" type={bChoiceD.length ===0 ? 'disabled':'base'} fontSize='24'/></Button>
              </Stack>
              <Stack sx={{mt:'2.25vw',height:'3.5vw', width:'12vw'}} spacing={0} direction="row" justifyContent="space-around">
              <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="chngp" className='hexBtnCenter'
                  disabled={bChoiceD.length ===0 ? true:false}
                  onClick={onClickFun}> 
                  <HexButton titleText="Change Property" type={bChoiceD.length ===0 ? 'disabled':'base'} fontSize='24'/></Button>
                  <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="chngf" className='hexBtnCenter'
                  onClick={onClickFun}> 
                  <HexButton titleText="Chang Financing" type='base' fontSize='24'/></Button>
              </Stack>
              <Stack sx={{mt:'3.43vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="arngv" className='hexBtnCenter'
                      disabled={bChoiceD.length ===0 ? true:false}
                      onClick={onClickFun}> 
                  <HexButton titleText="Arrange Viewing" type={bChoiceD.length ===0 ? 'disabled':'base'} fontSize='24'/></Button>
              </Stack>
              <Centerbottom navigateForward = "ShowPurchaseOpt"/>
              </div>
          </Box>
        <RightBlock/>
      </Box>
    </Box>
    </React.Fragment>
  ));
}

function RightBlock() {
  const { finOptS,
          finOptP,
          bChoiceD,
          selectedProp
          } = useSelector((state)=>state.borrower);
  const [showMap,setShowMap] = React.useState(-1)
  const dispatch = useDispatch();
  if (!selectedProp) dispatch({type: SELPROP, payload:{...selectedProp,prop:bChoiceD[0],propId:0,finOpt:0}});
  return (
    <React.Fragment>
      <CssBaseline/>
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',opacity: 1, width: '50vw',height:'46.75vw' }}>
        <Box p={0,0} sx={{background:'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "100%",height:'18vw' ,border:0, borderRadius:0}}>
            {showMap === 5  
                ?<GoogleMaps position={{lat: finOptS.prop?.geoLocation.lat, lng:finOptS.prop?.geoLocation.lon}}
                                markerInput={[finOptS.prop]} wHeight='13.5vw'/> 
                :<InnerCarousel imageArray={finOptS.prop.images} numCards={2} chevron={1}/>
            }
          <Stack sx={{ml:2, mr:0,mb:1}} spacing={10} direction="row" justifyContent="space-between"> 
          <Typography mt='0.5vw' fontSize='1.1vw' fontWeight='bold' color='black' align='center'>{withCommas(finOptS.prop.offerPrice)}</Typography>
          <Box width='20vw'>
            <Typography fontSize='1.0vw' color='black' align='center'> <Button sx={{minHeight: 0, minWidth: 0}} onClick={()=> {showMap === -1? setShowMap(5) : setShowMap(-1)}} ><LocationOnIcon sx={{color:'#000000'}}/></Button>{finOptS?.prop.address}</Typography>
            <Typography sx={{mt:'-1.0vh'}}fontSize='0.9vw' color='black' align='center'> {finOptS?.prop.city}, {finOptS?.prop.state} - {finOptS?.prop.zipcode}</Typography>
          </Box>
          </Stack>  
        </Box>
        <Box mt='1.6vh' mb='1.7vh' p={2,2} height='10.125vw' background='#FFFFFF 0% 0% no-repeat padding-box' display='flex' flexDirection= 'row' borderRadius='0.5vw'>
          <Box sx={{background: "##027AF5 0% 0% no-repeat padding-box", width: "50%",height:'10.125vw',p:2,border:0 ,borderRadius:0}}>
            <Stack direction="row" justifyContent="space-between">
              <Typography  mt='0.56vw' fontSize='1.1vw' color='#31312C'>Rate:</Typography>
              <Typography  mt='0.56vw' fontSize='1.1vw' fontWeight='bold' color='#000000'>{intRNQM}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography  mt='0.56vw' fontSize='1.1vw' color='#31312C'>APR:</Typography>
              <Typography  mt='0.56vw' fontSize='1.1vw' fontWeight='bold' color='#000000'>{intAPR}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography  mt='0.56vw' fontSize='1.1vw' color='#31312C'>Ownership:</Typography>
              <Typography  mt='0.56vw' fontSize='1.1vw' fontWeight='bold' color='#000000'>100%</Typography>
            </Stack>
          </Box>
          <Box sx={{background: "#027AF5 0% 0% no-repeat padding-box", width: "50%",height:'10.125vw',p:2,border:0 ,borderRadius:0}}>
            <Stack direction="row" justifyContent="space-between">
              <Typography  mt='0.56vw' fontSize='1.1vw' color='#31312C'>Term:</Typography>
              <Typography  mt='0.56vw' fontSize='1.1vw' fontWeight='bold' color='#000000'>{terM} years</Typography>
            </Stack>
            {/* <Stack direction="row" justifyContent="space-between">
              <Typography  mt='0.56vw' fontSize='1.1vw' color='#31312C'>IR Term:</Typography>
              <Typography  mt='0.56vw' fontSize='1.1vw' fontWeight='bold' color='#000000'>3 years</Typography>
            </Stack> */}
            <Stack direction="row" justifyContent="space-between">
              <Typography  mt='0.56vw' fontSize='1.1vw' color='#31312C'>Home Insurance:</Typography>
              <Typography  mt='0.56vw' fontSize='1.1vw' fontWeight='bold' color='#000000'>{withCommas(Math.ceil(0.05*finOptS.prop.offerPrice/12))}</Typography>
            </Stack>
          </Box>

        </Box>
        <Box sx={{ background: "#FFFFFF 0% 0% no-repeat padding-box", display: 'flex', flexDirection: 'row', width:'100%', height:"21vw",boxShadow: '0px 0px 19px #0000008F'}}>
        <Typography  ml='0.5vw' fontSize='1.4vw' align='left' fontWeight='bold' color='#000000'>Facts & Features</Typography> 
          <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "50%",height:"21vw",p:2,border:0 ,borderRadius:0}}>
          <Typography  ml='0.5vw' fontSize='1.4vw' align='left' fontWeight='bold' color='#000000'>Overview</Typography> 
          <Typography  ml='0.5vw' fontSize='1.4vw' align='left' fontWeight='bold' color='#000000'>In 1959 when man first landed on the surface
          of moon, we predicted a colony by next decade or two. However, we are not able to achieve anything near to that goal. This is probably our inability to find a const name a new type 
          of force that can propel space crafts with enormouse speed.</Typography> 
          </Box>
          <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "50%",height:"21vw",p:2,border:0 ,borderRadius:0}}>
          <Stack sx={{mt:'1vw'}} direction="row" justifyContent="space-around"> 
          <Typography  ml='0.5vw' fontSize='1.4vw' align='left' fontWeight='bold' color='#000000'>Parking</Typography> 
                  <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left'>Total Spaces: </Typography>  
                  <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>2 </Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left' >Parking Features</Typography>  
              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>Ofline booking, Parking meters</Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left'>Garage Spaces:</Typography>  
              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'> few </Typography>  
          </Stack>
          <Typography mt={2} ml='.5vw' fontSize='1.4vw' fontWeight='bold' color='#000000'>Covered SPaces</Typography> 
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1.1vw' color='#000000'width='8vw'textAlign='left'>Income:</Typography>  
              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>a few</Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left'>Other parking info:</Typography>  
              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>No parkinson </Typography>  
          </Stack>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

function LeftBlock () {

  const dispatch = useDispatch();
  const { bProfile,
          finOptS
        } = useSelector((state)=>state.borrower);

  function onClickFun(e) {
    const buttonId = e.currentTarget.id;
    const input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
    switch(buttonId) {
      case "fname":dispatch(borrowerProfile({...bProfile,firstName:input}));break;
      case "lname":dispatch(borrowerProfile({...bProfile,lastName:input})); break;
      case "ssn": dispatch(borrowerProfile({...bProfile,ssn:input}));break;
      default:break;
      }
  }
  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
        <Box sx={{ mt:'8.2vw' , ml: '7.3vw', width: '26vw',height:'6vw' }}> 
            <Typography sx={{color:'#31312C', width:'14vw',
              fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                Selected Financing for {finOptS?.prop?.address} {finOptS?.prop?.county}
            </Typography>
        </Box>
        <Box sx={{ mt:'1vw' , ml: '7.3vw', width: '28vw',height:'14vw' }}> 
          <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
              <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>First Name :</Typography>  
              <Input disableUnderline={true} id="fname" value={bProfile.firstName} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                    inputProps={{style: { textAlign: 'left' }}}onChange={onClickFun}/>
          </Stack>
          <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Last Name :</Typography>  
              <Input disableUnderline={true} id="lname" value={bProfile.lastName} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                    inputProps={{style: { textAlign: 'left' }}}onChange={onClickFun}/>
          </Stack>
          {/* <Stack sx={{mt:1}} spacing={0} direction="row" justifyContent="space-between"> 
          <Typography sx={{mt:1,fontSize:'1.35vw', width:'10  vw', justifyContent:'space-between'}} color='#000000'>SSN :</Typography>  
              <Input disableUnderline={true} id="ssn" value='xxx-xxx-xxx' sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw",border:1, borderRadius:'0.4vw'}} 
                    inputProps={{style: { textAlign: 'left' }}}onChange={onClickFun}/>
          </Stack> */}
          
          <Typography sx={{color:'#31312C',width:'28vw', mt:'3vw',
              fontSize:'1.72vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
                Provide details to improve your purchasing power estimate
            </Typography>
        </Box>

        <LeftbottomA leftbottom={leftbottom}/>
    </Box>
  );
}

function InnerCarousel(props) {
  const {imageArray,numCards,chevron} = props;
  const [active, setActive] = React.useState(0);
  console.log("chevron",chevron)
  return (
    <div className="InnerCarousel">
      <ItemsCarousel
         autoPlay={true}
         infiniteLoop={true}
         gutter={0}
         timeout={1}
         activePosition={"center"}
         chevronWidth={-2}
         disableSwipe={false}
         alwaysShowChevrons={false}
         numberOfCards={numCards}
         slidesToScroll={1}
         outsideChevron={false}
         showSlither={true}
         firstAndLastGutter={true}
         activeItemIndex={active}
        requestToChangeActive={(value) => setActive(value)}
         //rightChevron={<ArrowForwardIosIcon sx={{color:"#FFFFFF" , fontSize:'3vw'}}/>}
         //leftChevron={<ArrowBackIosIcon    sx={{color:"#FFFFFF" , fontSize:'3vw'}}/>}
         rightChevron={ <img src={rightArrow} alt='right-arrow' style={{ width:'1.77vw', height:'3.28vw' }} /> }
         leftChevron={ <img src={leftArrow} alt='left-arrow' style={{ width:'1.77vw', height:'3.28vw' }} /> }

        //  rightChevron={<ArrowForwardIosIcon sx={{color:"#F1F2F4", fontSize:40,
        //   borderRadius: '15px 0px 0px 15px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
        // leftChevron={<ArrowBackIosIcon    sx={{ color:"#F1F2F4", fontSize:40,
        //   borderRadius: '0px 15px 15px 0px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
      >
      {imageArray.map((item,index) => 
      <div>
       <Box key={index} sx={{ border:1, borderColor:"#FFFFFF", borderRadius: '10px 10px 0px 0px', height:'13.5vw'}} >
       <img src={(typeof(item) ==='undefined' ? nestfuelImage : item)} alt={nestfuelImage} style={{borderRadius: '10px 10px 0px 0px'}} width="100%" height="100%"/>
        </Box>
        </div>)}
      </ItemsCarousel>
    </div>
  );
}
export default SelectFinancing