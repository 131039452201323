import React, { useEffect, useState } from 'react';
import { LOGOUT,LOGGEDIN, ISSIGNIN, _debug } from '../../constants';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import "firebase/compat/auth";
import compatApp from "firebase/compat/app";

import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import { borrowerGetUserData } from "../../actions/borrower";

import { getAuth, signOut, GoogleAuthProvider, FacebookAuthProvider,EmailAuthProvider,PhoneAuthProvider } from "firebase/auth";
import {  mongoborrowerGetUserData, mongoborrowerGetFinanceData } from '../../actions/borrower';
import { mongoborrowerPostUserData } from '../../actions/borrower';
import { getIdToken} from "firebase/auth";



var app
const firebaseConfig = _debug === 'development' 
    ?{
      apiKey            : process.env.REACT_APP_GOOGLE_AUTH_API_KEY_UAT,
      authDomain        : process.env.REACT_APP_GOOGLE_AUTH_DOMAIN_UAT,
      projectId         : process.env.REACT_APP_GOOGLE_AUTH_PROJECTID_UAT,
      storageBucket     : process.env.REACT_APP_GOOGLE_AUTH_BUCKET_UAT,
      messagingSenderId : process.env.REACT_APP_GOOGLE_AUTH_MSG_SNDR_ID_UAT,
      appId             : process.env.REACT_APP_GOOGLE_AUTH_APP_ID_UAT,
      measurementId     : process.env.REACT_APP_GOOGLE_AUTH_MSRMT_ID_UAT
    }
    :{
        apiKey            : process.env.REACT_APP_GOOGLE_AUTH_API_KEY,
        authDomain        : process.env.REACT_APP_GOOGLE_AUTH_DOMAIN,
        projectId         : process.env.REACT_APP_GOOGLE_AUTH_PROJECTID,
        storageBucket     : process.env.REACT_APP_GOOGLE_AUTH_BUCKET,
        messagingSenderId : process.env.REACT_APP_GOOGLE_AUTH_MSG_SNDR_ID,
        appId             : process.env.REACT_APP_GOOGLE_AUTH_APP_ID,
        measurementId     : process.env.REACT_APP_GOOGLE_AUTH_MSRMT_ID
    };  
try{ app = compatApp.app()}catch(error){ app = compatApp.initializeApp(firebaseConfig)}
const auth =compatApp.auth(app);

function SignInScreen() {

    const { bProfile,bProfileStatus } = useSelector((state)=>state.borrower); 
    const { bfinancialProfile } = useSelector((state)=>state.borrower);
    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    
    console.log('location',location);

    const uiConfig = {
        signInFlow: 'popup',
        signInOptions: [
          GoogleAuthProvider.PROVIDER_ID,
          FacebookAuthProvider.PROVIDER_ID,
          EmailAuthProvider.PROVIDER_ID,
          PhoneAuthProvider.PROVIDER_ID
        ],
        callbacks: {
          
          signInSuccessWithAuthResult: async function(authResult, redirectUrl="/DashboardProfile") {
            console.log('authResult',authResult)
            var user = authResult.user;
            console.log('fireUI=>',user);
           // console.log('fireUI=>',user);
           const token= await getIdToken(user,true); 
           console.log('authToken from signin',token);          
          sessionStorage.setItem('authToken', token);

            var displayName = user.multiFactor.user.displayName
            displayName = displayName.split(" ");
            console.log(displayName);
            console.log("Number",user.multiFactor.user.phoneNumber)
            var fname = (displayName[0]) ? displayName[0] : bProfile.firstName;
            var lname = (displayName[1]) ? displayName[1] : bProfile.lastName;
            var email = (user.multiFactor.user.email) ? user.multiFactor.user.email : bProfile.emailId;
            var uid = (user.multiFactor.user.uid) ? user.multiFactor.user.uid : bProfile.userId;
            var mno =  (user.multiFactor.user.phoneNumber) ? user.multiFactor.user.phoneNumber : bProfile.phoneNumber;
            console.log("get token from session storage",sessionStorage.getItem('authToken'));
          // send either email or phone number for new user registration
          if(user.multiFactor.user.email){
            var apiBodyData = {
              "data":{
              "isBorrower":true,
              "emailId": email,
              }
            }
          }else if(user.multiFactor.user.phoneNumber){
            var apiBodyData = {
              "data":{
              "isBorrower":true,
              "phoneNumber": mno,
              }
            }
          }
          console.log("apiBodyData",apiBodyData)
          dispatch(mongoborrowerPostUserData(apiBodyData));

            dispatch(mongoborrowerGetUserData(bProfile)); 
            dispatch(mongoborrowerGetFinanceData(bfinancialProfile));
            return true;
          },

        },
      };
      console.log("UIConfig",uiConfig)
      // console.log()
    // signInWithPopup(auth, provider)
    //   .then((result) => {
    //     const credential = provider.credentialFromResult(result);
    //     const token = credential.accessToken;
    //     const user = result.user;
    //   }).catch((error) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     const email = error.customData.email;
    //     const credential = provider.credentialFromError(error);
    // });
    const logout = () => {
        auth.signOut()
        dispatch({ type: LOGOUT })
        navigate("/", { replace: true });
    };
    
      // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
      const unregisterAuthObserver = auth.onAuthStateChanged(user => {
        setIsSignedIn(!!user);
        user === null ? dispatch({ type: LOGOUT }) : dispatch({ type: LOGGEDIN });
      });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    
      if (!isSignedIn) {
        return (
          <div><StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} /></div>
        );
      }

      return (
        <div>
          <p>Welcome {auth.currentUser.displayName}! You are now signed-in!</p>
          {/* <a onClick={() => firebase.auth().signOut()}>Sign-out</a> */}
          {/* <a onClick={logout}>Sign-out</a> */}
    
          <Button variant="contained" sx={{p:'0.5vw', width:'8vw',ml:'.5vw',width:'6vw',fontSize:'0.7vw',backgroundColor:'#31312C',color:'#FFFFFF', "&.MuiButtonBase-root:hover": {
              backgroundColor:'#31312C',color:'#FFFFFF'
              } }} onClick={logout}>Sign-out</Button>
          
        </div>
      );
}

export default SignInScreen;