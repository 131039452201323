import React from "react";
import { Box, CssBaseline, CircularProgress, Stack,Typography, Button} from "@mui/material";
import Navbar from "./NavBar";
import { rspvw } from "./styles";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import paymentImageSvg from '../../assets/svg/assets svg/Group 8317.svg';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { borrowerChoiceE, borrowerProfileStatus, borrowerfinancialProfile, borrowerPropertyDetail, calcPropfinOptionsA} from "../../actions/borrower";
import RightBlock, { Centerbottom, LeftbottomA, RightBlockSPBP } from "./RightBlock";
import leftbottom from '../../assets/images/bg-10@1x.png'
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { IS_PROFILE_INFO_CHANGED } from "../../constants";

function Ownership() {
  const { bChoiceE,
          bProfileStatus,
          bfinancialProfile,
          bPropertyDetail,
          bProfile,
          bChoiceD,
          bChoiceB,
          isLoading
        } = useSelector((state)=>state.borrower);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
 
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 

  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    var ownership = '';
    switch(buttonId) {
      case "SoleOwner"  : ownership = "Sole-Owner Sole-Borrower";
      dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
      dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});

      break;
      case "SoloJoint"  : ownership = "Sole-Owner Joint-Borrower";
      dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
      dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});
      break;
      case "JointJoint" : ownership = "Joint-Owner Joint-Borrower";
      dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
      dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});
      break;
      case "JointSolo"  : ownership = "Joint-Owner Sole-Borrower";
      dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
      dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});
      break;
      default:
        break;
    }
    dispatch(borrowerChoiceE({...bChoiceE,ownership:ownership}));
    dispatch(borrowerProfileStatus({...bProfileStatus,ownershipProvided:1}))

    const _finance = {...bfinancialProfile.finance, ownershipType:ownership};
    dispatch(borrowerfinancialProfile({...bfinancialProfile, finance:_finance }));

    navigate(-1);
  }
  return(
    isLoading ? <CircularProgress /> : (
      <React.Fragment><CssBaseline/>
        <Box mt={marginT} ml={marginL}  sx={{ overflow:'hidden', width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
          <Navbar pathname={location.pathname}/>
          <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
            { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
          
            <Box sx={{ width: '14vw', height:{xs:`${rspvw(815.9)}`,lg:`${rspvw(815.9)}`,
        md:`${rspvw(915)}`, xl:`${rspvw(815.9)}`,sm:`${rspvw(815.9)}`}, display:'block', alignItems:'center',position:'relative'}}>
              <div align='center'>
                <Typography sx={{ color: '#000000', mt: '11.42vw', fontSize: '1.4vw', textAlign: 'center' }}>
                  Update Settings
                </Typography>

                <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="SoleOwner" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Sole-Owner & Borrower" type={bfinancialProfile.finance.ownershipType === 'Sole-Owner Sole-Borrower' ? 'visited' : 'base'} fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="JointJoint" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Joint-Owner & Borrower" type={bfinancialProfile.finance.ownershipType === 'Joint-Owner Joint-Borrower' ? 'visited' : 'base'} fontSize='24' /></Button>
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="JointSolo" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Joint-Owner & Sole-Borrower" type={bfinancialProfile.finance.ownershipType === 'Joint-Owner Sole-Borrower' ? 'visited' : 'base'} fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '6.5vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="SoloJoint" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Sole-Owner Joint Borrower" type={bfinancialProfile.finance.ownershipType === 'Sole-Owner Joint-Borrower' ? 'visited' : 'base'} fontSize='24' /></Button>
                </Stack>
                <Centerbottom navigateForward='-1' />
              </div>
          </Box>
        <RightBlockSPBP/>
        </Box>
      </Box>
      </React.Fragment>
    ));
  }

  function LeftBlock() {
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:{xs:`${rspvw(815)}`,lg:`${rspvw(815)}`,
      md:`${rspvw(915)}`, xl:`${rspvw(915)}`,sm:`${rspvw(815)}`} }}>
            <Box sx={{ ml:'7vw' , mt:'13.5vw',background:'inherit',width:'21vw', height: '13.5vw' }}>
              <div align='center'>
            <img src={paymentImageSvg} alt = "Loading..." heigh="100px" width='100px'/>    
            <Typography mt={5} fontSize='1.5vw' fontStyle="regular" component='div'color='#000000'> 
                Property Ownership
            </Typography>
            <Typography sx={{ mt:2, width:'20vw'}} fontSize='1.2vw' component="div" color='#000000' align='center'>    
                Will this property be in your name or owned jointly?
            </Typography>
          </div>
        </Box>
        <LeftbottomA leftbottom={leftbottom}/>
      </Box>
    );
  }

export default Ownership;