import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button} from "@mui/material";
import Navbar from "./NavBar";
import { rspvw } from "./styles";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/bg-10@1x.png'
import {  useDispatch, 
          useSelector } from 'react-redux';
import { Centerbottom, LeftbottomA, RightBlockSPBPE } from "./RightBlock";
import { useWindowDimensions } from "./HelperFunctions";
import {withCommas, _debug} from '../../constants'
import { borrowerChoiceI } from "../../actions/borrower";
import { LeftBlockMsg } from "./Messenger";

const DebugOutput = true;

export function EmployerBenefitR(){
        const { isLoading,
          bChoiceE,
          bChoiceB,
          selectedProp,
          bfinancialProfile,
          bChoiceF
        } = useSelector((state)=>state.borrower);
      const navigate = useNavigate();
      const location = useLocation();
      const dispatch = useDispatch();
      const { height, width } = useWindowDimensions();
      const [marginT,setMarginT] = React.useState('0px')
      const [marginL,setMarginL] = React.useState('0px')
      React.useEffect(()=>{
        const calc1 = Math.ceil(height*0.5 - 0.28125*width)
        const calc2 = Math.ceil(width*0.5 - 1.78*height)
        setMarginT(String(calc1 >=0?calc1:0).concat('px'))
        setMarginL(String(calc2 >=0?calc2:0).concat('px'))
      
      },[height,width]) 
      function onClickFun(e){
      e.preventDefault();
      const buttonId = e.currentTarget.id;
      switch(buttonId) {
      case "employer": 
        navigate("/EmployerSupport", { replace: false });
        break;
      case "smart": 
      const propFinRequest = {
        selectedPropertyPrice   : selectedProp?.prop.offerPrice,
        proposedPropertyPrice   : selectedProp?.prop.offerPrice,
        financeType             : bChoiceB === 'firsthome' || bChoiceB === 'nexthome' ? 'primary':(bChoiceB === 'investment')? 'investment' : 'secondary',
        loanTerm                : bfinancialProfile?.finance.loanTerm,
        creditScore             : bfinancialProfile?.income.ficoScore,
        annualIncome            : bfinancialProfile?.income.annualIncome,
        upfront                 : bfinancialProfile?.income.upfront,
        financeDocs             : bfinancialProfile?.financeDocs.docsAll,
        creditEvents            : bfinancialProfile?.finance.creditEvents,
        houseEvents             : bfinancialProfile?.finance.houseEvents,
        monthlyOutgoing         : bfinancialProfile?.income.outgoing,
        monthlyBudget           : bfinancialProfile?.income.monthlyBudget,
        emailId                 : 'amitkd4@gmail.com'
      }
      if(_debug==='debug') console.log("Compare Finance Request",propFinRequest);
      dispatch(borrowerChoiceI(propFinRequest));  
      navigate("/SmartChoice", { replace: false })
      break
    case "adjust":
      navigate("/ShowProperty", { replace: false });
      break;
    default:break;
    }
  }

  return(
    isLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
      <React.Fragment>
        <CssBaseline/>
        <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
          <Navbar pathname={location.pathname}/>
          <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
            { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
          
            <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <Typography sx={{color:'#000000',mt:'30.0vh',fontSize:'1.4vw', textAlign:'center'}}> 
              
              </Typography>
              <div align='center'>
                <Stack sx={{mt:'2vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',width:'5.23vw',height:'6.80vw',border:0}} id="employer" className='hexBtnCenter'
                  onClick={onClickFun}> 
                  <HexButton titleText="Evaluate benefit" type='base' fontSize='24'/></Button>
                </Stack>
                <Centerbottom navigateForward="EmployerSupport"/>
              </div>
              {
              /*
              <div align='center'>
                <Stack sx={{mt:'3.43vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
                <Button sx={{minHeight: 0, minWidth: 0, padding: 0,width:'5.23vw',height:'3.5vw',border:0}} id="employer" 
                onClick={onClickFun}> 
                <HexButton titleText="Employer Benefit" type='base' fontSize='24'/></Button>
                </Stack>
                <Stack sx={{mt:'2.25vw',height:'3.5vw', width:'12vw'}} spacing={0} direction="row" justifyContent="space-around">
                <Button sx={{minHeight: 0, minWidth: 0, padding: 0,width:'5.23vw',height:'3.5vw',border:0}} id="smart" 
                onClick={onClickFun}> 
                <HexButton titleText="Nestfuel Smart" type='base' fontSize='24'/></Button>
                <Button sx={{minHeight: 0, minWidth: 0, padding: 0,mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="Adjust" 
                onClick={onClickFun}> 
                <HexButton titleText="Adjust Income& Cash" type='base' fontSize='24'/></Button>
                </Stack>
                <Centerbottom/>
              </div>
              */
              }
            </Box>
            <RightBlockSPBPE rbops='ebprR'/>
          </Box>
        </Box>
      </React.Fragment>
  ));
}

function LeftBlock () {

  const { bChoiceE,
          bChoiceDSFB,
          bfinancialProfile
        } = useSelector((state)=>state.borrower);
  const max = Math.floor(bChoiceDSFB?.saving.propPrice)
  const savings = Math.floor(bChoiceDSFB?.saving.savings)
  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
        <Box sx={{ mt:'5.5vw' , ml: '5.0vw', width: '28vw',height:'10vw' }}> 
            <Typography sx={{color:'#31312C', width:'28vw', mt:'1.5vh',
               fontSize:'2.5vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '-0.1vw'}}> 
                 Reduced Monthly payment by (Annual Benefit)
             </Typography>
             <Typography sx={{color:'#31312C',width:'20vw', mt:'2vh',
               fontSize:'5.20vw', fontFamily:'Roboto',fontWeight:700,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
                 {withCommas(Math.round(savings/100)*100)}
             </Typography>
             <Typography sx={{color:'#31312C', width:'28vw', mt:'2vh',
               fontSize:'2.5vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '-0.1vw'}}> 
                 Employer Benefit Scheme can reduce your outgoings
             </Typography>
        </Box>

        <LeftbottomA leftbottom={leftbottom}/>
    </Box>
  );
}

export default EmployerBenefitR

