import * as React from 'react';
import { Box, Toolbar, Avatar, Button, Stack,Typography } from '@mui/material';
import InputOutlinedIcon from '@mui/icons-material/InputOutlined';
import decode from 'jwt-decode';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import nestfuelImage from '../../assets/svg/nestfuel_nav.svg';
import useStyles from './styles';
import { initializeApp,getApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { updateFlag} from "../../actions/borrower";
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT,LOGGEDIN, ISSIGNIN, _debug } from '../../constants';
import { getAnalytics,logEvent } from "firebase/analytics";

const pages = ['Home', 'Properties', 'Why NestFuel', 'SignUP', 'Download App'];


const firebaseConfig = _debug === 'development' 
    ?{
      apiKey            : process.env.REACT_APP_GOOGLE_AUTH_API_KEY_UAT,
      authDomain        : process.env.REACT_APP_GOOGLE_AUTH_DOMAIN_UAT,
      projectId         : process.env.REACT_APP_GOOGLE_AUTH_PROJECTID_UAT,
      storageBucket     : process.env.REACT_APP_GOOGLE_AUTH_BUCKET_UAT,
      messagingSenderId : process.env.REACT_APP_GOOGLE_AUTH_MSG_SNDR_ID_UAT,
      appId             : process.env.REACT_APP_GOOGLE_AUTH_APP_ID_UAT,
      measurementId     : process.env.REACT_APP_GOOGLE_AUTH_MSRMT_ID_UAT
    }
    :{
        apiKey            : process.env.REACT_APP_GOOGLE_AUTH_API_KEY,
        authDomain        : process.env.REACT_APP_GOOGLE_AUTH_DOMAIN,
        projectId         : process.env.REACT_APP_GOOGLE_AUTH_PROJECTID,
        storageBucket     : process.env.REACT_APP_GOOGLE_AUTH_BUCKET,
        messagingSenderId : process.env.REACT_APP_GOOGLE_AUTH_MSG_SNDR_ID,
        appId             : process.env.REACT_APP_GOOGLE_AUTH_APP_ID,
        measurementId     : process.env.REACT_APP_GOOGLE_AUTH_MSRMT_ID
    }  

let app
try{app = getApp()}catch(error){ app = initializeApp(firebaseConfig);}
const auth = getAuth(app);
const analytics = getAnalytics(app);

const NavbarS = () => {
  const dispatch = useDispatch();
  const { bProfile,isLoggedin, navFlag } = useSelector((state)=>state.borrower); 

  const [user,setUser] = React.useState(auth.currentUser);

  // const dispatch = useDispatch();
  // const { bProfile,isLoggedin } = useSelector((state)=>state.borrower);  
  // const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('profile')));
  
  !isLoggedin && user && dispatch({ type: LOGGEDIN })
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();

  if(_debug === 'development') console.log("User",user)
  const logout = () => {
    auth.signOut()
    dispatch({ type: LOGOUT })
    //navigate(-1);
    navigate("/", { replace: true });
    setUser(null);
  };
  // const logout = () => {
  //   dispatch({ type: LOGOUT});
  //   localStorage.clear();
  //   navigate("/", { replace: false });
  //   setUser(null);
  // };

  
  // React.useEffect(() => {
  //   const token = user?.token;
  //   if (token) {
  //     const decodedToken = decode(token);
  //     if (decodedToken.exp * 10000 < new Date().getTime()) logout();
  //   }
  //   setUser(JSON.parse(localStorage.getItem('profile')));
  //   !isLoggedin && user && dispatch({ type: ISLOGGEDIN })
  // }, [location]);

  //console.log("Firebase Config",firebaseConfig)
  React.useEffect(() => {
    isLoggedin && setUser(auth.currentUser);
    !isLoggedin && user && dispatch({ type: LOGGEDIN })
  }, [isLoggedin,location]);

  const handleNav =()=>{
    console.log("Home Clicked")
    dispatch(updateFlag(false))
    
  
  
  }

  return (
    <>{
      navFlag ? "":
      <Stack spacing={0} direction="row" justifyContent="space-between">
      <Box sx={{width:'36vw', height:'4.5vw'}}>
      <Stack sx={{mt:'0vw'}}spacing='1vw' direction="row"> 
      <Box sx={{ml:'7.34vw',width:'13vw', height:'4.5vw'}}> 
        <img style={{ verticalAlign:'revert', paddingTop:'1.1vw',paddingBottom:'1.1vw'}} src={nestfuelImage} alt="no image found" height='100%'/>
      </Box>
        <Typography  sx={{pt:'1.5vw'}} fontSize='0.9375vw' color='#000000' width='16vw'textAlign='left'>Democratizing Home Ownership</Typography>  
      </Stack>
      </Box>
      <Box sx={{width:'50vw',height:'4.5vw'}} className={classes.appBar} position="static">
        <Link onClick={handleNav} to="/" className={classes.links}> HOME </Link>
        <Link to="/WhyUs" className={classes.links}> WHY NESTFUEL </Link>
        <Link to="/AboutUs" className={classes.links}> ABOUT US</Link>
        <Link to="/EmployerPartnership" className={classes.links}>EMPLOYER PARTNERSHIP  </Link>
        { (isLoggedin && user) ? (
          <div className={classes.profile}>
            {/* <Avatar className={classes.purple} alt={user?.firstName + ' ' + user?.lastName} src={user?.imageUrl}>{user?.firstName?.charAt(0) || bProfile?.firstName?.charAt(0)} {user?.lastName?.charAt(0)|| bProfile?.lastName?.charAt(0)}</Avatar> */}
            <Avatar className={classes.purple} alt={user?.displayName} src={user?.photoURL}>{user?.displayName.charAt(0)} {user?.displayName.split(" ")[1].charAt(0)}</Avatar>
            <Button variant="contained" sx={{p:'0.5vw', width:'8vw',ml:'.5vw',width:'6vw',fontSize:'0.7vw',backgroundColor:'#31312C',color:'#FFFFFF', "&.MuiButtonBase-root:hover": {
          backgroundColor:'#31312C',color:'#FFFFFF'
          } }} onClick={logout}>Logout</Button>
          </div>
        ) : (
          <Link to="/DashboardProfile" className={classes.links}> LOG-IN/REGISTER </Link>
        )}
        

        <Button component={Link} to="/YourJourney" variant="contained" sx={{ p:'0.5vw', width:'8vw',ml:'.5vw',backgroundColor:'#31312C',color:'#FFFFFF',fontSize:'0.7vw',
        "&.MuiButtonBase-root:hover": {
          backgroundColor:'#31312C',color:'#FFFFFF'
          } }}>YOUR JOURNEY</Button>  
    </Box>
    </Stack>
    }

    </>
  );
  
};
export default NavbarS;