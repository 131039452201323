import React, { useEffect } from "react";
import { Box, Input, CssBaseline, CircularProgress, Typography, Button, Stack} from "@mui/material";
import { useDebounce,useDebouncedCallback } from 'use-lodash-debounce'

import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/image-66@1x.png'
import GoogleMaps from "./Googlemaps";
import { useDispatch, useSelector } from 'react-redux';
import {  borrowerChoiceE, 
          borrowerChoiceK, 
          borrowerChoiceKInit, 
          borrowerChoiceKMax, 
          borrowerfinancialProfile} from "../../actions/borrower";
import { LeftBlockMsg } from "./Messenger";
import { withCommas } from "../../constants";
import { Centerbottom, RightBlockRefinance } from "./RightBlock";
import { useWindowDimensions } from "./HelperFunctions";

function ShowPropertyRefi() {
  const { bChoiceE,
          bChoiceB,
          bfinancialProfile,
          bChoiceD, 
          isLoading } = useSelector((state)=>state.borrower);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [refiRequest,setRefiRequest] = React.useState({});
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  },[height,width]) 

  React.useEffect( ()=>{
      setRefiRequest({
        propertyPrice   : bChoiceE?.propertyPrice,
        loanAmount      : bChoiceE?.outStandingLoan,
        financeType     : bChoiceB === "firsthome" || bChoiceB === 'nexthome' ? 'primary':'secondary',
        loanTerm        : bChoiceE?.loanTerm,
        creditEvents    : bfinancialProfile?.finance.creditEvents,
        houseEvents     : bfinancialProfile?.finance.houseEvents,
        cashoutAmount   : bChoiceE?.cashoutAmount,
        mlsId           : bChoiceD[0]?.mlsId,
        creditScore     : bfinancialProfile?.income.ficoScore,
        annualIncome    : bfinancialProfile?.income.annualIncome,
        monthlyOutgoing : bfinancialProfile?.income.outgoing,
        financeDocs     : bfinancialProfile?.financeDocs.docsAll
      })

  },[bChoiceE.loanAmount,bChoiceE.propertyPrice])

  const debounceFun = useDebouncedCallback(()=>{
    dispatch(borrowerChoiceKMax(refiRequest));
  },3000)
  
  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "change": navigate("/SearchProperty", { replace: false });break;
      case "reset":
      case "cashout":
        dispatch(borrowerChoiceK(refiRequest));
        
        dispatch(borrowerChoiceKInit(refiRequest));
        navigate("/ShowPropertyRefiExp", { replace: false });
        break;
      default:break;
    }
  }

  return(
    isLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
      <React.Fragment><CssBaseline/>
      <Box mt={marginT} ml={marginL} sx={{overflow:'hidden', width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <Navbar pathname={location.pathname}/>
        <Box sx={{ display: 'flex', flexDirection: 'row', height:'90h'}}>
          { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
        
          <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center'}}>
          <div align='center' positon='relative'>
              <Typography sx={{color:'#000000',mt:'13.5vw',fontSize:'1.4vw',font:'Roboto', textAlign:'center'}}> 
                My Refinance objectives </Typography>    
            <Stack sx={{mt:'3.43vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
            <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="cashout" className='hexBtnCenter'
                      onClick={onClickFun}> 
                      <HexButton titleText="Cash-out" 
                      type='base' fontSize='24'/>
                  </Button>
              </Stack>
              <Stack sx={{mt:'2.25vw',height:'3.5vw',width:'12vw'}} spacing={0} direction="row" justifyContent="space-around">
              <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="reset" className='hexBtnCenter'
                     onClick={onClickFun}> 
                      <HexButton  titleText="Rate reset" 
                      type='base' fontSize='24'/>
                  </Button>
                  
                  <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}}id="change" className='hexBtnCenter'
                    onClick={onClickFun}> 
                    <HexButton titleText="Change property" 
                    type='base' fontSize='24'/>
                  </Button>
              </Stack>
                <Centerbottom navigateForward="PropertyType" />
              </div>
        </Box>
        <RightBlockRefinance/>
      </Box>
    </Box>
    </React.Fragment>
  ));
}

function RightBlock() {
  const {bChoiceC,bChoiceD} = (useSelector((state)=>state.borrower));
      return (
        <React.Fragment><CssBaseline/>
          <Box sx={{background: '#31312C 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',p:1,opacity: 1, width: '50vw',height:'46.75vw' }}>
              <Box mt='4vw'backgroundColor='#FFFFFF' borderRadius='0vw'> 
                <GoogleMaps position={{ lat:parseFloat(bChoiceC.position.latitude), 
                                        lng: parseFloat(bChoiceC.position.longiitude)}}
                                        markerInput={bChoiceD} wHeight='33.75vw'/>
              <Box sx={{zindex:1,mt:'-40vh',p:'2vw 4vw',backgroundColor:'#FFFFFF'}}>
                  <Typography fontSize='1.4vw' color='black' align='center'> {bChoiceD[0]?.address}</Typography>
                  <Typography fontSize='1.4vw' color='black' align='center'> {bChoiceD[0]?.city}, {bChoiceD[0]?.state} - {bChoiceD[0]?.zipcode}</Typography>
              </Box>
            </Box>
          </Box>
        </React.Fragment>
      );
}

function LeftBlock() {
  const dispatch = useDispatch();
  const { bChoiceE,
          bChoiceD,
          bfinancialProfile,
          isLoading}= (useSelector((state)=>state.borrower));
  const [outstandingLoan,setOutstandingLoan] = React.useState(0)
  const [annualIncome,setAnnualIncome] = React.useState(bfinancialProfile.income.annualIncome);
  const [propertyValue,setPropertyValue] = React.useState(0);

  useEffect(()=>{
    if(bChoiceD.length === 0 ) console.log("B undefined")
    else {
      console.log("DDD",bChoiceD)
      setOutstandingLoan(0.8*parseInt(bChoiceD[0].price));
      setPropertyValue(parseInt(bChoiceD[0].price));
      dispatch(borrowerChoiceE({...bChoiceE,
                                outStandingLoan:0.8*parseInt(bChoiceD[0].price), 
                                propertyPrice:parseInt(bChoiceD[0].price)}));
      }
  },[bChoiceD])
  
  
  function onClickFun(e) {
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "AnnualIncome":
        var input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
        setAnnualIncome(isNaN(input) ? 0 : input)
        dispatch(borrowerfinancialProfile({...bfinancialProfile,
                                            annualIncome:annualIncome}));
        break;
      case "Outstanding":
        var input1 = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
        setOutstandingLoan(isNaN(input1) ? 0 : input1)
        dispatch(borrowerChoiceE({...bChoiceE,
                                  outStandingLoan:input1 <=0}));
        break;
      case "PropValue":
        var input2 = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
        setPropertyValue(isNaN(input2) ? 0 : input2)
        dispatch(borrowerChoiceE({...bChoiceE,
                                  propertyPrice:input2<0?0:input2}));
          break;
      default:
        break;
      }
  }
  return (
    isLoading ? <CircularProgress /> : (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
        <Box sx={{ mt:'8.4vw' , ml: '8.3vw', width: '27vw',height:'23.6vw' }}> 
            <Typography sx={{color:'#31312C', width:'25vw',
              fontSize:'2.2vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.1,letterSpacing: '-.1vw'}}> 
              Refinancing {bChoiceD[0].address.substring(0,12)}... 
          </Typography>
          <Typography sx={{color:'#31312C', width:'25vw',
              fontSize:'2.2vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.1,letterSpacing: '-.1vw'}}> 
              update your economic circumstances
          </Typography>
          <Stack sx={{mt:6}}spacing={2} direction="row" alignItems="center" justifyContent="space-between"> 
               <Typography fontSize='1.35vw' color='#000000'>Outstanding Loan </Typography>
               <Box sx={{width:'15vw',border:1,borderRadius:2}}>
               <Input id="Outstanding" variant="outlined" value={withCommas(Math.round(outstandingLoan))} sx={{ml:'4vw', border:0, color:"#000000",fontSize:'1.2vw', width:"10vw"}} 
                  onChange={onClickFun}InputProps={{disableUnderline:true }}/>
                  </Box>
          </Stack>
          <Stack sx={{mt:2}}spacing={2} direction="row" alignItems="center" justifyContent="space-between"> 
                  <Typography fontSize='1.35vw' color='#000000'>Property Value </Typography>
                  <Box sx={{width:'15vw',border:1,borderRadius:2}}>
                 <Input id="PropValue" variant="outlined" value={withCommas(Math.round(propertyValue))} sx={{ml:'4vw', border:0, color:"#000000",fontSize:'1.2vw', width:"10vw"}} 
                  onChange={onClickFun}InputProps={{disableUnderline:true }}/>
                  </Box>
          </Stack>
          <Stack sx={{mt:2}}spacing={2} direction="row" alignItems="center" justifyContent="space-between">
            <Typography fontSize='1.35vw' color='#000000'>Annual Income </Typography>
            <Box sx={{width:'15vw',border:1,borderRadius:2}}>
            <Input id="AnnualIncome" variant="outlined" value={withCommas(Math.round(annualIncome))} sx={{ml:'4vw', border:0, color:"#000000",fontSize:'1.2vw', width:"10vw"}} 
                  onChange={onClickFun}InputProps={{disableUnderline:true }}/>
            </Box>
          </Stack>
          
          <Typography sx={{mt:'3vw'}} fontSize='1.72vw' color='#000000'>Provide details to improve your</Typography>
          <Typography fontSize='1.72vw' color='#000000'>refinancing power estimate</Typography>
      </Box>
      <Box sx={{bottom:'0',height:'10.8vw',width: 'inherit',position:'absolute' }}>  
           <img src={leftbottom} alt = "Loading..." width="99%" height="100%"/> 
      </Box>
      
      </Box>
  ));
}



export default ShowPropertyRefi ;