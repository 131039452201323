import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button} from "@mui/material";

import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';

import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/bg-10@1x.png'
import {  useDispatch, 
          useSelector } from 'react-redux';
import { calcPropfinOptions, calcPropfinOptionsA} from "../../actions/borrower";
import { Centerbottom, LeftbottomA, RightBlockSPBP, RightBlockSPBPRate, RightBlockSPBPE } from "./RightBlock";
import { withCommas } from "../../constants";
import { LeftBlockMsg } from "./Messenger";

const DebugOutput = true;

export function ShowPurchaseSelfEmp() {
  const { bChoiceD,
          selectedProp,
          bfinancialProfile,
          isLoading,
          bChoiceE,
          bChoiceB } = useSelector((state)=>state.borrower);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [comp,setComp] = React.useState(0)

  React.useEffect(()=>{
    if(comp ===0){
      dispatch(calcPropfinOptions(bChoiceD,bfinancialProfile,bChoiceB))
      setComp(-1)
    } 
    if(!isLoading && comp === 1){
      dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile))
      setComp(2)
      console.log("123")
    }
    if(!isLoading && comp === 2){
      setComp(-1)
      navigate("/SmartChoice", { replace: false })
    }
  },[isLoading,comp])
  
  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "selectPr": setComp(1); break;
      case "changeLo": 
        navigate("/SearchProperty", { replace: false });
        break;
      case "changePr":
        navigate("/BorrowerProfileEmp", { replace: false });
        break;
      default:break;
    }
  }

  var centerButtonCss = `svg.base text#textbutton { font-weight:500 !important; }
    svg.address text#textbutton { font-weight:500 !important; }`;

  return(
    isLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
      <React.Fragment>
            <CssBaseline/>
            <Box sx={{ overflow:'hidden', width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
              <Navbar pathname={location.pathname}/>
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
              { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
              
                <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
                <Typography sx={{color:'#31312C',mt:'23.42vh',fontSize:'1.15vw', textAlign:'center'}}> 
              Select Property
            </Typography>
              <div align='center'>
                <style>
                  {centerButtonCss}
                </style>
                <Stack sx={{ mt: '3vh', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="selectPr" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText={selectedProp.prop.address.substr(0, 10)} type='address' fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="changePr" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Change Profile" type='base' fontSize='24' /></Button>
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="changeLo" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Change location" type='base' fontSize='24' /></Button>
                </Stack>
                <Centerbottom navigateForward="ShowPurchaseSelfEmp" />
              </div>
          </Box>
        {/* <RightBlockSPBPRate rbops='ebpr'/> */}
        <RightBlockSPBPE rbops='abs_N'/>
      </Box>
    </Box>
    </React.Fragment>
  ));
}

function LeftBlock () {

  const dispatch = useDispatch();
  const { bChoiceE,
          bfinancialProfile,
          bChoiceDSFB,
          maxMortgage,
        } = useSelector((state)=>state.borrower);
  const maxA = Math.ceil(bChoiceDSFB?.tradAfford?.propPrice)
  const maxB = Math.ceil(bChoiceDSFB?.extendedAfford?.propPrice)
  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
        <Box sx={{ mt:'8.2vw' , ml: '7.3vw', width: '24.1vw',height:'10vw' }}> 
            <Typography sx={{color:'#31312C', width:'24.1vw',
              fontSize:'2.5vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '-0.12vw'}}> 
                Your Purchasing Power
            </Typography>
            <Typography sx={{color:'#31312C',width:'20vw', mt:'1.8vh',
              fontSize:'5.2vw', fontFamily:'Roboto',fontWeight:400,textAlign:'justify',lineHeight:1.3,letterSpacing: '-0.25vw'}}> 
               {withCommas(maxA)}
            </Typography>
        </Box>
        <Box sx={{ mt:'1.7vw' , ml: '7.3vw', width: '28vw',height:'14vw' }}>  
        <Typography sx={{color:'#31312C',width:'24vw',
              fontSize:'1.67vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
                based on your economic profile & traditional finance
            </Typography>
            <Typography sx={{color:'#31312C',width:'28vw', mt:'1.7vw',
              fontSize:'1.72vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
                Enhance your purchasing power with same monthly
            </Typography>
        </Box>

        <LeftbottomA leftbottom={leftbottom}/>
    </Box>
  );
}

export default ShowPurchaseSelfEmp