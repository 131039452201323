import React from "react";
import {Box, CssBaseline, Stack, Typography, Button, CircularProgress} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import paymentImageSvg from '../../assets/svg/assets svg/contract-loan-svgrepo-com.svg'
import { useDispatch, useSelector } from 'react-redux';
import { borrowerfinancialProfile, borrowerProfileStatus, calcPropfinOptionsA, calcPropfinOptionsEmpA} from "../../actions/borrower";
import leftbottom from '../../assets/images/bg-10@1x.png'
import RightBlockPropConf, { Centerbottom, LeftbottomA, RightBlockSPBP } from './RightBlock'
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { compareFinance } from "../../api";
import { IS_PROFILE_INFO_CHANGED, UPDATE_PROFILEJOURNEY } from "../../constants";
import { rspvw } from "./styles";
function Bankruptcy() {
  const { bfinancialProfile,
          bProfileJourney,
          isLoading,
          bProfileStatus,
          bChoiceD,bChoiceB,
          bChoiceE
        } = useSelector((state)=>state.borrower);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [bankrupStatus, SetBankrupStatus] = React.useState(bfinancialProfile.income.bankruptcy);
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 

  function onClickFun(e){
      e.preventDefault();
      const buttonId = e.currentTarget.id;
      var bankruptcy='';
      switch(buttonId) {
        case "Never": 
        bankruptcy= "Never"; 
        dispatch(borrowerProfileStatus({...bProfileStatus,bankruptcyProvided:1}))
        const income_1 = {...bfinancialProfile.income, bankruptcy:bankruptcy};
        const finance_1 = {...bfinancialProfile.finance, creditEvents:0};
        dispatch(borrowerfinancialProfile({...bfinancialProfile, finance:finance_1,income:income_1 }));
        dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, creditCreditEvents : true} });
        dispatch({type: IS_PROFILE_INFO_CHANGED, payload:true})
        

          navigate(-1);
        break;
        case "Past2": 
        bankruptcy= "Past2Years";
        dispatch(borrowerProfileStatus({...bProfileStatus,bankruptcyProvided:1}))
        dispatch({type: IS_PROFILE_INFO_CHANGED, payload:true})
      const income_2 = {...bfinancialProfile.income, bankruptcy:bankruptcy};
      dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income_2}));
      const finance_2  = {...bfinancialProfile.finance, creditEvents:24};
      dispatch(borrowerfinancialProfile({...bfinancialProfile, finance:finance_2 ,income:income_2}));
      dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, creditCreditEvents : true} });
      

      navigate(-1);
        break;
        case "Past4":
           bankruptcy= "Past4Years";
           dispatch(borrowerProfileStatus({...bProfileStatus,bankruptcyProvided:1}))
         const income_3= {...bfinancialProfile.income, bankruptcy:bankruptcy};
           const finance_3 = {...bfinancialProfile.finance, creditEvents:48};
           dispatch(borrowerfinancialProfile({...bfinancialProfile, finance:finance_3,income:income_3 }));
           dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, creditCreditEvents : true} });
           dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});

          

             navigate(-1);
           break;
        case "Morethan4": 
        bankruptcy= "MoreThan4YearsBack"; 
        dispatch(borrowerProfileStatus({...bProfileStatus,bankruptcyProvided:1}))
        const income_4 = {...bfinancialProfile.income, bankruptcy:bankruptcy};
        const finance_4 = {...bfinancialProfile.finance, creditEvents:0};
        dispatch(borrowerfinancialProfile({...bfinancialProfile, finance:finance_4,income:income_4 }));
        dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, creditCreditEvents : true} });
        dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});

          navigate(-1);
        

        break;
        default:break;
      }
  
      
  }
  
  return(
    isLoading ? <CircularProgress color='#616161' size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
    <React.Fragment><CssBaseline/>
            <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden', width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
              <Navbar pathname={location.pathname}/>
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1 }}>
                { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}

                <Box sx={{ width: '14vw',height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
                <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> 
              Update Settings
            </Typography>
              <div align='center'>
                <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Never" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Never" type={bankrupStatus === 'Never' ? 'visited' : 'base'} fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Past4" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Yes 2-4Years Ago" type={bankrupStatus === 'Past4Years' ? 'visited' : 'base'} fontSize='24' /></Button>
                  <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Past2" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="In Past2 Years" type={bankrupStatus === 'Past2Years' ? 'visited' : 'base'} fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '6.5vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow:'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Morethan4" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="More Than4 YearsAgo" type={bankrupStatus === 'MoreThan4YearsBack' ? 'visited' : 'base'} fontSize='24' /></Button>
                </Stack>

                <Centerbottom navigateForward='-1' />
              </div>
            
              </Box>
            <RightBlockSPBP/>
          </Box>
        </Box>
        </React.Fragment>
  ));
}

  function LeftBlock() {
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
          <Box sx={{ ml:'7vw',mt:'30%' , width:'21vw',background:'inherit', height: '19.7vw' }}>
            <div align='center'>
                  <img src={paymentImageSvg} alt = "Loading..." heigh="100px" width='100px'/>
                  <Typography mt={5} fontSize='1.5vw' fontStyle="regular" component='div'color='#000000'> 
                      Bankruptcy Status
                  </Typography>
                  <Typography sx={{ mt:2}} fontSize='1.1vw'component="div" color='#000000' align='center'>
                      Have you ever been bankrupt?
                  </Typography>
            </div>
          </Box>
          <LeftbottomA leftbottom={leftbottom}/>
      </Box>
    );
  }

  
export default Bankruptcy;