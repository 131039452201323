import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button, Input} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/bg-10@1x.png'
import {  useDispatch, 
          useSelector } from 'react-redux';
import { Centerbottom, LeftbottomA} from "./RightBlock";
import { RightBlockCompare} from "./RightBlockCompare";
import { UPDATE_D, UPDATE_PROFILEJOURNEY, withCommas, _debug } from "../../constants";
import { calcPropfinOptions } from "../../actions/borrower";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";

export function UpdateOfferPrice() {
   const { bChoiceD,
          bChoiceB,
          isLoading,
          bfinancialProfile,
          bProfileJourney,
          selectedProp,
          proposedProp,
        } = useSelector((state)=>state.borrower);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    
    const { height, width } = useWindowDimensions();
    const [marginT,setMarginT] = React.useState('0px')
    const [marginL,setMarginL] = React.useState('0px')
    const [comp,setComp] = React.useState(0)
console.log(location,'here is update offer price')
    React.useEffect(()=>{
        const calc1 = Math.ceil(height*0.5 - 0.28125*width)
        const calc2 = Math.ceil(width*0.5 - 1.78*height)
        setMarginT(String(calc1 >=0?calc1:0).concat('px'))
        setMarginL(String(calc2 >=0?calc2:0).concat('px'))
    },[height,width]) 

    React.useEffect(()=>{
      if(comp === 1){
        dispatch(calcPropfinOptions(bChoiceD,bfinancialProfile,bChoiceB))
        setComp(2)
      }
      if(!isLoading && comp === 2){
        setComp(-1)
        navigate(-1);
      }
    },[isLoading,comp])

    function validateBtn(){
      if((bChoiceD[selectedProp.propId].offerPrice) >= (bChoiceD[selectedProp.propId].price * 80 / 100)){
        soffer = 1
      }
      if((bChoiceD[proposedProp.propId].offerPrice) >= (bChoiceD[proposedProp.propId].price * 80 / 100)){
        poffer = 1
      }
      return (soffer === poffer) ? true : false
    }
    
    function onClickFun(e){
      e.preventDefault();
      const buttonId = e.currentTarget.id;
      switch(buttonId) {
      case "submit": 
      dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, propertySubmitOffer: true, propertyShortlisted:true} });
      setComp(1)
          break;
      default:
        break;
      }
    }
    
    return(
    isLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
    <React.Fragment> <CssBaseline/>
         <Box mt={marginT} ml={marginL}  sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
          <Navbar pathname={location.pathname}/>
          <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
          { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlockA/>}
            <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
            <Typography sx={{color:'#31312C',mt:'11.3vw',fontSize:'1.4vw', textAlign:'center'}}> 
            Select Financing</Typography>
                <div align='center'>
                  <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="submit" className='hexBtnCenter'
                      onClick={onClickFun} >
                      <HexButton titleText="Submit" type='base' fontSize='24' /></Button>
                  </Stack>
                  <Centerbottom />
                </div>
          
        </Box>
        <RightBlockCompare rbops = 'smartChoice' info={location.state.info}/>
      </Box>
      </Box>
      </React.Fragment>
    ));
}

function LeftBlockA () {

  const dispatch = useDispatch();
  const { selectedProp,
          proposedProp,
          bProfileJourney,
          bChoiceD
        } = useSelector((state)=>state.borrower);

  const [soffer,setSoffer] = React.useState(isNaN(bChoiceD[selectedProp.propId].offerPrice) ? 0 : bChoiceD[selectedProp.propId].offerPrice)
  const [poffer,setPoffer] = React.useState(isNaN(bChoiceD[proposedProp.propId].offerPrice) ? 0 : bChoiceD[proposedProp.propId].offerPrice)
  
  const [sofferErr, setSofferErr] = React.useState(false);
  const [pofferErr, setPofferErr] = React.useState(false);

  React.useEffect(()=>{
    setPoffer(isNaN(bChoiceD[proposedProp.propId].offerPrice) ? 0 : bChoiceD[proposedProp.propId].offerPrice)
  },[proposedProp.propId])

  React.useEffect(()=>{
    setSoffer(isNaN(bChoiceD[selectedProp.propId].offerPrice) ? 0 : bChoiceD[selectedProp.propId].offerPrice)
  },[selectedProp.propId])

  const selectedProperty = bChoiceD

  function onClickFun(e) {
    const buttonId = e.currentTarget.id;
    const input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
    switch(buttonId) {
      case "soffer":
        setSoffer(isNaN(input) ? 0 : input)
        if(input >= (bChoiceD[selectedProp.propId].price * 80 / 100)){
          selectedProperty[selectedProp.propId].offerPrice =  input
          dispatch({ type: UPDATE_D, payload: selectedProperty });
          dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, propertySubmitOffer: true, propertyShortlisted:true} });
          setSofferErr(false);
        }else{
          setSofferErr(true);
        }
        break;
        case "poffer":
          setPoffer(isNaN(input) ? 0 : input)
          if(input >= (bChoiceD[proposedProp.propId].price * 80 / 100)){
            selectedProperty[proposedProp.propId].offerPrice =  input
            dispatch({ type: UPDATE_D, payload: selectedProperty });
            dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, propertySubmitOffer: true, propertyShortlisted:true} });
            setPofferErr(false);
          }else{
            setPofferErr(true);
          }
          
          break;
      default:
        break;
      }
  }

  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '46.75vw', position: 'relative'
    }}>
      <Box sx={{ mt: '4.2vw', ml: '7.3vw', width: '26vw', height: '10vw' }}>
        <Typography sx={{
          color: '#31312C', width: '26vw',
          fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          Update offer price
        </Typography>
        <Typography sx={{
          color: '#31312C', width: '26vw', mt: '2vw',
          fontSize: '2.0vw', fontFamily: 'Roboto', fontWeight: 700, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          {selectedProp.prop.address} &nbsp;  {selectedProp.prop.city}
        </Typography>
        <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.4vw', width: '12vw', align: 'left' }} color='#000000'>List Price</Typography>
          <Typography sx={{ mt: 1, fontSize: '1.4vw', width: '12vw' }} fontWeight={700} color='#000000' align='center'>{withCommas(bChoiceD[selectedProp.propId].price)}</Typography>
        </Stack>
        <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.4vw' }} color='#000000'>Offer Price</Typography>
          <Input disableUnderline={true} id="soffer" value={withCommas(soffer)} sx={{ backgroundColor: '#FFFFFF', color: "#8F8F8F", fontSize: '1.1vw', height: '2.5vw', width: "13vw", border: 1, borderRadius: '0.5vw' }}
            inputProps={{ style: { textAlign: 'center' } }} onChange={onClickFun} />
        </Stack>
        {
          sofferErr 
          ? 
            <>
            <Stack sx={{ }} spacing={0} direction="row" justifyContent="space-between">
              <Typography sx={{ fontSize: '0.7vw' }} color='#000000'>&nbsp;</Typography>
              <Typography sx={{ fontSize: '0.7vw' }} color='#B83B46'>Minimum {withCommas(bChoiceD[selectedProp.propId].price * 80 / 100)}</Typography>
            </Stack>
            </>
          : ''
        }
        
        {
          selectedProp.prop.mlsId === proposedProp.prop.mlsId
          ? ''
          : 
          <>
            <Typography sx={{
              color: '#31312C', width: '26vw', mt: '2vw',
              fontSize: '2.0vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
            }}>
              {proposedProp.prop.address} &nbsp;  {proposedProp.prop.city}
            </Typography>
            <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
              <Typography sx={{ mt: 1, fontSize: '1.4vw', width: '12vw', align: 'left' }} color='#000000'>List Price</Typography>
              <Typography sx={{ mt: 1, fontSize: '1.4vw', width: '12vw' }} fontWeight={700} color='#000000' align='center'>{withCommas(bChoiceD[proposedProp.propId].price)}</Typography>
            </Stack>
            <Stack sx={{ mt: 1 }} spacing={0} direction="row" justifyContent="space-between">
              <Typography sx={{ mt: 1, fontSize: '1.4vw' }} color='#000000'>Offer Price</Typography>
              <Input disableUnderline={true} id="poffer" value={withCommas(poffer)} sx={{ backgroundColor: '#FFFFFF', color: "#8F8F8F", fontSize: '1.1vw', height: '2.5vw', width: "13vw", border: 1, borderRadius: '0.5vw' }}
                inputProps={{ style: { textAlign: 'center' } }} onChange={onClickFun} />
            </Stack>
            {
              pofferErr
              ? 
                <>
                  <Stack sx={{ }} spacing={0} direction="row" justifyContent="space-between">
                    <Typography sx={{ fontSize: '0.7vw' }} color='#000000'>&nbsp;</Typography>
                    <Typography sx={{ fontSize: '0.7vw' }} color='#B83B46'>Minimum {withCommas(bChoiceD[proposedProp.propId].price * 80 / 100)}</Typography>
                  </Stack>
                </>
              : ''
            }
            
          </>
        }
        
      </Box>
      <LeftbottomA leftbottom={leftbottom} />
    </Box>
  );
}

export default UpdateOfferPrice