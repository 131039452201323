import * as React from 'react';
import { useRef, useState } from 'react';
import {  useLocation, useNavigate } from 'react-router-dom';
import nestfuelImage from '../../assets/svg/nestfuel_nav.svg';
import useStyles from './styles';
import { initializeApp, getApp } from 'firebase/app';
import { getAuth,signOut } from "firebase/auth";
import { updateFlag,updateEmployer, borrowerProfile, borrowerGetUserData,breadcrumbData} from "../../actions/borrower";
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT,LOGGEDIN, ISSIGNIN, _debug, LOAD_STATE } from '../../constants';
import { styled, alpha } from '@mui/material/styles';
import {  makeStyles } from '@mui/styles';
import InputBase from '@mui/material/InputBase';
const pages = ['Home', 'Properties', 'Why US', 'SignUP', 'Download App'];
import { getAnalytics,logEvent } from "firebase/analytics";
import ReactSearchBox from "react-search-box";
import SearchIcon from '@mui/icons-material/Search';
//import CountryIcon from '@mui/icons-material/CountryFlag';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import avatar_boy from '../../assets/svg/avatar-boy.png'
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Typography,Badge
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import { makeStyles } from '@material-ui/core/styles';

import expand_more from '../../assets/svg/expand_more.svg'
import icon_search from '../../assets/svg/icon_search.svg'
import notificationIcon from '../../assets/svg/notification.svg'
import us_Flag from '../../assets/svg/us-flag.png'
import flag from '../../assets/flag.png'
import { rspvw } from "./styles";

const firebaseConfig = _debug === 'development' 
    ?{
      apiKey            : process.env.REACT_APP_GOOGLE_AUTH_API_KEY_UAT,
      authDomain        : process.env.REACT_APP_GOOGLE_AUTH_DOMAIN_UAT,
      projectId         : process.env.REACT_APP_GOOGLE_AUTH_PROJECTID_UAT,
      storageBucket     : process.env.REACT_APP_GOOGLE_AUTH_BUCKET_UAT,
      messagingSenderId : process.env.REACT_APP_GOOGLE_AUTH_MSG_SNDR_ID_UAT,
      appId             : process.env.REACT_APP_GOOGLE_AUTH_APP_ID_UAT,
      measurementId     : process.env.REACT_APP_GOOGLE_AUTH_MSRMT_ID_UAT
    }
    :{
        apiKey            : process.env.REACT_APP_GOOGLE_AUTH_API_KEY,
        authDomain        : process.env.REACT_APP_GOOGLE_AUTH_DOMAIN,
        projectId         : process.env.REACT_APP_GOOGLE_AUTH_PROJECTID,
        storageBucket     : process.env.REACT_APP_GOOGLE_AUTH_BUCKET,
        messagingSenderId : process.env.REACT_APP_GOOGLE_AUTH_MSG_SNDR_ID,
        appId             : process.env.REACT_APP_GOOGLE_AUTH_APP_ID,
        measurementId     : process.env.REACT_APP_GOOGLE_AUTH_MSRMT_ID
    }  
    
let app
try{ app = getApp()}catch(error){ app = initializeApp(firebaseConfig)}
const auth = getAuth(app)
const analytics = getAnalytics(app);



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const useStylesHover = makeStyles(() => ({
  chipRoot: {
    "& .appear-item": {
      display: "none"
    },
    "&:hover .appear-item": {
      display: "block"
    }
  }
}))

const useStyless = makeStyles({
  chipRoot: {
    marginBottom: 16,
    "&:hover .MuiAvatar-root": {
        color: "orange",
      },
    "& .MuiAvatar-root:hover": {
      color: "red",
    }
  }
});


const useStylesN = makeStyles({
  stackRoot: {
    border: "1px solid gray",

    "& a:hover": {
      color: "red",
    },
  }
});


export const NavBarBreadCrumbUpdate = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickRes = (event) => {
    //setAnchorEl(event.currentTarget);
   // event.preventDefault();
 // navigate("/EmployerResources", { replace: false }) 
  navigate("/EmployerContract", { replace: false }) 
   console.info('You clicked a resource menu.');
  };

  
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
};
  const { bProfile,isLoggedin, navFlag,navEmployer , breadcrumbData} = useSelector((state)=>state.borrower);  
  const [user, setUser] = React.useState(getAuth().currentUser)
  //const [user,setUser] = React.useState(auth.currentUser);
  //if(_debug === 'development') console.log("Firebase analytics",analytics)
  //if(_debug === 'development') console.log("Firebase Auth",auth)
  logEvent(analytics, 'notification_received');
  !isLoggedin && user && dispatch({ type: LOGGEDIN })
  const location = useLocation();
  const classes = useStyles();
  const classesn = useStylesN();
  const navigate = useNavigate();
  const anchorRef = useRef(null);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickTeam=(event)=> {
    event.preventDefault();
    navigate("/EmployerTeam", { replace: false }) 
    console.info('You clicked a breadcrumb.');
  }
  React.useEffect(() => {
    if(auth.currentUser !==null){
      setUser(auth.currentUser)
      console.log("user",user)
      var displayName = auth.currentUser?.displayName?.indexOf(' ') >=0 ? auth.currentUser?.displayName?.split(" ") : [auth.currentUser?.displayName,'']
      if(displayName.length >=2) dispatch(borrowerProfile({...bProfile,firstName:displayName[0],lastName:displayName[1],emailId:auth.currentUser.email,userId:auth.currentUser.uid,photoURL:auth.currentUser.photoURL})); 
      if(displayName.length ===1) dispatch(borrowerProfile({...bProfile,firstName:displayName[0],lastName:"",emailId:auth.currentUser.email,userId:auth.currentUser.uid,photoURL:auth.currentUser.photoURL})); 
    }else{
      setUser(null)
    }
  }, [auth.currentUser]);

  const breadcrumbs = [
   <Box className={classes.breadCrumbMenuBr}>
  
   <Link underline="hover" key="1" color="inherit" className='yourteam' href="#" onClick={e => e.preventDefault()}>
      Employer login
    </Link>
    
    </Box>,
    <Box className={classes.breadCrumbMenuBr}> <Link
      underline="hover"
      key="2"
      color="inherit"
      className='yourteam'
      href="#"
      onClick={e => e.preventDefault()}
    >
      {breadcrumbData}
    </Link>
    </Box>
   
  ];

  
  


  return (
    <>
    {
   
<Stack spacing={0} direction="row" justifyContent="space-between" backgroundColor="#FFFFFF" sx={{width:'100%',mb:`${rspvw(20.5)}`}}>
<Box sx={{width:`${rspvw(960)}`, height:`${rspvw(86.4)}`, ml:`${rspvw(141)}`, mt:`${rspvw(0.7)}`}} className={classes.appBar} >        
<Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
          {breadcrumbs}
      </Breadcrumbs>           
</Box>

<Box sx={{width:`${rspvw(768)}`, height:`${rspvw(43.7)}`,mt:`${rspvw(29.5)}`,mr:`${rspvw(167.2)}`}} className={classes.appBar}>
<Stack sx={{mt:'0vw',ml:'0.0vw'}}  spacing='1vw' direction="row"> 
<Box sx={{width:`${rspvw(518.4)}`}}>
 <Search
           sx={{  
            borderRadius: 7,
            width:'8vw',
            height:'2.5vw',
            borderColor: 'Red',
            backgroundColor:"#F0F0F0",
            ml:'10vw'
             }}>
            <SearchIconWrapper>
              {/* <SearchIcon /> */}
              <img src={icon_search} sx={{ width: 32, height: 32 }}></img>
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search transactions,invoices or help"
              inputProps={{ 'aria-label': 'search' }}
              sx={{mr:'7vw'}}
             
           />
          </Search>
</Box>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
                {/* <img src={notificationIcon} height='100%'></img> */}
              </Badge>
            </IconButton>
            <Box>
            <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
            <Avatar alt="profile user" src={avatar_boy} sx={{ width: 32, height: 32,ml:'0vw' }} />
     
<Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon/>}
        sx={{ fontWeight: 'Medium', font: 'Roboto', textTransform:'none',ml:'0vw', fontSize:'0.83vw', color:'#30302B', width:'3vw',height:'1.93vw'}}
      >
     Scott
      </Button> 
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <EditIcon />
          Scott@gmail.com
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <FileCopyIcon />
          View
        </MenuItem>
        {/* <Divider sx={{ my: 0.5 }} /> */}
        {/* <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Archive
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <MoreHorizIcon />
          More
        </MenuItem> */}
      </StyledMenu>
      </Stack>
    </Box>



</Stack>
</Box>

</Stack>

    }
    
    </>
  );
  
};

// export function Backtohistory() {
//   //const navigate = useNavigate();
//   console.log('remove_hash_from_url - clicked')
// 	var uri = window.location.toString();
// 	if (uri.indexOf("#messenger") > 0) {
// 		var clean_uri = uri.substring(0, uri.indexOf("#messenger"));
//     console.log(clean_uri)
// 		//navigate(clean_uri, { replace: false });
// 	}
// }

export default NavBarBreadCrumbUpdate;
