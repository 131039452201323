import React from "react";
import { Box, CssBaseline, Input, CircularProgress,  Typography, Button, Stack} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import bedicon from '../../assets/svg/bed-svgrepo-com.svg';
import bathicon from '../../assets/svg/shower-head-svgrepo-com.svg'
import garageicon from '../../assets/svg/garage-svgrepo-com.svg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Card, ListGroup, ListGroupItem} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import ItemsCarousel from "react-items-carousel";
import leftbottom from '../../assets/images/bg-10@1x.png'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GoogleMaps from './Googlemaps';
import {  borrowerChoiceD3, 
          borrowerChoiceI, 
          borrowerProfile, 
          borrowerProfileNew } from "../../actions/borrower";
import { BedsAndBaths, Centerbottom, LeftbottomA } from "./RightBlock";
import { PROPROP, withCommas } from "../../constants";
import { LeftBlockMsg } from "./Messenger";

import leftArrow from '../../assets/svg/left-arrow.svg'
import rightArrow from '../../assets/svg/right-arrow.svg'

const DebugOutput = true;



function BiggerAndBetter() {
  const { selectedProp,
          proposedProp,
          isLoading,
          bfinancialProfile,
          bChoiceB,bChoiceE,} = useSelector((state)=>state.borrower);
  var propFinRequest;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  function onClickFun(e){
    e.preventDefault();
    setTimeout(500);
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "LowerPayments":
          dispatch({type: PROPROP, payload:selectedProp});
          propFinRequest = {
            selectedPropertyPrice   : selectedProp.prop.offerPrice,
            proposedPropertyPrice   : proposedProp.prop.offerPrice,
            financeType             : bChoiceB === 'firsthome' || bChoiceB === 'nexthome' ? 'primary' :(bChoiceB === 'investment')? 'investment' : 'secondary',
            loanTerm                : bChoiceE.loanTerm,
            creditScore             : bfinancialProfile.ficoScore,
            annualIncome            : bfinancialProfile.annualIncome,
            upfront                 : bChoiceE.upfront,
            financeDocs             : bfinancialProfile.financeDocs.docsAll,
            creditEvents            : bChoiceE.creditEvents,
            houseEvents             : bChoiceE.creditEvents,
            monthlyOutgoing         : bfinancialProfile.monthlyOutgoing,
            monthlyBudget           : bChoiceE.monthlyBudget,
            emailId                 : 'amitkd4@gmail.com'
          }
          dispatch(borrowerChoiceI(propFinRequest));  
          navigate("/CompareFinance", { replace: false });
          break;
      case "BiggerAndBetter":
        propFinRequest = {
          mlsId                   : selectedProp.prop.mlsId,
          financeType             : bChoiceB === 'firsthome' || bChoiceB === 'nexthome' ? 'primary':(bChoiceB === 'investment')? 'investment' : 'secondary',
          loanTerm                : bChoiceE.loanTerm,
          creditScore             : bfinancialProfile.ficoScore,
          annualIncome            : bfinancialProfile.annualIncome,
          upfront                 : bChoiceE.upfront,
          financeDocs             : bfinancialProfile.financeDocs.docsAll,
          creditEvents            : bChoiceE.creditEvents,
          houseEvents             : bChoiceE.creditEvents,
          monthlyOutgoing         : bfinancialProfile.monthlyOutgoing,
          monthlyBudget           : bChoiceE.monthlyBudget,
        }
        console.log(propFinRequest);
         // dispatch(borrowerChoiceD3(propFinRequest));
          navigate("/DesignFinanceBB", { replace: false });
          break;
      case "ApplyNow":
        navigate("/SummaryApplication", { replace: false });
        break;
      default:
        break;
    }
  }

  return(
    isLoading ? <CircularProgress /> : (
      <React.Fragment>
          <CssBaseline/>
          <Box sx={{ width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
            <Navbar pathname={location.pathname}/>
            <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
              { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlockA/>}
            
              <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> 
              ...</Typography>
              <div align='center'>
            <Stack sx={{mt:'4vh',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
              <Button sx={{minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="ApplyNow"  className='hexBtnCenter'
             onClick={onClickFun}> 
              <HexButton titleText="Apply Now" type='applynow' fontSize='24'/></Button>
            </Stack>
            <Stack sx={{mt:'2.25vw',height:'3.5vw',width:'12vw'}}  direction="row" justifyContent="space-around">
              <Button sx={{minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="LowerPayments" className='hexBtnCenter'
              onClick={onClickFun}> 
              <HexButton titleText="Lower Payments" type='lowerpayments' fontSize='24'/></Button>
              <Button sx={{minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="BiggerAndBetter" className='hexBtnCenter'
             onClick={onClickFun}> 
              <HexButton titleText="Bigger & Better" type='biggerbetter' fontSize='24'/></Button>
            </Stack>
            <Centerbottom navigateForward="DesignFinanceBB"/>
            </div>
            
          </Box>
          <RightBlock/>
        </Box>
        </Box>
        </React.Fragment>
      ));
}

function RightBlock() {
      const { selectedProp,
              bChoiceH,
              bfinancialProfile
            } = useSelector((state)=>state.borrower);
      const [showMap,setShowMap] = React.useState(-1)
      const dispatch = useDispatch();
      return (
          <React.Fragment>
          <CssBaseline/>
          <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',opacity: 1, width: '50vw',height:'46.75vw' }}>
            <Box m={1.2} sx={{background:'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "99%",height:'18vw' ,border:0, borderRadius:'0.5vw'}}>
                {showMap === 5  
                    ?<GoogleMaps position={{lat: selectedProp?.prop?.geoLocation.lat, lng:selectedProp?.prop?.geoLocation.lon}}
                                    markerInput={[selectedProp?.prop]} wHeight='13.5vw'/> 
                    :<InnerCarousel imageArray={selectedProp?.prop.images} numCards={2}/>
                }
                <Box sx={{ display: 'flex', flexDirection: 'row', width:'inherit', height:"4.5vw"}}> 
                <Typography mt='0.5vw' width='8vw' fontSize='1.1vw' fontWeight='bold' color='black' align='center'>{withCommas(selectedProp?.prop.offerPrice)}</Typography>
                <Box width='16vw'>
                  <Typography fontSize='0.9vw' color='black' align='center'> <Button sx={{minHeight: 0, minWidth: 0}} onClick={()=> {showMap === -1? setShowMap(5) : setShowMap(-1)}} ><LocationOnIcon sx={{color:'#000000'}}/></Button>{selectedProp?.prop.address}</Typography>
                  <Typography sx={{mt:'-1.2vh'}}fontSize='0.9vw' color='black' align='center'> {selectedProp?.prop.city}, {selectedProp?.prop.state} - {selectedProp?.prop.zipcode}</Typography>
                </Box>
                <Box sx={{background:'inherit',width:'24vw'}} >
                  <BedsAndBaths color={'black'}/>
                </Box>
            </Box>  
          </Box>
          <Box m={1.2} sx={{background:'#00657C 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "98%",height:'10.125vw' ,border:0, borderRadius:'0.5vw'}}>     
              <Typography ml='1vw'fontSize='1.67vw' fontWeight={700} color='white' align='left'> Traditional </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', width:'inherit'}}>
              <Box sx={{ml:'2vw',width:'24vw'}}>
                  <Stack mt={0.5} direction="row" justifyContent="space-between">
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> Rate </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> {bChoiceH?.interestRate}% </Typography>
                  </Stack>
                  <Stack mt={0.5} direction="row" justifyContent="space-between">
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> APR </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> {(bChoiceH?.interestRate+0.35).toFixed(3)}% </Typography>
                  </Stack>
                  <Stack mt={0.5} direction="row" justifyContent="space-between">
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> Ownership </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> 100% </Typography>
                  </Stack>
              </Box>
              <Box sx={{ml:'3vw',mr:'1vw',width:'24vw'}}>
                  <Stack mt={0.5} direction="row" justifyContent="space-between">
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> Term </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> {bChoiceH?.loanTerm} years</Typography>
                  </Stack>
                  {/* <Stack mt={0.5} direction="row" justifyContent="space-between">
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> IR Term </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> 3 years </Typography>
                  </Stack> */}
                  <Stack mt={0.5} direction="row" justifyContent="space-between">
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> Home Insurance </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> {withCommas(Math.ceil(bChoiceH?.propertyPrice/2400))} per month </Typography>
                  </Stack>
                  </Box>
              </Box>
          </Box>
          <Box sx={{ background: "#FFFFFF 0% 0% no-repeat padding-box",width:'100%', height:"21vw",boxShadow: '0px 0px 19px #0000008F'}}>
        <Typography  ml='0.5vw' fontSize='1.4vw' align='left' fontWeight='bold' color='#000000'>Facts & Features</Typography> 
        <Box sx={{display: 'flex', flexDirection: 'row', width:'inherit'}}>
          <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "50%",height:"21vw",p:1,border:0 ,borderRadius:0}}>
          <Typography  ml='0.5vw' fontSize='1.2vw' align='left' fontWeight='bold' color='#000000'>Overview</Typography> 
          <Typography  ml='0.5vw' fontSize='1.vw' align='left' fontWeight={400} color='#000000'>In 1959 when man first landed on the surface
          of moon, we predicted a colony on moons equatorial zone by next decade or two. However, we are not able to achieve anything near to that goal. This is probably our inability to find a const name a new type 
          of force that can propel space crafts with enormouse speed.</Typography> 
          </Box>
          <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "50%",height:"21vw",p:2,border:0 ,borderRadius:0}}>
          
          <Typography  ml='0.5vw' fontSize='1.4vw' align='left' fontWeight='bold' color='#000000'>Parking</Typography> 
          <Stack direction="row" justifyContent="space-around"> 
                  <Typography  fontSize='1vw' color='#000000' width='8vw'textAlign='left'>Total Spaces: </Typography>  
                  <Typography  fontSize='1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>2 </Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1vw' color='#000000' width='8vw'textAlign='left' >Parking Features</Typography>  
              <Typography  fontSize='1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>Parking meters</Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1vw' color='#000000' width='8vw'textAlign='left'>Garage Spaces:</Typography>  
              <Typography  fontSize='1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'> few </Typography>  
          </Stack>
          
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
          <Typography fontSize='1vw' fontWeight='regular' color='#000000'>Covered SPaces</Typography> 
              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>a few</Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1vw' color='#000000' width='8vw'textAlign='left'>Other parking info:</Typography>  
              <Typography  fontSize='1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>No parkinson around </Typography>  
          </Stack>
          </Box>
        </Box>
        </Box>
      </Box>
          </React.Fragment>
        );
    }

    function LeftBlockA () {

      const dispatch = useDispatch();
      const { bProfile,
              selectedProp
            } = useSelector((state)=>state.borrower);
    
      function onClickFun(e) {
        const buttonId = e.currentTarget.id;
        const input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
        switch(buttonId) {
          case "fname":
            dispatch(borrowerProfile({...bProfile,firstName:input}));
            dispatch(borrowerProfileNew({...bProfile,firstName:input})); 
            break;
          case "lname":
            dispatch(borrowerProfile({...bProfile,lastName:input}));
            dispatch(borrowerProfileNew({...bProfile,lastName:input}));  
            break;
          case "ssn":
            dispatch(borrowerProfile({...bProfile,ssn:input}));
            break;
          default:
            break;
          }
      }
      return (
        <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',
          boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
            <Box sx={{ mt:'14.7vh' , ml: '7.3vw', width: '26vw',height:'11.25vw' }}> 
                <Typography sx={{color:'#31312C', width:'26vw',
                  fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                    Selected Financing for - {selectedProp?.prop?.address}, {selectedProp?.prop?.city}
                </Typography>
                <Typography sx={{color:'#31312C', width:'24vw',mt:'1.7vw',
                  fontSize:'1.35vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                    Complete your profile and start your application today
                </Typography>
            </Box>
            <Box sx={{ mt:'6vh' , ml: '7.3vw', width: '28vw',height:'14vw' }}> 
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                  <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>First Name :</Typography>  
                  <Input disableUnderline={true} id="fname" value={bProfile.firstName} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{style: { textAlign: 'left' }}}onChange={onClickFun}/>
              </Stack>
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Last Name :</Typography>  
                  <Input disableUnderline={true} id="lname" value={bProfile.lastName} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{style: { textAlign: 'left' }}}onChange={onClickFun}/>
              </Stack>
              <Stack sx={{mt:1}} spacing={0} direction="row" justifyContent="space-between"> 
              <Typography sx={{mt:1,fontSize:'1.35vw', width:'10  vw', justifyContent:'space-between'}} color='#000000'>SSN :</Typography>  
                  <Input disableUnderline={true} id="ssn" value='xxx-xxx-xxx' sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw",border:1, borderRadius:'0.4vw'}} 
                        inputProps={{style: { textAlign: 'left' }}}onChange={onClickFun}/>
              </Stack>
            </Box>
    
            <LeftbottomA leftbottom={leftbottom}/>
        </Box>
      );
    }

    function LeftBlock() {
      const {bChoiceH,bfinancialProfile} = (useSelector((state)=>state.borrower));
      const [dummy,setDummy]= React.useState(bChoiceH);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      return (
        <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
          <Box sx={{ ml:'7vw',mt:'10%' , width:'24vw',background:'inherit', height: '19.7vw' }}>
          <div>
                  <Box sx={{ pt:7, border:0, borderColor:"#000000",borderRadius: 2,  ml:0, mt:"5%" , background:'inherit',width: '25vw', height: '25vh' }}>
                  <Typography sx={{color:'#31312C', width:'20vw',
                    fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                        Nestfuel Smart </Typography>
                        <Typography sx={{color:'#31312C', width:'20vw',mt:1,
                          fontSize:'1.2vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                        For the selected property</Typography>

                        <Stack mt={2} direction="row" justifyContent="space-between">
                        <Typography fontSize='1.2vw' fontStyle="regular" color='#000000'> Rate Estimate: </Typography>
                        <Typography fontWeight='bold' fontSIze='1.2vw' fontStyle="regular" component='div' color='#000000'> {bChoiceH?.interestRate}%  </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize='1.2vw' fontStyle="regular" component='div' color='#000000'> Upfront Payment:</Typography>
                        <Typography fontWeight='bold' fontSIze='1.2vw' fontStyle="regular" component='div' color='#000000'> {withCommas(bChoiceH.upfront)}  </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize='1.2vw' fontStyle="regular" component='div' color='#000000'> Monthly Installment: </Typography>
                        <Typography fontWeight='bold' fontSIze='1.2vw' fontStyle="regular" component='div' color='#000000'> {withCommas(bChoiceH.monthlyEMI)}  </Typography>
                        </Stack>
                        <Typography fontSize='0.9vw' fontStyle="regular" component='div' color='#000000'> (Fixed for {bChoiceH.loanTerm} years) </Typography>
              
                    { bfinancialProfile?.frontEndDTI > bChoiceH?.monthlyEMI 
                    ? <>
                    <Typography ml={'0%'} mt={2} fontSize='1.5vw' fontStyle="regular" component='div'color='#000000'> 
                        Congratulations!</Typography>
                    <Typography sx={{mt:2,width:'70%'}}  fontSize='1.2vw' fontStyle="regular" color='#000000'> 
                        Apply to lock in the rate below or try Nestfuel SmartMortgage for personalize financing</Typography>
                    </>
                    : <>
                    <Typography ml={'0%'} mt={2} fontSize='1.2vw' fontStyle="regular"color='#000000' align='center'> 
                        You are almost there. </Typography>
                    <Typography  mt={2} width='80%' fontSIze='1.1vw' fontStyle="regular" color='#000000' align='center'> 
                        Your income is low. We can help you find a financing solution </Typography>
                    </>
                  }
                  </Box>
              </div>  
              </Box>
              <LeftbottomA leftbottom={leftbottom}/>
        </Box>
      );
    }

    function ImageTextCard(props){

      const { CardElement,
              propertyPrice, 
              loanAmount,
              upfront,
              monthly,
              interestRate,
              loanTerm } = props; 
              
    return (
    <Card style={{ width: '16vw' }}>
      <Card.Body>
        <Card.Title>
          <Typography height='1.7vw' fontSize='1.1vw' color='#000000' align='center'>Finance Details</Typography>
        <Box sx={{ border:0, borderColor:"#FFFFFF", borderRadius:4, width:"100%", height:"8%"}} >
       <img src={CardElement.thumbnail} width="100%"/>
        </Box>
        </Card.Title>
        <Card.Text>
        <Stack height='0.56vw'spacing={1} direction="row" justifyContent="center">
        <Typography fontSize='0.8vw' color='#000000'>{CardElement.address}</Typography>
        <Typography fontSize='0.8vw' color='#000000'>{CardElement.city}</Typography>
        <Typography fontSize='0.8vw' color='#000000'>{CardElement.zipcode}</Typography>
        </Stack>
        </Card.Text>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>
        <Stack spacing={0} height='2vh' direction="row" justifyContent="space-around">
        <Typography fontSize='1.8vh' color='#707070'> Property Price</Typography>
        <Typography fontSize='1.8vh' fontWeight='bold' color="#000000">{withCommas(propertyPrice)}</Typography>
        </Stack>
        </ListGroupItem>
        <ListGroupItem>
        <Stack height='2vh'spacing={0} direction="row" justifyContent="space-around">
        <Typography fontSize='1.8vh' color='#707070'> Loan Amount</Typography>
        <Typography fontSize='1.8vh' fontWeight='bold' color="#000000">{withCommas(loanAmount)}</Typography>
        </Stack>
        </ListGroupItem>
        <ListGroupItem>
        <Stack height='2vh' spacing={0} direction="row" justifyContent="space-around">
        <Typography fontSize='1.8vh' color='#707070'> Upfront </Typography>
        <Typography fontSize='1.8vh' fontWeight='bold' color="#000000">{withCommas(upfront)}</Typography>
        </Stack>
        </ListGroupItem>
        <ListGroupItem>
        <Stack height='2vh' spacing={0} direction="row" justifyContent="space-around">
        <Typography fontSize='1.8vh' color='#707070'> Monthly </Typography>
        <Typography fontSize='1.8vh' fontWeight='bold' color="#000000">{withCommas(monthly)}</Typography>
        </Stack>
        </ListGroupItem>
        <ListGroupItem>
        <Stack height='2vh' spacing={0} direction="row" justifyContent="space-around">
        <Typography fontSize='1.8vh' color='#707070'> Rate </Typography>
        <Typography fontSize='1.8vh' fontWeight='bold' color="#000000"> {interestRate}%</Typography>
        </Stack>
        </ListGroupItem>
        <ListGroupItem>
        <Stack height='2vh' spacing={0} direction="row" justifyContent="space-around">
        <Typography fontSize='1.8vh' color='#707070'> Loan Term </Typography>
        <Typography fontSize='1.8vh' fontWeight='bold' color="#000000"> {loanTerm} years</Typography>
        </Stack>
        </ListGroupItem>
      </ListGroup>
    </Card>
      );
}

function InnerCarousel(props) {
  const {imageArray,numCards,chevron} = props;
  const [active, setActive] = React.useState(0);
  console.log("chevron",chevron)
  return (
    <div className="InnerCarousel">
      <ItemsCarousel
         autoPlay={true}
         infiniteLoop={true}
         gutter={0}
         timeout={1}
         activePosition={"center"}
         chevronWidth={-2}
         disableSwipe={false}
         alwaysShowChevrons={false}
         numberOfCards={numCards}
         slidesToScroll={1}
         outsideChevron={false}
         showSlither={true}
         firstAndLastGutter={true}
         activeItemIndex={active}
        requestToChangeActive={(value) => setActive(value)}
         //rightChevron={<ArrowForwardIosIcon sx={{color:"#FFFFFF" , fontSize:'3vw'}}/>}
         //leftChevron={<ArrowBackIosIcon    sx={{color:"#FFFFFF" , fontSize:'3vw'}}/>}
         rightChevron={ <img src={rightArrow} alt='right-arrow' style={{ width:'1.77vw', height:'3.28vw' }} /> }
         leftChevron={ <img src={leftArrow} alt='left-arrow' style={{ width:'1.77vw', height:'3.28vw' }} /> }
        //  rightChevron={<ArrowForwardIosIcon sx={{color:"#F1F2F4", fontSize:40,
        //   borderRadius: '15px 0px 0px 15px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
        // leftChevron={<ArrowBackIosIcon    sx={{ color:"#F1F2F4", fontSize:40,
        //   borderRadius: '0px 15px 15px 0px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
      >
      {imageArray.map((item) => 
      <div>
       <Box sx={{ border:1, borderColor:"#FFFFFF", borderRadius: '10px 10px 0px 0px', height:'13.5vw'}} >
       <img src={(typeof(item) ==='undefined' ? nestfuelImage : item)} alt={nestfuelImage} style={{borderRadius: '10px 10px 0px 0px'}} width="100%" height="100%"/>
        </Box>
        </div>)}
      </ItemsCarousel>
    </div>
  );
}

export default BiggerAndBetter ;