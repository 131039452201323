import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button, Input } from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/bg-10@1x.png'
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { Centerbottom, LeftbottomA } from "./RightBlock";
import { getAnalytics, logEvent } from "firebase/analytics";
import { RightBlockCompare, RightBlockCompareEmp } from "./RightBlockCompare";
import { maxMortgageNQM92E, maxMortgageQM, } from "./HelperFunctions";
import { PROPROP, SELPROP, withCommas,MODIFY_D, _debug } from "../../constants";
import { borrowerPostPropertyData, empBenefitDSF_EMB, updateDSF_EMB,calcPropfinOptions,calcPropfinOptionsA } from "../../actions/borrower";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { rspvw } from "./styles";
import  EmployerSupportDtRegRightBlock  from "./EmployerSupportDtRegRightBlock";

export function EmployerSupportDtReg() {
  const { selectedProp,
    proposedProp,
    isLoading,
    bChoiceDSF,
    bfinancialProfile,
    bPropertyDetail,
    isEmbFlow,
    bChoiceB,
    bChoiceD,
    bChoiceD1,
    bChoiceE,
    isEmployerLoading,
    isBchoiceDLoading,
    employeeMortgageBenifitProfile
  } = useSelector((state) => state.borrower);
  var propFinRequest;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [indexS, setIndexS] = React.useState(selectedProp.propId)
  const [indexP, setIndexP] = React.useState(proposedProp.propId)
  const [tTextS, setTTextS] = React.useState('Smart Traditional')
  const [tTextP, setTTextP] = React.useState('Smart Traditional')
  const [typeS, setTypeS] = React.useState("Trad")
  const [typeP, setTypeP] = React.useState("Trad")
  const [colBS, setColBS] = React.useState('#00657C')
  const [colTS, setColTS] = React.useState('#FEFEFE')
  const [colBP, setColBP] = React.useState('#00657C')
  const [colTP, setColTP] = React.useState('#FEFEFE')
  const [sbenOn, setSbenOn] = React.useState(false)
  const [pbenOn, setPbenOn] = React.useState(false)
  const [abenOn, setAbenOn] = React.useState(selectedProp?.finOpt === 4 ? true : false)
// const [bChoiceDSF_E, setBChoiceDSF_E] = React.useState(isBchoiceDLoading?'': bChoiceDSF)
  const { height, width } = useWindowDimensions();
  const [marginT, setMarginT] = React.useState('0px')
  const [marginL, setMarginL] = React.useState('0px')

  var newArr=[]
  var newArr1=[]
  
//   for (let i = 0; i < bChoiceDSF.length; i++) {
//     var newArr = bChoiceDSF[i].filter((val) => {
//       return val.label == "Smart Traditional" || val.label == "Employer Benefit"
//     })
//     newArr = newArr.map((item, index) => {
//       if (item.label == "Employer Benefit") {
//         item.benefits = Math.ceil(((newArr[0].emi * newArr[0].loanTerm * 12) - newArr[0].loanAmount) - ((item.initialMonthlyEMI * item.loanTerm * 12) - item.loanAmount))
//         return item;
//       }
//       return item;
// })
// // console.log("BENEfits check",JSON.stringify(newArr))
// // conole.log("Here is array")
// newArr1[i]=newArr
//   }
  console.log('Here is the bchoiceDSF',bChoiceDSF,proposedProp)

  React.useEffect(()=>{
console.log("bChoiceDSF have been changed from EMPSDTReg")
  },[bChoiceDSF])
  React.useEffect(() => {
    const calc1 = Math.ceil(height * 0.5 - 0.28125 * width)
    const calc2 = Math.ceil(width * 0.5 - 1.78 * height)
    setMarginT(String(calc1 >= 0 ? calc1 : 0).concat('px'))
    setMarginL(String(calc2 >= 0 ? calc2 : 0).concat('px'))

  }, [height, width])
  const analytics = getAnalytics();
  logEvent(analytics, 'employersupportdtreg', { name: 'employersupportdtreg'});
React.useEffect(()=>{
  if (isEmbFlow ==true){
 dispatch(updateDSF_EMB(newArr1))
  }
},[])
// React.useEffect(()=>{

// },[isBchoiceDLoading])
React.useEffect(()=>{
  if(bChoiceDSF.length<1){
  dispatch(calcPropfinOptions(bChoiceD,bfinancialProfile,bChoiceB))
  dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB))
  }

},[])
 
  return (
    
      <React.Fragment> <CssBaseline  />
        <Box mt={marginT} ml={marginL} >
        { isLoading || isEmployerLoading || isBchoiceDLoading|| bChoiceDSF?.length==0 ? <CircularProgress sx={{mt:'45vh',ml:'45vw'}}  /> :
         <EmployerSupportDtRegRightBlock/>
        // <>Hello</>
         }
        </Box>
      </React.Fragment>
  );
}

function LeftBlock() {

  const dispatch = useDispatch();
  const { bProfile,
    bChoiceL,
    isNewEmployer,
    isEmbFlow,
    employeeMortgageBenifitProfile,
    isBorrowerFlow
  } = useSelector((state) => state.borrower);
  console.log(bProfile);
  if (_debug === 'development') console.log(bChoiceL)
  const textsplit = bChoiceL?.title.split(" ");

  var labelTxt = 'onboarded'
  if ((bProfile?.employer?.nestfuelEmployerId) && (bProfile?.employer?.nestfuelEmployerId).startsWith("PRE")) {
    labelTxt = 'pre-qualified'
  }
  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '40vw', height: '46.75vw', position: 'relative'
    }}>
      {
        // isEmbFlow ? <>
        // <Box sx={{marginLeft:`${rspvw(100)}`,marginTop:`${rspvw(159)}`,width:`${rspvw(470)}`,height:`${rspvw(126)}`}}>
        //   <Typography sx={{fontSize:`${rspvw(48)}`,fontFamily: 'Roboto',color:'#31312C', fontWeight:'medium',textAlign: 'left'}}>
        //   Mortgage as Benefit Personalized for you
        //   </Typography>
        // </Box>
        //      <Typography sx={{
        //     color: '#31312C', width: '22vw', mt: `${rspvw(40)}`, ml:`${rspvw(100)}`,
        //     fontSize: `${rspvw(48)}`, fontFamily: 'Roboto', fontWeight:'medium', textAlign: 'center', lineHeight: 1.2, letterSpacing: '0'
        //   }}>
        //    Saving
        //   </Typography>

        //   <Typography sx={{
        //     color: '#31312C', width: '21vw', mt: '0.2vh', ml:`${rspvw(110)}`,
        //     fontSize:`${rspvw(32)}`, fontFamily: 'Roboto', fontWeight: 'medium', textAlign: 'center', lineHeight: 1.2, letterSpacing: '0'
        //   }}>
        //    upto
        //   </Typography>
        //   <Typography sx={{
        //     color: '#31312C', width: '22vw', mt: `${rspvw(6)}`, ml:`${rspvw(90)}`,
        //     fontSize:`${rspvw(100)}`, fontFamily: 'Roboto', fontWeight: 'medium', textAlign: 'center', lineHeight: 1.2, letterSpacing: '0'
        //   }}>
    
        //    {withCommas(employeeMortgageBenifitProfile.saving)}
        //   </Typography>
        //   <Typography sx={{
        //     color: '#31312C', width: '22vw', mt: '0vh', ml:`${rspvw(100)}`,
        //     fontSize:`${rspvw(32)}`, fontFamily: 'Roboto', fontWeight: 'regular', textAlign: 'center', lineHeight: 1.2, letterSpacing: '0'
        //   }}>
        //    on your mortgage interest
        //   </Typography>
        
        //   <Typography sx={{
        //     color: '#31312C', width: '20vw', mt: '2vh', ml:`${rspvw(240)}`,
        //     fontSize:`${rspvw(16)}`, fontFamily: 'Roboto', fontWeight: 'regular', textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        //   }}>
        //     over a 30 year term
        //   </Typography>
        // </>:
        // isNewEmployer ? <Box sx={{ mt: '8.2vw', ml: '4.3vw', width: '28vw', height: '10vw' }}>
        //   <Typography sx={{
        //     color: '#31312C', width: '28vw',
        //     fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        //   }}>
        //     Finance matching with your property aspiration
        //   </Typography>
        //   <Typography sx={{
        //     color: '#31312C', width: '26vw', mt: '6vh',
        //     fontSize: '1.6vw', fontFamily: 'Roboto', fontWeight: 400, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        //   }}>
        //     Compare available properties with financing matching your needs
        //   </Typography>


        // </Box> : <Box sx={{ mt: '8.2vw', ml: '7.3vw', width: '26vw', height: '10vw' }}>
        //   <Typography sx={{
        //     color: '#31312C', width: '26vw',
        //     fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        //   }}>
        //     {bProfile?.employer?.companyName} {labelTxt} for Nestfuel Benefits
        //   </Typography>
        //   <Typography sx={{
        //     color: '#31312C', width: '22vw', mt: '6vh',
        //     fontSize: '1.6vw', fontFamily: 'Roboto', fontWeight: 400, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        //   }}>
        //     Compare properties from your preferred location with available financing options
        //   </Typography>

        //   <Typography sx={{
        //     color: '#31312C', width: '22vw', mt: '4vh',
        //     fontSize: '1.6vw', fontFamily: 'Roboto', fontWeight: 400, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        //   }}>
        //     Discover also Alternative Finance
        //   </Typography>
        // </Box>
        isBorrowerFlow ?
      
        <Box sx={{ ml:`${rspvw(100)}`}}>
        <Box sx={{ mt:`${rspvw(159)}` , width: `${rspvw(600)}`,height:`${rspvw(63)}` }}> 
         <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(45)}`, fontFamily:'Roboto',fontWeight:'medium',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
         Personalized rate
         </Typography>
     </Box>
     <Box sx={{ mt:`${rspvw(0)}` , width: `${rspvw(600)}`,height:`${rspvw(63)}` }}> 
         <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(45)}`, fontFamily:'Roboto',fontWeight:'medium',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
        Based on the below profile
         </Typography>
     </Box>
     <Box sx={{display:'flex'}}>
      <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
      <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
       Income
         </Typography>
      </Box>
      <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
      <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'right',lineHeight:1.2,letterSpacing: '0'}}> 
       $HELL IS REAL
         </Typography>
      </Box>
      

     </Box>
     <Box sx={{display:'flex'}}>
      <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
      <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
        Cash avalable
         </Typography>
      </Box>
      <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
      <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'right',lineHeight:1.2,letterSpacing: '0'}}> 
       $xx,xxx
         </Typography>
      </Box>
      

     </Box>
     <Box sx={{display:'flex'}}>
      <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
      <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
        Credit score
         </Typography>
      </Box>
      <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
      <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'right',lineHeight:1.2,letterSpacing: '0'}}> 
        XXX 
         </Typography>
      </Box>
      

     </Box>
     <Box sx={{ mt:`${rspvw(25)}` , width: `${rspvw(600)}`,height:`${rspvw(63)}` }}> 
         <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
         Save up to $xx,xxx with Mortgage as Benefit
         </Typography>
     </Box>
     </Box>
     : isNewEmployer ? <Box sx={{ mt: '8.2vw', ml: '4.3vw', width: '28vw', height: '10vw' }}>
       <Typography sx={{
         color: '#31312C', width: '28vw',
         fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
       }}>
         Finance matching with your property aspiration
       </Typography>
       <Typography sx={{
         color: '#31312C', width: '26vw', mt: '6vh',
         fontSize: '1.6vw', fontFamily: 'Roboto', fontWeight: 400, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
       }}>
         Compare available properties with financing matching your needs
       </Typography>


     </Box> : <Box sx={{ mt: '6.2vw', ml: '7.3vw', width: '26vw', height: '10vw' }}>
       <Typography sx={{
         color: '#31312C', width: '26vw',
         fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
       }}>
         {bProfile?.employer?.companyName} {labelTxt} for Nestfuel Benefits
       </Typography>
       <Typography sx={{
         color: '#31312C', width: '22vw', mt: '6vh',
         fontSize: '1.6vw', fontFamily: 'Roboto', fontWeight: 400, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
       }}>
         Compare properties from your preferred location with available financing options
       </Typography>

       <Typography sx={{
         color: '#31312C', width: '22vw', mt: '4vh',
         fontSize: '1.6vw', fontFamily: 'Roboto', fontWeight: 400, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
       }}>
         Discover also Alternative Finance
       </Typography>
     </Box>
     
      }
      <LeftbottomA leftbottom={leftbottom} />
    </Box>
  );
}

function LeftBlockA() {

  const dispatch = useDispatch();
  const { bChoiceE,
    bChoiceL,
    selectedProp,
    proposedProp,
    bfinancialProfile
  } = useSelector((state) => state.borrower);
  const [name, setName] = React.useState('Aivanaa Maraea')
  const [soffer, setSoffer] = React.useState(selectedProp.prop.offerPrice)
  const [poffer, setPoffer] = React.useState(proposedProp.prop.offerPrice)
  const maxA = maxMortgageNQM92E(bfinancialProfile.frontEndDTI, bfinancialProfile.income.upfront)
  const maxB = maxMortgageQM(bfinancialProfile.frontEndDTI, bfinancialProfile.income.upfront)
  if (_debug === 'development') console.log(bChoiceL)
  const textsplit = bChoiceL?.title.split(" ");

  function onClickFun(e) {
    const buttonId = e.currentTarget.id;
    const input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
    switch (buttonId) {
      case "soffer":
        setSoffer(isNaN(input) ? 0 : input)
        const sprop = { ...selectedProp.prop, offerPrice: input }
        dispatch({ type: SELPROP, payload: { ...selectedProp, prop: sprop } });
        break;
      case "poffer":
        setPoffer(isNaN(input) ? 0 : input)
        const pprop = { ...proposedProp.prop, offerPrice: input }
        dispatch({ type: PROPROP, payload: { ...proposedProp, prop: pprop } });
        break;
      default:
        break;
    }
  }

  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '46.75vw', position: 'relative'
    }}>
      <Box sx={{ mt: '8.2vw', ml: '7.3vw', width: '26vw', height: '10vw' }}>
        <Typography sx={{
          color: '#31312C', width: '26vw',
          fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          Update offer price
        </Typography>
        <Typography sx={{
          color: '#31312C', width: '26vw',
          fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          {selectedProp.prop.address}
        </Typography>
        <Stack sx={{ mt: 1 }} spacing={0} direction="column" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw' }} color='#000000'>List Price</Typography>
          <Typography sx={{ mt: 1, fontSize: '1.35vw' }} color='#000000'>{selectedProp.prop.price}</Typography>
        </Stack>
        <Stack sx={{ mt: 1 }} spacing={0} direction="column" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw' }} color='#000000'>Offer Price</Typography>
          <Input disableUnderline={true} id="soffer" value={withCommas(soffer)} sx={{ backgroundColor: '#FFFFFF', color: "#8F8F8F", fontSize: '1.1vw', height: '2.5vw', width: "13vw", border: 1, borderRadius: '0.5vw' }}
            inputProps={{ style: { textAlign: 'center' } }} onChange={onClickFun} />
        </Stack>
        <Typography sx={{
          color: '#31312C', width: '26vw',
          fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          {selectedProp.prop.address}
        </Typography>
        <Stack sx={{ mt: 1 }} spacing={0} direction="column" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw' }} color='#000000'>List Price</Typography>
          <Typography sx={{ mt: 1, fontSize: '1.35vw' }} color='#000000'>{proposedProp.prop.price}</Typography>
        </Stack>
        <Stack sx={{ mt: 1 }} spacing={0} direction="column" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw' }} color='#000000'>Offer Price</Typography>
          <Input disableUnderline={true} id="poffer" value={withCommas(poffer)} sx={{ backgroundColor: '#FFFFFF', color: "#8F8F8F", fontSize: '1.1vw', height: '2.5vw', width: "13vw", border: 1, borderRadius: '0.5vw' }}
            inputProps={{ style: { textAlign: 'center' } }} onChange={onClickFun} />
        </Stack>
      </Box>
      <LeftbottomA leftbottom={leftbottom} />
    </Box>
  );
}

export default EmployerSupportDtReg