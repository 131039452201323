import React from "react";
import { Box,CssBaseline, Typography, Button,Stack,CircularProgress} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ItemsCarousel from "react-items-carousel";
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import leftbottom from '../../assets/images/bg-10@1x.png'

import { useDispatch, useSelector } from 'react-redux';
import {  borrowerfinOpt, 
          borrowerAdjust, 
          borrowerReview, 
          borrowerChoiceI} from "../../actions/borrower";
import { PROPROP, withCommas } from "../../constants";
import { LeftBlockMsg } from "./Messenger";

import leftArrow from '../../assets/svg/left-arrow.svg'
import rightArrow from '../../assets/svg/right-arrow.svg'

const DebugOutput = true;

function CompareFinance() {
    const { review,
            finOptS,
            finOptP,
            isLoading } = useSelector((state)=>state.borrower);
    const [disabledA,setDisabledA] = React.useState(false)
    const [disabledB,setDisabledB] = React.useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    console.log("finOPTS and P",finOptS,finOptP)
    React.useEffect(()=>{
      console.log("finOPTS and P",finOptS,finOptP)
      setDisabledA(finOptS?.fin?.label === 'Traditional'?true:false)
    },[finOptS?.fin])
    React.useEffect(()=>{
      console.log("finOPTS and P",finOptS,finOptP)
      setDisabledB(finOptP?.fin?.label === 'Traditional'?true:false)
    },[finOptP?.fin])

      function onClickFun(e){
      e.preventDefault();
      const buttonId = e.currentTarget.id;
      switch(buttonId) {
        case "ReviewA": 
          dispatch(borrowerReview('selected'));
          break;
        case "ReviewB": 
          dispatch(borrowerReview('proposed'));
          break;
        case "AdjustA": 
          dispatch(borrowerAdjust('selected'));
          navigate("/AdjustOptions",{ replace: false }); 
          break;
        case "AdjustB": 
          dispatch(borrowerAdjust('proposed'));
          navigate("/AdjustOptions",{ replace: false }); 
          break;
        case "Adjust": 
          dispatch(borrowerAdjust(review));
          navigate("/AdjustOptions",{ replace: false }); 
          break;
        case "Other": 
          dispatch(borrowerReview(''));
          dispatch(borrowerAdjust(''));
          break;
        case "StartA" : navigate("/SummaryApplication",{ replace: false }); break;
        break;
        default: break;
      }
    }

  return(
    isLoading ? <CircularProgress /> : (
      <React.Fragment>
          <CssBaseline/>
          <Box sx={{ width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
            <Navbar pathname={location.pathname}/>
            <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
            { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
            
              <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center', position:'relative'}}>
              <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> 
            I want to
          </Typography>
          <div align='center'>
            { review === 'selected' || review ==='proposed'
            ? <>
                 <Stack sx={{mt:'3.5vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
                 <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="StartA" className='hexBtnCenter' onClick={onClickFun}> 
                    <HexButton titleText="Start Application" type='confirm' fontSize='24'/></Button>
                  </Stack>
                  <Stack sx={{mt:'2.25vw',height:'3.5vw',width:'12vw'}} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="Adjust" className='hexBtnCenter' onClick={onClickFun}> 
                    <HexButton titleText="Adjust" type='applynow' fontSize='24'/></Button>
                    <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="Other" className='hexBtnCenter' onClick={onClickFun}> 
                    <HexButton titleText="Other Options" type='biggerbetter' fontSize='24'/></Button>
                </Stack>
              </>
            : <>
               <Stack sx={{mt:'3.5vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="ReviewA" className='hexBtnCenter' onClick={onClickFun}> 
                    <HexButton titleText="Review Option-A" type='confirm' fontSize='24'/></Button>
                </Stack>
                <Stack sx={{mt:'2.25vw',height:'3.5vw',width:'12vw'}} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="AdjustB" className='hexBtnCenter' disabled={disabledB} onClick={onClickFun}> 
                    <HexButton titleText="Adjust Option-B"  type={disabledB?'disabled':'applynow'} fontSize='24'/></Button>
                    <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="AdjustA" className='hexBtnCenter' disabled={disabledA} onClick={onClickFun}> 
                    <HexButton titleText="Adjust Option-A" type={disabledA?'disabled':'applynow'} fontSize='18'/></Button>
                </Stack>
                <Stack sx={{mt:'2.25vw',height:'3.5vw',width:'6vw'}} direction="row" justifyContent="space-around">
                    <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="ReviewB" className='hexBtnCenter' onClick={onClickFun}> 
                    <HexButton titleText="Review Option-B" type='biggerbetter' fontSize='18'/></Button>
                </Stack>
              </>
            }
            <Centerbottom navigateForward='-1'/>
            </div>
            {/* <Stack sx={{bottom:'0', position:'fixed',width:'14vw'}} direction="row" justifyContent="space-between">
                    <Button id="Back" onClick={()=>navigate(-1)}> <ArrowBackIosIcon sx={{color:"#000000" , fontSize:'3vw'}}/></Button>
                    <Button sx={{mr:-3}}id="Forward" onClick={()=>navigate("/PropertyType", { replace: false })}>
                      <ArrowForwardIosIcon sx={{color:"#000000" , fontSize:'3vw'}}/></Button>
            </Stack> */}
          </Box>
          <RightBlock/>
      </Box>
    </Box>
    </React.Fragment>
  ));
}
  
function RightBlock() {
    const { bChoiceCheapOne,
            bChoiceIone,
            bChoiceItwo,
            bChoiceD,
            review,
            selectedProp,
            proposedProp
          } = useSelector((state)=>state.borrower);

    const objArrayA = Object.entries(bChoiceIone)
    const objArrayB = Object.entries(bChoiceItwo)
    if(typeof bChoiceCheapOne?.metaData !== 'undefined') 
              objArrayA[0][1] = {
                financeType                : bChoiceCheapOne?.metaData.financeType,
                initialMonthlyEMI          : bChoiceCheapOne?.middle[0].value,
                interestRate               : bChoiceCheapOne?.metaData.interestRate,
                label                      : bChoiceCheapOne?.metaData.label,
                loanAmount                 : bChoiceCheapOne?.top[4].value,
                loanTerm                   : bChoiceCheapOne?.middle[2].value,
                monthlyEMI                 : bChoiceCheapOne?.top[1].value,
                mortgageType               : bChoiceCheapOne?.metaData.mortgageType,
                percentLTV                 : bChoiceCheapOne?.metaData.percentLTV,
                monthlyEMIPostUnsecureTerm : bChoiceCheapOne?.middle[1].value,
                monthlyUnsecureTermEMI     : bChoiceCheapOne?.top[1].value,
                upfront                    : bChoiceCheapOne?.top[3].value,
                points                     : 0,
                pointCost                  : 0.0
              }
    console.log("obj array cheap",objArrayA)
    if(DebugOutput) console.log("Objects to be sent to Table",objArrayA,objArrayB);
    return (
    <React.Fragment>
    <CssBaseline/>
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',opacity: 1, width: '50vw',height:'46.75vw' }}>
    <Box sx={{ background: '#F3F3F3 0% 0% no-repeat padding-box',width:'100%', height:'18vw', p2:2,display: 'flex', flexDirection: 'row',boxShadow: '0px 0px 19px #0000008F'}}>
        <Box sx={{m:1,background:'#FFFFFF 0% 0% no-repeat padding-box', width: "49%",height:'18vw', borderRadius:2,border:0,boxShadow: '0px 0px 19px #0000008F' }}>
          <Typography align='center'fontSize='1.9vh' color='#000000'>Option-A</Typography>
              <img src={selectedProp?.prop.thumbnail} width="100%" height="70%"/>
              <Stack sx={{ml:2, mr:2,mb:0}} spacing={0} direction="row" justifyContent="space-between"> 
                <Typography mt='0.8vh' fontSize='1.1vw' fontWeight='bold' color='black' align='center'>${withCommas(selectedProp?.prop.offerPrice)}</Typography>
                <Stack direction="column">
                  <Typography fontSize='0.9vw' color='black' align='center'> {selectedProp?.prop.address}</Typography>
                  <Typography fontSize='0.9vw' color='black' align='center'> {selectedProp?.prop.city}, {selectedProp?.prop.state} - {selectedProp?.prop.zipcode}</Typography>
                </Stack>
              </Stack>
              </Box>
                <Box sx={{m:1,background:'#FFFFFF 0% 0% no-repeat padding-box', width: "49%",height:'18vw', borderRadius:2,border:0,boxShadow: '0px 0px 19px #0000008F'}}>
                <Typography align='center'fontSize='1.9vh' color='#000000'>Option-B</Typography>
                <OuterCarouselT itemArray={bChoiceD} numCards={1.0}/>
              </Box>
          </Box>
          <Box sx={{background:'#F3F3F3 0% 0% no-repeat padding-box', display: 'flex', flexDirection: 'row'}}>
          <Box sx={{ ml:'0',mt:'0.56vw',background:'inherit', width: '17vw',height:'60vh',borderRadius:0}}>
            <ImageTextCardA/>
          </Box>
            {review != 'proposed'
             ? <Box sx={{ ml:'0',mt:'0.56vw',background:'inherit', width: '16vw',height:'56vh',borderRadius:0}}>
                    <OuterCarousel  ObjArray = {objArrayA} propertyPrice={selectedProp?.prop.price} _uScore='Y'/>
              </Box>
            :<></>}
             {review != 'selected'
              ? <Box sx={{ ml:'0.01vw',mt:'0.56vw',background:'inherit', width: '16vw',height:'56vh',borderRadius:0}}>
                    <OuterCarousel  ObjArray = {objArrayB} propertyPrice={proposedProp?.prop.price }_uScore='N'/>
              </Box>
              :<></>}
            </Box>
        </Box>
      </React.Fragment>
    );

}

  function LeftBlock() {
    const { bChoiceE, 
            bChoiceF,
            bfinancialProfile
          }= useSelector((state)=>state.borrower);
    const navigate = useNavigate();
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
            <Box sx={{ ml:'8.3vw', mt:'11.25vw' , width:'20vw',background:'inherit', height: '40vh' }}> 
                <Typography sx={{fontSize:'5.2vw', fontWeight:400,textAlign:'left',lineHeight:1.1,letterSpacing: '-0.25vw'}}> 
                      Compare Financing</Typography>
                  <Typography sx={{mt:'6vh', width:'20vw'}}fontSize='1.2vw' component="div"
                              color='#000' align='left'>
                      Nestfuel tailors financing to combine your property aspirations and finance limitations
                  </Typography>
            </Box>
          <Box sx={{ bottom:'0', position:'absolute', height:'11.4vw',width: 'inherit' }}>  
              <img src={leftbottom} alt = "Loading..." width="100%" height="100%"/> 
          </Box>
      </Box>
    );
  }

function OuterCarousel(props) {
    const { ObjArray,
            propertyPrice,
            _uScore} = props;
    const { bChoiceIone,
            bChoiceItwo,
            finOptS,
            finOptP,
            review} = useSelector((state)=>state.borrower);
    const dispatch = useDispatch();
    const [active, setActive] = React.useState(0);
    console.log("OBject Array inside OuterCarousel",ObjArray)
    React.useEffect(()=>{
      console.log("Active",active,bChoiceIone, bChoiceItwo);
      if(active >=0){ 
        console.log("Active",bChoiceIone[active]);
        _uScore==='Y' 
        ? dispatch(borrowerfinOpt({...finOptS,fin:bChoiceIone[active],property: 'selected',option:active}))
        : dispatch(borrowerfinOpt({...finOptP,fin:bChoiceItwo[active],property: 'proposed',option:active}))
      }
    },[active]);
    console.log("Object Array",ObjArray);
    if(DebugOutput) ObjArray.map((item) =>console.log(" Item ",item[1]))

    return (
      <div className="OuterCarousel">
        <ItemsCarousel
          autoPlay={true}
          infiniteLoop={true}
          gutter={1}
          timeout={1}
          activePosition={"center"}
          chevronWidth={-4}
          disableSwipe={false}
          alwaysShowChevrons={false}
          numberOfCards={1}
          slidesToScroll={1}
          outsideChevron={false}
          showSlither={true}
          firstAndLastGutter={false}
          activeItemIndex={active}
          requestToChangeActive={(value) => {
                                    if(review === "selected" || review ==="proposed"){
                                      _uScore==='Y' ? setActive(finOptS.option)
                                      : setActive(finOptP.option)
                                    } else {
                                      if( value >=0 && value < ObjArray.length ) setActive(value)
                                      if(value <0 ) setActive(ObjArray.length -1)
                                      if(value >= ObjArray.length) setActive(0)
                                    } 
                               }
                            }            
          //rightChevron={<ArrowForwardIosIcon sx={{mt:'-53vh',color:"#14214B" , fontSize:20}}/>}
          //leftChevron={<ArrowBackIosIcon    sx={{mt:'-53vh',color:"#14214B" , fontSize:20}}/>}
          rightChevron={ <img src={rightArrow} alt='right-arrow' style={{ marginTop:'-53vw',width:'1.77vw', height:'3.28vw' }} /> }
          leftChevron={ <img src={leftArrow} alt='left-arrow' style={{ marginTop:'-53vw',width:'1.77vw', height:'3.28vw' }} /> }
          // rightChevron={<ArrowForwardIosIcon sx={{mt:'-53vh',color:"#F1F2F4", fontSize:20,
          //   borderRadius: '15px 0px 0px 15px',
          //   width: '34px',
          //   height: '63px',
          //   background: "rgba(0,0,0,0.40)",
          //   color:'#FFFFFF',
          //   paddingLeft: '0.3vw'
          // }}/>}
          // leftChevron={<ArrowBackIosIcon    sx={{ mt:'-53vh',color:"#F1F2F4", fontSize:20,
          //   borderRadius: '0px 15px 15px 0px',
          //   width: '34px',
          //   height: '63px',
          //   background: "rgba(0,0,0,0.40)",
          //   color:'#FFFFFF',
          //   paddingLeft: '0.3vw'
          // }}/>}
        >
        { ObjArray.map((item) =>
        // <Box sx={{background: 'inherit', height: "98%",border:0, borderColor:"#000000", borderRadius:0} } >
            <>
            <ImageTextCard CardElement={_uScore==='Y'?finOptS.fin:finOptP.fin } financeOption={item[1]} propertyPrice={propertyPrice} score = {_uScore}/>
       </>
        // </Box>
        )}
        </ItemsCarousel>
      </div>
    );
}

  function ImageTextCardA(){

  return (
    <>
    <Box sx={{ ml:'0.5vw',mt:'0.56vw',background:'#FFFFFF 0% 0% no-repeat padding-box',boxShadow: '0px 0px 19px #0000008F', width: '16.5vw',height:'1.7vw',borderRadius:'6px 0px 0px 6px'}}>
    
    </Box>
    <Box sx={{ ml:'0.5vw',mt:'0.56vw',background:'#FFFFFF 0% 0% no-repeat padding-box',boxShadow: '0px 0px 19px #0000008F', width: '16.5vw',height:"4.5vw",borderRadius:'6px 0 0 6px'}}>
      <Stack spacing={1} direction="column" width='12vw'ml='2vw'>
        <Typography align='left'  fontSize="1vw"    color='black' > Max Property Value </Typography>
        <Typography align='left'  fontSize="1vw"    color='black' > Mas Loan Amount </Typography>
      </Stack>
    </Box>
    <Box sx={{ ml:'0.5vw',mt:'0.56vw',background:'#FFFFFF 0% 0% no-repeat padding-box',boxShadow: '0px 0px 19px #0000008F', width: '16.5vw',height:'44vh',borderRadius:'6px 0 0 6px'}}>
      <Stack spacing={1} direction="column" width='12vw'ml='2vw'>
        <Typography align='left'  fontSize="1vw"    color='black' > Loan Amount</Typography>
        <Typography align='left'  fontSize="1vw"    color='black' > Upfront</Typography>
        <Typography align='left'  fontSize="1vw"    color='black' > Monthly</Typography>
        <Typography align='left'  fontSize="1vw"    color='black' > Rate</Typography>
        <Typography align='left'  fontSize="1vw"    color='black' > Monthly EMI from 2022</Typography>
        <Typography align='left'  fontSize="1vw"    color='black' > Monthly EMI from 2025</Typography>
        <Typography align='left'  fontSize="1vw"    color='black' > Monthly EMI from 2028</Typography>
        <Typography align='left'  fontSize="1vw"    color='black' > Loan Term</Typography>
        <Typography align='left'  fontSize="1vw"    color='black' > Points</Typography>
        <Typography align='left'  fontSize="1vw"    color='black' > Point Cost</Typography>
        <Typography align='left'  fontSize="1vw"    color='black' > Percent Ownership</Typography>
      </Stack>
  </Box>
  </>
  );
}

function ImageTextCard(props){

  const { financeOption,
        propertyPrice,
        score 
        } = props; 

console.log("Finance__Option",financeOption?.label,propertyPrice)
const label = financeOption?.label
return (
  <>
  <Box sx={{ ml:'0',mt:'0.56vw',background:'#FFFFFF 0% 0% no-repeat padding-box',boxShadow: '0px 0px 19px #0000008F', width: '16vw',height:'1.7vw',borderRadius:0}}>
  <Typography align='center'  fontSize="1vw"    color='black' > {financeOption?.label} </Typography>
  </Box>
   <Box sx={{ ml:'0vw',mt:'0.56vw',background:'#FFFFFF 0% 0% no-repeat padding-box',boxShadow: '0px 0px 19px #0000008F', width: '16vw',height:"4.5vw",borderRadius:0}}>
    <Stack spacing={1} direction="column" width='12vw'>
    <Typography align='center'  fontSize="1vw"    color='black' > ${withCommas(propertyPrice)}</Typography>
    <Typography align='center'  fontSize="1vw"    color='black' > ${withCommas(Math.floor(propertyPrice*0.95))}</Typography>
    </Stack>
  </Box>
  <Box sx={{ ml:'0vw',mt:'0.56vw',background:'#FFFFFF 0% 0% no-repeat padding-box',boxShadow: '0px 0px 19px #0000008F', width: '16vw',height:'44vh',borderRadius:0}}>
    <Stack spacing={1} direction="column" width='12vw'>
        <Typography align='center'  fontSize="1vw"    color='black' > {withCommas(financeOption?.loanAmount)}</Typography>
        <Typography align='center'  fontSize="1vw"    color='black' > {withCommas(financeOption?.upfront)}</Typography>
        <Typography align='center'  fontSize="1vw"    color='black' > {withCommas(financeOption?.monthlyEMI)}</Typography>
        <Typography align='center'  fontSize="1vw"    color='black' > {financeOption?.interestRate.toFixed(2)}%</Typography>
        <Typography align='center'  fontSize="1vw"    color='black' > {withCommas(financeOption?.monthlyUnsecureTermEMI)}</Typography>
        <Typography align='center'  fontSize="1vw"    color='black' > {withCommas(financeOption?.monthlyEMIPostUnsecureTerm)}</Typography>
        <Typography align='center'  fontSize="1vw"    color='black' > {withCommas(financeOption?.monthlyUnsecureTermEMI)}</Typography>
        <Typography align='center'  fontSize="1vw"    color='black' > {financeOption?.loanTerm} years</Typography>
        <Typography align='center'  fontSize="1vw"    color='black' > {financeOption?.points}</Typography>
        <Typography align='center'  fontSize="1vw"    color='black' > {withCommas(Math.ceil(financeOption?.points*0.01*financeOption?.loanAmount))}</Typography>
        <Typography align='center'  fontSize="1vw"    color='black' > {financeOption?.percentOwnership}%</Typography>
    </Stack>
  </Box>
  </>
  );
}


function OuterCarouselT(props) {
  const {itemArray, numCards } = props;
  const { bChoiceD,
          bChoiceB,
          bChoiceE,
          bfinancialProfile,
          selectedProp,
          proposedProp
          }= (useSelector((state)=>state.borrower));
  const dispatch = useDispatch();
  const [active, setActive] = React.useState(0);
  React.useEffect(()=>{
    if(proposedProp.propId !== active){
        dispatch({type: PROPROP, payload:{...proposedProp,prop:bChoiceD[active],propId:active}});
        const propFinRequest = {
          selectedPropertyPrice   : selectedProp?.prop.offerPrice,
          proposedPropertyPrice   : proposedProp?.prop.offerPrice,
          financeType             : bChoiceB === 'firsthome' || bChoiceB === 'nexthome' ? 'primary':(bChoiceB === 'investment')? 'investment' : 'secondary',
          loanTerm                : bChoiceE?.loanTerm,
          creditScore             : bfinancialProfile?.ficoScore,
          annualIncome            : bfinancialProfile?.annualIncome,
          upfront                 : bChoiceE?.upfront,
          financeDocs             : bfinancialProfile?.financeDocs,
          creditEvents            : bChoiceE?.creditEvents,
          houseEvents             : bChoiceE?.creditEvents,
          monthlyOutgoing         : bfinancialProfile?.monthlyOutgoing,
          monthlyBudget           : bChoiceE?.monthlyBudget,
          emailId                 : 'amitkd4@gmail.com'
        }
        dispatch(borrowerChoiceI(propFinRequest));  
      }
  },[active]);
  
  
  return (
    <div className="OuterCarousel">
      <ItemsCarousel
        autoPlay={true}
        infiniteLoop={true}
        gutter={10}
        timeout={1}
        activePosition={"center"}
        chevronWidth={-2}
        disableSwipe={false}
        alwaysShowChevrons={false}
        numberOfCards={numCards}
        slidesToScroll={1}
        outsideChevron={false}
        showSlither={true}
        firstAndLastGutter={true}
        activeItemIndex={active}
        requestToChangeActive={(value) => value === selectedProp.propId 
                                  ? setActive(value >= itemArray.length-1?0:value+1)
                                  : value >=0 && value < itemArray.length 
                                  ? setActive(value)
                                  : value <0 ?  setActive(itemArray.length -1)
                                  : setActive(0)}
        //rightChevron={<ArrowForwardIosIcon  sx={{mt:'-2.5vw',color:"#F1F2F4" , fontSize:40}}/>}
        //leftChevron={<ArrowBackIosIcon      sx={{mt:'-2.5vw',color:"#F1F2F4" , fontSize:40}}/>}
        rightChevron={ <img src={rightArrow} alt='right-arrow' style={{ marginTop:'-2.5vw',width:'1.77vw', height:'3.28vw' }} /> }
        leftChevron={ <img src={leftArrow} alt='left-arrow' style={{ marginTop:'-2.5vw',width:'1.77vw', height:'3.28vw' }} /> }
        // rightChevron={<ArrowForwardIosIcon sx={{ mt:'-2.5vw',color:"#F1F2F4", fontSize:40,
        //   borderRadius: '15px 0px 0px 15px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
        // leftChevron={<ArrowBackIosIcon    sx={{ mt:'-2.5vw',color:"#F1F2F4", fontSize:40,
        //   borderRadius: '0px 15px 15px 0px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
      >
      {itemArray.map((item) => 
      <Box sx={{background: '#FFFFFF 0% 0% no-repeat padding-box', width:'inherit',height: '28vh',border:0, borderColor:"#000000", borderRadius:3} } >
       {/* <InnerCarouselT imageArray={item.images} numCards={1} chevron={active}/> */}
       <img src={item.thumbnail} width="100%" height="80%"/>
        <Stack sx={{ml:2, mr:2,mb:0}} spacing={0} direction="row" justifyContent="space-between"> 
            <Typography mt='0.8vh' fontSize='1.1vw' fontWeight='bold' color='black' align='center'>${withCommas(item.offerPrice)}</Typography>
            <Stack direction="column">
              <Typography fontSize='0.9vw' color='black' align='center'> {item.address}</Typography>
              <Typography fontSize='0.9vw' color='black' align='center'> {item.city}, {item.state} - {item.zipcode}</Typography>
            </Stack>
        </Stack>
        
      </Box>)}
      </ItemsCarousel>
    </div>
  );
}

function InnerCarouselT(props) {
  const {imageArray,numCards,chevron} = props;
  const [active, setActive] = React.useState(0);
  console.log("chevron",chevron)
  return (
    <div className="InnerCarousel">
      <ItemsCarousel
         autoPlay={true}
         infiniteLoop={true}
         gutter={0}
         timeout={1}
         activePosition={"center"}
         chevronWidth={-2}
         disableSwipe={false}
         alwaysShowChevrons={false}
         numberOfCards={numCards}
         slidesToScroll={1}
         outsideChevron={false}
         showSlither={true}
         firstAndLastGutter={true}
         activeItemIndex={active}
        requestToChangeActive={(value) => setActive(value)}
         //rightChevron={<ArrowForwardIosIcon sx={{color:"#FFFFFF" , fontSize:40}}/>}
         //leftChevron={<ArrowBackIosIcon    sx={{color:"#FFFFFF" , fontSize:40}}/>}
         rightChevron={ <img src={rightArrow} alt='right-arrow' style={{ width:'1.77vw', height:'3.28vw' }} /> }
         leftChevron={ <img src={leftArrow} alt='left-arrow' style={{ width:'1.77vw', height:'3.28vw' }} /> }
        //  rightChevron={<ArrowForwardIosIcon sx={{color:"#F1F2F4", fontSize:40,
        //   borderRadius: '15px 0px 0px 15px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
        // leftChevron={<ArrowBackIosIcon    sx={{ color:"#F1F2F4", fontSize:40,
        //   borderRadius: '0px 15px 15px 0px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
      >
      {imageArray.map((item) => 
      <div>
       <Box sx={{ border:1, borderColor:"#FFFFFF", borderRadius:1, height:'13.5vw'}} >
       <img src={(typeof(item) ==='undefined' ? nestfuelImage : item)} alt={nestfuelImage} width="100%" height="100%"/>
        </Box>
        </div>)}
      </ItemsCarousel>
    </div>
  );
}

export default CompareFinance;