import React from "react";
import {
  Box,
  Stack,
  CssBaseline,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import { HexButton } from "./ButtonIcons1";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getWindowDimensions } from "../../computations/helperFunctions";
import { updateFlag } from "../../actions/borrower";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import leftbottom from "../../assets/svg/assets svg/image-leftbottom01.png";
import { BottomBox } from "./RightBlock";
import slide1 from "../../assets/staticPageAssets/H-1.png";
import slide2 from "../../assets/staticPageAssets/H-2.png";
import slide3 from "../../assets/staticPageAssets/H-3.png";

import home3 from "../../assets/staticPageAssets/H-RIGHT-3.mp4";
import home4 from "../../assets/staticPageAssets/H-RIGHT-4.gif";
import home5 from "../../assets/staticPageAssets/H-RIGHT-5.mp4";
import hexa1 from "../../assets/staticPageAssets/1.png";
import hexa_fade1 from "../../assets/staticPageAssets/1a.png";

import hexa2 from "../../assets/staticPageAssets/2Dark.png";
import hexa_fade2 from "../../assets/staticPageAssets/2-fade.png";

import hexa3 from "../../assets/staticPageAssets/3Dark.png";
import hexa_fade3 from "../../assets/staticPageAssets/3-fade.png";

import hexa4 from "../../assets/staticPageAssets/4Dark.png";
import hexa_fade4 from "../../assets/staticPageAssets/4.png";

import hexa5 from "../../assets/staticPageAssets/5Dark.png";
import hexa_fade5 from "../../assets/staticPageAssets/5.png";

import { getAnalytics, logEvent } from "firebase/analytics";

import bullet from "../../assets/staticPageAssets/Polygon.svg";
import rightSvg1 from "../../assets/svg/assets svg/homeSvgOne.svg";
import rightSvg2 from "../../assets/svg/assets svg/homeSvgTwo.svg";
import rightSvg3 from "../../assets/svg/assets svg/homeSvgThree.svg";
import circleSvg from "../../assets/svg/assets svg/circle.svg";
import circleSvgDark from "../../assets/svg/assets svg/circle_Dark.svg";

import useStyles from "./styles";
import { LeftbottomA } from "./RightBlock";
import { _debug } from "../../constants";
import Navbar from "./NavBar";
import { fontWeight, textAlign } from "@mui/system";

var allowed = "no";

function Home() {
  const navigate = useNavigate();
  const [hover, setHover] = React.useState(0);
  const { height, width } = getWindowDimensions();
  const [seek, setSeek] = React.useState(allowed);
  const dispatch = useDispatch();
  const classes = useStyles();
  // if(_debug === 'development') allowed = 'yes'
  allowed = "yes";
  const [stateUpdate, setStateUpdate] = React.useState(0);
  const [check, setCheck] = React.useState(false);
  const [visibilityFirst, setVisibilityFirst] = React.useState(true);
  const [visibilitySecond, setVisibilitySecond] = React.useState(true);
  const [visibilityThird, setVisibilityThird] = React.useState(true);
  const [slideState, setSlideState] = React.useState({
    heading: ["There is a clear divide", "by race of homeownership"],
    content: [
      "Homeownership is a key tool for building wealth but households of ",
      "color have lower ownership due to access, education, and",
      "tooling",
    ],
    image: slide1,
    active: [true, false, false],
    footer: [
      "Urban Institute, 1990–2010 Decennial Censuses and 2007–2018 American Community Surveys.",
      "Values for 2020, 2030, and 2040 are projected values.The “Other” category includes Asians, American Indians, Alaska",
      "Native Hawaiians, other Pacific Islanders, and multiracial individuals.",
    ],
  });

  const { navFlag } = useSelector((state) => state.borrower);
  const analytics = getAnalytics();
  logEvent(analytics, 'home', { name: 'home'});
  React.useEffect(() => {
    // window.addEventListener("beforeunload", alertUser);
    dispatch(updateFlag(false));

    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const demo = () => {
    console.log("Discover Nest");
    dispatch(updateFlag(true));
    // setStateUpdate(1)
    setCheck(navFlag);
  };
  const handleSlide1 = () => {
    const headingItems = [
      "There is a clear divide",
      "by race on homeownership",
    ];
    const contentItems = [
      "Homeownership is a key tool for building wealth but households of ",
      "color have lower ownership due to access, education, and",
      "tooling",
    ];
    const footerItems = [
      "Urban Institute, 1990–2010 Decennial Censuses and 2007–2018 American Community Surveys.",
      "Values for 2020, 2030, and 2040 are projected values.The “Other” category includes Asians, American Indians, Alaska",
      "Native Hawaiians, other Pacific Islanders, and multiracial individuals.",
    ];
    setSlideState({
      heading: headingItems,
      content: contentItems,
      image: slide1,
      active: [true, false, false],

      footer: footerItems,
    });
    console.log("One");
  };
  // Millennials are increasingly struggling to access home ownership.
  // this gap is rising through lack of innovative finance.
  // why can Millennials afford to rent and not buy
  const handleSlide2 = () => {
    const headingItems = ["Home ownership share by age"];
    const contentItems = [
      "Millennials are increasingly struggling to access home ownership.",
      "This gap is rising through lack of innovative finance. How can  ",
      "millennials afford to rent and not buy?",
    ];
    const footerItems = [
      "Urban Institute, 1990–2010 Decennial Censuses and 2007–2018 American Community Surveys.",
      "Values for 2020, 2030, and 2040 are projected values.The “Other” category includes Asians, American Indians, Alaska",
      "Native Hawaiians, other Pacific Islanders, and multiracial individuals.",
    ];
    setSlideState({
      heading: headingItems,
      content: contentItems,
      image: slide2,
      active: [false, true, false],

      footer: footerItems,
    });
  };
  //There is a disparity in financial education - credit scores dont
  //tell the whole story and adversely impact certain segments of society,
  //preventing access to finance
  const handleSlide3 = () => {
    const headingItems = ["Do credit scores tell whole story?"];
    const contentItems = [
      "There is a disparity in financial education - credit scores dont ",
      "tell the whole story and adversely impact certain segments of society,",
      "preventing access to finance",
    ];
    const footerItems = ["Urban Institute, Freddie Mac"];
    setSlideState({
      heading: headingItems,
      content: contentItems,
      image: slide3,
      active: [false, false, true],

      footer: footerItems,
    });
    console.log("Three");
  };

  const handleOnHover = (index) => {
    if (index === 1) setVisibilityFirst(false);
    if (index === 2) setVisibilitySecond(false);
    if (index === 3) setVisibilityThird(false);
    console.log("hello");
  };

  const handleHoverOut = (index) => {
    if (index === 1) setVisibilityFirst(true);
    if (index === 2) setVisibilitySecond(true);
    if (index === 3) setVisibilityThird(true);
  };

  // console.log("new flag state",check)

  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        id="/Home"
        sx={{
          overflow: "hidden",
          width: "100%",
          background:
            "transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box",
        }}
      >
       
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LeftBlockA />
          <Box sx={{ width: "14vw", justifyContent: "center" }}>
            <Stack
              sx={{
                mr: "2.0vw",
                ml: "3.0vw",
                mt: "17.5vw",
                height: "10vh",
                width: "8vw",
              }}
              direction="row"
            >
              <Button
                sx={{
                  overflow: "hidden",
                  mt: 0,
                  zIndex: 1,
                  border: 0,
                  width: "8.0vw",
                  position: "fixed",
                  height: "10vw",
                  minHeight: 0,
                  minWidth: 0,
                  padding: "0.2vw",
                }}
                id="Discover"
                className="hexBtnCenter"
                onClick={() => {
                  demo();
                  allowed === "yes"
                    ? navigate("/Discover", { replace: false })
                    : setSeek("ddd");
                }}
              >
                <HexButton
                  titleText="Discover Nestfuel"
                  type="large"
                  fontSize="24"
                />
              </Button>
            </Stack>
            {seek == "ddd" ? <InputKey /> : <></>}
          </Box>
          < RightBlockA/>
        </Box>

        <Box
          id={"OurMission"}
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "52vw",
          }}
        >
          <Box sx={{ width: "36vw" }}>
            <Typography
              sx={{
                ml: "7.2vw",
                mt: "4.8vw",
                color: "#31312C",
                fontWeight: 300,
                font: "Roboto",
                textAlign: "left",
              }}
              fontSize="5vw"
              lineHeight={1.2}
              letterSpacing="-1.6px"
            >
              Our Mission
            </Typography>
            <Typography
              sx={{
                mt: "2vw",
                ml: "7.2vw",
                color: "#8f8f8f",
                font: "Roboto",
                fontWeight: "light",
                textAlign: "left",
              }}
              fontSize="2.4vw"
              lineHeight={1.2}
            >
              Bringing equal access to finance
            </Typography>
            <Typography
              sx={{
                mt: "2vw",
                ml: "7.2vw",
                color: "#8f8f8f",
                font: "Roboto",
                fontWeight: "light",
                textAlign: "left",
              }}
              fontSize="2.4vw"
              lineHeight={1.2}
            >
              through innovative solutions
            </Typography>
            <Box sx={{ display: "flex", marginTop: "2.24vw" }}>
              {/* <svg width={15} height={15} style={{marginLeft:'8.24vw',marginTop:'9.07vh'}}>
      <polygon
        points={{
          top: [`${15 / 2},0`, `0,${15}`, `${15},${15}`],
          right: [`0,0`, `0,${15}`, `${15},${15 / 2}`],
          bottom: [`0,0`, `${15},0`, `${15 / 2},${15}`],
          left: [`${15},0`, `${15},${15}`, `0,${15 / 2}`],
        }["right"].join(' ')}
        fill={'#44a6e8'}
      />
      
      
    
    </svg> */}
              <Box
                sx={{ width: "1.15vw", height: "1.30vw", marginLeft: "8.28vw" }}
              >
                <img src={bullet} height="100%"></img>
              </Box>

              <Box sx={{ width: "21.46vw", height: "1.93vw" }}>
                <Typography
                  sx={{
                    mt: "0vh",
                    ml: "0.50vw",
                    color: "#31312C",
                    font: "Roboto",
                    textAlign: "left",
                  }}
                  fontSize="1.5vw"
                  lineHeight={1.2}
                >
                  Enable home ownership for more people
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: "2.24vw" }}>
        
              <Box
                sx={{ width: "1.15vw", height: "1.30vw", marginLeft: "8.28vw", marginTop: "2.42vw"  }}
              >
                <img src={bullet} height="100%"></img>
              </Box>

              <Box sx={{ width: "18vw", height: "5.78vw", marginTop: "2.42vw" }}>
                <Typography
                  sx={{
                    mt: "0vh",
                    ml: ".5vw",
                    color: "#31312C",
                    font: "Roboto",
                    textAlign: "left",
                  }}
                  fontSize="1.5vw"
                  lineHeight={1.2}
                >
                  Provide greater access to home finance
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", marginTop: "2.24vw" }}>

              <Box
                sx={{ width: "1.15vw", height: "1.30vw", marginLeft: "8.28vw" }}
              >
                <img src={bullet} height="100%"></img>
              </Box>

              <Box sx={{ width: "21.46vw", marginTop: "0vh" }}>
                <Typography
                  sx={{
                    mt: "0vh",
                    ml: ".5vw",
                    color: "#31312C",
                    font: "Roboto",
                    textAlign: "left",
                  }}
                  fontSize="1.5vw"
                  lineHeight={1.2}
                >
                  Help sustain & develop local communities
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              ml: "14vw",
              width: "50vw",
              height: "50vw",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <img src={home2} alt = "Loading..." width="100%" heigh="100%" background='inherit'/>  */}
            <Box
              sx={{ marginLeft: "3.6vw", height: "43.5vw", width: "37.5vw" }}
            >
              <Box
                sx={{
                  marginLeft: "3.6vw",
                  marginTop: "5.5vw",
                  width: "30.5vw",
                  height: "7.4vw",
                }}
              >
                <Typography
                  sx={{
                    mt: "2vw",
                    color: "#000000",
                    font: "Roboto",
                    textAlign: "center",
                  }}
                  fontSize="2.3vw"
                  lineHeight={1.2}
                  letterSpacing="-1.6px"
                  fontWeight={300}
                >
                  {slideState.heading[0] ? slideState.heading[0] : ""}
                </Typography>

                <Typography
                  sx={{
                    mt: "2vw",
                    color: "#000000",
                    font: "Roboto",
                    textAlign: "center",
                  }}
                  fontSize="2.3vw"
                  lineHeight={1.2}
                  letterSpacing="-1.6px"
                  fontWeight={300}
                >
                  {slideState.heading[0] ? slideState.heading[1] : ""}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginLeft: "0vw",
                  marginTop: "1.5vw",
                  width: "37.6vw",
                  height: "6.04vw",
                }}
              >
                <Typography
                  sx={{
                    mt: "0vw",
                    color: "#868686",
                    font: "Roboto",
                    textAlign: "center",
                  }}
                  fontSize="1.24vw"
                  lineHeight={1.2}
                  letterSpacing="0px"
                  fontWeight={300}
                >
                  {slideState.content[2] ? slideState.content[0] : ""}
                </Typography>
                <Typography
                  sx={{
                    mt: "0vw",
                    color: "#868686",
                    font: "Roboto",
                    textAlign: "center",
                  }}
                  fontSize="1.24vw"
                  lineHeight={1.2}
                  letterSpacing="0px"
                  fontWeight={300}
                >
                  {slideState.content[1] ? slideState.content[1] : ""}
                </Typography>
                <Typography
                  sx={{
                    mt: "0vw",
                    color: "#868686",
                    font: "Roboto",
                    textAlign: "center",
                  }}
                  fontSize="1.24vw"
                  lineHeight={1.2}
                  letterSpacing="0px"
                  fontWeight={300}
                >
                  {slideState.content[1] ? slideState.content[2] : ""}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginLeft: "0vw",
                  marginTop: "1.18vw",
                  width: "37.6vw",
                  height: "18.6vw",
                  
                }}
              >
                <img
                  src={slideState.image}
                  alt = "Loading..."
                  width="100%"
                  heigh="100%"
                  background="inherit"
                />
              </Box>
              <Box
                sx={{
                  marginLeft: "3vw",
                  marginTop: "4.5vw",
                  width: "31.67vw",
                  height: "3vw",
                }}
              >
                <Typography
                  sx={{
                    mt: "0vw",
                    color: "#868686",
                    font: "Roboto",
                    textAlign: "center",
                  }}
                  fontSize="0.8vw"
                  lineHeight={1.3}
                  letterSpacing="-1.4px"
                  fontWeight={300}
                >
                  Sources : {slideState.footer[0] ? slideState.footer[0] : ""}
                </Typography>

                <Typography
                  sx={{
                    mt: "0vw",
                    color: "#868686",
                    font: "Roboto",
                    textAlign: "left",
                  }}
                  fontSize="0.77vw"
                  lineHeight={1.3}
                  letterSpacing="-1.56px"
                  fontWeight={300}
                >
                  <Box sx={{ display: "flex" }}>
                    <Box></Box>
                  </Box>

                  <Box></Box>

                  {slideState.footer[1] ? (
                    <span
                      sx={{
                        fontWeight: "Bold",
                        color: "black",
                        font: "Roboto",
                        textAlign: "left",
                      }}
                    >
                      Notes :{" "}
                    </span>
                  ) : (
                    ""
                  )}
                  {slideState.footer[1] ? slideState.footer[1] : ""}
                </Typography>
                <Typography
                  sx={{
                    mt: "0vw",
                    color: "#868686",
                    font: "Roboto",
                    textAlign: "center",
                  }}
                  fontSize="0.8vw"
                  lineHeight={1.3}
                  letterSpacing="-1.3px"
                  fontWeight={300}
                >
                  {slideState.footer[2] ? slideState.footer[2] : ""}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginLeft: "16.6vw",
                  marginTop: "1.67vw",
                  width: "4.2vw",
                  height: "1.18vw",
                }}
              >
                <Box
                  sx={{
                    width: ".9vw",
                    height: "0.9vw",
                    borderRadius: "50%",
                    display: "inline-block",
                    border: "1px solid #707070",
                    backgroundColor:
                      slideState.active[0] == true ? "#707070" : "#fff",
                  }}
                  onMouseEnter={handleSlide1}
                ></Box>
                <Box
                  sx={{
                    width: ".9vw",
                    height: "0.9vw",
                    borderRadius: "50%",
                    display: "inline-block",
                    border: "1px solid #707070",
                    backgroundColor:
                      slideState.active[1] == true ? "#707070" : "#fff",
                  }}
                  onMouseEnter={handleSlide2}
                ></Box>
                <Box
                  sx={{
                    width: ".9vw",
                    height: "0.9vw",
                    borderRadius: "50%",
                    display: "inline-block",
                    border: "1px solid #707070",
                    backgroundColor:
                      slideState.active[2] == true ? "#707070" : "#fff",
                  }}
                  onMouseEnter={handleSlide3}
                ></Box>
              </Box>
            </Box>
          </Box>
          {/* <Box sx={{marginLeft:}}></Box> */}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LeftBlockB />
          <RightBlockB />
        </Box>

        <Box
          id="Experience"
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "51vw",
          }}
        >
          <Box sx={{ width: "36vw" }}>
            <Typography
              sx={{
                ml: "7.2vw",
                mt: "6vw",
                color: "#31312C",
                fontWeight: 300,
                font: "Roboto",
                textAlign: "left",
              }}
              fontSize="5vw"
              lineHeight={1.2}
              letterSpacing="-1.6px"
            >
              One-Stop
            </Typography>
            <Typography
              sx={{
                ml: "7.2vw",
                color: "#31312C",
                fontWeight: 300,
                font: "Roboto",
                textAlign: "left",
              }}
              fontSize="5vw"
              lineHeight={1.2}
              letterSpacing="-1.6px"
            >
              Shop
            </Typography>
            <Typography
              sx={{
                mt: "3.6vh",
                ml: "7.2vw",
                color: "#8f8f8f",
                font: "Roboto",
                textAlign: "left",
              }}
              fontSize="2.5vw"
              lineHeight={1.2}
              fontWeight={300}
            >
              A unique innovative
            </Typography>
            <Typography
              sx={{
                mt: "2vw",
                ml: "7.2vw",
                color: "#8f8f8f",
                font: "Roboto",
                textAlign: "left",
              }}
              fontSize="2.5vw"
              lineHeight={1.2}
              fontWeight={300}
            >
              platform for your
            </Typography>
            <Typography
              sx={{
                mt: "2vw",
                ml: "7.2vw",
                color: "#8f8f8f",
                font: "Roboto",
                textAlign: "left",
              }}
              fontSize="2.5vw"
              lineHeight={1.2}
              fontWeight={300}
            >
              home journey
            </Typography>
          </Box>
          <Box sx={{ ml: "14vw", width: "50vw", overflow: "hidden" }}>
            {/* <img src={home4} alt = "Loading..." width="100%" heigh="100%"/> */}

            {/* main working */}

            <Box sx={{ marginLeft: "4.22vw", marginTop: "1.15vw" }}>
              <Box
                class="main-hover"
                onMouseEnter={() => {
                  handleOnHover(1);
                }}
                onMouseLeave={() => {
                  handleHoverOut(1);
                }}
              >
                <Box class="inner_both">
                  <Box class="inner-one">
                    <span class="circle-img"></span>
                    <Typography
                      sx={{ color: "#FFFFFF", fontSize: "1.15vw" }}
                      className="innerText"
                    >
                      - Check your home affordability while you search
                      <br /> <br />- Match financing for your dream home and
                      obtain pre-approval for your mortgage
                    </Typography>
                  </Box>
                  <Box class="inner-two">
                    <Box
                      class="circle-img">
                    {
                      visibilityFirst?
                      <Box
                      sx={{
                        marginTop: "3vw",
                        height: "5.42vw",
                        width: "6.34vw",
                      }}
                    >
                      <img src={rightSvg1} height="100%"></img>
                    </Box>:
                      <Box
                      sx={{
                        marginTop: "-1.5vw",
                        marginLeft:'-0.5vw',
                        height: "5.42vw",
                        width: "6.34vw",
                      }}
                    >
                      <img src={rightSvg1} height="100%"></img>
                    </Box>
                    }
                    </Box>
                    <Box
                      className="innerText"
                      sx={{ marginLeft: "2vw", width: "35vw" }}
                    >
                      <Typography
                        sx={{
                          color: "#8F8F8F",
                          fontSize: "2.50vw",
                          fontWeight: "medium",
                          textAlign: "right",
                        }}
                      >
                        Find & Finance your <br></br> home simultaneously
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* here again */}

            {/* <Box sx={{marginLeft:'4.22vw',marginTop:'1.15vw'}} > */}

            {/* <div class="main-hover righ-hover" onMouseEnter={()=>{handleOnHover(2)}} onMouseOut={()=>{handleHoverOut(2)}}>
    <div class="inner_both">
        <div class="inner-one">
            <span class="circle-img"></span>
            <Typography sx={{color:'#FFFFFF',fontSize:'1.15vw'}} className="innerText">
            - Check your home affordability while you search<br/>- Match financing for your dream home and obtain pre-approval for your mortgage
            </Typography>
        </div>
        <div class="inner-two">
        <Box  class="circle-img" onMouseEnter={()=>{setVisibilityFirst(visibilityFirst)}}  onMouseOut={()=>{setVisibilityFirst(visibilityFirst)}} >
          
            </Box>
            <Box className="innerTextRight" sx={{color:'#8F8F8F'}}>
              Find & Finance your home simultaneously
              </Box>
            <p></p>
        </div>
    </div>
</div> */}

            {/* here  */}

            <Box sx={{ marginLeft: "4.22vw", marginTop: "1.15vw" }}onMouseEnter={()=>{handleOnHover(2)}} onMouseLeave={()=>{handleHoverOut(2)}}>
              <Box class="main-hover-right right-hover">
                <Box class="inner_both">
                  <Box class="inner-one">
                    <span class="circle-img"></span>
                    <Typography
                      sx={{
                        marginLeft: "2vw",
                        color: "#FFFFFF",
                        fontSize: "1.15vw",
                        fontWeight: "normal",
                        fontFamily: "Roboto",
                      }}
                      className="innerText"
                    >
                      - Smart financing matched to your current and future
                      economic path
                      <br /> <br />- Deliver best customer experience we can
                      with tailored financing
                    </Typography>
                  </Box>
                  <Box class="inner-two">
                 {
                  visibilitySecond ?
                  <Box
                  class="circle-img"
                >
                  <Box
                    sx={{
                      marginTop: "-1vw",
                      marginLeft:'-0.5vw',
                      height: "5.42vw",
                      width: "6.34vw",
                    }}
                  >
                    <img src={rightSvg2} height="100%"></img>
                  </Box>
                </Box>:
                   <Box
                   class="circle-img"
                 >
                   <Box
                     sx={{
                       marginTop: "1.5vw",
                       height: "5.42vw",
                       width: "6.34vw",
                     }}
                   >
                     <img src={rightSvg2} height="100%"></img>
                   </Box>
                 </Box>
                 }
                    <Box
                      className="innerText"
                      sx={{
                        marginLeft: "-2vw",
                        width: "35vw",
                        height: "6.56vw",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#8F8F8F",
                          fontSize: "2.50vw",
                          fontWeight: "medium",
                          textAlign: "center",
                          fontFamily: "Roboto",
                        }}
                      >
                        Home financing tailored<br></br> to your personal
                        situation
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            {visibilityThird ? (
              <Box
                sx={{
                  marginLeft: "4.22vw",
                  marginTop: "1.51vw",
                  width: "41.88vw",
                  height: "13.91VW",
                  borderRadius: "2.08vw",
                  boxShadow: "0px 0.31vw 0.78vw #00000024",
                  position: "relative",
                }}
                onMouseEnter={() => {
                  handleOnHover(3);
                }}
                onMouseLeave={() => {
                  handleHoverOut(3);
                }}
              >
                <Box sx={{ dispaly: "flex", position: "relative" }}>
                  <Box
                    sx={{
                      marginLeft: "4.53vw",
                      marginTop: "2.71vw",
                      width: "6.41vw",
                      height: "6.35vw",
                      position: "absolute",
                    }}
                  >
                    <img src={circleSvg} height="100%"></img>
                    <Box
                      sx={{
                        marginLeft: "-1.53vw",
                        marginTop: "-6.5vw",
                        width: "5.10vw",
                        height: "5.51vw",
                        position: "absolute",
                      }}
                    >
                      <img src={rightSvg3} height="100%"></img>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      marginLeft: "17.92vw",
                      marginTop: "3.28vw",
                      width: "18.88vw",
                      height: "6.56vw",
                      position: "absolute",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#8F8F8F",
                        fontSize: "2.50vw",
                        fontWeight: "medium",
                        textAlign: "center",
                        fontFamily: "Roboto",
                      }}
                      lineHeight="3.28vw"
                      letterSpacing="-0.12px"
                    >
                      Digital & Modern Experience
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  marginLeft: "4.22vw",
                  marginTop: "1.51vw",
                  width: "41.88vw",
                  height: "13.91VW",
                  borderRadius: "2.08vw",
                  background: "#31312C",
                  boxShadow: "0px 0.31vw 0.78vw #00000024",
                  position: "relative",
                }}
                onMouseEnter={() => {
                  handleOnHover(3);
                }}
                onMouseLeave={() => {
                  handleHoverOut(3);
                }}
              >
                <Box sx={{ dispaly: "flex", position: "relative" }}>
                  <Box
                    sx={{
                      marginLeft: "6.53vw",
                      marginTop: "4.71vw",
                      width: "6.41vw",
                      height: "6.35vw",
                      position: "absolute",
                    }}
                  >
                    <img src={circleSvgDark} height="100%"></img>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: "3vw",
                      marginTop: "2.5vw",
                      width: "5.10vw",
                      height: "5.51vw",
                      position: "absolute",
                    }}
                  >
                    <img src={rightSvg3} height="100%"></img>
                  </Box>

                  <Box
                    sx={{
                      marginLeft: "15vw",
                      marginTop: "6vw",
                      width: "18.88vw",
                      height: "6.56vw",
                      position: "absolute",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "1.15vw",
                        fontWeight: "normal",
                        textAlign: "left",
                        fontFamily: "Roboto",
                      }}
                      lineHeight="0vw"
                      letterSpacing="0px"
                    >
                      {" "}
                      - Leading edge technology
                    </Typography>
                    <Typography
                      sx={{
                        mt: "3vw",
                        color: "#FFFFFF",
                        fontSize: "1.15vw",
                        fontWeight: "normal",
                        textAlign: "left",
                        fontFamily: "Roboto",
                      }}
                      lineHeight="0vw"
                      letterSpacing="0px"
                    >
                      {" "}
                      - Single, easy to use platform
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <LeftBlockC />
          <RightBlockC />
        </Box>
      </Box>
      <Box className={classes.bottomBar}>
        <BottomBox />
      </Box>
    </React.Fragment>
  );
}

function RightBlockA() {
  const [content, setContent] = React.useState("Empower Home Owners");
  const [counter, setCounter] = React.useState(0);
  const rightContentA = [
    "Empower Home Owners",
    "Enhance Home Purchasing Power",
    "Delivered by Innovative Finance",
    "Fueling your Employer Benefits",
    "Increase Home   Affordability",
  ];
  if (counter > 4) {
    setCounter(0);
  }
  const handleContentUpdate = () => {
    if (counter !== 5) {
      setContent(rightContentA[counter]);
      setCounter(counter + 1);
    }
  };

  const handleChanges = () => {
    const contentUpdate = () => {
      if (counter !== 5) {
        setContent(rightContentA[counter]);
        setCounter(counter + 1);
      }
      setInterval(contentUpdate(), 1000);
      console.log("update");
    };
  };

  return (
    <>
      <Box
        sx={{
          background: "#F3F3F3 0% 0% no-repeat padding-box",
          overflow: "hidden",
          boxShadow: "-1px -6px 46px #0000003D",
          opacity: 1,
          width: "50vw",
          height: "46.75vw",
        }}
      >
        {/* <img style={{marginTop:'-2.4vw'}} src={home1} alt = "Loading..." width='100%' heigh="100%"/>    */}
        <Box
          sx={{
            background: "0% 0% no-repeat padding-box",
            overflow: "hidden",
            height: "25.67vh",
            width: "39.11vw",
            opacity: 1,
            marginTop: "13.7vh",
            marginLeft: "7.35vw",
            marginRight: "13.44vw",
            color: "#8f8f8f",
            cursor: "pointer",
            font: "Roboto",
            textAlign: "left",
            fontSize: "8.24vh",
            fontWeight: "bold",
          }}
          onClick={handleContentUpdate}
        >
          {content}
        </Box>
        <Box sx={{ height: "56.48vh", width: "60.63vw" }}>
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                width: "12.82vw",
                height: "25.43vh",
                marginLeft: "12.63vw",
                marginTop: "5.26vh",
              }}
              onMouseEnter={() => {
                setCounter(0);
                setContent("Empower Home Owners");
              }}
              onMouseOut={() => {
                setCounter(0);
              }}
            >
              {counter === 0 ? (
                <img
                  style={{ marginTop: "-2.4vw" }}
                  src={hexa1}
                  alt = "Loading..."
                  width="100%"
                  heigh="100%"
                />
              ) : (
                <img
                  style={{ marginTop: "-2.4vw" }}
                  src={hexa_fade1}
                  alt = "Loading..."
                  width="100%"
                  heigh="100%"
                />
              )}
            </Box>
            <Box
              sx={{
                width: "12.82vw",
                height: "25.43",
                marginLeft: "0.33vw",
                marginTop: "5.26vh",
              }}
              onMouseEnter={() => {
                setCounter(1);
                setContent("Enhance Home Purchasing Power");
              }}
              onMouseOut={() => {
                setCounter(1);
              }}
            >
              {counter === 1 ? (
                <img
                  style={{ marginTop: "-2.4vw" }}
                  src={hexa2}
                  alt = "Loading..."
                  width="100%"
                  heigh="100%"
                />
              ) : (
                <img
                  style={{ marginTop: "-2.4vw" }}
                  src={hexa_fade2}
                  alt = "Loading..."
                  width="100%"
                  heigh="100%"
                />
              )}
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  width: "12.82vw",
                  height: "25.43",
                  marginLeft: "6.15vw",
                  marginRigh: "31.46vw",
                  marginTop: "-1vw",
                }}
                onMouseEnter={() => {
                  setCounter(2);
                  setContent("Delivered by Innovative Finance");
                }}
                onMouseOut={() => {
                  setCounter(2);
                }}
              >
                {counter === 2 ? (
                  <img
                    style={{ marginTop: "-2.4vw" }}
                    src={hexa3}
                    alt = "Loading..."
                    width="100%"
                    heigh="100%"
                  />
                ) : (
                  <img
                    style={{ marginTop: "-2.4vw" }}
                    src={hexa_fade3}
                    alt = "Loading..."
                    width="100%"
                    heigh="100%"
                  />
                )}
              </Box>
              <Box
                sx={{
                  width: "12.82vw",
                  height: "25.43",
                  marginLeft: "0.33vw",
                  marginTop: "-1vw",
                }}
                onMouseEnter={() => {
                  setCounter(3);
                  setContent("Fueling your Employer Benefits");
                }}
                onMouseOut={() => {
                  setCounter(3);
                }}
              >
                {counter === 3 ? (
                  <img
                    style={{ marginTop: "-2.4vw" }}
                    src={hexa4}
                    alt = "Loading..."
                    width="100%"
                    heigh="100%"
                  />
                ) : (
                  <img
                    style={{ marginTop: "-2.4vw" }}
                    src={hexa_fade4}
                    alt = "Loading..."
                    width="100%"
                    heigh="100%"
                  />
                )}
              </Box>
              <Box
                sx={{
                  width: "12.82vw",
                  height: "14.30vw",
                  marginLeft: "0.33vw",
                  marginTop: "-1vw",
                  marginBottom: "3.12vw",
                }}
                onMouseEnter={() => {
                  setCounter(4);
                  setContent("Increase Home   Affordability");
                }}
                onMouseOut={() => {
                  setCounter(4);
                }}
              >
                {counter === 4 ? (
                  <img
                    style={{ marginTop: "-2.4vw" }}
                    src={hexa5}
                    alt = "Loading..."
                    width="100%"
                    heigh="100%"
                  />
                ) : (
                  <img
                    style={{ marginTop: "-2.4vw" }}
                    src={hexa_fade5}
                    alt = "Loading..."
                    width="100%"
                    heigh="100%"
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function RightBlockB() {
  return (
    <Box
      sx={{
        ml: "14vw",
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        overflow: "hidden",
        boxShadow: "-1px -6px 46px #0000003D",
        opacity: 1,
        width: "50vw",
        height: "50vw",
      }}
    >
      <video
        style={{ marginLeft: "-.1vw" }}
        loop
        autoPlay={true}
        muted
        width="100%"
        heigh="100%"
        object_fit="cover"
      >
        {" "}
        <source src={home3} alt="video not found" />{" "}
      </video>
    </Box>
  );
}

function RightBlockC() {
  let [check, setCheck] = React.useState(0);
  const NUMBER_OF_PICTURES = 4;
  const [content, setContent] = React.useState("Empower Home Owners");
  const [counter, setCounter] = React.useState(0);
  const rightContentA = [
    "Empower Home Owners",
    "Enhance Home Purchasing Power",
    "Delivered by Innovative Finance",
    "Fueling your Employer Benefits",
    "Increase Home   Affordability",
  ];
  React.useEffect(() => {
    console.log("from the useEffect");

    const intervalId = setInterval(() => {
      setCheck((prevIndex) => prevIndex + 1);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <Box
      sx={{
        ml: "14vw",
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        pt: "1vw",
        boxShadow: "-1px -6px 46px #0000003D",
        opacity: 1,
        width: "50vw",
        height: "36vw",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "42.29vw",
          height: "33.49vw",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "7.06vw",
          marginLeft: "4.8vw",
          marginRight: "4.8vw",
        }}
      >
        <video
          style={{ borderRadius: "0.5vw" }}
          controls
          borderRadius="20px"
          overflow="hidden"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="auto"
        >
          {" "}
          <source src={home5} alt="video not found" />{" "}
        </video>
      </Box>
    </Box>
  );
}
function LeftBlockA() {
  return (
    <Box
      sx={{
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        position: "relative",
        boxShadow: "1px 6px 46px #0000003D",
        opacity: 1,
        width: "36vw",
        height: "46.75vw",
      }}
    >
      <Box sx={{ mt: "6.7vw", ml: "7.2vw", width: "28vw", height: "25vw" }}>
        <Typography
          sx={{
            color: "#8f8f8f",
            width: "16.2vw",
            heigth: "45.5vh",
            fontSize: "4.5vw",
            fontFamily: "Roboto",
            fontWeight: 500,
            textAlign: "left",
            lineHeight: 1.55,
            letterSpacing: "-0.13vw",
          }}
        >
          Bigger
        </Typography>
        <Typography
          sx={{
            color: "#8f8f8f",
            fontSize: "4.5vw",
            fontFamily: "Roboto",
            fontWeight: 500,
            textAlign: "left",
            lineHeight: 1.55,
            letterSpacing: "-0.13vw",
          }}
        >
          Brighter
        </Typography>
        <Typography
          sx={{
            color: "#8f8f8f",
            fontSize: "4.5vw",
            fontFamily: "Roboto",
            fontWeight: 500,
            textAlign: "left",
            lineHeight: 1.55,
            letterSpacing: "-0.13vw",
          }}
        >
          Better
        </Typography>
        <Typography
          sx={{
            color: "#8f8f8f",
            fontSize: "4.5vw",
            fontFamily: "Roboto",
            fontWeight: 500,
            textAlign: "left",
            lineHeight: 1.55,
            letterSpacing: "-0.13vw",
          }}
        >
          Homes
        </Typography>
      </Box>
      <LeftbottomA leftbottom={leftbottom} />
    </Box>
  );
}
function LeftBlockB() {
  return (
    <Box
      id="Community"
      sx={{
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        boxShadow: "1px 6px 46px #0000003D",
        opacity: 1,
        width: "36vw",
        height: "50vw",
      }}
    >
      <Box sx={{ width: "36vw" }}>
        <Typography
          sx={{
            ml: "7.2vw",
            mt: "6vw",
            color: "#000000",
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="4.6vw"
          lineHeight={1.2}
          letterSpacing="-1.6px"
          fontWeight={300}
        >
          Power of the Community
        </Typography>
        <Typography
          sx={{
            ml: "7.2vw",
            mt: "2vw",
            color: "#8f8f8f",
            letterSpacing: "-.5px",
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="2.5vw"
          lineHeight={1.2}
          fontWeight={300}
        >
          Community roots begin
        </Typography>
        <Typography
          sx={{
            ml: "7.2vw",
            mt: "1vw",
            color: "#8f8f8f",
            letterSpacing: "-.5px",
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="2.5vw"
          lineHeight={1.2}
          fontWeight={300}
        >
          with homeowners
        </Typography>
      </Box>
    </Box>
  );
}

function LeftBlockC() {
  return (
    <Box
      id="OneMinute"
      sx={{
        background: "#F3F3F3 0% 0% no-repeat padding-box",
        boxShadow: "1px 6px 46px #0000003D",
        opacity: 1,
        width: "36vw",
        height: "36vw",
      }}
    >
      <Box sx={{ ml: "1.2vw", mt: 10, width: "33vw" }}>
        <Typography
          sx={{
            color: "#000000",
            ml: "6vw",
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="5vw"
          lineHeight={1.2}
          letterSpacing="-1.6px"
          fontWeight={300}
        >
          Nestfuel in
        </Typography>
        <Typography
          sx={{
            color: "#000000",
            ml: "6vw",
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="5vw"
          lineHeight={1.2}
          letterSpacing="-1.6px"
          fontWeight={300}
        >
          1 minute
        </Typography>
        <Typography
          sx={{
            mt: "1.6vw",
            ml: "6vw",
            color: "#8f8f8f",
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="2.5vw"
          lineHeight={1.2}
          fontWeight={300}
        >
          Discover how
        </Typography>
        <Typography
          sx={{
            mt: "1.2vw",
            ml: "6vw",
            color: "#8f8f8f",
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="2.5vw"
          lineHeight={1.2}
          fontWeight={300}
        >
          Nestfuel
        </Typography>
        <Typography
          sx={{
            mt: "1.2vw",
            ml: "6vw",
            color: "#8f8f8f",
            font: "Roboto",
            textAlign: "left",
          }}
          fontSize="2.5vw"
          lineHeight={1.2}
          fontWeight={300}
        >
          can help you
        </Typography>
      </Box>
    </Box>
  );
}

function InputKey() {
  const name = "password";
  const type = "password";
  const [cur, setCur] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);

  function onClickFun(e) {
    const val1 = e.currentTarget.value;
    val1 === "N3$tfu3L" ? (allowed = "yes") : (allowed = "no");
  }

  return (
    <div position="fixed" align="center">
      <Typography sx={{ fontSize: "1vw", width: "12vw" }}>
        (Please answer the following to activae DISCOVER NESTFUEL)
      </Typography>
      <Typography sx={{ mt: 2, fontSize: "1vw", width: "12vw" }}></Typography>
      <TextField
        sx={{
          fontsize: "0.5vw",
          mt: 1,
          width: "12vw",
          border: 1,
          borderColor: "black",
          borderRadius: 2,
        }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label="No flying ends Indian penny"
        autoFocus={true}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default Home;
