import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button, Input, Divider } from "@mui/material";
import * as api from '../../api/index';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import bedicon from '../../assets/svg/bed-svgrepo-com.svg';
import bathicon from '../../assets/svg/shower-head-svgrepo-com.svg'
import garageicon from '../../assets/svg/garage-svgrepo-com.svg'
import demoprop1 from '../../assets/demoprop1.jpg'
import demoprop2 from '../../assets/demoprop2.jpg'
import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/Group 15540.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ItemsCarousel from "react-items-carousel";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import leftArrow from '../../assets/svg/left-arrow.svg'
import rightArrow from '../../assets/svg/right-arrow.svg'
// import ReactPlayer from 'react-player';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { Centerbottom, LeftbottomA } from "./RightBlock";
import { getAnalytics, logEvent } from "firebase/analytics";
import { employeeMortgageBenifitProfileUpdate, borrowerfinancialProfile, updateEmbFlow, employeeMortgageBenifitIdentifier, borrowerChoiceA, updateBorrowerFlow, borrowerChoiceD } from "../../actions/borrower";
import { useSearchParams,Link } from "react-router-dom"
import { RightBlockCompare, RightBlockCompareEmp } from "./RightBlockCompare";
import { maxMortgageNQM92E, maxMortgageQM } from "./HelperFunctions";
import { PROPROP, SELPROP, _debug, withCommas, withCommasWithoutCurrency, START_LOADING, END_LOADING } from "../../constants";
import { borrowerPostPropertyData } from "../../actions/borrower";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { rspvw } from "./styles";
import { borderRadius, width } from "@mui/system";
import { slice } from "lodash";
import Googlemaps from "./Googlemaps";


export function EmployeeMortgageBenefit() {


  const empInfo = {
    name: 'MicroSoft',
    displayName: 'MS',
    employerIdentifier: 'Yes',
    assumedSalary: 90000,
    assumedFico: 820,
    assumedLtv: 90,
    assumedUpfront: 50000,
    saving: 3600,
    empPayPoints: ''
  }
  const { selectedProp,
    proposedProp,
    isLoading,
    isEmployerLoading,
    bChoiceDSF,
    bfinancialProfile,
    bChoiceA,
    bChoiceB,
    bChoiceC,
    bPropertyDetail,
    employeeMortgageBenifitProfile

  } = useSelector((state) => state.borrower);

  const [searchParams, setSearchParams] = useSearchParams();
  // single-time read
  const params = Object.fromEntries([...searchParams]);
  console.log('Mounted:', params);

React.useEffect(()=>{
  updateBorrowerFlow(false)
  if (Object.keys(selectedProp).length==0){
    dispatch(borrowerChoiceD( bChoiceC.name,'purchase',
    bChoiceC.position?.latitude,
    bChoiceC.position?.longiitude,
    bChoiceC.locality,
    bfinancialProfile?.income?.upfront,
    bfinancialProfile.income.annualIncome,
    '00','primary'))
  }
},[])



  React.useEffect(() => {
    console.log("here===>", employeeMortgageBenifitProfile)
    // dispatch(borrowerChoiceA("finance"))
    dispatch({ type: START_LOADING });

    async function fetchEmployeeData() {
      // const response = {
      //     "_id": {
      //       "$oid": "641a99d30c78ed46859b3526"
      //     },
      //     "name": "Accenture ltd",
      //     "displayName": "Accenture",
      //     "employerIdentifier": "ACC",
      //     "assumedSalary": 200000,
      //     "assumedFico": 700,
      //     "assumedLtv": 90,
      //     "assumedUpfront": 70000,
      //     "saving": 8000,
      //     "assumedOutgoing": 600,
      //     "address": {
      //       "lat": 39.7310435,
      //       "lon": -104.8755231,
      //       "city": "Denver county",
      //       "searchString": "East 10th Avenue, East, Denver, CO, USA",
      //       "stateCode": "CO",
      //       "countryCode": "USA",
      //       "queryAddress": "Denver"
      //     } 

      //   }
      //   const defaultupfront = isNaN(bfinancialProfile.income.upfront) || bfinancialProfile.income.upfront < bfinancialProfile.income.annualIncome*0.3 ? Math.floor(bfinancialProfile.income.annualIncome*0.3) : bfinancialProfile.income.upfront 
      // const income = {...bfinancialProfile.income,annualIncome:response.assumedSalary, upfront:response.assumedUpfront, fico:response.assumedFico};
      // dispatch(employeeMortgageBenifitProfileUpdate(response))
      // dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income}));
      // dispatch(updateEmbFlow(true))
      // dispatch(employeeMortgageBenifitIdentifier(response.employerIdentifier))


      try {
        let response = await api.getEmployeeDetails(params.employerIdentifier)
        response = await response.data
        const defaultupfront = isNaN(bfinancialProfile.income.upfront) || bfinancialProfile.income.upfront < bfinancialProfile.income.annualIncome * 0.3 ? Math.floor(bfinancialProfile.income.annualIncome * 0.3) : bfinancialProfile.income.upfront
        const income = { ...bfinancialProfile.income, annualIncome: response.assumedSalary, upfront: response.assumedUpfront, ficoScore: response.assumedFico };
        dispatch(employeeMortgageBenifitProfileUpdate(response))
        dispatch(borrowerfinancialProfile({ ...bfinancialProfile, income: income }));
        dispatch(updateEmbFlow(true))
        dispatch(employeeMortgageBenifitIdentifier({ "employerIdentifier": response.employerIdentifier }))




        console.log("HERE IS RESPONSE",response)
      }
      catch (error) {
        console.log(error)
      }

    }
    fetchEmployeeData()
  }, [])
  var propFinRequest;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [indexS, setIndexS] = React.useState(selectedProp.propId)
  const [indexP, setIndexP] = React.useState(proposedProp.propId)
  const [tTextS, setTTextS] = React.useState('Smart Traditional')
  const [tTextP, setTTextP] = React.useState('Smart Traditional')
  const [typeS, setTypeS] = React.useState("Trad")
  const [typeP, setTypeP] = React.useState("Trad")
 
  const [sbenOn, setSbenOn] = React.useState(false)
  const [pbenOn, setPbenOn] = React.useState(false)
  const [abenOn, setAbenOn] = React.useState(selectedProp?.finOpt === 4 ? true : false)

  const { height, width } = useWindowDimensions();
  const [marginT, setMarginT] = React.useState('0px')
  const [marginL, setMarginL] = React.useState('0px')
  React.useEffect(() => {
    const calc1 = Math.ceil(height * 0.5 - 0.28125 * width)
    const calc2 = Math.ceil(width * 0.5 - 1.78 * height)
    setMarginT(String(calc1 >= 0 ? calc1 : 0).concat('px'))
    setMarginL(String(calc2 >= 0 ? calc2 : 0).concat('px'))

  }, [height, width])
  //   const analytics = getAnalytics();
  //   logEvent(analytics, 'employersupportdtreg', { name: 'employersupportdtreg'});
  //  s


  //   function onClickFun(e) {
  //     e.preventDefault();
  //     const buttonId = e.currentTarget.id;
  //     switch (buttonId) {
  //       case "change": navigate("/UpdateOfferPrice", { replace: false }); break;
  //       case "selected":
  //       case "alternative":
  //         const pindex = selectedProp.propId
  //         const findex = selectedProp.finOpt
  //         dispatch({ type: PROPROP, payload: selectedProp })
  //         const address = {
  //           city: selectedProp.prop.address,
  //           state: selectedProp.prop.city,
  //           street: selectedProp.prop.state,
  //           zipCode: selectedProp.prop.zipcode
  //         }
  //         const thumbnail = selectedProp.prop.thumbnail
  //         const mlsId = selectedProp.prop.mlsId
  //         const selectedProperty = { ...bPropertyDetail.selectedProperty, address: address, thumbnail: thumbnail, mlsId: mlsId }

  //         const mortgageDetails = {
  //           apr: selectedProp?.prop.mortgageDetails?.apr || selectedProp.prop.mortgageDetails.interestRate + 0.25,
  //           closingCost: selectedProp?.prop.mortgageDetails.closingCost,
  //           financeType: bChoiceDSF[pindex][findex]?.financeType,
  //           insurance: selectedProp?.prop.mortgageDetails.insurance,
  //           interestRate: bChoiceDSF[pindex][findex]?.interestRate,
  //           loanAmount: bChoiceDSF[pindex][findex]?.loanAmount,
  //           loanTerm: bChoiceDSF[pindex][findex]?.loanTerm,
  //           monthlyEMI: bChoiceDSF[pindex][findex]?.monthlyEMI,
  //           mortgageType: bChoiceDSF[pindex][findex]?.mortgageType,
  //           offerPrice: selectedProp?.prop?.offerPrice,
  //           percentLTV: bChoiceDSF[pindex][findex]?.percentLTV,
  //           pmi: selectedProp?.prop.mortgageDetails.pmi,
  //           price: selectedProp?.prop.mortgageDetails.propertyPrice,
  //           propTax: selectedProp?.prop.mortgageDetails.propTax,
  //           totalCost: bChoiceDSF[pindex][findex]?.totalCost,
  //           upfront: bChoiceDSF[pindex][findex]?.upfront,
  //           nestFuelFinanceOption: bChoiceDSF[pindex][findex]?.label,
  //         }
  //         const propertyViewed = bPropertyDetail.propertyViewed === undefined ? [] : bPropertyDetail.propertyViewed
  //         propertyViewed.push(selectedProperty)
  //         dispatch(borrowerPostPropertyData({
  //           ...bPropertyDetail, selectedProperty: selectedProperty,
  //           mortgageDetails: mortgageDetails,
  //           propertiesViewed: propertyViewed
  //         }));
  //         navigate("/SmartChoice", { state: { name: buttonId } }, { replace: false });
  //         break;
  //       case "proposed":
  //         dispatch({ type: SELPROP, payload: proposedProp })
  //         const _address = {
  //           city: proposedProp.prop.address,
  //           state: proposedProp.prop.city,
  //           street: proposedProp.prop.state,
  //           zipCode: proposedProp.prop.zipcode
  //         }
  //         const _pindex = proposedProp.propId
  //         const _findex = proposedProp.finOpt
  //         const _thumbnail = proposedProp.prop.thumbnail
  //         const _mlsId = proposedProp.prop.mlsId
  //         const _selectedProperty = { ...bPropertyDetail.selectedProperty, address: _address, thumbnail: _thumbnail, mlsId: _mlsId }

  //         const _mortgageDetails = {
  //           apr: proposedProp.prop.mortgageDetails?.apr || proposedProp.prop.mortgageDetails.interestRate + 0.25,
  //           closingCost: proposedProp.prop.mortgageDetails.closingCost,
  //           financeType: bChoiceDSF[_pindex][_findex]?.financeType,
  //           insurance: proposedProp.prop.mortgageDetails.insurance,
  //           interestRate: bChoiceDSF[_pindex][_findex]?.interestRate,
  //           loanAmount: bChoiceDSF[_pindex][_findex]?.loanAmount,
  //           loanTerm: bChoiceDSF[_pindex][_findex]?.loanTerm,
  //           monthlyEMI: bChoiceDSF[_pindex][_findex]?.monthlyEMI,
  //           mortgageType: bChoiceDSF[_pindex][_findex]?.mortgageType,
  //           offerPrice: proposedProp?.prop?.offerPrice,
  //           percentLTV: bChoiceDSF[_pindex][_findex]?.percentLTV,
  //           pmi: proposedProp.prop.mortgageDetails.pmi,
  //           price: proposedProp.prop.mortgageDetails.propertyPrice,
  //           propTax: proposedProp.prop.mortgageDetails.propTax,
  //           totalCost: bChoiceDSF[_pindex][_findex]?.totalCost,
  //           upfront: bChoiceDSF[_pindex][_findex]?.upfront,
  //           nestFuelFinanceOption: bChoiceDSF[_pindex][_findex]?.label,
  //         }
  //         const _propertyViewed = bPropertyDetail.propertyViewed === undefined ? [] : bPropertyDetail.propertyViewed
  //         _propertyViewed.push(_selectedProperty)
  //         dispatch(borrowerPostPropertyData({
  //           ...bPropertyDetail, selectedProperty: _selectedProperty,
  //           mortgageDetails: _mortgageDetails,
  //           propertiesViewed: _propertyViewed
  //         }));
  //         navigate("/SmartChoice", { state: { name: 'proposed' } }, { replace: false });
  //         break;

  //       default:
  //         break;
  //     }
  //   }

  function onClickFun(e) {
    e.preventDefault();
    console.log("Clicked")
    // const buttonId = e.currentTarget.id;
    //   switch(buttonId) {
    //     case "First": dispatch(borrowerChoiceB("firsthome"));break;
    //     case "Next": dispatch(borrowerChoiceB("nexthome"));break;
    //     case "Invest": dispatch(borrowerChoiceB("investment")); break;
    //     case "Holiday":dispatch(borrowerChoiceB("holidayhome"));break;
    //     default:break;
    //   }
    navigate("/SearchProperty", { replace: false });
  }
  var centerButtonCss = `svg.base text#textbutton { font-weight:500 !important; }
    svg.TradS text#textbutton { font-weight:500 !important; }
    svg.TradP text#textbutton { font-weight:500 !important; }
    svg.disabled text#textbutton { font-weight:500 !important; }`;

  return (
    isLoading ? <CircularProgress /> : (
      <React.Fragment> <CssBaseline />
        <Box mt={marginT} ml={marginL} sx={{ overflow: 'hidden', width: '100%', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
          <Navbar pathname={location.pathname} />
          <Box sx={{ display: 'flex', flexDirection: 'row', p: 0, m: 0, borderRadius: 1, }}>
            {location.hash === "#messenger" ? <LeftBlockMsg /> : <LeftBlock />}
            {/* <Box sx={{ width: '14vw', height: '46.75vw', display: 'block', alignItems: 'center', position: 'relative' }}>
              <Typography sx={{ color: '#31312C', mt: '11.3vw', fontSize: '1.4vw', textAlign: 'center' }}>
                Select Property</Typography>
              <div align='center'>
                <style>
                  {centerButtonCss}
                </style>
                <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="change" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Change Offer&nbsp;Price" type='base' fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="selected" className='hexBtnCenter' disabled={!sbenOn}
                    onClick={onClickFun}>
                    <HexButton titleText={tTextS.split(" ").splice(0, 3).join(" ")} type={typeS} fontSize='24' /></Button>
                  <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="proposed" className='hexBtnCenter' disabled={!pbenOn}
                    onClick={onClickFun}>
                    <HexButton titleText={tTextP.split(" ").splice(0, 3).join(" ")} type={typeP} fontSize='24' /></Button>
                </Stack>
                // <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                //     <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="alternative" disabled={!abenOn}
                //       onClick={onClickFun}>
                //       <HexButton titleText="Alternative Finance" type={abenOn ? 'question' : 'disabled'} fontSize='24' /></Button>
                //   </Stack>
                <Centerbottom navigateForward="EmployerSupportDtReg" />
              </div>

            </Box> */}

            <Box sx={{ width: '14vw', height:'46.75vw', display: 'block', alignItems: 'center', position: 'relative' }}>
              <div align='center' position='relative'>
                <Box sx={{ width: `${rspvw(200)}`, height: `${rspvw(58)}` }}>
                  {
                    bChoiceA === "refinance" ? <Typography sx={{ color: '#000000', mt: '11.5vw',width:`${rspvw(200)}`, fontSize: '1.4vw', textAlign: 'center' }}>
                      Estimate your savings</Typography>
                      : <Typography sx={{ color: '#000000', mt: '11.5vw', fontSize: '1.4vw', textAlign: 'center' }}>
                        Estimate your savings</Typography>
                  }</Box>
                <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="First" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText={bChoiceA === "refinance" ? "Principal Home" : "Home purchase"} type='EMSP' sx={{ "backgroundColor": '#7F4B08', }} fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Invest" className='hexBtnCenter'
                  // onClick={onClickFun}
                  >
                    <HexButton titleText="Investment Property"  fontSize='24' type='disabledNew'/></Button>
                  {/* <Button sx={{ overflow:'hidden', textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}}id="Next" className='hexBtnCenter'
                      onClick={onClickFun}> 
                      <HexButton titleText={bChoiceA === "refinance" ?"Second Home" :"Next Home"} type={bChoiceB ==='nexthome' ? 'visited':'base'} fontSize='24'/></Button> */}
                  <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Refinance" className='hexBtnCenter'
                  // onClick={onClickFun}
                  >
                    <HexButton titleText="Refinance"
                       fontSize='24' type='disabledNew'/>
                  </Button>
                </Stack>
                {bChoiceA === "refinance"
                  ? <></>
                  : <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '6.5vw' }} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Holiday" className='hexBtnCenter'
                    // onClick={onClickFun}
                    >
                      <HexButton titleText="Evaluate Traditional" type='disabledNew' fontSize='24' /></Button>
                  </Stack>
                }
                <Centerbottom navigateForward='SearchProperty' />

              </div>

            </Box>
            {/* <RightBlockCompare rbops='smartChoice' /> */}
            <RightBlockMortgage />
            {/* hghghg */}
          </Box>
        </Box>
      </React.Fragment>
    ));
}

function LeftBlock() {

  const dispatch = useDispatch();
  const { bProfile,
    bChoiceL,
    isNewEmployer,
    employeeMortgageBenifitProfile

  } = useSelector((state) => state.borrower);
  console.log(bProfile);
  if (_debug === 'development') console.log(bChoiceL)
  const textsplit = bChoiceL?.title.split(" ");

  var labelTxt = 'onboarded'
  if ((bProfile?.employer?.nestfuelEmployerId) && (bProfile?.employer?.nestfuelEmployerId).startsWith("PRE")) {
    labelTxt = 'pre-qualified'
  }
  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height:'46.75vw', position: 'relative'
    }}>
      {
        isNewEmployer ? <Box sx={{ mt: '4.2vw', ml: '4.3vw', width: '28vw', height: '10vw' }}>
          <Typography sx={{
            color: '#31312C', width: '28vw',
            fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
          }}>
            Finance matching with your property aspiration
          </Typography>
          <Typography sx={{
            color: '#31312C', width: '26vw', mt: '6vh',
            fontSize: '1.6vw', fontFamily: 'Roboto', fontWeight: 400, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
          }}>
            Compare available properties with financing matching your needs
          </Typography>


        </Box> : <Box sx={{ mt: '1.5vw', ml: '7.3vw', width: '26vw', height: '10vw' }}>
          <Typography sx={{
            color: '#31312C', width: '26vw',
            fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
          }}>
            {/* {bProfile?.employer?.companyName} {labelTxt} for Nestfuel Benefits */}

            Mortgage as Benefit {employeeMortgageBenifitProfile?.name} employees
          </Typography>
          <Typography sx={{
            color: '#31312C', width: '22vw', mt: `${rspvw(30)}`, ml: '0vw',
            fontSize: `${rspvw(48)}`, fontFamily: 'Roboto', fontWeight: 'medium', textAlign: 'center', lineHeight: 1.2, letterSpacing: '0'
          }}>
            Save
          </Typography>

          <Typography sx={{
            color: '#31312C', width: '22vw', mt: '0.1vh', ml: '0vw',
            fontSize: `${rspvw(32)}`, fontFamily: 'Roboto', fontWeight: 'medium', textAlign: 'center', lineHeight: 1.2, letterSpacing: '0'
          }}>
            up to
          </Typography>
          <Typography sx={{
            color: '#31312C', width: '22vw', mt: `${rspvw(20)}`, ml: '0vw',
            fontSize: `${rspvw(100)}`, fontFamily: 'Roboto', fontWeight: 'medium', textAlign: 'center', lineHeight: 1.2, letterSpacing: '0'
          }}>

            {withCommas(employeeMortgageBenifitProfile.saving)}
          </Typography>
          <Typography sx={{
            color: '#31312C', width: '22vw', mt: '0vh', ml: '0vw',
            fontSize: `${rspvw(32)}`, fontFamily: 'Roboto', fontWeight: 'regular', textAlign: 'center', lineHeight: 1.2, letterSpacing: '0'
          }}>
            on your mortgage interest
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{
              color: '#31312C', mt: '0vh', ml: '7vw',
              fontSize: `${rspvw(32)}`, fontFamily: 'Roboto', fontWeight: 'regular', textAlign: 'center', lineHeight: 1.2, letterSpacing: '0'
            }}>
              *
            </Typography>
            <Typography sx={{
              color: '#31312C', width: '20vw', mt: '0.5vh', ml: '0.5vw',
              fontSize: `${rspvw(16)}`, fontFamily: 'Roboto', fontWeight: 'regular', textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
            }}>
              over a 30 year term
            </Typography>
          </Box>
          <Box sx={{ ml: `${rspvw(0)}`, mt: `${rspvw(30)}`, width: `${rspvw(464)}`, height: `${rspvw(72)}` }}>
            <Typography sx={{
              color: '#000000', mt: '0vh', ml: '0vw',
              fontSize: `${rspvw(13)}`, fontFamily: 'Roboto', fontWeight: 'regular', textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
            }}>
              Smart Traditional means a traditional conventional mortgage offered by Nestfuel
            </Typography>
            <Typography sx={{
              color: '#000000', mt: '1vh', ml: '0vw',
              fontSize: `${rspvw(13)}`, fontFamily: 'Roboto', fontWeight: 'regular', textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
            }}>
              Example based on an household earning  {withCommas(employeeMortgageBenifitProfile.assumedSalary)} per year,
              with {employeeMortgageBenifitProfile.assumedFico} FICO, {withCommas(employeeMortgageBenifitProfile.assumedOutgoing
              )}/mo outgoings. No late payments or defaults in the past 24 months
            </Typography>
          </Box>
        </Box>
      }
      <LeftbottomA leftbottom={leftbottom} />
    </Box>
  );
}

function LeftBlockA() {

  const dispatch = useDispatch();
  const { bChoiceE,
    bChoiceL,
    selectedProp,
    proposedProp,
    bfinancialProfile,
    bChoiceB
  } = useSelector((state) => state.borrower);

  const [name, setName] = React.useState('Aivanaa Maraea')
  const [soffer, setSoffer] = React.useState(selectedProp.prop.offerPrice)
  const [poffer, setPoffer] = React.useState(proposedProp.prop.offerPrice)
  const maxA = maxMortgageNQM92E(bfinancialProfile.frontEndDTI, bfinancialProfile.income.upfront)
  const maxB = maxMortgageQM(bfinancialProfile.frontEndDTI, bfinancialProfile.income.upfront)
  if (_debug === 'development') console.log(bChoiceL)
  const textsplit = bChoiceL?.title.split(" ");

  //   function onClickFun(e) {
  //     const buttonId = e.currentTarget.id;
  //     const input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
  //     switch (buttonId) {
  //       case "soffer":
  //         setSoffer(isNaN(input) ? 0 : input)
  //         const sprop = { ...selectedProp.prop, offerPrice: input }
  //         dispatch({ type: SELPROP, payload: { ...selectedProp, prop: sprop } });
  //         break;
  //       case "poffer":
  //         setPoffer(isNaN(input) ? 0 : input)
  //         const pprop = { ...proposedProp.prop, offerPrice: input }
  //         dispatch({ type: PROPROP, payload: { ...proposedProp, prop: pprop } });
  //         break;
  //       default:
  //         break;
  //     }
  //   }



  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '46.75vw', position: 'relative'
    }}>
      <Box sx={{ mt: '8.2vw', ml: '7.3vw', width: '26vw', height: '10vw' }}>
        <Typography sx={{
          color: '#31312C', width: '26vw',
          fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          Update offer price
        </Typography>
        <Typography sx={{
          color: '#31312C', width: '26vw',
          fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          {selectedProp.prop.address}
        </Typography>
        <Stack sx={{ mt: 1 }} spacing={0} direction="column" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw' }} color='#000000'>List Price</Typography>
          <Typography sx={{ mt: 1, fontSize: '1.35vw' }} color='#000000'>{selectedProp.prop.price}</Typography>
        </Stack>
        <Stack sx={{ mt: 1 }} spacing={0} direction="column" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw' }} color='#000000'>Offer Price</Typography>
          <Input disableUnderline={true} id="soffer" value={withCommas(soffer)} sx={{ backgroundColor: '#FFFFFF', color: "#8F8F8F", fontSize: '1.1vw', height: '2.5vw', width: "13vw", border: 1, borderRadius: '0.5vw' }}
            inputProps={{ style: { textAlign: 'center' } }} onChange={onClickFun} />
        </Stack>
        <Typography sx={{
          color: '#31312C', width: '26vw',
          fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
        }}>
          {selectedProp.prop.address}
        </Typography>
        <Stack sx={{ mt: 1 }} spacing={0} direction="column" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw' }} color='#000000'>List Price</Typography>
          <Typography sx={{ mt: 1, fontSize: '1.35vw' }} color='#000000'>{proposedProp.prop.price}</Typography>
        </Stack>
        <Stack sx={{ mt: 1 }} spacing={0} direction="column" justifyContent="space-between">
          <Typography sx={{ mt: 1, fontSize: '1.35vw' }} color='#000000'>Offer Price</Typography>
          <Input disableUnderline={true} id="poffer" value={withCommas(poffer)} sx={{ backgroundColor: '#FFFFFF', color: "#8F8F8F", fontSize: '1.1vw', height: '2.5vw', width: "13vw", border: 1, borderRadius: '0.5vw' }}
            inputProps={{ style: { textAlign: 'center' } }} onChange={onClickFun} />
        </Stack>
      </Box>
      <LeftbottomA leftbottom={leftbottom} />
    </Box>
  );
}
function RightBlockMortgage() {

  const { selectedProp,
    proposedProp,
    bChoiceH,
    bChoiceL,
    isEmployerLoading,
    bfinancialProfile,
    bChoiceDEMB,
    bChoiceDSFEMB
  } = useSelector((state) => state.borrower);
  const [showMap, setShowMap] = React.useState(-1)
  const [colBS,setColBS]      = React.useState('#00657C')
  const [colTS,setColTS]      = React.useState('#FEFEFE')
  const [colBP,setColBP]      = React.useState('#00657C')
  const [colTP,setColTP]      = React.useState('#FEFEFE')
  const dispatch = useDispatch();
  const propdetails = [{
    image: demoprop1,
    area: '2000',
    beds: '2',
    baths: '3',
    garage: '1',
    price: '625,000'
  }, {
    image: demoprop2,
    area: '3000',
    beds: '3',
    baths: '2',
    garage: '1',
    price: '710,000'
  }]
  // React.useEffect(()=>{
  //   switch(bChoiceDEMB.details[proposedProp?.propId][proposedProp?.finOpt]?.label){
  //     case 'Smart Traditional':
  //       setColBP('#66A3B0')
  //       setColTP('#00657C')
  //       break
  //     case 'Interest Only':
  //       setColBP('#DCC254')
  //       setColTP('#6D602A')
  //       break
  //     case 'Pay Points':
  //       setColBP('#6794af')
  //       setColTP('#243F4F')
  //       break
  //     case 'Reduced Initial Monthly':
  //       setColBP('#EF395C')
  //       setColTP('#7C2636')
  //       break
  //     case 'Part Buy Part Rent':
  //       setColBP('#DCC254')
  //       setColTP('#6D602A')
  //       break
  //     case 'Longer Term':
  //       setColBP('#82C494')
  //       setColTP('#43634B')
  //       break
  //     case 'Employer Benefit':
  //       setColBP('#884D00')
  //       setColTP('#884D00')
  //       break
  //     default:
  //       break;
  //   }
  // },[proposedProp?.propId])
  
  // React.useEffect(()=>{
  //   switch(bChoiceDSF[selectedProp?.propId][selectedProp?.finOpt]?.label){
  //     case 'Smart Traditional':
  //       setColBS('#66A3B0')
  //       setColTS('#C9EAE6')
  //       break
  //     case 'Interest Only':
  //       setColBS('#DCC254')
  //       setColTS('#ECDFA9')
  //       break
  //     case 'Pay Points':
  //       setColBS('#6794af')
  //       setColTS('#A4BFCF')
  //       break
  //     case 'Reduced Initial Monthly':
  //       setColBS('#EF395C')
  //       setColTS('#F79BAD')
  //       break
  //     case 'Part Buy Part Rent':
  //       setColBS('#DCC254')
  //       setColTS('#ECDFA9')
  //       break
  //     case 'Longer Term':
  //       setColBS('#82C494')
  //       setColTS('#BFDEC7')
  //       break
  //     case 'Employer Benefit':
  //       setColBS('#FFCD8A')
  //       setColTS('#FFCD8A')
  //       break
  //     default:
  //       break;
  //   }
  //   // setFinObjS(bChoiceDSF[selectedProp?.propId][selectedProp?.finOpt])
  //   // console.log("FIN OBJECT",finObjS)
  // },[selectedProp?.propId,selectedProp?.finOpt])
  console.log(bChoiceDSFEMB,"That's what DSFEMB looks like")
  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D', opacity: 1, width: '50vw', height: '92vh' }}>
        <Box sx={{ marginTop: `${rspvw(20)}`, flexDirection: 'row', width: 'inherit' }}>

          {/* {showMap === 5  
                  ?<GoogleMaps position={{lat: selectedProp?.prop?.geoLocation.lat, lng:selectedProp?.prop?.geoLocation.lon}}
                                  markerInput={[selectedProp?.prop]} wHeight='13.5vw'/> 
                  :<InnerCarousel imageArray={selectedProp?.prop.images} numCards={1}/>
              } */}
          {/* <Box sx={{ display: 'flex', flexDirection: 'row', width:'inherit', height:"4.5vw"}}> 
                <Typography mt='0.5vw' width='8vw' fontSize='1.0vw' fontWeight='bold' color='black' align='center'>{withCommas(selectedProp?.prop.mortgageDetails.propertyPrice)}</Typography>
                <Box width='16vw'>
                  <Typography fontSize='0.9vw' color='black' align='center'> <Button sx={{minHeight: 0, minWidth: 0}} onClick={()=> {showMap === -1? setShowMap(5) : setShowMap(-1)}} ><LocationOnIcon sx={{color:'#000000'}}/></Button>{selectedProp?.prop.address}</Typography>
                  <Typography sx={{mt:'-1.1.2vw'}}fontSize='0.9vw' color='black' align='center'> {selectedProp?.prop.city}, {selectedProp?.prop.state} - {selectedProp?.prop.zipcode}</Typography>
                </Box>
              </Box>
            </Box>  
            <Box m={1.2} sx={{background:'#F8D02B', boxShadow: '-1px -6px 46px #0000003D',width: "24vw",height:"31.2vw" ,border:0, borderRadius:'0.5vw'}}> */}
          {/* {showMap === 8  
                  ?<GoogleMaps position={{lat: proposedProp?.prop?.geoLocation.lat, lng:proposedProp?.prop?.geoLocation.lon}}
                                  markerInput={[proposedProp?.prop]} wHeight='13.5vw'/> 
                  :<InnerCarousel imageArray={proposedProp?.prop.images} numCards={1}/>
              } */}
          {/* <Box sx={{ display: 'flex', flexDirection: 'row', width:'inherit', height:"4.5vw"}}> 
                <Typography mt='0.5vw' width='8vw' fontSize='1.0vw' fontWeight='bold' color='black' align='center'>{withCommas(proposedProp?.prop.mortgageDetails.propertyPrice)}</Typography>
                <Box width='16vw'>
                  <Typography fontSize='0.9vw' color='black' align='center'> <Button sx={{minHeight: 0, minWidth: 0}} onClick={()=> {showMap === -1? setShowMap(8) : setShowMap(-1)}} ><LocationOnIcon sx={{color:'#000000'}}/></Button>{selectedProp?.prop.address}</Typography>
                  <Typography sx={{mt:'-1.1.2vw'}}fontSize='0.9vw' color='black' align='center'> {proposedProp?.prop.city}, {proposedProp?.prop.state} - {proposedProp?.prop.zipcode}</Typography>
                </Box>
              </Box>
            </Box>  
          </Box>  
            <Box sx={{ display: 'flex', flexDirection: 'row', width:'50vw',height:'11.25vw'}}>
              <Box sx={{ml:'.7vw',mt:'0.56vw',width:'24vw',background:'#00657C 0% 0% no-repeat padding-box', height:"10.125vw" ,border:0, borderRadius:'0.5vw'}}>
                  <Typography ml='1vw'fontSize='1.15vw' fontWeight={700} color='white' align='right'> TRADITIONAL</Typography>
                  <Divider sx={{color:'#FFFFFF',borderBottomWidth: 2}}/>
                  <Stack mt={0.5} direction="row" justifyContent="space-around">
                    <Box> <CheckCircleIcon sx={{height:'5.625vw',fontSize:'48px',color:'green'}}/> </Box>
                    <Box>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> Rate {bChoiceH?.interestRate}% </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> APR  {(bChoiceH?.interestRate+0.35).toFixed(3)}%</Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'>  </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> Ownership  100%</Typography>
                    </Box>
                  </Stack>
              </Box>
          <Box sx={{ml:'1.4vw',mt:'0.56vw',width:'24vw', background:'#F8D02B 0% 0% no-repeat padding-box',height:"10.125vw" ,border:0, borderRadius:'0.5vw'}}>
          
          <Typography ml='1vw'fontSize='1.15vw' fontWeight={700} color='black' align='right'> EMPLOYER BENEFITS {bChoiceL.title} </Typography>
          <Divider sx={{color:'#000000',borderBottomWidth: 2}}/>
          <Stack mt={0.5} direction="row" justifyContent="space-around">
              
              <Box>
                 <Typography fontSize='1.0vw' fontWeight={500} width='12vw' color='black' align='left'> Rate {bChoiceH?.interestRate}% </Typography>
                 <Typography fontSize='1.0vw' fontWeight={500} width='12vw' color='black' align='left'> APR  {(bChoiceH?.interestRate+0.35).toFixed(3)}%</Typography>
                 <Typography fontSize='1.0vw' fontWeight={500} width='12vw' color='black' align='left'>  </Typography>
                 <Typography fontSize='1.0vw' fontWeight={500} width='12vw' color='black' align='left'> Ownership  100%</Typography>
               </Box>
               <Box> <HighlightOffIcon sx={{height:'5.625vw',fontSize:'48px',color:'red'}}/> </Box>
          </Stack>
      </Box>
      </Box>

      <Box sx={{ background: "#FFFFFF 0% 0% no-repeat padding-box",width:'100%', height:"34vw",boxShadow: '0px 0px 19px #0000008F'}}> 
    <Box sx={{display: 'flex', flexDirection: 'row', width:'inherit'}}>
      <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "24vw",height:"34vw",p:4,border:0 ,borderRadius:0}}>
      <Stack mt={0.5} direction="row" justifyContent="space-between">
      <Typography  fontSize='1.2vw' align='left' fontWeight='bold' color='#000000'>Summary</Typography> 
      <Typography  fontSize='1.2vw' align='left' fontWeight='bold' color='#000000'>Traditional</Typography> 
      </Stack>
      <Stack mt={0.5} direction="row" justifyContent="space-around">
            <Typography fontSize='1.0vw' fontWeight={500} width='12vw' color='black' align='right'>Rate </Typography>
            <Typography fontSize='1.0vw' fontWeight={500} width='12vw' color='black' align='right'> {bChoiceH?.interestRate}% </Typography>
            </Stack>
      <Stack mt={0.5} direction="row" justifyContent="space-around">
            <Typography fontSize='1.0vw' fontWeight={500} width='12vw' color='black' align='right'> APR  </Typography>
            <Typography fontSize='1.0vw' fontWeight={500} width='12vw' color='black' align='right'> {(bChoiceH?.interestRate+0.35).toFixed(3)}%</Typography>
            </Stack>
      <Stack mt={0.5} direction="row" justifyContent="space-around">
            <Typography fontSize='1.0vw' fontWeight={500} width='12vw' color='black' align='right'> Ownership </Typography>
            <Typography fontSize='1.0vw' fontWeight={500} width='12vw' color='black' align='right'> 100%</Typography>
      </Stack>
      
      </Box>
      <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "25vw",height:"34vw",p:4,border:0 ,borderRadius:0}}>
            <Typography mt={0.5} fontSize='1.2vw' align='left' fontWeight='bold' color='#000000'>Smart Finance</Typography> 
            <Typography mt={0.5} fontSize='1.0vw' fontWeight={500} width='12vw' color='black' align='left'> {bChoiceH?.interestRate}% </Typography>
            <Typography mt={0.5} fontSize='1.0vw' fontWeight={500} width='12vw' color='black' align='left'> {(bChoiceH?.interestRate+0.35).toFixed(3)}%</Typography>
            <Typography mt={0.5} fontSize='1.0vw' fontWeight={500} width='12vw' color='black' align='left'> 100%</Typography>
      
      </Box>*/}

          {/* <Box sx={{marginLeft :`20vw`,m:0.25,borderColor:'#FFFFFF', border:1,borderRadius:2,background:'#80B2BE 0% 0% no-repeat padding-box',  width:`${rspvw(460)}`,height:'18vw' }}>
              <InnerCarousel CardElement={selectedProp.prop} numCards={1.6}/>    
           </Box>
           <Box sx={{m:0.25,borderColor:'#FFFFFF', border:1,borderRadius:2, background:'#31312C 0% 0% no-repeat padding-box', width: "100%",height:"60vh" }}> 
           <Stack mt='5vw'spacing={0} direction="row" justifyContent="space-around">
             <ImageTextCardB CardElement={selectedProp} FinanceElement={bChoiceCheapOne}/>
             <ImageTextCardC CardElement={selectedProp} FinanceElement={bChoiceCheapOne}/>
           </Stack>
           </Box> */}
          {
            isEmployerLoading ? 
      <Box sx={{ marginLeft: `${rspvw(450)}`,marginTop:`${rspvw(0)}`, borderRadius: 2,width: `${rspvw(460)}`, height: '5vw' }}>
      <CircularProgress /> 
              </Box>
            : (
              <Box sx={{ display: 'flex' }}>

                {
                  bChoiceDEMB?.listings.map((item, index) =>
                    <EmployeeRightImageCard bgColor={index==0?'#80B2BE':'#7F4B08'} image={demoprop1} propdetail={item} txtColor={index==0?'#000000':'#FEFEFE'}index={index}/>

                  )

                }


              </Box>)

          }


          {/* <Box sx={{marginLeft :`25vw`,m:0.25,borderColor:'#FFFFFF', border:1,borderRadius:2,background:'#7F4B08 0% 0% no-repeat padding-box',  width:`${rspvw(460)}`,height:'18vw' }}>
              <InnerCarousel CardElement={selectedProp.prop} numCards={1.6}/>    
           </Box>
           <Box sx={{m:0.25,borderColor:'#FFFFFF', border:1,borderRadius:2, background:'#31312C 0% 0% no-repeat padding-box', width: "100%",height:"60vh" }}> 
           <Stack mt='5vw'spacing={0} direction="row" justifyContent="space-around">
             <ImageTextCardB CardElement={selectedProp} FinanceElement={bChoiceCheapOne}/>
             <ImageTextCardC CardElement={selectedProp} FinanceElement={bChoiceCheapOne}/>
           </Stack>
         
           </Box> */}
          {isEmployerLoading ? 
          <Box sx={{ marginLeft: `${rspvw(450)}`,marginTop:`${rspvw(0)}`, borderRadius: 2,width: `${rspvw(460)}`, height: '18vw' }}>
          {/* <CircularProgress />  */}
                  </Box> : (<Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: '0.8vw', ml: '0.85vw', mr: '0vw', background: '#F3F3F3 0% 0% no-repeat padding-box', width: "23.8vw", height: '9vw', border: 0, borderRadius: '0.5vw' }}>

              <Box backgroundColor='#80B2BE' height='9.0vw' borderRadius='0.5vw' border={0} borderColor={colBS} boxShadow= '-1px -6px 46px #0000003D'>

                <DataCarousel  _bChoiceDSF_EMB={bChoiceDSFEMB[0].slice(0)} opt='selected' colB={colBP} colTx='#000000' orig ={bChoiceDSFEMB[0]}/>

              </Box>
            </Box>
            <Box sx={{ mt: '0.8vw', ml: '0.8vw', mr: '0.4vw', background: '#F3F3F3 0% 0% no-repeat padding-box', width: "23.8vw", height: '9vw', border: 0, borderRadius: '0.5vw' }}>

              <Box backgroundColor='#7F4B08' height='9.0vw' borderRadius='0.5vw' border={0} borderColor={colBP}boxShadow= '-1px -6px 46px #0000003D' >
                <DataCarousel _bChoiceDSF_EMB={bChoiceDSFEMB[1].slice(1)} opt='proposed' colB={colBP} colTx='#FEFEFE' orig ={bChoiceDSFEMB[1]} />

              </Box>
            </Box>

          </Box>)}

          <Box sx={{ width: `${rspvw(967)}`, height: `${rspvw(380)}`, borderRadius: `${rspvw(8)}` ,mt:'0.8vw' }}>

            <video style={{ width: `${rspvw(967)}`, height: `${rspvw(380)}` }} controls autoPlay muted>
              {/* <source src='https://nestfuel-dev-images.s3.us-west-2.amazonaws.com/Videos/NFVideo.MP4' /> */}
              <source src='https://nestfuel-dev-images.s3.us-west-2.amazonaws.com/Videos/NFVideo16.6_V3.mp4' />

            </video>
            {/* <ReactPlayer url='https://nestfuel-dev-images.s3.us-west-2.amazonaws.com/Videos/NFVideo.MP4' /> */}
          </Box>

        </Box>


      </Box>
    </React.Fragment>
  );
}


const EmployeeRightImageCard = (props) => {
  const { selectedProp,
    proposedProp,
    isLoading,
    isEmployerLoading,
    bChoiceDSF,
    bfinancialProfile,
    bChoiceB,
    bChoiceA,
    bPropertyDetail
  } = useSelector((state) => state.borrower);
  const [showMap, setShowMap] = React.useState(-1)

  console.log('here sree the props', props)
  console.log(props.propdetail,'here are the details')
  return (
    <>
      <Box sx={{ marginLeft: `${rspvw(16)}`, borderRadius: 2, background: `${props.bgColor}`, width: `${rspvw(460)}`, height: '18vw',boxShadow: '-1px -6px 46px #0000003D', border:'none' }}>
        {/* <InnerCarousel CardElement={selectedProp.prop} numCards={1.6}/>    
           </Box>
           <Box sx={{m:0.25,borderColor:'#FFFFFF', border:1,borderRadius:2, background:'#31312C 0% 0% no-repeat padding-box', width: "100%",height:"60vh" }}> 
           <Stack mt='5vw'spacing={0} direction="row" justifyContent="space-around">
             <ImageTextCardB CardElement={selectedProp} FinanceElement={bChoiceCheapOne}/>
             <ImageTextCardC CardElement={selectedProp} FinanceElement={bChoiceCheapOne}/>
           </Stack> */}

           {
  showMap === props.index
                  ?<Googlemaps position={{lat: selectedProp?.prop?.geoLocation.lat, lng:selectedProp?.prop?.geoLocation.lon}}
                                  markerInput={[selectedProp?.prop]} wHeight='13.5vw'/> 
                  :<><Link sx={{textDecoration: "none"}} to ={props.propdetail?.url} target="_blank">
                  <Box sx={{ with: `${rspvw(460)}`, height: `${rspvw(260)}`, backgroundImage: `url(${props.propdetail?.images[0]})`, borderRadius: 2 }}>
          
                  </Box>
                  <Box sx={{ position: 'absolute', mt: '-4vw', background: "rgba(0,0,0,0.7)", width: `${rspvw(460)}`, height: "4vw",  borderRadius: 0, }}>
                    <Stack sx={{ m: '0 auto', width: '18vw', mt: 1 }} spacing={2} direction="row" justifyContent="space-around">
                      <Stack textAlign="center" ml='0vw' width='28%' direction={'column'}>
                        <Typography fontSize='0.8vw' color='#FFFFFF'>Area</Typography>
                        <Typography fontSize='0.8vw' color='#FFFFFF'>
                          {/* {withCommasWithoutCurrency(item?.squareFeet)}  */} {props.propdetail?.squareFeet + ' '}
                          sqft</Typography>
                      </Stack>
                      <Stack textAlign="center" width='24%' direction={'column'}>
                        <Typography fontSize='0.8vw' color='#FFFFFF'>Beds</Typography>
                        <Typography fontSize='0.8vw' color='#FFFFFF'>
                          {/* {item?.beds} */}
                          {props.propdetail?.beds}
                          <img color="#FFFFFF" src={bedicon} style={{ marginLeft: '0.26vw', height: '1.14vw' }} /></Typography>
                      </Stack>
                      <Stack textAlign="center" width='24%' direction={'column'}>
                        <Typography fontSize='0.8vw' color='#FFFFFF'>Baths</Typography>
                        <Typography fontSize='0.8vw' color='#FFFFFF'>
                          {/* {item?.baths}  */}
                          {props.propdetail?.baths}
          
                          <img sx={{ mr: 10 }} color="#FFFFFF" src={bathicon} style={{ marginLeft: '0.26vw', height: '1.14vw' }} /></Typography>
                      </Stack>
                      <Stack textAlign="center" mr='0vw' width='24%' direction={'column'}>
                        <Typography fontSize='0.8vw' color='#FFFFFF'>Garages</Typography>
                        <Typography fontSize='0.8vw' color='#FFFFFF'>
                          {/* {props.propdetail.garage} */}
                          <img color="#FFFFFF" src={garageicon} style={{ marginLeft: '0.26vw', height: '1.14vw' }} /></Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  </Link>
                  </>
              
           }

        <Stack sx={{ ml: 1.5, mr: 0, mb: 1 }} spacing={0} direction="row" justifyContent="space-between">
          {
            bChoiceA === "purchase"
              ? <Typography ml='1vw' mt='1.1vw' fontSize='1.5vw' fontWeight='bold' color={props.txtColor} align='left'>
                {(withCommas(props?.propdetail?.mortgageDetails.propertyPrice))}
              </Typography>
              : <Typography ml='1vw' mt='1.1vw' fontSize='1.32vw' fontWeight='bold' color='black' align='left'>

                {/* {withCommas(bChoiceD[0].offerPrice)} */}
              </Typography>
          }
          <Box marginLeft='3.2vw' width='14vw' mt='0.5vw'>
            <Typography mr='1.2vw' fontSize='0.8vw' align='left' color={props.txtColor} >
              <Button sx={{ padding: 0, minHeight: 0, minWidth: 0, pt: '0.56vw' }}
              onClick={()=> {showMap === -1? setShowMap(props.index) : setShowMap(-1)}}
              >
                <LocationOnIcon sx={{ color:  `${props.txtColor}`, fontSize: '2.08vw' }} />
              </Button>
              {props.propdetail?.address.substr(0, 20)}

            </Typography>
            <Typography paddingLeft="2.14vw" mr='1.2vw' mt='-0.97vw' fontSize='0.8vw' color={props.txtColor} align='left'>
             
              {props.propdetail?.city}, {props.propdetail?.state} - {props.propdetail?.zipcode}
            </Typography>
          </Box>
        </Stack>
      </Box></>
  )
}

function DataCarousel(props) {
  const { index, _bChoiceDSF_EMB, opt, colTx, colB ,orig} = props;
  const { finOptS,
    bfinancialProfile,
    bChoiceA,
    bChoiceE,
    selectedProp,
    bChoiceDSFEMB,
    bProfile,
    proposedProp,
    review
  } = useSelector((state) => state.borrower);



  const [active, setActive] = React.useState(0);
  React.useEffect(() => {
    switch (_bChoiceDSF_EMB.label) {
      case 'Smart Traditional':
        setColBS('#66A3B0')
        setColTS('#C9EAE6')
        setTypeS('TradS')
        break
      case 'Interest Only':
        setColBS('#DCC254')
        setColTS('#ECDFA9')
        setTypeS('IOS')
        break
      case 'Pay Points':
        setColBS('#C682EC')
        setColTS('#A4BFCF')
        setTypeS('PPS')
        break
      case 'Reduced Initial Monthly':
        setColBS('#EF395C')
        setColTS('#F79BAD')
        setTypeS('DFS')
        break
      case 'Part Buy Part Rent':
        setColBS('#DCC254')
        setColTS('#ECDFA9')
        setTypeS('PBPRS')
        break
      case 'Longer Term':
        setColBS('#82C494')
        setColTS('#BFDEC7')
        setTypeS('LTS')
        break;
      case "Employer Support":
        setColBS('#82C494')
        setColTS('#BFDEC7')
        setTypeS('ESS')
        break
      default:
        break;
    }

  }, [active])

  // if(_bChoiceDSF_E === 'undefined') _bChoiceDSF_E = bChoiceDSF[index]
  // const colTx = '#000000'
  // const dispatch = useDispatch();
  // const [active, setActive] = React.useState(0);
  // React.useEffect(()=>{
  //   opt==='selected' 
  //   ?dispatch({type: SELPROP, payload:{...selectedProp,finOpt:active}})
  //   :dispatch({type: PROPROP, payload:{...proposedProp,finOpt:active}})
  // },[active]);
  return (
    <div className="Data" style={{ height: '10.125vw' }} >
      <ItemsCarousel
        autoPlay={true}
        infiniteLoop={true}
        gutter={1}
        timeout={1}
        activePosition={"center"}
        chevronWidth={-4}
        disableSwipe={false}
        alwaysShowChevrons={false}
        numberOfCards={1}
        slidesToScroll={1}
        outsideChevron={false}
        showSlither={true}
        firstAndLastGutter={false}
        activeItemIndex={active}
        // requestToChangeActive={(value) => setActive(value)}
        requestToChangeActive={(value) => {
          // if(bProfile.employmentType != "Employed" && value === 2) setActive(3)
          if (value >= 0 && value < _bChoiceDSF_EMB.length) setActive(value % _bChoiceDSF_EMB.length)
          if (value < 0) setActive(_bChoiceDSF_EMB.length - 1)
          if (value >= _bChoiceDSF_EMB.length) setActive(0)
        }
        }
        //rightChevron={<ArrowForwardIosIcon sx={{mr:'0vw',mt:'0',color:"#14214B" , fontSize:40,right:'16px'}}/>}
        //leftChevron={<ArrowBackIosIcon    sx={{mt:'0',color:"#14214B" , fontSize:40,ml: '0.5vw'}}/>}

        // rightChevron={ <img src={rightArrow} alt='right-arrow' style={{ marginTop:'-4.5vw',width:'1.77vw', height:'3.28vw' }} /> }
        // leftChevron={ <img src={leftArrow} alt='left-arrow' style={{ marginTop:'-4.5vw',width:'1.77vw', height:'3.28vw' }} /> }

        // rightChevron={<img src={rightArrow} alt='right-arrow' style={{ marginTop: '1.7vw', width: '1.77vw', height: '3.28vw' }} />}
        // leftChevron={<img src={leftArrow} alt='left-arrow' style={{ marginTop: '1.7vw', width: '1.77vw', height: '3.28vw' }} />}

      // rightChevron={<ArrowForwardIosIcon sx={{mr:'0vw',mt:'0',color:"#F1F2F4", fontSize:40, right:'16px',
      //   borderRadius: '15px 0px 0px 15px',
      //   width: '34px',
      //   height: '63px',
      //   background: "rgba(0,0,0,0.40)",
      //   color:'#FFFFFF',
      //   paddingLeft: '0.3vw'
      // }}/>}
      // leftChevron={<ArrowBackIosIcon    sx={{ mr:'0vw',mt:'0',color:"#F1F2F4", fontSize:40, right:'16px',
      //   borderRadius: '0px 15px 15px 0px',
      //   width: '34px',
      //   height: '63px',
      //   background: "rgba(0,0,0,0.40)",
      //   color:'#FFFFFF',
      //   paddingLeft: '0.3vw'
      // }}/>}

      >
        {_bChoiceDSF_EMB?.map((item, index) =>
          <>

            {opt === 'selected'
              ? <Typography mt='0.5vw' pr='1vw' align='right' fontSize='1vw' fontWeight={700} color={colTx} width='22vw' > {(item.label == "Smart Traditional") ? 'SMART TRADITIONAL' : "MORTGAGE AS BENEFIT"} </Typography>
              : <Typography mt='0.5vw' ml='2vw' mr='auto' align='left' fontSize='1vw' fontWeight={700} color={colTx} width='22vw' > {(item.label == "Smart Traditional") ? 'SMART TRADITIONAL' : "MORTGAGE AS BENEFIT"} </Typography>
            }
            <Divider sx={{ borderBottomWidth: 2, color: opt=='selected'?colTx:'#000000' }} />
            <Stack direction="row" width='22vw' >
              {/* {
                  opt === 'proposed'?<></>
                    : item.percentLTV > 95 || item.initialMonthlyEMI > bfinancialProfile.frontEndDTI || item.initialMonthlyEMI > bfinancialProfile.income.monthlyBudget
                    ? <Box width="6vw">
                        <HighlightOffIcon sx={{ml:'2vw',mt:'2vw',height:'2vw',fontSize:'2vw',color:'red'}}/>
                        <Typography mt='1vw' align='center' fontWeight={400} fontSize='1vw' width='8vw'  color={colTx} > Out of Budget </Typography>
                      </Box>
                    : <Box width="6vw">
                        <CheckCircleIcon  sx={{ml:'2vw',mt:'2vw',height:'2vw',fontSize:'2vw',color:'green'}}/>
                        <Typography mt='1vw' align='center' fontWeight={400} fontSize='1vw' width='8vw'  color={colTx} > Within Budget </Typography>
                      </Box> 
                } */}
              {
                opt === 'proposed'
                  ? <Box width='22vw' ml='2vw'>
                    <Stack mt='0.5vw' spacing={0} direction="row" width='22vw'>
                      <Typography width='7vw' align='left' fontSize='1vw' fontWeight={700} color={colTx} > {withCommas(Math.ceil(item?.monthlyEMI))}</Typography>
                      <Typography width='14vw' align='left' fontSize='1vw' fontWeight={400} color={colTx} > Monthly mortgage</Typography>
                    </Stack>
                    <Stack spacing={0} direction="row" width='22vw'>
                      <Typography width='7vw' align='left' fontSize='1vw' fontWeight={700} color={colTx} > {(item?.interestRate?.toFixed(2) )}%</Typography>
                      <Typography width='14vw' align='left' fontSize='1vw' fontWeight={400} color={colTx} >Rate</Typography>
                    </Stack>
                    <Stack spacing={0} direction="row" width='22vw'>
                      <Typography width='7vw' align='left' fontSize='1vw' fontWeight={700} color={colTx} >{(item?.apr?.toFixed(2))}%</Typography>
                      <Typography width='14vw' align='left' fontSize='1vw' fontWeight={400} color={colTx} >APR</Typography>
                    </Stack>
                    <Stack spacing={0} direction="row" width='22vw'>

                      <Typography width='7vw' align='left' fontSize='1vw' fontWeight={700} color={colTx} > {item.label == "Employer Support" ? withCommas(Math.ceil(Math.round((orig[0]?.emi * orig[0]?.loanTerm *12)- orig[0]?.loanAmount ) - Math.round((item?.emi * item?.loanTerm*12) - item?.loanAmount)) ): "-"} </Typography>
                      <Typography width='14vw' align='left' fontSize='1vw' fontWeight={400} color={colTx} >{item.label == "Employer Support" ? "Benefit Savings" : "-"}</Typography>


                    </Stack>
                  </Box>
                  : <Box width='22vw'>
                    <Stack mt='0.5vw' spacing={0} direction="row" width='22vw'>
                      <Typography width='14vw' align='right' fontSize='1vw' fontWeight={400} color={colTx} > Monthly mortgage</Typography>
                      <Typography width='7vw' align='right' fontSize='1vw' fontWeight={700} color={colTx} > {withCommas(Math.ceil(item?.monthlyEMI))}</Typography>

                    </Stack>
                    <Stack spacing={0} direction="row" width='22vw'>
                      <Typography width='14vw' align='right' fontSize='1vw' fontWeight={400} color={colTx} >Rate</Typography>
                      <Typography width='7vw' align='right' fontSize='1vw' fontWeight={700} color={colTx} >{(item?.interestRate?.toFixed(2) )}%</Typography>

                    </Stack>
                    <Stack spacing={0} direction="row" width='22vw'>
                      <Typography width='14vw' align='right' fontSize='1vw' fontWeight={400} color={colTx} >APR</Typography>
                      <Typography width='7vw' align='right' fontSize='1vw' fontWeight={700} color={colTx} >{(item?.apr?.toFixed(2))}%</Typography>

                    </Stack>
                    <Stack spacing={0} direction="row" width='22vw'>

                      <Typography width='14vw' align='right' fontSize='1vw' fontWeight={400} color={colTx} > {item.label == "Employer Support" ? "Benefit Savings" : "-"} </Typography>
                      <Typography width='7vw' align='right' fontSize='1vw' fontWeight={700} color={colTx} > {item.label == "Employer Support" ? withCommas(Math.round(Math.round((_bChoiceDSF_EMB[index-1]?.emi * _bChoiceDSF_EMB[index-1]?.loanTerm *12) - _bChoiceDSF_EMB[index-1]?.loanAmount ) - Math.round((item?.emi * item?.loanTerm *12) - item?.loanAmount )) ): "-"}  </Typography>
                    </Stack>
                  </Box>
              }


            </Stack>
          </>
        )}

      </ItemsCarousel>
    </div>
  );
}
export default EmployeeMortgageBenefit