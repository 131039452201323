import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button} from "@mui/material";
import { rspvw } from "./styles";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/bg-10@1x.png'
import {  useDispatch, 
          useSelector } from 'react-redux';
import { Centerbottom, LeftbottomA, RightBlockSPBP, RightBlockSPBPE, RightBlockSPBPRate } from "./RightBlock";
import { withCommas } from "../../constants";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";

export function ShowPurchaseRate() {
  const { isLoading,
          bChoiceA,
          bChoiceD,
          bChoiceDSFB,
        } = useSelector((state)=>state.borrower);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 
  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "Yes": 
        bChoiceA === "purchase" ? navigate("/EmployerBenefitP", { replace: false })
        : bChoiceDSFB?.tradAfford?.loan < bChoiceD[0].offerPrice*0.92 ? navigate("/EmployerBenefitP", { replace: false })
        : navigate("/EmployerBenefitR", { replace: false })
        break;
      case "No": 
      bChoiceA === "purchase" ? navigate("/EmployerBenefitR", { replace: false }) 
      : bChoiceDSFB?.tradAfford?.loan < bChoiceD[0].offerPrice*0.92 ? navigate("/EmployerBenefitR", { replace: false })
      : navigate("/SmartChoice", { replace: false }) 
        break;
      case "change":
        navigate("/BorrowerProfileEmp", { replace: false });
        break;
      default:break;
    }
  }

  return(
    isLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
      <React.Fragment><CssBaseline/>
            <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden', width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
              <Navbar pathname={location.pathname}/>
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
                { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
                <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <div align='center'>
              {bChoiceA === "purchase"
                ?<Typography sx={{ color: '#31312C', mt: '8.3vw', width: '12vw', fontSize: '1.4vw', textAlign: 'center' }}>
                  Increase your purchasing power
                </Typography>
                :<Typography sx={{ color: '#31312C', mt: '8.3vw', width: '12vw', fontSize: '1.4vw', textAlign: 'center' }}>
                Increase your borrowing power
              </Typography>
                }
                <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Yes" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Yes" type='base' fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="change" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Change Profile" type='base' fontSize='24' /></Button>
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="No" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="No" type='base' fontSize='24' /></Button>
                </Stack>
                <Centerbottom navigateForward="EmployerBenefitP" />
              </div>
          </Box>
        <RightBlockSPBPE rbops='abs_N'/>
      </Box>
    </Box>
    </React.Fragment>
  ));
}

function LeftBlock () {

  const dispatch = useDispatch();
  const { bChoiceA,
          bChoiceD,
          bChoiceDSFB,
          bChoiceDSF,
          maxMortgage,
        } = useSelector((state)=>state.borrower);
console.log("bChoiceDSF Show PurchaseOpt==>",bChoiceDSF)
  
  const maxA = Math.floor(bChoiceDSFB?.tradAfford.propPrice/100)*100
  const maxB = Math.floor(bChoiceDSFB?.extendedAfford.propPrice/100)*100
  const maxAFO = bChoiceA === "purchase" ? 0 : Math.ceil(Math.min(bChoiceDSFB?.tradAfford?.loan,bChoiceD[0].offerPrice*0.92)/100)*100
  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
        <Box sx={{ mt:'6.2vw' , ml: '7.3vw', width: '24.1vw',height:'10vw' }}> 
        {bChoiceA === "purchase"
          ? <>
            <Typography sx={{color:'#31312C', width:'24.1vw',
              fontSize:'2.5vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '-0.12vw'}}> 
                Your Purchasing Power
            </Typography>
            <Typography sx={{color:'#31312C',width:'20vw', mt:'1.04vw',
              fontSize:'5.2vw', fontFamily:'Roboto',fontWeight:500,textAlign:'justify',lineHeight:1.3,letterSpacing: '-0.25vw'}}> 
               {withCommas(maxA)}
            </Typography>
            </>
          : <><Typography sx={{color:'#31312C', width:'25.1vw',
          fontSize:'2.5vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '-0.12vw'}}> 
            Your Borrowing Estimate</Typography>
            <Typography sx={{color:'#31312C',width:'20vw', mt:'1.1vw', mb:'1.1vw',
              fontSize:'5.2vw', fontFamily:'Roboto',fontWeight:400,textAlign:'justify',lineHeight:1.3,letterSpacing: '-0.25vw'}}> 
                {withCommas(maxAFO)}</Typography>
          </>
      }
        </Box>
        <Box sx={{ mt:'1.7vw' , ml: '7.3vw', width: '28vw',height:'14vw' }}>  
        {bChoiceA === "purchase"
          ? <>
          <Typography sx={{color:'#31312C',width:'26vw',
              fontSize:'1.66vw', fontFamily:'Roboto',fontWeight:600,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
                based on your economic profile & traditional finance
            </Typography>
            <Typography sx={{color:'#31312C',width:'28vw', mt:'1.7vw',
              fontSize:'1.72vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
                Enhance your purchasing power by using Employer Benefit Program
            </Typography>
          </>
          :<>
          <Typography sx={{color:'#31312C',width:'26vw',
              fontSize:'1.66vw', fontFamily:'Roboto',fontWeight:600,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
                based on your economic profile and loan requirement
            </Typography>
            <Typography sx={{color:'#31312C',width:'28vw', mt:'1.7vw',
              fontSize:'1.72vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
                Enhance your borrowing power by using Employer Benefit Program
            </Typography>
          </>
        }
        
        </Box>

        <LeftbottomA leftbottom={leftbottom}/>
    </Box>
  );
}

export default ShowPurchaseRate 