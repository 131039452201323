import React, { useState } from "react";
import { Box, CssBaseline, Container, Typography } from "@mui/material";
import ItemsCarousel from "react-items-carousel";
import ImageCard from "./ImageCard";
import imageTarget from '../../assets/images/image-home.jpg';
import imageTarget1 from '../../assets/images/download.png';
import imageTarget2 from '../../assets/svg/assets svg/Image 17.svg';

const imageList = [ imageTarget1,imageTarget,imageTarget2, imageTarget, imageTarget1,imageTarget2,imageTarget];
const imageList1 = [imageTarget, imageTarget1, imageTarget2, imageTarget1,imageTarget2,imageTarget1,imageTarget2];
function Properties() {
  return(
    <>
    <PreferredLocation/>
    <BiggerAndBetter/>
    <NestfuelSuggested/>
    </>
  )
}

function PreferredLocation() {
  const [active, setActive] = useState(0);
  const chevronWidth = 80;
  return (
    <React.Fragment>
      <CssBaseline/>
        <Container maxWidth='false'>
            <Box sx={{ bgcolor: '#14214B', height: '50vh' }}>
            <div align='center'>
              <Typography mt={10} variant='h2' component='span'
                          color='White'> 
                  Preferred 
              </Typography>
              <Typography mt={10} ml={2} variant='h2' fontStyle="bold" component='span'
                          color='#027AF5'> 
                  Location 
              </Typography>
            </div>
            <Container maxWidth='lg' >
              <Typography variant='h5' component="div"
                          color='#12E0D9' align='center'>
                  These are latest properties in Sales category. You can create 
                  the list using the 'latest listing shortcodes' and show items by specific categories.
              </Typography>
            </Container>
            <div>
              <ItemsCarousel
                autoPlay
                infiniteLoop={true}
                gutter={0}
                timeout={1}
                activePosition={"center"}
                chevronWidth={20}
                disableSwipe={false}
                alwaysShowChevrons={false}
                numberOfCards={3}
                slidesToScroll={1}
                outsideChevron={false}
                showSlither={false}
                firstAndLastGutter={false}
                activeItemIndex={active}
                requestToChangeActive={(value) => setActive(value)}
                rightChevron={">"}
                leftChevron={"<"}
              >
              {imageList1.map((imageItem) => 
              <Box sx={{p: 2, width:600, border: '1px dashed grey'}} >
                <ImageCard imageUrl={imageItem} price='3000000' address="125 North Mary Avenue, Sunnyvale, CA 940086"/>
              </Box>)}
              </ItemsCarousel>
            </div>
            </Box>
          </Container>
    </React.Fragment>
  );

}

function BiggerAndBetter() {
  const [active, setActive] = useState(0);
  const chevronWidth = 80;
  return (
    <React.Fragment>
      <CssBaseline/>
        <Container maxWidth='false'>
            <Box sx={{ bgcolor: '#14214B', height: '50vh' }}>
            <div align='center'>
              <Typography mt={10} variant='h2' component='span'
                          color='White'> 
                  Bigger 
              </Typography>
              <Typography mt={10} ml={2} variant='h2' fontStyle="bold" component='span'
                          color='#027AF5'> 
                  Better 
              </Typography>
            </div>
            <Container maxWidth='lg' >
              <Typography variant='h5' component="div"
                          color='#12E0D9' align='center'>
                  These are latest properties in Sales category. You can create 
                  the list using the 'latest listing shortcodes' and show items by specific categories.
              </Typography>
            </Container>
            <div>
              <ItemsCarousel
                autoPlay
                infiniteLoop={true}
                gutter={0}
                timeout={1}
                activePosition={"center"}
                chevronWidth={20}
                disableSwipe={false}
                alwaysShowChevrons={false}
                numberOfCards={6}
                slidesToScroll={1}
                outsideChevron={false}
                showSlither={false}
                firstAndLastGutter={false}
                activeItemIndex={active}
                requestToChangeActive={(value) => setActive(value)}
                rightChevron={">"}
                leftChevron={"<"}
              >
              {imageList1.map((imageItem) => 
              <Box sx={{p: 2, width:400, border: '1px dashed grey'}} >
                <ImageCard imageUrl={imageItem} title="Some Title" config="3 Bed | 2 bath"/>
              </Box>)}
              </ItemsCarousel>
            </div>
            </Box>
          </Container>
    </React.Fragment>
  );
}

function NestfuelSuggested() {
  const [active, setActive] = useState(0);
  const chevronWidth = 80;
  return (
    <React.Fragment>
      <CssBaseline/>
        <Container maxWidth='false'>
            <Box sx={{ bgcolor: '#14214B', height: '50vh' }}>
            <div align='center'>
              <Typography mt={10} variant='h2' component='span'
                          color='White'> 
                  Nestfuel 
              </Typography>
              <Typography mt={10} ml={2} variant='h2' fontStyle="bold" component='span'
                          color='#027AF5'> 
                  Suggested
              </Typography>
            </div>
            <Container maxWidth='lg' >
              <Typography variant='h5' component="div"
                          color='#12E0D9' align='center'>
                  These are latest properties in Sales category. You can create 
                  the list using the 'latest listing shortcodes' and show items by specific categories.
              </Typography>
            </Container>
            <div>
              <ItemsCarousel
                autoPlay
                infiniteLoop={true}
                gutter={0}
                timeout={1}
                activePosition={"center"}
                chevronWidth={20}
                disableSwipe={false}
                alwaysShowChevrons={false}
                numberOfCards={6}
                slidesToScroll={1}
                outsideChevron={false}
                showSlither={false}
                firstAndLastGutter={false}
                activeItemIndex={active}
                requestToChangeActive={(value) => setActive(value)}
                rightChevron={">"}
                leftChevron={"<"}
              >
              {imageList1.map((imageItem) => 
              <Box sx={{p: 2, width:400, border: '1px dashed grey'}} >
                <ImageCard imageUrl={imageItem} title="Some Title" config="3 Bed | 2 bath"/>
              </Box>)}
              </ItemsCarousel>
            </div>
            </Box>
          </Container>
    </React.Fragment>
  );
}

export default Properties ;