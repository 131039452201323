import { deepPurple, grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import rightImage from '../../assets/images/mask-group-42-1@1x.png'

export const rspvw = (px)=>{
  const cal =(px*100)/1920
  const calVw = cal + 'vw'
  return calVw
}

export default makeStyles((theme) => ({
  button: {
    titleText: "Discover Nestfuel",
    '&:hover': {
      titleText: "Hover",
    }
  },
  images: {
    height: 1356,
    backgroundImage: `url(${rightImage})`
  },

  bottomBar: {
    background:'inherit',
    zIndex:1000
  },

  appBar: {
    elevation:0,
    background:'inherit',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5vw 0.5vw',
  },
  heading: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '1vw',
    fontWeight: 300,
  },
  image: {
    marginLeft: '0.5vw',
    marginTop: '5px',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '20vw'
  },
  profile: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '10vw',
    alignItems: 'center',
  },
  logout: {
    marginLeft: '1vw',
    width: '6vw',
    fontSize: '0.6rem'
  },
  userName: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  brandContainer: {
    display: 'flex',
    alignItems: 'right',
  },
  links: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: '0.9vw',
    //color:'black'
    color:'#616161',
    fontWeight:'500'
  },

  linksB: {
    display: 'flex',
    alignItems: 'left',
    textDecoration: 'none',
    fontSize: '1.2vw',
    color:'#FFFFFF'
  },

  linksB1: {
    display: 'flex',
    alignItems: 'left',
    textDecoration: 'none',
    fontSize: '1.0vw',
    color:'#FFFFFF'
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    fontSize: '1.0vw'
  },
  grey: {
    color: theme.palette.getContrastText(grey[800]),
    backgroundColor: grey[800],
    fontSize: '1.0vw'
  },
  appBarSearch: {
    borderRadius: 4,
    marginBottom: '1rem',
    display: 'flex',
    padding: '16px',
  },
  pagination: {
    borderRadius: 4,
    marginTop: '1rem',
    padding: '16px',
  },
  gridContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    fontSize: '.2vw'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  googleButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fileInput: {
    width: '97%',
    margin: '10px 0',
  },
  buttonSubmit: {
    marginBottom: 10,
  },
  // breadCrumbMenuA: {
  //   "& a:hover": {
  //     color: '#31312C',
  //     font:'Roboto,Regular',
  //     fontSize:'1.85vh',
  //     textDecoration:'none'
  //   }, 
  // },


  breadCrumbMenuA: {
    "& .yourdashboard": {
      color: '#31312C',
      font:'Roboto',
      fontSize:'1.85vh',
      textDecoration:'none',
      fontWeight:'Bold'
    },
    "&:hover .yourdashboard": {
      color: '#0d6efd',
      font:'Roboto,Regular',
      fontSize:'1.85vh',
      textDecoration:'none',
      fontWeight:'Bold',
      cursor: 'pointer',
     
    }
  },

  // breadCrumbMenuC: {
  //   "& a:hover": {
  //     color: '#31312C',
  //     font:'Roboto,Regular',
  //     fontSize:'1.85vh',
  //     textDecoration:'none',
  //     fontWeight:'bold'
  //   },
  // },



  breadCrumbMenuBr: {
    "& .yourteam": {
      color: '#31312C',
      font:'Roboto',
      fontSize:'1.85vh',
      textDecoration:'none',
      fontWeight:'normal'
    },
    "&:hover .yourteam": {
      color: '#0d6efd',
      font:'Roboto',
      fontSize:'1.85vh',
      textDecoration:'none',
      fontWeight:'normal',
      cursor: 'pointer',
    }
  },




  breadCrumbMenuB: {
    "& .yourteam": {
      color: '#31312C',
      font:'Roboto',
      fontSize:'1.85vh',
      textDecoration:'none',
      fontWeight:'Bold'
    },
    "&:hover .yourteam": {
      color: '#0d6efd',
      font:'Roboto',
      fontSize:'1.85vh',
      textDecoration:'none',
      fontWeight:'Bold',
      cursor: 'pointer',
    }
  },

  breadCrumbMenuC: {
    "& .yourresource": {
      color: '#31312C',
      font:'Roboto',
      fontSize:'1.80vh',
      mt:'-1vh',
      textDecoration:'none',
      fontWeight:'Bold'
    },
    "&:hover .yourresource .btnnm": {
      color: '#31312C',
      font:'Roboto',
      fontSize:'1.80vh',
      textDecoration:'none',
      mt:'-1vh',
      fontWeight:'Bold',
      cursor: 'pointer',
    },
    // "&:hover .btnnm": {
    //   color: '#31312C',
    //   font:'Roboto,Regular',
    //   fontSize:'1.85vh',
    //   mt:'-1vh',
    //   textDecoration:'none',
    //   fontWeight:'normal'
    // },
    "& .btnnm": {
      color: '#31312C',
      font:'Roboto,Regular',
      fontSize:'1.85vh',
      mt:'-1vh',
      textDecoration:'none',
      fontWeight:'normal'
    },
    "&.MuiButtonBase-root:hover": {
      color: '#0d6efd',
      font:'Roboto',
      fontSize:'1.85vh',
      textDecoration:'none',
      mt:'-1vh',
      fontWeight:'normal'
    }
  },

  breadCrumbMenuD: {
    "& .youradmin": {
      color: '#31312C',
      font:'Roboto,Regular',
      fontSize:'1.85vh',
      textDecoration:'none',
      fontWeight:'Bold'
    },
    "&:hover .youradmin": {
      color: '#0d6efd',
      font:'Roboto',
      fontSize:'1.85vh',
      textDecoration:'none',
      fontWeight:'Bold',
      cursor: 'pointer',
    }
  },

  // breadCrumbMenuD: {
  //   "& a:hover": {
  //     color: '#31312C',
  //     font:'Roboto,Regular',
  //     fontSize:'1.85vh',
  //     textDecoration:'none'
  //   },
  // },


}));