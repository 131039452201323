import React, { useEffect } from "react";
import { Box, CssBaseline, CircularProgress, Typography, Button, Stack} from "@mui/material";

import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/image-66@1x.png'
import GoogleMaps from "./Googlemaps";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector } from 'react-redux';
import { LeftBlockMsg } from "./Messenger";
import { withCommas } from "../../constants";

function ShowPropertyRefiExp() {
  const { isLoading } = useSelector((state)=>state.borrower);
  const navigate = useNavigate();
  const location = useLocation();

  return(
    isLoading ? <CircularProgress /> : (
      <React.Fragment>
      <CssBaseline/>
      <Box sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <Navbar pathname={location.pathname}/>
        <Box sx={{ display: 'flex', flexDirection: 'row', height:'90h'}}>
        { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
        
          <Box sx={{ width: '14vw', height:'inherit', display:'block', alignItems:'center'}}>
          <Typography sx={{color:'#000000',mt:'9vw',fontSize:'2vw',font:'Roboto', textAlign:'center'}}> 
                ....
              </Typography>
              <div align='center'>
                <Stack sx={{ml:'4vw',mt:'0.8vw',height:'8vw',width:'10vw'}} spacing={0} direction="row" justifyContent="space-between">
                  <Button sx={{ textTransform:'none', width:'6.5vw'}} id="Update Profile" onClick={()=>navigate("/BorrowerProfileRefi", { replace: false })}> 
                  <HexButton titleText="Complete Profile" type='base' fontSize='24'/></Button>
                </Stack>
                <Stack sx={{bottom:'0', position:'fixed',width:'14vw'}} direction="row" justifyContent="space-between">
                      <Button id="Back" onClick={()=>navigate(-1)}>
                        <ArrowBackIosIcon sx={{color:"#000000" , fontSize:'3vw'}}/>
                      </Button>
                      <Button sx={{mr:-3}}id="Forward" onClick={()=>navigate("/PropertyType", { replace: false })}>
                        <ArrowForwardIosIcon sx={{color:"#000000" , fontSize:'3vw'}}/>
                      </Button>
                </Stack>
              </div>
        </Box>
        <RightBlock/>
      </Box>
    </Box>
    </React.Fragment>
  ));
}

function RightBlock() {
  const { bChoiceC,
          bChoiceD,
          bChoiceE
        } = useSelector((state)=>state.borrower);
    return (
      <React.Fragment>
        <CssBaseline/>
        <Box sx={{background: '#31312C 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',p:1,opacity: 1, width: '50vw',height:'46.75vw' }}>
            <Box mt='4vw'backgroundColor='#FFFFFF' borderRadius='2vw'> 
              <GoogleMaps position={{ lat:parseFloat(bChoiceC.position.latitude), 
                                      lng: parseFloat(bChoiceC.position.longiitude)}}
                                      markerInput={bChoiceD} wHeight='half'/>
            <Stack sx={{p:'2vw 3vw'}}direction="column">
            <Typography fontSize='1.4vw' color='black' align='center'> {bChoiceD[0]?.address}</Typography>
            <Typography fontSize='1.4vw' color='black' align='center'> {bChoiceD[0]?.city}, {bChoiceD[0]?.state} - {bChoiceD[0]?.zipcode}</Typography>
            <Typography sx={{fontSize:'1.4vw', fontWight:'400'}} color='black' align='center'> Estimated Value:  {'$'+withCommas(bChoiceE?.propertyPrice)}</Typography>
          </Stack>
          </Box>
        </Box>
      </React.Fragment>
    );
}

function LeftBlock() {
  const { bChoiceKMax,
          isLoading}= (useSelector((state)=>state.borrower));
  return (
    isLoading ? <CircularProgress /> : (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'90vh' }}>
        <Box sx={{ mt:'8.4vw' , ml: '8.3vw', width: '27vw',height:'23.6vw' }}> 
          <Typography sx={{color:'#31312C', width:'25vw',
              fontSize:'2.2vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
              Refinance Option for this property
          </Typography>
          <Stack sx={{mt:6}}spacing={2} direction="column" justifyContent="space-between"> 
              <Typography fontSize='1.1vw' color='#000000'>Today's Rate : {bChoiceKMax?.interestRate} %</Typography>
              <Typography fontSize='0.9vw' color='#000000'>(Nestfuel Standard Mortgage)</Typography>
              <Typography sx={{color:'#31312C', width:'25vw',mt:'1.7vw',
                  fontSize:'1.2vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
                  Borrowers typically </Typography>
              <Typography fontSize='1.1vw' color='#000000'>Need Monthly : {withCommas(bChoiceKMax?.monthlyEMI)}</Typography>
              <Typography fontSize='1.1vw' color='#000000'>Get Cashout  : {withCommas(bChoiceKMax?.maxCashoutAmount)}</Typography>
          </Stack>
      </Box>
      <Box sx={{bottom:'0',height:'10.8vw',width: 'inherit',position:'absolute' }}>  
           <img src={leftbottom} alt = "Loading..." width="99%" height="100%"/> 
      </Box>
      </Box>
  ));
}



export default ShowPropertyRefiExp ;