import PropTypes from 'prop-types';

// material-ui
import { Box, Chip, Grid, Stack, Typography, Divider, IconButton, Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
//import person from '../../assets/svg/person.svg'
// project import
import MainCard from './MainCard';

// assets
//import { RiseOutlined, FallOutlined } from '@ant-design/icons';
import OutlinedInput from '@mui/material/OutlinedInput';
import { width } from '@mui/system';

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const AnalyticEcommerce = ({ color, title, count, image, percentage, isLoss, extra }) => (
    <MainCard contentSX={{ p: 2.25,elevation: 10,border:0 , width:'24.68vw' , height:'13vw'}} >
        <Stack spacing={0.5}  >
            <Typography sx={{fontWeight:'Regular', fontSize:'2.04vh', textTransform: 'none', font:'Roboto', color:'#31312C',ml:'-0.1vh'}}>
                {title}
            </Typography>
            
<Divider sx={{borderBottomWidth: 2,ml:'0.1vw',mr:'2.34vw', border
                    : '0.5px solid #DEDEDE'}} />

<Stack spacing={0} direction="row" sx={{ mt: '2.45vh', mb: 0 ,wt:'60vw'}} >
            <Box sx={{ mt: '0', mr: '0vw' }}>

                <Typography sx={{
                    mt: '0.00vh', textTransform: 'none', fontWeight: 'Regular', fontSize: '0.73vw', font: 'Roboto', color: '#616161', ml: '0.1vw',
                }}>
                    Change in Month
                </Typography>
            </Box>
            <Box sx={{  ml: '9.64vw', width: '0vw', height: '0vh' }} >
           
                <Typography sx={{
                    mr: '2.00vh', fontWeight: 'Bold', fontSize: '1.5vh', font: 'Roboto', color: '#3CC480', ml: '0.1vw',
                }}>
                    {extra}
                    
                </Typography>
               
            </Box>
        </Stack>


        </Stack>
        {/* <Stack spacing={0} direction="row" justifyContent="space-between" sx={{ mt: 1.25,mb:-1 }}>
            <Box sx={{ pt: 2.25, mt: 2.25 }}>
                <Typography variant="caption" color="textSecondary">
                   
                    <Typography component="span" variant="caption" sx={{fontWeight:'Bold', fontSize:'2.96vh', font:'Roboto', color:'#31312C',ml:'-0.1vh'}}>
                        {count}
                    </Typography>{' '}
                   
                </Typography>
            </Box>
            <Box
                display="flex"
                justifyContent="right"
                sx={{mt:3.00}}
            >
                <img src={image} height='100%'></img>
            </Box>
        </Stack> */}




<Stack spacing={0} direction="row" sx={{ mb: '2.41vw' ,mt:'1.00vw'}}>
            <Box sx={{ mt: '2.25vh', mr: '12vw' }}>

                <Typography sx={{
                    mt: '6.00vh', fontWeight: 'Bold', fontSize: '1.2vw', font: 'Roboto', color: '#31312C', ml: '0.1vw',
                }}>
                    {count}
                </Typography>
            </Box>
            
        </Stack>

    </MainCard>
);

AnalyticEcommerce.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    count: PropTypes.string,
    image: PropTypes.string,
    percentage: PropTypes.number,
    isLoss: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

AnalyticEcommerce.defaultProps = {
    color: 'primary'
};

export default AnalyticEcommerce;
