import React from "react";
import { Slider,Box, Input, CssBaseline, Stack,Typography, Button} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import { Centerbottom, LeftbottomA, RightBlockSPBP } from './RightBlock'
import leftbottom from '../../assets/images/bg-10@1x.png'
import { useDispatch, useSelector } from 'react-redux';
import { borrowerfinancialProfile, borrowerProfileStatus, calcPropfinOptionsA} from "../../actions/borrower";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { IS_PROFILE_INFO_CHANGED, UPDATE_PROFILEJOURNEY } from "../../constants";
import { rspvw } from "./styles";
function FicoScore() {
  const { bfinancialProfile,
          bProfileJourney,
          bProfileStatus,
          bChoiceD,
          bChoiceB,
          bChoiceE
  } = useSelector((state)=>state.borrower);
  const fScore = bfinancialProfile.income.ficoScore;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 

  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "Skip": 
      const income = {...bfinancialProfile.income, ficoScore:fScore};
      dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income }));
      


      break;
      case "Confirm": 
      dispatch(borrowerProfileStatus({...bProfileStatus,ficoProvided:1}))
      dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, creditFICO:true} });
      dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
      dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});


      break;
      default:
        break;
    }
    navigate(-1)
  }

  return(
    <React.Fragment><CssBaseline/>
        <Box mt={marginT} ml={marginL} sx={{ overflow: 'hidden', width: '100%',
        background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
          <Navbar pathname={location.pathname}/>
          <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 0,}}>
            { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
            <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center', position:'relative'}}>
            <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> 
                Update Settings</Typography>
            <div align='center'>
              <Stack sx={{ mt: '2vw', height: '3.5vw', width: '12vw' }} spacing={0} direction="row" justifyContent="space-around">
                <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Confirm" className='hexBtnCenter'
                  onClick={onClickFun}>
                  <HexButton titleText="&nbsp; Confirm &nbsp;" type='base' fontSize='24' /></Button>
                <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="Skip" className='hexBtnCenter'
                  onClick={onClickFun}>
                  <HexButton titleText="Skip" type='base' fontSize='24' /></Button>
              </Stack>
              <Centerbottom navigateForward='-1' />
            </div>
          </Box>
            <RightBlockSPBP/>
          </Box>
        </Box>
        </React.Fragment>
      );
}

function LeftBlock() {
    const {bfinancialProfile} = useSelector((state)=>state.borrower);
    const [fico,setFico] = React.useState(bfinancialProfile.income.ficoScore);
    const dispatch = useDispatch();
    var dummy;
    React.useEffect(()=>{
      if( fico !== bfinancialProfile.income.ficoScore){
      const income= {...bfinancialProfile.income, ficoScore:fico};
      dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income }));
    }
    },[fico])
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
        boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
            <Box sx={{ ml:'7vw',mt:'13vw' , width:'21vw',background:'inherit', height: '19.7vw' }}>
                <div align='center'>
                  <Typography mt={1} fontSize='1.4vw'fontWeight='bold' component='div'color='#000000'> 
                      Credit Score
                  </Typography>
                  <Typography sx={{ mt:2}} fontSize='1.2vw' component="div" color='#000000' align='center'>
                      Please tell us your FICO score
                  </Typography>
                  <Stack mt={2} direction='row' justifyContent='space-between'>
                    <Typography sx={{mt:'0.56vw',fontSize:'1vw'}}>660</Typography>
                    
                    {/* <Input disableUnderline={true} value={fico} sx={{ml:6, border:'none', color:"#000000",fontSize:'1.2vw', fontWeight:'bold', width:"8vw"}} 
                      onChange={(e,value)=>setFico(value)} InputProps={{disableUnderline:'true' }}/> */}
                    <Typography sx={{border:'none', color:"#000000",fontSize:'1.2vw', fontWeight:'bold'}}>{fico}</Typography>
                    
                    <Typography sx={{mt:'0.56vw',fontSize:'1vw'}}>850</Typography>
                  </Stack>
                  <Slider sx={{mt:1, color:'#000000', width:'21vw'}}id="fSlider" aria-label="Income" value={fico}
                     min={660} max={850} step={10}onChange={(e,value)=>setFico(value)}/>
                 
              </div>
            </Box>
       <LeftbottomA leftbottom={leftbottom}/>
      </Box>
    );
  }
  
export default FicoScore;