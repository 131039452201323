import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button, Input } from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import leftbottom from '../../assets/images/bg-10@1x.png'
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { Centerbottom, LeftbottomA } from "./RightBlock";
import { getAnalytics, logEvent } from "firebase/analytics";
import { RightBlockCompare, RightBlockCompareEMB, RightBlockCompareEmp } from "./RightBlockCompare";
import { maxMortgageNQM92E, maxMortgageQM } from "./HelperFunctions";
import { PROPROP, SELPROP, withCommas, _debug } from "../../constants";
import { borrowerPostPropertyData, empBenefitDSF_EMB, updateDSF_EMB } from "../../actions/borrower";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { rspvw } from "./styles";

export function EmployerSupportDtRegEMB() {
  const { selectedProp,
    proposedProp,
    isLoading,
    bChoiceDSF,
    bfinancialProfile,
    bPropertyDetail,
    isEmbFlow,
    bChoiceB,
    bChoiceE,
    employeeMortgageBenifitProfile
  } = useSelector((state) => state.borrower);
  var propFinRequest;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location)
  const [indexS, setIndexS] = React.useState(selectedProp.propId)
  const [indexP, setIndexP] = React.useState(proposedProp.propId)
  const [tTextS, setTTextS] = React.useState('Smart Traditional')
  const [tTextP, setTTextP] = React.useState('Smart Traditional')
  const [typeS, setTypeS] = React.useState("Trad")
  const [typeP, setTypeP] = React.useState("Trad")
  const [colBS, setColBS] = React.useState('#00657C')
  const [colTS, setColTS] = React.useState('#FEFEFE')
  const [colBP, setColBP] = React.useState('#00657C')
  const [colTP, setColTP] = React.useState('#FEFEFE')
  const [sbenOn, setSbenOn] = React.useState(false)
  const [pbenOn, setPbenOn] = React.useState(false)
  const [abenOn, setAbenOn] = React.useState(selectedProp?.finOpt === 4 ? true : false)
  const [bChoiceDSF_E, setBChoiceDSF_E] = React.useState(location.state.info)
  const { height, width } = useWindowDimensions();
  const [marginT, setMarginT] = React.useState('0px')
  const [marginL, setMarginL] = React.useState('0px')
 
  console.log('Here is the bchoiceDSF', bChoiceDSF, proposedProp)
  React.useEffect(() => {
    const calc1 = Math.ceil(height * 0.5 - 0.28125 * width)
    const calc2 = Math.ceil(width * 0.5 - 1.78 * height)
    setMarginT(String(calc1 >= 0 ? calc1 : 0).concat('px'))
    setMarginL(String(calc2 >= 0 ? calc2 : 0).concat('px'))

  }, [height, width])
  const analytics = getAnalytics();
  logEvent(analytics, 'EmployerSupportDtRegEMB', { name: 'EmployerSupportDtRegEMB' });
  React.useEffect(() => {
    console.log("LABEL TEST  66 ESDR", bChoiceDSF[proposedProp?.propId][proposedProp?.finOpt]?.label)
    // const finOpti=isEmbFlow?(proposedProp?.finOpt>0?2:proposedProp?.finOpt):
    const finOpti = proposedProp?.finOpt
    switch (bChoiceDSF_E[proposedProp?.propId][finOpti]?.label) {
      case 'Smart Traditional':
        setColBP('#66A3B0')
        setColTP('#00657C')
        setTypeP('TradP')
        break
      case "Employer Benefit":
        setColBP('#7F4B08')
        setColTP('#7F4B08')
        setTypeP('ESP')
        break
      default:
        break;
    }
    setTTextP(proposedProp.prop.address)
    setIndexP(finOpti)
   
  }, [proposedProp?.propId, proposedProp?.finOpt])
  var newArr1=[]
  React.useEffect(()=>{
    console.log("bChoiceDSF have been changed from EMPSDTReg")
    
    for (let i = 0; i < bChoiceDSF.length; i++) {
      var newArr = bChoiceDSF[i].filter((val) => {
        return val.label == "Smart Traditional" || val.label == "Employer Benefit" || val.label == "Employer Support"
      })
      newArr = newArr.map((item, index) => {
        if (item.label == "Employer Benefit" || item.label == "Employer Support") {
          item.benefits = Math.ceil(((newArr[0].emi * newArr[0].loanTerm * 12) - newArr[0].loanAmount) - ((item.initialMonthlyEMI * item.loanTerm * 12) - item.loanAmount))
          return item;
        }
        return item;
  })
  // console.log("BENEfits check",JSON.stringify(newArr))
  // conole.log("Here is array")
  newArr1[i]=newArr
}
setBChoiceDSF_E(newArr1)
dispatch(empBenefitDSF_EMB(newArr1))
      },[bChoiceDSF])
  React.useEffect(() => {
    console.log("LABEL TEST  111 ESDR", bChoiceDSF[selectedProp?.propId][selectedProp?.finOpt]?.label)
    // const finOpti=isEmbFlow?(selectedProp?.finOpt>0?2:selectedProp?.finOpt):selectedProp?.finOpt
    const finOpti = selectedProp?.finOpt


    switch (bChoiceDSF_E[selectedProp?.propId][finOpti]?.label) {
      case 'Smart Traditional':
        setColBS('#80B2BE')
        setColTS('#80B2BE')
        setTypeS('TradS')
        break
      case "Employer Benefit":
        setColBS('#7F4B08')
        setColTS('#7F4B08')
        setTypeS('ESS')
        break
      default:
        break;
    }
    setTTextS(selectedProp.prop.address)
    setIndexS(finOpti)
  }, [selectedProp?.propId, selectedProp?.finOpt])

  React.useEffect(() => {
    const pindex = proposedProp.propId
    const findex = proposedProp.finOpt
    const affordable = bChoiceDSF[pindex][findex]?.percentLTV > 95 ? false : true
    // || bChoiceDSF_E[pindex][findex]?.initialMonthlyEMI > bfinancialProfile.frontEndDTI ? false : true
    setPbenOn(affordable)
    //console.log('setPbenOn--',affordable)
  }, [proposedProp.finOpt, proposedProp.propId])
  React.useEffect(() => {
    const pindex = selectedProp.propId
    const findex = selectedProp.finOpt
    const affordable = bChoiceDSF[pindex][findex]?.percentLTV > 95  ? false : true
    // || bChoiceDSF_E[pindex][findex]?.initialMonthlyEMI > bfinancialProfile.frontEndDTI
    setSbenOn(affordable)
  }, [selectedProp.finOpt, selectedProp.propId])

  console.log("Here is the MB saving", employeeMortgageBenifitProfile)
  function onClickFun(e) {
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch (buttonId) {
      case "change": navigate("/UpdateOfferPrice",{state:{name:"Hello",info: location.state.info }} ,{ replace: false }); break;
      case "changeProfile": navigate("/BorrowerProfileEmp", { replace: false }); break;
      case "selected":
      case "alternative":
        const pindex = selectedProp.propId
        const findex = selectedProp.finOpt
        dispatch({ type: PROPROP, payload: selectedProp })
        const address = {
          city: selectedProp.prop.address,
          state: selectedProp.prop.city,
          street: selectedProp.prop.state,
          zipCode: selectedProp.prop.zipcode
        }
        const thumbnail = selectedProp.prop.thumbnail
        const mlsId = selectedProp.prop.mlsId
        const selectedProperty = { ...bPropertyDetail.selectedProperty, address: address, thumbnail: thumbnail, mlsId: mlsId }

        const mortgageDetails = {
          apr: selectedProp?.prop.mortgageDetails?.apr ,
          closingCost: selectedProp?.prop.mortgageDetails.closingCost,
          financeType: bChoiceDSF[pindex][findex]?.financeType,
          insurance: selectedProp?.prop.mortgageDetails.insurance,
          interestRate: bChoiceDSF[pindex][findex]?.interestRate,
          loanAmount: bChoiceDSF[pindex][findex]?.loanAmount,
          loanTerm: bChoiceDSF[pindex][findex]?.loanTerm,
          monthlyEMI: bChoiceDSF[pindex][findex]?.monthlyEMI,
          mortgageType: bChoiceDSF[pindex][findex]?.mortgageType,
          offerPrice: selectedProp?.prop?.offerPrice,
          percentLTV: bChoiceDSF[pindex][findex]?.percentLTV,
          pmi: selectedProp?.prop.mortgageDetails.pmi,
          price: selectedProp?.prop.mortgageDetails.propertyPrice,
          propTax: selectedProp?.prop.mortgageDetails.propTax,
          totalCost: bChoiceDSF[pindex][findex]?.totalCost,
          upfront: bChoiceDSF[pindex][findex]?.upfront,
          nestFuelFinanceOption: bChoiceDSF[pindex][findex]?.label,
        }
        const propertyViewed = bPropertyDetail.propertyViewed === undefined ? [] : bPropertyDetail.propertyViewed
        propertyViewed.push(selectedProperty)
        dispatch(borrowerPostPropertyData({
          ...bPropertyDetail, selectedProperty: selectedProperty,
          mortgageDetails: mortgageDetails,
          propertiesViewed: propertyViewed
        }));
        navigate("/SmartChoiceEMB", { state: { name: buttonId, info: bChoiceDSF_E } }, { replace: false });
        break;
      case "proposed":
        dispatch({ type: SELPROP, payload: proposedProp })
        const _address = {
          city: proposedProp.prop.address,
          state: proposedProp.prop.city,
          street: proposedProp.prop.state,
          zipCode: proposedProp.prop.zipcode
        }
        const _pindex = proposedProp.propId
        const _findex = proposedProp.finOpt
        const _thumbnail = proposedProp.prop.thumbnail
        const _mlsId = proposedProp.prop.mlsId
        const _selectedProperty = { ...bPropertyDetail.selectedProperty, address: _address, thumbnail: _thumbnail, mlsId: _mlsId }

        const _mortgageDetails = {
          apr: proposedProp.prop.mortgageDetails?.apr,
          closingCost: proposedProp.prop.mortgageDetails.closingCost,
          financeType: bChoiceDSF[_pindex][_findex]?.financeType,
          insurance: proposedProp.prop.mortgageDetails.insurance,
          interestRate: bChoiceDSF[_pindex][_findex]?.interestRate,
          loanAmount: bChoiceDSF[_pindex][_findex]?.loanAmount,
          loanTerm: bChoiceDSF[_pindex][_findex]?.loanTerm,
          monthlyEMI: bChoiceDSF[_pindex][_findex]?.monthlyEMI,
          mortgageType: bChoiceDSF[_pindex][_findex]?.mortgageType,
          offerPrice: proposedProp?.prop?.offerPrice,
          percentLTV: bChoiceDSF[_pindex][_findex]?.percentLTV,
          pmi: proposedProp.prop.mortgageDetails.pmi,
          price: proposedProp.prop.mortgageDetails.propertyPrice,
          propTax: proposedProp.prop.mortgageDetails.propTax,
          totalCost: bChoiceDSF[_pindex][_findex]?.totalCost,
          upfront: bChoiceDSF[_pindex][_findex]?.upfront,
          nestFuelFinanceOption: bChoiceDSF[_pindex][_findex]?.label,
        }
        const _propertyViewed = bPropertyDetail.propertyViewed === undefined ? [] : bPropertyDetail.propertyViewed
        _propertyViewed.push(_selectedProperty)
        dispatch(borrowerPostPropertyData({
          ...bPropertyDetail, selectedProperty: _selectedProperty,
          mortgageDetails: _mortgageDetails,
          propertiesViewed: _propertyViewed
        }));
        navigate("/SmartChoiceEmb", { state: { name: buttonId, info: bChoiceDSF_E } }, { replace: false });
        break;

      default:
        break;
    }
  }

  var centerButtonCss = `svg.base text#textbutton { font-weight:500 !important; }
    svg.TradS text#textbutton { font-weight:500 !important; }
    svg.TradP text#textbutton { font-weight:500 !important; }
    svg.disabled text#textbutton { font-weight:500 !important; }`;

  return (
    isLoading ? 
    <Box sx={{marginLeft:`${rspvw(900)}`,marginTop:`${rspvw(400)}`}}>
 <CircularProgress /> 
    </Box>
   : (
      <React.Fragment> <CssBaseline />
        <Box mt={marginT} ml={marginL} sx={{ overflow: 'hidden', width: '100%', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
          <Navbar pathname={location.pathname} />
          <Box sx={{ display: 'flex', flexDirection: 'row', p: 0, m: 0, borderRadius: 1, }}>
            {location.hash === "#messenger" ? <LeftBlockMsg /> : <LeftBlock />}
            <Box sx={{ width: '14vw', height:'46.75vw', display: 'block', alignItems: 'center', position: 'relative' }}>
              <Typography sx={{ color: '#31312C', mt: '13vw', fontSize: '1.4vw', textAlign: 'center' }}>
                Select Property</Typography>
              <div align='center'>
                <style>
                  {centerButtonCss}
                </style>
                <Stack sx={{ mt: `${rspvw(10)}`, height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  
                  <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="change" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Change Offer&nbsp;Price" type='base' fontSize='24' /></Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="selected" className='hexBtnCenter' disabled={!sbenOn}
                    onClick={onClickFun}>
                    <HexButton titleText={tTextS.split(" ").splice(0, 3).join(" ")} type={typeS} fontSize='24' /></Button>
                  <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="proposed" className='hexBtnCenter' disabled={!pbenOn}
                    onClick={onClickFun}>
                    <HexButton titleText={tTextP.split(" ").splice(0, 3).join(" ")} type={typeP} fontSize='24' /></Button>
                </Stack>
                {
                  isEmbFlow ?
                    <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                      <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="changeProfile" className='hexBtnCenter'
                        onClick={onClickFun}>
                        <HexButton titleText="Change &nbsp;profile" type='base' fontSize='24' /></Button>
                    </Stack> :
                    <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                      <Button sx={{ overflow: 'hidden', textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="changeProfile" className='hexBtnCenter'
                        onClick={onClickFun}>
                        <HexButton titleText="Change &nbsp;profile" type='base' fontSize='24' /></Button>
                    </Stack>
                }

                <Centerbottom navigateForward="EmployerSupportDtRegEMB" />
              </div>

            </Box>
            <RightBlockCompareEMB rbops='smartChoiceEMB' info={bChoiceDSF_E} />
          </Box>
        </Box>
      </React.Fragment>
    ));
}

function LeftBlock() {
  const { bProfile,
    bChoiceL,
    isNewEmployer,
    isEmbFlow,
    employeeMortgageBenifitProfile
  } = useSelector((state) => state.borrower);
  console.log(bProfile);
  if (_debug === 'development') console.log(bChoiceL)

  var labelTxt = 'onboarded'
  if ((bProfile?.employer?.nestfuelEmployerId) && (bProfile?.employer?.nestfuelEmployerId).startsWith("PRE")) {
    labelTxt = 'pre-qualified'
  }
  return (
    <Box sx={{
      background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '40vw', height:'46.75vw', position: 'relative'
    }}>
      {
        isEmbFlow ? <>
          <Box sx={{ marginLeft: `${rspvw(100)}`, marginTop: `${rspvw(102)}`, width: `${rspvw(470)}`, height: `${rspvw(126)}` }}>
            <Typography sx={{ fontSize: `${rspvw(48)}`, fontFamily: 'Roboto', color: '#31312C', fontWeight: 'medium', textAlign: 'left' }}>
              Mortgage as Benefit Personalize for you
            </Typography>
          </Box>
          <Typography sx={{
            color: '#31312C', width: '22vw', mt: `${rspvw(40)}`, ml: `${rspvw(100)}`,
            fontSize: `${rspvw(48)}`, fontFamily: 'Roboto', fontWeight: 'medium', textAlign: 'center', lineHeight: 1.2, letterSpacing: '0'
          }}>
            Saving
          </Typography>

          <Typography sx={{
            color: '#31312C', width: '21vw', mt: '0.2vh', ml: `${rspvw(110)}`,
            fontSize: `${rspvw(32)}`, fontFamily: 'Roboto', fontWeight: 'medium', textAlign: 'center', lineHeight: 1.2, letterSpacing: '0'
          }}>
            upto
          </Typography>
          <Typography sx={{
            color: '#31312C', width: '22vw', mt: `${rspvw(6)}`, ml: `${rspvw(90)}`,
            fontSize: `${rspvw(100)}`, fontFamily: 'Roboto', fontWeight: 'medium', textAlign: 'center', lineHeight: 1.2, letterSpacing: '0'
          }}>

            {withCommas(employeeMortgageBenifitProfile.saving)}
          </Typography>
          <Typography sx={{
            color: '#31312C', width: '22vw', mt: '0vh', ml: `${rspvw(100)}`,
            fontSize: `${rspvw(32)}`, fontFamily: 'Roboto', fontWeight: 'regular', textAlign: 'center', lineHeight: 1.2, letterSpacing: '0'
          }}>
            on your mortgage interest
          </Typography>

          <Typography sx={{
            color: '#31312C', width: '20vw', mt: '2vh', ml: `${rspvw(240)}`,
            fontSize: `${rspvw(16)}`, fontFamily: 'Roboto', fontWeight: 'regular', textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
          }}>
            over a 30 year term
          </Typography>
        </> :
          isNewEmployer ? <Box sx={{ mt: '8.2vw', ml: '4.3vw', width: '28vw', height: '10vw' }}>
            <Typography sx={{
              color: '#31312C', width: '28vw',
              fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
            }}>
              Finance matching with your property aspiration
            </Typography>
            <Typography sx={{
              color: '#31312C', width: '26vw', mt: '6vh',
              fontSize: '1.6vw', fontFamily: 'Roboto', fontWeight: 400, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
            }}>
              Compare available properties with financing matching your needs
            </Typography>


          </Box> : <Box sx={{ mt: '8.2vw', ml: '7.3vw', width: '26vw', height: '10vw' }}>
            <Typography sx={{
              color: '#31312C', width: '26vw',
              fontSize: '2.5vw', fontFamily: 'Roboto', fontWeight: 500, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
            }}>
              {bProfile?.employer?.companyName} {labelTxt} for Nestfuel Benefits
            </Typography>
            <Typography sx={{
              color: '#31312C', width: '22vw', mt: '6vh',
              fontSize: '1.6vw', fontFamily: 'Roboto', fontWeight: 400, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
            }}>
              Compare properties from your preferred location with available financing options
            </Typography>

            <Typography sx={{
              color: '#31312C', width: '22vw', mt: '4vh',
              fontSize: '1.6vw', fontFamily: 'Roboto', fontWeight: 400, textAlign: 'left', lineHeight: 1.2, letterSpacing: '0'
            }}>
              Discover also Alternative Finance
            </Typography>
          </Box>


        //  </Box>
        //  <Box sx={{display:'flex'}}>
        //   <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
        //   <Typography sx={{color:'#31312C', 
        //        fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
        //     Cash avalable
        //      </Typography>
        //   </Box>
        //   <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
        //   <Typography sx={{color:'#31312C', 
        //        fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'right',lineHeight:1.2,letterSpacing: '0'}}> 
        //    $xx,xxx
        //      </Typography>
        //   </Box>


        //  </Box>
        //  <Box sx={{display:'flex'}}>
        //   <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
        //   <Typography sx={{color:'#31312C', 
        //        fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
        //     Credit score
        //      </Typography>
        //   </Box>
        //   <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
        //   <Typography sx={{color:'#31312C', 
        //        fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'right',lineHeight:1.2,letterSpacing: '0'}}> 
        //     XXX
        //      </Typography>
        //   </Box>


        //  </Box>
        //  <Box sx={{ mt:`${rspvw(25)}` , width: `${rspvw(400)}`,height:`${rspvw(63)}` }}> 
        //      <Typography sx={{color:'#31312C', 
        //        fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
        //      Save up to $xx,xxx with Mortgage as Benefit
        //      </Typography>
        //  </Box>
        //  </Box>
      }
      <LeftbottomA leftbottom={leftbottom} />
    </Box>
  );
}


export default EmployerSupportDtRegEMB