import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import ItemsCarousel from "react-items-carousel";
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import leftbottom from '../../assets/svg/assets svg/image-leftbottom01.png'
import {  useDispatch, 
          useSelector } from 'react-redux';
import { Centerbottom, LeftbottomA, RightBlockSPBP} from "./RightBlock";
import { SELPROP, UPDATE_D, withCommas } from "../../constants";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { rspvw } from "./styles";
const DebugOutput = true;


export function ShowProperty() {
  const { bChoiceD1,
          bChoiceD,
          isLoading,isfirstTime
        } = useSelector((state)=>state.borrower);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

console.log("===shpwPurchaseOptisFirstTimeBuyer",isfirstTime)

  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 

  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "Yes": 
        navigate("/BorrowerProfileEmp", { replace: false });
        break;
      case "No": 
        navigate("/EmployerBenefit", { replace: false });
        break;
      case "Economics":
        if(bChoiceD1.length > bChoiceD.length){
          const listings = bChoiceD1.map(a => {return {...a}})
          dispatch({ type: UPDATE_D, payload: listings });
        }
        navigate("/ShowProperty", { replace: false });  
        break;
      default:break;
    }
  }

  return(
    isLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
      <React.Fragment><CssBaseline/>
           <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden',width: '100%',
                  background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
              <Navbar pathname={location.pathname}/>
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
                { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
                <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
                <div align='center'>
                  <Typography sx={{color:'#31312C',mt:'11.5vw',fontSize:'1.4vw', textAlign:'center'}}> 
                        Update your profile</Typography>
                  <Stack sx={{mt:'2vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id="Yes"  className='hexBtnCenter'
                      onClick={onClickFun}> 
                      <HexButton titleText="Yes" type='base' fontSize='24'/></Button>
                  </Stack>
                  <Stack sx={{mt:'2.25vw',height:'3.5vw', width:'12vw'}} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id="No" className='hexBtnCenter'
                      onClick={onClickFun}> 
                      <HexButton titleText="No" type='base' fontSize='24'/></Button>
                    <Button sx={{ overflow:'hidden', textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id="Economics" className='hexBtnCenter'
                      onClick={onClickFun}> 
                      <HexButton titleText="Update Economics" type='base' fontSize='24'/></Button>
                  </Stack>
                  <Centerbottom navigateForward = "BorrowerProfileEmp"/>
                  </div>
                </Box>
                <RightBlockSPBP rbops='abs'/>
              </Box>
          </Box>
    </React.Fragment>
  ));
}

function LeftBlock () {

  const dispatch = useDispatch();
  const { bChoiceE,
          bChoiceA,
          bChoiceDSFB,
          bChoiceD,
        } = useSelector((state)=>state.borrower);
  const [_dis,setDis] = React.useState(0)  
 
  const maxA = Math.ceil(bChoiceDSFB?.tradAfford?.propPrice/100)*100
  const maxAFO = bChoiceA === "purchase" ? 0 : Math.ceil(Math.min(bChoiceDSFB?.tradAfford?.loan,bChoiceD[0].offerPrice*0.92)/100)*100
  
  return (
    <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw', border:0}}>
        <Box sx={{ mt:'6.2vw' , ml: '7.3vw', width: '28.3vw',height:'10vw' }}> 
        {bChoiceA === "purchase"
          ? <><Typography sx={{color:'#31312C', width:'24.1vw',
          fontSize:'2.5vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '-0.12vw'}}> 
            Your Purchasing Power</Typography>
            <Typography sx={{color:'#31312C',width:'20vw', mt:'1.1vw', mb:'1.1vw',
              fontSize:'5.2vw', fontFamily:'Roboto',fontWeight:400,textAlign:'justify',lineHeight:1.3,letterSpacing: '-0.25vw'}}> 
                {withCommas(maxA)}
            </Typography>
            </>
          : <><Typography sx={{color:'#31312C', width:'25.1vw',
          fontSize:'2.5vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '-0.12vw'}}> 
            Your Borrowing Estimate</Typography>
            <Typography sx={{color:'#31312C',width:'20vw', mt:'1.1vw', mb:'1.1vw',
              fontSize:'5.2vw', fontFamily:'Roboto',fontWeight:400,textAlign:'justify',lineHeight:1.3,letterSpacing: '-0.25vw'}}> 
                {withCommas(maxAFO)}</Typography>
          </>
      }
            
            
        </Box>
        <Box sx={{ mt:'1.8vw' , ml: '7.3vw', width: '28.3vw',height:'14vw' }}>  
        {bChoiceA === "purchase"
          ?<>
           <Typography sx={{color:'#31312C',width:'18vw',
              fontSize:'1.60vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
                estimate based on your income and cash
            </Typography>
            <Typography sx={{color:'#31312C',width:'28vw', mt:'1.2vw',
              fontSize:'1.72vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
                Provide details to improve your purchasing power estimate
            </Typography>
          </>
          :<>
          <Typography sx={{color:'#31312C',width:'18vw',
              fontSize:'1.60vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
                estimate based on your income, cash and property price
            </Typography>
            <Typography sx={{color:'#31312C',width:'28vw', mt:'1.2vw',
              fontSize:'1.72vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
                Provide details to improve your borrowing estimate
            </Typography>
          </>
        }  
        </Box>
        <LeftbottomA leftbottom={leftbottom}/>
    </Box>
  );
}

 
function OuterCarousel(props) {
  const {itemArray, numCards } = props;
  const { bChoiceD,
          bChoiceE,
          selectedProp
        }= (useSelector((state)=>state.borrower));
  const dispatch = useDispatch();
  const [active, setActive] = React.useState(0);
  React.useEffect(()=>{
    dispatch({type: SELPROP, payload:{...selectedProp,prop:bChoiceD[active],propId:active}});
  },[active]);
  const openInNewTab = (url) => {
    console.log('hello clicked')
    if (url !=undefined){
      window.open(url, '_blank', 'noreferrer');

    }
  };
  
  return (
    <div className="OuterCarousel">
      <ItemsCarousel
        autoPlay={true}
        infiniteLoop={true}
        gutter={20}
        timeout={1}
        activePosition={"center"}
        chevronWidth={-2}
        disableSwipe={false}
        alwaysShowChevrons={false}
        numberOfCards={numCards}
        slidesToScroll={1}
        outsideChevron={false}
        showSlither={true}
        firstAndLastGutter={true}
        activeItemIndex={active}
        requestToChangeActive={(value) => value >=0 && value < itemArray.length 
                                  ? setActive(value)
                                  :value <0 ?  setActive(itemArray.length -1)
                                  : setActive(0)}
        rightChevron={<ArrowForwardIosIcon sx={{mt:'-2.5vw',color:"#F1F2F4" , fontSize:'2vw'}}/>}
        leftChevron={<ArrowBackIosIcon    sx={{mt:'-2.5vw',color:"#F1F2F4", fontSize:'2vw'}}/>}
      >
      {itemArray.map((item,index) => 
      <>
      <Box  key={index} sx={{mt:'1.1vw',background: '#FFFFFF 0% 0% no-repeat padding-box', height: "18vw",border:0, boxShadow: '0px 0px 19px #0000008F', borderRadius:3} } >
      <Link to =''  onClick={() => openInNewTab(`${item?.url}`)} target="_blank">
        <InnerCarousel imageArray={item.images} numCards={1} chevron={active}/>
        </Link>
        <Stack sx={{ml:2, mr:2,mb:0}} spacing={0} direction="row" justifyContent="space-between"> 
            <Typography mt='0.5vw' fontSize='1.1vw' fontWeight='bold' color='black' align='center'>{withCommas(item.offerPrice)}</Typography>
            <Stack direction="column">
              <Typography fontSize='0.9vw' color='black' align='center'> {item.address}</Typography>
              <Typography fontSize='0.9vw' color='black' align='center'> {item.city}, {item.state} - {item.zipcode}</Typography>
            </Stack>
        </Stack>
      
      </Box>
      <Box mt='1.2vw' mb='1.2vw' p={2,1} height='10.125vw' background='#FFFFFF 0% 0% no-repeat padding-box' boxShadow= '0px 0px 19px #0000008F' borderRadius='0.5vw'>
          <Stack direction="row" justifyContent="space-around">
            <Typography  mt='0.5625vw' fontSize='1.1vw' color='#000000'>Affordable:</Typography>
            <Typography  mt='0.5625' fontSize='1.1vw' fontWeight='bold' color='#000000'>{(item?.affordableType === 'Affordable' || item?.affordableType === 'Median-Affordable')? "Yes":"No"}</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-around">
            <Typography  mt='3.4vw' fontSize='1.1vw' color='#000000'>Estimated Loan Amount:</Typography>
            <Typography  mt='3.4vw' fontSize='1.1vw' fontWeight='bold' color='#000000'>{withCommas(item?.mortgageDetails.loanAmount)}</Typography>
          </Stack>
      </Box>
      </>
      )}
      </ItemsCarousel>
    </div>
  );
}

function InnerCarousel(props) {
  const {imageArray,numCards,chevron} = props;
  const [active, setActive] = React.useState(0);
  console.log("chevron",chevron)
  return (
    <div className="InnerCarousel">
      <ItemsCarousel
         autoPlay={true}
         infiniteLoop={true}
         gutter={0}
         timeout={1}
         activePosition={"center"}
         chevronWidth={-2}
         disableSwipe={false}
         alwaysShowChevrons={false}
         numberOfCards={numCards}
         slidesToScroll={1}
         outsideChevron={false}
         showSlither={true}
         firstAndLastGutter={true}
         activeItemIndex={active}
        requestToChangeActive={(value) => setActive(value)}
        rightChevron={<ArrowForwardIosIcon sx={{color:"#FFFFFF" , fontSize:'2vw'}}/>}
         leftChevron={<ArrowBackIosIcon    sx={{color:"#FFFFFF" , fontSize:'2vw'}}/>}
      >
      {imageArray.map((item,index) => 
      <div>
       <Box key={index} sx={{ border:1, borderColor:"#FFFFFF", borderRadius: '10px 10px 0px 0px', height:"13.5vw"}} >
       <img src={(typeof(item) ==='undefined' ? nestfuelImage : item)} alt={nestfuelImage} style={{borderRadius: '10px 10px 0px 0px'}} width="100%" height="100%"/>
        </Box>
        </div>)}
      </ItemsCarousel>
    </div>
  );
}

export default ShowProperty 