import React from "react";
import {Box, CssBaseline, Stack, Typography, Button} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import homeImage from '../../assets/images/intro-property.png';
import paymentImageSvg from '../../assets/svg/assets svg/contract-loan-svgrepo-com.svg'
import { useDispatch, useSelector } from 'react-redux';
import { borrowerProfile, borrowerProfileStatus, borrowerProfileNew,} from "../../actions/borrower";
import ItemsCarousel from "react-items-carousel";
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import leftbottom from '../../assets/images/bg-10@1x.png'
import { Centerbottom, LeftbottomA, RightBlockPropConf, RightBlockSPBP} from './RightBlock'
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";

function GovtLoan() {
  const { bProfile,
          bProfileStatus
        } = useSelector((state)=>state.borrower);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 

  function onClickFun(e){
      e.preventDefault();
      const buttonId = e.currentTarget.id;
      var govtloanEligibility = 'No';
      switch(buttonId) {
        case "VA"   : govtloanEligibility = "VA"; 
        dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});
        break;
        case "FHA"  : govtloanEligibility = "FHA";
        dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});
        break;
        case "USDA" : govtloanEligibility = "USDA";
        dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});
        break;
        case "NO"   : govtloanEligibility = "No";
        dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});
        break;
        default:break;
      }
      //console.log(govtloanEligibility);
      dispatch(borrowerProfile({...bProfile, govtLoanEligibility:govtloanEligibility}));
      dispatch(borrowerProfileStatus({...bProfileStatus,govtloanEligibilityProvided:1}))

      //dispatch(borrowerProfileNew({...bProfile, govtLoanEligibility:govtloanEligibility}));

      navigate(-1);
  }

  return(
    <React.Fragment><CssBaseline/>
             <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
             <Navbar pathname={location.pathname}/>
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
              { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
              
                <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
                <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> 
              Update Settings
            </Typography>
            <div align='center'>
              <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="VA" className='hexBtnCenter'
                  onClick={onClickFun}>
                  <HexButton titleText="VA" type={bProfile.govtLoanEligibility === 'VA' ? 'visited' : 'base'} fontSize='24' /></Button>
              </Stack>
              <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} direction="row" justifyContent="space-around">
                <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="FHA" className='hexBtnCenter'
                  onClick={onClickFun}>
                  <HexButton titleText="FHA" type={bProfile.govtLoanEligibility === 'FHA' ? 'visited' : 'base'} fontSize='24' /></Button>
                <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="USDA" className='hexBtnCenter'
                  onClick={onClickFun}>
                  <HexButton titleText="USDA" type={bProfile.govtLoanEligibility === 'USDA' ? 'visited' : 'base'} fontSize='24' /></Button>
              </Stack>
              <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '6.5vw' }} direction="row" justifyContent="space-around">
                <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="NO" className='hexBtnCenter'
                  onClick={onClickFun}>
                  <HexButton titleText="None" type={bProfile.govtLoanEligibility === 'No' ? 'visited' : 'base'} fontSize='24' /></Button>
              </Stack>
              <Centerbottom navigateForward='-1' />
            </div>
            {/* <Stack sx={{bottom:'0', position:'fixed',width:'14vw'}} direction="row" justifyContent="space-between">
                  <Button id="Back" onClick={()=>navigate(-1)}>          
                    <ArrowBackIosIcon sx={{color:"#000000" , fontSize:'3vw'}}/></Button>
                  <Button sx={{mr:-3}}id="Forward" onClick={()=>navigate("/PropertyType", { replace: false })}>
                    <ArrowForwardIosIcon sx={{color:"#000000" , fontSize:'3vw'}}/></Button>
            </Stack> */}
              </Box>
            <RightBlockSPBP/>
          </Box>
        </Box>
        </React.Fragment>
      );
}

function LeftBlock() {
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
          <Box sx={{ ml:'7vw',mt:'30%' , width:'21vw',background:'inherit', height: '19.7vw' }}>
            <div align='center'>
                  <img src={paymentImageSvg} alt = "Loading..." heigh="100px" width='100px'/>
                  <Typography mt={5} fontSize='1.4vw' fontStyle="regular" component='div'color='#000000'> 
                      Eligibility for Government loan
                  </Typography>
                  <Typography sx={{ mt:2}} fontSize='1vw' component="div" color='#000000' align='center'>
                      Eligibility for one of the government loan types
                  </Typography>
                  </div>
              </Box>
             <LeftbottomA leftbottom={leftbottom}/>
        </Box>
      );
}


export default GovtLoan;