import React from "react";
import { Box, CssBaseline, Typography, Button,Stack,CircularProgress} from "@mui/material";
import {Card, ListGroup, ListGroupItem} from 'react-bootstrap'
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import leftbottom from '../../assets/images/bg-10@1x.png'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ItemsCarousel from "react-items-carousel";
import { useDispatch, useSelector } from 'react-redux';
import {  borrowerChoiceI } from "../../actions/borrower";
import { Centerbottom, RightBlockApply, RightBlockSPBP } from "./RightBlock";
import { LeftBlockMsg } from "./Messenger";
import { PROPROP } from "../../constants";


const DebugOutput = true;
function withCommas(x) {return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")};

function AffordabilityChF() {
  const { selectedProp, 
          proposedProp, 
          bfinancialProfile,
          bChoiceE,bChoiceB,
          isLoading,
          bChoiceIone,
          bChoiceCheapOne} = useSelector((state)=>state.borrower);
 
  const [dummy,setDummy] = React.useState(bChoiceCheapOne+selectedProp+proposedProp+bChoiceE);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
    function onClickFun(e){
      e.preventDefault();
      const buttonId = e.currentTarget.id;
      switch(buttonId) {
        case "ChangeIncome": navigate("/AnnualIncome", { replace: false }); break;
        case "ChangeFin": 
        dispatch({type: PROPROP, payload:selectedProp});
        const propFinRequest = {
          selectedPropertyPrice   : selectedProp?.prop.offerPrice,
          proposedPropertyPrice   : proposedProp?.prop.offerPrice,
          financeType             : bChoiceB === 'firsthome' || bChoiceB === 'nexthome' ? 'primary' :(bChoiceB === 'investment')? 'investment' : 'secondary',
          loanTerm                : bChoiceE?.loanTerm,
          creditScore             : bfinancialProfile?.ficoScore,
          annualIncome            : bfinancialProfile?.annualIncome,
          upfront                 : bChoiceE?.upfront,
          financeDocs             : bfinancialProfile?.financeDocs.docsAll,
          creditEvents            : bChoiceE?.creditEvents,
          houseEvents             : bChoiceE?.creditEvents,
          monthlyOutgoing         : bfinancialProfile?.monthlyOutgoing,
          monthlyBudget           : bChoiceE?.monthlyBudget,
          emailId                 : 'amitkd4@gmail.com'
        }
      if(DebugOutput) console.log("Compare Finance Request",propFinRequest);
      dispatch(borrowerChoiceI(propFinRequest));  
        setDummy(bChoiceIone)
        navigate("/CompareFinance", { replace: false });
        break;
        case "Apply": navigate("/SummaryApplication",{ replace: false });break;
        default: break;
      }
    }

  return(
    isLoading ? <CircularProgress /> : (
      <React.Fragment>
          <CssBaseline/>
          <Box sx={{ width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
            <Navbar pathname={location.pathname}/>
            <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
              { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
            
              <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> 
              ...</Typography>
              <div align='center'>
              <Stack sx={{mt:'4vh',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
              <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="Apply" className='hexBtnCenter' onClick={onClickFun}> 
              <HexButton titleText="Apply" type='base' fontSize='24'/></Button>
              </Stack>
            <Stack sx={{mt:'2.25vw',height:'3.5vw',width:'12vw'}}  direction="row" justifyContent="space-around">
              <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}}   id="ChangeFin" className='hexBtnCenter' onClick={onClickFun}> 
              <HexButton titleText="Change Finance" type='base' fontSize='24'/></Button>
              <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}}id="Adjust" className='hexBtnCenter' onClick={onClickFun}> 
                <HexButton titleText="Adjust" type='base' fontSize='18'/></Button>
          </Stack>
          </div>
          <Centerbottom navigateForward='CompareFinance'/>
          </Box>
          <RightBlockApply/>
      </Box>
      </Box>
     </React.Fragment>
    ));
}
  
function RightBlock() {
  const {selectedProp,bChoiceCheapOne} = (useSelector((state)=>state.borrower));
  const [selectedPropA,setSelectedPropA] = React.useState(selectedProp)
  const [bChoiceCheapOneA,setBChoiceCheapOne] = React.useState(bChoiceCheapOne)
    const navigate = useNavigate();
    return (
      <React.Fragment>
      <CssBaseline/>
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '50vw',height:'90vh' }}>
          <Box sx={{m:0.25,borderColor:'#FFFFFF', border:1,borderRadius:2,background:'#31312C 0% 0% no-repeat padding-box', width: "100%",height:'18vw' }}>
              <InnerCarousel CardElement={selectedProp.prop} numCards={1.6}/>    
           </Box>
           <Box sx={{m:0.25,borderColor:'#FFFFFF', border:1,borderRadius:2, background:'#31312C 0% 0% no-repeat padding-box', width: "100%",height:"60vh" }}> 
           <Stack mt='5vw'spacing={0} direction="row" justifyContent="space-around">
             <ImageTextCardB CardElement={selectedProp} FinanceElement={bChoiceCheapOne}/>
             <ImageTextCardC CardElement={selectedProp} FinanceElement={bChoiceCheapOne}/>
           </Stack>
           </Box>
        </Box>
    </React.Fragment>
  );
  }

  function LeftBlock() {
    const { bfinancialProfile,
            bChoiceCheapOne,
            bChoiceE,
            bChoiceF} = (useSelector((state)=>state.borrower));
    const navigate = useNavigate();
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'50vw' }}>
          <Box sx={{ ml:'7vw',mt:'10vw' , width:'20vw',background:'inherit', height: '19vw' }}>
          <Typography sx={{color:'#31312C', width:'20vw',
              fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                Nestfuel Smart
            </Typography>
            <Typography sx={{color:'#31312C', width:'20vw',mt:1,
              fontSize:'1.2vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                Finance Needs For the selected property:
            </Typography>
            <div>
            <Box sx={{ p:0, border:0, background:'inherit',borderRadius: 2, mt:"5%" , background:'inherit',width: '22vw', height: '11.25vw' }}>
            <Stack spacing={1} direction="row" justifyContent="space-between">
          <Typography fontSize='1.1vw' color='#707070'> {bChoiceCheapOne?.top[0]?.label}</Typography>
          <Typography fontSize='1.1vw'fontWeight={700} color="#000000">${withCommas(bChoiceCheapOne?.top[0]?.value)}</Typography>
          </Stack>
          <Stack spacing={1} direction="row" justifyContent="space-between">
          <Typography fontSize='1.1vw' color='#707070'> {bChoiceCheapOne?.top[2]?.label}</Typography>
          <Typography fontSize='1.1vw' fontWeight={700} color="#000000">${withCommas(bChoiceCheapOne?.top[2]?.value)}</Typography>
          </Stack>
          <Stack spacing={1} direction="row" justifyContent="space-between">
          <Typography fontSize='1.1vw' color='#707070'> {bChoiceCheapOne?.top[3]?.label}</Typography>
          <Typography fontSize='1.1vw' fontWeight={700} color="#000000">${withCommas(bChoiceCheapOne?.top[3]?.value)}</Typography>
          </Stack>
          <Stack spacing={1} direction="row" justifyContent="space-between">
          <Typography fontSize='1.1vw' color='#707070'> {bChoiceCheapOne?.top[4]?.label}</Typography>
          <Typography fontSize='1.1vw' fontWeight={700} color="#000000">${withCommas(bChoiceCheapOne?.top[4]?.value)}</Typography>
          </Stack>
          <Stack spacing={1} direction="row" justifyContent="space-between">
          <Typography fontSize='1.1vw' color='#707070'> Monthly Affordability</Typography>
          <Typography fontSize='1.1vw' fontWeight={700} color="#000000">${withCommas(bfinancialProfile?.frontEndDTI)}</Typography>
          </Stack>
          </Box>
          </div>

                <div align='center'>
                  <Typography sx={{mt:2, width:'26vw'}} fontSize='1.2vw'  component="div"
                              color='#000' align='center'>
                      You are {bChoiceF.monthly === 'Sufficient' || bChoiceF.monthly === 'High'? '': 'not'} eligible for a loan for this properrty as your income is {bChoiceF.monthly.toLowerCase()} enough to prove ability
                      to repay.
                  </Typography>
                </div>
          </Box>
          <Box sx={{ bottom:'0',position:'absolute',height:'11.9vw',width: 'inherit' }}>  
                  <img src={leftbottom} alt = "Loading..." width="100%" height="100%"/> 
              </Box>
      </Box>
    );
  }



function ImageTextCardB(props){
  const {bfinancialProfile} = useSelector((state)=>state.borrower);
  const { CardElement,
          FinanceElement } = props; 
  console.log("Finance Element",FinanceElement)
  const [dummy,setDummy] = React.useState(FinanceElement);
  const [dummy1,setDummy1] = React.useState(CardElement);
  var element = dummy + dummy1;
  return (
  <Card style={{ width: '36rem' }}>
    <Card.Body>
      <Card.Title>
          <div align='center'><Typography fontSize='1.4vw'> Finance Needed </Typography></div>
      </Card.Title>
    </Card.Body>
    <ListGroup className="list-group-flush">
      <ListGroupItem>
          <Stack spacing={1} direction="row" justifyContent="space-around">
          <Typography fontSize='1vw' color='#707070'> {FinanceElement?.top[0]?.label}</Typography>
          <Typography fontSize='1vw' color="#000000">${withCommas(FinanceElement?.top[0]?.value)}</Typography>
          </Stack>
      </ListGroupItem>
      <ListGroupItem>
          <Stack spacing={1} direction="row" justifyContent="space-around">
          <Typography fontSize='1vw' color='#707070'> {FinanceElement?.top[2]?.label}</Typography>
          <Typography fontSize='1vw' color="#000000">${withCommas(FinanceElement?.top[2]?.value)}</Typography>
          </Stack>
      </ListGroupItem>
      <ListGroupItem>
          <Stack spacing={1} direction="row" justifyContent="space-around">
          <Typography fontSize='1vw' color='#707070'> {FinanceElement?.top[3]?.label}</Typography>
          <Typography fontSize='1vw' color="#000000">${withCommas(FinanceElement?.top[3]?.value)}</Typography>
          </Stack>
      </ListGroupItem>
      <ListGroupItem>
          <Stack spacing={1} direction="row" justifyContent="space-around">
          <Typography fontSize='1vw' color='#707070'> {FinanceElement?.top[4]?.label}</Typography>
          <Typography fontSize='1vw' color="#000000">${withCommas(FinanceElement?.top[4]?.value)}</Typography>
          </Stack>
      </ListGroupItem>
      <ListGroupItem>
          <Stack spacing={1} direction="row" justifyContent="space-around">
          <Typography fontSize='1vw' color='#707070'> Monthly Affordability</Typography>
          <Typography fontSize='1vw' color="#000000">${withCommas(bfinancialProfile?.frontEndDTI)}</Typography>
          </Stack>
      </ListGroupItem>
      </ListGroup>
  </Card>
    );
}



function ImageTextCardC(props){

  const { CardElement,
          FinanceElement } = props; 
    console.log("Finance Element",FinanceElement)
    const [dummy,setDummy] = React.useState(FinanceElement);
    const [dummy1,setDummy1] = React.useState(CardElement);
    var element = dummy + dummy1;
    return (
    <Card style={{ width: '36rem' }}>
      <Card.Body>
        <Card.Title>
            <div align='center'><Typography fontSize='1.4vw'> Nestfuel Smart Finance </Typography></div>
        </Card.Title>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>
            <Stack spacing={1} direction="row" justifyContent="space-around">
            <Typography fontSize='1.1vw' color='#707070'> Mortgage Type</Typography>
            <Typography fontSize='1.1vw' color="#000000">{FinanceElement.metaData.mortgageType}</Typography>
            </Stack>
        </ListGroupItem>
        <ListGroupItem>
            <Stack spacing={1} direction="row" justifyContent="space-around">
            <Typography fontSize='1vw' color='#707070'> Percent LTV</Typography>
            <Typography fontSize='1.1vw' color="#000000">{FinanceElement.metaData.percentLTV}%</Typography>
            </Stack>
        </ListGroupItem>
        <ListGroupItem>
            <Stack spacing={1} direction="row" justifyContent="space-around">
            <Typography fontSize='1vw' color='#707070'> Interest Rate</Typography>
            <Typography fontSize='1.1vw' color="#000000">{FinanceElement.metaData.interestRate}%</Typography>
            </Stack>
        </ListGroupItem>
        <ListGroupItem>
            <Stack spacing={1} direction="row" justifyContent="space-around">
            <Typography fontSize='1vw' color='#707070'> {FinanceElement.top[1].label}</Typography>
            <Typography fontSize='1.1vw' color="#000000">${withCommas(FinanceElement.top[1].value)}</Typography>
            </Stack>
        </ListGroupItem>
        <ListGroupItem>
            <Stack spacing={1} direction="row" justifyContent="space-around">
            <Typography fontSize='1vw' color='#707070'> {FinanceElement.middle[1].label}</Typography>
            <Typography fontSize='1.1vw' color="#000000">${withCommas(FinanceElement.middle[1].value)}</Typography>
            </Stack>
        </ListGroupItem>
        <ListGroupItem>
            <Stack spacing={1} direction="row" justifyContent="space-around">
            <Typography fontSize='1vw' color='#707070'> {FinanceElement.middle[2].label}</Typography>
            <Typography fontSize='1.1vw' color="#000000">{FinanceElement.middle[2].value} years</Typography>
            </Stack>
        </ListGroupItem>  
        </ListGroup>
    </Card>
  );
}



function InnerCarousel(props) {
  const {CardElement,numCards} = props;
  const [active, setActive] = React.useState(0);
  return (
    <div className="InnerCarousel">
      <ItemsCarousel
         autoPlay={true}
         infiniteLoop={true}
         gutter={70}
         timeout={1}
         activePosition={"center"}
         chevronWidth={-2}
         disableSwipe={false}
         alwaysShowChevrons={false}
         numberOfCards={numCards}
         slidesToScroll={1}
         outsideChevron={false}
         showSlither={true}
         firstAndLastGutter={true}
         activeItemIndex={active}
        requestToChangeActive={(value) => setActive(value)}
         rightChevron={<ArrowForwardIosIcon sx={{color:"#FFFFFF" , fontSize:60}}/>}
         leftChevron={<ArrowBackIosIcon    sx={{color:"#FFFFFF", fontSize:60}}/>}
      >
       {CardElement.images.map((item) => 
      <div>
      <Box sx={{ backgroundColor:'#FFF',  border:1, borderColor:"#FFFFFF", borderRadius:2, height:"30vh"}} >
        <img src={(typeof(item) ==='undefined' ? nestfuelImage : item)} alt={nestfuelImage} width="100%" height="90%"/>
        <Stack spacing={1} direction="row" justifyContent="center">
            <Typography fontSize='1.1vw' color='#000000'>{CardElement.address},</Typography>
            <Typography fontSize='1.1vw' color='#000000'>{CardElement.city},</Typography>
            <Typography fontSize='1.1vw' color='#000000'>{CardElement.state}-</Typography>
            <Typography fontSize='1.1vw' color='#000000'>{CardElement.zipcode}</Typography>
        </Stack>
      </Box>
       </div>)}
      </ItemsCarousel>
    </div>
  );
}
export default AffordabilityChF;



/* Dead Code  - Below this line is a dead code */

function InnerCarouselX(props) {
  const { CardElement,
    FinanceElement } = props; 
  const [active, setActive] = React.useState(0);
  return (
    <div className="InnerCarousel">
      <ItemsCarousel
         autoPlay={true}
         infiniteLoop={true}
         gutter={10}
         timeout={1}
         activePosition={"center"}
         chevronWidth={-2}
         disableSwipe={false}
         alwaysShowChevrons={false}
         numberOfCards={2}
         slidesToScroll={1}
         outsideChevron={false}
         showSlither={true}
         firstAndLastGutter={true}
         activeItemIndex={active}
        requestToChangeActive={(value) => setActive(value)}
        rightChevron={<ArrowForwardIosIcon sx={{color:"#FFFFFF" , fontSize:60}}/>}
         leftChevron={<ArrowBackIosIcon    sx={{color:"#FFFFFF", fontSize:60}}/>}
      >
      <div>
      <Box sx={{pl:6,pr:2,border:1, borderColor:"#FFFFFF", borderRadius:2, height:"30vh"}} >
        <ImageTextCardA CardElement={CardElement}
                  FinanceElement = {FinanceElement}/>
       </Box>
       </div>
       <div>
       <Box sx={{pl:6,border:1, borderColor:"#FFFFFF", borderRadius:2, height:"30vh"}} >
       <ImageTextCardB CardElement={CardElement}
                  FinanceElement = {FinanceElement}/>
       </Box>
       </div>
       <div>
      <Box sx={{pl:6,border:1, borderColor:"#FFFFFF", borderRadius:2, height:"30vh"}} >
        <ImageTextCardA CardElement={CardElement}
                  FinanceElement = {FinanceElement}/>
       </Box>
       </div>
       <div>
       <Box sx={{pl:6,border:1, borderColor:"#FFFFFF", borderRadius:2, height:"30vh"}} >
       <ImageTextCardC CardElement={CardElement}
                  FinanceElement = {FinanceElement}/>
       </Box>
       </div>
      </ItemsCarousel>
    </div>
  );
}

function ImageTextCard(props){

  const { CardElement,
          FinanceElement } = props; 
console.log("Finance Element",FinanceElement)
const [dummy,setDummy] = React.useState(FinanceElement);
const [dummy1,setDummy1] = React.useState(CardElement);
var element = dummy + dummy1;
return (
<Card style={{ width: '36rem' }}>
  <Card.Body>
    <Card.Title>
    <Box sx={{p:0.25, border:4, borderColor:"#FFFFFF", borderRadius:4, width:"100%", height:"100%"}} >
   <img src={CardElement.thumbnail} width="100%"/>
    </Box>
    </Card.Title>
    <Card.Text>
    <Stack spacing={1} direction="row" justifyContent="center">
    <Typography variant='h5' color='#000000'>{CardElement.address}</Typography>
    <Typography variant='h5' color='#000000'>{CardElement.city}</Typography>
    </Stack>
    </Card.Text>
  </Card.Body>
  <ListGroup className="list-group-flush">
  <ListGroupItem>
    <Stack spacing={1} direction="row" justifyContent="space-around">
    <Typography variant='h5' color='#707070'> Finance Type</Typography>
    <Typography variant='h4' color="#000000">{FinanceElement.metaData.label}</Typography>
    </Stack>
    </ListGroupItem>
    <ListGroupItem>
    <Stack spacing={1} direction="row" justifyContent="space-around">
    <Typography variant='h5' color='#707070'> Mortgage Type</Typography>
    <Typography variant='h4' color="#000000">{FinanceElement.metaData.mortgageType}</Typography>
    </Stack>
    </ListGroupItem>
    <ListGroupItem>
    <Stack spacing={1} direction="row" justifyContent="space-around">
    <Typography variant='h5' color='#707070'> Percent LTV</Typography>
    <Typography variant='h4' color="#000000">{FinanceElement.metaData.percentLTV}%</Typography>
    </Stack>
    </ListGroupItem>
    <ListGroupItem>
    <Stack spacing={1} direction="row" justifyContent="space-around">
    <Typography variant='h5' color='#707070'> Interest Rate</Typography>
    <Typography variant='h4' color="#000000">{FinanceElement.metaData.interestRate}%</Typography>
    </Stack>
    </ListGroupItem>
    <ListGroupItem>
    <Stack spacing={1} direction="row" justifyContent="space-around">
    <Typography variant='h5' color='#707070'> {FinanceElement.top[0].label}</Typography>
    <Typography variant='h4' color="#000000">${withCommas(FinanceElement.top[0].value)}</Typography>
    </Stack>
    </ListGroupItem>
    <ListGroupItem>
    <Stack spacing={1} direction="row" justifyContent="space-around">
    <Typography variant='h5' color='#707070'> {FinanceElement.top[1].label}</Typography>
    <Typography variant='h4' color="#000000">${withCommas(FinanceElement.top[1].value)}</Typography>
    </Stack>
    </ListGroupItem>
    <ListGroupItem>
    <Stack spacing={1} direction="row" justifyContent="space-around">
    <Typography variant='h5' color='#707070'> {FinanceElement.top[2].label}</Typography>
    <Typography variant='h4' color="#000000">${withCommas(FinanceElement.top[2].value)}</Typography>
    </Stack>
    </ListGroupItem>
    <ListGroupItem>
    <Stack spacing={1} direction="row" justifyContent="space-around">
    <Typography variant='h5' color='#707070'> {FinanceElement.top[3].label}</Typography>
    <Typography variant='h4' color="#000000">${withCommas(FinanceElement.top[3].value)}</Typography>
    </Stack>
    </ListGroupItem>
    <ListGroupItem>
    <Stack spacing={1} direction="row" justifyContent="space-around">
    <Typography variant='h5' color='#707070'> {FinanceElement.top[4].label}</Typography>
    <Typography variant='h4' color="#000000">${withCommas(FinanceElement.top[4].value)}</Typography>
    </Stack>
    </ListGroupItem>
    <ListGroupItem>
    <Stack spacing={1} direction="row" justifyContent="space-around">
    <Typography variant='h5' color='#707070'> {FinanceElement.middle[0].label}</Typography>
    <Typography variant='h4' color="#000000">${withCommas(FinanceElement.middle[0].value)}</Typography>
    </Stack>
    </ListGroupItem>
    <ListGroupItem>
    <Stack spacing={1} direction="row" justifyContent="space-around">
    <Typography variant='h5' color='#707070'> {FinanceElement.middle[1].label}</Typography>
    <Typography variant='h4' color="#000000">${withCommas(FinanceElement.middle[1].value)}</Typography>
    </Stack>
    </ListGroupItem>
    <ListGroupItem>
    <Stack spacing={1} direction="row" justifyContent="space-around">
    <Typography variant='h5' color='#707070'> {FinanceElement.middle[2].label}</Typography>
    <Typography variant='h4' color="#000000">{FinanceElement.middle[2].value} years</Typography>
    </Stack>
    </ListGroupItem>  
    </ListGroup>
    
  <Card.Body>
   
  </Card.Body>
</Card>
  );
}
function ImageTextCardA(props){

  const { CardElement,
          FinanceElement } = props; 
console.log("Finance Element",FinanceElement)
const [dummy,setDummy] = React.useState(FinanceElement);
const [dummy1,setDummy1] = React.useState(CardElement);
var element = dummy + dummy1;
return (
<Card style={{ width: '36rem' }}>
  <Card.Body>
    <Card.Title>
    <Box sx={{p:0.25, border:4, borderColor:"#FFFFFF", borderRadius:4, width:"100%", height:"100%"}} >
   <img src={CardElement.thumbnail} width="100%"/>
    </Box>
    </Card.Title>
    <Card.Text>
    <Stack spacing={1} direction="row" justifyContent="center">
    <Typography variant='h5' color='#000000'>{CardElement.address},</Typography>
    <Typography variant='h5' color='#000000'>{CardElement.city},</Typography>
    <Typography variant='h5' color='#000000'>{CardElement.state}-</Typography>
    <Typography variant='h5' color='#000000'>{CardElement.zipcode}</Typography>
    </Stack>
    </Card.Text>
  </Card.Body>
  <Card.Body>
  </Card.Body>
</Card>
  );
}