import React from "react";
import { Box, Button, CssBaseline, Divider,Avatar, Stack, Typography} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ItemsCarousel from "react-items-carousel";
import GoogleMaps from './Googlemaps';
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import nestfuelHomeImageOne         from "../../assets/svg/home-preview-one.svg";
import nestfuelHomeImageTwo         from "../../assets/svg/home-preview-two.svg";
import nestfuelHomeImageThree       from "../../assets/svg/home-preview-three.svg";
import bedicon from '../../assets/svg/bed-svgrepo-com.svg';
import bathicon from '../../assets/svg/shower-head-svgrepo-com.svg'
import garageicon from '../../assets/svg/garage-svgrepo-com.svg'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import './RightBlockStyles.css'
import { intAPR, intRNQM, withCommas, _debug,withCommasWithoutCurrency, SELPROP, PROPROP } from "../../constants";
import { BedsAndBaths } from "./RightBlock";
import { Link}        from 'react-router-dom';

import leftArrow from '../../assets/svg/left-arrow.svg'
import rightArrow from '../../assets/svg/right-arrow.svg'
import { fiFI } from "@mui/x-date-pickers";
import { empBenefitDSF_EMB } from "../../actions/borrower";

export function RightBlockCompare(props) {
  const {rbops, info, idnt} = props;
  console.log(idnt, "IDNT")
  const { bChoiceD,
          bChoiceDSF,
          bChoiceDSF_EMB,
          bChoiceDSFB,
          selectedProp,
          proposedProp,
          isBchoiceDLoading,
          isEmbFlow
        } = useSelector((state)=>state.borrower);
  console.log('here is something===>',bChoiceDSF)
  React.useEffect(()=>{

  },[isBchoiceDLoading])
  const [bChoiceDSF_E,setBChoiceDSF_E] =React.useState(bChoiceDSF)
  const [finObjS,setFinObjS]  = React.useState(bChoiceDSF_E[selectedProp?.propId][selectedProp?.finOpt])
  const [finObjP,setFinObjP]  = React.useState(bChoiceDSF_E[proposedProp?.propId][proposedProp?.finOpt])
  const [colBS,setColBS]      = React.useState('#80B2BE')
  const [colTS,setColTS]      = React.useState('#80B2BE')
  const [colBP,setColBP]      = React.useState('#7F4B08')
  const [colTP,setColTP]      = React.useState('#7F4B08')
  console.log(bChoiceDSF_E)
  var newArr=[]
  var newArr1=[]
console.log(proposedProp?.finOpt,'here is finOjs before setting')
const dispatch = useDispatch();

  
  for (let i = 0; i < bChoiceDSF.length; i++) {
    var newArr = bChoiceDSF[i].filter((val) => {
     
      if (val.label == "Smart Traditional" ){
        return val.label= "Smart Traditional"
      }
     
      if (val.label == "Employer Support" ){
        return val.label="Employer Benefit"

    }
      // return val.label == "Smart Traditional" || val.label == "Employer Benefit"\
    
    })
    newArr = newArr.map((item, index) => {
      if (item.label == "Employer Benefit") {
        item.benefits = Math.ceil(((newArr[0].emi * newArr[0].loanTerm * 12) - newArr[0].loanAmount) - ((item.initialMonthlyEMI * item.loanTerm * 12) - item.loanAmount))
        return item;
      }
      return item;
})
// console.log("BENEfits check",JSON.stringify(newArr))
// conole.log("Here is array")
newArr1[i]=newArr
  }

console.log("here new arr",newArr1)
console.log("FinObjP", finObjP ,'something else',bChoiceDSF_E[proposedProp?.propId][0])
// React.useEffect(()=>{
//   if (isEmbFlow ==true){
//     dispatch(empBenefitDSF_EMB(newArr1))

//     setBChoiceDSF_E(newArr1)
//     setFinObjS(bChoiceDSF_E[selectedProp?.propId][selectedProp?.finOpt])
//     if (proposedProp?.finOpt>0){ 
//     setFinObjP(bChoiceDSF_E[proposedProp?.propId][proposedProp?.finOpt-1])
//     }
//     if(proposedProp.finOpt==0){
//     setFinObjP(bChoiceDSF_E[proposedProp?.propId][proposedProp?.finOpt])

//     }
//   }
// },[proposedProp?.propId,proposedProp?.finOpt])
var newArr2=[]
React.useEffect(()=>{
  for (let i = 0; i < bChoiceDSF.length; i++) {
    var newArry = bChoiceDSF[i].filter((val) => {
     
      if (val.label == "Smart Traditional" ){
        return val.label= "Smart Traditional"
      }
     
      if (val.label == "Employer Support" ){
        return val.label="Employer Benefit"

    }
      // return val.label == "Smart Traditional" || val.label == "Employer Benefit"\
    
    })
    newArry = newArry.map((item, index) => {
      if (item.label == "Employer Benefit") {
        item.benefits = Math.ceil(((newArr[0].emi * newArr[0].loanTerm * 12) - newArr[0].loanAmount) - ((item.initialMonthlyEMI * item.loanTerm * 12) - item.loanAmount))
        return item;
      }
      return item;
})
newArr2[i]=newArry
  }
},[bChoiceDSF])

console.log(newArr2,'this is the new array after changes')

  React.useEffect(()=>{
    if (isEmbFlow==true){
      console.log(proposedProp?.finOpt,'here is the value of thg proposed prop')
    }
    switch(bChoiceDSF_E[proposedProp?.propId][proposedProp?.finOpt]?.label){
      case 'Smart Traditional':
        setColBP('#00657C')
        setColTP('#00323E')
        break
      case 'Interest Only':
        setColBP('#DCC254')
        setColTP('#6D602A')
        break
      case 'Pay Points':
        setColBP('#6794af')
        setColTP('#243F4F')
        break
      case 'Reduced Initial Monthly':
        setColBP('#EF395C')
        setColTP('#7C2636')
        break
      case 'Part Buy Part Rent':
        setColBP('#DCC254')
        setColTP('#6D602A')
        break
      case 'Longer Term':
        setColBP('#82C494')
        setColTP('#43634B')
        break
      case 'Employer Benefit':
        setColBP('#7F4B08')
        setColTP('#204975')
        break
      default:
        break;
    }
    setFinObjP(bChoiceDSF_E[proposedProp?.propId][proposedProp?.finOpt])
  },[proposedProp?.propId,proposedProp?.finOpt])
  
  React.useEffect(()=>{
    switch(bChoiceDSF_E[selectedProp?.propId][selectedProp?.finOpt]?.label){
      case 'Smart Traditional':
        setColBS('#00657C')
        setColTS('#80B2BE')
        break
      case 'Interest Only':
        setColBS('#DCC254')
        setColTS('#ECDFA9')
        break
      case 'Pay Points':
        setColBS('#6794af')
        setColTS('#A4BFCF')
        break
      case 'Reduced Initial Monthly':
        setColBS('#EF395C')
        setColTS('#F79BAD')
        break
      case 'Part Buy Part Rent':
        setColBS('#DCC254')
        setColTS('#ECDFA9')
        break
      case 'Longer Term':
        setColBS('#82C494')
        setColTS('#BFDEC7')
        break
      case 'Employer Benefit':
        setColBS('#7F4B08')
        setColTS('#A0CBFA')
        break
      default:
        break;
    }
    setFinObjS(bChoiceDSF_E[selectedProp?.propId][selectedProp?.finOpt])
  
  },[selectedProp?.propId,selectedProp?.finOpt])
  const traditionalPurchasingPower = Math.ceil(bChoiceDSFB?.tradAfford?.propPrice)
        const estimatedPurchasingPower = Math.ceil(bChoiceDSFB?.extendedAfford?.propPrice)
        const estimatedExtraBenifit  = estimatedPurchasingPower - traditionalPurchasingPower
        
  return (
      <React.Fragment><CssBaseline/>
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: '50vw',height:'46.75vw' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width:'inherit'}}>
            <Box sx={{mt:'0.8vw',ml:'0.8vw',mr:'0.4vw',background: 'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'15.5vw' , borderRadius:'0.5vw'}}>
              <OuterCarousel itemArray={bChoiceD} numCards={1} opt='selected' colB={colBS} colT={colTS} callFrom={rbops} />
            </Box> 
            
            <Box sx={{ mt:'0.8vw',ml:'0.4vw',mr:'0.8vw',background:'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'15.5vw' ,borderRadius:'0.5vw'}}>
              <OuterCarousel itemArray={bChoiceD} numCards={1} opt='proposed' colB={colBP} colT={colTP} callFrom={rbops} />
            </Box>  
          </Box> 
      <Box sx={{ display: 'flex', flexDirection: 'row', width:'50vw',height:'10.125vw'}}>
        <Box sx={{mt:'0.8vw',ml:'0.8vw',mr:'0.4vw',background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'10.125vw' ,border:0, borderRadius:'0.5vw'}}>
          <Box backgroundColor={colTS} height='10.125vw'borderRadius='0.5vw' border={0} borderColor={colBS}>
            <DataCarousel index={selectedProp?.propId} _bChoiceDSF_E={bChoiceDSF_E[selectedProp?.propId]} opt='selected' colB={colBS} colT={colTS} finObjS ={finObjS} idnt ={idnt} />
          </Box>
        </Box>
        <Box sx={{mt:'0.8vw',ml:'0.4vw',mr:'0.8vw',background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'10.125vw' ,border:0, borderRadius:'0.5vw'}}>
          <Box backgroundColor={colTP} height='10.125vw'borderRadius='0.5vw'border={0} borderColor={colBP} >
            <DataCarousel index={proposedProp?.propId} _bChoiceDSF_E={bChoiceDSF_E[proposedProp?.propId]} opt='proposed' colB={colBP} colT={colBP} finObjP ={finObjP} idnt={idnt} />
          </Box>
            
        </Box>
      </Box>
      
      <Box sx={{ mt:'-2.3vw', background: "#FFFFFF 0% 0% no-repeat padding-box",width:'100%', height:"21vw",boxShadow: '0vw 0vw 19px #0000008F'}}> 
      {
      isEmbFlow ==false ||isEmbFlow == true ?
        <>
              <Typography sx={{mt:'4vw', width:'48vw'}} fontSize='2.5vw' align='center' fontWeight='700' color='#31312C'>{isEmbFlow? 'Compare Financing Options':'Compare property options'}</Typography> 

<Box sx={{display: 'flex', flexDirection: 'row', width:'inherit'}}>
  <Box sx={{ml:'2vw',background: "#FFFFFF 0% 0% no-repeat padding-box", width: "24vw",height:"18vw",border:0 ,borderRadius:0}}>
    <Stack mt='0.2vw' direction="row" justifyContent="space-between">
      <Typography  fontSize='1.15vw' align='left' fontWeight='500' color='#31312C'></Typography> 
      <Typography width='18vw' fontSize='1.15vw' align='right' fontWeight='bold' color='#000000'>{finObjS?.label?.toUpperCase()}</Typography> 
    </Stack>
      <Stack mt='0.4vw' display='flex' >
  
    <Typography  fontSize='1.2vw' fontWeight={500}  align='left' color='#31312C'>Monthly payment</Typography>
    <Typography ml='14vw' mt='-1.8vw' fontSize='1.2vw' fontWeight={700} width='10vw' color='black' align='right'>{withCommas(Math.ceil(finObjS?.monthlyEMI))} </Typography>
      
    </Stack>
    {/* <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      
    { finObjS?.label === "Employer Benefit" 
    ?
    <>
    <Typography fontSize='1.2vw' fontWeight={500} width='20vw' align='left' color='#31312C'>(After 5 years) </Typography>
      <Typography ml='6vw' mt='0.0vw' fontSize='1.2vw' fontWeight={700} width='8vw' color='black' align='right'>+ {withCommas(Math.ceil(finObjS?.loanAmount/3000))}</Typography>
    </>
  : <>
  <Typography fontSize='1.2vw' fontWeight={500} width='20vw' align='left' color='#31312C'>(After 5 years) </Typography>
  <Typography ml='6vw' mt='0.0vw' fontSize='1.2vw' fontWeight={700} width='8vw' color='black' align='right'> - </Typography>  
  </>
  }
    </Stack> */}


    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>Purchasing power</Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObjS?.label === "Smart Traditional" ? withCommas(traditionalPurchasingPower):finObjS?.label === "Employer Benefit"?withCommas(estimatedPurchasingPower):''} </Typography>
    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>Benefits savings </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{finObjS?.benefits? withCommas(finObjS?.benefits):'-'}</Typography>

    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>  Term, type </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{finObjS?.loanTerm} years, fixed </Typography>
    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> Rate </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{(finObjS?.interestRate)?.toFixed(2)}%</Typography>
    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> APR</Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{finObjS?.label === "Part Buy Part Rent" ? (finObjS?.interestRate + 0.31).toFixed(2) : finObjS?.apr.toFixed(2)}%</Typography>
    </Stack>
  
  </Box>
  <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "25vw",height:"18vw",pl:'2vw',border:0 ,borderRadius:0}}>
    <Typography ml='2vw' mt='0.2vw' width='18vw' fontSize='1.15vw' align='left' fontWeight='bold' color='#000000'>{finObjP?.label.toUpperCase()}</Typography> 
    { finObjP?.label === "Employer Benefit" 
    ?
    <>
      <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {withCommas(Math.ceil(finObjP?.monthlyEMI))} </Typography>
      {/* <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>+ {withCommas(Math.ceil(finObjP?.loanAmount/3000))}</Typography> */}
    </>
  : <> 
  <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>{withCommas(Math.ceil(finObjP?.monthlyEMI))} </Typography>  
  {/* <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> - </Typography>   */}
  </>
  }
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label === "Smart Traditional" ? withCommas(traditionalPurchasingPower):finObjP?.label === "Employer Benefit"?withCommas(estimatedPurchasingPower):'-'} </Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>{finObjP?.benefits? withCommas(finObjP?.benefits):'-'}</Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.loanTerm} years, fixed</Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>{(finObjP?.interestRate)?.toFixed(2)}%  </Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>{finObjP?.label === "Part Buy Part Rent" ? (finObjP?.interestRate + 0.31).toFixed(2) : finObjP?.apr?.toFixed(2)}% </Typography>

  </Box>
</Box>
        </>
        :
        <>
              <Typography sx={{mt:'4vw', width:'48vw'}} fontSize='2.5vw' align='center' fontWeight='700' color='#31312C'>Compare property options</Typography> 

<Box sx={{display: 'flex', flexDirection: 'row', width:'inherit'}}>
  <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "24vw",height:"18vw",pl:'4vw',border:0 ,borderRadius:0}}>
    <Stack mt='0.2vw' direction="row" justifyContent="space-between">
      <Typography  fontSize='1.15vw' align='left' fontWeight='500' color='#31312C'></Typography> 
      <Typography width='18vw' fontSize='1.15vw' align='right' fontWeight='bold' color='#000000'>{finObjS?.label?.toUpperCase()}</Typography> 
    </Stack>
      <Stack mt='0.4vw' direction="row" justifyContent="space-around">
  
    <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>Monthly </Typography>
    <Typography ml='11vw' mt='0.0vw' fontSize='1.2vw' fontWeight={700} width='10vw' color='black' align='right'>{withCommas(Math.ceil(finObjS?.monthlyEMI))}</Typography>
      
    </Stack>
    {/* <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      
    { finObjS?.label === "Employer Benefit" 
    ?
    <>
    <Typography fontSize='1.2vw' fontWeight={500} width='20vw' align='left' color='#31312C'>(After 5 years) </Typography>
      <Typography ml='6vw' mt='0.0vw' fontSize='1.2vw' fontWeight={700} width='8vw' color='black' align='right'>+ {withCommas(Math.ceil(finObjS?.loanAmount/3000))}</Typography>
    </>
  : <>
  <Typography fontSize='1.2vw' fontWeight={500} width='20vw' align='left' color='#31312C'>(After 5 years) </Typography>
  <Typography ml='6vw' mt='0.0vw' fontSize='1.2vw' fontWeight={700} width='8vw' color='black' align='right'> - </Typography>  
  </>
  }
    </Stack> */}


    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>Rate </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {(finObjS?.interestRate)?.toFixed(2)}% </Typography>
    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> APR  </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObjS?.label === "Part Buy Part Rent" ? (finObjS?.interestRate + 0.31).toFixed(2) : finObjS?.apr.toFixed(2)}%</Typography>
    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> Ownership </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{finObjS?.label === "Part Buy Part Rent" ? finObjS?.percentOwnership: 100} % </Typography>
    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> Term </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObjS?.loanTerm} years</Typography>
    </Stack>
    {/* <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> IR Term </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{finObjS?.label ==="Interest Only"? '3 years' : '-'}</Typography>
    </Stack> */}
  
  </Box>
  <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "25vw",height:"18vw",pl:'2vw',border:0 ,borderRadius:0}}>
    <Typography ml='2vw' mt='0.2vw' width='18vw' fontSize='1.15vw' align='left' fontWeight='bold' color='#000000'>{finObjP?.label.toUpperCase()}</Typography> 
    { finObjP?.label === "Employer Benefit" 
    ?
    <>
      <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {withCommas(Math.ceil(finObjP?.monthlyEMI))} </Typography>
      {/* <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>+ {withCommas(Math.ceil(finObjP?.loanAmount/3000))}</Typography> */}
    </>
  : <> 
  <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>{withCommas(Math.ceil(finObjP?.monthlyEMI))} </Typography>  
  {/* <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> - </Typography>   */}
  </>
  }
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {(finObjP?.interestRate)?.toFixed(2)}% </Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label === "Part Buy Part Rent" ? (finObjP?.interestRate + 0.31).toFixed(2) : finObjP?.apr?.toFixed(2)}%</Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label === "Part Buy Part Rent" ? finObjP?.percentOwnership: 100} %</Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.loanTerm} years</Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label ==="Interest Only"? '3 years' : '-'} </Typography>

  </Box>
</Box>
        </>
      }

      </Box>
  </Box>
      </React.Fragment>
    );
}
export function RightBlockCompareEMB(props) {
  const {rbops,info} = props;
  const { bChoiceD,
          bChoiceDSF,
          bChoiceDSF_EMB,
          bChoiceDSFB,
          selectedProp,
          proposedProp,
          isEmbFlow
        } = useSelector((state)=>state.borrower);
  console.log('here is something===>',bChoiceDSF)
  const [bChoiceDSF_E,setBChoiceDSF_E] =React.useState(info)
  const [finObjS,setFinObjS]  = React.useState(bChoiceDSF_E[selectedProp?.propId][selectedProp?.finOpt])
  const [finObjP,setFinObjP]  = React.useState(bChoiceDSF_E[proposedProp?.propId][proposedProp?.finOpt])
  const [colBS,setColBS]      = React.useState('#80B2BE')
  const [colTS,setColTS]      = React.useState('#80B2BE')
  const [colBP,setColBP]      = React.useState('#7F4B08')
  const [colTP,setColTP]      = React.useState('#7F4B08')
  console.log(bChoiceDSF_E)
  var newArr=[]
  var newArr1=[]
console.log(proposedProp?.finOpt,'here is finOjs before setting')
const dispatch = useDispatch();

  
  for (let i = 0; i < bChoiceDSF.length; i++) {
    var newArr = bChoiceDSF[i].filter((val) => {
      return val.label == "Smart Traditional" || val.label == "Employer Benefit"
    })
    newArr = newArr.map((item, index) => {
      if (item.label == "Employer Benefit") {
        item.benefits = Math.ceil(((newArr[0].emi * newArr[0].loanTerm * 12) - newArr[0].loanAmount) - ((item.initialMonthlyEMI * item.loanTerm * 12) - item.loanAmount))
        return item;
      }
      return item;
})
// console.log("BENEfits check",JSON.stringify(newArr))
// conole.log("Here is array")
newArr1[i]=newArr
  }

console.log("here new arr",newArr1)
console.log("FinObjP", finObjP ,'something else',bChoiceDSF_E[proposedProp?.propId][0])
// React.useEffect(()=>{
//   if (isEmbFlow ==true){
//     dispatch(empBenefitDSF_EMB(newArr1))

//     setBChoiceDSF_E(newArr1)
//     setFinObjS(bChoiceDSF_E[selectedProp?.propId][selectedProp?.finOpt])
//     if (proposedProp?.finOpt>0){ 
//     setFinObjP(bChoiceDSF_E[proposedProp?.propId][proposedProp?.finOpt-1])
//     }
//     if(proposedProp.finOpt==0){
//     setFinObjP(bChoiceDSF_E[proposedProp?.propId][proposedProp?.finOpt])

//     }
//   }
// },[proposedProp?.propId,proposedProp?.finOpt])

  React.useEffect(()=>{
    // dispatch(empBenefitDSF_EMB())
    setBChoiceDSF_E(bChoiceDSF_EMB)
  },[bChoiceDSF])
  React.useEffect(()=>{

  },[bChoiceDSF_E])
  React.useEffect(()=>{
    if (isEmbFlow==true){
      console.log(proposedProp?.finOpt,'here is the value of thg proposed prop')
    }
    switch(bChoiceDSF_E[proposedProp?.propId][proposedProp?.finOpt]?.label){
      case 'Smart Traditional':
        setColBP('#00657C')
        setColTP('#00323E')
        break
      case 'Interest Only':
        setColBP('#DCC254')
        setColTP('#6D602A')
        break
      case 'Pay Points':
        setColBP('#6794af')
        setColTP('#243F4F')
        break
      case 'Reduced Initial Monthly':
        setColBP('#EF395C')
        setColTP('#7C2636')
        break
      case 'Part Buy Part Rent':
        setColBP('#DCC254')
        setColTP('#6D602A')
        break
      case 'Longer Term':
        setColBP('#82C494')
        setColTP('#43634B')
        break
      case 'Employer Benefit' ||"Employer Support":
        setColBP('#7F4B08')
        setColTP('#204975')
        break
      default:
        break;
    }
    setFinObjP(bChoiceDSF_E[proposedProp?.propId][proposedProp?.finOpt])
  },[proposedProp?.propId,proposedProp?.finOpt])
  
  React.useEffect(()=>{
    switch(bChoiceDSF_E[selectedProp?.propId][selectedProp?.finOpt]?.label){
      case 'Smart Traditional':
        setColBS('#00657C')
        setColTS('#80B2BE')
        break
      case 'Interest Only':
        setColBS('#DCC254')
        setColTS('#ECDFA9')
        break
      case 'Pay Points':
        setColBS('#6794af')
        setColTS('#A4BFCF')
        break
      case 'Reduced Initial Monthly':
        setColBS('#EF395C')
        setColTS('#F79BAD')
        break
      case 'Part Buy Part Rent':
        setColBS('#DCC254')
        setColTS('#ECDFA9')
        break
      case 'Longer Term':
        setColBS('#82C494')
        setColTS('#BFDEC7')
        break
      case 'Employer Benefit':
        setColBS('#7F4B08')
        setColTS('#A0CBFA')
        break
      default:
        break;
    }
    setFinObjS(bChoiceDSF_E[selectedProp?.propId][selectedProp?.finOpt])
  
  },[selectedProp?.propId,selectedProp?.finOpt])
  const traditionalPurchasingPower = Math.ceil(bChoiceDSFB?.tradAfford?.propPrice)
        const estimatedPurchasingPower = Math.ceil(bChoiceDSFB?.extendedAfford?.propPrice)
        const estimatedExtraBenifit  = estimatedPurchasingPower - traditionalPurchasingPower
        
  return (
      <React.Fragment><CssBaseline/>
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: '50vw',height:'46.75vw' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width:'inherit'}}>
            <Box sx={{mt:'0.8vw',ml:'0.8vw',mr:'0.4vw',background: 'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'15.5vw' , borderRadius:'0.5vw'}}>
              <OuterCarousel itemArray={bChoiceD} numCards={1} opt='selected' colB={colBS} colT={colTS} callFrom={rbops} />
            </Box> 
            
            <Box sx={{ mt:'0.8vw',ml:'0.4vw',mr:'0.8vw',background:'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'15.5vw' ,borderRadius:'0.5vw'}}>
              <OuterCarousel itemArray={bChoiceD} numCards={1} opt='proposed' colB={colBP} colT={colTP} callFrom={rbops} />
            </Box>  
          </Box> 
      <Box sx={{ display: 'flex', flexDirection: 'row', width:'50vw',height:'10.125vw'}}>
        <Box sx={{mt:'0.8vw',ml:'0.8vw',mr:'0.4vw',background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'10.125vw' ,border:0, borderRadius:'0.5vw'}}>
          <Box backgroundColor={colTS} height='10.125vw'borderRadius='0.5vw' border={0} borderColor={colBS}>
            <DataCarousel index={selectedProp?.propId} _bChoiceDSF_E={bChoiceDSF_E[selectedProp?.propId]} opt='selected' colB={colBS} colT={colTS} />
          </Box>
        </Box>
        <Box sx={{mt:'0.8vw',ml:'0.4vw',mr:'0.8vw',background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'10.125vw' ,border:0, borderRadius:'0.5vw'}}>
          <Box backgroundColor={colTP} height='10.125vw'borderRadius='0.5vw'border={0} borderColor={colBP} >
            <DataCarousel index={proposedProp?.propId} _bChoiceDSF_E={bChoiceDSF_E[proposedProp?.propId]} opt='proposed' colB={colBP} colT={colBP} />
          </Box>
            
        </Box>
      </Box>
      
      <Box sx={{ mt:'-2.3vw', background: "#FFFFFF 0% 0% no-repeat padding-box",width:'100%', height:"21vw",boxShadow: '0vw 0vw 19px #0000008F'}}> 
      {isEmbFlow ==true ||isEmbFlow==false ?
        <>
              <Typography sx={{mt:'4vw', width:'48vw'}} fontSize='2.5vw' align='center' fontWeight='700' color='#31312C'>Compare property options</Typography> 

<Box sx={{display: 'flex', flexDirection: 'row', width:'inherit'}}>
  <Box sx={{ml:'2vw',background: "#FFFFFF 0% 0% no-repeat padding-box", width: "24vw",height:"18vw",border:0 ,borderRadius:0}}>
    <Stack mt='0.2vw' direction="row" justifyContent="space-between">
      <Typography  fontSize='1.15vw' align='left' fontWeight='500' color='#31312C'></Typography> 
      <Typography width='18vw' fontSize='1.15vw' align='right' fontWeight='bold' color='#000000'>{finObjS?.label?.toUpperCase()}</Typography> 
    </Stack>
      <Stack mt='0.4vw' display='flex' >
  
    <Typography  fontSize='1.2vw' fontWeight={500}  align='left' color='#31312C'>Monthly payment</Typography>
    <Typography ml='14vw' mt='-1.8vw' fontSize='1.2vw' fontWeight={700} width='10vw' color='black' align='right'>{withCommas(Math.ceil(finObjS?.monthlyEMI))} </Typography>
      
    </Stack>
    {/* <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      
    { finObjS?.label === "Employer Benefit" 
    ?
    <>
    <Typography fontSize='1.2vw' fontWeight={500} width='20vw' align='left' color='#31312C'>(After 5 years) </Typography>
      <Typography ml='6vw' mt='0.0vw' fontSize='1.2vw' fontWeight={700} width='8vw' color='black' align='right'>+ {withCommas(Math.ceil(finObjS?.loanAmount/3000))}</Typography>
    </>
  : <>
  <Typography fontSize='1.2vw' fontWeight={500} width='20vw' align='left' color='#31312C'>(After 5 years) </Typography>
  <Typography ml='6vw' mt='0.0vw' fontSize='1.2vw' fontWeight={700} width='8vw' color='black' align='right'> - </Typography>  
  </>
  }
    </Stack> */}


    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>Purchasing power</Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObjS?.label === "Smart Traditional" ? withCommas(traditionalPurchasingPower):finObjS?.label === "Employer Benefit"?withCommas(estimatedPurchasingPower):''} </Typography>
    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>Benefits savings </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{finObjS?.benefits? withCommas(finObjS?.benefits):'-'}</Typography>

    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>  Term, type </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{finObjS?.loanTerm} years, fixed </Typography>
    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> Rate </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{(finObjS?.interestRate)?.toFixed(2)}%</Typography>
    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> APR</Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{finObjS?.label === "Part Buy Part Rent" ? (finObjS?.interestRate + 0.31).toFixed(2) : finObjS?.apr.toFixed(2)}%</Typography>
    </Stack>
  
  </Box>
  <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "25vw",height:"18vw",pl:'2vw',border:0 ,borderRadius:0}}>
    <Typography ml='2vw' mt='0.2vw' width='18vw' fontSize='1.15vw' align='left' fontWeight='bold' color='#000000'>{finObjP?.label.toUpperCase()}</Typography> 
    { finObjP?.label === "Employer Benefit" 
    ?
    <>
      <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {withCommas(Math.ceil(finObjP?.monthlyEMI))} </Typography>
      {/* <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>+ {withCommas(Math.ceil(finObjP?.loanAmount/3000))}</Typography> */}
    </>
  : <> 
  <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>{withCommas(Math.ceil(finObjP?.monthlyEMI))} </Typography>  
  {/* <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> - </Typography>   */}
  </>
  }
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label === "Smart Traditional" ? withCommas(traditionalPurchasingPower):finObjP?.label === "Employer Benefit"?withCommas(estimatedPurchasingPower):'-'} </Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>{finObjP?.benefits? withCommas(finObjP?.benefits):'-'}</Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.loanTerm} years, fixed</Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>{(finObjP?.interestRate)?.toFixed(2)}%  </Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>{finObjP?.label === "Part Buy Part Rent" ? (finObjP?.interestRate + 0.31).toFixed(2) : finObjP?.apr?.toFixed(2)}% </Typography>

  </Box>
</Box>
        </>:
        <>
              <Typography sx={{mt:'4vw', width:'48vw'}} fontSize='2.5vw' align='center' fontWeight='700' color='#31312C'>Compare Financing Options</Typography> 

<Box sx={{display: 'flex', flexDirection: 'row', width:'inherit'}}>
  <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "24vw",height:"18vw",pl:'4vw',border:0 ,borderRadius:0}}>
    <Stack mt='0.2vw' direction="row" justifyContent="space-between">
      <Typography  fontSize='1.15vw' align='left' fontWeight='500' color='#31312C'></Typography> 
      <Typography width='18vw' fontSize='1.15vw' align='right' fontWeight='bold' color='#000000'>{finObjS?.label?.toUpperCase()}</Typography> 
    </Stack>
      <Stack mt='0.4vw' direction="row" justifyContent="space-around">
  
    <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>Monthly </Typography>
    <Typography ml='11vw' mt='0.0vw' fontSize='1.2vw' fontWeight={700} width='10vw' color='black' align='right'>{withCommas(Math.ceil(finObjS?.monthlyEMI))}</Typography>
      
    </Stack>
    {/* <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      
    { finObjS?.label === "Employer Benefit" 
    ?
    <>
    <Typography fontSize='1.2vw' fontWeight={500} width='20vw' align='left' color='#31312C'>(After 5 years) </Typography>
      <Typography ml='6vw' mt='0.0vw' fontSize='1.2vw' fontWeight={700} width='8vw' color='black' align='right'>+ {withCommas(Math.ceil(finObjS?.loanAmount/3000))}</Typography>
    </>
  : <>
  <Typography fontSize='1.2vw' fontWeight={500} width='20vw' align='left' color='#31312C'>(After 5 years) </Typography>
  <Typography ml='6vw' mt='0.0vw' fontSize='1.2vw' fontWeight={700} width='8vw' color='black' align='right'> - </Typography>  
  </>
  }
    </Stack> */}


    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>Rate </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {(finObjS?.interestRate)?.toFixed(2)}% </Typography>
    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> APR  </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObjS?.label === "Part Buy Part Rent" ? (finObjS?.interestRate + 0.31).toFixed(2) : finObjS?.apr.toFixed(2)}%</Typography>
    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> Ownership </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{finObjS?.label === "Part Buy Part Rent" ? finObjS?.percentOwnership: 100} % </Typography>
    </Stack>
    <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> Term </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObjS?.loanTerm} years</Typography>
    </Stack>
    {/* <Stack mt='0.4vw' direction="row" justifyContent="space-around">
      <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> IR Term </Typography>
      <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{finObjS?.label ==="Interest Only"? '3 years' : '-'}</Typography>
    </Stack> */}
  
  </Box>
  <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "25vw",height:"18vw",pl:'2vw',border:0 ,borderRadius:0}}>
    <Typography ml='2vw' mt='0.2vw' width='18vw' fontSize='1.15vw' align='left' fontWeight='bold' color='#000000'>{finObjP?.label.toUpperCase()}</Typography> 
    { finObjP?.label === "Employer Benefit" 
    ?
    <>
      <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {withCommas(Math.ceil(finObjP?.monthlyEMI))} </Typography>
      {/* <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>+ {withCommas(Math.ceil(finObjP?.loanAmount/3000))}</Typography> */}
    </>
  : <> 
  <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>{withCommas(Math.ceil(finObjP?.monthlyEMI))} </Typography>  
  {/* <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> - </Typography>   */}
  </>
  }
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {(finObjP?.interestRate)?.toFixed(2)}% </Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label === "Part Buy Part Rent" ? (finObjP?.interestRate + 0.31).toFixed(2) : finObjP?.apr?.toFixed(2)}%</Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label === "Part Buy Part Rent" ? finObjP?.percentOwnership: 100} %</Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.loanTerm} years</Typography>
    <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label ==="Interest Only"? '3 years' : '-'} </Typography>

  </Box>
</Box>
        </>
      }

      </Box>
  </Box>
      </React.Fragment>
    );
}

export function RightBlockCompareSmartChoice(props) {
  const {rbops} = props;
  const { bChoiceD,
          bChoiceA,
          bChoiceDSF,
          isEmbFlow,
          bChoiceDSF_EMB,
          selectedProp,
          proposedProp
        } = useSelector((state)=>state.borrower);
  // const [bChoiceDSF,setbChoiceDSF] = React.useState(bChoiceDSF)
  const [finObjS,setFinObjS]  = React.useState(bChoiceDSF[selectedProp?.propId][selectedProp?.finOpt])
  const [finObjP,setFinObjP]  = React.useState(bChoiceDSF[proposedProp?.propId][proposedProp?.finOpt])
  const [colBS,setColBS]      = React.useState('#00657C')
  const [colTS,setColTS]      = React.useState('#FEFEFE')
  const [colBP,setColBP]      = React.useState('#00657C')
  const [colTP,setColTP]      = React.useState('#FEFEFE')
  React.useEffect(()=>{
    // setbChoiceDSF(bChoiceDSF)
  },[bChoiceDSF])
  React.useEffect(()=>{
    if (isEmbFlow ==true){
      
  
      // setbChoiceDSF(bChoiceDSFMB)
      // setFinObjS(bChoiceDSF[selectedProp?.propId][selectedProp?.finOpt])
      // if (proposedProp?.finOpt>0){ 
      // setFinObjP(bChoiceDSF[proposedProp?.propId][proposedProp?.finOpt-1])
      // }
      // if(proposedProp.finOpt==0){
      // setFinObjP(bChoiceDSF[proposedProp?.propId][proposedProp?.finOpt])
  
      // }
    }
  },[proposedProp?.propId,proposedProp?.finOpt])
  
  React.useEffect(()=>{
    const finOpti=isEmbFlow?(proposedProp?.finOpt>0?2:proposedProp?.finOpt):proposedProp?.finOpt
    console.log("finopti","1"+bChoiceDSF[proposedProp?.propId][finOpti]?.label);

    switch(bChoiceDSF[proposedProp?.propId][finOpti]?.label){
      case 'Smart Traditional':
        setColBP('#00657C')
        setColTP('#00323E')
        break
      case 'Interest Only':
        setColBP('#DCC254')
        setColTP('#6D602A')
        break
      case 'Pay Points':
        setColBP('#6794af')
        setColTP('#243F4F')
        break
      case 'Reduced Initial Monthly':
        setColBP('#EF395C')
        setColTP('#7C2636')
        break
      case 'Part Buy Part Rent':
        setColBP('#DCC254')
        setColTP('#6D602A')
        break
      case 'Longer Term':
        setColBP('#82C494')
        setColTP('#43634B')
        break
      case "Employer Support":
      case "Employer Benefit":
        setColBP('#884D00')
        setColTP('#204975')
        break
      default:
        break;
    }
    setFinObjP(bChoiceDSF[proposedProp?.propId][finOpti])
  },[proposedProp?.propId,proposedProp?.finOpt],bChoiceDSF)
  
  React.useEffect(()=>{
    const finOpti=isEmbFlow?(selectedProp?.finOpt>0?2:selectedProp?.finOpt):selectedProp?.finOpt
    console.log("finopti","1"+bChoiceDSF[selectedProp?.propId][finOpti]?.label);

    switch(bChoiceDSF[selectedProp?.propId][finOpti]?.label){
      case 'Smart Traditional':
        setColBS('#00657C')
        setColTS('#80B2BE')
        break
      case 'Interest Only':
        setColBS('#DCC254')
        setColTS('#ECDFA9')
        break
      case 'Pay Points':
        setColBS('#6794af')
        setColTS('#A4BFCF')
        break
      case 'Reduced Initial Monthly':
        setColBS('#EF395C')
        setColTS('#F79BAD')
        break
      case 'Part Buy Part Rent':
        setColBS('#DCC254')
        setColTS('#ECDFA9')
        break
      case 'Longer Term':
        setColBS('#82C494')
        setColTS('#BFDEC7')
        break
      case "Employer Support":
      case "Employer Benefit":
        setColBS('#FFCD8A')
        setColTS('#A0CBFA')
        break
      default:
        break;
    }
    setFinObjS(bChoiceDSF[selectedProp?.propId][finOpti])
  },[selectedProp?.propId,selectedProp?.finOpt,bChoiceDSF])
  const [showMap,setShowMap] = React.useState(-1)
  return (
      <React.Fragment><CssBaseline/>
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: '50vw',height:'46.75vw' }}>
      <Box m={'0.8vw'} sx={{background:'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "97%",height:'18vw' ,border:0, borderRadius:'0.5vw'}}>
            {showMap === 5  
                ?<GoogleMaps position={{lat: selectedProp?.prop?.geoLocation.lat, lng:selectedProp?.prop?.geoLocation.lon}}
                                markerInput={[selectedProp?.prop]} wHeight='13.5vw'/> 

                :
                  <InnerCarousel imageArray={selectedProp?.prop.images} url={selectedProp?.prop?.url} numCards={2}/>
            }
            <Box sx={{  display: 'flex', flexDirection: 'row', width:'inherit', height:"4.5vw"}}> 
              <Typography ml='1vw' mt='1vw' width='12vw' fontSize='1.67vw' fontWeight='bold' color='black' align='center'>{withCommas(bChoiceD[selectedProp.propId].offerPrice)}</Typography>
              <Box width='15vw'>
                <Typography fontSize='0.78vw' align='left' color="#31312C"> 
                  <Button sx={{padding:0, minHeight: 0, minWidth: 0, mt:'1vw'}} onClick={()=> {showMap === -1? setShowMap(5) : setShowMap(-1)}} >
                  <LocationOnIcon sx={{color:'#000000', fontSize:'2vw'}}/>
                  </Button>{selectedProp?.prop.address}
                  </Typography>
                <Typography paddingLeft="2.14vw" mr='1.2vw' mt='-0.97vw' fontSize='0.78vw' color='#31312C' align='left'> {selectedProp?.prop.city}, {selectedProp?.prop.state} - {selectedProp?.prop.zipcode}</Typography>
              </Box>
              <Box sx={{background:'inherit',width:'24vw'}} >
                <BedsAndBaths color={'black'}/>
              </Box>
            </Box>
        </Box>
         
      <Box sx={{ mt:'-0.8vw',display: 'flex', flexDirection: 'row', width:'50vw',height:'10.125vw'}}>
        <Box sx={{mt:'0.8vw',ml:'0.8vw',mr:'0.4vw',background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'10.125vw' ,border:0, borderRadius:'0.5vw'}}>
          <Box backgroundColor={colTS} height='10.125vw'borderRadius='0.5vw' border={0} borderColor={colBS}>
            <DataCarousel index={selectedProp?.propId} _bChoiceDSF_E={bChoiceDSF[selectedProp?.propId]} opt='selected' colB={colBS} colT={colTS}  finObjS ={finObjS}  />
          </Box>
        </Box>
        <Box sx={{mt:'0.8vw',ml:'0.4vw',mr:'0.8vw',background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'10.125vw' ,border:0, borderRadius:'0.5vw'}}>
          <Box backgroundColor={colTP} height='10.125vw'borderRadius='0.5vw'border={0} borderColor={colBP} >
            <DataCarousel index={proposedProp?.propId} _bChoiceDSF_E={bChoiceDSF[proposedProp?.propId]} opt='proposed' colB={colBP} colT={colTP}  finObjP ={finObjP} />
          </Box>
        </Box>
      </Box>
      
      <Box sx={{ mt:'-2.3vw', background: "#FFFFFF 0% 0% no-repeat padding-box",width:'100%', height:"21vw",boxShadow: '0vw 0vw 19px #0000008F'}}> 
      <Typography sx={{mt:'4vw', width:'48vw'}} fontSize='2.0vw' align='center' fontWeight='700' color='#31312C'>Compare Financing Options</Typography> 
        <Box sx={{display: 'flex', flexDirection: 'row', width:'inherit'}}>
          <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "24vw",height:"18vw",pl:'4vw',border:0 ,borderRadius:0}}>
            <Stack mt='0.2vw' direction="row" justifyContent="space-between">
              <Typography  fontSize='1.15vw' align='left' fontWeight='500' color='#31312C'></Typography> 
              <Typography width='18vw' fontSize='1.15vw' align='right' fontWeight='bold' color='#000000'>{finObjS?.label.toUpperCase()}</Typography> 
            </Stack>
            <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>Monthly </Typography>
              <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {withCommas(Math.ceil(finObjS?.monthlyEMI))}</Typography>
            </Stack>
            {/* <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              
              { finObjS?.label === "Employer Benefit"
              ?
              <>
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>(5+ yrs) </Typography>
                <Typography ml='11vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>+ {withCommas(Math.ceil(finObjS?.loanAmount/3000))}</Typography>
              </>
            : <>
            <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>(5+ yrs) </Typography>
            <Typography ml='10vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='right'> - </Typography>  
            </>
            }
              </Stack> */}
            <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>Rate </Typography>
              <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {(finObjS?.interestRate)?.toFixed(2)}% </Typography>
            </Stack>
            <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> APR  </Typography>
              <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObjS?.label === "Part Buy Part Rent" ? (finObjS?.interestRate + 0.31).toFixed(2) : finObjS?.apr.toFixed(2)}%</Typography>
            </Stack>
            <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> Ownership </Typography>
              <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObjS?.label === "Part Buy Part Rent" ? finObjS?.percentOwnership : 100}%</Typography>
            </Stack>
            <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> Term </Typography>
              <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObjS?.loanTerm} years</Typography>
            </Stack>
            <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> IR Term </Typography>
              <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{finObjS?.label ==="Interest Only"? '3 years' : '-'}</Typography>
            </Stack>
         
          </Box>
          <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "25vw",height:"18vw",pl:'2vw',border:0 ,borderRadius:0}}>
            <Typography ml='2vw' mt='0.2vw' width='18vw' fontSize='1.15vw' align='left' fontWeight='bold' color='#000000'>{finObjP?.label.toUpperCase()}</Typography> 
            { finObjP?.label === "Employer Benefit" 
            ?
            <>
              <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {withCommas(Math.ceil(finObjP?.monthlyEMI))} </Typography>
              {/* <Typography ml='2vw' mt='0.7vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>+ {withCommas(Math.ceil(finObjP?.loanAmount/3000))}</Typography> */}
            </>
          : <> 
          <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>{withCommas(Math.ceil(finObjP?.monthlyEMI))} </Typography>  
          {/* <Typography ml='2vw' mt='0.8vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> - </Typography>   */}
          </>
          }
           
            <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {(finObjP?.interestRate).toFixed(2)}% </Typography>

            <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label === "Part Buy Part Rent" ? (finObjP?.interestRate + 0.31).toFixed(2) : finObjP?.apr.toFixed(2)}%</Typography>
            <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label === "Part Buy Part Rent" ? finObjP?.percentOwnership : 100}%</Typography>
            <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.loanTerm} years</Typography>
            <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label ==="Interest Only"? '3 years' : '-'}</Typography>

          </Box>
        </Box>
      </Box>
  </Box>
      </React.Fragment>
    );
}


export function RightBlockCompareSmartChoiceEmb(props) {
  const {rbops, info} = props;
  const { bChoiceD,
          bChoiceA,
          bChoiceDSF,
          isEmbFlow,
          bChoiceDSF_EMB,
          selectedProp,
          proposedProp
        } = useSelector((state)=>state.borrower);
  const [bChoiceDSF_E,setBChoiceDSF_E] = React.useState(info)
  const [finObjS,setFinObjS]  = React.useState(bChoiceDSF_E[selectedProp?.propId][selectedProp?.finOpt])
  const [finObjP,setFinObjP]  = React.useState(bChoiceDSF_E[proposedProp?.propId][proposedProp?.finOpt])
  const [colBS,setColBS]      = React.useState('#00657C')
  const [colTS,setColTS]      = React.useState('#FEFEFE')
  const [colBP,setColBP]      = React.useState('#00657C')
  const [colTP,setColTP]      = React.useState('#FEFEFE')
  
  
  React.useEffect(()=>{
    const finOpti=proposedProp?.finOpt

    switch(bChoiceDSF_E[proposedProp?.propId][finOpti]?.label){
      case 'Smart Traditional':
        setColBP('#00657C')
        setColTP('#80B2BE')
        break
      case "Employer Benefit":
        setColBP('#884D00')
        setColTP('#204975')
      case "Employer Support":
        setColBP('#204975') 
        setColTP('#204975')
        break
      default:
        break;
    }
    setFinObjP(bChoiceDSF_E[proposedProp?.propId][finOpti])
  },[proposedProp?.propId,proposedProp?.finOpt])
  
  React.useEffect(()=>{
    const finOpti=selectedProp?.finOpt

    switch(bChoiceDSF_E[selectedProp?.propId][finOpti]?.label){
      case 'Smart Traditional':
        setColBS('#00657C')
        setColTS('#80B2BE')
        break
      case "Employer Benefit":
        setColBS('#FFCD8A')
        setColTS('#A0CBFA')
      case "Employer Support":
        setColBS('#204975')
        setColTS('#A0CBFA')
        break
      default:
        break;
    }
    setFinObjS(bChoiceDSF_E[selectedProp?.propId][finOpti])
  },[selectedProp?.propId,selectedProp?.finOpt])
  const [showMap,setShowMap] = React.useState(-1)
  return (
      <React.Fragment><CssBaseline/>
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: '50vw',height:'46.75vw' }}>
      <Box m={'0.8vw'} sx={{background:'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "97%",height:'18vw' ,border:0, borderRadius:'0.5vw'}}>
            {showMap === 5  
                ?<GoogleMaps position={{lat: selectedProp?.prop?.geoLocation.lat, lng:selectedProp?.prop?.geoLocation.lon}}
                                markerInput={[selectedProp?.prop]} wHeight='13.5vw'/> 

                :
                  <InnerCarousel imageArray={selectedProp?.prop.images} url={selectedProp?.prop?.url} numCards={2}/>
            }
            <Box sx={{  display: 'flex', flexDirection: 'row', width:'inherit', height:"4.5vw"}}> 
              <Typography ml='1vw' mt='1vw' width='12vw' fontSize='1.67vw' fontWeight='bold' color='black' align='center'>{withCommas(bChoiceD[selectedProp.propId].offerPrice)}</Typography>
              <Box width='15vw'>
                <Typography fontSize='0.78vw' align='left' color="#31312C"> 
                  <Button sx={{padding:0, minHeight: 0, minWidth: 0, mt:'1vw'}} onClick={()=> {showMap === -1? setShowMap(5) : setShowMap(-1)}} >
                  <LocationOnIcon sx={{color:'#000000', fontSize:'2vw'}}/>
                  </Button>{selectedProp?.prop.address}
                  </Typography>
                <Typography paddingLeft="2.14vw" mr='1.2vw' mt='-0.97vw' fontSize='0.78vw' color='#31312C' align='left'> {selectedProp?.prop.city}, {selectedProp?.prop.state} - {selectedProp?.prop.zipcode}</Typography>
              </Box>
              <Box sx={{background:'inherit',width:'24vw'}} >
                <BedsAndBaths color={'black'}/>
              </Box>
            </Box>
        </Box>
         
      <Box sx={{ mt:'-0.8vw',display: 'flex', flexDirection: 'row', width:'50vw',height:'10.125vw'}}>
        <Box sx={{mt:'0.8vw',ml:'0.8vw',mr:'0.4vw',background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'10.125vw' ,border:0, borderRadius:'0.5vw'}}>
          <Box backgroundColor={colTS} height='10.125vw'borderRadius='0.5vw' border={0} borderColor={colBS}>
            <DataCarousel index={selectedProp?.propId} _bChoiceDSF_E={bChoiceDSF_E[selectedProp?.propId]} opt='selected' colB={colBS} colT={colTS} finObjS ={finObjS} />
          </Box>
        </Box>
        <Box sx={{mt:'0.8vw',ml:'0.4vw',mr:'0.8vw',background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'10.125vw' ,border:0, borderRadius:'0.5vw'}}>
          <Box backgroundColor={colTP} height='10.125vw'borderRadius='0.5vw'border={0} borderColor={colBP} >
            <DataCarousel index={proposedProp?.propId} _bChoiceDSF_E={bChoiceDSF_E[proposedProp?.propId]} opt='proposed' colB={colBP} colT={colTP} finObjP = {finObjP} />
          </Box>
        </Box>
      </Box>
      
      <Box sx={{ mt:'-2.3vw', background: "#FFFFFF 0% 0% no-repeat padding-box",width:'100%', height:"21vw",boxShadow: '0vw 0vw 19px #0000008F'}}> 
      <Typography sx={{mt:'4vw', width:'48vw'}} fontSize='2.0vw' align='center' fontWeight='700' color='#31312C'>Compare Financing Options</Typography> 
        <Box sx={{display: 'flex', flexDirection: 'row', width:'inherit'}}>
          <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "24vw",height:"18vw",pl:'4vw',border:0 ,borderRadius:0}}>
            <Stack mt='0.2vw' direction="row" justifyContent="space-between">
              <Typography  fontSize='1.15vw' align='left' fontWeight='500' color='#31312C'></Typography> 
              <Typography width='18vw' fontSize='1.15vw' align='right' fontWeight='bold' color='#000000'>{finObjS?.label =="Employer Support"? "EMPLOYER BENEFIT":finObjS?.label.toUpperCase()}</Typography> 
            </Stack>
            <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>Monthly </Typography>
              <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {withCommas(Math.ceil(finObjS?.monthlyEMI))}</Typography>
            </Stack>
            {/* <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              
              { finObjS?.label === "Employer Benefit"
              ?
              <>
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>(5+ yrs) </Typography>
                <Typography ml='11vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>+ {withCommas(Math.ceil(finObjS?.loanAmount/3000))}</Typography>
              </>
            : <>
            <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>(5+ yrs) </Typography>
            <Typography ml='10vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='right'> - </Typography>  
            </>
            }
              </Stack> */}
            <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>Rate </Typography>
              <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {(finObjS?.interestRate)?.toFixed(2)}% </Typography>
            </Stack>
            <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> APR  </Typography>
              <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObjS?.label === "Part Buy Part Rent" ? (finObjS?.interestRate + 0.31).toFixed(2) : finObjS?.apr.toFixed(2)}%</Typography>
            </Stack>
            <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> Ownership </Typography>
              <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObjS?.label === "Part Buy Part Rent" ? finObjS?.percentOwnership : 100}%</Typography>
            </Stack>
            <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> Term </Typography>
              <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObjS?.loanTerm} years</Typography>
            </Stack>
            <Stack mt='0.4vw' direction="row" justifyContent="space-around">
              <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> IR Term </Typography>
              <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'>{finObjS?.label ==="Interest Only"? '3 years' : '-'}</Typography>
            </Stack>
         
          </Box>
          <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "25vw",height:"18vw",pl:'2vw',border:0 ,borderRadius:0}}>
            <Typography ml='2vw' mt='0.2vw' width='18vw' fontSize='1.15vw' align='left' fontWeight='bold' color='#000000'>{finObjP?.label =="Employer Support"? "EMPLOYER BENEFIT":finObjP?.label.toUpperCase()}</Typography> 
            { finObjP?.label === "Employer Benefit" 
            ?
            <>
              <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {withCommas(Math.ceil(finObjP?.monthlyEMI))} </Typography>
              {/* <Typography ml='2vw' mt='0.7vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>+ {withCommas(Math.ceil(finObjP?.loanAmount/3000))}</Typography> */}
            </>
          : <> 
          <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>{withCommas(Math.ceil(finObjP?.monthlyEMI))} </Typography>  
          {/* <Typography ml='2vw' mt='0.8vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> - </Typography>   */}
          </>
          }
           
            <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {(finObjP?.interestRate)?.toFixed(2)}% </Typography>

            <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label === "Part Buy Part Rent" ? (finObjP?.interestRate + 0.31).toFixed(2) : finObjP?.apr.toFixed(2)}%</Typography>
            <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label === "Part Buy Part Rent" ? finObjP?.percentOwnership : 100}%</Typography>
            <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.loanTerm} years</Typography>
            <Typography ml='2vw' mt='0.4vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> {finObjP?.label ==="Interest Only"? '3 years' : '-'}</Typography>

          </Box>
        </Box>
      </Box>
  </Box>
      </React.Fragment>
    );
}

export function RightBlockCompareSelfEmp() {
  const { bChoiceD,
          bChoiceH,
          bChoiceDSF,
          selectedProp,
          proposedProp
        } = useSelector((state)=>state.borrower);
  const [colBS,setColBS] = React.useState('#00657C')
  const [colTS,setColTS] = React.useState('#FEFEFE')
  const [colBP,setColBP] = React.useState('#00657C')
  const [colTP,setColTP] = React.useState('#FEFEFE')
  
  React.useEffect(()=>{
    var pindex = proposedProp?.propId
    var findex = proposedProp?.finOpt
    switch(bChoiceDSF[pindex][findex]?.label){
      case 'Smart Traditional':
        setColBP('#00657C')
        setColTP('#00323E')
        break
      case 'Interest Only':
        setColBP('#F174D8')
        setColTP('#16214A')
        break
      case 'Pay Points':
        setColBP('#16214A')
        setColTP('#FEFEFE')
        break
      case 'Reduced Initial Monthly':
        setColBP('#6A446E')
        setColTP('#FEFEFE')
        break
      case 'Part Buy Part Rent':
        setColBP('#027AF5')
        setColTP('#FEFEFE')
        break
      case 'Longer Term':
        setColBP('#F1BF74')
        setColTP('#16214A')
        break
      default:
        break;
    }
    setIndexP(proposedProp?.propId)
  },[proposedProp?.propId,proposedProp?.finOpt])
  
  React.useEffect(()=>{
    var pindex = selectedProp?.propId
    var findex = selectedProp?.finOpt
    switch(bChoiceDSF[pindex][findex]?.label){
      case 'Smart Traditional':
        setColBS('#00657C')
        setColTS('#80B2BE')
        break
      case 'Interest Only':
        setColBS('#F174D8')
        setColTS('#16214A')
        break
      case 'Pay Points':
        setColBS('#16214A')
        setColTS('#FEFEFE')
        break
      case 'Reduced Initial Monthly':
        setColBS('#6A446E')
        setColTS('#FEFEFE')
        break
      case 'Part Buy Part Rent':
        setColBS('#027AF5')
        setColTS('#FEFEFE')
        break
      case 'Longer Term':
        setColBS('#F1BF74')
        setColTS('#16214A')
        break
      default:
        break;
    }
    setIndexS(selectedProp?.propId)
  },[selectedProp?.propId,selectedProp?.finOpt])

  return (
      <React.Fragment>
      <CssBaseline/>
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',opacity: 1, width: '50vw',height:'46.75vw' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width:'inherit'}}>
            <Box sx={{mt:'0.8vw',ml:'0.8vw',mr:'0.4vw',background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'18vw' ,border:0, borderRadius:'0.5vw'}}>
              <OuterCarousel itemArray={selectedProp.prop} numCards={1} opt='selected' colB={colBS} colT={colTS}/>
            </Box> 
            <Box sx={{ mt:'0.8vw',ml:'0.4vw',mr:'0.8vw',background:'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'18vw' ,border:0, borderRadius:'0.5vw'}}>
              <OuterCarousel itemArray={bChoiceD} numCards={1} opt='proposed' colB={colBP} colT={colTP}/>
            </Box>  
          </Box> 
          <Box sx={{ display: 'flex', flexDirection: 'row', width:'50vw',height:'11.25vw'}}>
          <Box sx={{mt:'0.8vw',ml:'0.8vw',mr:'0.4vw',background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'11.25vw' ,border:0, borderRadius:'0.5vw'}}>
        <Box backgroundColor={colBS} height='10.125vw'borderRadius='0.5vw'>
          <DataCarousel index={selectedProp?.propId} _bChoiceDSF_E={bChoiceDSF[selectedProp?.propId]} opt='selected' colB={colBS} colT={colTS} />
        </Box></Box>
        <Box sx={{mt:'0.8vw',ml:'0.4vw',mr:'0.8vw',background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'11.25vw' ,border:0, borderRadius:'0.5vw'}}>
        <Box backgroundColor={colBP} height='10.125vw'borderRadius='0.5vw'>
          <DataCarousel index={proposedProp?.propId} _bChoiceDSF_E={bChoiceDSF[proposedProp?.propId]} opt='proposed' colB={colBP} colT={colTP} />
        </Box></Box>
        </Box>
      <Box sx={{ background: "#FFFFFF 0% 0% no-repeat padding-box",width:'100%', height:"21vw",boxShadow: '0vw 0vw 19px #0000008F'}}> 
    <Box sx={{display: 'flex', flexDirection: 'row', width:'inherit'}}>
      <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "24vw",height:"21vw",p:4,border:0 ,borderRadius:0}}>
      <Stack mt='0.2vw' direction="row" justifyContent="space-between">
      <Typography  fontSize='1.2vw' align='left' fontWeight='bold' color='#000000'>Summary</Typography> 
      <Typography  fontSize='1.2vw' align='left' fontWeight='bold' color='#000000'>Traditional</Typography> 
      </Stack>
      <Stack mt='0.2vw' direction="row" justifyContent="space-around">
            <Typography fontSize='1.2vw' fontWeight={500} width='12vw' color='black' align='right'>Rate </Typography>
            <Typography fontSize='1.2vw' fontWeight={500} width='12vw' color='black' align='right'> {bChoiceH?.interestRate}% </Typography>
            </Stack>
      <Stack mt='0.2vw' direction="row" justifyContent="space-around">
            <Typography fontSize='1.2vw' fontWeight={500} width='12vw' color='black' align='right'> APR  </Typography>
            <Typography fontSize='1.2vw' fontWeight={500} width='12vw' color='black' align='right'> {(bChoiceH?.interestRate+0.35).toFixed(3)}%</Typography>
            </Stack>
      <Stack mt='0.2vw' direction="row" justifyContent="space-around">
            <Typography fontSize='1.2vw' fontWeight={500} width='12vw' color='black' align='right'> Ownership </Typography>
            <Typography fontSize='1.2vw' fontWeight={500} width='12vw' color='black' align='right'> 100%</Typography>
      </Stack>
      
      </Box>
      <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "25vw",height:"21vw",p:4,border:0 ,borderRadius:0}}>
            <Typography mt='0.2vw' fontSize='1.2vw' align='left' fontWeight='bold' color='#000000'>Smart Finance</Typography> 
            <Typography mt='0.2vw' fontSize='1.2vw' fontWeight={500} width='12vw' color='black' align='left'> {bChoiceH?.interestRate}% </Typography>
            <Typography mt='0.2vw' fontSize='1.2vw' fontWeight={500} width='12vw' color='black' align='left'> {(bChoiceH?.interestRate+0.35).toFixed(3)}%</Typography>
            <Typography mt='0.2vw' fontSize='1.2vw' fontWeight={500} width='12vw' color='black' align='left'> 100%</Typography>
      
      </Box>
    </Box>
    </Box>
  </Box>
      </React.Fragment>
    );
}

export function RightBlockCompareEmp() {
  const { bChoiceD,
          bChoiceH,
          bChoiceDSF,
          isEmbFlow,
          bChoiceDSFE,
          bChoiceDSFEM,
          selectedProp,
          proposedProp
        } = useSelector((state)=>state.borrower);
  const [showMap,setShowMap]  = React.useState(-1)
  const [finOptS,setfinOptS]  = React.useState(!isEmbFLow?selectedProp.finOpt:selectedProp.finOpt>0?2:selectedProp.finOpt)
  const [finOptP,setfinOptP]  = React.useState(!isEmbFLow?proposedProp.finOpt:proposedProp.finOpt>0?2:proposedProp.finOpt)
  const [finObj,setFinObj]    = React.useState(bChoiceDSF[selectedProp?.propId][finOptS])
  React.useEffect(()=>{
    setIndexS(selectedProp.propId)
    setIndexP(proposedProp.propId)
    setfinOptS(selectedProp.finOpt)
    setfinOptP(proposedProp.finOpt)
    setFinObj(bChoiceDSF[selectedProp?.propId][finOptS])

  },[selectedProp.propId,proposedProp.propId,selectedProp.finOpt,proposedProp.finOpt])


  const dispatch = useDispatch();
  
  const nprops = bChoiceD?.length;
  console.log(bChoiceH)
  return (
      <React.Fragment>
        <CssBaseline/>
        <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',opacity: 1, width: '50vw',height:'46.75vw' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width:'inherit'}}>
            <Box sx={{mt:'0.85vw',ml:'0.8vw',mr:'0.4vw',background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'18vw' ,border:0, borderRadius:'0.5vw'}}>
              <OuterCarousel itemArray={bChoiceD} numCards={1} opt='selected' colB='#00657C' colT='#FFFFFF' callFrom="RightBlockCompareEmp" />
            </Box> 
            <Box sx={{ mt:'0.85vw',ml:'0.4vw',mr:'0.8vw',background:'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "23.8vw",height:'18vw' ,border:0, borderRadius:'0.5vw'}}>
              <OuterCarousel itemArray={bChoiceD} numCards={1} opt='proposed' colB='#F8D02B' colT='#000000' callFrom="RightBlockCompareEmp" />
            </Box>  
          </Box> 
          <Box sx={{ display: 'flex', flexDirection: 'row', width:'50vw',height:'10vw'}}>
            <Box sx={{mt:'0.85vw',ml:'0.8vw',mr:'0.4vw',width: "23.8vw",height:'10vw' ,border:0, borderRadius:'0.5vw'}}>
              <Box backgroundColor='#00657C' height='10.125vw'borderRadius='0.5vw'>
                <DataCarousel index={selectedProp?.propId} _bChoiceDSF_E={bChoiceDSF[selectedProp?.propId]} opt='selected' colB='#00657C' colT='#FFFFFF' />
              </Box>
            </Box>
            <Box sx={{mt:'0.85vw',ml:'0.4vw',mr:'0.8vw',width: "23.8vw",height:'10vw' ,border:0, borderRadius:'0.5vw'}}>
              <Box backgroundColor='#F8D02B' height='10vw'borderRadius='0.5vw'>
                <DatawCarousel index={proposedProp?.propId} _bChoiceDSF_E={bChoiceDSFEM.data.mlsNewArray} opt='proposed' colB='#F8D02B' colT='#000000' />
              </Box>
            </Box>
          </Box>
          <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box",width:'100%', height:"21vw",boxShadow: '0vw 0vw 19px #0000008F'}}> 
          <Typography sx={{mt:'2vw'}} fontSize='1.5vw' align='center' fontWeight='500' color='#31312C'>Finance Summary</Typography> 
            <Box sx={{display: 'flex', flexDirection: 'row', width:'inherit'}}>
              <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "24vw",height:"21vw",p:2,border:0 ,borderRadius:0}}>
                <Stack mt='0.2vw' direction="row" justifyContent="space-between">
                  <Typography  fontSize='1.15vw' align='left' fontWeight='500' color='#31312C'>Parameter</Typography> 
                  <Typography width='14vw' fontSize='1.15vw' align='right' fontWeight='bold' color='#000000'>{finObj?.label}</Typography> 
                </Stack>
                <Stack mt={1} direction="row" justifyContent="space-around">
                  <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'>Rate </Typography>
                  <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {(finObj?.interestRate).toFixed(2)}% </Typography>
                </Stack>
                <Stack mt='0.2vw' direction="row" justifyContent="space-around">
                  <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> APR  </Typography>
                  <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObj?.label === "Part Buy Part Rent" ? (finObj?.interestRate + 0.31).toFixed(2) : finObj?.apr.toFixed(2)}%</Typography>
                </Stack>
                <Stack mt='0.2vw' direction="row" justifyContent="space-around">
                  <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> Ownership </Typography>
                  <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> { finObj?.label === "Part Buy Part Rent" ? finObj?.percentOwnership : 100} %</Typography>
                </Stack>
                <Stack mt='0.2vw' direction="row" justifyContent="space-around">
                  <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> Term </Typography>
                  <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObj?.loanTerm} years</Typography>
                </Stack>
                <Stack mt='0.2vw' direction="row" justifyContent="space-around">
                  <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> IR Term </Typography>
                  <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> 3 years</Typography>
                </Stack>
                <Stack mt='0.2vw' direction="row" justifyContent="space-around">
                  <Typography fontSize='1.2vw' fontWeight={500} width='12vw' align='left' color='#31312C'> LTV </Typography>
                  <Typography fontSize='1.2vw' fontWeight={700} width='12vw' align='right' color='#000000'> {finObj?.percentLTV}%</Typography>
                </Stack>
              </Box>
              <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "24vw",height:"21vw",p:2,border:0 ,borderRadius:0}}>
                <Typography ml='1.2vw'mt='0.2vw' width='10vw' fontSize='1.15vw' align='left' fontWeight='bold' color='#000000'>{bChoiceDSFEM.data.mlsNewArray[proposedProp?.propId]?.label}</Typography> 
                <Typography ml='1.2vw'mt='0.5vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>     {bChoiceDSFEM.data.mlsNewArray[proposedProp?.propId]?.interestRate}% </Typography>
                <Typography ml='1.2vw'mt='0.2vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>     {(bChoiceDSFEM.data.mlsNewArray[proposedProp?.propId]?.interestRate+0.31).toFixed(2)}%</Typography>
                <Typography ml='1.2vw'mt='0.2vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>     {bChoiceDSFEM.data.mlsNewArray[proposedProp?.propId]?.percentOwnership} %</Typography>
                <Typography ml='1.2vw'mt='0.2vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>     {bChoiceDSFEM.data.mlsNewArray[proposedProp?.propId]?.loanTerm} years</Typography>
                <Typography ml='1.2vw'mt='0.2vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'> 3 years </Typography>
                <Typography ml='1.2vw'mt='0.2vw' fontSize='1.2vw' fontWeight={700} width='12vw' color='black' align='left'>     {100*bChoiceDSFEM.data.mlsNewArray[proposedProp?.propId]?.percentLTV} % </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </React.Fragment>
    );
}

function OuterWCarousel(props) {
  const {itemArray, numCards,colB,colT,opt} = props;
  const item = itemArray
  const [showMap,setShowMap] = React.useState(-1)
  
  return (
      <Box sx={{background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '0vw 0vw 19px #0000008F',opacity: 1, width: "23.8vw",height:'18vw' ,border:0, borderRadius:'100vw'}}>
        <Box  backgroundColor={colB} sx={{width: "23.8vw",height:'18vw' ,border:0, borderRadius:'0.5vw'}}>
          {showMap === 100  ?<><GoogleMaps position={{lat: item?.geoLocation.lat, lng:item?.geoLocation.lon}}
                                  markerInput={[item]} wHeight='13.5vw'/> </>
                          :<><Box sx={{ border:0, borderColor:"#FFFFFF", borderRadius: '0.5vw 0.5vw 0vw 0vw', height:'13.5vw'}} >
                          <img src={(typeof(item.thumbnail) ==='undefined' ? nestfuelImage : item.thumbnail)} 
                            alt={nestfuelImage} style={{borderRadius: '0.5vw 0.5vw 0vw 0vw'}} width="100%" height="100%"
                            onError={(e) => (e.target.onerror = null, e.target.src = nestfuelImage)}
                            />
                          </Box>
        
         <Box sx={{position:'absolute',mt:'-7vh', background: "#31312C 0% 0% no-repeat padding-box", opacity:0.5, width: "100%",height:'4vw',border:'0.1vw' ,borderRadius:0, borderColor:"#FFFFFF" }}>
                  <Stack sx={{ml:'4vw',width:'18vw'}} spacing={2} direction="row" justifyContent="space-around"> 
                    <Stack  width='28%'direction={'column'}>
                        <Typography fontSize='0.9vw' color='#FFFFFF'>Area</Typography>  
                        <Typography fontSize='0.9vw' color='#FFFFFF'>{item?.squareFeet} sqft</Typography>  
                    </Stack>
                    <Stack width='24%'direction={'column'}>
                        <Typography fontSize='0.9vw' color='#FFFFFF'>Beds</Typography>  
                        <Typography fontSize='0.9vw' color='#FFFFFF'>{item?.beds}  <img color="#FFFFFF" src={bedicon} height='11.25vw'/></Typography>  
                    </Stack>
                    <Stack width='24%'direction={'column'}>
                        <Typography fontSize='0.9vw' color='#FFFFFF'>Baths</Typography>  
                        <Typography fontSize='0.9vw' color='#FFFFFF'>{item?.baths} <img sx={{mr:10}}color="#FFFFFF" src={bathicon} height='11.25vw'/></Typography>  
                    </Stack>
                    <Stack width='24%'direction={'column'}>
                        <Typography fontSize='0.9vw' color='#FFFFFF'>Garages</Typography>  
                        <Typography fontSize='0.9vw' color='#FFFFFF'>1 <img color="#FFFFFF" src={garageicon} height='11.25vw'/></Typography>  
                    </Stack>
                  </Stack>
          </Box>
        </>}
        <Stack sx={{ml:1, mr:0,mb:1}} spacing={0} direction="row" justifyContent="space-between"> 
            <Typography mt='0.5vw' fontSize='1.1vw' fontWeight='bold' color={colT} align='left'>{isNaN(item?.offerPrice) ? '$0' : withCommas(item?.offerPrice)}</Typography>
            <Box>
              <Typography mr='1.2vw' ml='auto' fontSize='0.8vw' color={colT} align='right'> <Button sx={{minHeight: 0, minWidth: 0}} onClick={()=> {showMap === -1? setShowMap(100) : setShowMap(-1)}} ><LocationOnIcon sx={{backgroundColor:{colT}}}/></Button>{item.address.substr(0,20)}</Typography>
              <Typography mr='1.2vw' ml='auto' mt='-1.0vh' fontSize='0.8vw' color={colT} align='right'> {item.city}, {item.state} - {item.zipcode}</Typography>
            </Box>
        </Stack>  
        </Box>
        </Box>
  );
}


function OuterCarousel(props) {
  const {itemArray, numCards,colB,colT,opt,callFrom} = props;
  const { bChoiceD,
          selectedProp,
          proposedProp
        }= useSelector((state)=>state.borrower);
  const colBx     =  colB
  const colTx     =  opt === 'selected'? "#000000":"#FFFFFF"
  const dispatch = useDispatch();
  const [active, setActive] = React.useState(opt==='selected'?selectedProp.propId:proposedProp.propId);
  const [showMap,setShowMap] = React.useState(-1)
  React.useEffect(()=>{
    opt==='selected' 
    ?dispatch({type: SELPROP, payload:{...selectedProp,prop:bChoiceD[active],propId:active}})
    :dispatch({type: PROPROP, payload:{...proposedProp,prop:bChoiceD[active],propId:active}})
  },[active]);
  
  const locationIconColor = {
      color: colTx,
      fill: colTx
  }
  const widthBednBathBlock = (callFrom === 'smartChoice' || callFrom === 'RightBlockCompareEmp') ? { width:'100%' } : { width:'100%' };
  return (
    <div className="OuterCarousel">
      <ItemsCarousel
        autoPlay={true}
        infiniteLoop={true}
        gutter={0}
        timeout={1}
        activePosition={"center"}
        chevronWidth={-2}
        disableSwipe={false}
        alwaysShowChevrons={false}
        numberOfCards={numCards}
        slidesToScroll={1}
        outsideChevron={false}
        showSlither={true}
        firstAndLastGutter={false}
        activeItemIndex={active}
        requestToChangeActive={(value) => opt === 'selected' &&  value === selectedProp.propId
                                  ? value = (value +1)%(itemArray.length -1)
                                  : opt === 'proposed' &&  value === proposedProp.propId
                                  ? value = (value +1)%(itemArray.length -1)
                                  : value >=0 && value < itemArray.length 
                                  ? setActive(value)
                                  :value <0 ?  setActive(itemArray.length -1)
                                  : setActive(0)}
        rightChevron={ <img src={rightArrow} alt='right-arrow' style={{ marginTop:'-7vh',width:'1.77vw', height:'3.28vw' }} /> }
        leftChevron={ <img src={leftArrow} alt='left-arrow' style={{ marginTop:'-7vh',width:'1.77vw', height:'3.28vw' }} /> }
        // rightChevron={<ArrowForwardIosIcon sx={{mt:'-16vh',color:"#F1F2F4", fontSize:40,
        //   borderRadius: '15px 0px 0px 15px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
        // leftChevron={<ArrowBackIosIcon    sx={{ mt:'-16vh',color:"#F1F2F4", fontSize:40,
        //   borderRadius: '0px 15px 15px 0px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
      >
      {itemArray.map((item,index) => 
      <>
        <Box  border={0} backgroundColor={colT} borderColor={colB} sx={{width: "23.8vw",height:'15.5vw' , borderRadius:'0.5vw'}}>
          {showMap === index  ?<><GoogleMaps position={{lat: item?.geoLocation.lat, lng:item?.geoLocation.lon}}
                                  markerInput={[item]} Height='13vw'/> </>
                          :<>
                <Link to={item.url} target="_blank">
                  <Box sx={{ border: 0, borderColor: "#FFFFFF", borderRadius: '0.5vw 0.5vw 0vw 0vw', height: '12vw' }} >
                    <img src={(typeof (item.thumbnail) === 'undefined' || item.thumbnail.length === 0 || item.thumbnail === 'dummy' ? nestfuelHomeImageOne : item.thumbnail)}
                      alt={nestfuelHomeImageOne} style={{ opacity: '0.9', borderRadius: '0.5vw 0.5vw 0vw 0vw' }} width="100%" height="100%"
                      onError={(e) => (e.target.onerror = null, e.target.src = nestfuelHomeImageOne)}
                    />
                  </Box>
                </Link>
                <Box style={widthBednBathBlock} sx={{position:'absolute',mt:'-4vw', background: "rgba(0,0,0,0.7)", height:'4vw',border:0 ,borderRadius:0 }}>
                  <Stack sx={{m:'0 auto',width:'18vw',mt:1}} spacing={2} direction="row" justifyContent="space-around"> 
                    <Stack textAlign="center" ml='0vw' width='28%' direction={'column'}>
                        <Typography fontSize='0.8vw' color='#FFFFFF' fontWeight={300}>Area</Typography>  
                        <Typography fontSize='0.8vw' color='#FFFFFF' fontWeight={300}>{withCommasWithoutCurrency(item?.squareFeet)} sqft</Typography>  
                    </Stack>
                    
                    <Stack textAlign="center" width='24%' direction={'column'}>
                      <Typography fontSize='0.8vw' color='#FFFFFF' fontWeight={300}>Beds</Typography>  
                      <Typography fontSize='0.8vw' color='#FFFFFF' fontWeight={300}>{item?.beds}  <img color="#FFFFFF" src={bedicon} style={{ marginLeft:'0.26vw', height:'1.14vw' }}  /></Typography>  
                    </Stack>
                    <Stack textAlign="center" width='24%' direction={'column'}>
                        <Typography fontSize='0.8vw' color='#FFFFFF' fontWeight={300}>Baths</Typography>  
                        <Typography fontSize='0.8vw' color='#FFFFFF' fontWeight={300}>{item?.baths} <img sx={{mr:10}}color="#FFFFFF" src={bathicon} style={{ marginLeft:'0.26vw', height:'1.14vw' }}  /></Typography>  
                    </Stack>
                    <Stack textAlign="center" mr='0vw' width='24%' direction={'column'}>
                        <Typography fontSize='0.8vw' color='#FFFFFF' fontWeight={300}>Garages</Typography>  
                        <Typography fontSize='0.8vw' color='#FFFFFF' fontWeight={300}>1 <img color="#FFFFFF" src={garageicon}  style={{ marginLeft:'0.26vw', height:'1.14vw' }} /></Typography>  
                    </Stack>
                  </Stack>
               </Box>

          
        </>}
                
                <Stack sx={{ml:1,mb:1}} spacing={0} direction="row" justifyContent="space-between"> 
                  <Typography ml='2vw' mt='0.7vw' fontSize='1.32vw' fontWeight='bold' color={colTx} align='left'>{isNaN(item?.offerPrice) ? '$0' : withCommas(item?.offerPrice)}</Typography>
                  <Box width='12vw' sx={{mt:'0.7vw'}}>
                    <Typography mr='1vw' fontSize='0.8vw' align='left' color={colTx}> 
                    <Button sx={{ padding:0, minHeight: 0, minWidth: 0, pt:'0vw'}} onClick={()=> {showMap === -1? setShowMap(index) : setShowMap(-1)}} >
                      <LocationOnIcon style={locationIconColor} sx={{fontSize:'2vw' }}/>
                    </Button>{item.address.substr(0,20)}
                    </Typography>
                    <Typography sx={{ textOverflow:'ellipsis', overflow:'hidden',width:'9vw',whiteSpace:'nowrap' }} ml="2.14vw" mr='1.2vw' mt='-0.7vw' fontSize='0.8vw' color={colTx} align='left'> {item.city.substr(0,10)}, {item.state} - {item.zipcode}</Typography>
                  </Box>
                </Stack> 
        </Box>
      </>
      )}
      </ItemsCarousel>
    </div>
  );
}

function OneElementCarousel(props) {
  const {item,colB,colT} = props;
  const [showMap,setShowMap] = React.useState(-1)
  return(
      <Box sx={{background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '0vw 0vw 19px #0000008F',opacity: 1, width: "23.8vw",height:'18vw' ,border:0, borderRadius:'100vw'}}>
        <Box  backgroundColor={colB} sx={{width: "23.8vw",height:'18vw' ,border:0, borderRadius:'0.5vw'}}>
          {showMap === 20  
              ?<><GoogleMaps position={{lat: item?.geoLocation.lat, lng:item?.geoLocation.lon}} markerInput={[item]} wHeight='13.5vw'/> </>
              :<> <Link to =''  
              // onClick={() => openInNewTab(`${item?.url}`)} 
              target="_blank">
              <InnerCarousel imageArray={item?.images} url={item?.url} numCards={1} chevron={0}/></Link>
                <Box sx={{position:'absolute',mt:'-20vh', background: "#31312C 0% 0% no-repeat padding-box", opacity:0.5, width: "10%",height:"1vh",border:1 ,borderRadius:0, borderColor:"#FFFFFF" }}>
                <Typography fontSize='0.9vw' color='#FFFFFF'>Selected</Typography>
                </Box>
                 <Box sx={{position:'absolute',mt:'-7vh', background: "#31312C 0% 0% no-repeat padding-box", opacity:0.5, width: "100%",height:'4vw',border:1 ,borderRadius:0, borderColor:"#FFFFFF" }}>
                      <Stack sx={{ml:'4vw',width:'18vw'}} spacing={2} direction="row" justifyContent="space-around"> 
                        <Stack  width='28%'direction={'column'}>
                            <Typography fontSize='0.9vw' color='#FFFFFF'>Area</Typography>  
                            <Typography fontSize='0.9vw' color='#FFFFFF'>{item?.squareFeet} sqft</Typography>  
                        </Stack>
                        <Stack width='24%'direction={'column'}>
                            <Typography fontSize='0.9vw' color='#FFFFFF'>Beds</Typography>  
                            <Typography fontSize='0.9vw' color='#FFFFFF'>{item?.beds}  <img color="#FFFFFF" src={bedicon} height='11.25vw'/></Typography>  
                        </Stack>
                        <Stack width='24%'direction={'column'}>
                            <Typography fontSize='0.9vw' color='#FFFFFF'>Baths</Typography>  
                            <Typography fontSize='0.9vw' color='#FFFFFF'>{item?.baths} <img sx={{mr:10}}color="#FFFFFF" src={bathicon} height='11.25vw'/></Typography>  
                        </Stack>
                        <Stack width='24%'direction={'column'}>
                            <Typography fontSize='0.9vw' color='#FFFFFF'>Garages</Typography>  
                            <Typography fontSize='0.9vw' color='#FFFFFF'>1 <img color="#FFFFFF" src={garageicon} height='11.25vw'/></Typography>  
                        </Stack>
                      </Stack>
                  </Box>
              </>
          }
          <Stack sx={{ml:1, mr:0,mb:1}} spacing={0} direction="row" justifyContent="space-between"> 
              <Typography mt='0.5vw' fontSize='1.1vw' fontWeight='bold' color={colT} align='left'>{isNaN(item?.offerPrice) ? '$0' : withCommas(item?.offerPrice)}</Typography>
              <Box>
                <Typography mr='1.2vw' ml='auto' fontSize='0.8vw' color={colT} align='right'> <Button sx={{minHeight: 0, minWidth: 0}} onClick={()=> {showMap === -1? setShowMap(20) : setShowMap(-1)}} ><LocationOnIcon sx={{backgroundColor:{colT}}}/></Button>{item.address.substr(0,20)}</Typography>
                <Typography mr='1.2vw' ml='auto' mt='-1.0vh' fontSize='0.8vw' color={colT} align='right'> {item.city}, {item.state} - {item.zipcode}</Typography>
              </Box>
          </Stack>  
        </Box>
      </Box>
  );
}

function InnerCarousel(props) {
  const {imageArray,url,numCards,chevron} = props;
  if (imageArray.length >=1 && imageArray[0] === 'dummy') imageArray.pop()
  if (imageArray.length >=1 && imageArray[0].toLowerCase().match(/\.(png)/g)) imageArray.pop()
  if(imageArray.length < numCards){
    imageArray.push(nestfuelHomeImageOne)
    imageArray.push(nestfuelHomeImageTwo)
    imageArray.push(nestfuelHomeImageThree)
}
  const [active, setActive] = React.useState(0);
  return (
    <div className="InnerCarousel">
      <ItemsCarousel
         autoPlay={true}
         infiniteLoop={true}
         gutter={0}
         timeout={1}
         activePosition={"center"}
         chevronWidth={-2}
         disableSwipe={false}
         alwaysShowChevrons={false}
         numberOfCards={numCards}
         slidesToScroll={1}
         outsideChevron={false}
         showSlither={true}
         firstAndLastGutter={true}
         activeItemIndex={active}
        requestToChangeActive={(value) => setActive(value)}
         //rightChevron={<ArrowForwardIosIcon sx={{mr:'-0.8vw',color:"#FFFFFF" , fontSize:40}}/>}
         //leftChevron={<ArrowBackIosIcon    sx={{color:"#FFFFFF" , fontSize:40}}/>}
         rightChevron={ <img src={rightArrow} alt='right-arrow' style={{ marginTop:'-0.8vw',width:'1.77vw', height:'3.28vw' }} /> }
         leftChevron={ <img src={leftArrow} alt='left-arrow' style={{ width:'1.77vw', height:'3.28vw' }} /> }
        //  rightChevron={<ArrowForwardIosIcon sx={{mr:'-0.8vw',color:"#F1F2F4", fontSize:40,
        //   borderRadius: '15px 0px 0px 15px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
        // leftChevron={<ArrowBackIosIcon    sx={{ color:"#F1F2F4", fontSize:40,
        //   borderRadius: '0px 15px 15px 0px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
      >
      {imageArray.map((item) => 
      numCards <= 2 
      ? <div>
        <Link to ={url} target="_blank">
       <Box sx={{ border:1, borderColor:"#FFFFFF", borderRadius: '0.5vw 0.5vw 0vw 0vw', height:'13.5vw'}} >
       <img src={(typeof(item) ==='undefined' ? nestfuelImage : item)} alt={nestfuelImage} style={{borderRadius: '0.5vw 0.5vw 0vw 0vw'}} width="100%" height="100%"
       onError={(e) => (e.target.onerror = null, e.target.src = nestfuelHomeImageOne)}
       />
        {/* <Typography sx={{position:'absolute',botto:0,background:'black',opacity:'0.5',color:'white'}}>Hello Brother</Typography> */}
        </Box>
        </Link>
        </div>
      : <div>
      <Box sx={{ border:1, borderColor:"#FFFFFF", borderRadius: '0.5vw 0.5vw 0.5vw 0.5vw', height:"16.3vw"}} >
      <img src={(typeof(item) ==='undefined' ? nestfuelImage : item)} alt={nestfuelImage} style={{borderRadius: '0.5vw 0.5vw 0.5vw 0.5vw'}} width="100%" height="100%"/>
       </Box>
       </div>
      
      )}
      </ItemsCarousel>
    </div>
  );
}

function DataCarousel(props) {
    const {index,_bChoiceDSF_E,opt,colT,colB,finObjS,finObjP,idnt} = props;
    console.log(props,'VAlue of finOjP')
    const { finOptS,
            bfinancialProfile,
            bChoiceA,
            bChoiceE,
            selectedProp,
            bChoiceDSF,
            bChoiceDSFB,
            bProfile,
            proposedProp,
            isEmbFlow,
            review
          } = useSelector((state)=>state.borrower);
          console.log("index",index)
    if(_bChoiceDSF_E === 'undefined') _bChoiceDSF_E = bChoiceDSF[index]
    console.log("here is the selelcted",selectedProp.finOpt)

    console.log('here is the prop',proposedProp.finOpt)
    const colTx = opt==='selected' ? '#000000':'#FFFFFF'
    const dispatch = useDispatch();
    const [active, setActive] = React.useState(opt==='selected'?selectedProp.finOpt:isEmbFlow? 0:0);
    const [activePropsd, setActivePropsd] = React.useState(opt==='selected'?selectedProp.finOpt:isEmbFlow? 0:2);

    React.useEffect(()=>{
      opt==='selected' 
      ?dispatch({type: SELPROP, payload:{...selectedProp,finOpt:active}})
      :dispatch({type: PROPROP, payload:{...proposedProp,finOpt:active}})
    },[active,activePropsd]);
    return (
      <div className="Data" style={{height:'10.125vw'}}>
        <ItemsCarousel
          autoPlay={true}
          infiniteLoop={true}
          gutter={1}
          timeout={1}
          activePosition={"center"}
          chevronWidth={-4}
          disableSwipe={false}
          alwaysShowChevrons={false}
          numberOfCards={1}
          slidesToScroll={1}
          outsideChevron={false}
          showSlither={true}
          firstAndLastGutter={false}
          activeItemIndex={active}
          // requestToChangeActive={(value) => setActive(value)}
          requestToChangeActive={(value) => {
                                    if(bProfile.employmentType != "Employed" && value === 2) setActive(3)
                                    if( value >=0 && value < _bChoiceDSF_E.length ) setActive(value%_bChoiceDSF_E.length)
                                      if(value <0 ) setActive(_bChoiceDSF_E.length -1)
                                      if(value >= _bChoiceDSF_E.length) setActive(0)
                                    } 
                               }        
          //rightChevron={<ArrowForwardIosIcon sx={{mr:'0vw',mt:'0',color:"#14214B" , fontSize:40,right:'16px'}}/>}
          //leftChevron={<ArrowBackIosIcon    sx={{mt:'0',color:"#14214B" , fontSize:40,ml: '0.5vw'}}/>}
          
          // rightChevron={ <img src={rightArrow} alt='right-arrow' style={{ marginTop:'-4.5vw',width:'1.77vw', height:'3.28vw' }} /> }
          // leftChevron={ <img src={leftArrow} alt='left-arrow' style={{ marginTop:'-4.5vw',width:'1.77vw', height:'3.28vw' }} /> }

          rightChevron={ <img src={rightArrow} alt='right-arrow' style={{ marginTop:'1.7vw',width:'1.77vw', height:'3.28vw' }} /> }
          leftChevron={ <img src={leftArrow} alt='left-arrow' style={{ marginTop:'1.7vw',width:'1.77vw', height:'3.28vw' }} /> }
          
          // rightChevron={<ArrowForwardIosIcon sx={{mr:'0vw',mt:'0',color:"#F1F2F4", fontSize:40, right:'16px',
          //   borderRadius: '15px 0px 0px 15px',
          //   width: '34px',
          //   height: '63px',
          //   background: "rgba(0,0,0,0.40)",
          //   color:'#FFFFFF',
          //   paddingLeft: '0.3vw'
          // }}/>}
          // leftChevron={<ArrowBackIosIcon    sx={{ mr:'0vw',mt:'0',color:"#F1F2F4", fontSize:40, right:'16px',
          //   borderRadius: '0px 15px 15px 0px',
          //   width: '34px',
          //   height: '63px',
          //   background: "rgba(0,0,0,0.40)",
          //   color:'#FFFFFF',
          //   paddingLeft: '0.3vw'
          // }}/>}
          
          > 
          { _bChoiceDSF_E?.map((item) =>
          <>
           
                { opt === 'selected' 
                  ? <Typography mt='0.5vw' ml='auto' mr='2vw' align='right' fontSize='1vw' fontWeight={700} color={colTx} width='19vw' > {(item.label == "Traditional") ? 'SMART TRADITIONAL' :(item.label == "Employer Support") ? 'EMPLOYER BENEFIT': (item.label == "Employer Benefit") ? 'EMPLOYER BENEFIT' :item?.label.toUpperCase()} </Typography>
                  : <Typography mt='0.5vw' mr='auto' ml='2.5vw' align='left' fontSize='1vw' fontWeight={700} color={colTx} width='19vw' > {(item.label == "Traditional") ? 'SMART TRADITIONAL' :(item.label == "Employer Support") ? 'EMPLOYER BENEFIT': item?.label.toUpperCase()} </Typography> 
                } 
                <Divider sx={{borderBottomWidth: 2, color:colTx}}/>
                <Stack spacing={opt==='proposed'?-0.1:1} direction="row" width='24vw' pl='1vw' pr='1vw'>
                  {
                    opt === 'proposed'?<></>
                  : ( idnt?(item.label=="Smart Traditional"&&finObjS?.propertyPrice >bChoiceDSFB.tradAfford.propPrice)||( (item.label == "Employer Support"|| item.label == "Employer Benefit")&& finObjS?.propertyPrice > bChoiceDSFB.extendedAfford.propPrice ):(finObjS?.monthlyEMI> bfinancialProfile.income.monthlyBudget))
                    // :
                    //  item.initialMonthlyEMI > bfinancialProfile.frontEndDTI || item.initialMonthlyEMI > bfinancialProfile.income.monthlyBudget

                    // : 
                      // item.percentLTV > 95
                        // || item.initialMonthlyEMI > bfinancialProfile.frontEndDTI || item.initialMonthlyEMI > bfinancialProfile.income.monthlyBudget
                      ? <Box width="6vw">
                          <HighlightOffIcon sx={{ml:'2vw',mt:'2vw',height:'2vw',fontSize:'2vw',color:'red'}}/>
                          <Typography mt='1vw' align='center' fontWeight={400} fontSize='1vw' width='8vw'  color={colTx} > Out of Budget </Typography>
                        </Box>
                      : <Box width="6vw">
                          <CheckCircleIcon  sx={{ml:'2vw',mt:'2vw',height:'2vw',fontSize:'2vw',color:'green'}}/>
                          <Typography mt='1vw' align='center' fontWeight={400} fontSize='1vw' width='8vw'  color={colTx} > Within Budget </Typography>
                        </Box> 
                  }
                  {
                    opt === 'proposed'
                    ? <Box pl='2.5vw' width='16vw'>
                     <Stack mt='0.5vw'ml='-1vw'spacing={0} direction="row" width='14vw'>
                        <Typography width='6vw' align='left'  fontSize='1vw' fontWeight={700} color={colTx} > {withCommas(Math.ceil(item?.loanAmount))}</Typography>
                        <Typography width='8vw' align='left'  fontSize='1vw' fontWeight={400} color={colTx} > Loan Amount</Typography> 
                     </Stack>
                     <Stack ml='-1vw' spacing={0} direction="row" width='14vw'>
                        <Typography width='6vw' align='left'  fontSize='1vw' fontWeight={700} color={colTx} > {(Math.round(item?.percentLTV))}%</Typography>
                        <Typography width='8vw' align='left'  fontSize='1vw' fontWeight={400} color={colTx} > LTV</Typography> 
                     </Stack>
                     <Stack ml='-1vw'spacing={0} direction="row" width='14vw'>
                        {bChoiceA === "refinance"
                        ? <> <Typography width='6vw' align='left'  fontSize='1vw' fontWeight={700} color={colTx} > {withCommas(bChoiceE.cashoutAmount)}</Typography>
                        <Typography width='8vw' align='left'  fontSize='1vw' fontWeight={400} color={colTx} > Cashout </Typography> </>
                        :<> <Typography width='6vw' align='left'  fontSize='1vw' fontWeight={700} color={colTx} > {withCommas(Math.ceil(item?.upfront))}</Typography>
                        <Typography width='8vw' align='left'  fontSize='1vw' fontWeight={400} color={colTx} > Upfront</Typography>  </>
                      }
  
                     </Stack> 
                     </Box>
                    : <Box width='16vw'>
                        <Stack mt='0.5vw'spacing={0} direction="row" width='14vw'>
                        <Typography width='8vw' align='left'  fontSize='1vw' fontWeight={400} color={colTx} > Loan Amount</Typography> 
                        <Typography width='6vw' align='right'  fontSize='1vw' fontWeight={700} color={colTx} > {withCommas(Math.ceil(item?.loanAmount))}</Typography>
                       
                     </Stack>
                     <Stack spacing={0} direction="row" width='14vw'>
                        <Typography width='8vw' align='left'  fontSize='1vw' fontWeight={400} color={colTx} >LTV</Typography> 
                        <Typography width='6vw' align='right'  fontSize='1vw' fontWeight={700} color={colTx} > {(Math.round(item?.percentLTV ))}%</Typography>
                        
                     </Stack>
                     <Stack spacing={0} direction="row" width='14vw'>
                       {bChoiceA === "refinance"
                        ?  <><Typography width='8vw' align='left'  fontSize='1vw' fontWeight={400} color={colTx} > Cashout </Typography>
                        <Typography width='6vw' align='right'  fontSize='1vw' fontWeight={700} color={colTx} > {withCommas(bChoiceE.cashoutAmount)}</Typography>
                        </>
                        : <> <Typography width='8vw' align='left'  fontSize='1vw' fontWeight={400} color={colTx} > Upfront</Typography>
                        <Typography width='6vw' align='right'  fontSize='1vw' fontWeight={700} color={colTx} > {withCommas(Math.ceil(item?.upfront))}</Typography>
                        </>
                        }
                       
                        
                         
                     </Stack> 
                     </Box>
                  }
                  
                  {
                    opt === 'selected' ? <></>
                    :( idnt?(item.label=="Smart Traditional"&&finObjP?.propertyPrice >bChoiceDSFB.tradAfford.propPrice)||( (item.label == "Employer Support"|| item.label == "Employer Benefit")&& finObjP?.propertyPrice > bChoiceDSFB.extendedAfford.propPrice ):(finObjP?.monthlyEMI> bfinancialProfile.income.monthlyBudget))

                    // || item.initialMonthlyEMI > bfinancialProfile.frontEndDTI || item.initialMonthlyEMI > bfinancialProfile.income.monthlyBudget
                    ? <Box  width="6vw">
                        <HighlightOffIcon sx={{ml:'2vw', mt:'2vw',height:'2vw',fontSize:'2vw',color:'red'}}/>
                        <Typography ml="-2vw" mt='1vw' align='center'  fontSize='1vw' width="8vw"  fontWeight={400} color={colTx} > Out of Budget </Typography>
                        </Box>
                    : <Box  width="6vw">
                        <CheckCircleIcon  sx={{ml:'2vw', mt:'2vw',height:'2vw',fontSize:'2vw',color:'green'}}/>
                        <Typography ml="-2vw" mt='1vw' align='center'  fontSize='1vw' width="8vw"  fontWeight={400} color={colTx} > Within Budget </Typography>
                        </Box>
                  }
                </Stack>
                </>
          )} 
        </ItemsCarousel>
      </div>
    );
}

function DatawCarousel(props) {
  const {index,_bChoiceDSF_E,opt,colT,colB} = props;
  const { finOptS,
          bfinancialProfile,
          selectedProp,
          proposedProp,
          review
        } = useSelector((state)=>state.borrower);
  const item = _bChoiceDSF_E[index]
  return (
        <div>
            <Typography pt="1vw" mt='0' ml='2vw' mr='auto' align='left' fontSize='1vw' fontWeight={700} color={colT} width='19vw' borderBottom='2px solid #000' > {item?.label.toUpperCase()}  </Typography>
            
            <Stack spacing={1} direction="row" width='23vw'>
                  
              <Box width='19vw'>
                <Typography width='12vw' ml='2vw' mt='0.1vw' align='left'  fontSize='1vw' fontWeight={700}    color={colT} > Loan Amount {withCommas(Math.ceil(item?.loanAmount))}</Typography>
                <Typography width='12vw' ml='2vw' mt='0.1vw' align='left'  fontSize='1vw' fontWeight={700}    color={colT} > {withCommas(Math.ceil(item?.monthlyEMI))}/month</Typography>
                <Typography width='12vw' ml='2vw' mt='0.1vw' align='left'  fontSize='1vw' fontWeight={700}    color={colT} > {withCommas(Math.ceil(item?.upfront))} upfront</Typography>
              </Box>
              <Box width='8vw'> 
                { item.percentLTV > 95 || item.initialMonthlyEMI > bfinancialProfile.frontEndDTI
                ? <><HighlightOffIcon sx={{ml:'1vw',mt:'-1vw',height:'7vw',fontSize:'3.5vw',color:'red'}}/>   <Typography ml='0.1vw' mt='-1.5vw' align='center'  fontSize="1vw" width="7vw" fontWeight={700} color={colT} > Out of Budget </Typography></>
                : <><CheckCircleIcon  sx={{ml:'1vw',mt:'-1vw',height:'7vw',fontSize:'3.5vw',color:'green'}}/>  <Typography ml='0.1vw' mt='-1.5vw' align='center'  fontSize="1vw" width="7vw" fontWeight={700} color={colT} > Within Budget </Typography></>
              }</Box>
            </Stack>
        </div>
  );
}

function AvatarCard(props) {
  const {bImage,bName,} = props;
  const { bChoiceDSFB
        } = useSelector((state)=>state.borrower);
  return (
      <Box>
        <Stack direction="row" spacing={2}>
          <Avatar alt={bName} src={bImage} sx={{ width: '2.92vw', height: '2.92vw' }}/>
          <Stack direction="column" spacing={1}>
              <Typography>Purchasing Power</Typography>
              <Typography>{Math.floor(bChoiceDSFB?.tradAfford?.propPrice)}</Typography>
          </Stack>
        </Stack>
        <Divider sx={{borderBottomWidth: 2}} color='black'/>
        <Typography>{bName}</Typography>
      </Box>
  );
}

export default RightBlockCompare