import React from "react";
import { Slider,Box, CssBaseline, Stack, Typography, Button, Input} from "@mui/material";

import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import { borrowerfinancialProfile,borrowerProfileStatus, calcPropfinOptionsA} from "../../actions/borrower";
import {Centerbottom, LeftbottomA, RightBlockSPBP} from './RightBlock'
import leftbottom from '../../assets/images/bg-10@1x.png'
import { IS_PROFILE_INFO_CHANGED, UPDATE_PROFILEJOURNEY, withCommas } from "../../constants";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { rspvw } from "./styles";
function MonthlyOutgoing() {
  const { bfinancialProfile,
          bProfileStatus,
          bChoiceD,
          bChoiceB,
          bChoiceE,
          isEmbFlow
        } = useSelector((state)=>state.borrower);
  const dispatch = useDispatch();
  const [outgoing,setOutgoing] = React.useState(bfinancialProfile.income.outgoing);
  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 

  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "Confirm": 
      dispatch(borrowerProfileStatus({...bProfileStatus,outgoingProvided:1}))
      dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
      dispatch({type:IS_PROFILE_INFO_CHANGED, payload:true});

      break;
      case "Skip": 
      const income = {...bfinancialProfile.income, outgoing:outgoing};
      dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income }));

      case "More": break;
      default: break;
    }
    navigate(-1)
  }

  return(
    <React.Fragment><CssBaseline/>
            <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
              <Navbar pathname={location.pathname}/>
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
              { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlock/>}
        
                <Box sx={{ width: '14vw',height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
                <Typography sx={{color:'#000000',mt:'11.42vw',fontSize:'1.4vw', textAlign:'center'}}> 
                    Update Settings</Typography>
                <div align='center'>
                  {/* <Stack sx={{mt:'3.43vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: 0,mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}} id="Confirm" 
                    onClick={onClickFun}> 
                    <HexButton titleText="&nbsp; Confirm &nbsp;" type='base' fontSize='24'/></Button>
                  </Stack> */}
                  <Stack sx={{mt:'2vw',height:'3.5vw', width:'12vw'}} spacing={0} direction="row" justifyContent="space-around">
                    {/* <Button sx={{ textTransform:'none', minHeight: 0, minWidth: 0, padding: 0,mt:'0',ml:'0.0vw',width:'5.23vw',height:'3.5vw',border:0}}id="More"  
                    onClick={onClickFun}> 
                    <HexButton titleText="Provide More Details" type='base' fontSize='24'/></Button> */}
                    <Button sx={{ overflow: 'hidden',textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id="Confirm" className='hexBtnCenter'
                    onClick={onClickFun}> 
                    <HexButton titleText="&nbsp; Confirm &nbsp;" type='base' fontSize='24'/></Button>
                    <Button sx={{ overflow: 'hidden',textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id="Skip" className='hexBtnCenter'
                    onClick={onClickFun}> 
                    <HexButton titleText="Skip" type='base' fontSize='24'/></Button>
                   </Stack>
              <Centerbottom navigateForward = "-1"/>
              </div>
        </Box>
      <RightBlockSPBP/>
      </Box>
    </Box>
    </React.Fragment>
  );
}


function LeftBlock() {
    var input;
    const dispatch = useDispatch();
    const {bChoiceE,bfinancialProfile,bProfileJourney, bProfileStatus,isEmbFlow} = useSelector((state)=>state.borrower);
    const [monthlyOutgoing,setMonthlyOutgoing] = React.useState(bfinancialProfile.income.outgoing);
    const [upfront,setUpfront] = React.useState(bfinancialProfile.income.upfront);
    const [once,setOnce] = React.useState(true)
    const maxValue = Math.ceil(bfinancialProfile.income.annualIncome*0.03)
    React.useEffect(()=>{
          if(once) {
            dispatch(borrowerProfileStatus({...bProfileStatus,monthlybudgetProvided:1}));
            dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, creditOutgoing:true } });
            setOnce(false)
          }
          const income = {...bfinancialProfile.income, outgoing:monthlyOutgoing};
          dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income }));
    },[monthlyOutgoing])
    
    function onClickFun(e){
      e.preventDefault();
      const buttonId = e.currentTarget.id;
      switch(buttonId) {
        case "MonthlyOutgoing":
        case "mSlider":
          var input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
          setMonthlyOutgoing(isNaN(input) ? 0 : input > maxValue ? maxValue:input)
        break;
        default:
          break;
      }
    }
    return (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
          <Box sx={{ ml:'7vw',mt:'1%' , width:'21vw',background:'inherit', height: '19.7vw' }}>
              <div align='center'>
                <Typography mt={23} fontSize='1.4vw' fontStyle="regular" component='div'color='#000000'> 
                     { isEmbFlow?'Monthly Outgoings':'Monthly Outgoing'}
                  </Typography>
                  <Typography sx={{ mt:2, width:'20vw'}} fontSize='1vw' color='#000000' align='center'>
                      {isEmbFlow?'Please tell us your monthly commitment towards other borrowings':'Please tell us your total recurring monthly expenses and payments'}
                  </Typography>
                  <Stack mt={1} direction='row' justifyContent='space-between'>
                        <Typography sx={{mt:'0.56vw',fontSize:'1vw'}}> {withCommas(0)} </Typography>
                <Input error={monthlyOutgoing > maxValue} disableUnderline={true} id="MonthlyOutgoing" variant="standard" value={withCommas(monthlyOutgoing)} sx={{ml:12, color:"#000000",fontSize:'1.3vw', fontWeight:'bold', width:"10vw"}} 
                 helperText="Value outside prescribed limits" onChange={onClickFun}InputProps={{disableUnderline:true }}/>
                <Typography sx={{mt:'0.56vw',fontSize:'1vw'}}> {withCommas(maxValue)} </Typography>
                </Stack>
                <Slider id="mSlider" sx={{color:'#000000',mt:'0.56vw' ,width:"21vw"}}aria-label="Outgoing" value={monthlyOutgoing}
                  min={0} max={maxValue} step={100} onChange={(e,newValue)=>setMonthlyOutgoing(newValue)}/>
        
                  <Typography sx={{ mt:2, width:'20vw'}} fontSize='1.2vw' fontStyle="regular" component='div'
                              color='#000000'> 
                      {isEmbFlow?'':'Your Monthly commitment towards other borrowings and fees'}
                  </Typography>
                </div>
              </Box>
            <LeftbottomA leftbottom={leftbottom}/>
          </Box>
    );
}

export default MonthlyOutgoing;