import React from "react";
import { useNavigate } from "react-router-dom";
import { rspvw } from "./styles";
// material-ui
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  CssBaseline, InputAdornment, IconButton, Divider
} from '@mui/material';
import { HexButton } from './ButtonIcons1';
import { getWindowDimensions } from '../../computations/helperFunctions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import leftbottom from '../../assets/svg/assets svg/image-leftbottom02.png'
import mask_group from '../../assets/staticPageAssets/MaskGroup107.png'
import bullet from '../../assets/staticPageAssets/Polygon.svg'
import useStyles from './styles';
import { BottomBox, LeftbottomA } from "./RightBlock";
import { _debug } from "../../constants";
import { border, fontWeight, width } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex";
import NavBarDashBoardUpdate from "./NavBarDashBoardUpdate";
import NavBarBreadCrumbUpdate from "./NavBarBreadCrumbUpdate";

import AnalyticResourceOne from './AnalyticResourceOne';
import AnalyticResourceOneNew from './AnalyticResourceOneNew';
import AnalyticResourceTwoNew from './AnalyticResourceTwoNew';
import AnalyticResourceThreeNew from './AnalyticResourceThreeNew';
import AnalyticEcommerce from './AnalyticEcommerce';
//import IncomeAreaChart from './IncomeAreaChart';
//import MonthlyBarChart from './MonthlyBarChart';
import { Bar, Line } from "react-chartjs-2";
import MainCard from './MainCard';
import "chartjs-plugin-datalabels";
import person from '../../assets/svg/person.svg'
import dollarsolid from '../../assets/svg/dollar-solid.svg'
import briefcasefill from '../../assets/svg/briefcase-fill.svg'
import graduationcap from '../../assets/svg/graduation-cap.svg'
import ResourceTable from './ResourceTable';
import avatar_boy from '../../assets/svg/avatar-boy.png'
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import viewfilled from '../../assets/view-filled.png'
import viewfilledGrey from '../../assets/svg/view-filled.svg'
import bLike from '../../assets/bxs-like.png'
import commentAlt from '../../assets/comment-alt-text.png'
import documentPDF from '../../assets/svg/document-pdf.svg'
import expandMore from '../../assets/svg/expand_more.svg'
import color from '../../assets/svg/Color.svg'
import calender from '../../assets/svg/ios-calendar-outline.svg'
import bLikeGrey from '../../assets/svg/bxs-like.svg'
import pptOpen from '../../assets/ppt-open.png'
import playButton from '../../assets/svg/play-button-o.svg'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement, PointElement,
  Title,
  Tooltip,
  Legend, LineElement

} from 'chart.js';
import { breadcrumbData} from "../../actions/borrower";
import { useDispatch, useSelector } from 'react-redux';
var allowed = "no"


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip, LineElement,

  Legend, PointElement
);




function EmployerCompanyMaterial() {
  const navigate = useNavigate();
  const dispatch= useDispatch()
  dispatch(breadcrumbData("Your Material"));
  const { height, width } = getWindowDimensions();
  const [seek, setSeek] = React.useState(allowed);
  const [hexaText1, setHexaText1] = React.useState('Investors')
  const [hexaText2, setHexaText2] = React.useState('Technology Partners')
  const [hexaText3, setHexaText3] = React.useState('Employers')
  const [hexaText4, setHexaText4] = React.useState('Mortgage Industry')


  const [value, setValue] = React.useState('today');
  const [slot, setSlot] = React.useState('week');
  // if(_debug === 'development') allowed = 'yes'
  allowed = 'yes'


  const state = {
    labels: ['January', 'February', 'March',
      'April', 'May'],
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [65, 59, 80, 81, 56]
      }
    ]
  }


  // const dataBar = {
  //   labels: ["January", "February", "March", "April", "May", "June", "July"],
  //   datasets: [
  //     {
  //       label: "My First dataset",
  //       backgroundColor: "#EC932F",
  //       borderColor: "rgba(255,99,132,1)",
  //       borderWidth: 1,
  //       hoverBackgroundColor: "rgba(255,99,132,0.4)",
  //       hoverBorderColor: "rgba(255,99,132,1)",
  //       data: [65, 59, 80, 81, 56, 55, 40]
  //     }
  //   ]
  // };

  // const options = {
  //   plugins: {
  //     datalabels: {
  //       display: true,
  //       color: "black",
  //       align: "end",
  //       anchor: "end",
  //       font: { size: "14" }
  //     }
  //   },
  //   legend: {
  //     display: false
  //   }
  // };


  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
      //   {
      //     label: "Second dataset",
      //     data: [33, 25, 35, 51, 54, 76],
      //     fill: false,
      //     borderColor: "#742774"
      //   }
    ]
  };


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  //  const data = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'Dataset 1',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //     },
  //     {
  //       label: 'Dataset 2',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //     },
  //   ],
  // };

  // avatar style
  const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem'
  };

  // action style
  const actionSX = {
    mt: 0.75,
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',
    transform: 'none'
  };
  
  const handleClick = (event) => {
   console.log("hiii")
   navigate("/EmployerCompanyCal", { replace: false }) 
  };

  return (
    <React.Fragment><CssBaseline />
      <Box sx={{ overflow: 'hidden', width: '100%', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <NavBarDashBoardUpdate />
        <NavBarBreadCrumbUpdate />

      
        <Box sx={{ ml: `${rspvw(141)}`, mr: `${rspvw(141)}`, mt: `${rspvw(27)}`, mb: `${rspvw(23)}` }} >
          <Grid container >
            <Grid item xs={12} md={5} lg={4}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography sx={{ mt: '0', mr: '0vw', ml: '0vh', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(22)}` }} >
                    Title Here</Typography>
                </Grid>

              </Grid>

              <List sx={{ mt: `${rspvw(23)}`, mr: `${rspvw(0)}`, ml: '0.0vw', '& .MuiListItemButton-root': { py: 2 } }}>
                <Box sx={{ background: '#ffffff 0% 0% no-repeat padding-box', width: `${rspvw(478)}`, height: `${rspvw(86)}`, borderRadius: 2, border: 1, borderColor: '#FFF' }}>
                  <ListItemButton >
                    <Box sx={{ mr: `${rspvw(15)}` }}>
                      <Avatar alt="profile user" src={avatar_boy} sx={{ width: `${rspvw(36)}`, height: `${rspvw(36)}` }} />
                    </Box>


                    <ListItemText disableTypography
                      primary={<Typography sx={{
                        mt: `${rspvw(6.5)}`, mr: '0vw', ml: `${rspvw(20)}`, mb: `${rspvw(6.5)}`,
                        fontWeight: 'Bold', font: 'Roboto', color: '#000000', opacity: '1', fontSize: `${rspvw(17)}`
                      }}>Company Material</Typography>}

                    />

                  </ListItemButton>
                </Box>

                <Box sx={{ mt: `${rspvw(16)}`, background: '#ffffff 0% 0% no-repeat padding-box', width: `${rspvw(478)}`, height: `${rspvw(86)}`, borderRadius: 2, border: 1, borderColor: '#FFF' }}>
                  <ListItemButton>
                    <Box sx={{ mr: `${rspvw(15)}` }}>
                      <Avatar alt="profile user" src={avatar_boy} sx={{ width: `${rspvw(36)}`, height: `${rspvw(36)}` }} />
                    </Box>


                    <ListItemText disableTypography
                      primary={<Typography sx={{
                        mt: `${rspvw(6.5)}`, mr: '0vw', ml: `${rspvw(20)}`, mb: `${rspvw(6.5)}`,
                        fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: `${rspvw(17)}`
                      }}>Nestfuel Publications</Typography>}

                    />


                  </ListItemButton>
                </Box>
                <Box sx={{ mt:  `${rspvw(16)}`, background: '#ffffff 0% 0% no-repeat padding-box', width: `${rspvw(478)}`, height: `${rspvw(86)}`, borderRadius: 2, border: 1, borderColor: '#FFF' }}>
                  <ListItemButton   onClick={handleClick}>
                    <Box sx={{ mr: `${rspvw(15)}` }}>
                      <Avatar alt="profile user" src={avatar_boy} sx={{ width: `${rspvw(36)}`, height: `${rspvw(36)}` }} />
                    </Box>
                    <ListItemText disableTypography
                      primary={<Typography sx={{
                        mt: `${rspvw(6.5)}`, mr: '0vw',  ml: `${rspvw(20)}`, mb: `${rspvw(6.5)}`,
                        fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: `${rspvw(17)}`
                      }}>Cost Calculator</Typography>}

                    />
                  </ListItemButton>
                </Box>

                <Box sx={{ mt:  `${rspvw(16)}`, background: '#ffffff 0% 0% no-repeat padding-box', width: `${rspvw(478)}`, height: `${rspvw(86)}`, borderRadius: 2, border: 1, borderColor: '#FFF' }}>
                  <ListItemButton>
                    <Box sx={{ mr: `${rspvw(15)}` }}>
                      <Avatar alt="profile user" src={avatar_boy} sx={{ width: `${rspvw(36)}`, height: `${rspvw(36)}`}} />
                    </Box>


                    <ListItemText disableTypography
                      primary={<Typography sx={{
                        mt: `${rspvw(6.5)}`, mr: '0vw', ml: `${rspvw(20)}`, mb: `${rspvw(6.5)}`,
                        fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: `${rspvw(17)}`
                      }}>Important Information</Typography>}

                    />

                  </ListItemButton>
                </Box>

                <Box sx={{ mt: `${rspvw(16)}`, background: '#ffffff 0% 0% no-repeat padding-box', width: `${rspvw(478)}`, height: `${rspvw(86)}`, borderRadius: 2, border: 1, borderColor: '#FFF' }}>
                  <ListItemButton>
                    <Box sx={{ mr: `${rspvw(11.9)}` }}>
                      <Avatar alt="profile user" src={avatar_boy} sx={{ width: `${rspvw(36)}`, height: `${rspvw(36)}`}} />
                    </Box>
                    <ListItemText disableTypography
                      primary={<Typography sx={{
                        mt: `${rspvw(6.5)}`, mr: '0vw', ml: `${rspvw(20)}`, mb: `${rspvw(6.5)}`,
                        fontWeight: 'Regular', font: 'Roboto', color: '#585858', opacity: '1', fontSize: `${rspvw(17)}`
                      }}>General Queries</Typography>}

                    />

                  </ListItemButton>
                </Box>
              </List>


            </Grid>

            <Grid item xs={12} md={5} lg={8} sx={{ mt: `${rspvw(65.6)}` }}>

              <MainCard sx={{ mt: 0 }} content={false}>

                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item sx={{ mt: `${rspvw(34)}`, mr: '0vw', ml: `${rspvw(57)}` }}>
                    <Typography
                      sx={{
                        mt: `0`, mr: '0vw', ml: `${rspvw(0)}`, mb: '0vh',
                        fontWeight: 'Bold', font: 'Roboto', color: '#000000', opacity: '1', fontSize: `${rspvw(26)}`
                      }} >
                      Company Material</Typography>
                  </Grid>

                </Grid>
              
                <AnalyticResourceOneNew/>
         
                <Divider sx={{ borderBottomWidth: 0.5, mt: `${rspvw(75.6)}`, ml: `${rspvw(57)}` }} color='black' />
                {/* Webinars */}
                <Grid container rowSpacing={4.5} columnSpacing={1} sx={{ ml: `${rspvw(37)}`, mr: `${rspvw(29.5)}`}}>
                  <Grid item xs={12} sx={{ mb: -2.25 ,}}>
                    <Typography variant="h5" sx={{ ml: `${rspvw(6.5)}`, mr: `${rspvw(141)}`, mt: `${rspvw(43.2)}`, mb: `${rspvw(2.2)}` }}>Webinars</Typography>
                  </Grid>
                  <Grid item xs={23} sm={7} md={10} lg={4} >
                    <AnalyticResourceOne title="Total Views" count="1450" image={viewfilled} percentage={59.3} extra="35,000" />
                  </Grid>
                  <Grid item xs={23} sm={7} md={10} lg={4}>
                    <AnalyticResourceOne title="Likes" count="1250" image={bLike} percentage={70.5} extra="8,900" />
                  </Grid>
                  <Grid item xs={23} sm={7} md={10} lg={4}>
                    <AnalyticResourceOne title="Comments" count="1200" image={commentAlt} percentage={27.4} isLoss color="warning" extra="1,943" />
                  </Grid>
                </Grid>

                <Box sx={{mt:`${rspvw(80.5)}`}}>
                <AnalyticResourceTwoNew/>
                </Box>
               

                <Divider sx={{ borderBottomWidth: 0.5, mt: `${rspvw(76.5)}`,  ml: `${rspvw(37)}` }} color='black' />
                {/* Webinars */}
                <Grid container rowSpacing={4.5} columnSpacing={1} sx={{ ml: `${rspvw(37)}` }}>
                  <Grid item xs={12} sx={{ mb: -2.25 }}>
                    <Typography variant="h5" sx={{ ml: `${rspvw(6.5)}`, mr: `${rspvw(141)}`, mt: `${rspvw(43.2)}`, mb: `${rspvw(2.2)}` }}>Video Teach In</Typography>
                  </Grid>
                  <Grid item xs={23} sm={7} md={10} lg={4} >
                    <AnalyticResourceOne title="Total Views" count="1650" image={viewfilled} percentage={59.3} extra="35,000" />
                  </Grid>
                  <Grid item xs={23} sm={7} md={10} lg={4}>
                    <AnalyticResourceOne title="Likes" count="1050" image={bLike} percentage={70.5} extra="8,900" />
                  </Grid>
                  <Grid item xs={23} sm={7} md={10} lg={4}>
                    <AnalyticResourceOne title="Comments" count="1750" image={commentAlt} percentage={27.4} isLoss color="warning" extra="1,943" />
                  </Grid>
                </Grid>


                {/* Video Teach In End */}


                <Box sx={{mt:`${rspvw(80.5)}`}}>
                <AnalyticResourceThreeNew/>
                </Box>

              

                <Divider sx={{ borderBottomWidth: 0.5, ml: `${rspvw(37)}`, mt: `${rspvw(76.6)}` }} color='black' />

                {/* Blog Start */}
                <Grid container rowSpacing={4.5} columnSpacing={1} sx={{ ml: `${rspvw(37)}` }}>
                  <Grid item xs={12} sx={{ mb: -2.25 }}>
                    <Typography variant="h5" sx={{ ml: `${rspvw(6.4)}`, mr: `${rspvw(141)}`, mt: `${rspvw(43.2)}`, mb: `${rspvw(2.2)}` }}>Blogs</Typography>
                  </Grid>
                  <Grid item xs={23} sm={7} md={10} lg={4} >
                    <AnalyticResourceOne title="Total Views" count="1550" image={viewfilled} percentage={59.3} extra="35,000" />
                  </Grid>
                  <Grid item xs={23} sm={7} md={10} lg={4}>
                    <AnalyticResourceOne title="Likes" count="1250" image={bLike} percentage={70.5} extra="8,900" />
                  </Grid>
                  <Grid item xs={23} sm={7} md={10} lg={4}>
                    <AnalyticResourceOne title="Comments" count="1050" image={commentAlt} percentage={27.4} isLoss color="warning" extra="1,943" />
                  </Grid>
                </Grid>


                {/* Blog End */}
                <Stack spacing={0} direction='row' sx={{mt:`${rspvw(95.5)}`}}>

                  <Stack spacing={0}>
                    <Typography sx={{ mt: `${rspvw(32.4)}`, mr: `${rspvw(43)}`, ml: `${rspvw(86)}`, mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#000000', opacity: '1', fontSize: `${rspvw(16)}` }}>
                      Click here and read information here</Typography>

                    <Typography sx={{ mt: `${rspvw(9)}`, mr: `${rspvw(196.6)}`, ml: `${rspvw(86)}`, mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: `${rspvw(16)}` }}>
                    To buy or not to buy that is the question</Typography>

                  </Stack>

                  <Stack spacing={0} sx={{ mb: `${rspvw(162)}` }}>
                    <Box sx={{ mr: `${rspvw(326.4)}`, ml: `${rspvw(153.6)}`, mt:  `${rspvw(67.1)}`, mb: '0vh', width: `${rspvw(33)}`, height: `${rspvw(11.4)}` }}>
                      <img src={playButton} alt = "Loading..." width="100%" heigh="100%" />

                    </Box>
                    <Box sx={{ mr: `${rspvw(38.4)}`, ml:`${rspvw(29.7)}`, mt: `${rspvw(18.1)}`, mb: '0vh' }}>
                      <Typography sx={{ mt:  `${rspvw(32.4)}`, mr: '0vw', ml: `${rspvw(0.2)}`, mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', 
                      fontSize: `${rspvw(15)}` }}>
                        12:00 pm | April 1 2023</Typography>
                    </Box>

                  </Stack>
                  
                </Stack>
                
                  <Divider sx={{ borderBottomWidth: 1.5, ml: `${rspvw(94.6)}`, mt: `${rspvw(-129.6)}`, mr: `${rspvw(305)}` }} color='black' />
                
                <Stack spacing={0} direction='row'  sx={{mt:`${rspvw(95.5)}`}}>

                  <Stack spacing={0}>
                    <Typography sx={{ mt: `${rspvw(32.4)}`, mr: `${rspvw(43)}`, ml: `${rspvw(86)}`, mb: '0vh', fontWeight: 'Regular', font: 'Roboto', 
                    color: '#000000', opacity: '1', fontSize: `${rspvw(16)}` }}>
                      Click here and read information here</Typography>

                    <Typography sx={{ mt: `${rspvw(9)}`, mr: `${rspvw(196.6)}`, ml: `${rspvw(86)}`, mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: `${rspvw(16)}` }}>
                    Making cents' of financial wellbeing</Typography>

                  </Stack>

                  <Stack spacing={0} sx={{ mb: `${rspvw(162)}` }}>
                    <Box sx={{ mr: `${rspvw(326.4)}`, ml: `${rspvw(153.6)}`, mt: `${rspvw(67.1)}`, mb: '0vh', width: `${rspvw(33)}`, height: `${rspvw(11.5)}` }}>
                      <img src={playButton} alt = "Loading..." width="100%" heigh="100%" />

                    </Box>
                    <Box sx={{ mr: `${rspvw(38.4)}`, ml: `${rspvw(16.7)}`, mt:  `${rspvw(18.1)}`, mb: '0vh' }}>
                      <Typography sx={{ mt: `${rspvw(32.4)}`, mr: '0vw', ml: `${rspvw(0.2)}`, mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: `${rspvw(15)}` }}>
                        11:00 pm | March 1 2023</Typography>
                    </Box>

                  </Stack>
                </Stack>
                <Divider sx={{ borderBottomWidth: 1.5, ml: `${rspvw(94.7)}`, mt: `${rspvw(-129.6)}`, mr: `${rspvw(306)}` }} color='black' />

                <Stack spacing={0} direction='row' sx={{mt:`${rspvw(95.5)}`}}>

                  <Stack spacing={0}>
                    <Typography sx={{ mt: `${rspvw(32.4)}`, mr: `${rspvw(43)}`, ml: `${rspvw(86)}`, mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#000000', opacity: '1', fontSize: `${rspvw(16)}` }}>
                      Click here and read information here</Typography>

                    <Typography sx={{ mt: `${rspvw(9)}`, mr: `${rspvw(196.6)}`, ml: `${rspvw(86)}`, mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: `${rspvw(16)}` }}>
                    Mortgage rates - sky high oh my my!</Typography>

                  </Stack>

                  <Stack spacing={0} sx={{ mb: `${rspvw(162)}` }}>
                    <Box sx={{ mr: `${rspvw(326)}`, ml: `${rspvw(154)}`, mt: `${rspvw(67.1)}`, mb: '0vh', width: `${rspvw(33)}`, height: `${rspvw(11.5)}` }}>
                      <img src={playButton} alt = "Loading..." width="100%" heigh="100%" />

                    </Box>
                    <Box sx={{ mr: `${rspvw(38.4)}`, ml: `${rspvw(30)}`, mt: `${rspvw(18.1)}`, mb: '0vh' }}>
                      <Typography sx={{ mt: `${rspvw(32.4)}`, mr: '0vw', ml: `${rspvw(0.2)}`, mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#616161', opacity: '1', fontSize: `${rspvw(15)}` }}>
                        10:00 pm | April 4 2022</Typography>
                    </Box>

                  </Stack>
                </Stack>
                <Divider sx={{ borderBottomWidth: 1.5, ml: `${rspvw(94.7)}` , mt: `${rspvw(-129.6)}` , mr: `${rspvw(306)}`, mb:`${rspvw(108)}` }} color='black' />



              </MainCard>

              <Stack spacing={0} direction='row' sx={{mt:`${rspvw(50.2)}`,mb:`${rspvw(54)}`}}>
                {/* <TextField id="Upfront" sx={{ml:"25%", input: { fontSize: '1.2vw', color: '#18DFD7',width: '10vw'}}} 
                  type="text" name="Upfront" />  */}

                <TextField sx={{ backgroundColor: '#FFFFFF', mt: `${rspvw(54)}`, fontSize: `${rspvw(0.9)}` , width: `${rspvw(960)}` , height:'0', ml: "1%" }}
                  variant='outlined'
                  defaultValue=''
                  label='Insert here any desired webinar,presentation or blog topic'
                  placeholder='Insert here any desired webinar,presentation or blog topic'
                  id="outlined-basic"

                />
                <Button variant="contained" sx={{ backgroundColor: '#000000', mt: `${rspvw(54)}`, fontSize: `${rspvw(16)}`, ml: "1%" }} >Send</Button>
              </Stack>

            </Grid>
          </Grid>
        </Box>

      </Box>
    </React.Fragment>
  );
}

function InputKey() {

  const name = 'password'
  const type = 'password'
  const [cur, setCur] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);

  function onClickFun(e) {
    const val1 = e.currentTarget.value
    val1 === "N3$tfu3L" ? allowed = 'yes' : allowed = 'no'


  };

  return (
    <div position='fixed' align='center'>
      <Typography sx={{ fontSize: '1vw', width: '12vw' }}>Please answer the following to activae DISCOVER NESTFUEL (Hint:Army man of the Raj earns working days by a week 7-6-3)</Typography>
      <Typography sx={{ mt: 2, fontSize: '1vw', width: '12vw' }}></Typography>
      <TextField sx={{ fontsize: '0.5vw', mt: 1, width: '12vw', border: 1, borderColor: 'black', borderRadius: 2 }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label
        autoFocus={true}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </div>
  )
}

export default EmployerCompanyMaterial;