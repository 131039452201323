import React from "react";
import { Box, Stack, CssBaseline, TextField, InputAdornment, IconButton, Typography, Button } from "@mui/material";
import { HexButton } from './ButtonIcons1';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import useStyles from './styles';
import Navbar from "./NavBar";
import { BottomBox } from "./RightBlock";
import { _debug } from "../../constants";
import { border, borderRight } from "@mui/system";

var allowed = "no"

function LegalDisclosure() {
  const navigate = useNavigate();

  const [seek, setSeek] = React.useState(allowed)
  const classes = useStyles();
  // if(_debug === 'development') allowed = 'yes'
  allowed = 'yes'

  return (
    <React.Fragment><CssBaseline />
      <Box sx={{ overflow:'hidden', width: '100%', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <Navbar />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <LeftBlockA />

          <Box sx={{ width: '14vw', justifyContent: 'center' }}>
            <Stack sx={{ mr: '2.0vw', ml: '3.0vw', mt: '19vw', height: '10vw', width: '10vw' }} direction="row">
              <Button sx={{overflow:'hidden', mt:0,zIndex:1,border:0,width:'8.0vw',position:'fixed',height:'10vw', minHeight: 0, minWidth: 0,padding: '0.2vw'}}  id="Discover" className='hexBtnCenter'
                onClick={() => allowed === 'yes' ? navigate(-1, { replace: false }) : setSeek("ddd")}>
                <HexButton titleText="CLOSE" type='large' fontSize='24' />
              </Button>
            </Stack>
            {seek == 'ddd' ? <InputKey /> : <></>}
          </Box>

          
          <Box sx={{
        background: '#F3F3F3 0% 0% no-repeat padding-box',
        boxShadow: '-1px -6px 46px #0000003D', p:0,opacity: 1, width: '50vw',borderRadius:'0.5vw'}}>
          
<Box sx={{marginLeft:'4.84vw',marginTop:'7.465vw',width:'39.32vw',height:'34.22vw' ,overflowX:'none',overflowY:'auto','&::-webkit-scrollbar': { width: 0, }}}>
<h3 >Disclosure & Licensing </h3>

    <p></p> 
    <p align='justify'>Nestfuel Realty LLC is an Equal Housing Lender. As prohibited by federal law, we do not engage in business practices
     that discriminate on the basis of race, color, religion, national origin, sex, marital status, age (provided you have the capacity to 
     enter into a binding contract), because all or part of your income may be derived from any public assistance program, or because you 
     have, in good faith, exercised any right under the Consumer Credit Protection Act. The federal agency that administers our compliance 
     with these federal laws is the Federal Trade Commission, Equal Credit Opportunity, Washington, DC, 20580. </p>
      
  
     <Box sx={{marginTop:'2vw' }}>

    <Box sx={{width:'39.32vw',height:'5vw',display:'flex'}}>
        <Box sx={{width:'10vw',height:'5vw'}}>
        <p align='justify'>NMLS #2246224 – </p>
            </Box>
            <Box sx={{width:'29.32vw',height:'5vw'}}>
 
                <Typography sx={{mt:'0.5vh', ml:'.5vw',color:'#31312C',
                font: "Roboto", textAlign:'left'}} fontSize='1vw'lineHeight={1.2}fontWeight='normal'> 
        <a href='https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/2246224' 
    target="popup">NMLS Consumer Access</a> 
                </Typography>
            <p align='justify'>
       
            </p>
        </Box>
            </Box>
      
      </Box> 
      
    <p align='justify'> </p>

    <Box sx={{width:'39.32vw',height:'5vw',display:'flex',border:'1px solid black'}}>
        <Box sx={{width:'10vw',height:'5vw',borderRight:'1px solid black'}}>
       
        <Typography sx={{mt:'0.9vh', ml:'.5vw',color:'#31312C',
                font: "Roboto", textAlign:'left'}} fontSize='1vw'lineHeight={1.2}fontWeight='normal'> 
          Colorado licensing
                </Typography>
        </Box>
        <Box sx={{width:'29.32vw',height:'5vw'}}>
        <Typography sx={{mt:'0.5vh', ml:'.5vw',color:'#31312C',
                font: "Roboto", textAlign:'left'}} fontSize='1vw'lineHeight={1.2}fontWeight='normal'> 
              Loans made or arranged pursuant to Mortgage Company 
                </Typography>
                <Typography sx={{mt:'0.9vh', ml:'.5vw',color:'#31312C',
                font: "Roboto", textAlign:'left'}} fontSize='1vw'lineHeight={1.2}fontWeight='normal'> 
           Registration with the Colorado Division of Real Estate 
                </Typography>
            <p align='justify'>
       
            </p>
        </Box>
        

    </Box>
    
     
</Box>

   </Box>

        
        </Box>




<Box className={classes.bottomBar}><BottomBox/></Box>

      </Box>
    </React.Fragment>
  );
}


  function LeftBlockA() {
    return (
     
  
      <Box sx={{
        background: '#F3F3F3 0% 0% no-repeat padding-box',
        boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '45vw'
      }}>
    
          <Box sx={{marginLeft:'6.72vw',marginTop:'7.76vw',width:'18.89vw',height:'12.60vw',fontsize:'4.64vw',font:'Roboto'}}>
          <Typography sx={{color:'#8f8f8f', fontWeight:'bold',
                  font: "Roboto", textAlign:'center'}} fontSize='4.6vw' lineHeight={1.2} letterSpacing='-1.6px'> 
                Disclosure & Licensing
                  </Typography>
          </Box>
       

  
          <Box sx={{marginLeft:'7.34vw',marginTop:'6.51vw',width:'24.01vw',height:'12.60vw',fontsize:'4.64vw',font:'Roboto'}}>
          <Typography sx={{color:'#8f8f8f', fontWeight:'bold',
                  font: "Roboto", textAlign:'left'}} fontSize='2.08vw' lineHeight={1.2} letterSpacing='-1.84px'> 
                No products or services 
                  </Typography>
                  <Typography sx={{color:'#8f8f8f', fontWeight:'bold',
                  font: "Roboto", textAlign:'left'}} fontSize='2.08vw' lineHeight={3} letterSpacing='-1.84px'> 
                 are currently offered directly 
                  </Typography>
                  <Typography sx={{color:'#8f8f8f', fontWeight:'bold',
                  font: "Roboto", textAlign:'left'}} fontSize='2.08vw' lineHeight={1.2} letterSpacing='-1.84px'> 
                 to the consumers.
                  </Typography>
          </Box>
  
      </Box>
      );
    }







function InputKey() {

  const name = 'password'
  const type = 'password'
  const [cur, setCur] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);


  function onClickFun(e) {
    const val1 = e.currentTarget.value
    val1==="N3$tfu3L"? allowed = 'yes' : allowed = 'no'

  };

  return (
    <div position='fixed' align='center'>
      <Typography sx={{ fontSize: '1vw', width: '12vw' }}>(Please answer the following to activae DISCOVER NESTFUEL)</Typography>
      <Typography sx={{ mt: 2, fontSize: '1vw', width: '12vw' }}></Typography>
      <TextField sx={{ fontsize: '0.5vw', mt: 1, width: '12vw', border: 1, borderColor: 'black', borderRadius: 2 }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label='No flying ends Indian penny'
        autoFocus={true}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </div>
  )
}

export default LegalDisclosure;