import React from "react";
import { Box, CssBaseline, Input, CircularProgress,  Typography, Button, Stack, selectClasses} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate } from 'react-router-dom';
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import ItemsCarousel from "react-items-carousel";
import leftbottom from '../../assets/images/bg-10@1x.png'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GoogleMaps from './Googlemaps';
import {  borrowerProfile,
          borrowerProfileNew,
          borrowerPropertyDetail,
          borrowerProfileStatus} from "../../actions/borrower";
import { BedsAndBaths, Centerbottom, LeftbottomA, RightBlockApply } from "./RightBlock";
import { UPDATE_PROFILEJOURNEY, withCommas } from "../../constants";
import {useLocation} from 'react-router-dom';
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import * as api from '../../api/index';
import { fontFamily } from "@mui/system";
import { rspvw } from "./styles";
import { mongoborrowerPostPropertyData } from "../../actions/borrower";
import { RightBlockDashboardFinanceDefault } from "./DashboardRightB";

function StartApplicationDefault() {
  
  const { selectedProp,
          proposedProp,
          isLoading,
          bProfile,
          bfinancialProfile,
          bProfileJourney,
          bChoiceDSF,
          bPropertyDetail,
         } = useSelector((state)=>state.borrower);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  const [isvalidEmail,setIsValidEmail] = React.useState(true)
  const [isValidName, setIsValidName] = React.useState(true)
  const [navCheck, setNavCheck] = React.useState(false)
  const [warning, setWarning] = React.useState(false)
  const [propViewed,setPropViewed] = React.useState(Array.isArray(bProfile.propertiesViewed)?bProfile.propertiesViewed:[])
  // const [propC,setPropC]    = React.useState(location?.state?.name)
  // const [finObj,setFinObj]  = React.useState(bChoiceDSF[propC?.propId][propC?.finOpt])
  // console.log("here is the bChoiceDSF", bChoiceDSF[propC?.propId][propC?.finOpt])
  // const [financeOptionDetails, setFinanceOptionDetails] = React.useState('')
  // const [propertyDetails, setPropertyDetails] = React.useState('')
  const financeOptionDetails ={
    "propertyPrice": selectedProp.prop.offerPrice,
    "financeType": "primary",
    "mortgageType": "QM",
    "loanTerm": selectedProp.prop.mortgageDetails.loanTerm,
    "interestRate": selectedProp.prop.mortgageDetails.interestRate,
    "creditRisk": selectedProp.prop.mortgageDetails.creditRisk,
    "swapRate": selectedProp.prop.mortgageDetails.swapRate,
    "loanAmount": selectedProp.prop.mortgageDetails.loanAmount,
    "monthlyEMI": selectedProp.prop.mortgageDetails.monthlyEMI,
    "upfront": selectedProp.prop.mortgageDetails.upfront,
    // "discountedCost": 7400,
    "points": 1,
    "percentLTV": selectedProp.prop.mortgageDetails.percentLTV,
    "emailId": "test@nestfuel.com",
    "idealMonthlyEMI": selectedProp.prop.monthlyEMI,
    "label": "Smart Traditional",
    "notes": "Lower interest, high upfront.",
    "totalCost": selectedProp.prop.mortgageDetails.totalCost,
    "emi": selectedProp.prop.mortgageDetails.monthlyEMI,
    // "apr": 5.370101807482719,
    "loan": selectedProp.prop.mortgageDetails.loanAmount,
    "price": selectedProp.prop.mortgageDetails.propertyPrice,
    "insurance": selectedProp.prop.mortgageDetails.insurance,
    "pmi": selectedProp.prop.mortgageDetails.pmi,
    "propTax":selectedProp.prop.mortgageDetails.propTax,
    "downpayment": selectedProp.prop.mortgageDetails.upfront,
    // "monthlyUnsecureTermEMI": 4753,
    // "monthlyEMIPostUnsecureTerm": 4753,
    // "filters": [
    //     {
    //         "label": "points",
    //         "start": 0,
    //         "end": 2,
    //         "steps": 0.1
    //     },
    //     {
    //         "label": "upfront",
    //         "start": 50000,
    //         "end": 64800,
    //         "steps": 100
    //     }
    // ]
}

const propertyDetails = {
  "address":  selectedProp.prop.address,
  "city": selectedProp.prop.city,
  "state":selectedProp.prop.state,
  "zipCode": selectedProp.prop.zipcode,
  "price": selectedProp.prop.price,
  "offerPrice": selectedProp.prop.offerPrice,
  "priceAccuracy": 0,
  "daysOnMarket": 8,
  "beds": 3,
  "baths": 4,
  "url": "https://www.redfin.com/CO/Denver/1333-Elati-St-80204/unit-5/home/112879286",
  "squareFeet":selectedProp.prop.squareFeet,
  "mlsId": selectedProp.prop.mlsId,
  "geoLocation": {
      "lat": selectedProp.prop.geoLocation.lat,
      "lon": selectedProp.prop.geoLocation.lon,
  },
  "thumbnail":selectedProp.prop.thumbnail,
  "images":selectedProp.prop.images,
  "pricePerSqFt": selectedProp.prop.pricePerSqFt,
  "affordableType": selectedProp.prop.affordableType,
  "monthlyEMI": selectedProp.prop.monthlyEMI,
  "mortgageDetails": {
      "propertyPrice": selectedProp.prop.mortgageDetails.propertyPrice,
      "financeType": selectedProp.prop.mortgageDetails.financeType,
      "mortgageType": selectedProp.prop.mortgageDetails.mortgageType,
      "loanTerm": selectedProp.prop.mortgageDetails.loanTerm,
      "interestRate": selectedProp.prop.mortgageDetails.interestRate,
      "creditRisk": selectedProp.prop.mortgageDetails.creditRisk,
      "swapRate": selectedProp.prop.mortgageDetails.swapRate,
      "percentLTV": selectedProp.prop.mortgageDetails.percentLTV,
      "loanAmount": selectedProp.prop.mortgageDetails.loanAmount ,
      "monthlyEMI":selectedProp.prop.mortgageDetails.monthlyEMI,
      "upfront": selectedProp.prop.mortgageDetails.upfront
      ,
      "totalCost": selectedProp.prop.mortgageDetails.totalCost,
      "label": "",
      "closingCost": selectedProp.prop.mortgageDetails.closingCost,
      "pmi": selectedProp.prop.mortgageDetails.pmi,
      "insurance": selectedProp.prop.mortgageDetails.insurance,
      "propTax": selectedProp.prop.mortgageDetails.propTax
  }
}
  console.log(financeOptionDetails,'<===here are the financeDetails')
  console.log("HERE IS THE LOCATION", location.state.propData.data)
  function isValidEmail() {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(!bProfile.emailId || regex.test(bProfile.emailId) === false){
        return false;
    }
  
    return true;
  }
  function isValidate() {
    const regex = /^(\w+\S+)$/;
    if(!isValidEmail() || bProfile.firstName==''||regex.test(bProfile.firstName) === false){
       setWarning('Enter valid details')
        return false;
    }


    return true;
    setWarning(false)
  }
  const postPreApprovalObj ={
    firstName:bProfile.firstName,
    lastName:bProfile.lastName,
    emailId:bProfile.emailId,
    borrowerProfile:bfinancialProfile,
    bProfile:bProfile,
    propertyDetails:propertyDetails,
    financeOptionDetails:financeOptionDetails

  }
  console.log("Here is the payload object===>",postPreApprovalObj)
  const sendPostPreApproval = async () => {
  
    let response = '';

    try {
const apiBodyData = {
  "data": {
      "selectedProperty": {
          "mlsId": selectedProp.prop.mlsId,
          "address": {
              "street": selectedProp.prop.address,
              "city": selectedProp.prop.city,
              "state":selectedProp.prop.state,
              "zipCode": selectedProp.prop.zipcode
          },
          "thumbnail": selectedProp.prop.thumbnail
      },
      "mortgageDetails": {
          "price": selectedProp.prop.price,
          "offerPrice": selectedProp.prop.offerPrice,
          "mortgageType": selectedProp.prop.mortgageDetails.mortgageType,
          "nestFuelFinanceOption": "Yes",
          "closingCost":selectedProp.prop.mortgageDetails.closingCost ,
          "financeType":selectedProp.prop.mortgageDetails.financeType,
          "loanAmount": selectedProp.prop.mortgageDetails.loanAmount,
          "loanTerm": selectedProp.prop.mortgageDetails.loanTerm,
          "percentLTV": selectedProp.prop.mortgageDetails.percentLTV,
          "upfront": selectedProp.prop.mortgageDetails.upfront,
          "totalCost": selectedProp.prop.mortgageDetails.totalCost,
          "interestRate":selectedProp.prop.mortgageDetails.interestRate,
          "apr": selectedProp.prop.mortgageDetails?.apr,
          "propTax": selectedProp.prop.mortgageDetails.propTax,
          "pmi": selectedProp.prop.mortgageDetails.pmi,
          "monthlyEMI": selectedProp.prop.mortgageDetails.monthlyEMI,
          "insurance": selectedProp.prop.mortgageDetails.insurance
      },
      "propertiesViewed": [
          {
            "mlsId": selectedProp.prop.mlsId,
            "address": {
              "street": selectedProp.prop.address,
              "city": selectedProp.prop.city,
              "state":selectedProp.prop.state,
              "zipCode": selectedProp.prop.zipcode
            }
          }
      ]
  }
}

  dispatch(mongoborrowerPostPropertyData(apiBodyData))

      response = await api.postPreApproval(postPreApprovalObj);
      const data = await response;
      // if(_debug === 'development'){
        console.log(data);
    } catch (error) {
       
        console.log(error);
    }
  }
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 
  console.log("location passed",location)
  const len = propViewed?.length
  if(location?.state?.name === 'selected' || location?.state?.name === 'alternative'){
    propViewed.filter((item)=> item.mlsId === selectedProp.prop.mlsId).length < 1
        ? propViewed.push(selectedProp?.prop)
        : console.log(" Selected property already in the array")
  }else{
    propViewed.filter((item)=> item.mlsId === proposedProp.prop.mlsId).length < 1
        ? propViewed.push(proposedProp?.prop)
        : console.log("Proposed property already in the array")
  }
  if(propViewed.length> len) {
    dispatch(borrowerProfile({...bProfile,propertiesViewed:propViewed}))

    dispatch(borrowerPropertyDetail({...bPropertyDetail,propertiesViewed:propViewed}))
  }
console.log("Here is the Prop view===>",propViewed)
console.log("LOCATION.....",location)
  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "changeP":
          navigate("/ShowProperty", { replace: false });
          break;
      case "changeF":
          navigate(-1, { replace: false });
          break;
      case "arrV":
      case "preA":
        // navigate("/NestfuelMessenger",{state:{name:location.state.name,flow :'preapproval'}}, { replace: false });
        dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, propertyShortlisted:true} });
        if(bProfile.emailId =='-' || bProfile.emailId==''){
            setIsValidEmail(false)
            setNavCheck(true)
            console.log("here is HERE")
        }
        
        console.log(isvalidEmail)
        if(isValidate()){
        sendPostPreApproval()

          navigate("/NestfuelMessenger",{state:{propData:location.state.propData.data,flow :'preapproval'}}, { replace: false });
        }
       
      
       
        break;
      default:
        break;
    }
  }

  return(
    isLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
      <React.Fragment><CssBaseline/>
          <Box mt={marginT} ml={marginL} sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
            <Navbar pathname={location.pathname}/>
            <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
              { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlockA warning={warning} setIsValidEmail={setIsValidEmail} isvalidEmail={isvalidEmail} isValidName={isValidName} setIsValidName={setIsValidName} setNavCheck={setNavCheck}/>}
            
              <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <Box sx={{color:'#000000',mt:'16svw',fontSize:'1.4vw', textAlign:'center'}}> </Box>
              <div align='center'>
                <Stack sx={{ mt: '2vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="preA" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Preapprove" type='base' fontSize='24' /></Button>
                </Stack>
                {/* <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="changeP" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Change property" type='base' fontSize='24' /></Button>
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="changeF" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Change financing" type='base' fontSize='24' /></Button>
                </Stack> */}
                {/* <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '12vw' }} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="arrV" className='hexBtnCenter' disabled='true'
                    // onClick={onClickFun}
                    >
                    <HexButton titleText="Arrange viewing" type='disabledNew' fontSize='24' /></Button>
                </Stack> */}

                <Centerbottom navigateForward="NestfuelMessenger" />

              </div>
            
          </Box>
          {/* <RightBlockApply  setFinDetails={setFinanceOptionDetails}  setPropertyDetails={setPropertyDetails} opt={location?.state?.name}/> */}
          <RightBlockDashboardFinanceDefault propData= {location.state.propData.data} />

        </Box>
        </Box>
        </React.Fragment>
      ));
}

    function LeftBlockA (props) {

      const dispatch = useDispatch();
      const { bProfile,
              bProfileStatus,
              bProfileJourney,
              selectedProp
            } = useSelector((state)=>state.borrower);
    
          console.log(props,'<====here are some props')  
      const [fname, setValueF]   = React.useState(bProfile.firstName)
      const [lname, setValueL] = React.useState(bProfile.lastName)
      const [email, setValueE] = React.useState(bProfile.emailId)
      const [error, setError] = React.useState(null);
      //const [lname, setValueL] = React.useState(bProfile.LastName)
      function isValidEmail() {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(!email || regex.test(email) === false){
            setError(
                 "Email is not valid");
                 props.setIsValidEmail(false)
                 props.setNavCheck(false)

            return false;
        }
        setError('Valid')
        props.setIsValidEmail(true)
        props.setNavCheck(true)

        return true;
      }
      console.log(error)
      function isValidNameCheck() {
        const regex = /^(\w+\S+)$/;
        if(!email || regex.test(fname) === false){
            setError(
                 "Email is not valid");
                 props.setIsValidName(false)
                 props.setNavCheck(false)
            return false;
        }
        setError('Valid')
        props.setIsValidName(true)
        props.setNavCheck(true)

        return true;
      }
      console.log(error)

      function onClickFun(e) {
        const buttonId = e.currentTarget.id;
        //const input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
        const input = (e.currentTarget.value)
        //console.log(e);
      

        switch(buttonId) {
          case "fname":
            if(isValidNameCheck()){
              console.log(fname);
              setValueF(input)
          }
            dispatch(borrowerProfile({...bProfile,firstName:input}));
            
            dispatch(borrowerProfileNew({...bProfile,firstName:input}));

            dispatch(borrowerProfileStatus({...bProfileStatus,nameProvided:1}));
            setValueF(input);
            break;
          case "lname":
            dispatch(borrowerProfile({...bProfile,lastName:input}));
            dispatch(borrowerProfileNew({...bProfile,lastName:input}));
            dispatch(borrowerProfileStatus({...bProfileStatus,nameProvided:1}));
            dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, personalName:true} });
            setValueL(input);
            break;
          case "email":
            if(isValidEmail()){
              console.log(email);
              setValueE(input)
          }
            
        
            dispatch(borrowerProfile({...bProfile,emailId:input}));

            dispatch(borrowerProfileNew({...bProfile,emailId:input}));

            dispatch(borrowerProfileStatus({...bProfileStatus,emailProvided:1}));
            setValueE(input);
            break;
          case "ssn":
            dispatch(borrowerProfile({...bProfile,ssn:input}));
            //setValueS(input);
            break;
          default:
            break;
          }
          
      }
      return (
        <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
          boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' ,position:'relative'}}>
            <Box sx={{ mt:'10.2vh' , ml: '7.3vw', width: '26vw',height:'11.25vw' }}> 
                <Typography sx={{color:'#31312C', width:'21vw',
                  fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                    Selected financing {selectedProp?.prop?.address}, {selectedProp?.prop?.city},{selectedProp?.prop?.state}-{selectedProp?.prop?.zipcode}
                </Typography>
                <Typography sx={{color:'#31312C', width:'24vw',mt:'1.7vw',
                  fontSize:'1.35vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                    Complete your profile and start your application today
                </Typography>
            </Box>
            <Box sx={{ mt:'2.24vw' , ml: '7.3vw', width: '27vw',height:'14vw' }}> 
           
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                  <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>First Name :</Typography>  
                  <Input placeholder="First Name" disableUnderline={true} id="fname" value={fname} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{style: { textAlign: 'center' }}} onChange={onClickFun}/>
                  
              </Stack>
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Last Name :</Typography>  
                  <Input placeholder="Last Name" disableUnderline={true} id="lname" value={lname} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{style: { textAlign: 'center' }}} onChange={onClickFun}/>
              </Stack>
              <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between"> 
                    <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Email :</Typography>  
                  <Input placeholder="Email" disableUnderline={true} id="email" value={email} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{style: { textAlign: 'center' }}} onChange={onClickFun}/>
              </Stack>
              {
             props.warning ==false?
             <Typography sx={{color:'red' , ml:'12.7vw'}}>
             </Typography>:
              <Typography sx={{color:'red' ,mt:'1vw', ml:'12.9vw', textAlign:'center', fontFamily:'Roboto'}}>
              {props.warning}
              </Typography>
            }
              {/* <Stack sx={{mt:1}} spacing={0} direction="row" justifyContent="space-between"> 
              <Typography sx={{mt:1,fontSize:'1.35vw', width:'10  vw', justifyContent:'space-between'}} color='#000000'>SSN :</Typography>  
                  <Input placeholder="xxx-xxx-xxx" disableUnderline={true} id="ssn" value='xxx-xxx-xxx' sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"14vw",border:1, borderRadius:'0.4vw'}} 
                        inputProps={{style: { textAlign: 'center' }}} onChange={onClickFun}/>
              </Stack> */}
            </Box>
    
            <LeftbottomA leftbottom={leftbottom}/>
        </Box>
      );
    }

    
function InnerCarousel(props) {
  const {imageArray,numCards,chevron} = props;
  const [active, setActive] = React.useState(0);
  console.log("chevron",chevron)
  return (
    <div className="InnerCarousel">
      <ItemsCarousel
         autoPlay={true}
         infiniteLoop={true}
         gutter={0}
         timeout={1}
         activePosition={"center"}
         chevronWidth={-2}
         disableSwipe={false}
         alwaysShowChevrons={false}
         numberOfCards={numCards}
         slidesToScroll={1}
         outsideChevron={false}
         showSlither={true}
         firstAndLastGutter={true}
         activeItemIndex={active}
        requestToChangeActive={(value) => setActive(value)}
        rightChevron={<ArrowForwardIosIcon sx={{color:"#FFFFFF" , fontSize:'3vw'}}/>}
         leftChevron={<ArrowBackIosIcon    sx={{color:"#FFFFFF" , fontSize:'3vw'}}/>}
      >
      {imageArray.map((item) => 
      <div>
       <Box sx={{ border:1, borderColor:"#FFFFFF", borderRadius: '10px 10px 0px 0px', height:'13.5vw'}} >
       <img src={(typeof(item) ==='undefined' ? nestfuelImage : item)} alt={nestfuelImage} style={{borderRadius: '10px 10px 0px 0px'}} width="100%" height="100%"/>
        </Box>
        </div>)}
      </ItemsCarousel>
    </div>
  );
}

export default StartApplicationDefault ;