import React from "react";
import { Box, CssBaseline, Input, CircularProgress,  Typography, Button, Stack} from "@mui/material";
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import { useNavigate, useLocation } from 'react-router-dom';
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Card, ListGroup, ListGroupItem} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import ItemsCarousel from "react-items-carousel";
import leftbottom from '../../assets/images/bg-10@1x.png'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GoogleMaps from './Googlemaps';
import {  borrowerChoiceE, borrowerfinancialProfile,
          borrowerPostPropertyData,
          calcPropfinOptions, 
          } from "../../actions/borrower";
import { RightBlockCompare, RightBlockCompareSmartChoice} from "./RightBlockCompare";
import { BedsAndBaths, Centerbottom, LeftbottomA } from "./RightBlock";
import { UPDATE_PROFILEJOURNEY, withCommas } from "../../constants";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";
import { rspvw } from "./styles";
function SmartChoice() {
  const { selectedProp,
          proposedProp,
          bChoiceB,
          bChoiceD,
          isLoading,
          bChoiceDSF,
          bChoiceDSF_EMB,
          isEmbFlow,
          bChoiceDSFB,
          bProfileJourney,
          bfinancialProfile,
          isEmployerLoading,
          bPropertyDetail
        } = useSelector((state)=>state.borrower);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [callonce,setCallonce] = React.useState(true)
  var truth = 0 
  const [tTextS,setTTextS]  = React.useState('Smart Traditional')
  const [tTextP,setTTextP]  = React.useState('Smart Traditional')
  const [typeS,setTypeS]    = React.useState("TradP")
  const [typeP,setTypeP]    = React.useState("TradS")
  const [colBS,setColBS]    = React.useState('#00657C')
  const [colTS,setColTS]    = React.useState('#FEFEFE')
  const [colBP,setColBP]    = React.useState('#00657C')
  const [colTP,setColTP]    = React.useState('#FEFEFE')
  const [sbenOn,setSbenOn]  = React.useState(false)
  const [pbenOn,setPbenOn]  = React.useState(false)

  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 
  React.useEffect(()=>{

  },[bChoiceDSF,isEmployerLoading])
  React.useEffect(()=>{
    // switch(isEmbFlow?bChoiceDSF_EMB[proposedProp?.propId][proposedProp?.finOpt]?.label:bChoiceDSF[proposedProp?.propId][proposedProp?.finOpt]?.label)
    switch( bChoiceDSF[proposedProp?.propId][proposedProp?.finOpt]?.label)
    {
      case 'Smart Traditional':
        setColBP('#66A3B0')
        setColTP('#00657C')
        setTTextP('Smart Traditional')
        setTypeP('TradP')
        break
      // case 'Interest Only':
      //   setColBP('#DCC254')
      //   setColTP('#6D602A')
      //   setTTextP('Interest Only')
      //   setTypeP('IOP')
      //   break
      // case 'Pay Points':
      //   setColBP('#233E3E')
      //   setColTP('#243F4F')
      //   setTTextP('Pay Points')
      //  setTypeP('PPP')
      //   break
      // case 'Reduced Initial Monthly':
      //   setColBP('#EF395C')
      //   setColTP('#7C2636')
      //   setTTextP('Reduced Initial Monthly')
      //  setTypeP('DFP')
      //   break
      // case 'Part Buy Part Rent':
      //   setColBP('#DCC254')
      //   setColTP('#6D602A')
      //   setTTextP('Part-Buy Part-Rent')
      //  setTypeP('PBPRP')
      //   break
      // case 'Longer Term':
      //   setColBP('#82C494')
      //   setColTP('#43634B')
      //   setTTextP('Longer Term')
      //  setTypeP('LTP')
      //   break
      case "Employer Benefit":
          setColBP('#82C494')
          setColTP('#43634B')
          setTTextP('Employer Benefit')
          setTypeP('ESP')
          break
      case "Employer Support":
              setColBP('#82C494')
              setColTP('#BFDEC7')
              setTTextP('Employer Benefit')
             setTypeP('ESP')
              break
      default:
        break;
    }
  },[proposedProp?.propId,proposedProp?.finOpt])
  
  React.useEffect(()=>{
    // switch(isEmbFlow?bChoiceDSF_EMB[selectedProp?.propId][selectedProp?.finOpt]?.label:bChoiceDSF[selectedProp?.propId][selectedProp?.finOpt]?.label)
    switch( bChoiceDSF[selectedProp?.propId][selectedProp?.finOpt]?.label)
    {
      case 'Smart Traditional':
      console.log(bChoiceDSF[selectedProp?.propId][selectedProp?.finOpt]?.label)
        setColBS('#66A3B0')
        setColTS('#C9EAE6')
        setTTextS('Smart Traditional')
       setTypeS('TradS')
        break
      // case 'Interest Only':
      //   setColBS('#DCC254')
      //   setColTS('#ECDFA9')
      //   setTTextS('Interest Only')
      //  setTypeS('IOS')
      //   break
      // case 'Pay Points':
      //   setColBS('#C682EC')
      //   setColTS('#A4BFCF')
      //   setTTextS('Pay Points')
      //  setTypeS('PPS')
      //   break
      // case 'Reduced Initial Monthly':
      //   setColBS('#EF395C')
      //   setColTS('#F79BAD')
      //   setTTextS('Reduced Initial Monthly')
      //  setTypeS('DFS')
      //   break
      // case 'Part Buy Part Rent':
      //   setColBS('#DCC254')
      //   setColTS('#ECDFA9')
      //   setTTextS('Part-Buy Part-Rent')
      //  setTypeS('PBPRS')
      //   break
      // case 'Longer Term':
      //   setColBS('#82C494')
      //   setColTS('#BFDEC7')
      //   setTTextS('Longer Term')
      //  setTypeS('LTS')
      //  break
       case "Employer Benefit":
      console.log(bChoiceDSF[selectedProp?.propId][selectedProp?.finOpt]?.label)
        setColBS('#82C494')
        setColTS('#BFDEC7')
        setTTextS('Employer Benefit')
       setTypeS('ESS')
        break
        case "Employer Support":
          console.log(bChoiceDSF[selectedProp?.propId][selectedProp?.finOpt]?.label)
            setColBS('#82C494')
            setColTS('#BFDEC7')
            setTTextS('Employer Benefit')
           setTypeS('ESS')
            break
      default:
      
        break;
    }
  },[selectedProp?.propId,selectedProp?.finOpt,selectedProp])

  React.useEffect(()=>{
    const affordable = bChoiceDSF[proposedProp?.propId][proposedProp?.finOpt].monthlyEMI <= bfinancialProfile.income.monthlyBudget ? true:false
        setPbenOn(affordable)
  },[proposedProp.finOpt,bfinancialProfile])
  React.useEffect(()=>{
    // const affordable = bChoiceDSF[selectedProp.propId][selectedProp.finOpt]?.propPrice <=  bChoiceDSFB?.tradAfford.propPrice ? false:true
    const affordable = bChoiceDSF[selectedProp?.propId][selectedProp?.finOpt].monthlyEMI <= bfinancialProfile.income.monthlyBudget ? true:false
    console.log("Afford ", affordable)
        setSbenOn(affordable)
  },[selectedProp.finOpt,bfinancialProfile])
  React.useEffect(()=>{
if(isEmbFlow){

}
  },[])
  
console.log("location",location)
  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "selected":
         const address = {
          city    : selectedProp.prop.address,
          state   : selectedProp.prop.city,
          street  : selectedProp.prop.state, 
          zipCode : selectedProp.prop.zipcode
         }
         const thumbnail = selectedProp.prop.thumbnail
         const mlsId = selectedProp.prop.mlsId
         const selectedProperty = {...bPropertyDetail.selectedProperty,address:address,thumbnail:thumbnail,mlsId:mlsId}

         const mortgageDetails = {
          apr           : selectedProp.prop.mortgageDetails?.apr || selectedProp.prop.mortgageDetails.interestRate +0.25,
          closingCost   : selectedProp.prop.mortgageDetails.closingCost,
          financeType   : selectedProp.prop.mortgageDetails.financeType,
          insurance     : selectedProp.prop.mortgageDetails.insurance,
          interestRate  : selectedProp.prop.mortgageDetails.interestRate,
          loanAmount    : selectedProp.prop.mortgageDetails.loanAmount,
          loanTerm      : selectedProp.prop.mortgageDetails.loanTerm,
          monthlyEMI    : selectedProp.prop.mortgageDetails.monthlyEMI,
          mortgageType  : selectedProp.prop.mortgageDetails.mortgageType,
          offerPrice    : selectedProp.prop.offerPrice,
          percentLTV    : selectedProp.prop.mortgageDetails.percentLTV,
          pmi           : selectedProp.prop.mortgageDetails.pmi,
          price         : selectedProp.prop.mortgageDetails.propertyPrice,
          propTax       : selectedProp.prop.mortgageDetails.propTax,
          totalCost     : selectedProp.prop.mortgageDetails.totalCost,
          upfront       : selectedProp.prop.mortgageDetails.upfront,
          nestFuelFinanceOption : isEmbFlow?bChoiceDSF_EMB[selectedProp.propId][selectedProp.finOpt].label:bChoiceDSF[selectedProp.propId][selectedProp.finOpt].label,
         }
         const propertyViewed = bPropertyDetail.propertyViewed === undefined ? []:bPropertyDetail.propertyViewed
         propertyViewed.push(selectedProperty)
         dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, financeRate:true} });
          dispatch(borrowerPostPropertyData({...bPropertyDetail,selectedProperty:selectedProperty,
                                                mortgageDetails:mortgageDetails,
                                                propertiesViewed:propertyViewed}));
          navigate("/StartApplication",{state:{name:'selected'}}, { replace: false });
          break;
      case "proposed":
        const _address = {
          city    : proposedProp.prop.address,
          state   : proposedProp.prop.city,
          street  : proposedProp.prop.state, 
          zipCode : proposedProp.prop.zipcode
         }
         const _thumbnail = proposedProp.prop.thumbnail
         const _mlsId = proposedProp.prop.mlsId
         const _selectedProperty = {...bPropertyDetail.selectedProperty,address:_address,thumbnail:_thumbnail,mlsId:_mlsId}

         const _mortgageDetails = {
          apr           : proposedProp.prop.mortgageDetails?.apr || proposedProp.prop.mortgageDetails.interestRate +0.25,
          closingCost   : proposedProp.prop.mortgageDetails.closingCost,
          financeType   : proposedProp.prop.mortgageDetails.financeType,
          insurance     : proposedProp.prop.mortgageDetails.insurance,
          interestRate  : proposedProp.prop.mortgageDetails.interestRate,
          loanAmount    : proposedProp.prop.mortgageDetails.loanAmount,
          loanTerm      : proposedProp.prop.mortgageDetails.loanTerm,
          monthlyEMI    : proposedProp.prop.mortgageDetails.monthlyEMI,
          mortgageType  : proposedProp.prop.mortgageDetails.mortgageType,
          offerPrice    : proposedProp.prop.offerPrice,
          percentLTV    : proposedProp.prop.mortgageDetails.percentLTV,
          pmi           : proposedProp.prop.mortgageDetails.pmi,
          price         : proposedProp.prop.mortgageDetails.propertyPrice,
          propTax       :   proposedProp.prop.mortgageDetails.propTax,
          totalCost     : proposedProp.prop.mortgageDetails.totalCost,
          upfront       : proposedProp.prop.mortgageDetails.upfront,
          nestFuelFinanceOption : isEmbFlow?bChoiceDSF_EMB[proposedProp.propId][proposedProp.finOpt].label:bChoiceDSF[proposedProp.propId][proposedProp.finOpt].label,
         }
         const _propertyViewed = bPropertyDetail.propertyViewed === undefined ? []:bPropertyDetail.propertyViewed
         _propertyViewed.push(_selectedProperty)
        dispatch(borrowerPostPropertyData({...bPropertyDetail,selectedProperty:_selectedProperty,
                                                mortgageDetails:_mortgageDetails,
                                                propertiesViewed:_propertyViewed}));
          navigate("/StartApplication",{state:{name:'proposed'}}, { replace: false });
          break;
      case "adjusP":

        const objNew = [bChoiceD[selectedProp.propId], bChoiceD[proposedProp.propId]]
        dispatch(calcPropfinOptions(objNew,bfinancialProfile,bChoiceB, selectedProp.propId, proposedProp.propId));
        console.log("==clickedhiii")
        //navigate("/BorrowerProfileEMP", { replace: false });
        break;
      default:
        break;
    }
  }

  return(
    isLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
      <React.Fragment> <CssBaseline/>
           <Box mt={marginT} ml={marginL}  sx={{ overflow:'hidden',width: '100%',background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
            <Navbar pathname={location.pathname}/>
            <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
            { location.hash === "#messenger" ? <LeftBlockMsg/> : <LeftBlockA/>}
            
              <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <Typography sx={{color:'#31312C',mt:'11.3vw',fontSize:'1.4vw', textAlign:'center'}}> 
              Select Financing</Typography>
              <div align='center'>
                <Stack sx={{ mt: '2vw', height: '3.5vw', width: '12vw' }} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="selected" className='hexBtnCenter' disabled={!sbenOn}
                    onClick={onClickFun}>
                    <HexButton titleText={tTextS} type={typeS} fontSize='24' /></Button>
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="proposed" className='hexBtnCenter' disabled={!pbenOn}
                    onClick={onClickFun}>
                    <HexButton titleText={tTextP} type={typeP} fontSize='24' />
                    </Button>
                </Stack>
                <Stack sx={{ mt: '2.25vw', height: '3.5vw', width: '6vw' }} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow: 'hidden',textTransform: 'none', minHeight: 0, minWidth: 0, padding: '0.1vw', mt: '0', ml: '0.0vw', width: '5.23vw', height: '6.80vw', border: 0 }} id="adjusP" className='hexBtnCenter'
                    onClick={onClickFun}>
                    <HexButton titleText="Adjust Preferences" type='base' fontSize='24' />
                    </Button>
                </Stack>
                <Centerbottom navigateForward="SmartChoice" />
              </div>
            
          </Box>
          <RightBlockCompareSmartChoice rbops = 'smartChoice'/>
        </Box>
        </Box>
        </React.Fragment>
      ));
}

function RightBlock() {
      const { selectedProp,
              bChoiceH,
              bfinancialProfile
            } = useSelector((state)=>state.borrower);
      const [showMap,setShowMap] = React.useState(-1)
      const dispatch = useDispatch();
      return (
          <React.Fragment>
          <CssBaseline/>
          <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',opacity: 1, width: '50vw',height:'46.75vw' }}>
            <Box m={1.2} sx={{background:'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "99%",height:'18vw' ,border:0, borderRadius:'0.5vw'}}>
                {showMap === 5  
                    ?<GoogleMaps position={{lat: selectedProp?.prop?.geoLocation.lat, lng:selectedProp?.prop?.geoLocation.lon}}
                                    markerInput={[selectedProp?.prop]} wHeight='13.5vw'/> 
                    :<InnerCarousel imageArray={selectedProp?.prop.images} numCards={2}/>
                }
                <Box sx={{ display: 'flex', flexDirection: 'row', width:'inherit', height:"4.5vw"}}> 
                <Typography mt='0.5vw' width='8vw' fontSize='1.1vw' fontWeight='bold' color='black' align='center'>{withCommas(selectedProp?.prop.offerPrice)}</Typography>
                <Box width='16vw'>
                  <Typography fontSize='0.9vw' color='black' align='center'> <Button sx={{minHeight: 0, minWidth: 0}} onClick={()=> {showMap === -1? setShowMap(5) : setShowMap(-1)}} ><LocationOnIcon sx={{color:'#000000'}}/></Button>{selectedProp?.prop.address}</Typography>
                  <Typography sx={{mt:'-1.2vh'}}fontSize='0.9vw' color='black' align='center'> {selectedProp?.prop.city}, {selectedProp?.prop.state} - {selectedProp?.prop.zipcode}</Typography>
                </Box>
                <Box sx={{background:'inherit',width:'24vw'}} >
                  <BedsAndBaths color={'black'}/>
                </Box>
            </Box>  
          </Box>
          <Box m={1.2} sx={{background:'#00657C 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',width: "98%",height:'10.125vw' ,border:0, borderRadius:'0.5vw'}}>     
              <Typography ml='1vw'fontSize='1.67vw' fontWeight={700} color='white' align='left'> Traditional </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', width:'inherit'}}>
              <Box sx={{ml:'2vw',width:'24vw'}}>
                  <Stack mt={0.5} direction="row" justifyContent="space-between">
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> Rate </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> {bChoiceH?.interestRate}% </Typography>
                  </Stack>
                  <Stack mt={0.5} direction="row" justifyContent="space-between">
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> APR </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> {(bChoiceH?.interestRate+0.35).toFixed(3)}% </Typography>
                  </Stack>
                  <Stack mt={0.5} direction="row" justifyContent="space-between">
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> Ownership </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> 100% </Typography>
                  </Stack>
              </Box>
              <Box sx={{ml:'3vw',mr:'1vw',width:'24vw'}}>
                  <Stack mt={0.5} direction="row" justifyContent="space-between">
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> Term </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> {bChoiceH?.loanTerm} years</Typography>
                  </Stack>
                  {/* <Stack mt={0.5} direction="row" justifyContent="space-between">
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> IR Term </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> 3 years </Typography>
                  </Stack> */}
                  <Stack mt={0.5} direction="row" justifyContent="space-between">
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> Home Insurance </Typography>
                      <Typography fontSize='1.0vw' fontWeight={500} color='white' align='left'> {withCommas(Math.ceil(bChoiceH?.propertyPrice/2400))} per month </Typography>
                  </Stack>
                  </Box>
              </Box>
          </Box>
          <Box sx={{ background: "#FFFFFF 0% 0% no-repeat padding-box",width:'100%', height:"21vw",boxShadow: '0px 0px 19px #0000008F'}}>
        <Typography  ml='0.5vw' fontSize='1.4vw' align='left' fontWeight='bold' color='#000000'>Facts & Features</Typography> 
        <Box sx={{display: 'flex', flexDirection: 'row', width:'inherit'}}>
          <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "50%",height:"21vw",p:1,border:0 ,borderRadius:0}}>
          <Typography  ml='0.5vw' fontSize='1.2vw' align='left' fontWeight='bold' color='#000000'>Overview</Typography> 
          <Typography  ml='0.5vw' fontSize='1.vw' align='left' fontWeight={400} color='#000000'>In 1959 when man first landed on the surface
          of moon, we predicted a colony on moons equatorial zone by next decade or two. However, we are not able to achieve anything near to that goal. This is probably our inability to find a const name a new type 
          of force that can propel space crafts with enormouse speed.</Typography> 
          </Box>
          <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "50%",height:"21vw",p:2,border:0 ,borderRadius:0}}>
          
          <Typography  ml='0.5vw' fontSize='1.4vw' align='left' fontWeight='bold' color='#000000'>Parking</Typography> 
          <Stack direction="row" justifyContent="space-around"> 
                  <Typography  fontSize='1vw' color='#000000' width='8vw'textAlign='left'>Total Spaces: </Typography>  
                  <Typography  fontSize='1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>2 </Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1vw' color='#000000' width='8vw'textAlign='left' >Parking Features</Typography>  
              <Typography  fontSize='1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>Parking meters</Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1vw' color='#000000' width='8vw'textAlign='left'>Garage Spaces:</Typography>  
              <Typography  fontSize='1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'> few </Typography>  
          </Stack>
          
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
          <Typography fontSize='1vw' fontWeight='regular' color='#000000'>Covered SPaces</Typography> 
              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>a few</Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1vw' color='#000000' width='8vw'textAlign='left'>Other parking info:</Typography>  
              <Typography  fontSize='1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>No parkinson around </Typography>  
          </Stack>
          </Box>
        </Box>
        </Box>
      </Box>
          </React.Fragment>
        );
    }

    function LeftBlockA () {

      const dispatch = useDispatch();
      const { bChoiceE,
              bChoiceA,
              bfinancialProfile,
              bChoiceDSF,
              selectedProp
            } = useSelector((state)=>state.borrower);
      const [upfront,setUpfront] = React.useState(bChoiceA === "refinance"? bChoiceE?.cashoutAmount : bfinancialProfile.income.upfront)
      const [monthlyBudget,setMonthlyBudget] = React.useState(bfinancialProfile.income.monthlyBudget)
      const [max,setMax] = React.useState(bfinancialProfile.frontEndDTI);
      const [diff,setDiff] = React.useState(Math.round(0.23*selectedProp?.prop.offerPrice))
      const [percentO,setPercentO] = React.useState(bChoiceE.percentOwnership)
      //console.log(bChoiceE)
      function onClickFun(e) {
        const buttonId = e.currentTarget.id;
        const input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
        switch(buttonId) {
          case "monthly":
            setMonthlyBudget(isNaN(input) ? 0 : input<0?0:input)
            const income = {...bfinancialProfile.income,monthlyBudget:input<0?0:input}
            dispatch(borrowerfinancialProfile({...bfinancialProfile,income:income}));
            break;
          case "upfront":
            setUpfront(isNaN(input) ? 0 : input<0?0:input)
            if(bChoiceA === "refinance"){
              const upfront = bChoiceE?.propertyPrice - bChoiceE?.outStandingLoan - bChoiceE?.cashoutAmount
              dispatch(borrowerChoiceE({...bChoiceE,cashoutAmount:input<0?0:input}));
              const income = {...bfinancialProfile.income,upfront:upfront<0?0:upfront}
            dispatch(borrowerfinancialProfile({...bfinancialProfile,income:income}));
            }else{
              const income = {...bfinancialProfile.income,upfront:input}
              dispatch(borrowerfinancialProfile({...bfinancialProfile,income:income}));
            }
           
            break;
          case "ownership":
            setPercentO(isNaN(input) ? 0 : input<0?0:input)
            const financr = {...bfinancialProfile.finance,percentOwnership:input}
            dispatch(borrowerfinancialProfile({...bfinancialProfile,finance:financr}));
            break;
          default:
            break;
          }
      }
      return (
        <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
          boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
            <Box sx={{ mt:'14.7vh' , ml: '7.3vw', width: '26vw',height:'11.25vw' }}> 
            { bChoiceA === "refinance"
              ?<> <Typography sx={{color:'#31312C', width:'26vw',
              fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                Refinancing Tailored for you
            </Typography>
            <Typography sx={{color:'#31312C', width:'26vw',
                  fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                    {selectedProp?.prop?.address}, {selectedProp?.prop?.city} can be Refinanced
                </Typography>

            </>
              : <><Typography sx={{color:'#31312C', width:'26vw',
                  fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                    Financing Tailored for you
                </Typography> 
                 <Typography sx={{color:'#31312C', width:'26vw',
                 fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                   {selectedProp?.prop?.address}, {selectedProp?.prop?.city} is Affordable
               </Typography>
               </>
            }
               
                <Typography sx={{color:'#31312C', width:'24vw',mt:'1.7vw',
                  fontSize:'1.67vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                    Adjust your preferences below
                </Typography>
            </Box>
            <Box sx={{ mt:'4vh' , ml: '7.3vw', width: '27vw',height:'14vw' }}> 
              <Stack sx={{mt:'0.5vw'}}spacing={0} direction="row" justifyContent="space-between"> 
                  <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Monthly Payment :</Typography>  
                  <Input disableUnderline={true} id="monthly" value={withCommas(monthlyBudget)} sx={{backgroundColor:'#FFFFFF',color:"#8F8F8F",fontSize:'1.1vw', height: '2.5vw',width:"13vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{min:0,max:{max},style: { textAlign: 'center' }}}onChange={onClickFun}/>
              </Stack>
              <Stack sx={{mt:'0.5vw'}}spacing={0} direction="row" justifyContent="space-between"> 
              { bChoiceA === "refinance"
                 ? <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'> Cashout Required :</Typography> 
                 : <Typography sx={{mt:1,fontSize:'1.35vw'}} color='#000000'>Upfront Cash/Asset :</Typography> 
              }
                  <Input disableUnderline={true} id="upfront" value={withCommas(upfront)} sx={{backgroundColor:'#FFFFFF',color:"#8F8F8F",fontSize:'1.1vw', height: '2.5vw',width:"13vw", border:1, borderRadius:'0.5vw'}} 
                        inputProps={{style: { textAlign: 'center' }}}onChange={onClickFun}/>
              </Stack>
              { bChoiceDSF[selectedProp.propId][selectedProp.finOpt].label === "Part Buy Part Rent" 
              ? <Stack sx={{mt:'0.5vw', position:'relative'}} spacing={0} direction="row" justifyContent="space-between"> 
              <Typography sx={{mt:1,fontSize:'1.35vw', width:'10  vw', justifyContent:'space-between'}} color='#000000'>Ownership :</Typography>  
                  
                  <Input type="number" disableUnderline={true} id="ownership" value={percentO} sx={{backgroundColor:'#FFFFFF',color:"#8F8F8F",fontSize:'1.1vw', height: '2.5vw',width:"13vw",border:1, borderRadius:'0.5vw'}} 
                        inputProps={{min: 0, max: 100 ,style: { textAlign: 'center',width: '70%', margin:'0 auto' }}}onChange={onClickFun} />
                  <span id="percent-sign" style={{color:"#8F8F8F",fontSize:'1.1vw', top: '0.5vw', right: '1vw', position: 'absolute',zIndex: 1}}>%</span>
              </Stack>
              :<></>
              }
            </Box>
            
            <LeftbottomA leftbottom={leftbottom}/>
        </Box>
      );
    }

    function LeftBlock() {
      const {bChoiceH,bfinancialProfile} = (useSelector((state)=>state.borrower));
      const [dummy,setDummy]= React.useState(bChoiceH);
      const dispatch = useDispatch();
      const navigate = useNavigate();
      return (
        <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
          <Box sx={{ ml:'7vw',mt:'10%' , width:'24vw',background:'inherit', height: '19.7vw' }}>
          <div>
                  <Box sx={{ pt:7, border:0, borderColor:"#000000",borderRadius: 2,  ml:0, mt:"5%" , background:'inherit',width: '25vw', height: '25vh' }}>
                  <Typography sx={{color:'#31312C', width:'20vw',
                    fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                        Nestfuel Smart </Typography>
                        <Typography sx={{color:'#31312C', width:'20vw',mt:1,
                          fontSize:'1.2vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
                        For the selected property</Typography>

                        <Stack mt={2} direction="row" justifyContent="space-between">
                        <Typography fontSize='1.2vw' fontStyle="regular" color='#000000'> Rate Estimate: </Typography>
                        <Typography fontWeight='bold' fontSIze='1.2vw' fontStyle="regular" component='div' color='#000000'> {bChoiceH?.interestRate}%  </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize='1.2vw' fontStyle="regular" component='div' color='#000000'> Upfront Payment:</Typography>
                        <Typography fontWeight='bold' fontSIze='1.2vw' fontStyle="regular" component='div' color='#000000'> {withCommas(bChoiceH.upfront)}  </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize='1.2vw' fontStyle="regular" component='div' color='#000000'> Monthly Installment: </Typography>
                        <Typography fontWeight='bold' fontSIze='1.2vw' fontStyle="regular" component='div' color='#000000'> {withCommas(bChoiceH.monthlyEMI)}  </Typography>
                        </Stack>
                        <Typography fontSize='0.9vw' fontStyle="regular" component='div' color='#000000'> (Fixed for {bChoiceH.loanTerm} years) </Typography>
              
                    { bfinancialProfile?.frontEndDTI > bChoiceH?.monthlyEMI 
                    ? <>
                    <Typography ml={'0%'} mt={2} fontSize='1.5vw' fontStyle="regular" component='div'color='#000000'> 
                        Congratulations!</Typography>
                    <Typography sx={{mt:2,width:'70%'}}  fontSize='1.2vw' fontStyle="regular" color='#000000'> 
                        Apply to lock in the rate below or try Nestfuel SmartMortgage for personalize financing</Typography>
                    </>
                    : <>
                    <Typography ml={'0%'} mt={2} fontSize='1.2vw' fontStyle="regular"color='#000000' align='center'> 
                        You are almost there. </Typography>
                    <Typography  mt={2} width='80%' fontSIze='1.1vw' fontStyle="regular" color='#000000' align='center'> 
                        Your income is low. We can help you find a financing solution </Typography>
                    </>
                  }
                  </Box>
              </div>  
              </Box>
              <LeftbottomA leftbottom={leftbottom}/>
        </Box>
      );
    }

    function ImageTextCard(props){

      const { CardElement,
              propertyPrice, 
              loanAmount,
              upfront,
              monthly,
              interestRate,
              loanTerm } = props; 
              
    return (
    <Card style={{ width: '16vw' }}>
      <Card.Body>
        <Card.Title>
          <Typography height='1.7vw' fontSize='1.1vw' color='#000000' align='center'>Finance Details</Typography>
        <Box sx={{ border:0, borderColor:"#FFFFFF", borderRadius:4, width:"100%", height:"8%"}} >
       <img src={CardElement.thumbnail} width="100%"/>
        </Box>
        </Card.Title>
        <Card.Text>
        <Stack height='0.56vw'spacing={1} direction="row" justifyContent="center">
        <Typography fontSize='0.8vw' color='#000000'>{CardElement.address}</Typography>
        <Typography fontSize='0.8vw' color='#000000'>{CardElement.city}</Typography>
        <Typography fontSize='0.8vw' color='#000000'>{CardElement.zipcode}</Typography>
        </Stack>
        </Card.Text>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>
        <Stack spacing={0} height='2vh' direction="row" justifyContent="space-around">
        <Typography fontSize='1.8vh' color='#707070'> Property Price</Typography>
        <Typography fontSize='1.8vh' fontWeight='bold' color="#000000">{withCommas(propertyPrice)}</Typography>
        </Stack>
        </ListGroupItem>
        <ListGroupItem>
        <Stack height='2vh'spacing={0} direction="row" justifyContent="space-around">
        <Typography fontSize='1.8vh' color='#707070'> Loan Amount</Typography>
        <Typography fontSize='1.8vh' fontWeight='bold' color="#000000">{withCommas(loanAmount)}</Typography>
        </Stack>
        </ListGroupItem>
        <ListGroupItem>
        <Stack height='2vh' spacing={0} direction="row" justifyContent="space-around">
        <Typography fontSize='1.8vh' color='#707070'> Upfront </Typography>
        <Typography fontSize='1.8vh' fontWeight='bold' color="#000000">{withCommas(upfront)}</Typography>
        </Stack>
        </ListGroupItem>
        <ListGroupItem>
        <Stack height='2vh' spacing={0} direction="row" justifyContent="space-around">
        <Typography fontSize='1.8vh' color='#707070'> Monthly </Typography>
        <Typography fontSize='1.8vh' fontWeight='bold' color="#000000">{withCommas(monthly)}</Typography>
        </Stack>
        </ListGroupItem>
        <ListGroupItem>
        <Stack height='2vh' spacing={0} direction="row" justifyContent="space-around">
        <Typography fontSize='1.8vh' color='#707070'> Rate </Typography>
        <Typography fontSize='1.8vh' fontWeight='bold' color="#000000"> {interestRate}%</Typography>
        </Stack>
        </ListGroupItem>
        <ListGroupItem>
        <Stack height='2vh' spacing={0} direction="row" justifyContent="space-around">
        <Typography fontSize='1.8vh' color='#707070'> Loan Term </Typography>
        <Typography fontSize='1.8vh' fontWeight='bold' color="#000000"> {loanTerm} years</Typography>
        </Stack>
        </ListGroupItem>
      </ListGroup>
    </Card>
      );
}

function InnerCarousel(props) {
  const {imageArray,numCards,chevron} = props;
  const [active, setActive] = React.useState(0);
  console.log("chevron",chevron)
  return (
    <div className="InnerCarousel">
      <ItemsCarousel
         autoPlay={true}
         infiniteLoop={true}
         gutter={0}
         timeout={1}
         activePosition={"center"}
         chevronWidth={-2}
         disableSwipe={false}
         alwaysShowChevrons={false}
         numberOfCards={numCards}
         slidesToScroll={1}
         outsideChevron={false}
         showSlither={true}
         firstAndLastGutter={true}
         activeItemIndex={active}
        requestToChangeActive={(value) => setActive(value)}
        rightChevron={<ArrowForwardIosIcon sx={{color:"#FFFFFF" , fontSize:'3vw'}}/>}
         leftChevron={<ArrowBackIosIcon    sx={{color:"#FFFFFF" , fontSize:'3vw'}}/>}
      >
      {imageArray.map((item) => 
      <div>
       <Box sx={{ border:1, borderColor:"#FFFFFF", borderRadius: '10px 10px 0px 0px', height:'13.5vw'}} >
       <img src={(typeof(item) ==='undefined' ? nestfuelImage : item)} alt={nestfuelImage} style={{borderRadius: '10px 10px 0px 0px'}} width="100%" height="100%"/>
        </Box>
        </div>)}
      </ItemsCarousel>
    </div>
  );
}

export default SmartChoice ;