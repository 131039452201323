import React from "react";
import { Box, Stack, CssBaseline, TextField, InputAdornment, IconButton, Typography, Button } from "@mui/material";
import { HexButton } from './ButtonIcons1';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import useStyles from './styles';
import Navbar from "./NavBar";
import { BottomBox } from "./RightBlock";
import { _debug } from "../../constants";

var allowed = "no"

function LegalTerms() {
  const navigate = useNavigate();

  const [seek, setSeek] = React.useState(allowed)
  const classes = useStyles();
  // if(_debug === 'development') allowed = 'yes'
  allowed = 'yes'

  return (
    <React.Fragment><CssBaseline />
      <Box sx={{ overflow:'hidden', width: '100%', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <Navbar />
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <LeftBlockA />

          <Box sx={{ width: '14vw', justifyContent: 'center' }}>
            <Stack sx={{ mr: '2.0vw', ml: '3.0vw', mt: '19vw', height: '10vw', width: '10vw' }} direction="row">
              <Button sx={{overflow:'hidden', mt:0,zIndex:1,border:0,width:'8.0vw',position:'fixed',height:'10vw', minHeight: 0, minWidth: 0,padding: '0.2vw'}}  id="Discover" className='hexBtnCenter'
                onClick={() => allowed === 'yes' ? navigate(-1, { replace: false }) : setSeek("ddd")}>
                <HexButton titleText="CLOSE" type='large' fontSize='24' />
              </Button>
            </Stack>
            {seek == 'ddd' ? <InputKey /> : <></>}
          </Box>

          
          <Box sx={{
        background: '#F3F3F3 0% 0% no-repeat padding-box',
        boxShadow: '-1px -6px 46px #0000003D', p:0,opacity: 1, width: '50vw',borderRadius:'0.5vw'}}>
          
<Box sx={{marginLeft:'4.84vw',marginTop:'7.465vw',width:'39.32vw',height:'34.22vw' ,overflowX:'none',overflowY:'auto','&::-webkit-scrollbar': { width: 0, }}}>
<h3 >Terms of Use </h3>

    <p>No products or services are currently offered directly to the consumers. </p>
      
      
      
    <p align='justify'>These Terms of Use govern your use of this website. Please read these terms and conditions carefully before
       using this website. By accessing or using this website, you agree to be bound by these Terms of Use. If you 
       do not agree to all of the terms, please immediately discontinue accessing this website. </p>
      
      
      
    <h5 align='left'>Content </h5>
      
    <p align='justify'>This website is operated by Nestuel Technologies Inc (“Nestfuel”) and may provide general information about our 
      and/or our subsidiaries’ products and services that maybe available in future. Any products or services displayed 
      on the Website are purely for demonstration purposes only.  </p>
      
      
      
    <p align='justify'>The information is neither intended to be fully comprehensive about potential products and services nor does it make 
      any promise of availability of these products and services in future. The products that are displayed on the website 
      takes into account the information and data provided by you, other third parties and maybe obtained from other available 
      sources. Nestfuel does not warrant the accuracy of the information and is not responsible for the data or the availability 
      of such products to you.  </p>
      
      
      
    <h5 align='left'>Future Products & Services:  </h5>
      
    <p align='justify'>Our first products and services are expected to be launched in Colorado, USA. Any reference to products and services 
    below are intended to imply those that may be available in future.  </p>
      
    <p align='justify'>Your eligibility for these particular products and services shall be subject to our and/or our subsidiaries’ final 
    determination, restrictions, and acceptance. We and/or our subsidiaries may discontinue or make changes to the information, products, 
    licenses, or services described on this website at any time. Any dated information is published as of its publication date only. We 
    and/or our subsidiaries do not undertake any obligation or responsibility to update or amend any such information. We and/or our 
    subsidiaries reserve the right to terminate any or all offerings without prior notice. Furthermore, by offering information, 
    products, or services via this website, no solicitation is made by us and/or our subsidiaries to any person to use such information, 
    products, or services in jurisdictions where the provision of information, products, or services is prohibited by law. </p>
      
     <p align='justify'>This website may contain links to third-party websites, such as social media websites, which we and/or our 
     subsidiaries do not own or control, but which are being providing for your convenience. If you visit a link to another website, 
     you do so at your own risk subject to the terms and conditions established by the operator of that website. The fact that we and/or 
     our subsidiaries provide a link to a website does not necessarily mean we and/or our subsidiaries endorse, authorize, or sponsor 
     that third-party website, or that we are affiliated with the third-party website’s owners or sponsors. We and our subsidiaries reserve 
     the right to terminate a link to a third-party website at any time. </p>
      
     <p align='justify'>Although we and our subsidiaries try to provide accurate and timely information on this website, there may be i
     nadvertent, technical, or factual inaccuracies and typographical errors. For these reasons we and our subsidiaries cannot warrant the 
     accuracy, completeness, or timeliness of the information, text, graphics, links, or other items on this website. </p>
      
     <p align='justify'>This website’s content, including but not limited to all music, images, icons, text, software, logos, expressions, 
     and ideas, is copyrighted and protected by U.S. and worldwide copyright laws and treaty provisions. In addition, this website’s 
     content is protected by patent and trademark laws, the laws of privacy and publicity, and the various communication regulations and 
     statutes. You are not authorized to post on or transmit to or from this website any unlawful, threatening, libelous, defamatory, 
     obscene, scandalous, inflammatory, pornographic, immoral, or profane material, or any other content that could give rise to any civil 
     or criminal liability under the law. </p>
      
     <p align='justify'>Any commercial use of this website or its content is prohibited. You may print a copy of the information contained 
     on this website only for your personal use. This right may be revoked at any time. </p>
      
     <h5 align='left'>Accessing the Website </h5>
      
     <p align='justify'>You agree and acknowledge that you have the sole responsibility and liability for your use of this website and for 
     providing or obtaining, and for maintaining, all of the hardware, software, electrical power, telecommunications, Internet services, 
     and other products or services necessary or desirable for you to access and use this website. </p>
      
     <p align='justify'>From time to time, due to the performance of maintenance, malfunctions or failures of software, equipment, or 
     telecommunications devices, unusual transaction volume, or similar reasons, the website may not be available for your use. We minimize 
     the periods of time during which website is unavailable. You agree that we and our subsidiaries shall not be responsible for any loss, 
     damages, costs, or expenses which you may suffer or incur, directly or indirectly, as a result of the unavailability of the website, 
     regardless of whether it could be shown that we could have prevented or reduced the duration of such unavailability by taking any 
     action within our reasonable control. We and our subsidiaries shall also not be responsible for any loss, damages, costs, or expenses 
     which you may suffer or incur, directly or indirectly, as a result of your inability to access the website caused directly or 
     indirectly, in whole or in part, by your computer or mobile device, your inability to establish a connection to the Internet, 
     your Internet Service Provider (“ISP”), or capacity or other limitations or constraints of the Internet. </p>
      
     <p align='justify'>We may at any time, with or without cause, and without prior notice to you deny you access to the website. We may 
     terminate these Terms of Use and your access to the website in whole or in part at any time without prior notice to you. In the event 
     we terminate this Terms of Use, any applications you have submitted will continue to be evaluated, and any existing transactions 
     you have entered into with us shall remain in effect. </p>
      
     <p align='justify'>The website is not intended for use by persons in any jurisdiction or country where such use would be contrary to 
     applicable laws or regulations. We may restrict your access to the website during times you are in a country for which use of the 
     website would be prohibited. You are responsible for compliance with all local laws. </p>
      
     <p align='justify'>To protect the security of your information, we and/or our subsidiaries may require you to authenticate your 
     identity (i.e., prove that you are who you say you are) to conduct certain transactions on this website. It is a good idea to 
     protect your security by always closing your web browser after leaving the website. If you believe that information that you have 
     submitted through the website has been used without your permission, you must tell us immediately. Telephoning us is the best way of 
     keeping your possible losses to a minimum. Call us at 650-460-5237 to report unauthorized access. If you fail to notify us, you may 
     be liable for all unauthorized activity on your account. </p>
      
     <p align='justify'>You can also contact us at 650-460-5237 regarding technical issues with this website.</p> 
      
      
      
    <h5 align='left'>Your Information </h5>
      
    <p align='justify'>During the process of your mortgage application you may submit certain information to us. We do not claim ownership
     of the materials you provide (including feedback and suggestions), post, upload, input, or submit to Nestfuel Realty LLC or 
     any of its affiliated businesses (collectively “Your Information”). However, by posting, uploading, inputting, providing, 
     or submitting Your Information, you are granting us, our affiliated companies, and any vendors necessary to the course of 
     your transaction permission to use Your Information to fulfill your request for services. That permission includes allowing 
     us to copy, distribute, transmit, publicly display (we will never publicly display your non-public personal information), 
     reproduce, edit, translate, and reformat Your Information. Please note that specific terms and conditions provided on the web 
     page describing a particular feature or offer, may supersede this provision. </p>
      
      
      
     <h5 align='left'>Consent to Employment Verification</h5> 
      
    <p align='justify'>By providing us with your employment information you are providing written consent permitting us and/or our 
    subsidiaries to contact third parties as necessary to verify your income and employment. </p>
      
      
      
    <h5 align='left'>Copyright Violations </h5>
      
    <p align='justify'>Any information published on this Website is to assist and help consumers and if you believe you have propriety 
    ownership of any published work, that you do not intend to publish such information or if you believe that your copyright-protected 
    work was posted to the Services without authorization, please notify us immediately at legal@nestfuel.com. You can also contact us 
    at 650-460-5237 to report such matters.  </p>
      
    <h5 align='left'>Third Party Websites </h5>
      
    <p align='justify'>You acknowledge that we have no control over, and no liability for any third party websites or materials, including 
    websites and materials that you may access from links on our Websites, including any Social Networks and websites operated by 
    third-party service providers that we engage to assist us in providing the Services. Nestfuel makes no guarantees about the accuracy, 
    currency, content, or quality of the information provided by such websites, and Nestfuel assumes no responsibility for unintended, 
    objectionable, inaccurate, misleading, or unlawful content that may reside on those websites. </p>
      
      
      
    <h5 align='left'>Limitations of Liability </h5>
      
    <p align='justify'>UNDER NO CIRCUMSTANCES SHALL WE OR OUR SUBSIDIARIES BE LIABLE FOR ANY DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, OR 
    CONSEQUENTIAL DAMAGES THAT MAY ARISE IN CONNECTION WITH THIS WEBSITE, OR FROM YOUR USE OF, OR INABILITY TO USE, THIS WEBSITE OR ANY 
    INFORMATION PROVIDED ON THIS WEBSITE; OR IN CONNECTION WITH ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY 
    IN OPERATION OR TRANSMISSION, COMPUTER VIRUS OR LINE OR SYSTEM FAILURE; OR DISCLOSURE OF INFORMATION WHEN REPLYING TO YOU BY E-MAIL 
    OR OTHER ELECTRONIC MEANS OR RECEIVING E-MAILS FROM YOU; EVEN IF WE AND/OR OUR SUBSIDIARIES ARE ADVISED OF THE POSSIBILITY OF SUCH 
    DAMAGES, LOSSES, OR EXPENSES. Some jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental 
    damages. In such jurisdictions, our liability is limited to the greatest extent permitted by law. </p>
      
      
      
    <p align='justify'>THIS WEBSITE CONTENT IS PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, 
    BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. </p>
      
      
      
    <p align='justify'>WE AND OUR SUBSIDIARIES ASSUME NO RESPONSIBILITY, AND SHALL NOT BE LIABLE FOR, ANY VIRUSES THAT MAY INFECT OR DAMAGE 
    YOUR COMPUTER EQUIPMENT OR OTHER PROPERTY AS A RESULT OF YOUR ACCESS TO, USE OF, OR BROWSING OF THIS WEBSITE, THE WEB, OR YOUR 
    DOWNLOADING OF ANY MATERIALS, DATA, TEXT, IMAGES, VIDEO, OR AUDIO FROM THIS WEBSITE OR THE WEB. </p>
      
      
      
    <p align='justify'>WE AND OUR SUBSIDIARIES DO NOT WARRANT NOR REPRESENT THAT YOUR USE OF MATERIALS DISPLAYED ON THIS WEBSITE WILL NOT 
    INFRINGE RIGHTS OF THIRD PARTIES NOT OWNED OR AFFILIATED WITH US. </p>
      
      
      
    <p align='justify'>You agree to indemnify and hold us and/or our subsidiaries harmless from and against any and all claims, losses, 
    expenses, demands, or liabilities, including attorneys’ fees and costs, incurred by us in connection with any claim by a third party 
    (including any intellectual property claim) arising out of your use of the website in violation of these Terms of Use or in violation 
    of any applicable law. You further agree that you will cooperate fully in the defense of any such claims. We and our subsidiaries 
    reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification 
    by you, and you shall not in any event settle any such claim or matter without our written consent. </p>
      
      
      
    <h5 align='left'>Other Terms & Conditions </h5>
      
    <p align='justify'>These Terms of Use will be governed by the laws of the United States and the State of California without giving 
    effect to any conflict of laws to the contrary. </p>
      
      
      
    <p align='justify'>No failure, omission, or delay on our part to exercise any right under these Terms of Use will preclude any other 
    further exercise of that right or other right under these Terms of Use. </p>
      
      
      
    <p align='justify'>If any terms in these Terms of Use change due to applicable law or are declared invalid by order of a court, the 
    remaining terms of these Terms of Use will not be affected, and these Terms of Use will be interpreted as if the invalid terms had 
    not been placed in these Terms of Use. The headings in these Terms of Use are intended only to help organize these Terms of Use. </p>
      
      
      
    <h5 align='left'>Changes to these Terms of Use </h5>
      
    <p align='justify'>You agree that we may from time to time amend all or any part of these Terms of Use, including, without limitation, 
    establishing, increasing, or decreasing the fees and charges for products and services made available through this website and changing
     the features and functionality to this website. We will notify you electronically of any change to these Terms of Use as required by 
     applicable law. Your use of the website after the effective date of the change shall constitute your agreement to be bound by the 
     terms of the change. You should also review these Terms of Use periodically for any changes. </p>
     
</Box>

   </Box>

        
        </Box>




<Box className={classes.bottomBar}><BottomBox/></Box>

      </Box>
    </React.Fragment>
  );
}


  function LeftBlockA() {
    return (
     
  
      <Box sx={{
        background: '#F3F3F3 0% 0% no-repeat padding-box',
        boxShadow: '1px 6px 46px #0000003D', opacity: 1, width: '36vw', height: '45vw'
      }}>
    
          <Box sx={{marginLeft:'6.72vw',marginTop:'7.76vw',width:'18.89vw',height:'12.60vw',fontsize:'4.64vw',font:'Roboto'}}>
          <Typography sx={{color:'#8f8f8f', fontWeight:'bold',
                  font: "Roboto", textAlign:'left'}} fontSize='4.6vw' lineHeight={1.2} letterSpacing='-1.6px'> 
                Terms of use
                  </Typography>
          </Box>
       

  
          <Box sx={{marginLeft:'7.34vw',marginTop:'6.51vw',width:'24.01vw',height:'12.60vw',fontsize:'4.64vw',font:'Roboto'}}>
          <Typography sx={{color:'#8f8f8f', fontWeight:'bold',
                  font: "Roboto", textAlign:'left'}} fontSize='2.08vw' lineHeight={1.2} letterSpacing='-1.84px'> 
                No products or services 
                  </Typography>
                  <Typography sx={{color:'#8f8f8f', fontWeight:'bold',
                  font: "Roboto", textAlign:'left'}} fontSize='2.08vw' lineHeight={3} letterSpacing='-1.84px'> 
                 are currently offered directly 
                  </Typography>
                  <Typography sx={{color:'#8f8f8f', fontWeight:'bold',
                  font: "Roboto", textAlign:'left'}} fontSize='2.08vw' lineHeight={1.2} letterSpacing='-1.84px'> 
                 to the consumers.
                  </Typography>
          </Box>
  
      </Box>
      );
    }







function InputKey() {

  const name = 'password'
  const type = 'password'
  const [cur, setCur] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);


  function onClickFun(e) {
    const val1 = e.currentTarget.value
    val1==="N3$tfu3L"? allowed = 'yes' : allowed = 'no'

  };

  return (
    <div position='fixed' align='center'>
      <Typography sx={{ fontSize: '1vw', width: '12vw' }}>(Please answer the following to activae DISCOVER NESTFUEL)</Typography>
      <Typography sx={{ mt: 2, fontSize: '1vw', width: '12vw' }}></Typography>
      <TextField sx={{ fontsize: '0.5vw', mt: 1, width: '12vw', border: 1, borderColor: 'black', borderRadius: 2 }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label='No flying ends Indian penny'
        autoFocus={true}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </div>
  )
}

export default LegalTerms;