import React from "react";
import { useNavigate } from "react-router-dom";
// material-ui
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  CssBaseline, InputAdornment, IconButton,Divider
} from '@mui/material';
import { HexButton } from './ButtonIcons1';
import { getWindowDimensions } from '../../computations/helperFunctions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import leftbottom from '../../assets/svg/assets svg/image-leftbottom02.png'
import mask_group from '../../assets/staticPageAssets/MaskGroup107.png'
import bullet from '../../assets/staticPageAssets/Polygon.svg'
import useStyles from './styles';
import { BottomBox, LeftbottomA } from "./RightBlock";
import { _debug } from "../../constants";
import { border, fontWeight, width } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex";
import NavBarDashBoardUpdate from "./NavBarDashBoardUpdate";
import NavBarBreadCrumbUpdate from "./NavBarBreadCrumbUpdate";
import AnalyticEcommerce from './AnalyticEcommerce';
//import IncomeAreaChart from './IncomeAreaChart';
//import MonthlyBarChart from './MonthlyBarChart';
import { Bar,Line } from "react-chartjs-2";
import MainCard from './MainCard';
import "chartjs-plugin-datalabels";
import person from '../../assets/svg/person.svg'
import dollarsolid from '../../assets/svg/dollar-solid.svg'
import briefcasefill from '../../assets/svg/briefcase-fill.svg'
import graduationcap from '../../assets/svg/graduation-cap.svg'
import { rspvw } from "./styles";
import { breadcrumbData} from "../../actions/borrower";
import { useDispatch, useSelector } from 'react-redux';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,PointElement,
  Title,
  Tooltip,
  Legend,  LineElement

} from 'chart.js';
var allowed = "no"


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,  LineElement,

  Legend,PointElement
);




function EmployerDashBoard() {
  const navigate = useNavigate();
  const dispatch =useDispatch();
  const { height, width } = getWindowDimensions();
  const [seek, setSeek] = React.useState(allowed);
  dispatch(breadcrumbData("Your Dashboard"));
  const [hexaText1, setHexaText1] = React.useState('Investors')
  const [hexaText2, setHexaText2] = React.useState('Technology Partners')
  const [hexaText3, setHexaText3] = React.useState('Employers')
  const [hexaText4, setHexaText4] = React.useState('Mortgage Industry')
 
  
  const [value, setValue] = React.useState('today');
  const [slot, setSlot] = React.useState('week');
  // if(_debug === 'development') allowed = 'yes'
  allowed = 'yes'


  const state = {
    labels: ['January', 'February', 'March',
             'April', 'May'],
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [65, 59, 80, 81, 56]
      }
    ]
  }



  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "March 2023",
        data: [5, 10, 20, 25, 30, 35],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
    //   {
    //     label: "Second dataset",
    //     data: [33, 25, 35, 51, 54, 76],
    //     fill: false,
    //     borderColor: "#742774"
    //   }
    ]
  };


  const dataSecond = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "March 2023",
        data: [4500, 3500, 5500, 6500, 7100, 8000],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
    //   {
    //     label: "Second dataset",
    //     data: [33, 25, 35, 51, 54, 76],
    //     fill: false,
    //     borderColor: "#742774"
    //   }
    ]
  };

  const dataThird = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "March 2023",
        data: [5, 10, 7, 9, 14, 18],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
    //   {
    //     label: "Second dataset",
    //     data: [33, 25, 35, 51, 54, 76],
    //     fill: false,
    //     borderColor: "#742774"
    //   }
    ]
  };

  const dataFourth = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "March 2023",
        data: [20, 15, 5, 4, 19, 1],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
    //   {
    //     label: "Second dataset",
    //     data: [33, 25, 35, 51, 54, 76],
    //     fill: false,
    //     borderColor: "#742774"
    //   }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };
  
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  
  //  const data = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'Dataset 1',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //     },
  //     {
  //       label: 'Dataset 2',
  //       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
  //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //     },
  //   ],
  // };




  return (
    <React.Fragment><CssBaseline />
      <Box sx={{ overflow: 'hidden', width: '100%', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <NavBarDashBoardUpdate />
        <NavBarBreadCrumbUpdate />
       

        <Box sx={{ml:'7.34vw',mr:'7.34vw',mt:'4vh',mb:'4vh'}} >
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {/* row 1 */}
            <Grid item xs={12} sx={{ mb: -2.25 }}>
                <Typography variant="h5" sx={{ml:'0.34vw',mr:'7.34vw',mt:'4vh',mb:'0.2vh'}}>Overview</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} >
                <AnalyticEcommerce title="Total Size of Team" count="35" image={person} percentage={59.3} extra="35,000" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <AnalyticEcommerce title="Annual Cost" count="$8,000" image={dollarsolid} percentage={70.5} extra="8,900" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <AnalyticEcommerce title="Tasks" count="17" image={briefcasefill} percentage={27.4} isLoss color="warning" extra="1,943" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <AnalyticEcommerce title="Learning in Month" count="20 hrs" image={graduationcap} percentage={27.4} isLoss color="warning" extra="$20,395" />
           
                {/* <Box style={{flexDirection: 'row', alignItems: 'flex-start'}}>
    <Typography style={{fontSize: 20, lineHeight: 30}}>10</Typography>
    <Typography style={{fontSize: 11, lineHeight: 18}}>am</Typography>
</Box> */}
            </Grid>
            
            <Grid item md={8} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

            <Grid   sx={{ border:0.0, mt: '2.07vh', width: '19.67vw',ml:'1.66vw',height: '48.26vh'}}>
                <MainCard  content={false} sx={{ border:0.5, height: '50vh'}}>
                    <Box sx={{ p: 3, pb: 0 }}>
                        <Stack spacing={2}>
                            <Typography variant="h7" color="textSecondary">
                                Satisfaction Score
                            </Typography>
                            <Divider sx={{
                border
                    : '1px solid #DEDEDE', opacity: 1
            }} />
                           
                        </Stack>
                    </Box>
                    {/* <Bar
          data={state}
          options={{
            title:{
              display:true,
              text:'Average Rainfall per month',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        /> */}

<Box sx={{ height: '38vh'}}>
<Line data={data} options={{ maintainAspectRatio: false }} style={{ height: "0vh", width: '100%' }}/>
</Box>
                    {/* <MonthlyBarChart /> */}
                </MainCard>
            </Grid>

            <Grid   sx={{ border:0.0, mt: '2.07vh', width: '19.67vw',ml:'1.66vw',height: '48.26vh'}}>
                <MainCard  content={false} sx={{ border:0.5, height: '50vh'}}>
                    <Box sx={{ p: 3, pb: 0 }}>
                        <Stack spacing={2}>
                            <Typography variant="h7" color="textSecondary">
                                Monthly Cost
                            </Typography>
                            <Divider sx={{
                border
                    : '1px solid #DEDEDE', opacity: 1
            }} />
                        </Stack>
                    </Box>
                    {/* <Bar
          data={state}
          options={{
            title:{
              display:true,
              text:'Average Rainfall per month',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        /> */}
<Box sx={{ height: '38vh'}}>
<Line data={dataSecond} options={{ maintainAspectRatio: false }} style={{ height: "0vh", width: '100%' }}/>
</Box>
                    {/* <MonthlyBarChart /> */}
                </MainCard>
            </Grid>
            <Grid   sx={{ border:0.0, mt: '2.07vh', width: '19.67vw',ml:'1.66vw',height: '48.26vh'}}>
                <MainCard  content={false} sx={{ border:0.5, height: '50vh'}}>
                    <Box sx={{ p: 3, pb: 0 }}>
                        <Stack spacing={2}>
                            <Typography variant="h7" color="textSecondary">
                                Nestfuel Mortgage Utilization
                            </Typography>
                            <Divider sx={{
                border
                    : '1px solid #DEDEDE', opacity: 1
            }} />
                        </Stack>
                    </Box>
                    {/* <Bar
          data={state}
          options={{
            title:{
              display:true,
              text:'Average Rainfall per month',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        /> */}
        <Box sx={{ height: '38vh'}}>
        <Line data={dataThird} options={{ maintainAspectRatio: false }} style={{ height: "35vh", width: '100%' }}/>
        </Box>
                    {/* <MonthlyBarChart /> */}
                </MainCard>
            </Grid>
            <Grid   sx={{ border:0.0, mt: '2.07vh', width: '19.67vw',ml:'1.66vw',height: '48.26vh'}}>
                <MainCard  content={false} sx={{ border:0.5, height: '50vh'}}>
                    <Box sx={{ p: 3, pb: 0 }} >
                        <Stack spacing={2}>
                            <Typography variant="h7" color="textSecondary">
                                Monthly Learning Hours
                            </Typography>
                            <Divider sx={{
                border
                    : '1px solid #DEDEDE', opacity: 1
            }} />
                        </Stack>
                    </Box>
                    {/* <Bar
          data={state}
          options={{
            title:{
              display:true,
              text:'Average Rainfall per month',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        /> */}
<Box sx={{ height: '38vh'}}>
        <Line data={dataFourth} options={{ maintainAspectRatio: false }} style={{ height: "35vh", width: '100%' }}/>
        </Box>         
                </MainCard>
            </Grid>
          
        </Grid>
     
</Box>

   
      </Box>
    </React.Fragment>
  );
}

function InputKey() {

  const name = 'password'
  const type = 'password'
  const [cur, setCur] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);

  function onClickFun(e) {
    const val1 = e.currentTarget.value
    val1 === "N3$tfu3L" ? allowed = 'yes' : allowed = 'no'


  };

  return (
    <div position='fixed' align='center'>
      <Typography sx={{ fontSize: '1vw', width: '12vw' }}>Please answer the following to activae DISCOVER NESTFUEL (Hint:Army man of the Raj earns working days by a week 7-6-3)</Typography>
      <Typography sx={{ mt: 2, fontSize: '1vw', width: '12vw' }}></Typography>
      <TextField sx={{ fontsize: '0.5vw', mt: 1, width: '12vw', border: 1, borderColor: 'black', borderRadius: 2 }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label
        autoFocus={true}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </div>
  )
}

export default EmployerDashBoard;