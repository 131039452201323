import PropTypes from 'prop-types';

// material-ui
import { Box, Chip, Grid, Stack, Typography, Divider, IconButton, Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
//import person from '../../assets/svg/person.svg'
// project import
import MainCard from './MainCard';

// assets
//import { RiseOutlined, FallOutlined } from '@ant-design/icons';
import OutlinedInput from '@mui/material/OutlinedInput';
import { width } from '@mui/system';

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const AnalyticEcommerce = ({ color, title, count, image, percentage, isLoss, extra }) => (
    <MainCard contentSX={{ p: 2.25,elevation: 10,border:0 , width:'13.68vw' , height:'13vw'}} >
        <Stack spacing={0.5}  >
            <Typography sx={{fontWeight:'Regular', fontSize:'2.04vh', textTransform: 'none', font:'Roboto', color:'#31312C',ml:'-0.1vh'}}>
                {title}
            </Typography>
            <Divider sx={{borderBottomWidth: 2,ml:'0.1vw',mr:'2.34vw', border
                    : '0.5px solid #DEDEDE'}} />

        </Stack>
        <Stack spacing={0} direction="row" justifyContent="space-between" sx={{ mt:"1.5vw",mb:"" }}>
            <Box sx={{ mt: '2.25vw', mr: '12vw',width:'2vw'}}>

                <Typography sx={{width:'5vw',
                    mt: '3vw', fontWeight: 'Bold', fontSize: '1.5vw', font: 'Roboto', color: '#31312C', ml: '0.1vw',
                }}>
                   {count}
                </Typography>
            </Box>
            <Box sx={{ mt: '5vw', ml: '0vw', width: '1.10vw', height: '2.60vw' }}>
                <img src={image} height='100%'></img>
            </Box>
        </Stack>

    </MainCard>
);

AnalyticEcommerce.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    count: PropTypes.string,
    image: PropTypes.string,
    percentage: PropTypes.number,
    isLoss: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

AnalyticEcommerce.defaultProps = {
    color: 'primary'
};

export default AnalyticEcommerce;
