import React from "react";
import { Box, CssBaseline, Stack, CircularProgress, Typography, Button,Input} from "@mui/material";
import { useDebounce,useDebouncedCallback } from 'use-lodash-debounce'
import bedicon from '../../assets/svg/bed-svgrepo-com.svg';
import bathicon from '../../assets/svg/shower-head-svgrepo-com.svg'
import garageicon from '../../assets/svg/garage-svgrepo-com.svg'
import Navbar from "./NavBar";
import { HexButton } from './ButtonIcons1';
import ItemsCarousel from "react-items-carousel";
import nestfuelImage from '../../assets/svg/nestfuel.svg';
import nestfuelHomeImageOne         from "../../assets/svg/home-preview-one.svg";
import nestfuelHomeImageTwo         from "../../assets/svg/home-preview-two.svg";
import nestfuelHomeImageThree       from "../../assets/svg/home-preview-three.svg";
import {Link, useNavigate, useLocation } from 'react-router-dom';
import GoogleMaps from './Googlemaps';
import leftbottom from '../../assets/svg/assets svg/image-leftbottom05.png'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { getAnalytics, logEvent } from "firebase/analytics";
import {  useDispatch, 
          useSelector } from 'react-redux';
import {  borrowerChoiceD, borrowerChoiceE, borrowerChoiceH, borrowerfinancialProfile, 
          borrowerPostFinanceData, 
          borrowerProfileStatus,
          borrowerPropertyDetail, 
          calcPropfinOptions, 
          calcPropfinOptionsA,
          calcPropfinOptionsEmpA,
          updateBorrowerFlow,
          updateEmbFlow} from "../../actions/borrower";
import { Centerbottom, LeftbottomA } from "./RightBlock";
import { withCommas, _debug, withCommasWithoutCurrency, SELPROP, MODIFY_D, UPDATE_PROFILEJOURNEY, UPDATE_ProfileS, PROPCHANGE, UPDATE_D, UPDATE_E, UPDATE_DSFB, PROPROP } from "../../constants";
import { useWindowDimensions } from "../../computations/helperFunctions";
import { LeftBlockMsg } from "./Messenger";

import leftArrow from '../../assets/svg/left-arrow.svg'
import rightArrow from '../../assets/svg/right-arrow.svg'
import { beforeAuthStateChanged } from "firebase/auth";
import { rspvw } from "./styles";
export function PersonalizeRate() {
  const location = useLocation();

  const { bChoiceD,
          bChoiceA,
          bChoiceB,
          bChoiceC,
          bChoiceE,
          bfinancialProfile,
          bPropertyDetail,
          bChoiceDSFB,
          selectedProp,
          rates,
          bChoiceD1,
          bChoiceL,
          bProfileStatus,
          isEmbFlow,
          isIncomeChanged,
          } = useSelector((state)=>state.borrower);

          const {bChoiceDSF,isProfileInfoChanged,isLocationChanged,isIncomeSavingChanged,
            isEmployerLoading,isBchoiceDLoading,isLoading
            } = useSelector((state)=>state.borrower);
            console.log(location)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const [marginT,setMarginT] = React.useState('0px')
  const [marginL,setMarginL] = React.useState('0px')
  const [propch,setPropch ] = React.useState(false);
//   var newArr1=[]
//   console.log(bChoiceDSF,"here is bChoiceDsf")
//   for (let i = 0; i < bChoiceDSF.length; i++) {
//     var newArr = bChoiceDSF[i].filter((val) => {
//       return val.label == "Smart Traditional" || val.label == "Employer Benefit" 
//     })
//     newArr = newArr.map((item, index) => {
//       if (item.label == "Employer Benefit") {
//         item.benefits = Math.ceil(((newArr[0].emi * newArr[0].loanTerm * 12) - newArr[0].loanAmount) - ((item.initialMonthlyEMI * item.loanTerm * 12) - item.loanAmount))
//         return item;
//       }
//       return item;
// })
// console.log("BENEfits check",JSON.stringify(newArr))
// // conole.log("Here is array")
// newArr1[i]=newArr
//   }
  console.log(location.state)
  React.useEffect(()=>{
    const calc1 = Math.ceil(height*0.5 - 0.28125*width)
    const calc2 = Math.ceil(width*0.5 - 1.78*height)
    setMarginT(String(calc1 >=0?calc1:0).concat('px'))
    setMarginL(String(calc2 >=0?calc2:0).concat('px'))
  
  },[height,width]) 


  // React.useEffect(()=>{
  //   dispatch(calcPropfinOptionsEmpA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE,bChoiceL))

  //   const newbChoiceD =  bChoiceD.filter((item)=> { return item.offerPrice <= bChoiceDSFB?.extendedAfford?.propPrice})
  //  console.log(newbChoiceD.length)

  //     const median = Math.floor((newbChoiceD.length + 1)/2)
  //     // if(_debug === 'development') console.log("Median property",median,newbChoiceD.length)  
  //     dispatch({ type: MODIFY_D, payload: newbChoiceD });
  //     // dispatch({ type: SELPROP, payload: {prop:newbChoiceD[median],propId:median,finOpt:0} });
  //     // dispatch({ type: PROPROP, payload: {prop:newbChoiceD[median+1],propId:median+1,finOpt:0} });
  //     // dispatch(calcPropfinOptionsA(newbChoiceD,bfinancialProfile,bChoiceB,bChoiceE))

  //     // dispatch(borrowerPostFinanceData(bfinancialProfile))
    
  // },[])
  React.useEffect(()=>{
    dispatch(updateBorrowerFlow(true))
    dispatch(updateEmbFlow(false))
  },[])
  React.useEffect(()=>{
    const propFinRequest = {
      propertyPrice    : bChoiceD[0]?.offerPrice,
      financeType      : (bChoiceB === "firsthome" || bChoiceB === "nexthome")? 'primary' :(bChoiceB === 'investment')? 'investment' : 'secondary',
      loanTerm         : bfinancialProfile?.finance.loanTerm,
      creditEvents     : bfinancialProfile?.finance.creditEvents,
      houseEvents      : bfinancialProfile?.finance.houseEvents,
      monthlyBudget    : bfinancialProfile?.income.monthlyBudget,
      upfront          : bfinancialProfile?.income.upfront,
      creditScore      : bfinancialProfile?.income.ficoScore,
      annualIncome     : bfinancialProfile?.income.annualIncome,
      monthlyOutgoing  : bfinancialProfile?.income.outgoing,
      financeDocs      : bfinancialProfile?.financeDocs.docsAll
  }
  dispatch(borrowerChoiceH(propFinRequest));
  },[])

  const [comp,setComp] = React.useState(0)
  const [dstate,setDstate] = React.useState(0)

  const bChoiceCAssumed= {
    "country": "United States",
    "county": "County",
    "locality": "Denver County",
    "name": "Lincoln Street",
    "position": {
        "latitude": 39.7320529,
        "longiitude": -104.9883101
    },
    "region": "Colorado",
    "zipcode": 80012,
    "type": "home"
}
// const finType  =  bChoiceB === ("firsthome" || bChoiceB === 'nexthome' )? 'primary' :(bChoiceB === "investment") ?'investment': 'secondary'
// // const propertyAddress = bChoiceC.name;
// const annualIncome = typeof bfinancialProfile.income.annualIncome === 'string' 
//   ? parseInt(bfinancialProfile.income.annualIncome.replace(/\$|,/g, ''))
//   : parseInt(bfinancialProfile.income.annualIncome) 
// const cashAvailable = typeof bfinancialProfile.income.upfront == 'string'
//   ? parseInt(bfinancialProfile.income.upfront.replace(/\$|,/g, ''))
//   : parseInt(bfinancialProfile.income.upfront)

  React.useEffect(()=>{
  
   dispatch(borrowerChoiceD( bChoiceC.name,'purchase',
                             bChoiceC.position?.latitude,
                             bChoiceC.position?.longiitude,
                             bChoiceC.locality,
                             bfinancialProfile?.income?.upfront,
                             bfinancialProfile.income.annualIncome,
                             '00','primary'))

  setDstate(1)
    if(bChoiceDSFB.length==0){
      dispatch(calcPropfinOptionsA(bChoiceD1,bfinancialProfile,bChoiceB))
    }
    if(bChoiceDSF.length==0){
      dispatch(calcPropfinOptions(bChoiceD,bfinancialProfile,bChoiceB))
    }
    

  },[])
 
  const analytics = getAnalytics();
  logEvent(analytics, 'showprpperty', { name: 'showproperty'});
  function onClickFun(e){
    e.preventDefault();
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "Income & Savings":
        navigate("/ShowPropertyBorrower", { replace: false })
        break;
      case "CreditHistory": 
      // dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile)); 
      // dispatch(calcPropfinOptions(bChoiceD,bfinancialProfile,bChoiceB));



        if(bChoiceA === "refinance") {
          const upfront = bChoiceE?.propertyPrice - bChoiceE?.outStandingLoan - bChoiceE?.cashoutAmount
          const income = {...bfinancialProfile.income, upfront:upfront>0?upfront:0};
          dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income }));
          // dispatch(calcPropfinOptionsA(bChoiceD1,bfinancialProfile,bChoiceB))
        dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB))
          dispatch(calcPropfinOptions(bChoiceD,bfinancialProfile,bChoiceB))
          setTimeout(setComp(1), 8000);
        }else if(bChoiceA === "finance"){
          dispatch(borrowerProfileStatus({...bProfileStatus,upfrontProvided:1,incomeProvided:1}))
          // dispatch(calcPropfinOptionsA(bChoiceD1,bfinancialProfile,bChoiceB))
          dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB))
          dispatch(calcPropfinOptions(bChoiceD,bfinancialProfile,bChoiceB))
          setTimeout(setComp(1), 5000);
        }else{
          setTimeout(setComp(1), 5000);
        
        }
        navigate("/BorrowerProfileEmp", { replace: false })
        break;
      //  
      

      case "Search": navigate("/SearchProperty", { replace: false });break;
      case "Next":
      //   navigate("/EmployerSupportDtRegEMB", { replace: false })
      //   break;
      const newbChoiceD =  bChoiceD.filter((item)=> { return item.offerPrice <= bChoiceDSFB?.extendedAfford?.propPrice})
      if(newbChoiceD.length > 0){
        const median = Math.floor((newbChoiceD.length + 1)/2)
        if(_debug === 'development') console.log("Median property",median,newbChoiceD.length)  
        dispatch({ type: MODIFY_D, payload: newbChoiceD });
        dispatch({ type: SELPROP, payload: {prop:newbChoiceD[median],propId:median,finOpt:0} });
        dispatch({ type: PROPROP, payload: {prop:newbChoiceD[median+1],propId:median+1,finOpt:0} });
        dispatch(calcPropfinOptionsA(newbChoiceD,bfinancialProfile,bChoiceB,bChoiceE))
        // dispatch(calcPropfinOptionsEmpA(bChoiceD,bfinancialProfile,bChoiceB,bChoiceE,bChoiceL))

        dispatch(borrowerPostFinanceData(bfinancialProfile))
      }
      console.log("Clicked")
       navigate(`/EmployerSupportDtReg`, {state:{name:"Hello",info:bChoiceDSF}},{ replace: false })

        break;
      case "all":
        navigate("/ShowPurchase", { replace: false });
        if(bChoiceA == "finance" || bChoiceA === "refinance"){ 
          setPropch(!propch);
        }else{
          // dispatch(calcPropfinOptionsA(bChoiceD1,bfinancialProfile,bChoiceB))
          dispatch(calcPropfinOptionsA(bChoiceD,bfinancialProfile,bChoiceB))
          dispatch(calcPropfinOptions(bChoiceD,bfinancialProfile,bChoiceB))
          setTimeout(setComp(2), 5000);
        }
        break;
      default:break;
    }
  }

  return(
    isLoading || isEmployerLoading ||isBchoiceDLoading ? <CircularProgress size={80}  sx={{mt:'45vh',ml:'45vw'}} /> : (
    <React.Fragment><CssBaseline/>
           <Box mt={marginT} ml={marginL} sx={{overflow:'hidden', width: '100%',position:'relative',
           background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
            <Navbar pathname={location.pathname}/>
            <Box sx={{ display: 'flex', flexDirection: 'row', p: 0,m: 0, borderRadius: 1,}}>
              { location.hash === "#messenger" ? <LeftBlockMsg/> : !propch ? <LeftBlock/>:<LeftBlockChangePropDetails/>}
              <Box sx={{ width: '14vw', height:'46.75vw', display:'block', alignItems:'center',position:'relative'}}>
              <div align='center'>
            
                <Stack sx={{mt:`${rspvw(210)}`,height:'3.43vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
                  <Button sx={{ overflow:'hidden', textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}} id="Income & Savings" className='hexBtnCenter'
                        disabled={bChoiceD.length ===0 ? true:false}
                        onClick={onClickFun}> 
                        { bChoiceA === "finance" 
                            ? <HexButton titleText="Income & savings" type={isIncomeChanged ===true || isIncomeSavingChanged ===true ? 'selected_new' : 'base_new'} textAlign='center' fontSize='24'/>
                            : bChoiceA === "refinance" 
                            ? <HexButton titleText="Income & savings" type={isIncomeChanged ===true || isIncomeSavingChanged ===true? 'selected_new' : 'base_new'} fontSize='24'/>
                            :<HexButton titleText="Income & savings" type={isIncomeChanged ===true || isIncomeSavingChanged ===true? 'selected_new' : 'base_new'} fontSize='24'/>
                        }
                        
                  </Button>
                </Stack>
                  
                <Stack sx={{mt:'2.25vw',height:'3.5vw',width:'12vw'}} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}}id="CreditHistory" className='hexBtnCenter'
                      onClick={onClickFun}> 
                      <HexButton titleText={bChoiceA === "refinance" ?"Credit history" :"Credit history"} type={isProfileInfoChanged ===true ? 'selected_new' : 'base_new'} fontSize='24'/></Button>
                      <Button sx={{ overflow:'hidden', textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}}id="Search" className='hexBtnCenter'
                      onClick={onClickFun}> 
                      <HexButton titleText="Property location"type={isLocationChanged ===true ? 'selected_new' : 'base_new'}fontSize='24'/></Button>
                </Stack> 
                <Stack sx={{mt:'2vw',height:'3.5vw',width:'6vw'}} spacing={0} direction="row" justifyContent="space-around">
                    <Button sx={{ overflow:'hidden', textTransform:'none', minHeight: 0, minWidth: 0, padding: '0.1vw',mt:'0',ml:'0.0vw',width:'5.23vw',height:'6.80vw',border:0}}id="Next"  className='hexBtnCenter'
                       onClick={onClickFun}
                       disabled={false}> 
                                       <HexButton sx={{mt:'1vw'}} titleText="Next" type='disabledNew' fontSize='24'/></Button>

                </Stack>
          
                <Centerbottom navigateForward = "ShowPurchaseOpt"/>
              </div>
              </Box>
              <RightBlock/>
            </Box>
          </Box>
    </React.Fragment>
  ));
}

function RightBlock() {
  const { bChoiceD,
          bChoiceA,
          selectedProp,
          isLoading,  
          isBchoiceDLoading,      
          bfinancialProfile
          } = useSelector((state)=>state.borrower);
  const dispatch = useDispatch();
  const [dstate,setDstate] = React.useState(0)
  const bChoiceC= {
    "country": "United States",
    "county": "County",
    "locality": "Denver County",
    "name": "Lincoln Street",
    "position": {
        "latitude": 39.7320529,
        "longiitude": -104.9883101
    },
    "region": "Colorado",
    "zipcode": 80012,
    "type": "home"
}
  // if(dstate ===0){  
  //  dispatch(borrowerChoiceD( bChoiceC.name,'purchase',
  //                            bChoiceC.position?.latitude,
  //                            bChoiceC.position?.longiitude,
  //                            bChoiceC.locality,
  //                            bfinancialProfile?.income?.upfront,
  //                            bfinancialProfile.income.annualIncome,
  //                            '00','primary'))
  //  setDstate(1)
  // } 
  var sum = bChoiceD[0]?.mortgageDetails?.propertyPrice
  var min = bChoiceD[0]?.mortgageDetails?.propertyPrice
  var max = bChoiceD[0]?.mortgageDetails?.propertyPrice
  for( var i = 1; i < bChoiceD?.length; i++ ){
      if(min>bChoiceD[i]?.mortgageDetails.propertyPrice) min = bChoiceD[i]?.mortgageDetails.propertyPrice
      if(max<bChoiceD[i]?.mortgageDetails.propertyPrice) max = bChoiceD[i]?.mortgageDetails.propertyPrice
      sum = sum + parseInt(bChoiceD[i]?.mortgageDetails.propertyPrice );
  }
  var avg = Math.floor(sum*0.001/bChoiceD?.length)*1000;
  if (!selectedProp) dispatch({type: SELPROP, payload:{...selectedProp,prop:bChoiceD[0],propId:0,finOpt:0}});
  console.log(bChoiceD,isBchoiceDLoading)
  const nprops = bChoiceD?.length;
  return (
    <React.Fragment>
      <CssBaseline/>
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box', boxShadow: '-1px -6px 46px #0000003D',opacity: 1, width: '50vw',height:'46.75vw' }}>
     
          { bChoiceD ==false ?  <Box p={0,0} sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center', background:'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "100%",height:"29vw" ,border:1,borderColor:'#F3F3F3', borderRadius:0}}>
          <Box sx={{ display: 'flex',ml:`${rspvw(400)}` }}>
      <CircularProgress thickness={1}  />
    </Box>
              <Typography sx={{mt:0, border:0,height:'11.25vw',textAlign:"center"}} fontSize='3vw' color="#C4C4C4">Loading....
              </Typography>
     
            </Box>:bChoiceD ? 
          //  :nprops>=1 ? 
            <Box p={0,0} sx={{background:'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "100%",height:"29vw" ,border:0, borderRadius:0}}>
             <OuterCarousel itemArray={bChoiceD} numCards={1.7}/>
            </Box>
            :<>
            <Box p={0,0} sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center', background:'inherit', boxShadow: '-1px -6px 46px #0000003D',width: "100%",height:"29vw" ,border:1,borderColor:'#F3F3F3', borderRadius:0}}>
              <Typography sx={{mt:0, border:0,height:'11.25vw',textAlign:"center"}} fontSize='3vw' color="#C4C4C4">No properties in your budget, Please change your Cash and income and search again</Typography>
            </Box>
         </>}
        <Box sx={{ background: "#FFFFFF 0% 0% no-repeat padding-box", display: 'flex', flexDirection: 'row', width:'100%', height:"18vw",boxShadow: '0px 0px 19px #0000008F'}}>
          <Box sx={{background: "#FFFFFF 0% 0% no-repeat padding-box", width: "50%",height:"18vw",p:2,border:0 ,borderRadius:0}}>
          {bChoiceD.length ===0 ? <>
            <Box sx={{ display: 'flex',ml:`${rspvw(160)}`, mt:`${rspvw(120)}` }}>
      <CircularProgress thickness={1}  />
    </Box>
          </>
          : bChoiceA === "finance" || bChoiceA === "refinance"
          ? <><Typography  ml='0.5vw' fontSize='1.4vw' fontWeight='bold' color='#000000'>Your Property</Typography> 
          <Stack sx={{mt:'1vw'}} direction="row" justifyContent="space-around"> 
                  <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left'>Address: </Typography>  
                  <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>{bChoiceD[0].address+' , '+ bChoiceD[0].city+' '+ bChoiceD[0].state+' '+bChoiceD[0].zipcode} </Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left' >Offer Price:</Typography>  
              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>{withCommas(bChoiceD[0].offerPrice)} </Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left'>Affordability:</Typography>  
              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>{bChoiceD[0].affordableType} </Typography>  
          </Stack>
          {/* <Typography mt={2} ml='.5vw' fontSize='1.4vw' fontWeight='bold' color='#000000'>Typical Borrower profile</Typography> */}
          <Typography mt={2} ml='.5vw' fontSize='1.4vw' fontWeight='bold' color='#000000'>Borrower profile</Typography> 
          
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              {/* <Typography  fontSize='1.1vw' color='#000000'width='8vw'textAlign='left'>Average income</Typography>   */}
              <Typography  fontSize='1.1vw' color='#000000'width='8vw'textAlign='left'>Income</Typography>  

              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>{withCommas(bfinancialProfile.income.annualIncome)} </Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              {/* <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left'>Average FICO</Typography>   */}
              <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left'> FICO</Typography>  

              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>{bfinancialProfile.income.ficoScore} </Typography>  
          </Stack>
          </>
          : <><Typography  ml='0.5vw' fontSize='1.4vw' fontWeight='bold' color='#000000'>Available properties</Typography> 
          <Stack sx={{mt:'1vw'}} direction="row" justifyContent="space-around"> 
                  <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left'>For sale: </Typography>  
                  <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>{bChoiceD?.length} </Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left' >Average Price:</Typography>  
              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>{withCommas(avg)} </Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left'>Price range:</Typography>  
              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>{withCommas(min)} - {withCommas(max)} </Typography>  
          </Stack>
          {/* <Typography mt={2} ml='.5vw' fontSize='1.4vw' fontWeight='bold' color='#000000'>Typical Borrower profile</Typography>  */}

          <Typography mt={2} ml='.5vw' fontSize='1.4vw' fontWeight='bold' color='#000000'>Borrower profile</Typography> 

          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              {/* <Typography  fontSize='1.1vw' color='#000000'width='8vw'textAlign='left'>Average income</Typography>   */}
              <Typography  fontSize='1.1vw' color='#000000'width='8vw'textAlign='left'>Income</Typography>  

              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>{withCommas(bfinancialProfile.income.annualIncome)} </Typography>  
          </Stack>
          <Stack sx={{mt:'0.5vw'}}spacing={1} direction="row" justifyContent="space-around"> 
              {/* <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left'>Average FICO</Typography>   */}
              <Typography  fontSize='1.1vw' color='#000000' width='8vw'textAlign='left'>FICO</Typography>  

              <Typography  fontSize='1.1vw' color='#000000' fontWeight='bold' width='12vw'textAlign='left'>{bfinancialProfile.income.ficoScore} </Typography>  
          </Stack>
          </>}
          </Box>
          <Box sx={{background:'#FFFFFF 0% 0% no-repeat padding-box', width: "50%",height:"18vw", borderRadius:0,border:0}}>
          <GoogleMaps position={{lat: 37.6408041, lng: -122.4295378}}
                                  markerInput={bChoiceD} wHeight='18vw'/>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

function LeftBlock () {

  const dispatch = useDispatch();
  const { bProfileStatus,
          bProfileJourney,
          bChoiceA,
          bChoiceE,
          rates,
          isEmbFlow,
          bfinancialProfile
        } = useSelector((state)=>state.borrower);
  const [annualIncome,setAnnualIncome] = React.useState(bfinancialProfile.income.annualIncome || 100000);
  const [upfront,setUpfront] = React.useState(bfinancialProfile.income.upfront || Math.floor(bfinancialProfile.income.annualIncome*0.3));
  const [cashout,setCashout] = React.useState(bChoiceE.propertyPrice*0.89 > bChoiceE.outStandingLoan?bChoiceE.propertyPrice*0.89 - bChoiceE.outStandingLoan:0);
  const [once,setOnce] = React.useState(true)
  const [maxCashout,setMaxCachout] = React.useState(bChoiceE.propertyPrice*0.89 > bChoiceE.outStandingLoan?bChoiceE.propertyPrice*0.89 - bChoiceE.outStandingLoan:0)
  React.useEffect(()=>{
    if(once){
      dispatch({ type: UPDATE_ProfileS, payload:  {...bProfileStatus,upfrontProvided:0,incomeProvided:0}});
      dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, economicsCash:false,economicsIncome:false} });
      const defaultupfront = isNaN(bfinancialProfile.income.upfront) || bfinancialProfile.income.upfront < bfinancialProfile.income.annualIncome*0.3 ? Math.floor(bfinancialProfile.income.annualIncome*0.3) : bfinancialProfile.income.upfront 
      const income = {...bfinancialProfile.income, upfront:defaultupfront};
      dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income }));
      if(bChoiceE.cashoutAmount === null || cashout === 10000){
        dispatch(borrowerChoiceE({...bChoiceE,cashoutAmount:10000}));
        setCashout(10000);
      }
      setOnce(false)
    }
  },[once])
  const analytics = getAnalytics();
  logEvent(analytics, 'propertyType', { name: 'propertyType'});
  
  function onClickFun(e) {
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "Upfront":
          const input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
          if(bChoiceA === "refinance"){
            if(isNaN(input) || input < 0){
              setCashout(0)
              dispatch(borrowerProfileStatus({...bProfileStatus,upfrontProvided:1}))
              dispatch(borrowerChoiceE({...bChoiceE,cashoutAmount:0}));
            }else{
              const _cashout = maxCashout > input ? input:maxCashout
              setCashout(_cashout);
              dispatch(borrowerProfileStatus({...bProfileStatus,upfrontProvided:1}))
              dispatch(borrowerChoiceE({...bChoiceE,cashoutAmount:_cashout}));
            }
            
          }else{
            if(isNaN(input) || input < 0){
              setUpfront(0)
              dispatch(borrowerProfileStatus({...bProfileStatus,upfrontProvided:1}))
              const income = {...bfinancialProfile.income, upfront:0};
              dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income }));
              dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, economicsCash:true} });
            }else{
              setUpfront(input)
              dispatch(borrowerProfileStatus({...bProfileStatus,upfrontProvided:1}))
              const income = {...bfinancialProfile.income, upfront:input};
              dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income }));
              dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, economicsCash:true} });
            }
          }
          break;
      case "AnnualIncome":
          const input1 = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
          if(isNaN(input1) || input1 < 0){
            setAnnualIncome(0);
            dispatch(borrowerProfileStatus({...bProfileStatus,incomeProvided:1}))
            const income1 = {...bfinancialProfile.income, annualIncome:0};
            dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income1 }));
            dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, economicsIncome: true} });
          }else{
            setAnnualIncome(input1);
            dispatch(borrowerProfileStatus({...bProfileStatus,incomeProvided:1}))
            const income1 = {...bfinancialProfile.income, annualIncome:input1};
            dispatch(borrowerfinancialProfile({...bfinancialProfile, income:income1 }));
            dispatch({ type: UPDATE_PROFILEJOURNEY, payload:{...bProfileJourney, economicsIncome: true} });
          }
        break;
      default:
        break;
      }
  }
  return (
    <Box sx={{ overflow:'hidden',background: '#F3F3F3 0% 0% no-repeat padding-box', position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw', border:0 }}>
           <Box sx={{ ml:`${rspvw(100)}`}}>
        <Box sx={{ mt:`${rspvw(159)}` , width: `${rspvw(600)}`,height:`${rspvw(63)}` }}> 
       <Typography sx={{fontSize:`${rspvw(45)}`, fontFamily:'Roboto',fontWeight:'medium',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
       Buy a bigger & better home
         </Typography>
     </Box>
     <Box sx={{ mt:`${rspvw(0)}` , width: `${rspvw(600)}`,height:`${rspvw(63)}` }}> 
         <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(45)}`, fontFamily:'Roboto',fontWeight:'medium',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
       Today's interest rate
         </Typography>
     </Box>
     {/* <Box sx={{display:'flex'}}>
      <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
      <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
       Income
         </Typography>
      </Box>
      <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
      <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'right',lineHeight:1.2,letterSpacing: '0'}}> 
       $xx,xxx
         </Typography>
      </Box>
      

     </Box>
     <Box sx={{display:'flex'}}>
      <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
      <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
        Cash avalable
         </Typography>
      </Box>
      <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
      <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'right',lineHeight:1.2,letterSpacing: '0'}}> 
       $xx,xxx
         </Typography>
      </Box>
      

     </Box>
     <Box sx={{display:'flex'}}>
      <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
      <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
        Credit score
         </Typography>
      </Box>
      <Box sx={{mt:`${rspvw(25)}`,width:`${rspvw(208)}`,height:`${rspvw(44)}`}}>
      <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(33)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'right',lineHeight:1.2,letterSpacing: '0'}}> 
        XXX
         </Typography>
      </Box>
      

     </Box>
     <Box sx={{ mt:`${rspvw(25)}` , width: `${rspvw(600)}`,height:`${rspvw(63)}` }}> 
         <Typography sx={{color:'#31312C', 
           fontSize:`${rspvw(45)}`, fontFamily:'Roboto',fontWeight:'medium',textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
         Save up to $xx,xxx with Mortgage as Benefit
         </Typography>
     </Box> */}
     
     </Box>
     <Box sx={{ mt:`${rspvw(10)}` , ml:`${rspvw(100)}`, width: `${rspvw(600)}`,height:`${rspvw(63)}` }}> 
            <Typography sx={{color:'#31312C', 
              fontSize:`${rspvw(30)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',letterSpacing: '0'}}> 
              starting from
            </Typography>
        </Box>
        <Box sx={{ mt:`${rspvw(-30)}` , ml:`${rspvw(100)}`, width: `${rspvw(600)}`,height:`${rspvw(63)}` }}> 
            <Typography sx={{color:'#31312C', 
              fontSize:`${rspvw(70)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',letterSpacing: '0'}}> 
              {rates.rate>0?rates.rate:"x.xx"}%
            </Typography>
            <Box sx={{display:'flex'}}>
            <Typography sx={{color:'#31312C', mt:`${rspvw(-20)}` ,
              fontSize:`${rspvw(70)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',letterSpacing: '0'}}> 
              {rates.apr>0? rates.apr.toFixed(2):"x.xx"}% 
            </Typography>
            <Typography sx={{color:'#31312C', mt:`${rspvw(23)}`,ml:`${rspvw(14)}`,
              fontSize:`${rspvw(32)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',letterSpacing: '0'}}> 
              APR
            </Typography>
            </Box>
            <Box sx={{ mt:`${rspvw(0)}` , ml:`${rspvw(0)}`, width: `${rspvw(600)}`,height:`${rspvw(63)}` }}> 
            <Typography sx={{color:'#31312C', 
              fontSize:`${rspvw(32)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',letterSpacing: '0'}}> 
             over a 30 year term
            </Typography>
        </Box>
        <Box sx={{ mt:`${rspvw(0)}` , ml:`${rspvw(0)}`, width: `${rspvw(457)}`,height:`${rspvw(36)}` }}> 
        <Typography sx={{color:'#000000', 
              fontSize:`${rspvw(13)}`, fontFamily:'Roboto',fontWeight:'regular',textAlign:'left',letterSpacing: '0'}}> 
            Example based on an household earning {rates.rate>0?withCommas( bfinancialProfile.income.annualIncome) :"$xxx,xxxx"} per year, with {rates.rate>0?( bfinancialProfile.income.ficoScore) :"xxx"} FICO, {rates.rate>0?withCommas( bfinancialProfile.income.outgoing) :"$xxx"}/mo outgoings, Neither late payments & nor defaults in the past 24 months
            </Typography>
</Box>
</Box>
        <LeftbottomA leftbottom={leftbottom}/>
    </Box>
  );
}


function LeftBlockChangePropDetails () {

  const dispatch = useDispatch();
  const { bChoiceD,
          bChoiceA,
          bChoiceE,
          selectedProp
        } = useSelector((state)=>state.borrower);

  const [price,setPrice] = React.useState(0.0);
  const [prop,setProp] = React.useState(bChoiceD[0]);
  const [offerPrice,setOfferPrice] = React.useState(bChoiceA ==="refinance"? bChoiceE.outStandingLoan : bChoiceD[0].offerPrice);
  const [zipcode,setZipcode] = React.useState(bChoiceD[0].zipcode);
  const analytics = getAnalytics();
  const [once,setOnce] = React.useState(true)

  logEvent(analytics, 'propertyTypeFO', { name: 'propertyTypeFO'});

  
  React.useEffect(()=>{
    if(once){
      const prop = {...bChoiceD[0],price:bChoiceE.propertyPrice, offerPrice:bChoiceE.propertyPrice}
      dispatch({ type: UPDATE_D, payload: [prop,prop,prop] });
      setPrice(bChoiceA ==="refinance"? bChoiceE.propertyPrice : bChoiceD[0].price)
      setOnce(false)
    }
  },[once])

  function onClickFun(e) {
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "price":
        const pric = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
        setPrice(isNaN(pric)? 0 : pric);
        if(bChoiceA === "refinance"){
          const property = {...prop,offerPrice:pric}
          dispatch(borrowerChoiceE({...bChoiceE,propertyPrice:pric}));
        }else{
          const property = {...prop,price:pric}
          dispatch({ type: UPDATE_D, payload: [property,property,property] });
          dispatch({type: SELPROP, payload:{...selectedProp, prop:property}});
        }
        
          break;
      case "offerprice":
        const offerP = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
        
        if(bChoiceA === "refinance"){ 
        setOfferPrice(isNaN(offerP)? 0 : offerP);
        const propertyPrice = Math.ceil((bChoiceE.cashoutAmount + offerP)/900)*1000
        setPrice(propertyPrice);
        dispatch(borrowerChoiceE({...bChoiceE,outStandingLoan:offerP,propertyPrice:propertyPrice}))
        }else{
          setOfferPrice(isNaN(offerP)? 0 : offerP);
          const property = {...prop,offerPrice:offerP<0?0:offerP}
          dispatch({ type: UPDATE_D, payload: [property,property,property] });
          dispatch({type: SELPROP, payload:{...selectedProp, prop:property}});
        }
        break;
      case "zipcode":
          const zipCode = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
          setZipcode(isNaN(zipCode)? 0 : zipCode);
          const property2 = {...prop,zipcode:zipCode}
          dispatch({ type: UPDATE_D, payload: [property2,property2,property2] });
          dispatch({type: SELPROP, payload:{...selectedProp, prop:property2}});
        break;
      default:
        break;
      }
  }
  return (
    <Box sx={{ overflow:'hidden',background: '#F3F3F3 0% 0% no-repeat padding-box', position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw', border:0 }}>
        <Box sx={{ mt:'6.2vw' , ml: '7.3vw', width: '28.7vw',height:'6vw' }}> 
        { bChoiceA === "refinance"
          ? <Typography sx={{color:'#31312C', width:'28vw',
          fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
            Provide property & finance Details
        </Typography>
          : <Typography sx={{color:'#31312C', width:'28vw',
          fontSize:'2.0vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.2,letterSpacing: '0'}}> 
            Provide Property Details
        </Typography>
        }
            
        </Box>
        <Box sx={{ mt:'1vw' , ml: '7.3vw', width: '27vw',height:'14vw' }}> 
          <Stack sx={{mt:1}}spacing={0} direction="row" justifyContent="space-between">
          { bChoiceA === "refinance"
            ? <Typography sx={{mt:1,fontSize:'1.35vw',width:'14vw'}} color='#000000'>   
            Current Property Price</Typography>
            : <Typography sx={{mt:1,fontSize:'1.35vw',width:'14vw'}} color='#000000'>   
            Property Price </Typography>
          }    
              <Input disableUnderline={true} id="price" value={withCommas(price)} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"13vw", border:1, borderRadius:'0.5vw'}} 
                    inputProps={{style: { textAlign: 'center' }}}onChange={onClickFun} onBlur={()=>{if (price <= 100) setPrice(100)}}/>
          </Stack>
          <Stack sx={{mt:1}} spacing={0} direction="row" justifyContent="space-between"> 
          { bChoiceA === "refinance"
            ? <Typography sx={{mt:1,fontSize:'1.35vw',width:'14vw'}} color='#000000'>   
            Outstanding Loan Amount</Typography>
            : <Typography sx={{mt:1,fontSize:'1.35vw',width:'14vw'}} color='#000000'>   
            Offer Price </Typography>
          } 
            <Input disableUnderline={true} id="offerprice" value={withCommas(offerPrice)} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"13vw",border:1, borderRadius:'0.4vw'}} 
                    inputProps={{style: { textAlign: 'center' }}}onChange={onClickFun} onBlur={()=>{if (offerPrice <= 100) setOfferPrice(100)}}/>
          </Stack>

          <Stack sx={{mt:1}} spacing={0} direction="row" justifyContent="space-between"> 
          { bChoiceA === "refinance"
            ? <></>
            : <><Typography sx={{mt:1,fontSize:'1.35vw',width:'14vw'}} color='#000000'>   
            Zip Code </Typography>
            <Input disableUnderline={true} id="zipcode" value={zipcode} sx={{backgroundColor:'#FFFFFF',color:"#000000",fontSize:'1.1vw', height: '2.5vw',width:"13vw",border:1, borderRadius:'0.4vw'}} 
                    inputProps={{style: { textAlign: 'center' }}}onChange={onClickFun} onBlur={()=>{if (zipcode <= 10000) setZipcode(bChoiceD[0].zipcode)}}/>
          </>
          } 
          </Stack>
          { bChoiceA === "refinance"
            ? <Typography sx={{color:'#31312C',width:'28vw', mt:'3vw',
            fontSize:'1.72vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
              Press Income Details button to provide and check maximum Cashout possible
          </Typography>
            : <Typography sx={{color:'#31312C',width:'28vw', mt:'3vw',
            fontSize:'1.72vw', fontFamily:'Roboto',fontWeight:400,textAlign:'left',lineHeight:1.1,letterSpacing: '0'}}> 
              Provide property price details to estimate your financing options
          </Typography>
        }
          
        </Box>
        <LeftbottomA leftbottom={leftbottom}/>
    </Box>
  );
}

function LeftBlockRefi() {
  const dispatch = useDispatch();
  const { bChoiceE,
          bChoiceD,
          bfinancialProfile,
          isLoading}= (useSelector((state)=>state.borrower));
  const [outstandingLoan,setOutstandingLoan] = React.useState(0)
  const [annualIncome,setAnnualIncome] = React.useState(bfinancialProfile.income.annualIncome);
  const [propertyValue,setPropertyValue] = React.useState(0);

  useEffect(()=>{
    if(bChoiceD.length === 0 ) console.log("B undefined")
    else {
      console.log("DDD",bChoiceD)
      setOutstandingLoan(0.8*parseInt(bChoiceD[0].price));
      setPropertyValue(parseInt(bChoiceD[0].price));
      dispatch(borrowerChoiceE({...bChoiceE,
                                outStandingLoan:0.8*parseInt(bChoiceD[0].price), 
                                propertyPrice:parseInt(bChoiceD[0].price)}));
      }
  },[bChoiceD])


  function onClickFun(e) {
    const buttonId = e.currentTarget.id;
    switch(buttonId) {
      case "AnnualIncome":
        var input = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
        setAnnualIncome(isNaN(input) ? 0 : input)
        const income = {...bfinancialProfile.income, annualIncome:input}
        dispatch(borrowerfinancialProfile({...bfinancialProfile,income:income}))
        break;
      case "Outstanding":
        var input1 = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
        setOutstandingLoan(isNaN(input1) ? 0 : input1)
        dispatch(borrowerChoiceE({...bChoiceE,
                                  outStandingLoan:input1 <0?0:input1}));
        break;
      case "PropValue":
        var input2 = parseInt(e.currentTarget.value.replace(/\$|,/g, ''))
        setPropertyValue(isNaN(input2) ? 0 : input2)
        dispatch(borrowerChoiceE({...bChoiceE,
                                  propertyPrice:input2}));
          break;
      default:
        break;
      }
  }
  return (
    isLoading ? <CircularProgress style={{'color': 'yellow'}} /> : (
      <Box sx={{background: '#F3F3F3 0% 0% no-repeat padding-box',position:'relative',
      boxShadow: '1px 6px 46px #0000003D',opacity: 1, width: '36vw',height:'46.75vw' }}>
        <Box sx={{ mt:'8.4vw' , ml: '8.3vw', width: '27vw',height:'23.6vw' }}> 
            <Typography sx={{color:'#31312C', width:'25vw',
              fontSize:'2.2vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.1,letterSpacing: '-.1vw'}}> 
              Refinancing {bChoiceD[0].address.substring(0,12)}... 
          </Typography>
          <Typography sx={{color:'#31312C', width:'25vw',
              fontSize:'2.2vw', fontFamily:'Roboto',fontWeight:500,textAlign:'left',lineHeight:1.1,letterSpacing: '-.1vw'}}> 
              update your economic circumstances
          </Typography>
          <Stack sx={{mt:6}}spacing={2} direction="row" alignItems="center" justifyContent="space-between"> 
               <Typography fontSize='1.35vw' color='#000000'>Outstanding Loan </Typography>
               <Box sx={{width:'15vw',border:1,borderRadius:2}}>
               <Input id="Outstanding" variant="outlined" value={withCommas(Math.round(outstandingLoan))} sx={{ml:'4vw', border:0, color:"#000000",fontSize:'1.2vw', width:"10vw"}} 
                  onChange={onClickFun}InputProps={{disableUnderline:true }}/>
                  </Box>
          </Stack>
          <Stack sx={{mt:2}}spacing={2} direction="row" alignItems="center" justifyContent="space-between"> 
                  <Typography fontSize='1.35vw' color='#000000'>Property Value </Typography>
                  <Box sx={{width:'15vw',border:1,borderRadius:2}}>
                 <Input id="PropValue" variant="outlined" value={withCommas(Math.round(propertyValue))} sx={{ml:'4vw', border:0, color:"#000000",fontSize:'1.2vw', width:"10vw"}} 
                  onChange={onClickFun}InputProps={{disableUnderline:true }}/>
                  </Box>
          </Stack>
          <Stack sx={{mt:2}}spacing={2} direction="row" alignItems="center" justifyContent="space-between">
            <Typography fontSize='1.35vw' color='#000000'>Annual Household Income </Typography>
            <Box sx={{width:'15vw',border:1,borderRadius:2}}>
            <Input id="AnnualIncome" variant="outlined" value={withCommas(Math.round(annualIncome))} sx={{ml:'4vw', border:0, color:"#000000",fontSize:'1.2vw', width:"10vw"}} 
                  onChange={onClickFun}InputProps={{disableUnderline:true }}/>
            </Box>
          </Stack>
          
          <Typography sx={{mt:'3vw'}} fontSize='1.72vw' color='#000000'>Provide details to improve your</Typography>
          <Typography fontSize='1.72vw' color='#000000'>refinancing  estimate</Typography>
      </Box>
      <Box sx={{bottom:'0',height:'10.8vw',width: 'inherit',position:'absolute' }}>  
           <img src={leftbottom} alt="Siaapa" width="99%" height="100%"/> 
      </Box>
      
      </Box>
  ));
}

 
function OuterCarousel(props) {
  const {itemArray, numCards } = props;
  const { bChoiceD,
          bChoiceA,
          bChoiceE,
          selectedProp}=useSelector((state)=>state.borrower);
  const median = Math.floor((bChoiceD.length +1)/2)
  const dispatch = useDispatch();
  const [active, setActive] = React.useState(median);
  const [showMap,setShowMap] = React.useState(-1)
  const { height, width } = useWindowDimensions();
  const gutter = 18*width/1920
  console.log("Here is the array", `${itemArray[0]?.url}`)
  React.useEffect(()=>{
    dispatch({type: SELPROP, payload:{...selectedProp, prop:bChoiceD[active],propId:active}});
  },[active]);
  const openInNewTab = (url) => {
    if (url !=undefined){
      window.open(url, '_blank', 'noreferrer');

    }
  };
  
  return (
    <div className="OuterCarousel">
      <ItemsCarousel
        autoPlay={true}
        infiniteLoop={true}
        gutter={gutter}
        timeout={1}
        activePosition={"center"}
        chevronWidth={-2}
        disableSwipe={false}
        alwaysShowChevrons={false}
        numberOfCards={numCards}
        slidesToScroll={1}
        outsideChevron={false}
        showSlither={true}
        firstAndLastGutter={true}
        activeItemIndex={active}
        requestToChangeActive={(value) => value >=0 && value < itemArray.length 
                                  ? setActive(value)
                                  :value <0 ?  setActive(itemArray.length -1)
                                  : setActive(0)}
        // rightChevron={<ArrowForwardIosIcon sx={{mt:'-15.75vw',color:"#F1F2F4", fontSize:'2vw',
        //   borderRadius: '15px 0px 0px 15px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
        // leftChevron={<ArrowBackIosIcon    sx={{ mt:'-15.75vw',color:"#F1F2F4", fontSize:'2vw',
        //   borderRadius: '0px 15px 15px 0px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}

        rightChevron={ <img src={rightArrow} alt='right-arrow' style={{ marginTop:'-15.75vw',width:'1.77vw', height:'3.28vw' }} /> }
        leftChevron={ <img src={leftArrow} alt='left-arrow' style={{ marginTop:'-15.75vw',width:'1.77vw', height:'3.28vw' }} /> }
        // rightChevron={<ArrowForwardIosIcon  sx={{mr:'-.8vw',mt:'-15.75vw',color:"#F1F2F4", fontSize:'3vw'}}/>}
        // leftChevron={<ArrowBackIosIcon      sx={{mt:'-15.75vw',color:"#F1F2F4", fontSize:'3vw'}}/>}
      >
      {itemArray.map((item,index) => 
      <>
      <Box sx={{position:'relative', mt:'1vw',background: '#FFFFFF 0% 0% no-repeat padding-box', height: "17.5vw",boxShadow: '0px 0px 19px #0000008F',borderRadius:'0.5vw'} } >
       {showMap === index  ?<><GoogleMaps position={{lat: item?.geoLocation.lat, lng:item?.geoLocation.lon}}
                                  markerInput={[item]} wHeight='13.5vw'/> </>
                          :<><Link to =''  onClick={() => openInNewTab(`${item?.url}`)} target="_blank">
                          
                          <InnerCarousel  imageArray={item.images} numCards={1} chevron={active}/></Link> 
         <Box sx={{position:'absolute',mt:'-4vw', background: "rgba(0,0,0,0.7)", width: "100%",height:"4vw",border:1 ,borderRadius:0, borderColor:"#FFFFFF" }}>
            <Stack sx={{m:'0 auto',width:'18vw',mt:1}} spacing={2} direction="row" justifyContent="space-around"> 
              <Stack textAlign="center" ml='0vw' width='28%' direction={'column'}>
                  <Typography fontSize='0.8vw' color='#FFFFFF'>Area</Typography>  
                  <Typography fontSize='0.8vw' color='#FFFFFF'>{withCommasWithoutCurrency(item?.squareFeet)} sqft</Typography>  
              </Stack>
              <Stack textAlign="center" width='24%' direction={'column'}>
                  <Typography fontSize='0.8vw' color='#FFFFFF'>Beds</Typography>  
                  <Typography fontSize='0.8vw' color='#FFFFFF'>{item?.beds}  <img color="#FFFFFF" src={bedicon} style={{ marginLeft:'0.26vw', height:'1.14vw' }}  /></Typography>  
              </Stack>
              <Stack textAlign="center" width='24%' direction={'column'}>
                  <Typography fontSize='0.8vw' color='#FFFFFF'>Baths</Typography>  
                  <Typography fontSize='0.8vw' color='#FFFFFF'>{item?.baths} <img sx={{mr:10}}color="#FFFFFF" src={bathicon} style={{ marginLeft:'0.26vw', height:'1.14vw' }}  /></Typography>  
              </Stack>
              <Stack textAlign="center" mr='0vw' width='24%' direction={'column'}>
                  <Typography fontSize='0.8vw' color='#FFFFFF'>Garages</Typography>  
                  <Typography fontSize='0.8vw' color='#FFFFFF'>1 <img color="#FFFFFF" src={garageicon}  style={{ marginLeft:'0.26vw', height:'1.14vw' }} /></Typography>  
              </Stack>
            </Stack>
          </Box>
          </>}
          {/* <Stack sx={{ml:1,mb:1}} spacing={0} direction="row" justifyContent="space-between"> 
            <Typography ml='.4vw' mt='1.1vw' fontSize='1.32vw' fontWeight='bold' color='black' align='left'>{withCommas(item?.mortgageDetails.propertyPrice)}</Typography>
            <Box width='16vw' >
              <Typography mt='0.8vw'mr='1.2vw' fontSize='0.9vw' align='right' color="#16214A"> 
              <Button sx={{ padding:0, minHeight: 0, minWidth: 0, pt:'.56vw'}} onClick={()=> {showMap === -1? setShowMap(index) : setShowMap(-1)}} >
                <LocationOnIcon sx={{color:'#000000', fontSize:'2vw'}}/>
              </Button>&ensp;{item.address.substr(0,25)}
              </Typography>
              <Typography mr='1.2vw' mt='-0.9vw' fontSize='0.9vw' color='#16214A' align='right'> {item.city}, {item.state} - {item.zipcode}</Typography>
            </Box>
          </Stack> */}

          <Stack sx={{ml:1, mr:0,mb:1,mt:2}} spacing={0} direction="row" justifyContent="space-between"> 
          {
              bChoiceA === "purchase" 
            ? <Typography ml='1vw' mt='-.5vw' sx={{fontSize:`${rspvw(32)}`}} fontWeight='bold' color='black' align='left'>{withCommas(item?.mortgageDetails.propertyPrice)}</Typography>
            : <Typography ml='1vw' mt='-0.5vw' fontSize='1.32vw' fontWeight='bold' color='black' align='left'>{withCommas(bChoiceD[0].offerPrice)}</Typography>
          }
            <Box width='14vw' mt='-.8vw'>
              <Typography mr='1vw' fontSize='0.8vw' align='left' color="#16214A"> 
              <Button sx={{ padding:0, minHeight: 0, minWidth: 0, pt:'0.56vw'}} onClick={()=> {showMap === -1? setShowMap(index) : setShowMap(-1)}} >
                <LocationOnIcon sx={{color:'#000000', fontSize:'2.08vw'}}/>
              </Button>{item.address.substr(0,20)}
              </Typography>
              <Typography paddingLeft="2.14vw" mr='1.2vw' mt='-0.97vw' fontSize='0.8vw' color='#16214A' align='left'> {item.city}, {item.state} - {item.zipcode}</Typography>
            </Box>
          </Stack>

        </Box>
      <Box mt='0vw' mb='0.9vw' height='8.1vw' background='#FFFFFF 0% 0% no-repeat padding-box' boxShadow= '0px 0px 19px #0000008F' borderRadius='0.5vw'>
      <Box height='8.1vw' backgroundColor='#FFFFFF' borderRadius='.5vw'>
          <Stack sx={{ml:'0', mt:'1.1vw'}} direction="row" justifyContent="space-around">
            {
              bChoiceA === "purchase" 
              ?<>
              <Typography  mt='1.1vw' width='16vw' fontSize='1.1vw' color='#000000'>Down payment & Costs </Typography>
              <Typography  mt='1.1vw' fontSize='1.1vw' fontWeight='bold' color='#000000'>{withCommas(Math.floor(item?.mortgageDetails.upfront + item?.mortgageDetails.closingCost))} </Typography>
              </> 
              :<>
              <Typography  mt='1.1vw' width='16vw' fontSize='1.1vw' color='#000000'>Cashout Estimated </Typography>
              <Typography  mt='1.1vw' fontSize='1.1vw' fontWeight='bold' color='#000000'>{withCommas(Math.floor(item?.mortgageDetails.loanAmount -bChoiceE.outStandingLoan - item?.mortgageDetails.closingCost))} </Typography>
              </>
            }
          </Stack>
          <Stack sx={{ml:'-0.5vw'}}direction="row" justifyContent="space-around">
            <Typography  mt='2.3vw' width='16vw'fontSize='1.1vw' color='#000000'>Typical monthly payments</Typography>
            {
              bChoiceA === "purchase" 
              ? <Typography  mt='2.3vw' fontSize='1.1vw' fontWeight='bold' color='#000000'>{withCommas(Math.round(item?.mortgageDetails.monthlyEMI))}</Typography>
              : <Typography  mt='2.3vw' fontSize='1.1vw' fontWeight='bold' color='#000000'>{withCommas(Math.round(item?.mortgageDetails.monthlyEMI*bChoiceD[0].offerPrice/bChoiceD[0].mortgageDetails.propertyPrice))}</Typography>
            }
        </Stack>
      </Box>
      </Box>
      </>
      )}
      </ItemsCarousel>
    </div>
  );
}

function InnerCarousel(props) {
  const {imageArray,numCards,chevron} = props;
  if (imageArray.length >=1 && imageArray[0] === 'dummy') imageArray.pop()
  if (imageArray.length >=1 && imageArray[0].toLowerCase().match(/\.(png)/g)) imageArray.pop()
  if(imageArray.length < numCards){
    imageArray.push(nestfuelHomeImageOne)
    // imageArray.push(nestfuelHomeImageTwo)
    // imageArray.push(nestfuelHomeImageThree)
}
  const [active, setActive] = React.useState(0);

  return (
    
    <div className="InnerCarousel">
      {
        imageArray.length === 1 
        ?<>
         <div>
            <Box  key={1} sx={{ border:1, borderColor:"#FFFFFF", borderRadius: '10px 10px 0px 0px', height:"13.5vw"}} >
              <img src={(typeof(imageArray[0]) ==='undefined' ? nestfuelImage : imageArray[0])} alt={nestfuelImage} style={{ opacity:'0.9', borderRadius: '10px 10px 0px 0px'}} width="100%" height="100%"
              onError={(e) => (e.target.onerror = null, e.target.src = nestfuelHomeImageOne)}
              />
            </Box>
          </div>
        </>
        :<>
       
      <ItemsCarousel
         autoPlay={true}
         infiniteLoop={true}
         gutter={0}
         timeout={1}
         activePosition={"center"}
         chevronWidth={-2}
         disableSwipe={false}
         alwaysShowChevrons={false}
         numberOfCards={numCards}
         slidesToScroll={1}
         outsideChevron={false}
         showSlither={true}
         firstAndLastGutter={true}
         activeItemIndex={active}
        requestToChangeActive={(value) => setActive(value)}
        // rightChevron={<ArrowForwardIosIcon sx={{color:"#FFFFFF" , fontSize:'3vw'}}/>}
        // leftChevron={<ArrowBackIosIcon    sx={{ ml:'0.8vw', color:"#FFFFFF" , fontSize:'3vw'}}/>}
        rightChevron={ <img src={rightArrow} alt='right-arrow' style={{ width:'1.77vw', height:'3.28vw' }} /> }
        leftChevron={ <img src={leftArrow} alt='left-arrow' style={{ width:'1.77vw', height:'3.28vw' }} /> }
        // rightChevron={<ArrowForwardIosIcon sx={{color:"#F1F2F4", fontSize:'2vw',
        //   borderRadius: '15px 0px 0px 15px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}
        // leftChevron={<ArrowBackIosIcon    sx={{ color:"#F1F2F4", fontSize:'2vw',
        //   borderRadius: '0px 15px 15px 0px',
        //   width: '34px',
        //   height: '63px',
        //   background: "rgba(0,0,0,0.40)",
        //   color:'#FFFFFF',
        //   paddingLeft: '0.3vw'
        // }}/>}

      >
        {
        imageArray.map((item,index) => 
          <div>
            <Box  key={index} sx={{ border:1, borderColor:"#FFFFFF", borderRadius: '10px 10px 0px 0px', height:"13.5vw"}} >
              <img src={(typeof(item) ==='undefined' ? nestfuelImage : item)} alt={nestfuelImage} style={{ opacity:'0.9', borderRadius: '10px 10px 0px 0px'}} width="100%" height="100%"
              onError={(e) => (e.target.onerror = null, e.target.src = nestfuelHomeImageOne)}
              />
            </Box>
          </div>
          )
        }
      </ItemsCarousel>
      </>
      }
    </div>
   
  );
}
export default PersonalizeRate