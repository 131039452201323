import React from "react";
import { useNavigate } from "react-router-dom";
// material-ui
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  CssBaseline, Select, InputAdornment, IconButton, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { HexButton } from './ButtonIcons1';
import { getWindowDimensions } from '../../computations/helperFunctions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import leftbottom from '../../assets/svg/assets svg/image-leftbottom02.png'
import avatar_boy from '../../assets/svg/avatar-boy.png'
import mask_group from '../../assets/staticPageAssets/MaskGroup107.png'
import bullet from '../../assets/staticPageAssets/Polygon.svg'
import useStyles from './styles';
import { BottomBox, LeftbottomA } from "./RightBlock";
import { _debug } from "../../constants";
import { border, fontWeight, width } from "@mui/system";
import zIndex from "@mui/material/styles/zIndex";
import NavBarDashBoardUpdate from "./NavBarDashBoardUpdate";
import NavBarBreadCrumbUpdate from "./NavBarBreadCrumbUpdate";
import AnalyticTeam from './AnalyticTeam';
//import IncomeAreaChart from './IncomeAreaChart';
//import MonthlyBarChart from './MonthlyBarChart';
import { Bar, Line } from "react-chartjs-2";
import MainCard from './MainCard';
import "chartjs-plugin-datalabels";
import person from '../../assets/svg/person.svg'
import dollarsolid from '../../assets/svg/dollar-solid.svg'
import briefcasefill from '../../assets/svg/briefcase-fill.svg'
import graduationcap from '../../assets/svg/graduation-cap.svg'
import expandMore from '../../assets/svg/expand_more.svg'
import calender from '../../assets/svg/ios-calendar-outline.svg'
import OrdersTable from './OrdersTable';
import EmployerTeamList from './EmployerTeamList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { styled, alpha } from '@mui/material/styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement, PointElement,
  Title,
  Tooltip,
  Legend, LineElement

} from 'chart.js';
import bxDown from '../../assets/svg/bx-down-arrow-alt.svg'
import { rspvw } from "./styles";
import { validator } from "./FormValidation/Validator";
import useForm from "./FormValidation/UseForm";

var allowed = "no"


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip, LineElement,

  Legend, PointElement
);





const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AnalyticResourceOneNew from "./AnalyticResourceOneNew";
import { useDispatch } from "react-redux";
import { breadcrumbData} from "../../actions/borrower";
function EmployerTeam() {
  const navigate = useNavigate();
  const dispatch= useDispatch()
  dispatch(breadcrumbData("Your Team"));
  const { height, width } = getWindowDimensions();
  const [seek, setSeek] = React.useState(allowed);
  const [hexaText1, setHexaText1] = React.useState('Investors')
  const [hexaText2, setHexaText2] = React.useState('Technology Partners')
  const [hexaText3, setHexaText3] = React.useState('Employers')
  const [hexaText4, setHexaText4] = React.useState('Mortgage Industry')


  const [value, setValue] = React.useState('today');
  const [slot, setSlot] = React.useState('week');
  // if(_debug === 'development') allowed = 'yes'
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  allowed = 'yes'

  const [openDialog, setOpen] = React.useState(false);
  const state = {
    labels: ['January', 'February', 'March',
      'April', 'May'],
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [65, 59, 80, 81, 56]
      }
    ]
  }

  const handleChangeDropDown = (event) => {
    setAge(event.target.value);
  };
  const [age, setAge] = React.useState('');
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },

    ]
  };


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };

  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  const initState = {
    firstName: "",
    lastName: "",
    birthDate: "bd",
    gender: "g",
    address: "add",
    position: "p",
    startDate: "sd",
    department: "d"
  };
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [birthDate, setBirthDate] = React.useState("bd");
  const [gender, setGender] = React.useState("0");
  const [address, setAddress] = React.useState("");
  const [position, setPosition] = React.useState("0");
  const [startDate, setStartDate] = React.useState("sd");
  const [department, setDepartment] = React.useState("0");
  const [birthDateComp, setStartDateComp] = React.useState(new Date());
  //  const onChange = (e) => {
  //    console.log("e.target",e.target)
  //  };

  const onChangeFirstName = (e) => {
    const firstName=e.target.value;
    setFirstName(firstName)
   
  };

  const onChangeLastName = (e) => {
    const lastName=e.target.value;
    setLastName(lastName)
   
  };

  const onChangeBirthDate = (name, date) => {
    console.log("e.target",name)
    console.log("e.birthDate",birthDateComp)
    const dateb = `${birthDateComp.getDate()}/${birthDateComp.getMonth()+1}/${birthDateComp.getFullYear()}`;
    setBirthDate(dateb)
   
  };
  
  const onChangeGender = (e) => {
    const gender=e.target.value;
    setGender(gender)
   
  };

  const onChangeAddress = (e) => {
     const address=e.target.value;
     setAddress(address)
   
  };

  
  const onChangePosition = (e) => {
    const position=e.target.value;
    setPosition(position)
  };



  const onChangeStartDate = (name, date) => {
    const dates = `${birthDateComp.getDate()}/${birthDateComp.getMonth()+1}/${birthDateComp.getFullYear()}`;
    setStartDate(dates)
   
  };

  const onChangeDepartment = (e) => {
     const department=e.target.value;
     setDepartment(department)
   
  };

  const submit = () => {
    console.log("ValidSubmited",birthDate);
    // if(firstName=='fn'){
    //   console.log(" SubmitedIF",firstName);
    //   toast("First Name Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    // }else if(lastName=='ln'){
    //   toast("Last Name Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    // }
    //  else if(birthDate=='bd'){
    //   toast("Birth Date Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    //  }
    // else if(gender == 'g'){
    //   toast("Gender Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    // }else if(address=='add'){
    //   toast("Address Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    // }else if(position=='p'){
    //   toast("Designation Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    // }
    //  else if(startDate=='sd'){
    //    toast("Start Date Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    //  }
    // else if(department=='de'){
    //   toast("Department Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    // }else{
    //   toast("Data Submitted Sucessfully!", {position: toast.POSITION.TOP_CENTER})
    // }

   // setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onSubmitData = () => {
    //setOpen(true);
    if(firstName==''){
      console.log(" SubmitedIF",firstName);
      toast("First Name Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    }else if(lastName==''){
      toast("Last Name Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    }
     else if(birthDate=='bd'){
      toast("Birth Date Should not be blank!", {position: toast.POSITION.TOP_CENTER})
     }
    else if(gender == '0'){
      toast("Gender Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    }else if(address==''){
      toast("Address Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    }else if(position=='0'){
      toast("Position Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    }
     else if(startDate=='sd'){
       toast("Start Date Should not be blank!", {position: toast.POSITION.TOP_CENTER})
     }
    else if(department=='0'){
      toast("Department Should not be blank!", {position: toast.POSITION.TOP_CENTER})
    }else{
      setOpen(false);
      toast("Data Submitted Sucessfully!", {position: toast.POSITION.TOP_CENTER})
    }
  };

  const handleCloseDialog = () => {
   // console.log(" cose",firstName);
    setOpen(false);
    //toast("Data Submitted Sucessfully!", {position: toast.POSITION.TOP_CENTER})
   
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    stateForm,
    errors,
    countryCode
  } = useForm({
    initState,
    callback: submit,
    validator
  });

  let isValidForm =
    Object.values(errors).filter(error => typeof error !== "undefined")
      .length === 0;
  const today = dayjs();
  const tomorrow = dayjs().add(1, 'day');
  const todayEndOfTheDay = today.endOf('day');

  return (
    <React.Fragment><CssBaseline />
      <Box sx={{ overflow: 'hidden', width: '100%', background: 'transparent radial-gradient(closest-side at 67% 55%, #FFFFFF 0%, #FEFEFE 38%, #F3F3F3 100%) 0% 0% no-repeat padding-box' }}>
        <NavBarDashBoardUpdate />
        <NavBarBreadCrumbUpdate />


        <Box sx={{ ml: '7.34vw', mr: '7.34vw', mt: '4vh', mb: '4vh' }} >
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            {/* row 1 */}
            <Grid item xs={12} sx={{ mb: -2.25 }}>
              <Typography sx={{ ml: '0.34vw', mr: '7.34vw', mt: '4vh', mb: '0.2vh' }}>Team Members</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} >
              <AnalyticTeam title="Company Employees" count="35" image={person} percentage={59.3} extra="+3" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AnalyticTeam title="Employees in Plan" count="30" image={dollarsolid} percentage={70.5} extra="+5" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AnalyticTeam title="Learning Hours in Month" count="17 hours" image={briefcasefill} percentage={27.4} isLoss color="warning" extra="+7" />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <AnalyticTeam title="Staff Engagement Score" count="95" image={graduationcap} percentage={27.4} isLoss color="warning" extra="+9" />
            </Grid>
            {/* row 3 */}

            <Grid sx={{ ml: '1.5vw', width: '100%', mt: '4.07vh' }}>
              <MainCard sx={{ mt: 2 }} content={false}>
                <Grid container justifyContent="space-between">
                  <Grid>
                    <Typography sx={{ mt: '3.52vh', mr: '0vw', ml: '1.04vw', mb: '0vh', fontWeight: 'Bold', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: '1.15vw' }}>Employer Information</Typography>
                  </Grid>
                  <Stack direction="row" justifyContent="flex-end">
                  <ToastContainer />
                    {/* <Box sx={{ mt: '3.32vh', ml: '1.85vh' }}>
                      <Button
                        id="fade-button"
                        sx={{ fontWeight: 'Medium', font: 'Roboto', textTransform: 'none', ml: '0vw', fontSize: '0.7vw', color: '#AFAFAF', border: '1px solid #D0D0D0', borderRadius: '0.2vw', borderColor: '#D0D0D0', width: '6.82vw', height: '1.93vw' }}
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        // onClick={handleClick}
                        endIcon={<KeyboardArrowDownIcon />}>
                        Sort by
                      </Button>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleClose} disableRipple>
                          <EditIcon />
                          Edit
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                          <FileCopyIcon />
                          Duplicate
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }} />
                        <MenuItem onClick={handleClose} disableRipple>
                          <ArchiveIcon />
                          Archive
                        </MenuItem>
                        <MenuItem onClick={handleClose} disableRipple>
                          <MoreHorizIcon />
                          More
                        </MenuItem>
                      </StyledMenu>
                    </Box> */}

                    <Box>
                      <Dialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        aria-labelledby="edit-apartment"
                      >
                        <DialogTitle id="edit-apartment">
                          <Typography align="center" sx={{ mt: '0', mr: '0vw', ml: '0vh', mb: '0vh', fontWeight: 'Bold', font: 'Roboto', color: '#31312C', opacity: '1', fontSize: `${rspvw(22)}` }}>Employer Information</Typography>


                        </DialogTitle>
                        <DialogContent>
                          <Box display="flex" justifyContent="center">
                            <Avatar alignItems="center" alt="profile user" src={avatar_boy} sx={{ width: `${rspvw(107)}`, height: `${rspvw(107)}`, ml: '0vw', alignItems: 'center' }} />
                          </Box>
                          <form >
                            <Grid container justifyContent="space-between">

                              <Stack direction="row" justifyContent="space-between" sx={{mt:`${rspvw(54)}`}}>
                                <TextField

                                  margin="dense"
                                  id="first_name"

                                  type="text"


                                  label="First Name"
                                  name="firstName"
                                  //className={margin}
                                  defaultValue={firstName}
                                  fullWidth
                                  sx={{ fontWeight: 'Medium', font: 'Roboto', textTransform: 'none', ml: '0vw', fontSize: '0.7vw', color: '#AFAFAF', width: `${rspvw(316)}`, height: `${rspvw(40)}`, mt: '0vw' }}
                                  onChange={(e) => onChangeFirstName(e)}
                                  error={errors.firstName ? true : false}
                                  helperText={errors.firstName}
                                 // onBlur={handleBlur}
                                />


                              </Stack>


                              <Stack direction="row" justifyContent="flex-end" sx={{mt:`${rspvw(54)}`}}>

                                <TextField

                                  margin="dense"
                                  id="last_name"
                                  type="text"
                                  label="Last Name"
                                  name="lastName"
                                  //className={margin}
                                  defaultValue={lastName}
                                  fullWidth
                                  sx={{ fontWeight: 'Medium', font: 'Roboto', textTransform: 'none', ml: '0vw', fontSize: '0.7vw', color: '#AFAFAF',width: `${rspvw(316)}`, height: `${rspvw(40)}`, mt: '0vw' }}
                                  
                                  onChange={(e) => onChangeLastName(e)}
                                  error={errors.lastName ? true : false}
                                  helperText={errors.lastName}
                                  //onBlur={handleBlur}
                                />

                              </Stack>


                              <Stack direction="row" justifyContent="space-between" sx={{mt:`${rspvw(54)}`}}>
                                {/* <TextField

                                  margin="dense"
                                  id="birth_date"
                                  label="Birth Date"
                                  type="text"

                                  name="birthDate"
                                  //className={margin}
                                  defaultValue={stateForm.birthDate}
                                  fullWidth

                                  onChange={handleChange}
                                  error={errors.birthDate ? true : false}
                                  helperText={errors.birthDate}
                                  onBlur={handleBlur}
                                /> */}

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DemoContainer
                                    components={[
                                      'DatePicker',
                                      'DateTimePicker',
                                      'TimePicker',
                                      'DateRangePicker',
                                    ]}
                                  >
                                    <DemoItem label="">
                                      {/* <DatePicker
                                        label="Birth Date"
                                        name="birthDate"
                                        value={stateForm.birthDate}
                                        defaultValue={stateForm.birthDate}
                                        onChange={e => this.handleDateChange(e.target.value)}
                                       // onChange={handleDateChange}
                                        onBlur={handleBlur}
                                        disableFuture
                                        views={['year', 'month', 'day']}
                                        sx={{ fontWeight: 'Medium', font: 'Roboto', textTransform: 'none', ml: '0vw', fontSize: '0.7vw', color: '#AFAFAF', width: `${rspvw(316)}`, height: `${rspvw(76.9)}`, mt: '0vw' }}
                                      /> */}

<DatePicker  label="Birth Date" selected={birthDate} onChange={onChangeBirthDate} />
{/* <DatePicker showToday onChange={onChange}  defaultValue={tomorrow}/> */}
                                    </DemoItem>



                                  </DemoContainer>
                                </LocalizationProvider>

                              </Stack>


                              <Stack direction="row" justifyContent="flex-end" sx={{mt:`${rspvw(65)}`}}>
                                <TextField
                                  select
                                  native="true"
                                  //className={classes.textField}
                                  margin="dense"
                                  id="gender"
                                  label="Gender"
                                  type="text"
                                  name="gender"
                                 // onChange={handleChange}
                                  onChange={(e) => onChangeGender(e)}
                                  error={errors.gender ? true : false}
                                  helperText={errors.gender}
                                  onBlur={handleBlur}
                                  sx={{ fontWeight: 'Medium', font: 'Roboto', textTransform: 'none', ml: '0vw', fontSize: '0.7vw', color: '#AFAFAF', width: `${rspvw(316)}`, height: `${rspvw(40)}`, mt: '0vw' }}
                                >
                                  <MenuItem value="0">Select Gender</MenuItem>
                                  <MenuItem value="1">Male</MenuItem>
                                  <MenuItem value="2">Female</MenuItem>

                                </TextField>

                              </Stack>


                              <Stack direction="row" justifyContent="space-between" sx={{mt:`${rspvw(45)}`}}>
                                <TextField

                                  margin="dense"
                                  id="address"
                                  label="Address"
                                  type="text"
                                  name="address"
                                  //className={margin}
                                  defaultValue={address}
                                  fullWidth
                                  sx={{ fontWeight: 'Medium', font: 'Roboto', textTransform: 'none', ml: '0vw', fontSize: '0.7vw', color: '#AFAFAF', width: `${rspvw(316)}`, height: `${rspvw(40)}`, mt: '0vw' }}
                                  onChange={onChangeAddress}
                                  error={errors.address ? true : false}
                                  helperText={errors.address}
                                  onBlur={handleBlur}
                                />


                              </Stack>


                              <Stack direction="row" justifyContent="flex-end" sx={{mt:`${rspvw(45)}`}}>

                                {/* <TextField

                                  margin="dense"
                                  id="position"
                                  label="Position"
                                  type="text"
                                  name="position"
                                  //className={margin}
                                  defaultValue={stateForm.position}
                                  fullWidth

                                  onChange={handleChange}
                                  error={errors.position ? true : false}
                                  helperText={errors.position}
                                  onBlur={handleBlur}
                                /> */}


                                <TextField
                                  select
                                  native="true"
                                  //className={classes.textField}
                                  margin="dense"
                                  id="position"
                                  label="Position"
                                  type="text"
                                  name="position"
                                  onChange={(e) => onChangePosition(e)}
                                  error={errors.position ? true : false}
                                  helperText={errors.position}
                                  onBlur={handleBlur}
                                  sx={{ fontWeight: 'Medium', font: 'Roboto', textTransform: 'none', ml: '0vw', fontSize: '0.7vw', color: '#AFAFAF', width: `${rspvw(316)}`, height: `${rspvw(40)}`, mt: '0vw' }}
                                >
                                  <MenuItem value="0">Select designation</MenuItem>
                                  <MenuItem value="1">Engineer</MenuItem>
                                  <MenuItem value="2">Sr. Engineer</MenuItem>
                                  <MenuItem value="3">Manager</MenuItem>
                                  <MenuItem value="4">Accountant</MenuItem>

                                </TextField>




                              </Stack>


                              {/* <Stack direction="row" justifyContent="space-between" sx={{mt:'1vw'}}>
                                <TextField

                                  margin="dense"
                                  id="start_date"
                                  label="Start Date"
                                  type="text"
                                  name="startDate"
                                  //className={margin}
                                  defaultValue={stateForm.startDate}
                                  fullWidth

                                  onChange={handleChange}
                                  error={errors.startDate ? true : false}
                                  helperText={errors.startDate}
                                  onBlur={handleBlur}
                                />


                              </Stack> */}



<Stack direction="row" justifyContent="space-between" sx={{mt:`${rspvw(54)}`}}>
                                {/* <TextField

                                  margin="dense"
                                  id="birth_date"
                                  label="Birth Date"
                                  type="text"

                                  name="birthDate"
                                  //className={margin}
                                  defaultValue={stateForm.birthDate}
                                  fullWidth

                                  onChange={handleChange}
                                  error={errors.birthDate ? true : false}
                                  helperText={errors.birthDate}
                                  onBlur={handleBlur}
                                /> */}

                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DemoContainer
                                    components={[
                                      'DatePicker',
                                      'DateTimePicker',
                                      'TimePicker',
                                      'DateRangePicker',
                                    ]}
                                  >
                                    <DemoItem label="">
                                      {/* <DatePicker
                                        label="Start Date"
                                        defaultValue={tomorrow}
                                        name="birthDate"
                                        disableFuture
                                        views={['year', 'month', 'day']}
                                        sx={{ fontWeight: 'Medium', font: 'Roboto', textTransform: 'none', ml: '0vw', fontSize: '0.7vw', color: '#AFAFAF', width: `${rspvw(316)}`, height: `${rspvw(76.9)}`, mt: '0vw' }}
                                      /> */}

<DatePicker  label="Start Date" selected={startDate} onChange={onChangeStartDate} />
                                    </DemoItem>



                                  </DemoContainer>
                                </LocalizationProvider>

                              </Stack>



                              <Stack direction="row" justifyContent="flex-end" sx={{mt:`${rspvw(65)}`}}>

                                {/* <TextField
                                 
                                  margin="dense"
                                  id="department"
                                  label="Department"
                                  type="text"
                                  name="department"
                                  //className={margin}
                                  defaultValue={stateForm.department}
                                  fullWidth

                                  onChange={handleChange}
                                  error={errors.department ? true : false}
                                  helperText={errors.department}
                                  onBlur={handleBlur}
                                /> */}



                                <TextField
                                  // id="id"
                                  //  name="name"
                                  select
                                  native="true"
                                  //className={classes.textField}
                                  margin="dense"
                                  id="department"
                                  label="Select Department"
                                  type="text"
                                  name="department"
                                  //className={margin}
                                  //  defaultValue={stateForm.department}
                                  // inputRef={register({ required: "Choose one option" })}
                                  // error={!!errors.name}

                                  onChange={(e) => onChangeDepartment(e)}
                                  error={errors.department ? true : false}
                                  helperText={errors.department}
                                  onBlur={handleBlur}
                                  sx={{ fontWeight: 'Medium', font: 'Roboto', textTransform: 'none', ml: '0vw', fontSize: '0.7vw', color: '#AFAFAF', width: `${rspvw(316)}`, height: `${rspvw(40)}`, mt: '0vw' }}
                                >
                                  <MenuItem value="0">Select Department</MenuItem>
                                  <MenuItem value="1">Finance</MenuItem>
                                  <MenuItem value="2">Technical</MenuItem>
                                  <MenuItem value="3">Marketing</MenuItem>
                                  <MenuItem value="4">Documentation</MenuItem>
                                </TextField>

                                {/* <Select
                                  id="department"
                                  name="department"
                                  //className={margin}
                                  // defaultValue={stateForm.department}
                                  fullWidth


                                  error={errors.department ? true : false}
                                  helperText={errors.department}
                                  onBlur={handleBlur}
                                  value={stateForm.department}
                                  onChange={handleChange}
                                  sx={{ fontWeight: 'Medium', font: 'Roboto', textTransform: 'none', ml: '0vw', fontSize: '0.7vw', color: '#AFAFAF', width: '14.9vw', height: '4vw', mt: '0.7vw' }}
                                  displayEmpty
                                  inputProps={{ 'aria-label': 'Without label' }}
                                >
                                  <MenuItem value="" >
                                    Select Department
                                  </MenuItem>
                                  <MenuItem value={10}>Ten</MenuItem>
                                  <MenuItem value={20}>Twenty</MenuItem>
                                  <MenuItem value={30}>Thirty</MenuItem>
                                </Select> */}


                              </Stack>

                            </Grid>
                          </form>


                        </DialogContent>
                        <DialogActions>
                        <Button color="primary" type="submit" disabled={!isValidForm} onClick={onSubmitData}>
                            Submit
                          </Button>
                          {/* <Button onClick={handleCloseDialog} color="secondary" >
                            Cancel
                          </Button> */}
                         
                        </DialogActions>
                      </Dialog>

                    </Box>
                    {/* <Box sx={{ mr: '0.00vw', ml: '0.68vw', mt: '3.52vh', mb: '0vh', width: '0.68vw', height: '1.65vw' }}>
                      <img src={bxDown} alt="Arrowdown" width="100%" heigh="100%" />
                    </Box> */}
                    <Button variant="outlined" onClick={handleClickOpen} sx={{ border: '1px solid #D0D0D0', borderRadius: '0.2vw', borderColor: '#D0D0D0', ml: '1.32vw', backgroundColor: '#E3E3E3', mt: '3.52vh', fontSize: '0.5vw', mr: "1.46vh", width: '8.33vw', height: '1.88vw' }}>
                      <Typography sx={{ mt: '00vh', mr: '0vw', ml: '0', mb: '0vh', fontWeight: 'Regular', font: 'Roboto', color: '#000000', opacity: '1', fontSize: '0.70vw' }}>
                        Add Member</Typography></Button>

                  </Stack>
                </Grid>


                <OrdersTable />

                {/* <EmployerTeamList/> */}
              </MainCard>
            </Grid>


          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
}

function InputKey() {

  const name = 'password'
  const type = 'password'
  const [cur, setCur] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false);

  function onClickFun(e) {
    const val1 = e.currentTarget.value
    val1 === "N3$tfu3L" ? allowed = 'yes' : allowed = 'no'


  };

  return (
    <div position='fixed' align='center'>
      <Typography sx={{ fontSize: '1vw', width: '12vw' }}>Please answer the following to activae DISCOVER NESTFUEL (Hint:Army man of the Raj earns working days by a week 7-6-3)</Typography>
      <Typography sx={{ mt: 2, fontSize: '1vw', width: '12vw' }}></Typography>
      <TextField sx={{ fontsize: '0.5vw', mt: 1, width: '12vw', border: 1, borderColor: 'black', borderRadius: 2 }}
        name={name}
        onChange={onClickFun}
        variant="outlined"
        required
        fullWidth={false}
        label
        autoFocus={true}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </div>
  )
}

export default EmployerTeam;